import React, { useEffect, useState } from 'react'

export type ScreenBrowserState = {
    projectScreens?: { [project: string]: any }
    screenId?: string | null,
    selectedProject?: string | null,
}

export const ScreenBrowserStateInitial = {
    projectScreens: {},
}

export type ScreenBrowserEvent = 
        { message: "ScreenBrowserDidLoad", params: {} }
    |   { message: "SetScreenId", params: { id: string } }
    |   { message: "CreateUIv2Load", params: {} }

const ScreenBrowser = (props: any) => {

    const [selectedProject, setSelectedProject] = useState<string>(props.selectedProject);

    useEffect(() => {
        props.onEvent({ message: "ScreenBrowserDidLoad", params: {} })
    }, [])

    useEffect(() => {
        setSelectedProject(props.selectedProject);
    }, [props.selectedProject])

    // console.log(props.projectScreens);

    return(
    <div
        style={{ 
            position: "absolute", 
            top: 30,
            left: 0,
            zIndex: 10001,
            backgroundColor: "white",
            width: "300px", 
            height: "400px",
            border: "solid black 1px",
            overflow: "auto" 
        }}>
        {props.projectScreens && 
        Object.keys(props.projectScreens)
        .sort()
        .map((project: string, project_index: number) => (
        <div key={project_index}>
            <div
                style={{ cursor: "pointer", width:"100%" }}
                onClick={(e: any) => {
                    setSelectedProject(project === selectedProject ? "" : project); 
                }}>
                {project}
            </div>
            {project === selectedProject && 
            props.projectScreens[project].map((screen: {id: string, name: string}, screen_index: number) => (
            <div 
                key={screen_index}
                style={{ cursor: "pointer", backgroundColor: "#eeeeee" }}
                onClick={(e) => { 
                    props.onEvent({ 
                        message: "SetScreenId",
                        params: { id: screen.id }
                    }) 
                }}>
                {"\u00a0".repeat(8) + screen.name}
            </div>
            ))}
        </div>
        ))}
    </div>
    )
}

export default ScreenBrowser