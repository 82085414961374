import { useEffect, useRef, useState } from "react";
import { RedmineStatus } from "ismor-lib/utils/Constant";
import { Checkbox } from "semantic-ui-react";
import BokehChartList from "./BokehChartList";
import { query } from "ismor-lib/utils/DuckLib";
import moment from "moment";

export default function PerformanceNew(props: any) {
  const [selectedUser, setSelectedUser] = useState<string|null>(null);
  const [mode, setMode] = useState("Day");
  const [monthData, setMonthData] = useState<any[]>([]);
  const [dayData, setDayData] = useState<any[]>([]);
  const [yList, setYList] = useState<string[]>([]);
  const [selectedY, setSelectedY] = useState<string|null>(null);
  const [selectedYm, setSelectedYm] = useState(moment());
  const [userList, setUserList] = useState<string[]>([]);
  const [userIssues, setUserIssues] = useState<any[]>([]);
  const [includeSupervisee, setIncludeSupervisee] = useState(false);
  const [filteredUser, setFilteredUser] = useState<string[]>([]);

  const processingDayData = useRef(false);

  useEffect(() => {
    if (Object.keys(props.performance).length > 0)
      processDayData();
  }, [props.performance, includeSupervisee, selectedUser, selectedYm]);
  
  useEffect(() => {
    setDayData([]);
    if (props.all)
      props.onEvent({message: "GetPerformanceAll", params: {supervise: props.supervise}});
    else
      props.onEvent({message: "GetPerformanceForUser", params: {}});
  }, [props.all]);

  useEffect(() => {
    let userList_: string[] = Object.keys(props.performance)
    // .filter((user: string) => (![
    //   "018811", "Nantawat", "apinya@thevcgroup.com", "boonyarit@thevcgroup.com", "champkuog",
    //   "chayapol.dau@mahidol.edu", "jirayut@thevcgroup.com", "kannika@thevcgroup.com", "kanokpol",
    //   "kowit@thevcgroup.com", "manasicha@thevcgroup.com", "manotien@thevcgroup.com",
    //   "methus@thevcgroup.com", "narumon@thevcgroup.com", "nattaphon@thevcgroup.com", "natthapong@thevcgroup.com",
    //   "nuthapon@thevcgroup.com", "obshow1987@gmail.com", "pack.yai", "pakawin@thevcgroup.com",
    //   "paparn@thevcgroup.com", "pattamawan@thevcgroup.com", "pattapong@thevcgroup.com",
    //   "peraphol@thevcgroup.com", "pitchanon@thevcgroup.com", "sarocha@thevcgroup.com", "sawinee@thevcgroup.com",
    //   "sirisa@thevcgroup.com", "tanomporn@thevcgroup.com", "wansiri.w", "wasin@thevcgroup.com",
    //   "watcharapol@thevcgroup.com", "worasit@thevcgroup.com"
    //   ].includes(user)
    // ));
    if (userList_.length === 0) return;
    if (props.all)
      userList_ = ["All", ...userList_];
    setUserList(userList_);
    setSelectedUser(userList_[0]);
  }, [props.performance]);

  useEffect(() => {
    if (selectedUser === null) return

    // Set userIssues and get additional info
    let userIssues_ = [];
    if (selectedUser === "All") {
      userIssues_ = Object.values(props.performance as {[key: string]: any[]})
        .flatMap((perf: any) => perf.issues)
        .filter((item: any) => item.is_author ||
          (["3", "5", "9"].includes(item.status_id) && item.resolver_last));
      setFilteredUser(Object.keys(props.performance));
    } else {
      userIssues_ = (Object.entries(props.performance) || [])
        .filter((entry: any) => (
          entry[0] === selectedUser || 
          (includeSupervisee && entry[1]?.supervisor?.includes(selectedUser))
        ))
        .flatMap((entry: any) => entry[1].issues)
        .filter((item: any) => item.is_author ||
          (["3", "5", "9"].includes(item.status_id) && item.resolver_last));
      setFilteredUser(
        (Object.entries(props.performance) || [])
        .filter((entry: [string, any]) => (
          entry[0] == selectedUser || 
          (includeSupervisee && entry[1]?.supervisor?.includes(selectedUser))
        ))
        .map((entry: [string, any]) => entry[0])
      )
    }
    setUserIssues(userIssues_);
    if (selectedUser !== "All" && userIssues_.length > 0)
      props.onEvent({message: "GetIssueLatestUpdates", params: {
        issues: userIssues_.map((issue: any) => issue.issue_id)}});
    setDataFromUserIssues(userIssues_);
  }, [selectedUser, includeSupervisee]);
  
  const processDayData = async () => {
    setDayData([]);    
    if (!processingDayData.current) {
      try {
        processingDayData.current = true;
        let con = props.duckCon;
        let result = (await query(`
          with temp as (
            from day_performance_${props.all ? "all" : "user"}
            select
              created_date,
              detail_id_unique,
              issue_id,
              status_id,
              username,
              count(perf_user) perf_user_count
            group by all
          ), temp1 as (
            from temp 
            select
              created_date,
              status_id,
              issue_id,
              list_distinct(list(username)) unique_username
            group by all
          )
          from temp1
          where contains(created_date, '${selectedYm.format('YYYY-MM')}')
          ;
          `, con)
        ).map((row: any) => 
          ({...row, unique_username: row.unique_username.toJSON()}));
        setDayData(result);
      } catch (e: any) { console.log(e); }
      processingDayData.current = false;
    }
  }
  
  const setDataFromUserIssues = (userIssues_: any[]) => {
    // Calculate month count & ylist
    let yList_: string[] = [];
    const monthCount = Object.entries(
      (userIssues_.reduce((acc: any, cur: any) => {
          if (cur.is_author) {
            let ym = cur.created_on.substring(0, 7);
            if (!(ym in acc))
              acc[ym] = {authored: 0, resolved: 0, closed: 0, issues_author: [], issues_resolve: []}
            acc[ym].authored += 1;
            acc[ym].issues_author.push(cur);
            if (!yList_.includes(cur.created_on.substring(0, 4))) {
              yList_.push(cur.created_on.substring(0, 4));
            }
          }
          else {
            let ym = cur.last_resolved.substring(0, 7);
            if (!(ym in acc))
              acc[ym] = {authored: 0, resolved: 0, closed: 0, issues_author: [], issues_resolve: []}
            if (["3", "9"].includes(cur.status_id)) {
              acc[ym].resolved += 1;
              acc[ym].issues_resolve.push(cur);
            } else if (cur.status_id === "5") {
              acc[ym].closed += 1;
              acc[ym].issues_resolve.push(cur);
            }
            if (!yList_.includes(cur.last_resolved.substring(0, 4))) {
              yList_.push(cur.last_resolved.substring(0, 4));
            }
          }
          return acc
        }, {})
      ))
      .sort((a: any, b: any) => a[0] < b[0] ? -1 : 1)
    yList_ = yList_.sort((a: any, b: any) => a < b ? -1 : 1);
    setYList(yList_);
    if (yList_.length > 0)
      setSelectedY(yList_[yList_.length - 1]);

    // Calculate percentage for chart plotting
    const maxCount: number = Math.max(
      ...monthCount.map((ymItem: any) => 
        Math.max(ymItem[1].authored, ymItem[1].resolved + ymItem[1].closed) as number));
    let monthData_ = monthCount.map((ym: any[]) => ({
      label: ym[0], 
      authored: ym[1].authored,
      closed: ym[1].closed, 
      resolved: ym[1].resolved, 
      issues_author: ym[1].issues_author,
      issues_resolve: ym[1].issues_resolve,
      authored_width: ym[1].authored / maxCount * 100.0,
      closed_width: ym[1].closed / maxCount * 100.0, 
      resolved_width: ym[1].resolved / maxCount * 100.0
    }));

    // Set final data 
    setMonthData(monthData_);
  };

  const templateColumns = "75px 1fr 75px 150px 150px 200px 200px";
  let daysOfMonth = Array.from({length: selectedYm.daysInMonth()}, (x, i) => i+1);

  return(
    <div 
      style={{ height: "90vh", padding: "5px"}}>
      <div style={{display: "flex", borderBottom: "solid grey 1px", alignItems: "center"}}>
        <div
          onClick={(e: any) => setMode("Day")}
          style={{
            padding: "5px", cursor: "pointer", 
            border: "solid #dddddd 1px", borderRadius: "2px",
            backgroundColor: mode === "Day" ? "#dddddd" : "white"
          }}>
          Day
        </div>
        <div
          onClick={(e: any) => setMode("Month")}
          style={{
            padding: "5px", cursor: "pointer", 
            border: "solid #dddddd 1px", borderRadius: "2px",
            backgroundColor: mode === "Month" ? "#dddddd" : "white"
          }}>
          Month
        </div>
        {selectedUser !== "All" &&
        <>
          <div 
            onClick={(e: any) => setMode("Authored")}
            style={{
              padding: "5px", cursor: "pointer", 
              border: "solid #dddddd 1px", borderRadius: "2px",
              backgroundColor: mode === "Authored" ? "#dddddd" : "white"
            }}>
            Authored
          </div>
          <div 
            onClick={(e: any) => setMode("Closed")}
            style={{
              padding: "5px", cursor: "pointer", 
              border: "solid #dddddd 1px", borderRadius: "2px",
              backgroundColor: mode === "Closed" ? "#dddddd" : "white"
            }}>
            Closed
          </div>
          <div 
            onClick={(e: any) => setMode("Resolved / Not Closed")}
            style={{
              padding: "5px", cursor: "pointer", 
              border: "solid #dddddd 1px", borderRadius: "2px",
              backgroundColor: mode === "Resolved / Not Closed" ? "#dddddd" : "white"
            }}>
            Resolved / Not Closed
          </div>
        </>}
        {/* {props.pythonLoaded && props.bokehLoaded &&
        <div
          onClick={(e: any) => setMode("Chart")}
          style={{
            padding: "5px", cursor: "pointer", 
            border: "solid #dddddd 1px", borderRadius: "2px",
            backgroundColor: mode === "Chart" ? "#dddddd" : "white"
          }}>
          Chart
        </div>} */}
        <div style={{flex: 1}}></div>
        {props.all && selectedUser !== "All" && 
         (Object.values(props.performance) || [])
                .filter((perf: any) => perf?.supervisor?.includes(selectedUser))
                .length > 0
         &&
        <Checkbox 
          style={{marginRight: "10px"}}
          label="Include Supervisee"
          checked={includeSupervisee}
          onClick={(e: any) => setIncludeSupervisee(!includeSupervisee)}
        />
        }
        <select
          value={selectedUser || ""}
          onChange={(e: any) => { setSelectedUser(e.target.value)}}>
          {userList.map((user: string, userIndex: number) => 
            <option key={userIndex} value={user}>{user}</option>)}  
        </select>
      </div>
      {["Month", "Authored", "Closed", "Resolved / Not Closed"].includes(mode) &&
      <div style={{display: "flex", borderBottom: "solid #cccccc 1px", marginTop: "2px"}}>
        {yList.map((y: string, yIndex: number) => 
          <div 
            key={yIndex}
            style={{
              padding: "5px", cursor: "pointer",
              backgroundColor: y === selectedY ? "lightgreen" : "white"
            }}
            onClick={(e: any) => setSelectedY(y)}>
            {y}
          </div>)}
      </div>}
      <div>
        {mode === "Month" ?
        <div style={{ height: "87vh", overflowY: "auto" }}>
          {monthData
            .filter((item: any) => item.label.substring(0, 4) === selectedY)
            .sort((a: any, b: any) => a.label > b.label ? -1 : 1)
            .map((ymItem: any, ymItemIndex: number) => (
              <div 
                key={ymItemIndex}
                style={{
                  display: "grid", gridTemplateColumns: "100px 1fr",
                  borderBottom: "solid #dddddd 1px", padding: "2px"
                }}
                onClick={(e: any) => {
                  let {issues_author, issues_resolve} = ymItem;
                  let issues_author_id = issues_author.map((item: any) => item.issue_id);
                  let issues_resolve_id = issues_resolve.map((item: any) => item.issue_id);
                  console.log("check issue_author_id", 
                              issues_author_id.length, 
                              Array.from(new Set(issues_author_id)).length);
                  console.log("check_issue_resolve_id", 
                              issues_resolve_id.length, 
                              Array.from(new Set(issues_resolve_id)).length);
                }}>
                <div>{ymItem.label}</div>
                <div>
                  <div 
                    style={{backgroundColor: "orange", width: `${ymItem.authored_width}%`, color: "white", paddingLeft: "3px"}}>
                    {ymItem.authored}
                  </div>
                  <div style={{display: "flex"}}>
                    <div 
                      style={{backgroundColor: "green", width: `${ymItem.closed_width}%`, color: "white", paddingLeft: "3px"}}>
                      {ymItem.closed}
                    </div>
                    <div 
                      style={{backgroundColor: "#dddddd", width: `${ymItem.resolved_width}%`, color: "white", paddingLeft: "3px"}}>
                      {ymItem.resolved}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        : mode === "Day" ?
        <div>
          <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
            <span 
              onClick={(e: any) => {
                setSelectedYm(selectedYm.clone().subtract(1, 'M'));
              }}
              style={{cursor: "pointer"}}>
              &lt;&lt;
            </span>
            <span style={{marginLeft: "10px", marginRight: "10px"}}>
              {selectedYm.format('YYYYMM')}
            </span>
            <span 
              onClick={(e: any) => {
                setSelectedYm(selectedYm.clone().add(1, 'M'))
              }}
              style={{cursor: "pointer"}}>
              &gt;&gt;
            </span>
          </div>
          <div 
            style={{
              display: "grid", 
              gridTemplateColumns: `100px repeat(${daysOfMonth.length}, 1fr)`, 
              width: "100%"
            }}>
            {[<div key={`0_0`} style={{border: "solid #cccccc 1px"}}></div>]
              .concat(daysOfMonth
                .map((day: number, dayIndex: number) => 
                  <div 
                    key={`0_${dayIndex + 1}`}
                    style={{flex: 1, border: "solid #cccccc 1px", textAlign: "center"}}>
                    {day}
                  </div>))
              .concat(["1", "2", "3", "4", "5", "6", "7", "9", "10"]
                .flatMap((statusId: string, statusIndex: number) => (
                  [<div key={`${statusIndex + 1}_0`} style={{border: "solid #cccccc 1px", height: "7vh"}}>
                    {RedmineStatus[statusId]}
                  </div>
                  ]
                  .concat(
                    daysOfMonth.map((day: number, statusDayIndex: number) => {
                      let supervisees = Object.entries(props.performance)
                        .filter((entry: [string, any]) => (
                          entry[1].supervisor?.includes(selectedUser)
                        ))
                        .map((entry: [string, any]) => entry[0]);
                      let displayItems = dayData
                        .filter((row: any) => row.status_id === statusId)
                        .filter((row: any) => 
                          row.created_date === selectedYm.set("date", day).format('YYYY-MM-DD'))
                        .filter((row: any) => 
                          selectedUser === "All" ? true 
                          : !includeSupervisee ? row.unique_username?.includes(selectedUser)
                          : (row.unique_username?.includes(selectedUser) 
                             || row.unique_username.some((user: string) => supervisees?.includes(user)))  
                        );
                      return (
                        <div 
                          key={`${statusIndex + 1}_${statusDayIndex + 1}`}
                          style={{border: "solid #cccccc 1px", textAlign: "center"}}
                          onClick={(e: any) => 
                            alert(
                              `issues: ${displayItems.map((row: any) => row.issue_id).join(', ')}`)}>
                          {displayItems.length === 0 ? "" : displayItems.length}
                        </div>
                      )
                    })
                  )   
                )))
            }
          </div>
        </div>
        : mode === "Chart" ?
        <div>
          <BokehChartList
            pythonLoaded={props.pythonLoaded} 
            bokehLoaded={props.bokehLoaded}
            pythonError={props.pythonError}
            python={props.python}
            rawData={props.performanceRaw}
            script={`
            # Prepare Data
            from itertools import chain
            data = list(chain.from_iterable(chain.from_iterable([[[
              {
                "performance_user": user_id,
                "issue_id": issue_id,
                "detail_id": detail_id,
                **detail
              }
              for detail_id, detail in issue.items()]
              for issue_id, issue in user_data.items()]
              for user_id, user_data in rawData.items()]
            )))
            df = pd.DataFrame(data)
            df1 = df.groupby("performance_user").agg(issue_count=("issue_id", "nunique"))
            df1 = df1.sort_values("issue_count", ascending=True)
            
            # Create plot
            p = []
            plot = df1.hvplot.barh(x="performance_user", height=400, width=1000)
            plot1 = df1.hvplot.table(columns=["performance_user", "issue_count"], width=1000)
            p.append(hv.render(plot, backend='bokeh'))
            p.append(hv.render(plot1, backend='bokeh'))
            `}
          />
        </div>
        :
        <>
          <div 
            style={{
              display: "grid", width: "100%",
              borderBottom: "solid #dddddd 1px",
              gridTemplateColumns: templateColumns,
            }}>
            <div>Issue ID</div>
            <div>Subject</div>
            <div>Status</div>
            <div>Created On</div>
            <div>Last Resolved</div>
            <div>Last Resolving User</div>
            <div>Author</div>
          </div>  
          {mode === "Authored" ?
          <div style={{ height: "85vh", overflowY: "auto" }}>
           {userIssues
            .filter((item: any) => 
              item.is_author && item.created_on.substring(0, 4) === selectedY)
            .sort((a: any, b: any) => a.created_on < b.created_on ? -1 : 1)
            .map((issue: any, issueIndex: number) => (
              <div 
                key={issueIndex}
                style={{
                  display: "grid", gridTemplateColumns: templateColumns,
                  borderBottom: "solid #dddddd 1px"
                }}>
                <div>{issue.issue_id}</div>
                <div>{props.issueLatestUpdates?.[issue.issue_id]?.subject || ""}</div>
                <div>{RedmineStatus?.[issue.status_id] || ""}</div>
                <div>{issue.created_on}</div>
                <div>{issue.last_resolved}</div>
                <div>{issue.last_resolving_user}</div>
                <div>{issue.author}</div>
              </div>
           ))}
          </div>   
          : mode === "Closed" ?
          <div style={{ height: "85vh", overflowY: "auto" }}>
            {userIssues
            .filter((item: any) => !item.is_author &&
              ["5"].includes(item.status_id) && item.last_resolved.substring(0, 4) === selectedY)
            .sort((a: any, b: any) => a.last_resolved < b.last_resolved ? -1 : 1)
            .map((issue: any, issueIndex: number) => (
              <div 
                key={issueIndex}
                style={{
                  display: "grid", gridTemplateColumns: templateColumns,
                  borderBottom: "solid #dddddd 1px"
                }}>
                <div>{issue.issue_id}</div>
                <div>{props.issueLatestUpdates?.[issue.issue_id]?.subject || ""}</div>
                <div>{RedmineStatus?.[issue.status_id] || ""}</div>
                <div>{issue.created_on}</div>
                <div>{issue.last_resolved}</div>
                <div>{issue.last_resolving_user}</div>
                <div>{issue.author}</div>
              </div>
            ))}
          </div>      
          : mode === "Resolved / Not Closed" ?
          <div style={{ height: "85vh", overflowY: "auto" }}>
            {userIssues
              .filter((item: any) => !item.is_author &&
                ["3", "9"].includes(item.status_id) && item.last_resolved.substring(0, 4) === selectedY)
              .sort((a: any, b: any) => a.last_resolved < b.last_resolved ? -1 : 1)
              .map((issue: any, issueIndex: number) => (
              <div 
                key={issueIndex}
                style={{
                  display: "grid", gridTemplateColumns: templateColumns,
                  borderBottom: "solid #dddddd 1px"
                }}>
                <div>{issue.issue_id}</div>
                <div>{props.issueLatestUpdates?.[issue.issue_id]?.subject || ""}</div>
                <div>{RedmineStatus?.[issue.status_id] || ""}</div>
                <div>{issue.created_on}</div>
                <div>{issue.last_resolved}</div>
                <div>{issue.last_resolving_user}</div>
                <div>{issue.author}</div>
              </div>
            ))}
          </div>
          :
          <></>
          }
        </>}
      </div>   
    </div>
  )
}
