import { useState } from "react";
import moment from "moment";
import { Icon } from "semantic-ui-react";

export default function OngoingView(props: any) {
  const thisMonth = (moment().format("YYYYMM"));
  const thisYear = (moment().format("YYYY"));
  const [selectedYear, setSelectedYear] = useState(thisYear);
  const [selectedDeliverable, setSelectedDeliverable] = useState<any>(null);
  
  const monthDict = Object.values(props.clientDeal)
  .filter((deal: any) => deal.status === "ONGOING")
  .flatMap((deal: any, index: number) =>
    (Object.values(deal.deliverables) || []).map((deliverable: any, deliverableIndex: number) => (
      {deal, index, deliverable, deliverableIndex})))
  .sort((a: any, b: any) => (
    a.deliverable.deadline_current < b.deliverable.deadline_current ? -1 : 1))
  .reduce((acc: any, cur: any) => {
    const month = cur.deliverable.deadline_current?.substring(0, 6) || "";
    if (!(month in acc)) {
      acc[month] = [];
    }
    acc[month].push(cur);
    return acc
  }, {});

  const yearList = Object.keys(monthDict).reduce((acc: string[], cur: string) => {
    if (acc.includes(cur.substring(0, 4)))
      return acc
    acc.push(cur.substring(0, 4))
    return acc
  }, []);

  return(
  <div >
    <div style={{display: "flex", borderBottom: "solid #eeeeee 1px", width: "100%"}}>
      {yearList.map((year: string, yearIndex: number) => 
        <div 
          style={{
            padding: "3px", cursor: "pointer",
            backgroundColor: year === selectedYear ? " #FFD580" : "white"
          }} 
          onClick={(e: any) => setSelectedYear(year)}
          key={yearIndex}>
          {year}
        </div>)}
    </div>
    <div 
      style={{
        height: "85vh", overflow: "auto", display: "flex", 
      }}>
      <div 
        style={{
          width: selectedDeliverable ? "400px" : "100%",
          borderRight: "solid #eeeeee 1px"
        }}>
        {Object.entries(monthDict)
        .filter((monthItem: [string, any]) => 
          monthItem[0].substring(0, 4) === selectedYear)
        .map((monthItem: [string, any], monthItemIndex: number) =>
          <div 
            key={monthItemIndex} 
            style={{
              padding: "5px",
              backgroundColor: monthItem[0] < thisMonth ? 
                                (monthItem[1].reduce(
                                  (acc: boolean, cur: any) => 
                                    acc && (cur.deliverable?.status === "FINISHED"), true
                                ) ? "#cefad0" : "pink")
                                : monthItem[0] === thisMonth  ? "yellow"
                                : "white"
            }}>
            <b>{monthItem[0] + '\u00a0' + "(" +
                monthItem[1].reduce((acc: number, cur: any) => 
                  acc + (Number(cur.deliverable.value) || 0), 0)
                .toLocaleString('en-US', {maximumFractionDigits: 0}) + ")"}</b>
            <div>
              {monthItem[1].map((entry: any, entryIndex: number) => (
              <div 
                style={{
                  display: "grid", 
                  gridTemplateColumns: selectedDeliverable ? "1fr" : "1fr 1fr 1fr 1fr 1fr",
                  borderBottom: "solid #cccccc 1px",
                  marginLeft: "20px", cursor: "pointer"
                }}
                key={`${entry.index}:${entry.deliverableIndex}`}
                onClick={(e: any) => {
                  // if (selectedDeliverable === null ||
                  //     !(selectedDeliverable.deal.id === entry.deal.id
                  //       && selectedDeliverable.deliverableIndex === entry.deliverableIndex))
                  //   setSelectedDeliverable(entry);
                  // else 
                  //   setSelectedDeliverable(null);
                }}>
                <div style={{display: "flex"}}>
                  <div style={{marginRight: "10px"}}>{`${entry.deal.clientName} ${entry.deal.dealName}`}</div>
                  <Icon 
                    name="folder open outline"
                    onClick={(e: any) => props.setSelectedDeal(entry.deal)}
                  />
                </div>
                <div>{`${entry.deliverable.name}`}</div>
                {selectedDeliverable ?
                <></>
                :
                <>
                  <div style={{textAlign: "right", marginRight: "40px"}}>
                    {entry.deliverable?.value
                    ?.toLocaleString('en-US', {maximumFractionDigits: 0})}
                  </div>
                  <div>{entry.deliverable.status}</div>
                  <div>{entry.deliverable.deadline_current}</div>
                </>
                }
              </div>
              ))}
            </div>
          </div>)}
      </div>
      {selectedDeliverable &&
      <div style={{flex: 1}}>
        {selectedDeliverable.deliverable.name}
      </div>
      }
    </div>
  </div>)
}
