import React, { useEffect, useState } from 'react'
import $ from 'jquery'

export default (props: any) => {

    //API uses App script at https://script.google.com/a/thevcgroup.com/d/1JxR7e2dc9hMey22GgH9tXdGW2m807uTFDb0EpcU-hDPTo8jG7SkJJ4Ym/edit?usp=drive_web
    useEffect(() => {
        // Get data from App script
        async function getData() {
            $.ajax({
                crossDomain: true,
                url: 'https://script.google.com/a/thevcgroup.com/macros/s/AKfycbx65d7W7Kh5BKW6OKelNJlLn46f61X1LRlHO4hNhKUzNPBllBQ/exec', 
                method: "GET", 
                dataType: "jsonp",
                success: (response) => {
                    console.log(response)
                }
            })  
        }
        getData()
    }, [])

    const handleEvent = ({message, params}: {message: string, params: any}) => {
        console.log(message, params)
    }
    
    return(
        <div>C Code
        </div>
    )
}

