import { useEffect, useState } from "react";

export default function GameStat(props: any) {
  const [ihGameStatData, setIHGameStatData] = useState<any[]>([]);
  useEffect(() => {
    if (props.gameId && props.xdId) 
      setIHGameStatData([]);
      props.onEvent({message: "GetGameStat", params: { 
        xdId: props.xdId,
        gameId: props.gameId
      }});
  }, [props.gameId]);

  useEffect(() => {
    if (props.ihGameStatList.length === 0) {
      setIHGameStatData([]);
      return;
    }
    (async () => {
      let ihGameStatData_ = await Promise.all(
        props.ihGameStatList.map(
          async (url: string) => await (await fetch(await (props.toWebDownloadUrl(url)))).json()));
      setIHGameStatData(ihGameStatData_);
    })();
  }, [props.ihGameStatList]);

  let templateColumns = "15% 15% 14% 14% 14% 14% 14%";

  return(
    <div style={{height: "88vh", overflowY: "auto", padding: "5px"}}>
      <div 
        style={{
          borderBottom: "solid grey 1px",
          display: "grid", gridTemplateColumns: templateColumns
        }}>
        <div>Datetime</div>
        <div style={{textAlign: "center"}}>User</div>
        <div style={{textAlign: "center"}}>Version</div>
        <div style={{textAlign: "center"}}>Total Score</div>
        <div style={{textAlign: "center"}}>Full Score</div>
        <div style={{textAlign: "center"}}>Reach</div>
        <div style={{textAlign: "center"}}>Final</div>
      </div>
      {ihGameStatData
      .sort((a: any, b: any) => a.datetime < b.datetime ? 1 : -1)
      .map((log: any, logIndex: number) => { 
        let parts = log.publishUrl?.split("/");
        let version = parts?.[parts.length -2];
        // let successCount = log.logs.filter((item: any) => item?.success).length;
        // let failCount = log.logs.filter((item: any) => !item?.success).length;
        let lastLog = log.logs[log.logs.length - 1];
        let lastStepLabel;
        if (log?.lastStep) {
          lastStepLabel = `Level ${log?.lastStep?.level + 1} Step ${log?.lastStep?.step + 1}`;
        } else {
          lastStepLabel = ""
        }
        return (
          <div 
            key={logIndex} 
            style={{
              borderBottom: "solid grey 1px",
              display: "grid", gridTemplateColumns: templateColumns
            }}>
            <div>{log.datetime}</div>
            <div style={{textAlign: "center"}}>{log.user}</div>
            <div style={{textAlign: "center"}}>{version}</div>
            <div style={{textAlign: "center"}}>{lastLog?.score}</div>
            <div style={{textAlign: "center"}}>{log?.fullScore}</div>
            <div style={{textAlign: "center"}}>
              {`Level ${lastLog.level + 1} Step ${lastLog.step + 1}`}
            </div>
            <div style={{textAlign: "center"}}>{lastStepLabel}</div>
          </div>)
      })}

    </div>
  )
}