import React, { useEffect, useState } from "react";
import { Dropdown, Modal } from "semantic-ui-react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css"
import moment from "moment";

moment.locale('en-es', {
  week: {
    dow: 1
  }
});
const localizer = momentLocalizer(moment);

const Perf = (props: any) => {
  const [display, setDisplay] = useState<any | null>(null);

  const perfData = props.perfData.flatMap((item: any, index: number) => (
    item.records_summary.sort((a: any, b: any) => (
      a.summary.includes("resolved") && !a.summary.includes("unresolved") ? -2 : 
      a.summary.includes("unresolved") && !b.summary.includes("resolved") ? -1 : 1
    ))
    .map((s: any, s_index: number) => (
      {
        ...item,
        display: { ...s, date: item.date },
        explain: s.issue_id.join(", "),
        title: (
          <div
            key={`${index}:${s_index}`}
            style={{ 
              backgroundColor: 
                s.summary.includes("unresolved") ? "yellow" : 
                s.summary.includes("resolved") ? "#81C32E" : "white",
              color: 
                s.summary.includes("unresolved") ? "black" : 
                s.summary.includes("resolved") ? "white" : "black"
            }}>
            {`${s.summary} (${s.issue_id.length})`}
          </div>)
      }
    ))
  ));
  
  useEffect(() => {
    // props.onEvent({"message": "GetPerfData", params: {}});
  }, [])

  // console.log(display);

  return(
    <div 
      style={{ 
        display: "flex", flexDirection: "column", 
        justifyContent: "center", alignItems: "center",
        height: "94vh",
        padding: props.mobileScreen ? "0": "10px"
      }}>
      <Modal
        open={display !== null}
        style={{ padding: "5px", overflowY: "scroll" }}
        onClose={(e: any) => setDisplay(null)}>
        <h2>{`${display?.date} (${display?.summary})`}</h2>
        {display?.issue_id?.map((id: number, index: number) => 
          <div
            key={index}
            style={{ 
              display: "grid",
              gridTemplateColumns: "10% 90%",
              marginBottom: "2px",
              cursor: "pointer"
            }}
            onClick={(e: any) => {
              window.open(
                `https://redmine.thevcgroup.com/issues/${id}`,
                "_blank"
              );
            }}>
            <div>{id}</div>
            <div>{display?.subject?.[index]}</div>
          </div>
        )}
      </Modal>
      {props.supervise.length > 1 &&
      <Dropdown
        style={{ 
          minWidth: props.mobileScreen ? "100%" : "40vw", 
          backgroundColor: "#b7e9f7",
        }}
        selection
        value={props.selectedUser}
        options={props.supervise
          .sort((a: string, b: string) => a < b ? -1 : 1)
          .map((email: string) => (
          { key: email, value: email, text: email }
        ))}
        onChange={(e: any, v: any) => {
          props.setProp("selectedUser", v.value);
          props.onEvent({
            message: "GetPerfData",
            params: { selectedUser: v.value }
          });
        }}
      />}
      <Calendar
        localizer={localizer}
        events={perfData}
        startAccessor="start"
        endAccessor="end"
        views={["month"]}
        popup={true}
        style={{ height: "100%", width: "100%" }}
        onRangeChange={(e: any) => {
          // console.log(e.start.getMonth());
          let year = e.start.getFullYear();
          let month = e.start.getMonth() + 1;
          if (e.start.getDate() !== 1) {
            if (month !== 12) {
              month = month + 1
            } else {
              month = 1;
              year += 1;
            }
          }
          console.log(year, month);
          props.onEvent({
            message: "GetPerfData",
            params: { selectedYear: year, selectedMonth: month }
          });
        }}
        selectable={true}
        onSelectSlot={(e: any) => {
          console.log(e);
        }}
        onSelectEvent={(e: any) => {
          console.log(e);
          setDisplay(e.display);
        }}
        tooltipAccessor="explain"
      />
    </div>
  )
}

export default Perf
