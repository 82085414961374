import { useEffect, useState } from "react";
import { Button, Input, Modal } from "semantic-ui-react";

export default function XDTestFormAddProblem(props: any) {
  const [description, setDescription] = useState("");
  const [candidateSeqList, setCandidateSeqList] = useState<number[]>();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  useEffect(() => {
    let candidates = (props.currentXdCase?.sequence || [])
      .map((caseSeq: any, seqIndex: number) => ({...caseSeq, seqIndex}))
      .filter((caseSeq: any, seqIndex: number) => caseSeq.stepId === props.selectedStep)
      .map((caseSeq: any) => caseSeq.seqIndex);
    setCandidateSeqList(candidates);
  }, [props.currentXdCase]);

  const doSave = (seqIndex: number) => {
    props.onEvent({message: "AddOrEditXdProblem", params: {
      mode: "add",
      xdCaseId: props.currentXdCase.id,
      seqIndex: seqIndex,
      testId: props.selectedTestId,
      testSeqIndex: props.testSeqIndex,
      nodeId: props.selectedNode,
      problemId: null,
      description,
      issues: [],
    }});
    clearForm();
  }

  const clearForm = () => {
    props.setAddingProblem(false);
    props.setSelectedNode(null);
    props.setSelectedStep(null);
  }

  return(
    <div>
      <div>
        <Input
          value={description}
          fluid
          placeholder="คำอธิบายปัญหา"
          onChange={(e: any) => setDescription(e.target.value)}
        />
      </div>
      <div style={{display: "flex", alignItems: "center"}}>
        <Button
          size="mini" color="red"
          onClick={(e: any) => {
            clearForm();
          }}>
          Cancel
        </Button>
        <Button
          disabled={candidateSeqList?.length === 0 || description === ""}
          size="mini" color="green"
          onClick={() => {
            if (candidateSeqList?.length === 1) {
              doSave(candidateSeqList[0]);
            } else {
              setOpenConfirmDialog(true)             
            }
          }}>
          Save
        </Button>
        {candidateSeqList?.length === 0 &&
        <div style={{color: "red"}}>ปัจจุบัน step นี้ไม่อยู่ใน case แล้ว ไม่สามารถเพิ่มปัญหาได้ กรุณากด Cancel</div>}
      </div>
      <Modal
        size="small"
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        closeIcon={true}>
        <div style={{padding: "15px", maxHeight: "70vh", overflowY: "auto"}}>
          <div>เนื่องจากมี step นี้มากกว่า 1 ใน case กรุณายืนยัน sequence ที่ต้องการเพิ่มปัญหา</div>
          {(props.currentXdCase?.sequence || [])
          .map((caseSeq: any, index: number) => (
            <div key={index} style={{display: "flex", margin: "5px", cursor: "pointer"}}>
              <div style={{width: "20px"}}>
                {`${candidateSeqList?.includes(index) ? ">>" : "  "}`}
              </div>
              <div
                style={{color: candidateSeqList?.includes(index) ? "blue" : "grey"}}
                onClick={() => {
                  if (candidateSeqList?.includes(index)) {
                    doSave(index);
                  }
                }}>
                {props.xddata?.steps?.[caseSeq.stepId]?.description}
              </div>
            </div>
          ))}
          <Button
            size="mini" color="red"
            onClick={(e: any) => {
            setOpenConfirmDialog(false);
            clearForm();
          }}>
          Cancel
        </Button>
        </div>
      </Modal>
    </div>
  )
}
