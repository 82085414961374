import { useEffect, useState } from "react";
import { monthListFrom, nextYm } from "./Utils";

export default function AddDetail(props: any) {
  const [summary, setSummary] = useState<any>(null);

  useEffect(() => {
    getSummary();
  }, [props.staffDict]);

  const getSummary = () => {
    let items= Object.values(props.staffDict)
                  .filter((item: any) => 
                    item.status !== "Inactive")
                  .map((item: any) => ({
                    ...item, 
                    last: item.detail?.[item.detail.length - 1]?.ym,
                    base: item.detail?.[item.detail.length - 1]?.components?.base,
                    role: item.detail?.[item.detail.length - 1]?.role,
                    stock: item.detail?.[item.detail.length - 1]?.components?.stock || 0,
                  }));
    
    let agg = items.reduce((acc: any, cur: any) => {
      return {
        id: "Sum",
        base: acc.base + cur.base,
        last: cur.last > acc.last ? cur.last : acc.last, 
      }
    }, {id: "Sum", base: 0.0, last: ""});
    
    const items_ = items.map((item: any) => parseInt(item.last));    
    const firstFillYm = Math.min(...items_).toString();
    items = [agg].concat(items);
    setSummary({
      items: items,
      monthList: monthListFrom(nextYm(firstFillYm))
    });
  }

  return(
    <div>
      {(summary?.items || [])
        ?.sort((a:any, b: any) => a.base > b.base ? -1 : 1)
        .map((item: any, index: number) => (
        <div 
          key={index}
          style={{
            display: "grid", 
            gridTemplateColumns: "200px 100px 100px " + "1fr ".repeat(summary?.monthList?.length || 0) + " 1fr", 
            borderBottom: "solid #eeeeee 1px"
          }}>
          <div>{item.id}</div>
          <div style={{textAlign: "right"}}>
            {item.base?.toLocaleString('en-US', {maximumFractionDigits: 0})}
          </div>
          <div style={{marginLeft: "10px"}}>
            {item.last}
          </div>
          {(summary?.monthList || []).map((ym: string, ymIndex: number) => (
            index === 0 ?
            <div key={ymIndex}>{ym}</div>
            :
            nextYm(item.last) === ym ?
            <div 
              key={ymIndex}
              style={{cursor: "pointer"}}
              onClick={(e: any) => props.onEvent({
                message: "AddStaffDetail",
                params: {item, ym}
              })}>
              Add
            </div>
            :
            ym > item.last ?
            <div 
              key={ymIndex}
              style={{cursor: "pointer", color: "#cccccc"}}>
              Add
            </div>
            :
            <div key={ymIndex}></div>
          ))}
          <div></div>
        </div>
      ))}
    </div>
  )
}