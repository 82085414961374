import React from 'react'
import { Element, ScreenData, ScreenPropsDefault } from './CreateUIv2Jsx'
import { CreateUIv2Event } from './CreateUIv2'
import UxDef, { Ux } from './UxDef'
import { Tx } from './TxDef'
import { DivGrid } from "./DivGrid";
import { momentLocalizer } from "react-big-calendar";
import moment from "moment";

moment.locale('en-es', {week: {dow: 1}});
const localizer = momentLocalizer(moment);

export const renderPlay = 
    (screenData: ScreenData, onEvent: any, openEditor: any, 
        uxToAdd: Ux | null, txToAdd: Tx | null, action: string, editedElementId: number | null, 
        setAction: any, openGridCanvas: any, divGridCells: any[], draggedCellIndex: number | null,
        setProp: any) => {
    if (screenData.elements && Object.keys(screenData.elements).length > 0) {
        try {
            return renderElement(screenData.elements, 
                screenData.screenPropsDefault || {},
                0, onEvent, openEditor, uxToAdd, txToAdd,
                action, editedElementId, setAction, openGridCanvas,
                divGridCells, draggedCellIndex, setProp)
        } catch {
            return React.createElement('div')
        }
    }
}

const renderElement: (elements: Element[], screenPropsDefault: ScreenPropsDefault,
        id: number, onEvent: any, openEditor: any, uxToAdd: Ux | null, txToAdd: Tx | null, 
        action: string, editedElementId: number | null, setAction: any,
        openGridCanvas: any, divGridCells: any[], draggedCellIndex: number | null,
        setProp: any) => React.ReactElement
    = (elements, screenPropsDefault, id, onEvent, openEditor, uxToAdd, txToAdd, 
      action, editedElementId, setAction, openGridCanvas, divGridCells, draggedCellIndex,
      setProp) => {
    try {
        let nodeData: Element = elements.filter((item: any) => item.id === id)?.[0]
        let node: React.ReactElement
        let component = UxDef[nodeData.name].component
        node = renderElementItself(id, nodeData, component, openEditor, uxToAdd, txToAdd,
                                   action, screenPropsDefault, onEvent, editedElementId, setAction)
        if (["Table", "DateTextBox", "TimeTextBox", "TimeFreeTextBox24"].includes(nodeData.name)) {
            return React.createElement('div', 
            {   onClick: (e: any) => { 
                    e.preventDefault()
                    e.stopPropagation()
                    onEvent({
                        message: "SetEditedElementId",
                        params: {
                            id: nodeData.id
                        }
                    })
                    openEditor()
                }
            }, 
            node)

        } else if (typeof nodeData.void === "undefined" || nodeData.void === false) {
            // Prepare divgrid overlay
            let elementProps= getElementProps(nodeData.props, screenPropsDefault)
            if (!Object.keys(elementProps).includes('style')) {
                elementProps.style = {}
            }
            let overlay: React.ReactElement<any, string | React.JSXElementConstructor<any>>[]
                = (nodeData.label === "divgrid" 
                   && nodeData.id === editedElementId 
                   && openGridCanvas) ? [React.createElement(
                        DivGrid, 
                        {
                            key: `divgrid:${nodeData.id}`,
                            editedElementId: editedElementId,
                            elements: elements,
                            divGridCells: divGridCells,
                            onEvent: onEvent,
                            style: {
                                ...elementProps.style,
                                zIndex: 10000
                            },
                            draggedCellIndex: draggedCellIndex,
                            setProp: setProp
                        }
                    )] : [];

            // Recursively collect children
            let children = overlay.concat(elements?.filter((item: any) => item.parent === id)
            ?.sort((a, b) => (a.seq - b.seq))
            ?.map((item: any) => 
                renderElement(elements, screenPropsDefault, item.id, onEvent, openEditor, 
                  uxToAdd, txToAdd, action, editedElementId, setAction, openGridCanvas,
                  divGridCells, draggedCellIndex, setProp)
            ))

            // Add children back to node
            if (!['input'].includes(nodeData.name)) {
                return React.cloneElement(
                    node, 
                    {}, 
                    React.Children.toArray(node.props.children).concat(children)
                )
            } else {
                return node
            }
        } else {
            return node
        }
        
    } catch (e) {
        console.log(e)
        return React.createElement('div')
    }
}

const renderElementItself:
    (id: number, nodeData: Element, component: any, openEditor: any, uxToAdd: Ux | null, 
      txToAdd: Tx | null, action: string, screenPropsDefault: ScreenPropsDefault,
        onEvent: (e: CreateUIv2Event) => void, editedElementId: number | null, setAction: any) => React.ReactElement
    = (id, nodeData, component, openEditor, uxToAdd, txToAdd, action, 
       screenPropsDefault, onEvent, editedElementId, setAction) => {
    let elementProps= getElementProps(nodeData.props, screenPropsDefault)
    if (!Object.keys(elementProps).includes('style')) {
        elementProps.style = {}
    }
    elementProps.style = {
        ...elementProps.style,
        // border: (typeof editedElementId === 'number' && nodeData.id === editedElementId) ? 
        //         "solid red 1px" : elementProps.style.border || "none"
        ...(typeof editedElementId === 'number' && nodeData.id === editedElementId ? 
                { border: "solid red 1px" } :
            elementProps?.style?.border ?
                { border: elementProps?.style?.border } :
                {}
            )
    }
    return React.createElement(
        component, 
        {   key: id, 
            ...elementProps,
            ...(nodeData.name === "Calendar" ? {localizer} : {}),
            onClick: 
                action === "Delete" ?
                (e: any) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onEvent({
                        message: "RemoveElementAndChildren",
                        params: {
                            id: nodeData.id,
                        }
                    })
                }:
                
                action === "Add" && uxToAdd ?
                (e: any) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onEvent({
                        message: "AddChildElement",
                        params: {
                            parent: nodeData.id,
                            ux: uxToAdd
                        }
                    })
                }:

                action === "AddTx" && txToAdd ?
                (e: any) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onEvent({ 
                        message: "AddTxElement",
                        params: { 
                            parent: nodeData.id,
                            tx: txToAdd
                        }
                    })
                }:

                action === "Move" ?
                (e: any) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onEvent({
                        message: "ChangeParent",
                        params: {
                            parent: nodeData.id,
                        }
                    })
                    // reset action
                    setAction("")
                }:
                
                
                (e: any) => { 
                    e?.preventDefault();
                    e?.stopPropagation();
                    onEvent({
                        message: "SetEditedElementId",
                        params: {
                            id: nodeData.id
                        }
                    })
                    openEditor()
                }
        }
    )
}

const getElementProps = (nodeProps?: { [key: string]: { type: string, value: string, valueEN?: string | null} },
    props?: ScreenPropsDefault) => {
    if (nodeProps) {
        let output: { [key: string]: any } = {}
        for (const key of Object.keys(nodeProps)) {
            if (nodeProps[key].value.trim() !== "") {
                if (nodeProps[key].type === 'value') {
                    try {
                        if (props?.language && props.language === "EN" && nodeProps[key].valueEN) {
                            output[key] = nodeProps[key].valueEN
                        } else {
                            output[key] = nodeProps[key].value
                        }
                        
                    } catch {
                        console.log(`Error evaluating value ${key} ${nodeProps} ${nodeProps[key].value} `)
                    }
                } else if (nodeProps[key].type === 'code' && !["onChange", "onClick", "getTrProps", "getTdProps"].includes(key)) {
                    try {
                        output[key] = eval(`(${nodeProps[key].value})`)
                        if (["options", "data"].includes(key)  && typeof output[key].map !== "function") {
                            output[key] = []
                        }
                    } catch {
                        // console.log(`Error evaluating code ${key} ${nodeProps} ${nodeProps[key].value} `)
                        if (key === "options") {
                            output[key] = null
                        }
                    }
                } else if (nodeProps[key].type === 'code' && ["onChange", "onClick"].includes(key)) {
                    output[key] = () => {}
                } else if (nodeProps[key].type === 'code' && ["getTrProps", "getTdProps"].includes(key)) {
                    output[key] = () => ({})
                } else {
                    if (props?.language && props.language === "EN" && nodeProps[key].valueEN) {
                        output[key] = nodeProps[key].valueEN
                    } else {
                        output[key] = nodeProps[key].value
                    }
                }
            }
        }
        return output
    } else {
        return {}
    }
} 

// if (nodeData.name === 'Segment') {
            
    // } else if (nodeData.name === 'Button') {
    //     node = React.createElement(
    //         component, 
    //         {
    //             key: id, 
    //             ...nodeData.props,
    //             onClick:(e: any) => { 
    //                 e.stopPropagation()
    //                 onEvent({
    //                     message: "SetEditedElementId",
    //                     params: {
    //                         id: nodeData.id
    //                     }
    //                 }) 
    //                 openEditor()
    //             }
                
    //         }, 
    //         'Click')
    // } else {
    //     node = React.createElement('div')
    // }
