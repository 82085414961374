import React from 'react';
import {
  Segment,
  Button,
  Dropdown,
  Input,
} from 'semantic-ui-react'

const Component = (props: any) => {
    return(
    <div>
      <div>
        <div>
          <Button>
            Smart Card
          </Button>
          <Button
            onClick={(e) => { props.setButtonTitle(props.buttonTitle + "A") }}>
            เปิดประวัติฉุกเฉิน
          </Button>
          <Button>
            ลงทะเบียน (visit)
          </Button>
          <Button>
            รับรองสิทธิ
          </Button>
          <Button>
            เอกสารสแกน
          </Button>
          <Button>
            ปกปิดประวัติผู้ป่วย
          </Button>
          <Button>
            พิมพ์บัตรผู้ป่วย
          </Button>
          <Button>
            พิมพ์เอกสาร
          </Button>
          <Button
            color="green">
            บันทึก
          </Button>
        </div>
        <div
          style={{ display: "flex", marginTop: "5px" }}>
          <div
            style={{ width: "150px", height: "200px", border: "solid black 1px" }}>
            
          </div>
          <div
            style={{ width: "800px", backgroundColor: "#044c84" }}>
            
            <div
              style={{ display: "flex", alignItems: "center", padding: "5px" }}>
              <div
                style={{ color: "white", marginRight: "5px" }}>
                HN:
              </div>
              <Input
                size="mini">
              </Input>
              <div
                style={{ color: "white", marginRight: "5px", marginLeft:"5px" }}>
                OPD Visit:
              </div>
              <Input
                size="mini">
              </Input>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", padding: "5px" }}>
              <div
                style={{ color: "white", marginRight: "5px" }}>
                ชื่อ
              </div>
              <Input
                size="mini">
              </Input>
              <div
                style={{ color: "white", marginRight: "5px", marginLeft: "5px" }}>
                ชื่อเดิม
              </div>
              <Input
                size="mini">
              </Input>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", padding: "5px" }}>
              <div
                style={{ color: "white", marginRight: "5px" }}>
                สิทธิการรักษา
              </div>
              <Input
                size="mini">
              </Input>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", padding: "5px" }}>
              <div
                style={{ color: "white", marginRight: "5px" }}>
                เวลานัดวันนี้
              </div>
              <Input
                size="mini">
              </Input>
            </div>
          </div>
        </div>
        <Segment>
          <Button
            color="red"
            size="small">
            Save
          </Button>
          <Button
            color={props.buttonColor}
            size="small">
            Save
          </Button>
          <Dropdown
            options={[{ key:"A", value: "A", text: "A" },{ key:"B", value: "B", text: "B" }]}
            selection={true}>
          </Dropdown>
        </Segment>
        <Segment>
          <Button
            color="red">
          </Button>
        </Segment>
        <div>
          สวัสดี
        </div>
      </div>
    </div>
    )
}

export default Component

export const screenPropsDefault = {"buttonTitle":"yahoo","buttonColor":"black","buttonTitle1":"Hey","name":"Jay Jootar","titleDict":{"title1":"แงๆ"}}
