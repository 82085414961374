export const issue_priority = `
  with priority_deliverable as (
    from priority_list
    left join deliverable
      on priority_list.deliverableId = deliverable.deliverableId
    select
      priority_list.name,
      priority_list.issues,
      priority_list.releases,
      deliverable.deadline_current
    order by deliverable.deadline_current asc    
  ), issue_priority as (
    from issues
    left join priority_deliverable
      on list_contains(priority_deliverable.issues, issues.id)
      or list_contains(priority_deliverable.releases, issues.release_id)
    select
      cast(issues.id as integer) id,
      issues.* exclude (id),
      list((
        priority_deliverable.name,
        priority_deliverable.deadline_current
      )::struct(
        priority_name varchar,
        deadline_current varchar
      )) priority
    group by all
  )
  from issue_priority
  select 
    * exclude(priority),
    list_first(priority).priority_name priority_name, 
    list_first(priority).deadline_current deadline_current,
  ;`;
