import { useEffect, useState } from "react";
import GamePlayer2 from "./GamePlayer2";
import { Modal } from "semantic-ui-react";

export default function IHGamePlay(props: any) {
  let [mode, setMode] = useState("List");
  let [selectedGameId, setSelectedGameId] = useState<string|null>(null);
  let [gamePlayStatus, setGamePlayStatus] = useState<any>(null);

  useEffect(() => {
    props.onEvent({message: "GetIHGameList", params: {all: true}});
  }, []);

  useEffect(() => {
    if (selectedGameId)
      props.onEvent({message: "SelectPublishedIHGame", params: {
        id: selectedGameId, setGamePlayStatus, setMode}});
  }, [selectedGameId])
  
  return(
    <div>
      {mode === "List" ?
      <div style={{padding: "10px"}}>
        {props.ihGameList
        .filter((game: any) => game?.publishUrl)
        .map((game: any) => { 
          let xd = (props.xdmaster?.allXd || []).find((xd: any) => xd?.id === game?.xdId)
          return ({ xdName: xd?.name || "[No XD name]", ...game})
        })
        .sort((a: any, b: any) => `${a.xdName}: ${a?.name}` < `${b.xdName}: ${b?.name}` ? -1 : 1)
        .map((game: any, gameIndex: number) => {
          return (
            <div 
              key={gameIndex}
              onClick={() => {
                if (game?.id) {
                  setSelectedGameId(game.id);
                }
              }}>
              {`[${game.xdName}] ${game?.name}`}
            </div>
          )
        })}
      </div>
      
      :
      <div>
        <div
          style={{width: "100%", borderBottom: "solid grey 1px", cursor: "pointer"}}
          onClick={() => {
            setMode("List");
            setSelectedGameId(null);
          }}>
          &lt;&lt; Back to List
        </div>
        <GamePlayer2
          onEvent={props.onEvent}
          forTest={false}
          selectedIHGame={props.publishedIHGame}
          ihGamePlaySession={props.ihGamePlaySession}
        />
      </div>}   
      <Modal
        size="small"
        open={gamePlayStatus !== null}>
        <div 
          style={{
            minHeight: "300px", overflowY: "auto", textAlign: "center", padding: "20px", 
            display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"
          }}>
          {(gamePlayStatus?.messages || []).map((message: string, index: number) => (
            <div key={index}>{message}</div>
          ))}
        </div>
      </Modal>
    </div>
  )
}
