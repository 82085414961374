import React from 'react'
import UxDef from './UxDef'

const ElementSelector = (props: any) => (
    <div 
        style={{ 
            position: "absolute", 
            top: 30,
            right: 500,
            zIndex: 10002,
            backgroundColor: "white",
            width: "350px", 
            height: "300px",
            border: "solid black 1px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignContent: "flex-start" 
        }}>
            {Object.keys(UxDef).map(ux => (
            <div 
                draggable={true}
                onDragStart={(e) => { 
                    console.log("ondragstart")
                    e.dataTransfer.setData("text", ux) 
                }}
                style={{ 
                    margin: "2px", 
                    border:"solid #cccccc 1px",
                    lineHeight: "30px",
                    height: "30px",
                    padding: "2px",
                    cursor: "pointer"
                }}
                onClick={(e: any) => {
                    props.setUxToAdd(UxDef[ux])
                }}>
                {ux}
            </div>
            ))}
    </div>
)

export default ElementSelector
