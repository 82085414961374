import { useEffect, useState } from "react";
import { Button } from "semantic-ui-react";

export default function NotiTest(props: any) {
  const [noti, setNoti] = useState<any>({});
  const [focusItem, setFocusItem] = useState<any>({});
  
  useEffect(() => {
    if (Object.keys(props.notifications).length > 0) {
      const notiData = Object.fromEntries(
        Object.entries(props.notifications).map((entry: [string, any], index: number) => (
        [ 
          entry[0], // User Email
          {
            summary: {
              unreadCount: Object.values(entry[1]).filter((issue: any) => !issue.read).length
            },
            data: Object.fromEntries(Object.entries(entry[1]).filter((issue: any) => !issue.read))
          }
        ]
      )))
      setNoti(notiData);
    }
  }, [props.notifications]);

  return(
    <div>
      <div style={{display: "flex", flexWrap: "wrap"}}>
        {Object.keys(noti).map((notiId: string, notiIndex: number) => (
          <div 
            key={notiIndex}
            style={{
              padding: "5px", margin: "5px", cursor: "pointer",
              backgroundColor: noti?.[notiId]?.summary?.unreadCount > 0 ? "pink" : "white",
              border: notiId === focusItem?.id ? "solid blue 3px" : "solid black 1px", 
            }}
            onClick={(e: any) => {
              if (focusItem === null || focusItem?.id !== notiId)
                setFocusItem({id: notiId, ...noti?.[notiId]})
              else 
                setFocusItem(null);
            }}>
            {`${notiId} (${noti?.[notiId]?.summary?.unreadCount})`}
          </div>
        ))}
      </div>
      {focusItem && focusItem?.id &&
      <div style={{padding: "10px", height: "60vh", overflow: "auto"}}>
        <div style={{display: "flex", alignItems: "center"}}>
          <span style={{marginRight: "10px"}}>{focusItem.id}</span>
          <Button size="mini" color="brown"
            onClick={(e: any) => {
              props.onEvent({message: "ClearNotifications", 
                params: {notiId: focusItem.id, notiData: props.notifications[focusItem.id]}})
            }}>
            Clear All
          </Button>
        </div>
        {Object.values(focusItem?.data || {})
        .filter((issue: any) => !issue.read)
        .map((issue: any, issueIndex: number) => 
          (<div key={issueIndex}>
            <div style={{fontWeight: "bold"}}>{issue.subject || "[No Subject]"}</div>
            <div style={{paddingLeft: "20px"}}>
              {Object.values(issue.details)
              .filter((detail: any) => !detail.read)
              .sort((a: any, b: any) => a.created_on < b.created_on ? -1 : 1)
              .map((detail:any, detailIndex: number) => (
                <div 
                  key={detailIndex} 
                  style={{
                    display: "flex", justifyContent: "space-between", width: "100%",
                    borderBottom: "solid #cccccc 1px"
                  }}>
                  <div style={{flex: 2}}>{detail.created_on}</div>
                  <div style={{flex: 2}}>{detail.username}</div>
                  <div style={{flex: 2}}>{detail.detail_id}</div>
                  <div style={{flex: 2}}>{detail.issue_id}</div>
                  <div style={{flex: 1}}>{detail.type}</div>
                  <div style={{flex: 1}}>{detail.prop_key}</div>
                  <div style={{flex: 1}}>{detail.value || "N/A"}</div>
                </div>
              ))}
            </div>
          </div>))}
      </div>
      }
    </div>
  )
}
