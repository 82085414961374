import { useEffect, useState } from "react";
import { monthKeys } from "./Utils";

export default function ByStaff(props: any) {
  const [name, setName] = useState("");
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (props.selectedStaffProfile) {
      setName(props.selectedStaffProfile.name || "");
    }
  }, [props.selectedStaffProfile])

  return(
    <>
      <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
        <div
          style={{cursor: "pointer"}}
          onClick={(e: any) => props.setSubMode(null)}>
          &lt;&lt;Back to List
        </div>
        <div style={{marginLeft: "50px", display: "flex"}}>
          <span>&nbsp;ชื่อ</span>
          {editing ?
          <input
            value={name}
            onChange={(e: any) => setName(e.target.value)}
            onKeyUp={(e: any) => {
              if (e.key !== "Enter") return;
              props.onEvent({
                message: "UpdateStaff",
                params: {id: props.selectedStaffProfile?.id, name: name}
              });
              setEditing(false);
            }}
            onBlur={(e: any) => {
              props.onEvent({
                message: "UpdateStaff",
                params: {id: props.selectedStaffProfile?.id, name: name}
              });setEditing(false);
            }}
          />:
          <div 
            style={{marginLeft: "5px", minWidth: "50px", backgroundColor: "#eeeeee"}} 
            onClick={(e: any) => setEditing(true)}>
            {name}
          </div>}
        </div>
      </div>
      <div
        style={{
          height: "850px",
          display: "grid", 
          gridTemplateColumns: (() => { 
            const count = Object.keys(props.staffDetail.year).length + 1;
            return `${100 / count}% `.repeat(count);
          })(),
          gridTemplateRows: `${100 / 13}% `.repeat(13),
        }}>
        {["year"].concat(Object.keys(props.staffDetail.year))
          .map((item: string, index: number) => (
            <div style={{border: "solid black 1px"}} key={index}>
              {item}
            </div>
          ))}
        {monthKeys.flatMap((monthKey: string) => 
          [{[monthKey]: ""}].concat(Object.keys(props.staffDetail.year).map((year: string) => 
            props.staffDetail[monthKey][year] || {}))
        ).map((item: any, index: number) => (
          Object.keys(item).length > 0 ?
          <div style={{border: "solid black 1px", width: "100%", padding: "2px"}} key={index}>
            {Object.entries(item).map((cat: any, catIndex: number) => 
              <div style={{display: "flex", width: "100%", height: "25%"}} key={catIndex}>
                <div style={{flex: 1, fontSize: "0.85rem"}}>{cat[0]}</div>
                <div style={{flex: 1, fontSize: "0.85rem"}}>{cat[1]?.toLocaleString('en-US', {maximumFractionDigits: 0})}</div>
              </div>
            )}
          </div>
          :
          <div style={{border: "solid black 1px", padding: "2px"}} key={index}></div>))
        }
      </div>
    </>
  )
}