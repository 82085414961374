import { useEffect, useRef, useState } from "react";
import { RedmineStatus } from "ismor-lib/utils/Constant";

export default function AssignedPriority(props: any) {
  const [issuePriorityDict, setIssuePriorityDict] = useState<any>({});
  const [priorityIssues, setPriorityIssues] = useState<any[] | null>(null);
  const [mode, setMode] = useState("Priority");

  const initialLoad = useRef(false);

  useEffect(() => {
    if (!initialLoad.current) {
      initialLoad.current = true;
      props.onEvent({message: "GetPriorityDict", params: {}});
    }
  }, []);

  useEffect(() => {
    if (Object.keys(props.priorityDict).length > 0) {
      let issuePriorityDict_ = Object.entries(props.priorityDict)
        .reduce((acc: any, cur: any) => {
          for (const issueId of (cur[1].issues || [])) {
            if (!(issueId in acc))
              acc[issueId] = [];
            if (!(acc[issueId].includes(cur[1].name)))
              acc[issueId].push(cur[1].name);
          }
          return acc
        }, {})
      setIssuePriorityDict(issuePriorityDict_);
    }
  }, [props.priorityDict]);

  useEffect(() => {
    if (Object.keys(props.issueLatestUpdates).length > 0 &&
        Object.keys(issuePriorityDict).length > 0
    ) {
      let assignedIssues_ = Object.values(props.issueLatestUpdates)
        .filter((issue: any) => 
          issue.assign === props.redmineUser
          && ["1", "2", "4"].includes(issue.status_id?.toString()))
        .map((issue: any) => ({
            ...issue,
            priorityNames: (issuePriorityDict?.[issue.id] || []).join(",")
        }));
      setPriorityIssues(assignedIssues_);
    }
  }, [props.issueLatestUpdates, issuePriorityDict]);

  return(
    <div style={{padding: "5px"}}>
      <div style={{display: "flex", borderBottom: "solid grey 1px"}}>
        <div 
          style={{padding: "4px", cursor: "pointer", backgroundColor: mode === "Priority" ? "#eeeeee" : "white"}}
          onClick={(e: any) => setMode("Priority")}>
          Priority
        </div>
        {/* <div 
          style={{padding: "4px", cursor: "pointer", backgroundColor: mode === "Resolved / Not Closed" ? "#eeeeee" : "white"}}
          onClick={(e: any) => setMode("Resolved / Not Closed")}>
          Resolved / Not Closed
        </div> */}
      </div>

      <div style={{height: "90vh", overflowY: "auto"}}>
        {mode === "Priority" ?
        (priorityIssues !== null && priorityIssues.length === 0 ?
        <div 
          style={{
            height: "80vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"
          }}>
          <img src="/sudyod.jpeg" />
        </div>
        :
        priorityIssues !== null ?
        priorityIssues.map((issue: any, issueIndex: number) => (
          <div 
            key={issueIndex}
            style={{
              display: "grid", gridTemplateColumns: "75px 1fr 75px 200px 200px 200px",
              borderBottom: "solid #dddddd 1px"
            }}>
            <div>{issue.id}</div>
            <div>{issue.subject}</div>
            <div>{RedmineStatus?.[issue.status_id] || ""}</div>
            <div>{issue.priorityNames}</div>
            <div>{issue.assign}</div>
            <div>{issue.author}</div>
          </div>
        ))
        :
        <>Loading data. Please wait.</>)
        :
        <></>
        }
      </div>
    </div>
  )
}
