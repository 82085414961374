import React, { useState, useEffect } from "react"
import { Input, Button, Dropdown, Icon } from "semantic-ui-react";

const StepRoleEditor = (props: any) => {

  const [nodeDetail, setNodeDetail] = useState<any>({});

  useEffect(() => {
    if (props.loc?.step?.nodeDetail) {
      setNodeDetail(props.loc.step.nodeDetail)
    }
  }, [props.loc]);

  return <div style={{padding: "5px"}}>
    {props.xddata.nodes.map((node: any, index: number) => (
    <div key={index} style={{display: "flex", alignItems: "center"}}>
      <div style={{width: "200px"}}>{`${node.name} (${node.system})`}</div>
      <Icon name="delete" 
        onClick={(e: any) => {
          delete nodeDetail[node.id];
          setNodeDetail({...nodeDetail});
        }}
      />
      <Input
        style={{width: "400px"}}
        value={
          node.id in nodeDetail ? 
          nodeDetail[node.id].detail : ""}
        onChange={(e: any) => {
          if (!props.xdedit) return;
          if (!(node.id in nodeDetail)) {
            nodeDetail[node.id] = {detail: "", type: Object.keys(nodeDetail).length === 0 ? "editor" : "reviewer"}
          }
          nodeDetail[node.id].detail = e.target.value;
          setNodeDetail({...nodeDetail});
        }}
      />
      <Dropdown 
        selection={true}
        options={nodeDetail[node.id]?.type ? [
          {key: "editor", value: "editor", text: "editor"},
          {key: "reviewer", value: "reviewer", text: "reviewer"}
        ] : []}
        value={nodeDetail[node.id]?.type}
        onChange={(e: any, v: any) => {
          if (!props.xdedit) return;
          if (!(node.id in nodeDetail)) {
            nodeDetail[node.id] = {detail: "", type: "reviewer"}
          }
          nodeDetail[node.id].type = v.value;
          setNodeDetail({...nodeDetail});
        }}
      />
    </div>))}
    <div style={{display: "flex", justifyContent: "flex-end", marginTop: "5px"}}>
      <Button color="red" size="mini" 
        onClick={(e: any) => props.setShowRoleEditor(false)}
        >
        Cancel
      </Button>
      <Button color="green" size="mini" 
        disabled={!props.xdedit}
        onClick={(e: any) => {
          if (!props.xdedit) return;
          props.onEvent({
            message: "ModifyStepRole", 
            params: {loc: props.loc, nodeDetail: nodeDetail}
          });
          props.setShowRoleEditor(false)
        }}>
        Save
      </Button>
    </div>
  </div>
}

export default StepRoleEditor;