/*eslint no-var: 0*/
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/compat/functions";
import "firebase/compat/storage";
import "firebase/compat/analytics";

import * as firebaseui from "firebaseui";
import firebaseConfig from "../../_manager/firebase-config";
import CONFIG from "config/config";

let appFirebase
if (!firebase.apps.length && !CONFIG?.OFFLINE) {
  console.log("Firebase: !!!! src/react-lib/frameworks/Firebase.ts ");

  let firebase_project = CONFIG.FIREBASE_PROJECT;
  console.log("Firebase: CONFIG: ", CONFIG);
  if (firebase_project === "") {
    console.error("Firebase: Not config FIREBASE_PROJECT in base.json");
  }


  console.log("Firebase: firebaseConfig: ", firebaseConfig);
  if (Array.isArray(firebaseConfig)) {
    let config = firebaseConfig.find((item: any) => item.projectId === firebase_project);
    console.log("Firebase: config: ", config);
    if (config) {
      appFirebase = firebase.initializeApp(config);
    } else {
      console.error("Firebase: Can't find suitable config, then use first");
      appFirebase = firebase.initializeApp(firebaseConfig[0]);
    }
  } else {
    // backward complatible
    appFirebase = firebase.initializeApp(firebaseConfig);
  }

}
export var firebaseApp = appFirebase

export { default as app } from "firebase/compat/app";
export var db: firebase.firestore.Firestore | null = !CONFIG?.OFFLINE ? firebase.firestore() : null;
export var analytics: firebase.analytics.Analytics | null = !CONFIG?.OFFLINE ? firebase.analytics() : null;
export var functions: firebase.functions.Functions | undefined | null = 
  !CONFIG?.OFFLINE ? CONFIG?.FIREBASE_FUNCTION_REGION ? firebaseApp?.functions(CONFIG?.FIREBASE_FUNCTION_REGION) : firebaseApp?.functions() : null;
export var storage: firebase.storage.Storage | null = !CONFIG?.OFFLINE ? firebase.storage() : null;
export var auth: firebase.auth.Auth | null = !CONFIG?.OFFLINE ? firebase.auth() : null;
export const ui = !CONFIG?.OFFLINE ? new firebaseui.auth.AuthUI(firebase.auth()) : null;

// ถ้า Function อยู่ที่ ต่าง region จะใช้ 
// export var functions: firebase.functions.Functions | undefined | null = !CONFIG?.OFFLINE ? firebaseApp?.functions('asia-east1') : null; 