import { useEffect, useState } from "react";
import { RedmineStatus } from "ismor-lib/utils/Constant";
import { Button, Icon, Input } from "semantic-ui-react";

export default function XDCaseFormAddorEditProblem(props: any) {
  const [description, setDescription] = useState("");
  const [issues, setIssues] = useState<any[]>([]);
  const [issueIdToRemove, setIssueIdToRemove] = useState<number|null>(null);
  const [newIssueId, setNewIssueId] = useState("");
  const [candidateIssues, setCandidateIssues] = useState<any[]>([]);

  useEffect(() => {
    if (props.problemMode !== "add") {
      setDescription(props.xdProblemDict?.[props.problemItem.id].description || "");
      setIssues(props.xdProblemDict?.[props.problemItem.id]?.issues || []);
    }
  }, [props.problemItem]);

  useEffect(() => {
    refreshCandidateIssues();
  }, [newIssueId]);

  useEffect(() => {
    refreshCandidateIssues();
  }, [props.issueLatestUpdates]);

  const refreshCandidateIssues = () => {
    if (newIssueId.length >= 3 && !Number.isNaN(Number(newIssueId))) {
      let issues = Object.values(props.issueLatestUpdates).filter((issue: any) => (
        issue.id.toString().includes(newIssueId)
      ));
      setCandidateIssues(issues);
    } else {
      setCandidateIssues([]);
    }
  }

  const clearForm = () => {
    props.setProblemMode(null);
    props.setSelectedNode(null);
    props.setSelectedProblemId(null);
    setCandidateIssues([]);
  }

  const addIssueId = (issueId: number) => {
    if (!issues.includes(issueId)) {
      setIssues([...issues, issueId].sort((a: any, b: any) => a < b ? -1 : 1));
      setNewIssueId("");
    }
  }

  const removeIssueId = (issueId: number) => {
    setIssues(issues.filter((id: number) => id !== issueId));
  }

  return(
    <div
      onClick={(e: any) => e.stopPropagation()}>
      <div style={{display: "flex"}}>
        <div style={{flex: 1, alignItems: "center"}}>
          <Input 
            size="mini" fluid
            placeholder="คำอธิบายปัญหา"
            value={description}
            onClick={(e: any) => e.stopPropagation()}
            onChange={(e: any) => {
              setDescription(e.target.value);
            }}
          />
        </div>
        {props.problemMode === "edit" &&
        <Icon 
          name="delete" 
          onClick={() => props?.onRequestDelete?.()}
        />}
      </div>
      {issues
      .sort((a: any, b: any) => (
        props.issueLatestUpdates?.[a]?.deadline_current 
          < props.issueLatestUpdates?.[b]?.deadline_current
          ? -1 : 1
      ))
      .map((issueId: any, issueIndex: number) => (
        <div key={issueIndex}
          style={{
            display: "flex", cursor: "pointer",
            textDecoration: issueId === issueIdToRemove ? "line-through" : "none",
            borderBottom: "solid #cccccc 1px"
          }}>
          <div 
            style={{flex: 1, borderLeft: "solid #cccccc 1px"}}
            >
            <a href={`https://redmine.thevcgroup.com/issues/${issueId}`} target="_blank">
              {`${issueId} (${props.issueTestResult?.[issueId]?.status || "Not tested"})`}
            </a>
          </div>
          <div 
            style={{flex: 4, borderLeft: "solid #cccccc 1px"}}
            onMouseEnter={() => {
              setIssueIdToRemove(issueId);
            }}
            onMouseLeave={() => {
              setIssueIdToRemove(null);
            }}
            onClick={() => {
              removeIssueId(issueId);
              setIssueIdToRemove(null);
            }}
            >
            {props.issueLatestUpdates?.[issueId]?.subject}
          </div>
          <div style={{flex: 1, borderLeft: "solid #cccccc 1px"}}>
            {RedmineStatus?.[props.issueLatestUpdates?.[issueId]?.status_id]}
          </div>
          <div style={{flex: 3, borderLeft: "solid #cccccc 1px"}}>
            {props.issueLatestUpdates?.[issueId]?.priority_name}
          </div>
          <div style={{flex: 1, borderLeft: "solid #cccccc 1px"}}>
            {props.issueLatestUpdates?.[issueId]?.deadline_current}
          </div>
        </div>
      ))}
      <div style={{position: "relative"}}>
        <Input 
          size="mini" fluid
          placeholder="เพิ่ม issue"
          value={newIssueId}
          onChange={(e: any) => {
            setNewIssueId(e.target.value);
          }}
          onKeyUp={(e: any) => {
            if (e.key === "Escape") {
              setNewIssueId("");
              setCandidateIssues([]);
            } else if (e.key === "Enter" && candidateIssues.length === 1) {
              addIssueId(candidateIssues[0].id);
              setNewIssueId("");
              setCandidateIssues([]);
            } else if (e.key === "Enter" && candidateIssues.length === 0) {
              props.onEvent({message: "GetIssueLatestUpdates", params: {issues: [newIssueId]}});
            }
          }}
        />
        {candidateIssues.length > 0 &&
        <div
          style={{
            position: "absolute", top: 30, left: 0, backgroundColor: "white", 
            padding: "5px", border: "solid #cccccc 1px", width: "100%", opacity: 0.9,
            maxHeight: "400px", overflowY: "scroll"
          }}>
          {candidateIssues
          .filter((issue: any) => !issues.includes(issue.id))
          .map((issue: any, issueIndex: number) => (
            <div 
              key={issueIndex} style={{display: "flex"}}
              onClick={() => {
                addIssueId(issue.id)
              }}>
              <div style={{flex: 1}}>{issue.id}</div>
              <div style={{flex: 9}}>{issue.subject}</div>
            </div>
          ))}
        </div>}
      </div>
      <Button
        size="mini" color="red"
        onClick={(e: any) => {
          clearForm();
        }}>
        Cancel
      </Button>
      <Button
        size="mini" color="green"
        onClick={() => {
          props.onEvent({message: "AddOrEditXdProblem", params: {
            mode: props.problemMode,
            xdCaseId: props.xdCaseId,
            seqIndex: props.selectedSeqIndex,
            nodeId: props.selectedNode,
            problemId: props.selectedProblemId,
            description,
            issues
          }});
          clearForm();
        }}>
        Save
      </Button>
    </div>
  )
}