import { WasmHandler } from '../../../react-lib/frameworks/WasmController'
import axios from "axios";

type Handler = WasmHandler<State, Event>

// Spec ---------------------------------------------------------------------
export type State = 
  {
    apiAnalysisData?: any[]
  }

export const StateInitial = 
  {
    apiAnalysisData: []
  }

export type Event = 
  { message: "GetApiAnalysisData", params: {} }


export const GetApiAnalysisData: Handler = async (controller, params) => {
  axios.get(
    `${controller.data.FASTAPI}/api/prometheus/api_analysis1/`,
    {headers: { "Authorization": `Token ${await controller.user.getIdToken()}`}}
  )
  // controller.functions.httpsCallable("getApiAnalysis1")("")
  .then((result: any) => {
    console.log(result)
    controller.setState({ apiAnalysisData: result.data })
  }).catch((error: any) => {
    console.log(error)
    controller.setState({ apiAnalysisData: [
      {
        "key": error.toString(),
        "avg": "",
        "count": "",
        "sum": "",
      }
    ] })
  })
}

