import { useEffect, useState } from "react";

export default function FlowView(props: any) {

  return(
    <div 
      style={{ width: "100%" }}>
      {props.videoGroup.map((group: number[], groupIndex: number) => (
        <div style={{display: "flex", borderBottom: "solid grey 1px"}} key={groupIndex}>
          {group.map((videoId: number, videoIndex: number) => { 
            let items = props.lessonData?.items || [];
            let label = `${items?.[videoId]?.name}`;
            let next = items?.[videoId]?.next || [];
            if (next.length > 0) {
              label += " -> "
              for (var i = 0; i < next.length; i++) {
                label += items?.[next[i]]?.name;
                if (i < next.length - 1) {
                  label += ", "
                }
              }
            }
            return(
              <div key={videoIndex} style={{width: "200px"}}>
                <img   
                  style={{width: "200px", height: "auto"}}
                  src={props.urlList?.[videoId]} 
                />
                <div>{label}</div>
              </div>
            )
          })}
        </div>
      ))}
    </div>
  )
}