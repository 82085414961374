import React, { Component, createRef } from 'react';
import { Button, Menu, Icon } from 'semantic-ui-react';

const cv = (window as any).cv

export default class Camera extends Component<any, 
  {
    camera: boolean,
    capture: boolean,
    video: boolean,
  }
>{

  myRef = createRef<HTMLVideoElement>()
  myCanvas = createRef<HTMLCanvasElement>()
  recordedChunks: any[] = []
  aRef = createRef<HTMLAnchorElement>()

  constructor(props: any) {
    super(props);
    this.state = {
      camera: false,
      capture: false,
      video: false,
    }
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.id = 'opencv';
    script.src = "/opencv.js";
    document.body.appendChild(script);
  }

  getStream = () => {
    if (!this.state.camera) {
      navigator.mediaDevices.getUserMedia({video: {facingMode: "environment"}})
      .then((stream: any) => {
          this.setState({camera: true});
          if (this.myRef?.current) {
            this.myRef.current.srcObject = stream;
            this.myRef.current.play();
          }
      }).catch((err: any) => {
        console.log(err)
      });
    } else {
      this.setState({capture: false});
    }
  }

  capturePic = () => {
    if (this.myRef?.current) {
      // Size of the video from camera
      const settings = (this.myRef.current?.srcObject as MediaStream).getVideoTracks()[0].getSettings();
      
      // Adjust size and capture image
      this.myRef.current.height = this.myRef.current.clientHeight
      this.myRef.current.width = this.myRef.current.clientWidth
      const cv = (window as any).cv;
      var src = new cv.Mat(this.myRef.current.height, this.myRef.current.width, cv.CV_8UC4);
      var cap = new cv.VideoCapture(this.myRef.current);
      cap.read(src)
      
      cv.imshow(this.myCanvas.current, src)
      const link = document.createElement('a');
      link.download = `${new Date()}.png`;
      link.href = this.myCanvas.current?.toDataURL() || "";
      link.click();
      link.remove();
      this.setState({ capture: true });
    }
  }

  videoRecord = () => {
    // console.log("record", this.myRef?.current?.srcObject)
    this.setState({video: true});
    const recorder = new MediaRecorder(
      (this.myRef?.current?.srcObject as MediaStream), 
      MediaRecorder.isTypeSupported("video/webm;codecs=vp9") ?
        { mimeType: "video/webm; codecs=vp9" }
        : { mimeType: "video/mp4; codecs=avc1" }
    );
    recorder.ondataavailable = (e: any) => {
      if (e.data.size > 0) {
        this.recordedChunks.push(e.data)
        this.download()
      }
    }
    recorder.start()
    setTimeout((event: any) => {
      // console.log("stopping");
      recorder.stop();
      this.setState({video: false});
    }, 5000);
  }

  download = () => {
    var blob = new Blob(this.recordedChunks, {
      type: "video/webm"
    });
    var url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `test.${MediaRecorder.isTypeSupported("video/webm;codecs=vp9") ? "webm" : "mp4"}`;
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  }

  render() {
    return (
      <div>
        <div style={{display: "flex"}}>
          <div style={{flex: 1}}></div>
          <div style={{display: "flex"}}>
            {(!this.state.camera || this.state.capture) && !this.state.video &&
            <Button size="mini" color="blue" onClick={(e) => this.getStream()}>Camera</Button>  
            }
            {this.state.camera && !this.state.capture && !this.state.video &&
            <Button size="mini" color="orange" onClick={(e) => this.capturePic()}>Picture</Button>
            }
            {this.state.camera && !this.state.capture && !this.state.video &&
            <Button size="mini" color="green" onClick={(e) => this.videoRecord()}>Video</Button>
            }
            {this.state.video &&
            <div>Wait for Video Recording</div>}
          </div>
          <div style={{flex: 1}}></div>
        </div>
        
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-start"}}>
          <div style={{flex: 1}}></div>
          <video 
            style={{
              width: "80vw",
              display: this.state.capture ? "none" : "block"}}
            ref={this.myRef} autoPlay muted playsInline 
          />
          <canvas ref={this.myCanvas} style={{
            width: "80vw", 
            display: this.state.capture ? "block": "none"}}
          />
          <div style={{flex: 1}}></div>
        </div>
      </div>
    )
  }
}
