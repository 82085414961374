import React, { useState, createRef, useEffect } from 'react'
import { renderPlay } from './CreateUIv2Play'
import { renderTree } from './CreateUIv2Tree'
import { renderJsx, ScreenData } from './CreateUIv2Jsx'
import ElementEditor, { ElementEditorEvent } from './ElementEditor'
import ElementSelector from './ElementSelector'
import TxSelector from './TxSelector'
import ScreenBrowser, { 
    ScreenBrowserState, 
    ScreenBrowserEvent, 
    ScreenBrowserStateInitial 
} from './ScreenBrowser'
import { Checkbox, Icon, Radio } from 'semantic-ui-react'
import { Ux } from './UxDef'
import { Tx } from './TxDef'
import 'react-table-6/react-table.css'
import { addReadOnlyProp } from './Transform';
import * as DivGridI from "./DivGridInterface";
import PropEditor from "./PropEditor";

export type CreateUIv2State = 
    {
        screenId?: string | null,
        screenData?: ScreenData,
        editedElementId?: number | null
        loggedin?: boolean,
        openGridCanvas?: boolean,
    }
    &   ScreenBrowserState
    &   DivGridI.State

export const CreateUIv2StateInitial = {
    screenId: null,
    screenData: { height: 0, width: 0 },
    editedElement: null,
    openGridCanvas: false,
    ...ScreenBrowserStateInitial,
    ...DivGridI.StateInitial
}

export type CreateUIv2Event =
        { message: "CreateUIv2Load", params: {} }
    |   { message: "CreateUIv2SetData", params: { id: string, doc: any } }
    |   { message: "AdjustUIv2Size", params: { axis: string, increase: boolean } }
    |   { message: "SetEditedElementId", params: { id: number } }
    |   { message: "AddChildElement", params: { parent: number, ux: Ux } }
    |   { message: "AddTxElement", params: { parent: number, tx: Tx } }
    |   { message: "RemoveElementAndChildren", params: { id: number } }
    |   { message: "SwapChildren", params: { up: boolean, id: number, parent: number } }
    |   { message: "ChangeParent", params: { parent: number } }
    |   { message: "CopyJsx", params: {} }
    |   { message: "CopyFirebaseToken", params: {} }
    |   { message: "ChangeScreenData", params: { name: string } }
    |   { message: "AddScreen", params: {} }
    |   { message: "DuplicateScreen", params: {} }
    |   { message: "ToggleMemo", params: {} }
    |   { message: "ToggleForwardRef", params: {} }
    |   { message: "ToggleIsMounted", params: {} }
    |   { message: "ImportElementsFromFile", params: { file: File }}
    |   { message: "ImportPicture", params: { file: File, editedElementId: number | null }}
    |   ElementEditorEvent
    |   ScreenBrowserEvent
    |   DivGridI.Event

class CreateUIv2 extends React.Component<
    CreateUIv2State
    &   { 
            user: any,
            db: any,
            onEvent: (e: CreateUIv2Event) => void
            setProp: (key: string, value: any) => void,
        }, 
        {
            firstLoad: boolean,
            trigger: boolean,
            mode: string,
            language: "TH" | "EN",
            openBrowser: boolean,
            openEditor: boolean,
            openPropEditor: boolean,
            openSelector: boolean,
            uxToAdd: Ux | null,
            openTxSelector: boolean,
            txToAdd: Tx | null,
            toCancel: boolean,
            action: string,
            copyJsx: boolean,
            copyToken: boolean,
            saveUpdate: boolean,
            hasError: boolean
        }
    >{

    constructor(props: any) {
        super(props)
        this.state = {
            firstLoad: true,
            trigger: true,
            mode: "Play",
            language: "TH",
            openBrowser: false,
            openEditor: false,
            openPropEditor: false,
            openSelector: false,
            uxToAdd: null,
            openTxSelector: false,
            txToAdd: null,
            toCancel: false,
            action: "",
            copyJsx: false,
            copyToken: false,
            saveUpdate: false,
            hasError: false,
        }
        this.setState = this.setState.bind(this)
        this.setAction = this.setAction.bind(this)
    }

    static getDerivedStateFromError(error: any) {
      return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
      console.log(error);
      console.log(errorInfo);
    }
    
    componentDidMount = () => {
        if (this.props.user && this.props.db) {
            if (this.state.firstLoad || 
                this.props.screenData && Object.keys(this.props.screenData).length === 0) {
                console.log('firstLoad')
                this.refreshData()
            }
        }
    }

    componentDidUpdate = (prevProp: any, prevState: any) => {
        if (prevProp.screenData !== this.props.screenData) {
            if (this.props.screenData && Object.keys(this.props.screenData).length > 0) {
                if (this.props.screenData.elements) {
                    this.setState({ firstLoad: false, hasError: false })
                }
            }
        } else if (prevProp.screenId !== this.props.screenId) {
            this.setState({ openBrowser: false, hasError: false })
            this.props.onEvent({ message: "CreateUIv2Load", params: {} })
        }
    }

    handleClick = (e: any, nodeData: any) => {
        e.stopPropagation()
        console.log(nodeData.name)
    }

    saveScreen = (e: any) => {
        if (this.props.screenId) {
            this.props.onEvent({
                message: "CreateUIv2SetData",
                params: { 
                    id: this.props.screenId,
                    doc: this.props.screenData 
                }
            })
        }
        this.setState({ saveUpdate: true })
        setTimeout(() => {
            this.setState({ saveUpdate: false })
        }, 200)
    }

    refreshData = () => {
        this.props.onEvent({ 
            message: "CreateUIv2Load", 
            params: { email: this.props.user.email } 
        })
    }

    adjustSize = (axis: string, increase: boolean) => {
        this.props.onEvent({ 
            message: "AdjustUIv2Size", 
            params: { axis: axis, increase: increase}
        })
    }

    setAction = (action: string) => {
        this.setState({ action: action })
    }

    tempTx = (tx: Tx) => {
        this.setState({ 
            action: "AddTx",
            txToAdd: tx
        })
    }

    render() {

        let content
        
        if (this.state.hasError) {
          content = <div 
            style={{ 
              height: "100%", 
              display: "flex", 
              flexDirection: "column", 
              justifyContent: "center", 
              alignItems: "center", 
            }}>
            <h2>Something went wrong</h2>
            <h2>Please select another screen</h2>
            <h2>and request delete of screen id</h2>
            <h2>{this.props.screenId}</h2>
          </div>
        } else {
          if (this.state.mode === "Play") {
            content = renderPlay(
              this.props.screenData || {},
              this.props.onEvent,
              () => this.setState({ openEditor: true }),
              this.state.uxToAdd,
              this.state.txToAdd,
              this.state.action,
              typeof this.props.editedElementId === "number" ? 
                  this.props.editedElementId : null,
              this.setAction,
              this.props.openGridCanvas,
              this.props?.divGridCells || [],
              this.props?.draggedCellIndex || null,
              this.props?.setProp)
          } else if (this.state.mode === "Tree") {
            content = renderTree(
              this.props.screenData, 
              this.props.onEvent,
              () => this.setState({ openEditor: true }),
              this.state.uxToAdd,
              this.state.txToAdd,
              this.state.action,
              typeof this.props.editedElementId === "number" ? 
                  this.props.editedElementId : null,
              this.setAction,
              this.tempTx)
          } else if (this.state.mode === "Jsx") {
            content = renderJsx(
              this.props.screenData || {})
          }
        }
        
        return (
            <div 
                style={{ 
                    position: "relative",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                }}
            >
                <div style={{ display: "flex", backgroundColor: "#fee6c8" }} >
                    <MenuItem
                        onClick={(e: any) => { this.setState({ openBrowser: !this.state.openBrowser }) }}
                        children={<Icon name="folder"/>}
                        hilight={this.state.openBrowser}
                    />

                    <MenuItem
                        onClick={(e: any) => { 
                            this.props.onEvent({ message: "AddScreen", params: {} })
                        }}
                        children={<Icon name="add"/>}
                    />

                    <MenuItem
                        onClick={this.saveScreen}
                        children="Save"
                        hilight={this.state.saveUpdate}
                    />
                    
                    <MenuItem
                        onClick={(e: any) => { this.refreshData() }}
                        children={<Icon name="refresh"/>}
                    />

                    {this.props.screenId &&
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <ScreenDataEditor
                            title="Name"
                            dataKey="name"
                            value={this.props.screenData?.name}
                            onEvent={this.props.onEvent}
                        />
                        <div>{`${"\u00a0".repeat(10)}`}</div>
                        <ScreenDataEditor
                            title="Project"
                            dataKey="project"
                            value={this.props.screenData?.project}
                            onEvent={this.props.onEvent}
                        />
                        <div>{`${"\u00a0".repeat(10)}`}</div>
                        <div>ID: {this.props.screenId}</div>
                    </div>
                    }

                    <MenuItem
                        onClick={(e: any) => { 
                            this.props.onEvent({ message: "DuplicateScreen", params: {} })
                        }}
                        children="Duplicate"
                    />
                   
                    <div style={{ display: "flex", alignItems: "center", marginLeft: "10px"}}>
                        <div style={{ marginRight: "5px" }}>Import</div>
                        <input 
                            type="file" 
                            onClick={(e: any) => { e.target.value = null }}
                            onChange={(event) => {
                                this.props.onEvent({ 
                                    message: "ImportElementsFromFile", 
                                    params: { 
                                        file: event?.target?.files?.[0]!,
                                    }
                                })
                            }}
                        />
                    </div>
                    <div
                      style={{ 
                        backgroundColor: "pink",
                        paddingLeft: "5px",
                        paddingRight: "5px", 
                        display: "none",
                        cursor: "pointer"
                      }}
                      onClick={(e: any) => { 
                          addReadOnlyProp(this.props.screenData, this.props.setProp);
                      }}>
                      Transform
                    </div>

                    <div style={{ flex: 1 }}/>

                    <MenuItem
                        onClick={(e: any) => { 
                            this.setState({ 
                                mode: "Play",
                            })
                        }}
                        hilight={this.state.mode === 'Play'}
                        children="Play"
                    />
                    <MenuItem
                        onClick={(e: any) => { 
                            this.setState({ 
                                mode: "Tree",
                            })
                        }}
                        hilight={this.state.mode === 'Tree'}
                        children="Tree"
                    />
                    <MenuItem
                        onClick={(e: any) => { 
                            this.setState({ 
                                mode: "Jsx" 
                            })
                        }}
                        hilight={this.state.mode === 'Jsx'}
                        children="Jsx"
                    />

                    <MenuItem
                        onClick={(e: any) => { 
                            this.props.onEvent({
                                message: "CopyJsx",
                                params: {}
                            })
                            this.setState({ copyJsx: true })
                            setTimeout(() => {
                                this.setState({ copyJsx: false })
                            }, 200)
                        }}
                        hilight={this.state.copyJsx}
                        children="JsxCopy"
                    />

                    {/* Move to Top bar */}
                    {/* <MenuItem
                        onClick={(e: any) => { 
                            this.props.onEvent({
                                message: "CopyFirebaseToken",
                                params: {}
                            })
                            this.setState({ copyToken: true })
                            setTimeout(() => {
                                this.setState({ copyToken: false })
                            }, 200)
                        }}
                        hilight={this.state.copyToken}
                        children="Token"
                    /> */}
                </div>

                <div style={{ display: "flex", backgroundColor: "#89CFF0" }} >
                    <MenuItem
                        onClick={(e: any) => { this.adjustSize("width", false)}}
                        children="W-"
                    />
                    <MenuItem
                        onClick={(e: any) => { this.adjustSize("width", true)}}
                        children="W+"
                    />
                    <MenuItem
                        onClick={(e: any) => { this.adjustSize("height", false)}}
                        children="H-"
                    />
                    <MenuItem
                        onClick={(e: any) => { this.adjustSize("height", true)}}
                        children="H+"
                    />
                    
                    <MenuItem
                        onClick={(e: any) => {
                            this.setState({
                                openSelector: this.state.action === "Add" && this.state.uxToAdd ? false :
                                                !this.state.openSelector,
                                uxToAdd: null,
                                action: this.state.action === "Add" && this.state.uxToAdd ? "" : 
                                            this.state.action
                            })
                        }}
                        hilight={this.state.action === "Add"}
                        children={<Icon name="plus"/>}
                    />
                    <MenuItem
                        onClick={(e: any) => {
                            this.setState({
                                openTxSelector: this.state.action === "AddTx" && this.state.txToAdd ? false :
                                                !this.state.openTxSelector,
                                txToAdd: null,
                                action: this.state.action === "AddTx" && this.state.txToAdd ? "" : 
                                            this.state.action
                            })
                        }}
                        hilight={this.state.action === "AddTx"}
                        children={<Icon name="bookmark"/>}
                    />
                    <MenuItem
                        onClick={(e: any) => {
                            this.setState({
                                action: this.state.action === "Delete" ? "" : "Delete",
                            })
                        }}
                        hilight={this.state.action === "Delete"}
                        children={<Icon name="cancel"/>}
                    />
                    <MenuItem
                        onClick={(e: any) => {
                            this.setState({
                                action: typeof this.props.editedElementId === "number" ?
                                            (this.state.action === "Move" ? "" : "Move") :
                                            this.state.action 
                            })
                        }}
                        hilight={this.state.action === "Move"}
                        children={<Icon name="move"/>}
                    />

                    <MenuItem
                        onClick={(e: any) => {
                            this.setState({
                                openEditor: !this.state.openEditor
                            })
                        }}
                        hilight={this.state.openEditor}
                        children={<Icon name="edit outline"/>}
                    />

                    <MenuItem
                        onClick={(e: any) => {
                            this.setState({
                                openPropEditor: !this.state.openPropEditor
                            })
                        }}
                        hilight={this.state.openPropEditor}
                        children={<Icon name="suitcase"/>}
                        style={{ marginRight: "20px" }}
                    />

                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Radio label="TH"
                            checked={this.props.screenData?.screenPropsDefault?.language==="TH"}
                            value="TH"
                            onChange={(e: any, v: any) => 
                                this.props.setProp("screenData.screenPropsDefault.language", v.value)}
                            style={{marginRight: "5px"}}
                        />
                        <Radio label="EN"
                            checked={this.props.screenData?.screenPropsDefault?.language==="EN"}
                            value="EN"
                            onChange={(e: any, v: any) => 
                                this.props.setProp("screenData.screenPropsDefault.language", v.value)}
                            style={{marginRight: "5px"}}
                        />
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                            checked={this.props.screenData?.memo || false}
                            onClick={(e) => {
                                this.props.onEvent({
                                    message: "ToggleMemo",
                                    params: {}
                                })
                            }}
                        />
                        <label style={{ marginLeft: "5px" }}>Memo</label>
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                            checked={this.props.screenData?.forwardRef || false}
                            onClick={(e) => {
                                this.props.onEvent({
                                    message: "ToggleForwardRef",
                                    params: {}
                                })
                            }}
                        />
                        <label style={{ marginLeft: "5px" }}>forwardRef</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                            checked={this.props.screenData?.isMounted || false}
                            onClick={(e) => {
                                this.props.onEvent({
                                    message: "ToggleIsMounted",
                                    params: {}
                                })
                            }}
                        />
                        <label style={{ marginLeft: "5px" }}>isMounted</label>
                    </div>

                    {/* <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{marginLeft: "10px", marginRight: "5px"}}>
                            displayName
                        </div>
                        <input
                            value={this.props.screenData?.displayName || ""}
                            onChange={(e: any) => 
                                this.props.setProp("screenData.displayName", e.target.value || "")
                            } 
                        />
                    </div> */}
                </div>

                {/* Dialog screens */}
                {this.state.openBrowser &&
                <ScreenBrowser
                    selectedProject={this.props.selectedProject}
                    projectScreens={this.props.projectScreens}
                    onEvent={this.props.onEvent}
                />
                }

                {this.state.openSelector &&
                <ElementSelector 
                    setUxToAdd={(uxToAdd: Ux) => { 
                        this.setState({
                            action: "Add", 
                            uxToAdd: uxToAdd,
                            openSelector: false
                        })
                    }}
                />}

                {this.state.openTxSelector &&
                <TxSelector 
                    setTxToAdd={(txToAdd: Tx) => { 
                        this.setState({
                            action: "AddTx", 
                            txToAdd: txToAdd,
                            openTxSelector: false
                        })
                    }}
                />}

                {typeof this.props.editedElementId === 'number' && 
                ["Tree", "Play"].includes(this.state.mode) &&
                <ElementEditor
                    openEditor={this.state.openEditor}
                    screenData={this.props.screenData}
                    editedElementId={this.props.editedElementId}
                    onEvent={this.props.onEvent}
                    setProp={this.props.setProp}
                    openGridCanvas={this.props.openGridCanvas || false}
                    mode={this.state.mode}
                    divGridCells={this.props.divGridCells || []}
                />}

                {["Tree", "Play"].includes(this.state.mode) && this.state.openPropEditor &&
                <PropEditor
                    screenData={this.props.screenData}
                    onEvent={this.props.onEvent}
                    setProp={this.props.setProp}
                />}

                {/* Main content */}
                {this.state.mode === "Play" ?
                <div 
                    style={{ 
                        width: `${this.props.screenData?.width || '0'}%`,
                        height: `${this.props.screenData?.height || '0'}%`,
                        maxHeight: "80vh",
                        overflow: "scroll",
                        border: "solid blue 0.5px"
                    }}
                >{content}
                </div>:
                
                this.state.mode === "Tree" ?
                <div style={{ height: "800px", overflowY: "scroll", borderBottom: "solid #cccccc 1px" }}>
                    {content}
                    <div style={{ height: "100px" }}/>
                </div>:

                this.state.mode === "Jsx" ?
                <textarea 
                    style={{ width: "100%", height: "90%" }} 
                    value={content?.toString()} 
                    readOnly
                />:
                
                <div></div>}
                
            </div>
        );
    }
}

export default CreateUIv2

export const MenuItem = (props: any) => (
    <div 
        style={{ 
            cursor: "pointer", 
            backgroundColor: props.hilight ? "#81C32E" : "#cccccc", 
            padding: "2px", 
            margin: "2px",
            ...props.style}}
        onClick={props.onClick}
    >{props.children}</div>
)

const ScreenDataEditor = (props: any) => {
    const [editing, setEditing] = useState(false)
    const [value, setValue] = useState(props.value)

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    // console.log(value)
    // console.log(props.dataKey)

    return(
    <div
        style={{ display: "flex "}}
        onClick={(e) => { setEditing(true) }}
    >
        <div>{props.title}: </div>
        <input
            hidden={!editing}
            ref={(input) => { if (input != null) { input.focus() }}}
            onBlur={(e: any) => { 
                setEditing(false)
                props.onEvent({ 
                    message: "ChangeScreenData", 
                    params: { [props.dataKey]: value }
                })
            }}
            onChange={(e: any) => { setValue(e.target.value) }}
            value={value} 
        />
        <div 
            hidden={editing}
        >
            {value}
        </div>
    </div>
    )
}

// export type ScreenData = {
//     name?: string,
//     project?: string,
//     height?: number,
//     width?: number,
//     elements?: Element[],
//     screenPropsDefault?: ScreenPropsDefault
// }

// export type ScreenPropsDefault = { [key: string]: any }

// export type Element = {
//     id: number,
//     name: string
//     parent: number,
//     seq: number,
//     props?: { [key: string]: { type: string, value: string } },
// }