// const config = {
//     apiKey: "AIzaSyDm1idj40-LiS8YOVDBWoTYfD2h3tDpoyY",
//     authDomain: "static-hosting-3f3a6.firebaseapp.com",
//     databaseURL: "https://static-hosting-3f3a6.firebaseio.com",
//     projectId: "static-hosting-3f3a6",
//     storageBucket: "static-hosting-3f3a6.appspot.com",
//     messagingSenderId: "366154374577"
// };

export const adminConfig = {
  apiKey: "AIzaSyDEmGWY7TdbhwWHR54QXoznZyGSzrbkRdI",
  authDomain: "ismoradmin.firebaseapp.com",
  projectId: "ismoradmin",
  storageBucket: "ismoradmin.appspot.com",
  messagingSenderId: "6381112548",
  appId: "1:6381112548:web:b727222d7097099e4570f1",
  measurementId: "G-GS6GDXGJ7D"
}

export const webConfig = {  
  apiKey: "AIzaSyAxwlYswURPdWSOYNaa3nQ7NGV24szjZXg",
  authDomain: "everymorian.firebaseapp.com",
  projectId: "everymorian",
  storageBucket: "everymorian.appspot.com",
  messagingSenderId: "1029431375416",
  appId: "1:1029431375416:web:ea0a48016628b8ecb398cd"
}

let config = {
  apiKey: "AIzaSyDm1idj40-LiS8YOVDBWoTYfD2h3tDpoyY",
  authDomain: "static-hosting-3f3a6.firebaseapp.com",
  databaseURL: "https://static-hosting-3f3a6.firebaseio.com",
  projectId: "static-hosting-3f3a6",
  storageBucket: "static-hosting-3f3a6.appspot.com",
  messagingSenderId: "366154374577",
  appId: "1:366154374577:web:b00372a6b45660f6",
  measurementId: "G-BDWJR616ZN"
}
  
export default config
