import { type } from "./FormDef";

export default function RenderSourceCode(formDef: any) {
  const pdfData = convertDataForPDFMake(formDef, true);
  pdfData.__meta.props = ["header", "footer"];
  pdfData.header = "header";
  pdfData.footer = "footer";
  const output = 
  `export default function toPDFMakeData(props: any) {
  return (\n`   
  + RenderSourceCodeDetail(pdfData)
  + `)\n}\n\n`
  return  output
}

export const convertDataForPDFMake = (data_: any, keepMeta: boolean = false) => {
  if (type(data_) !== "object") {
    if (!Number.isNaN(Number(data_))) {
      return Number(data_)
    } else{
      return data_
    }
  }
  let {__meta, ...data} = data_;
  if (keepMeta) data = data_;
  if (Object.keys(data).includes("__items")) {
    return data.__items.map((item: any) => convertDataForPDFMake(item, keepMeta));
  }
  let convertedData: {[key:string]: any} = {};
  for (const key of Object.keys(data)) { 
    if (type(data[key]) !== "object") {
      if (!keepMeta && __meta.props.includes(key)) {
        convertedData[key] = `[${data[key]}]`
      } else {
        if (key !== "width")
          convertedData[key] = data[key]
        else {
          if (!Number.isNaN(Number(data[key])))
            convertedData[key] = Number(data[key])
          else
            convertedData[key] = data[key]
        }
      }
    } else {
      convertedData[key] = convertDataForPDFMake(data[key], keepMeta);
    }
  }
  return convertedData;
 }

const RenderSourceCodeDetail: (obj: any, dataKey?: string, tab?: number, inProps?: boolean) => string 
= (obj, dataKey="", tab=0, inProps=false) => {

  const t = type(obj);

  const thisTab = " ".repeat(tab);

  if (t==="array")
    return "[\n" 
    + obj.map((item: any, index: number) => 
              thisTab + " "
              + RenderSourceCodeDetail(item, dataKey + `.${index}`, tab+1)).join(",\n")
    + "\n" + thisTab + "]"
  
  else if (t!=="object") 
    return (
      inProps ? `props.${obj.toString().trim()}` 
      : typeof obj === "string" ? `\`${obj.toString()}\`` 
      : obj.toString())
  
  else
    return "{\n"
    + Object.keys(obj)
      .filter((key: string) => key !== "__meta")
      .map((key: string) => 
        thisTab + " " + key 
        + ": " 
        + (key === "images" ? "props.images"
           : RenderSourceCodeDetail(obj[key], dataKey + `.${key}`, tab+1, obj.__meta.props.includes(key)))
      ).join(",\n")
    + "\n" + thisTab + "}"
}

// + JSON.stringify(pdfData, null, 2)
  //       .split("\n")
  //       .map((line: string) => "  " + line)
  //       .join("\n")