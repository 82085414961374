import React from 'react'
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'

export type MyBPlusState = {
    myBPlusStats?: {
        date: string,
        "users created": number,
        "activated patients": number,
        "cummurative users": number,
        "cummurative activated patients": number
    }[]
}

export const MyBPlusStateInitial = {
    stats: []
}

export type MyBPlusEvent = 
        { message: "MyBPlusDidMount", params: {} }

export default class MyBPlus extends React.Component<
    MyBPlusState & { onEvent: (e: MyBPlusEvent) => void }, 
    any> {

    constructor(props: any) {
        super(props)
        this.state = MyBPlusStateInitial
    }

    componentDidMount = () => {
        this.props.onEvent({ message: "MyBPlusDidMount", params: {} })
    }

    render = () => {
        console.log(this.props.myBPlusStats)
        return(
        <div>
            <div>MyBPlus</div>
            <ReactTable 
                columns={[
                    { Header: "name" },
                    { Header: "surname" }
                ]}
            />
            {this.props.myBPlusStats?.map(((row, row_index) => (
            <div key={row_index}>
                
                {row_index === 0 &&
                <div style={{ display: "flex" }}>
                    {Object.keys(row).map((header, header_index) => (
                    <div key={header_index} style={{ flex: 1 }}>{header}</div>
                    ))}
                </div>}

                <div style={{ display: "flex" }}>
                    {Object.values(row).map((col, col_index) => (
                    <div key={col_index} style={{ flex: 1 }}>{col}</div>
                    ))}
                </div>

            </div>
            )))}
        </div>
        )
    }
}