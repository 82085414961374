import {
    Pic2UIState,
    Pic2UIEvent
} from './Pic2UI'
import axios from 'axios'
import React from 'react'

import { WasmHandler } from '../../../react-lib/frameworks/WasmController'

type Handler = WasmHandler<Pic2UIState, Pic2UIEvent>

export const LoadPic2UI: Handler = async (controller, params) => {
    const IMGURL =  "https://firebasestorage.googleapis.com/" + 
        "v0/b/static-hosting-3f3a6.appspot.com/o/uigen.png" + 
        "?alt=media&token=e3362a95-894b-491a-8375-d5c970d2840c"
    const result = await axios.get(IMGURL, { responseType: 'arraybuffer'}).catch(err => console.log(err))
    if (result) {
        controller.setState({ uiPic: result.data})
        const buf = Buffer.from(result.data, 'binary')
        const picUrl = URL.createObjectURL(new Blob([buf]))
        if (params.ref && params.ref.current) {
            params.ref.current.src = picUrl
        }
    } else {
        console.log("result none")
    }
}

export const ProcessPic2UI: Handler = async (controller, params) => {
    const arrayBuffer = await readFileAsync(params.file) as ArrayBuffer
    processPic(arrayBuffer, params.ref)
}

// Utils --------------------------------------------------------------------------------------------------
const readFileAsync = (file: File) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    })
}

const processPic = (arrayBuffer: ArrayBuffer, ref: React.RefObject<HTMLImageElement>) => {
    const picUrl = URL.createObjectURL(new Blob([arrayBuffer]))
    if (ref && ref.current) {
        ref.current.src = picUrl
    }
}