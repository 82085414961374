declare global {
  interface Window {
    python: any;
  }
}

export const loadPythonWorker = async (
  name: string,
  setLoaded: any,
  setError: any,
  verbose: boolean = true,
  initScript: string,
) => {
  if (typeof(Worker) === "undefined") 
    return console.log("Worker unavailable in the environment.");

  if (typeof(window?.python) === "undefined")
    window.python = {};

  if (typeof(window?.python?.[name]) !== "undefined") {
    console.log(`An instance of Python Worker with name=${name} is already present.`)
    setLoaded(true);
    return window.python[name];
  }
  
  const pythonWorker = new Worker("/js/pythonworker.js");

  pythonWorker.onerror = (e: any) => {
    console.log(`Error in pyodideWorker at ${e.filename}, Line: ${e.lineno}, ${e.message}`);
    setError(e);
  };
  pythonWorker.onmessage = (e: any) => {
    const {results, error, id} = e.data;
    if (results) {
      if (id === "initScript") {
        console.log(`Python Worker loaded. Initial script executed. `);
        setLoaded(true);
        return
      }
      if (window.python[name].verbose)
        console.log(`id: ${id}\nreturn: ${JSON.stringify(results)}`);
      window.python[name].runCallback[id].resolve(JSON.parse(results));
      // setResults(JSON.parse(results));
    } else if (error) {
      if (window.python[name].verbose)
        console.log(`id: ${id}\nerror: ${JSON.stringify(error)}`);
      window.python[name].runCallback[id].reject(error);
      // setError(error);
    }   
  };
  window.python[name] = {
    worker: pythonWorker,
    run: (id: string, params: any, script: string) => {
      return new Promise((resolve, reject) => {
        window.python[name].runCallback[id] = {resolve, reject};
        let script_lines = script.split("\n");
        let firstline = script_lines[1];
        const spacesAtStart = firstline.length - firstline.trimLeft().length;
        let script_ = script_lines
                      .map((line: string) => line.substring(spacesAtStart))
                      .join("\n");
        pythonWorker.postMessage({id: id, python: script_, ...params});
      });
    },
    runCallback: {},
    verbose: verbose,
  };
  
  pythonWorker.postMessage({
    id: "initScript",
    python: initScript
  });
  return window.python[name];
};

export const loadPythonMainThread = async (
  setLoaded: any,
  setError: any,
  initScript: string,
) => {
  let output = await (new Promise((resolve, reject) => {
    let script = document.createElement("script");
    script.setAttribute("src", "https://cdn.jsdelivr.net/pyodide/v0.24.1/full/pyodide.js");
    document.body.appendChild(script);
    script.addEventListener("load", async () => {
      const pyodide = await (window as any).loadPyodide().catch((e: any) => reject(e));
      await pyodide.loadPackage(["numpy", "pytz", "pandas", "micropip", "bokeh"]);
      await pyodide.runPythonAsync(initScript);
      console.log("Python on Main Thread loaded. Initial script executed");
      setLoaded(true);
      resolve({
        pyodide: pyodide,
        run: (params: any, script: string) => {
          return new Promise(async (resolve, reject) => {
            (window as any).pyParams = params;
            let script_lines = script.split("\n");
            let firstline = script_lines[1];
            const spacesAtStart = firstline.length - firstline.trimLeft().length;
            let script_ = script_lines
                          .map((line: string) => line.substring(spacesAtStart))
                          .join("\n");
            pyodide.runPythonAsync(script_)
              .then((results: any) => {
                if (typeof results !== "undefined")
                  resolve(JSON.parse(results));
              })
              .catch((e: any) => reject(e));
          });
        },
      });
    });
  })).catch((e: any) => setError(e));
  return output;
}

export const loadBokehJS = async (setLoaded: any) => {
  let url_list = [
    "https://cdn.bokeh.org/bokeh/release/bokeh-3.2.2.min.js",
    "https://cdn.bokeh.org/bokeh/release/bokeh-gl-3.2.2.min.js",
    "https://cdn.bokeh.org/bokeh/release/bokeh-widgets-3.2.2.min.js",
    "https://cdn.bokeh.org/bokeh/release/bokeh-tables-3.2.2.min.js",
    "https://cdn.bokeh.org/bokeh/release/bokeh-mathjax-3.2.2.min.js",
    "https://cdn.bokeh.org/bokeh/release/bokeh-api-3.2.2.min.js",
  ]
  for (const url of url_list) {
    let result = await new Promise((resolve: any) => {
      let script = document.createElement("script");
      script.setAttribute("src", url);
      document.body.appendChild(script);
      script.addEventListener("load", async () => {
        resolve(url);  
      });
    });
  }
  setLoaded(true);
}
