import { useEffect, useRef, useState } from "react";
import { query } from "ismor-lib/utils/DuckLib";
import { Button } from "semantic-ui-react";

export default function XDJourney(props: any) {
  const [xdId, setXdId] = useState<string|null>(null);

  // Core data for display --------------------------------------------
  // Generated sequence of display items
  const [displayData, setDisplayData] = useState<any[]>([]);
  // Current sequence of display item
  const [displaySeq, setDisplaySeq] = useState<number | null>(null);
  // Pause status
  const [pause, setPause] = useState(true);
  const pauseRef = useRef(true);
  
  useEffect(() => {
    if (props.xdJourneyDataState !== "success" || !props.duckCon) return;
    prepareDisplayData();
  }, []);
  
  useEffect(() => {
    if (props.xdId !== xdId) {
      setXdId(props.xdId);
      setDisplayData([]);
      setPause(true);
      pauseRef.current = true;
    }
  }, [props.xdId]);

  useEffect(() => {
    if (props.xdJourneyDataState !== "success" || !props.duckCon) return;
    prepareDisplayData();
  }, [props.xdJourneyDataState]);

  useEffect(() => {
    pauseRef.current = pause;
    if (!pause && displaySeq !== null) {
      runDisplayShow(displaySeq);
    }
  }, [pause]);

  const prepareDisplayData = async () => {
    // Now just create displayData from doc directly
    // TODO: Add sequence that helps explanation such as role, etc
    try {
      let res = await query(`
      from stepdoc
      left join step
        on stepdoc.stepId = step.stepId
      left join step_section 
        on stepdoc.stepId = step_section.stepId
      order by sectionSeq, stepSeq, docSeq
      ;`, 
      props.duckCon);
      let sectionLabel: string | null = null;
      let res1 = res.reduce((acc: any[], cur: any) => {
        let currentSectionLabel = `${cur?.sectionSeq + 1}.${cur?.stepSeq + 1}`;
        if (sectionLabel === null || sectionLabel !== currentSectionLabel) {
          acc.push({
            type: "step",
            sectionSeq: cur?.sectionSeq,
            stepSeq: cur?.stepSeq,
            detail: cur?.description,
          });
          sectionLabel = currentSectionLabel;
        }
        acc.push(cur);
        return acc
      }, []);
      // console.log(res1);
      setDisplayData(res1);
      setDisplaySeq(0);
    } catch (e: any) { console.log(e); }
  }

  const runDisplayShow = (currentSeq: number) => {
    if (currentSeq < displayData.length - 1) {
      setTimeout(() => {
        if (!pauseRef.current)
          setDisplaySeq(Math.min(currentSeq + 1, displayData.length));
      }, 1000);
    }
  }

  return(
    <div 
      style={{
        width: "100%", height: "90vh", display: "flex", 
        flexDirection: "column", justifyContent: "center", alignItems: "center"
      }}>
      {props.xdJourneyDataState === "success" && Number.isInteger(displaySeq) && displaySeq !== null &&
      <>
        <div style={{display: "flex"}}>
          <Button 
            size="mini" 
            onClick={() => {
              if (pause) {
                setPause(false)
              } else {
                setPause(true);
              }
            }}>
            {`${pause ? "Next" : "Pause"}`}
          </Button>
          <div>{`${displaySeq} / ${displayData.length}`}</div>
        </div>
        <div style={{flex: 1, width: "100%"}}>
          <DisplayItemView
            toDownloadUrl={props.toDownloadUrl}
            displayItem={displayData?.[displaySeq]}
            displaySeq={displaySeq}
            runDisplayShow={runDisplayShow}
            pause={pause}
          />
        </div>
      </>
      }
    </div>    
  )
}

function DisplayItemView(props: any) {
  const [url, setUrl] = useState("");
  const [height, setHeight] = useState("auto");
  const [width, setWidth] = useState("auto");
  const divRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    setUrl("");
    if (props.displayItem?.type === "image") {
      (async () => {
        setUrl(await toDocUrl(props.displayItem));
      })();
    } else {
      nextSeq();
    }
  }, [props.displayItem]);

  const handleImageLoad = () => {
    if (imageRef.current && divRef.current) {
      let {naturalWidth, naturalHeight} = imageRef.current;
      let {clientWidth, clientHeight} = divRef.current;
      if (naturalHeight > 0 && clientHeight > 0) {
        if (naturalWidth/naturalHeight > clientWidth/clientHeight) {
          setWidth(`${clientWidth}px`);
          setHeight("auto");
        } else {
          setWidth("auto");
          setHeight(`${clientHeight}px`)
        }
      }
    }
    nextSeq();
  }

  const nextSeq = () => {
    if (!props.pause && props.displaySeq !== null)
    props.runDisplayShow(props.displaySeq);
  }

  const toDocUrl = async (displayItem: any) => {
    return await props.toDownloadUrl(
      `gs://ismor-xd/xdstep2/${displayItem.xdId}/${displayItem.stepId}/${displayItem.nodeId}/images/${displayItem.docId}.png`)
  }

  return(
    <div 
      ref={divRef}
      style={{
        border: "solid black 1px", height: "87vh", width: "100%", overflowY: "hidden",
        display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column",
      }}>
      {props.displayItem?.type === "text" ?
      <div 
        style={{
          fontSize: "2rem", 
          lineHeight: "2.5rem",
          padding: "50px", 
          textAlign: "center"
        }}>
        <div>
          {`${props.displayItem?.sectionSeq + 1}.${props.displayItem?.stepSeq + 1}`}
        </div>
        <div>
          {props.displayItem?.detail}
        </div>
      </div>
      : props.displayItem?.type === "step" ?
      <div 
        style={{
          width: "100%", height: "100%",
          display: "flex", 
          justifyContent: "center", alignItems: "center",
          fontSize: "2rem", 
          lineHeight: "2.5rem",
          padding: "50px", 
          textAlign: "center",
          backgroundColor: "lightblue"
        }}>
        <div>
          {`${props.displayItem?.sectionSeq + 1}.${props.displayItem?.stepSeq + 1}`}
        </div>
        <div>
          {props.displayItem?.detail}
        </div>
      </div>
      : props.displayItem?.type === "image" && url !== "" ?
      <img 
        ref={imageRef}
        style={{width: width, height: height}} 
        onLoad={handleImageLoad}
        src={url} 
      />
      : props.displayItem?.type === "image" && url === "" ?
      <div>Loading image</div>
      :
      <></>}
    </div>
  )
}
