import { useRef, useEffect } from "react";

export default function BokehDocChart(props: any) {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.chartData && Object.keys(props.chartData).length > 0 && chartRef.current) {
      try {
        let Bokeh = (window as any).Bokeh;
        chartRef.current.innerHTML = "";
        Bokeh.embed.add_document_standalone(props.chartData, chartRef.current);
      } catch (e: any) { console.log(e); }
      
    }
  }, [props.chartData]);

  return(
    <div ref={chartRef} />
  )
}
