import { useEffect, useRef, useState } from "react";
import { Button, Modal, Icon } from "semantic-ui-react";
import { loadPythonMainThread } from "ismor-lib/utils/Python";

export default function DealDocument(props: any) {
  const [fileSelected, setFileSelected] = useState(false);
  const [uploadFile, setUploadfile] = useState<File | null>(null);
  const [docName, setDocName] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);

  // Hook (PythonMainThread) testing
  const [pythonMainThreadLoaded, setPythonMainThreadLoaded] = useState(false);
  const [pythonMainThreadError, setPythonMainThreadError] = useState<any>(null);
  const [testString, setTestString] = useState("------> String initially set from React");
  const pythonMainThread = useRef<any>(null);
  const pythonMainThreadTesting = false;
  const hookTesting = false;

  // Worker testing
  const passingArrayBufferTesting = false;
  const returnValueTesting = false;
  const bokehTesting = false;
  const chartRef = useRef<any>(null);
  const [pdfExtract, setPdfExtract] = useState<any>(null);

  useEffect(() => {
    if (pythonMainThreadTesting)
      (async () => {
        pythonMainThread.current = 
        await loadPythonMainThread(setPythonMainThreadLoaded, setPythonMainThreadError, `
        import pandas
        import micropip
        True
        `);
      })();
  }, []);

  useEffect(() => {
    (async () => {
      if (pythonMainThreadTesting && pythonMainThreadLoaded) {
        if (hookTesting)
          await testHook(pythonMainThread, setTestString);
      }
    })();
  }, [pythonMainThreadLoaded]);

  useEffect(() => {
    (async () => {
      if (bokehTesting && props.pythonWorkerLoaded && props.bokehLoaded)
        await testBokeh(props.pythonWorker, chartRef);
      if (passingArrayBufferTesting)
        await testPassingArrayBuffer(props.pythonWorker);
      if (returnValueTesting)
        await testReturnValue(props.pythonWorker);
    })();
  }, [props.pythonWorkerLoaded, props.bokehLoaded])

  useEffect(() => {
    if (pythonMainThreadError) console.log(pythonMainThreadError)
  }, [pythonMainThreadError]);

  useEffect(() => {
    if (props.pythonWorkerError) console.log(props.pythonWorkerError)
  }, [props.pythonWorkerError]);

  useEffect(() => {
    setUploading(false);
  }, [props.selectedDeal]);

  if (hookTesting)
    console.log(testString);

  return(
    <div style={{padding: "5px"}}>
      {(Object.values(props.selectedDeal?.documents || {}))
        .sort((a: any, b: any) => a.name < b.name ? -1 : 1)
        .map((doc: any, docIndex: number) => (
        <div 
          key={docIndex} 
          style={{
            cursor: "pointer", display: "flex", alignItems: "center", 
            borderBottom: "solid #eeeeee 1px"
          }}>
          <Icon
            name="cloud download"
            color="green"
            onClick={async (e: any) => {
              window.open(await props.getDocumentUrl(doc.url), "_blank");
            }}
          />
          {props.pythonWorkerLoaded &&
          <Icon name="expand arrows alternate"
            color="blue" 
            disabled={!doc.name?.includes(".pdf")}
            onClick={async (e: any) => {
              if (props.pythonWorkerLoaded) {
                const result = await props.pythonWorker.run("ExtractPDF", { 
                }, `
                import micropip
                await micropip.install("PyPDF2")
                from PyPDF2 import PdfReader
                from pyodide.http import pyfetch
                from PIL import Image
                import io
                import json
                import base64
                
                res = await pyfetch("${await props.getDocumentUrl(doc.url)}")
                data = await res.bytes()
                reader = PdfReader(io.BytesIO(data))
                pages = reader.pages    
                text = ""
                images_base64 = []
                for index, page in enumerate(pages):
                    text += page.extract_text()
                    images = page.images
                    for image in images:
                        image_file = io.BytesIO(image.data)
                        img_str = base64.b64encode(image_file.getvalue()).decode()
                        images_base64.append(img_str)
                json.dumps({
                  "text": text,
                  "images": images_base64,
                })
                `
                ).catch((err: any) => console.log(err));
                setPdfExtract(result);
              }
            }}
          />}
          <div style={{flex: 1}}>{doc.name}</div>
        </div>
      ))}

      {uploading ? <div style={{color: "red"}}>Please wait while uploading file...</div> : <></>}
      
      <div style={{display: "flex"}}>
        {/* Step 1 select file */}
        <input 
          type="file"
          name="เลือกไฟล์"
          disabled={uploading}
          onClick={(e: any) => e.target.value = null}
          onChange={async (e: any) => {
            if (e.target?.files?.[0]) {
              setUploadfile(e.target.files[0]);
              setDocName(e.target.files[0].name)
              setFileSelected(true);
            }
          }}
        />
        {/* Step 2 name the doc */}
        <div>
          {fileSelected &&
            <input 
              placeholder="ตั้งชื่อไฟล์"
              value={docName || ""}
              onChange={(e: any) => {
                setDocName(e.target.value);
              }}
            />
          }
        </div>
        
        {/* Step 3 upload */}
        <div>
          {fileSelected && uploadFile && docName && docName !== "" &&
            <Button size="mini" color="blue"
              onClick={async (e: any) => {
                const result = await new Promise((resolve, reject) => {
                  let reader = new FileReader();
                  reader.onload = () => {
                    resolve(reader.result);
                  };
                  reader.onerror = reject;
                  reader.readAsArrayBuffer(uploadFile);
                });
                props.onEvent({
                  message: "UploadDealDocument", 
                  params: {
                    selectedDeal: props.selectedDeal,
                    docName: docName,
                    data: result
                  }
                });
                setFileSelected(false);
                setUploadfile(null);
                setDocName(null);
                setUploading(true);
              }}>
              Save
            </Button>
          }
        </div>
      </div>

      {pdfExtract?.text?.length > 0 &&
      <textarea
        style={{width: "100%", height: "80vh"}}
        value={pdfExtract.text}
        onChange={(e: any) => {}}
      />}

      {pdfExtract?.images?.length > 0 &&
      <div>
        <div>Image</div>
        {pdfExtract.images.map((image: any, imageIndex: number) => 
          <img key={imageIndex} style={{width: "100%"}} src={`data:image/jpeg;base64,${image}`}/>
        )}
      </div>}

      {props.bokehLoaded &&
      <div id="myplot" ref={chartRef}></div>
      }
    </div>
  )
}

// Python testing script ------------------------------------------------------------------------

const testHook = async (pythonMainThread: any, setTestString: any) => {
  const result = await pythonMainThread.current.run({
    setTestString
  }, `
  from js import pyParams
  pyParams.setTestString("------> String set from python via hook")
  True
  `);
};

const testPassingArrayBuffer = async (pythonWorker: any) => {
  let url = "https://firebasestorage.googleapis.com/v0/b/ismor-xd/o/xdstep2%2F5ZmkFlb7ScWyHf0HR4v7%2F09uRg9mmwF3pX7tEnpDc%2F0%2Fimages%2F1.png?alt=media&token=d0cbcae1-dfcd-4060-bdc4-b95a6de5f6c4&_gl=1*fxnqdv*_ga*MjA4MjI1NDkxNy4xNjkzNzkxNzQ2*_ga_CW55HF8NVT*MTY5NjY0MTEwMy4xNDMuMS4xNjk2NjQxMTI2LjM3LjAuMA..";
  let graphData = await new Promise((resolve) => {
    var img = new Image();
    img.setAttribute("crossorigin", "anonymous");
    img.onload = async () => {
      const canvas = document.createElement("canvas");
      canvas.width = 100;
      canvas.height = 100;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(img, 0, 0);
      const dataUrl = canvas.toDataURL()
      const result = await fetch(dataUrl);
      const data = await result.arrayBuffer();
      resolve(data);
    };
    img.src = url;
  });
  console.log(graphData);
  const result = await pythonWorker.run("arrayBuffer", {
    graphData
  }, `
  from js import pyParams
  print(pyParams.graphData)
  True
  `);
};

const testReturnValue = async (pythonWorker: any) => {
  const result = await pythonWorker.run("returnValue", {
  }, `
  import json
  json.dumps({'value': 'This string is returned from Python'})
  `).catch((e: any) => console.log(e));
  console.log(result);
}

const testBokeh = async (pythonWorker: any, chartRef: any) => {
  const result = await pythonWorker.run("testBokeh", {
  }, `  
  import micropip
  await micropip.install("bokeh")
  import json
  import bokeh
  from io import StringIO
  from bokeh.plotting import figure, output_file, save
  from bokeh.io import curdoc
  from bokeh.embed import json_item, components

  # prepare some data
  x = [1, 2, 3, 4, 5]
  y = [4, 5, 5, 7, 2]

  # set output to static HTML file
  output_file(filename="custom_filename.html", title="Static HTML file")

  # create a new plot with a specific size
  p = figure(sizing_mode="stretch_width", max_width=500, height=250)

  # add a circle renderer
  circle = p.circle(x, y, fill_color="red", size=15)
  json.dumps(json_item(p))
  `).catch((e: any) => console.log(e));
  chartRef.current.innerHTML = "";
  (window as any).Bokeh.embed.embed_item(result, "myplot");
}

// const [showWebviewMessages, setShowWebviewMessages] = useState(false);
{/* 
{props.webviewReady &&
<Button size="mini" color="green"
  onClick={async (e: any) => {
    if (props.webviewReady && props.selectedDeal?.documents?.[0]) {
      props.setProp("webviewMessages", []);
      (window as any).pywebview.api.onEvent({
        message: "ExtractDealDocument", 
        params: { 
          url: await props.getDocumentUrl(doc.url), 
          name: `${props.selectedDeal.clientName} - ${props.selectedDeal.dealName} - ${doc.name}`
        }
      });
      setShowWebviewMessages(true);
    }
  }}>
  Extract (IsMor Plus)
</Button>} 
*/}
{/* 
<Modal
  size="tiny"
  open={showWebviewMessages}
  closeOnDimmerClick={true}
  onClose={() => setShowWebviewMessages(false)}>
  <div style={{padding:"15px"}}>
    {props.webviewMessages.map((message: string, index: number) =>
    <div key={index} style={{padding: "5px"}}>
      {message}
    </div>)}
  </div>
</Modal> 
*/}