import { WasmHandler } from 'react-lib/frameworks/WasmController';
import axios from "axios";
import app from "firebase/compat/app";

export type State = {
  selectedStory?: any,
  storyMaster?: any,
  imageSearchList?: string[],
}

export const StateInitial: State = {
  selectedStory: { items: [] },
  storyMaster: {},
  imageSearchList: [],
}

export type Event =
  { message: "GetStoryMaster", params: {} }
  | { message: "UpdateStory", params: { data: {items: any[]}} }
  | { message: "SearchImage", params: { keyword: string, page?: number } }

export type Data = {
}

export const DataInitial = {
}
  
type Handler = WasmHandler<State, Event>

const docId = "5FezqiPTfqSTHtFlH79X";

const StoryItemDefCustom: {[name: string]: any} = {
  Rectangle: {
    group: "Shape",
    custom: true,
    propDef: {
      color: {type: "choice", choices: ["green", "red", "blue"], default: "green"},
      shape: {type: "choice", choices: ["circle", "square"], default: "square"},
      size: {type: "number", default: 100}
    }
  },
  Circle: {
    group: "Shape",
    custom: true,
    propDef: {
      color: {type: "choice", choices: ["green", "red"], default: "green"},
      shape: {type: "choice", choices: ["circle", "square"], default: "square"},
      size: {type: "number", default: 100}
    }
  },    
}

export const GetStoryMaster: Handler = async (controller, params) => {
  let storyResult = await controller.db.collection("story").doc(docId).get();
  let storyData = storyResult.data();
  let storyMasterResult = await controller.db.collection("cache").doc("story").get();
  let storyMasterData = storyMasterResult.data() || {};
  controller.setState({
    selectedStory: storyData, 
    storyMaster: {...StoryItemDefCustom, ...storyMasterData}
  });
}

export const SearchImage: Handler = async (controller, {keyword, page}) => {
  if (keyword === "") return;
  axios.get(
    `${controller.data.FASTAPI}/api/penta/google/?keyword=${keyword.replaceAll(" ", "+")}`, 
    {headers: { "Authorization": `Token ${await controller.user.getIdToken()}`}}
  )
  .then((result: any) => {
    controller.setState({imageSearchList: result.data.image_list});
  }).catch((error: any) => {
    console.log(error);
  })
  
  // const imgdata: Blob = await (new Promise((resolve) => {
  //   const img_ = new Image();
  //   img_.crossOrigin="anonymous";
  //   img_.onload = async (ev) => {
  //     const canvas = document.createElement("canvas");
  //     canvas.width = img_.naturalWidth;
  //     canvas.height = img_.naturalHeight
  //     canvas.getContext("2d")?.drawImage(img_ as CanvasImageSource, 0, 0, img_.naturalHeight, img_.naturalWidth);
  //     const data = await (await fetch(canvas.toDataURL())).blob();
  //     resolve(data);
  //   }
  //   img_.src = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsT9ksB5RXARB4phFkDrCK_Psou2cVNVhUgi2EnFpozrENMVZda9bfGoMZsK0&s"
  // }));
  // console.log(imgdata);
  // controller.storage.refFromURL("gs://ismor-xd/storymaster/test.png").put(imgdata);

  
}

export const UpdateStory: Handler = async (controller, {data}) => {
  await controller.db.collection("story").doc(docId).update(data);
  controller.setState({
    selectedStory: { ...controller.getState().selectedStory, ...data}
  });
}
