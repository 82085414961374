import { ScreenBrowserState, ScreenBrowserEvent } from './ScreenBrowser'

import { WasmHandler } from '../../../react-lib/frameworks/WasmController'

type Handler = WasmHandler<ScreenBrowserState, ScreenBrowserEvent>

export const ScreenBrowserDidLoad: Handler = (controller, params) => {
    // Use cache mechanism instead
    // controller.db
    //     .collection("uigen2screen")
    //     .get()
    //     .then(res => {
    //         // let cache = Object.fromEntries(res.docs.map(doc => { 
    //         //     const cachedata = doc.data();
    //         //     return [
    //         //         doc.id,
    //         //         { 
    //         //             name: cachedata.name,
    //         //             project: cachedata.project 
    //         //         }
    //         //     ]
    //         // }));
    //         // controller.db
    //         //     .collection("cache")
    //         //     .doc("uigen2screen")
    //         //     .set(cache);
    //         let data: { [project: string]: any } = {}
    //         res.docs.forEach(item => {
    //             const project = item.data().project
    //             if (!Object.keys(data).includes(project)) {
    //                 data[project] = []
    //             }
    //             data[project].push({ id: item.id, name: item.data().name })
    //         })
    //         for (const project of Object.keys(data)) {
    //             data[project] = data[project].sort((a: any, b: any) => a.name < b.name ? -1 : 1)
    //         }
    //         controller.setProp("projectScreens", data)
    //     })
    //     .catch(err => {
    //         console.log(err)
    //     });
    controller.db
        .collection("cache")
        .doc("uigen2screen")
        .get()
        .then(res => {
            const raw: any = res.data();
            let data: { [project: string]: any } = {}
            Object.entries(raw).forEach((entry: any) => {
                // console.log(entry);
                if (!Object.keys(data).includes(entry[1].project)) {
                    data[entry[1].project] = []
                }
                data[entry[1].project].push({id: entry[0], name: entry[1].name});
            });
            for (const project of Object.keys(data)) {
                data[project] = data[project].sort((a: any, b: any) => a.name < b.name ? -1 : 1)
            }
            controller.setProp("projectScreens", data);
        })
        .catch(err => {
            console.log(err);
        });
}

export const SetScreenId: Handler = (controller, params) => {
    controller.setProp("screenId", params.id);
    controller.db.collection("preferences")
        .doc(controller.user.email)
        .update({
            screenId: params.id
        })
}