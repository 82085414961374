import React, { useState, useEffect } from "react";
import { Input, Button } from "semantic-ui-react";

const Contest = (props: any) => {
    const [start, setStart] = useState(false);
    const [nt, setNt] = useState<NodeJS.Timeout | null>(null);
    const [hilight, setHilight] = useState(false);

    useEffect(() => {
        setHilight(true);
        setTimeout(() => {
            setHilight(false);
        }, 1000)
    }, [props.scoringList])

    useEffect(() => {
        if (start) {
            props.onEvent({
                message: "GetScoringContest",
                params: {}
            });
            const nt_ = setInterval(() => {
                console.log("run");
                props.onEvent({
                    message: "GetScoringContest",
                    params: {}
                });
            }, 10000);
            setNt(nt_);
        } else {
            clearInterval(nt as NodeJS.Timeout);
        }
    }, [start])

    const scoreSort = props.scoringList
                        .map((item: any) => item.count)
                        .sort((a: number, b: number) => a > b ? -1 : 1);
    const ranking = props.scoringList.map((item: any) => scoreSort.indexOf(item.count) + 1);

    return(
    <div>
        <div style={{ display: "flex ", justifyContent: "center" }}>
            <Button 
                color={start ? "red": "green"}
                onClick={(e: any) => {
                    setStart(!start);
                }}>
                {start ? "Pause": "Start"}
            </Button>
            <Button color="blue"
                onClick={(e: any) => {
                    props.onEvent({
                        message: "SaveContest",
                        params: {}
                    })
                }}>
                Save
            </Button>
        </div>
        <div style={{ display: "flex", width: "100%", marginTop: "10px" }}>
            {[0, 1, 2, 3].map((index: number) => 
            <div 
                key={index} 
                style={{ 
                    flex: 1, 
                    display: "flex", 
                    flexDirection: "column",
                    backgroundColor: hilight ? "#c0ffcc" : "white"
                }}>
                <div style={{ display: "flex" }}>
                    <Input
                        placeholder="กรอก HN"
                        style={{ flex: 1 }}
                        value={props.hnList[index]}
                        onChange={(e:any, v: any) => {
                            props.setProp(`hnList.${index}`, e.target.value);
                        }}
                    />
                    
                </div>

                {props.scoringList[index]?.count > 0 &&
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <h1 style={{ margin: 0 }}>{`Team ${String.fromCharCode(65 + index)} #${ranking[index]}`}</h1>
                    <h2 style={{ margin: 0 }}>{`Scoring: ${props.scoringList[index].count}`}</h2>
                </div>}

                <div>{props.scoringList[index]?.note}</div>

                <div>
                {props.scoringList[index]?.tables.map((table: string, index: number) => (
                    <div key={index}>{table}</div>
                ))}
                </div>
            </div>)}
        </div>
    </div>)
}

export default Contest