import { useEffect, useRef, useState } from "react";

const MUL = 7

export default function ExploreDb(props: any) {
  const [mode, setMode] = useState("Tree");
  const [selectedNodeName, setSelectedNodeName] = useState<string | null>(null);
  const [selectedEdgeIndex, setSelectedEdgeIndex] = useState<number | null>(null);
  const [plotData, setPlotData] = useState<any>({nodeDict: {}, edges: [], levels: []});
  const topRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    if (props.data?.length > 0) {
      let plotData_: any = { nodeDict: {}, edges: [], levels: [] };
      
      // Set initial nodeDict
      if (selectedNodeName === null) {
        plotData_.nodeDict = Object.fromEntries(
          JSON.parse(JSON.stringify(props.data))
            .map((node: any, index: number) => ([
              node.name, 
              {
                ...node, 
                y: index, 
                x: 0,
                width: `${node.name} (${node.rank})`.length * MUL, 
              }
            ])));
      } else {
        plotData_.nodeDict = Object.fromEntries(
          JSON.parse(JSON.stringify(props.data))
            .map((node: any, index: number) => ([
              node.name, 
              {
                ...node, 
                y: index, 
                x: 0,
                width: `${node.name} (${node.rank})`.length * MUL, 
              }
            ]))
            .filter((entry: [string, any]) => (
              entry[0] === selectedNodeName ||
              entry[1].to.includes(selectedNodeName) ||
              entry[1].from.includes(selectedNodeName)
            ))
            .map((entry: [string, any], index: number) => {
              if (entry[0] === selectedNodeName)
                return [
                  entry[0], 
                  { ...entry[1], y: index }
                ]
              else if (entry[1].from.includes(selectedNodeName)) {
                return [
                  entry[0],
                  { ...entry[1], from: [selectedNodeName], to: [], y: index }
                ]
              } else {
                return [
                  entry[0],
                  { ...entry[1], from: [], to: [selectedNodeName], y: index }
                ]
              }
            }));
      }

      // Create initial levels from nodeDict
      plotData_.levels = Object.values(plotData_.nodeDict)
        .map((node: any, index: number) => (
          {
            y: index,
            node_names: [node.name],
            maxX: node.width,
            rank: node.rank,
          }
        ));

      // Set color
      let currentColor = "lightgreen";
      for (var i=0; i < plotData_.levels.length; i++) {
        if (i > 0 && plotData_.levels[i].rank > plotData_.levels[i-1].rank) {
          currentColor = currentColor === "lightgreen" ? "lightblue" : "lightgreen";  
        } 
        plotData_.nodeDict[plotData_.levels[i].node_names[0]].color = currentColor;
      }

      // Rearrange level and modify nodeDict accordingly
      let targetY = 0;
      let sourceY = 1;
      let currentRank = plotData_.levels[0].rank;
      while (sourceY < plotData_.levels.length) {
        if (targetY === sourceY) {
          // console.log(1);
          sourceY += 1;
        } else if (plotData_.levels[sourceY].node_names.length === 0) {
          // console.log(2);
          sourceY += 1;
        } else if (plotData_.levels[sourceY].rank > currentRank) {
          // console.log(3);
          currentRank = plotData_.levels?.[sourceY].rank;
          if (plotData_.levels[targetY].node_names.length > 0) {
            targetY += 1;
          }
        } else if (plotData_.levels[targetY].node_names.some((name: any) => 
            plotData_.nodeDict[plotData_.levels[sourceY].node_names[0]].to.includes(name))
        ) {
          // console.log(4);
          targetY += 1;
        }  else if (
          ( (plotData_.nodeDict[plotData_.levels[sourceY].node_names[0]].to.length > 5) ||
            (plotData_.nodeDict[plotData_.levels[sourceY].node_names[0]].from.length > 5)
          ) && plotData_.levels[targetY].node_names.length > 0
        ) {
          // console.log(5);
          targetY += 1;
          if (targetY === sourceY) {
            targetY += 1;
            sourceY = targetY + 1;
          }
        } else {
          // console.log(6);
          let nodeNameToMove = plotData_.levels[sourceY].node_names[0];
          let display = `${plotData_.levels[sourceY].node_names[0]} (${plotData_.levels[sourceY].rank})`
          let newMaxX = plotData_.levels[targetY].maxX +  display.length * MUL;
          if (newMaxX > 900) {
            targetY += 1;
          } else {
            plotData_.nodeDict[nodeNameToMove].y = targetY;
            plotData_.nodeDict[nodeNameToMove].x = plotData_.levels[targetY].maxX;
            plotData_.levels[targetY].node_names.push(nodeNameToMove);
            plotData_.levels[targetY].maxX = newMaxX;
            plotData_.levels[sourceY].node_names = [];
            plotData_.levels[sourceY].maxX = 0;
            sourceY += 1;
          }
        }
      }
      plotData_.levels = plotData_.levels.filter((level: any) => level.node_names.length > 0);

      // Create edges from nodeDict
      plotData_.edges = Object.values(plotData_.nodeDict)
        .flatMap((node: any) => (
          node.from.map((dep: string) => ([
            { 
              x: plotData_.nodeDict[node.name].x + plotData_.nodeDict[node.name].width / 2, 
              y: plotData_.nodeDict[node.name].y 
            }, 
            { 
              x: plotData_.nodeDict[dep].x + plotData_.nodeDict[dep].width / 2, 
              y: plotData_.nodeDict[dep].y 
            },
          ]))
        ));

      // setPlotData
      
      setPlotData(JSON.parse(JSON.stringify(plotData_)));
      topRef.current?.scrollIntoView();
    }
  }, [props.data, selectedNodeName]);

  return(
    <div style={{height: "90vh"}}>
      <div style={{display: "flex", backgroundColor: "white", borderBottom: "solid #cccccc 1px"}}>
        <div 
          style={{
            paddingLeft: "5px", paddingRight: "5px", color: "black",            
            backgroundColor: mode === "Tree" ? "#cccccc" : "white"
          }}
          onClick={(e: any) => setMode("Tree")}>
          Tree
        </div>
        {/* <div 
          style={{
            paddingLeft: "5px", paddingRight: "5px", color: "black",
            backgroundColor: mode === "JSON" ? "#cccccc" : "white"
          }}
          onClick={(e: any) => setMode("JSON")}>
          JSON
        </div> */}
      </div>
      <div style={{height: props.streamlit ? "600px" : "90vh", overflowY: "auto"}}>
        <div ref={topRef}></div>
        {mode === "Tree" ?
        <svg
          width="100%"
          style={{backgroundColor: "white"}}
          viewBox={`0 0 900 ${Math.max(30*plotData.levels.length, 400)}`}>
          {Object.values(plotData.nodeDict).map((node: any, index: number) => (
            <g key={index}>
              <foreignObject
                x={`${node.x}`}
                y={node.y * 30}
                width={`${node.width - 5}`} height="20">
                <div
                  style={{
                    backgroundColor: node.name === selectedNodeName ? "pink" : node.color, 
                    height: "100%", color: "black", 
                    fontFamily: "Courier New", fontSize: "0.75rem"
                  }}
                  onClick={(e: any) => {
                    if (selectedNodeName === node.name)
                      setSelectedNodeName(null);
                    else 
                      setSelectedNodeName(node.name);
                  }}>
                  {`${node.name} (${node.rank})`}
                </div>
              </foreignObject>
            </g>
          ))}
          {plotData.edges.map((edge: any, edgeIndex: number) => (
            <g key={edgeIndex}>
              <line
                key={`${edgeIndex}_1`}
                x1={edge[0].x}
                y1={edge[0].y * 30 + 20}
                x2={edge[0].x}
                y2={edge[1].y * 30 - 5}
                style={{
                  stroke: edgeIndex === selectedEdgeIndex ? "red" : "#cccccc", 
                  strokeWidth: edgeIndex === selectedEdgeIndex ? 2 : 0.5, 
                }}
                onMouseOver={(e: any) => setSelectedEdgeIndex(edgeIndex)}
                onMouseOut={(e: any) => setSelectedEdgeIndex(null)}
              />
              <line
                key={`${edgeIndex}_2`}
                x1={edge[0].x}
                y1={edge[1].y * 30 - 5}
                x2={edge[1].x}
                y2={edge[1].y * 30 - 5}
                style={{
                  stroke: edgeIndex === selectedEdgeIndex ? "red" : "#cccccc", 
                  strokeWidth: edgeIndex === selectedEdgeIndex ? 2 : 1, 
                }}
                onMouseOver={(e: any) => setSelectedEdgeIndex(edgeIndex)}
                onMouseOut={(e: any) => setSelectedEdgeIndex(null)}
              />
              <line
                key={`${edgeIndex}_3`}
                x1={edge[1].x}
                y1={edge[1].y * 30 - 5}
                x2={edge[1].x}
                y2={edge[1].y * 30}
                style={{
                  stroke: edgeIndex === selectedEdgeIndex ? "red" : "#cccccc", 
                  strokeWidth: edgeIndex === selectedEdgeIndex ? 2 : 1, 
                }}
                onMouseOver={(e: any) => setSelectedEdgeIndex(edgeIndex)}
                onMouseOut={(e: any) => setSelectedEdgeIndex(null)}
              />
            </g>
          ))}
        </svg>
        : mode === "JSON" ?
        <textarea
          readOnly
          style={{width: "100%", height: "100%"}}
          value={JSON.stringify(plotData, null, 1)}
        />
        :
        <></>
        }
      </div>
      
    </div>
  )
}