import { ExchangeDiagramData, NodeDetailDocItem, NodeDetailValue, Step } from "./TypeDef"
import ReactDOMServer from "react-dom/server";
import { Document, Packer, Paragraph, TextRun, ImageRun, PageBreak, 
  HeadingLevel, AlignmentType } from "docx";
import sizeOf from "buffer-image-size";
import { Handler } from "./XDInterface"
import { genSVG } from "./XDGraph";
import { genGrid } from "./XDGrid";

const SpecRender: Handler = async (controller: any, params: any) => {
  let graphData = await SpecRenderGraph(params);
  let _ = await SpecRenderGrid(params);
  await SpecRenderFinal(controller, {...params, graphData});
}

const SpecRenderGraph = async (params: any) => {
  const view = params.view;
  const height = params.view.sectionViews
                  .map((view: any) => view.height)
                  .reduce((acc: number, cur: number) => acc + cur, 0);
  const svg = genSVG({
      layout: params.layout,
      xddata: params.xddata,
      sections: params.sections,
      moveLine: false,
      showSlim: true,
      sectionRefs: [],
      foldedSections: [],
      stepRefs: {},
    }, view, height, () => {}, "", null, null, null, () => {}, () => {}, 
    () => {}, null, () => {}, null, null, () => {}, null, () => {}, null, () => {}, false, () => {});

  // Gen HTML
  let svgText = ReactDOMServer.renderToString(
    <html>
      <body style={{margin: 0}}>
        <div style={{width: "100%", height: "100vh", margin: 0}}>
          {svg}
        </div>
      </body>
    </html>
  );
  svgText = svgText.replace('<svg ','<svg xmlns="http://www.w3.org/2000/svg" ')
  svgText = svgText.replaceAll('<div','<div xmlns="http://www.w3.org/1999/xhtml" ', )
  let fileName = `${params.xddata.name}-Graph.html`;
  if (!params.role) {
    if (params.webviewReady) {
      await (window as any).pywebview.api.onEvent({
        message: "SpecRenderGraph", 
        params: {fileName, content: svgText}
      });
    } else {
      const link = document.createElement('a');
      link.download = fileName;
      link.href = URL.createObjectURL(new Blob(
        [svgText], 
        {type: "text/html"})
      );
      link.click();
    }
  }

  // Gen PNG for later use in Docx
  svgText = ReactDOMServer.renderToString(svg);
  svgText = svgText.replace('<svg ','<svg xmlns="http://www.w3.org/2000/svg" ')
  svgText = svgText.replaceAll('<div','<div xmlns="http://www.w3.org/1999/xhtml" ', )

  const svgDataBase64 = btoa(unescape(encodeURIComponent(svgText)));
  const svgDataUrl = `data:image/svg+xml;charset=utf-8;base64,${svgDataBase64}`
  
  let graphData = await new Promise((resolve) => {
    var img = new Image();
    img.onload = async () => {
      const canvas = document.createElement("canvas");
      canvas.width = params.layout.width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(img, 0, 0);
      const dataUrl = canvas.toDataURL()
      const result = await fetch(dataUrl);
      
      // Download as image file (skip for now cuz hard to read anyway)
      // const link = document.createElement('a');
      // link.download = `${params.xddata.name}-Graph.png`;
      // link.href = dataUrl
      // link.click();
      
      // Send data to be packed into Word
      const data = await result.arrayBuffer();
      resolve(data);
    };
    img.src = svgDataUrl;
  });
  return graphData;
}

const SpecRenderGrid = async (params: any) => {
  // Generate html
  let grid = genGrid("html", {    
    cols: params.cols,
    xddata: params.xddata,
    showTool: false,
    view: params.view,
    sections: params.sections,
    sectionRefs: [],
    foldedSections: []
  }, () => {}, null, null, () => {}, () => {}, null, () => {}, null, () => {});
  let svgText = ReactDOMServer.renderToString(
    <html>
      <body style={{margin: 0}}>
        <div style={{width: "100%", height: "100vh", margin: 0}}>
          {grid}
        </div>
      </body>
    </html>
  );
  let fileName = `${params.xddata.name}-Grid.html`;
  if (!params.role) {
    if (params.webviewReady) {
      await (window as any).pywebview.api.onEvent({
        message: "SpecRenderGrid", 
        params: {fileName, content: svgText}
      });
    } else {
      const link = document.createElement('a');
      link.download = fileName;
      link.href = URL.createObjectURL(new Blob(
        [svgText], 
        {type: "text/html"})
      );
      link.click();
    }
  }
  return await doGenSVG(true, grid, params, svgText);
}

const SpecRenderFinal: Handler = async (controller: any, params: any) => {
  let progress: string[] = []
  const xddata = params.xddata!;

  // Prepare info to exclude inactive sections
  const inactiveSectionIndices = 
    params.xddata.sections
      .map((section: any, index: number) => ({...section, index}))
      .filter((section: any, index: number) => (section?.inactive || false))
      .map((section: any) => section.index);

  const inactiveStepIds = 
    params.xddata.sections
      .filter((section: any) => (section?.inactive || false))
      .flatMap((section: any) => (section?.steps || []));

  // Update start preparing template
  progress.push("Prepare template...")
  controller.setState({xdSpecProgress: progress})
  
  // Extract image data from xddata then get urls
  let imageData = Object.values(xddata.steps)
    .filter((step: any) => !inactiveStepIds.includes(step.id))
    .flatMap((step: any) => (
      Object.entries(step.nodeDetail)
      .flatMap((nodeDetail: any) => (
        nodeDetail[1]?.doc?.map((doc: any) => ({
          ...doc, 
          nodeId: nodeDetail[0],
          role: xddata.nodeMaster[nodeDetail[0]].name
        })) || []
      ))
      .filter((item: any) => item.type === "image" && !(item?.removed))
      .filter((item: any) => (params.role && params.role !== item.role) ? false : true)
      .map((item: any) => (
        {...item, stepId: step.id, xdId: step.xd }
      ))
    ));
  console.log(imageData);
  await Promise.all(
    imageData.map(async (imageItem: any) => {
      // If role is specified, only process image related to that role
      if (params.role && params.role !== imageItem.role) return null
      // Get download url
      const url = await controller.storage
        .refFromURL(`gs://ismor-xd/xdstep2/${imageItem.xdId}/${imageItem.stepId}/${imageItem.nodeId}/images/${imageItem.id}.png`)
        .getDownloadURL();
      imageItem.url = url
    })
  );
  
  // Prepare other data
  let fileName = `${xddata.name}-Spec${params.role ? "-" + params.role : ""}.docx`;
  let graphSize = sizeOf(Buffer.from(params.graphData));
  // // Prepare data for Patient & Staff journey
  let roleDict: {[name: string]: Step[]} = {};
  for (const [sectionIndex, section] of params.sections.entries()) {
    for (const [stepIndex, step] of section.steps.entries()) {
      for (const [nodeId, nodeDetail] of Object.entries(xddata.steps[step.id].nodeDetail)) {
        const nodeName = xddata.nodeMaster[nodeId].name;
        if (!params.role || (params.role && params.role ===nodeName)) {
          if (!(nodeName in roleDict)) { roleDict[nodeName] = [];}
          roleDict[nodeName].push(step); }}}}
  const docTemplate = 
    getDocTemplate(params, roleDict, xddata, inactiveSectionIndices, inactiveStepIds);

  // Path 1: Send to webview ===========================================================
  if (params.webviewReady) {
    // params.graphData = null;
    params.graphData = btoa(new Uint8Array(params.graphData as ArrayBuffer)
      .reduce((data, byte) => data + String.fromCharCode(byte), ''));
    (window as any).pywebview.api.onEvent({
      message: "SpecRenderFinal", 
      params: { fileName, params: {
          ...params, graphSize, roleDict, imageData, docTemplate, progress}}
    });
    return // shortcut return to skip Path 2
  }

  // Path 2: Do on browser =============================================================
  // Download images
  let count = 0;
  progress.push("Prepare images...")
  progress.push("");
  await Promise.all(
    imageData.map(async (imageItem: any) => {
      // If role is specified, only process image related to that role
      if (params.role && params.role !== imageItem.role) return null
      // Download
      const res = await fetch(imageItem.url);
      const data = await res.arrayBuffer();

      // Reduce image size
      const originalImage = await new Promise(resolve => {
        const image = new Image();
        image.onload = async (event: any) => resolve(image)
        image.src = URL.createObjectURL(new Blob([data]));
      });
      let originalSize = sizeOf(Buffer.from(data));
      let targetWidth=1200;
      // let targetWidth=originalSize.width;
      const compact = document.createElement("canvas");
      compact.width = targetWidth;
      compact.height = originalSize.height / originalSize.width * targetWidth;
      compact.getContext("2d")?.drawImage(originalImage as CanvasImageSource, 0, 0, compact.width, compact.height);
      const blob = await new Promise(resolve => compact.toBlob(resolve));
      const arrayBuffer = await new Promise(resolve => {
        let reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsArrayBuffer(blob as Blob);
      });
      imageItem.image = arrayBuffer;
      compact.remove();

      count++;
      progress[progress.length - 1] = `${count} of ${imageData.length}`;
      controller.setState({xdSpecProgress: progress});
    })
  )

  // Organize into Dict
  let imageDict: any = {
    graphData: { 
      data: params.graphData, 
      imageSize: graphSize
    }
  };
  for (const imageItem of imageData) {
    let {stepId, nodeId, id, ...rest} = imageItem;
    imageDict[`${stepId}-${nodeId}-${id}`] = {
      data: imageItem.image,
      imageSize: sizeOf(Buffer.from(imageItem.image))
    }
  }

  // Update done prepare images, render to docx
  progress[progress.length - 1] = `Finished preparing images`;
  progress.push("Render to docx....");
  controller.setState({xdSpecProgress: progress});
  console.log(docTemplate);
  let doc = convertToDoc(docTemplate, imageDict) as Document;  
  
  // Convert to file and download
  const b64string = await Packer.toBase64String(doc);
  const link = document.createElement('a');
  link.href = 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,' + b64string;
  link.download = fileName;
  link.click();

  // Update final progress
  progress.push("Done");
  controller.setState({xdSpecProgress: progress});
  setTimeout(() => {
    controller.setState({xdSpecProgress: []});
  }, 500);
}

export const convertToDoc = (template: any, imageDict: any) => {
  if (template.type === "Document") {
    let {sections, type, ...props} = template;
    let _sections = (sections || []).map((section: any) => {
      let {children, ...sectionProps} = section;
      let _children = children ? 
        children.map((child: any) => convertToDoc(child, imageDict)) : [];
      return {...sectionProps, children: _children}
    });
    return new Document({...props, sections: _sections});
  } else {
    let {children, type, ...props} = template;
    if (children) {
      let _children = 
        children.map((child: any) => convertToDoc(child, imageDict));
      props = {...props, children: _children};
    }
    switch (type) {
      case "Paragraph":
        return new Paragraph(props);
      case "TextRun":
        return new TextRun(props);
      case "ImageRun":
        let {width, height} = imageDict[props.key].imageSize;
        return new ImageRun({
          data: imageDict[props.key].data, 
          transformation: props.width ?
            { width: props.width, height: height / width * props.width }
            : props.height ?
            { width: width/ height * props.height, height: props.height }
            : { width: 600, height: 600 }
        });
      case "PageBreak":
        return new PageBreak();
      default:
        console.log("Error: template ==> ")
        console.log(template);
        return
    }
  }
}

const getDocTemplate = (
  params: any, roleDict: any, xddata: any,
  inactiveSectionIndices: number[], inactiveStepIds: string[]
) => {
  let docTemplate: any;
  if (!params.role) {
    const roleDoc = params.roleList.flatMap(getRoleDocTemplate(roleDict, xddata, inactiveStepIds));
    docTemplate = {
      type: "Document",
      sections: [
      { properties: {page: {margin: {top: "20pt",bottom: "20pt",left: "20pt",right: "20pt",}}},
        children: [
          {type: "Paragraph", indent: {left: "0pt"}, alignment: AlignmentType.CENTER, children: [
            { type: "ImageRun",
              key: "graphData", 
              height: 1000 }]}
        ]}, 
      { properties: {},
        children: 
          params.sections
          .filter((section: any, sectionIndex: number) => 
            !inactiveSectionIndices.includes(sectionIndex))
          .flatMap((section: any, sectionIndex: number) => (
            // Encounter Journey
            [{type: "Paragraph", pageBreakBefore: true, alignment: AlignmentType.CENTER, children: [
              {type: "TextRun", text: "", break: 10},
              { type: "TextRun",
                text: `${sectionIndex+1}. ${section.name}`,bold: true,
                break: sectionIndex > 0 ? 0: 0},
              {type: "PageBreak"}], heading: HeadingLevel.TITLE}
            ].concat(
              section.steps
              .filter((step: any) => (
                !inactiveStepIds.includes(step?.id)))
              .flatMap((step: any, stepIndex: number) => (
                [{type: "Paragraph", children: [
                  { type: "TextRun",
                    text: `${sectionIndex+1}.${stepIndex+1}. ${xddata.steps[step.id].description} -> ` 
                          + (step.nextStepsList.length === 0 ? "Final" 
                            : step.nextStepsList
                              .filter((step: any) => !inactiveStepIds.includes(step.id))
                              .map((step: any) => step.label)
                              .join(",")),
                    break: stepIndex > 0 ? 1: 0}], heading: HeadingLevel.HEADING_1}
                ]
                .concat(stepNodeDetailTemplate(xddata, step) as any)))
            ).concat([{type: "Paragraph", children: [{type:"PageBreak"}]}] as any[]))
          ).concat(roleDoc)}
      ],
    };
  } else {
    docTemplate = { type: "Document", sections: [{properties: {},
      children: [params.role].flatMap(getRoleDocTemplate(roleDict, xddata, inactiveStepIds))}]}
  };
  return docTemplate
}

const getRoleDocTemplate = (roleDict: any, xddata: any, inactiveStepIds: string[]) => 
  (role: string) => {
    let first = true;
    return (
      // Node Name
      [{type: "Paragraph", pageBreakBefore: true, alignment: AlignmentType.CENTER, 
        children: [
          { type: "TextRun", text: "", break: 10 },
          { type: "TextRun", text: `${role}`,  bold: true },
          { type: "PageBreak"}
        ], heading: HeadingLevel.TITLE }
      ].concat((roleDict[role] || [])
        .filter((step: any) => !inactiveStepIds.includes(step.id))
        .flatMap((step: any, roleStepIndex: number) => {
          let addedBreak = first ? 0 : 1 
          first = false
          return(
            [{type: "Paragraph", children: [
              { type: "TextRun",
                text: `${step.sectionIndex! + 1}.${step.stepIndex! + 1}. ${step.description} -> `
                      + (step.nextStepsList.length === 0 ? "Final" 
                      : step.nextStepsList
                        .filter((step: any) => !inactiveStepIds.includes(step.id))
                        .map((step: any) => step.label)
                        .join(",")),
                break: addedBreak }], heading: HeadingLevel.HEADING_1}
            ].concat(stepNodeDetailTemplate(xddata, step, role) as any[]))}) || []
      )      
    )
  }

const stepNodeDetailTemplate = (xddata: ExchangeDiagramData, step: any, role?: string) => 
  Object.entries(xddata.steps[step.id].nodeDetail)
  .sort((a: [string, NodeDetailValue], b: [string, NodeDetailValue]) => 
    a[1].type < b[1].type ? -1 : 1
  )
  .flatMap((detail: [string, NodeDetailValue], nodeDetailIndex: number) => (
    [{ type: "Paragraph", children: [
      { type: "TextRun",
        text: `${step.sectionIndex + 1}.${step.stepIndex + 1}.${nodeDetailIndex +1}. ` + 
              `${xddata.nodeMaster[detail[0]].name} ` + 
              `(${xddata.nodeMaster[detail[0]].system}): ` +
              `${detail[1].detail} `+ 
              `(${detail[1].type === "editor" ? "ผู้ทำรายการ" : "ผู้เกี่ยวข้อง"})`,
        break: nodeDetailIndex > 0 ? 1 : 0, bold: true }], heading: HeadingLevel.HEADING_3},
    ].concat(
      detail[1].extraDetail ?
      detail[1].extraDetail.split("\n").map((line: string, lineIndex: number) => (
        { type:"Paragraph", children: [ {type: "TextRun", text: `${line}` }]})) as any[] : []
    ).concat(
      role && role !== xddata.nodeMaster[detail[0]].name ? [] :
      (detail[1]?.doc || [])
      .filter((docItem: NodeDetailDocItem) => !(docItem?.removed))
      .map((docItem: NodeDetailDocItem) => {
        if (docItem.type === "image") {
          // Render Image
          return(
            {type: "Paragraph", indent: {left: "0pt"}, alignment: AlignmentType.CENTER, children: [
              { type: "ImageRun",
                key: `${step.id}-${detail[0]}-${docItem.id!}`,
                width: 600 }]})
        } else if (docItem.type === "text") {
          return(
            // Render Text
            {type: "Paragraph", indent: {left: "0pt"}, children: [{type: "TextRun", text: docItem.detail }]})
        } else {
          return {type: "Paragraph", children: [{type: "TextRun", text: "unknown type"}]}
        }}) as any[])
  ))

// Generate SVG (skip for now)
const doGenSVG = async (skip: boolean, grid: any, params: any, svgText: string) => {
  if (skip) {
    return null;
  } else {
    grid = genGrid("svg", {    
      cols: params.cols,
      xddata: params.xddata,
      showTool: false,
      view: params.view,
      sections: params.sections,
      sectionRefs: [],
      foldedSections: []
    }, () => {}, null, null, () => {}, () => {}, null, () => {}, null, () => {});
    
    const width = 1200;;
    const height = width * 6;
    svgText = ReactDOMServer.renderToString(
      <svg
        style={{backgroundColor: "white"}}
        viewBox={`0 0 ${width} ${height}`}>
        <foreignObject x={0} y={0} width="100%" height="100%">
          {grid}
        </foreignObject>
      </svg>
    );
    svgText = svgText.replace('<svg ','<svg xmlns="http://www.w3.org/2000/svg" ')
    svgText = svgText.replaceAll('<div','<div xmlns="http://www.w3.org/1999/xhtml" ', )
    const svgDataBase64 = btoa(unescape(encodeURIComponent(svgText)));
    const svgDataUrl = `data:image/svg+xml;charset=utf-8;base64,${svgDataBase64}`
    
    let gridData = await new Promise((resolve) => {
      var img = new Image();
      img.onload = async () => {
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx?.drawImage(img, 0, 0);
        const dataUrl = canvas.toDataURL()
        const result = await fetch(canvas.toDataURL());
        
        // Download as image file
        const link = document.createElement('a');
        link.download = `${params.xddata.name}-Grid.png`;
        link.href = dataUrl
        link.click();
        
        // Send data to be packed into Word
        const data = await result.arrayBuffer();
        resolve(data);
      };
      img.src = svgDataUrl; 
    });
    return gridData
  }
}

export default SpecRender
