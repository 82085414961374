export const RedmineStatus: {[key: string]: string} = {
  "1": "New",
  "2": "In Progress",
  "3" : "Resolved",
  "4" : "Feedback",
  "5" : "Closed",
  "6" : "Rejected",
  "7" : "Paused",
  "9" : "Fixed",
  "10" : "Defer"
}