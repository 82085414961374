import React, { useEffect, useRef, useState } from 'react';
import { Button, Radio, Icon, Modal } from "semantic-ui-react";
import FileExplorer from './FileExplorer';

let docId = "test";

const Video = (props: any) => {
  // Selected Index of component item (video-based, image-based, etc.)
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  // Adding & Editing
  const [showPath, setShowPath] = useState(false);
  const [addedType, setAddedType] = useState<string|null>(null);
  const [edited, setEdited] = useState(false);

  // Cleanup when navigating away from Video
  useEffect(() => {
    (window as any).pywebview.api.onEvent({message: "StartVideoServer", params: {}});
    // return () => {
    //   (window as any).pywebview.api.onEvent({message: "EndVideoServer", params: {}});
    //   props.setProp("selectedVideoUrl", null);
    // }
  }, []);

  // When videoServer is ready
  useEffect(() => {
    if (props.videoServerReady) getVideo();
  }, [props.videoServerReady]);

  useEffect(() => {
    if (props.videoData?.items?.length > 0) {
      setSelectedIndex(0);
    }
  }, [props.videoData])

  const getVideo = async () => {
    // updatePathInfo(props.videoPath);
    props.onEvent({message: "GetVideo", params: {docId}})
  }

  const updatePathInfo = (path: string) => {
    (window as any).pywebview.api.onEvent({
      message: "GetVideoPathInfo", params: {videoPath: path}});
  }

  const swap = (selectedIndex: number, direction: string) => {
    setEdited(true);
    // setSelectedIndex(null);
    let oldItems = (props.videoData?.items || [])
    let newItems = [];
    for (var itemIndex = 0; itemIndex < oldItems.length; itemIndex++) {
      if (direction === "up" && itemIndex === selectedIndex - 1)
        newItems.push(oldItems[selectedIndex])
      else if (direction === "up" && itemIndex === selectedIndex)
        newItems.push(oldItems[selectedIndex - 1])
      else if (direction === "down" && itemIndex === selectedIndex)
        newItems.push(oldItems[selectedIndex + 1])
      else if (direction === "down" && itemIndex === selectedIndex + 1)
        newItems.push(oldItems[selectedIndex])
      else
        newItems.push(oldItems[itemIndex])
    }
    props.setProp("videoData", {items: newItems});
  }

  const selectFileItem = (item: any) => {
    if (!addedType || selectedIndex === null) {
      return
    } else if (!item.isFile) {
      updatePathInfo(item.path);
    } else if (["video", "image"].includes(addedType)) {      
      (window as any).pywebview.api.onEvent({
        message: "AddVideo", 
        params: {
          path: item.path,
          videoData: props.videoData,
          addedType: addedType,
        }
      });
      setShowPath(false);
      setEdited(true);
    } else if (["audio"].includes(addedType)) {
      let selectedItem = props.videoData.items[selectedIndex];
      let audio = (selectedItem?.audio || []).concat({
        margin: 10,
        path: item.path,
      });
      setShowPath(false);
      setEdited(true);
      props.setProp(`videoData.items.${selectedIndex}.audio`, audio)
    } else if (["marginFiller"].includes(addedType)) {
      props.setProp(`videoData.items.${selectedIndex}.marginFiller`, item.path);
      setShowPath(false);
      setEdited(true);
    }
  }

  return(
    <div>
      {/* Top bar ------------------------------------------------------------ */}
      <div 
        style={{
          display: "flex", borderBottom: "solid #aaaaaa 1px", 
          alignItems: "center"
        }}>
        <Icon 
          style={{marginLeft: "10px"}}
          size="small"
          color="green"
          name="add" 
        />
        <Icon 
          size="large"
          color="green"
          name="image" 
          onClick={(e: any) => {
            e.stopPropagation();
            setShowPath(true);
            setAddedType("image")
          }}
        />
        <Icon 
          size="large"
          color="green"
          name="video" 
          onClick={(e: any) => {
            e.stopPropagation();
            setShowPath(true);
            setAddedType("video")
          }}
        />
        <div style={{flex: 1}}></div>
        <div style={{color: "red", marginRight: "10px"}}>{props.videoMessage}</div>
        {edited && <div style={{color: "red", marginRight: "10px"}}>You have unsaved changes</div>}
        <Button size="mini"
          color={"green"}
          onClick={(e: any) => {
            props.onEvent({
              message: "SaveVideo", 
              params: {docId, videoData: props.videoData}
            });
            // setSelectedIndex(null);
            setEdited(false);
          }}>
          Save
        </Button>
        <Button size="mini" 
          color={"blue"}
          disabled={props.videoBeingGenerated}
          onClick={(e: any) => {
            (window as any).pywebview.api.onEvent({
              message: "GenerateVideo", 
              params: {videoData: props.videoData}
            });
          }}>
          {props.videoBeingGenerated ? "Rendering..." : "Render"}
        </Button>
      </div>
      
      {/* Main ------------------------------------------------------------ */}
      <div style={{display: "flex", height: "90vh"}}>
        <div 
          style={{
            width: "350px", 
            borderRight: "solid #aaaaaa 1px", height: "100%"
          }}>
          {(props.videoData?.items || []).map((item: any, itemIndex: number) => 
            <VideoItemSummary
              key={itemIndex}
              itemIndex={itemIndex}
              item={item}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              swap={swap}
              videoData={props.videoData}
            />  
          )}
        </div>
        <div style={{flex: 1}}></div>
        {selectedIndex !== null &&
        <VideoItemEditor
          setProp={props.setProp}
          setEdited={setEdited}
          videoUrlPrefix={props.videoUrlPrefix}
          selectedItem={props.videoData.items[selectedIndex]}
          selectedIndex={selectedIndex}
          setShowPath={setShowPath}
          setAddedType={setAddedType}
        />}
        <div style={{flex: 1}}></div>
      </div>
      <Modal
        open={showPath}
        closeOnDimmerClick={true}
        onClose={() => setShowPath(false)}>
        <FileExplorer
          videoPathInfo={props.videoPathInfo}
          selectFileItem={selectFileItem}
          addedType={addedType}
        />
      </Modal>
    </div>
  )
}

const VideoItemSummary = (props: any) => {
  return(
    <div 
      style={{
        borderBottom: "solid #aaaaaa 1px", 
        padding: "5px",
        backgroundColor: props.selectedIndex === props.itemIndex ? "#eeeeee" : "white"
      }}>
      <div
        style={{position: "relative"}}
        onClick={(e: any) => {
          if (props.selectedIndex !== props.itemIndex) {
            props.setSelectedIndex(props.itemIndex);
          } else {
            // setSelectedIndex(null);
          }
        }}>
        <div style={{position: "absolute", top: 0, right: 0, display: "flex"}}>
          {props.itemIndex > 0 &&<Icon 
            name="angle up" 
            onClick={(e: any) => {
              e.stopPropagation();
              props.swap(props.itemIndex, "up");
            }}
          />}
          {props.itemIndex < (props.videoData?.items || []).length - 1 &&
          <Icon 
            name="angle down" 
            onClick={(e: any) => {
              e.stopPropagation();
              props.swap(props.itemIndex, "down");
            }}
          />}
        </div>
        <div>
          {props.item.path}
        </div>
        {props.item.thumbnail &&
        <img 
          style={{height: "10vh", width: "auto"}}
          src={`data:image/png;base64,${props.item.thumbnail}`} 
        />}
      </div>
    </div>
  )
}

const VideoItemEditor = (props: any) => {
  const [videoDuration, setVideoDuration] = useState<null | number>(null);
  const [videoTime, setVideoTime] = useState<null | number>(null);
  const [audioDurationList, setAudioDurationList] = useState<number[]>([]);
  const [audioIndex, setAudioIndex] = useState<number | null>(null);
  const [shouldPlayAudio, setShouldPlayAudio] = useState(false);
  const [currentAudioDuration, setCurrentAudioDuration] = useState(0);
  const [currentAudioOffset, setCurrentAudioOffset] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (props.selectedItem?.audio?.length > 0) {
      getAudioDuration();
    }
  }, [props.selectedItem]);
  
  useEffect(() => {
    if (videoTime === null || !(props.selectedItem?.audio?.length > 0)) return;
    let offset = 0;
    for (var i = 0; i < props.selectedItem.audio.length; i++) {
      if (videoTime < offset + props.selectedItem.audio[i].margin + audioDurationList[i]) {
        if (audioIndex !== i) {
          setAudioIndex(i);
          setCurrentAudioDuration(audioDurationList[i]);
        }
        // console.log(videoTime - (offset + props.selectedItem.audio[i].margin));
        setCurrentAudioOffset(videoTime - (offset + props.selectedItem.audio[i].margin));
        if (videoTime - (offset + props.selectedItem.audio[i].margin) >= 0) {
          setShouldPlayAudio(true);
        } else {
          setShouldPlayAudio(false);
        }
        return;
      } else {
        offset = offset + props.selectedItem.audio[i].margin + audioDurationList[i];
      }
    }
    // Set offset for the case of last audio index
    let lastOffset = videoTime - (offset - audioDurationList[audioIndex!]);
    setCurrentAudioOffset(lastOffset);
    if (lastOffset > audioDurationList[audioIndex!] && shouldPlayAudio) {
      setShouldPlayAudio(false);
    }
  }, [videoTime]);

  useEffect(() => {
    if (shouldPlayAudio) {
      audioRef?.current?.play();
    }
  }, [shouldPlayAudio])

  const getAudioDuration = async () => {
    const audioDurationList_ = await Promise.all(
      (props.selectedItem?.audio || [])
      .map(async (audioItem: any) => (
        await new Promise(resolve => {
          const audio = new Audio();
          audio.onloadedmetadata = async (event: any) => resolve(audio.duration);
          audio.src = `${props.videoUrlPrefix}${audioItem.path}`;
        })
      ))
    );
    setAudioDurationList(audioDurationList_);
  };

  return(
    <div>
      <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: "2px"}}>
        <Icon
          name="video play"
          size="large"
          color="blue"
          onClick={(e: any) => {
            videoRef?.current?.play();
          }}>
        </Icon>
        <div>{`Timestamp: ${videoTime?.toLocaleString('en-US', {maximumFractionDigits: 2})}`}</div>
      </div>
    <div>
      <video 
        ref={videoRef}
        src={`${props.videoUrlPrefix}${props.selectedItem.path}#t=0.000001`} 
        controls 
        width="900"
        preload="metadata"
        onLoadedMetadata={(e: any) => {
          setVideoDuration(videoRef?.current?.duration || 0)
        }}
        onTimeUpdate={(e: any) => {
          setVideoTime(e.target.currentTime);
        }}
      />
      <ProgressBar
        color="red"
        offset={videoTime}
        duration={videoDuration}
      />
    </div>
    <div>
      <audio
          ref={audioRef}
          src={audioIndex !== null ? `${props.videoUrlPrefix}${props.selectedItem?.audio?.[audioIndex]?.path}` : ""}
      />
    </div>
    <div>
      <div style={{display: "flex", alignItems: "center", padding: "2px"}}>
        <Icon name="add" size="small" color="blue" />
        <Icon name="file audio" color="blue" size="large"
          onClick={(e: any) => {
            props.setShowPath(true);
            props.setAddedType("audio")
          }}
        />
        <div style={{flex: 1}}></div>
        <div style={{display: "flex", alignItems: "center"}}>
          <span>Margin Filler</span>
          <input
            type="radio" 
            checked={!(props.selectedItem?.marginFiller && props.selectedItem?.marginFiller !== "")}
            style={{marginLeft: "10px"}} 
            onChange={(e: any) => {
              props.setProp(`videoData.items.${props.selectedIndex}.marginFiller`, null);
            }}
          />
          <span style={{marginLeft: "10px"}} >Silence</span>
          <input
            type="radio"
            checked={props.selectedItem?.marginFiller && props.selectedItem?.marginFiller !== ""}
            onChange={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              props.setShowPath(true);
              props.setAddedType("marginFiller");
            }}
            style={{marginLeft: "10px"}} 
          />
          <span style={{marginLeft: "10px"}} >File</span>
          {(props.selectedItem?.marginFiller && props.selectedItem?.marginFiller !== "") &&
          <span style={{marginLeft: "10px"}}>{props.selectedItem.marginFiller}</span>
          }
        </div>
      </div>
      <div>
        {(props.selectedItem?.audio || [])
         .map((audioItem: any, audioItemIndex: number) => (
          <div key={audioItemIndex}>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
              <Icon name="delete" color="red" />
              <div>{audioItem.path}</div>
              <div style={{flex: 1}}></div>
              <span>Margin</span>
              <input 
                style={{textAlign: "right", margin: "0 10px 0 10px", width: "40px"}} 
                value={audioItem.margin} 
                onChange={(e: any) => {
                  props.setProp(
                    `videoData.items.${props.selectedIndex}.audio.${audioItemIndex}.margin`, 
                    Number(e.target.value));
                  props.setEdited(true);
                }}
              />
              <div style={{display: "flex", justifyContent: "flex-end", width: "40px"}}>
                {audioItemIndex > 0 && <Icon name="angle up" />}
                {(audioItemIndex < (props.selectedItem?.audio || []).length - 1) && <Icon name="angle down" />}
              </div>
            </div>
            <ProgressBar
              color="green"
              offset={
                audioIndex !== null ? 
                  (audioItemIndex < audioIndex ? audioDurationList[audioItemIndex] 
                    : audioItemIndex === audioIndex ? currentAudioOffset 
                    : 0) 
                : 0
              }
              duration={audioDurationList[audioItemIndex]}
            />
          </div>
        ))}
      </div>
    </div>
  </div>
  )
}

const ProgressBar = (props: any) => {
  const width = props.offset < 0 ? 0 
    : props.offset > props.duration ? 100 
    : (props.offset || 0) / props.duration * 100;

  return(
    <div style={{height: "5px", display:"flex"}}>
      <div 
        style={{
          backgroundColor: props.color, 
          width: `${width}%`
        }}>
      </div>
      <div 
        style={{
          backgroundColor: "#cccccc", 
          width: `${100 - width}%`
        }}>
      </div>
    </div>
  )
}

export default Video;
