import { useState } from "react";

export default function ClientSelector(props: any) {
  const [selectingClient, setSelectingClient] = useState(false);
  return (
    <div style={{position: "relative", zIndex: 100}}>
      <input
        placeholder="เลือก Client"
        style={{top: 0, left: 0, cursor: "pointer", minWidth: "200px"}}
        onClick={(e: any) => {
          setSelectingClient(true)
        }}
        onChange={(e: any) => {}}
        value={props.clientDict && props.clientId ? props.clientDict[props.clientId].name : ""}
      />
      {selectingClient &&
      <div
        style={{
          position: "absolute", top: 0, left: 0, padding: "5px",
          backgroundColor: "white", border: "solid #cccccc 1px",
          minWidth: "200px", cursor: "pointer"
        }}>
      {Object.values(props.clientDict)
      .sort((a: any, b: any) => a.seq < b.seq ? -1: 1)
      .map((item: any, menuIndex: number) => 
        <div 
          key={menuIndex}
          style={{backgroundColor: "white", zIndex: 100}}
          onClick={(e: any) => {
            props.setClientId(item.id);
            setSelectingClient(false);
          }}>
          {item.display}
        </div>)}
      </div>}
    </div>
  )
}