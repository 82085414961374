import React, {
  useState,
  useEffect
} from "react";

const Tab = (props: any) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [showTab, setShowTab] = useState(false);

  useEffect(() => {
    if (props.data?.length > 0) {
      // if (props.selectedTab?.key) {
      //   console.log("!! Tab have key");
      //   let idx = props?.data?.findIndex(
      //     (item: any) => item.key === props.selectedTab?.key
      //   );
      //   console.log("!! Tab idx: ", idx);
      //   // setSelectedTabIndex(idx);
      // } else {
        if (Number.isInteger(props.selectedTabIndex)) {
          setSelectedTabIndex(props.selectedTabIndex);
        }
      // }
    }
    
  }, [props.data, props.selectedTabIndex]);

  // useImperativeHandle(ref, () => ({
  //   getMedReconcileIndex: () => {
  //     return
  //   },
  // }));
  useEffect(() => {
    // console.log(" find MedReconcile Index Tab ");
    let medReconcileIdx = props?.data?.findIndex(
      (item: any) => item.name === "Med Reconcile"
    );
    // console.log("find MedReconcile Index  idx ", idx);
    // console.log('props.medReconcileIndex: ', props.medReconcileIndex);

    if (medReconcileIdx !== props.medReconcileIndex && props.setProp) {
      props.setProp("medReconcileIndex", medReconcileIdx);
    }
  }, [props.data, props.medReconcileIndex]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: props.mobileScreen ? "column" : "row",
        height: props.mobileScreen ? "auto" : "100%",
        width: "100%",
      }}
    >
      <div
        className="tab-menu --framework"
        style={{
          width: props.mobileScreen ? "100%" : props.tabWidth || "250px",
          maxHeight: "95vh",
          display: "flex",
          flexDirection: "column",
          border: "solid #cccccc 1px",
          overflowY: "scroll",
          marginBottom: props.marginBottom || "0px",
          marginLeft: props.hideTabMenu ? `-${props.tabWidth || "250px"}` : 0,
          transition: "margin-left 0.15s ease 0s",
        }}
      >
        {props?.data.map(
          (item: any, index: number) =>
            (!props.mobileScreen ||
              showTab ||
              (!showTab && selectedTabIndex === index)) && (
              <div
                id={`tab-${item.key}`}
                key={index}
                style={{
                  backgroundColor:
                    props.data[selectedTabIndex]?.key === item.key
                      ? "#d6ecf3"
                      : "white",
                  padding: "5px",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  if (!props.disabled) {
                    if (props.mobileScreen) {
                      setShowTab(!showTab);
                    }
                    setSelectedTabIndex(index);
                    props?.onTabChange?.(index);
                  }
                }}
              >
                {item.name}
              </div>
            )
        )}
      </div>
      <div
        style={{
          flex: 1,
          maxHeight: "calc(100dvh - 60px)",
          overflowY: "auto",
          width: props.contentWidth,
        }}
      >
        {props?.data[selectedTabIndex]?.render() || ""}
      </div>
    </div>
  );
};

export default Tab;
