class NestedMap {
  data: any[];
  keys: any[];
  
  constructor(data: any[], keys: any[]) {
    this.data = data;
    this.keys = keys;
  }

  toMap = () => {
    let output: Map<string, any> | any[] = new Map();
    this.data.forEach((item: any) => {
      let keyList = [];
      let obj: Map<string, any> | any[] = output;
      this.keys.forEach((func: (row: any) => any, index: number) => {
        const key = func(item);
        if (!(obj as Map<string, any>).get(key)) {
          if (index < this.keys.length - 1)
            (obj as Map<string, any>).set(key, new Map());
          else
            (obj as Map<string, any>).set(key, []);
        }
        obj = (obj as Map<string, any>).get(key)
      });
      (obj as any[]).push(item)
    })
    return output
  }

}

export default NestedMap