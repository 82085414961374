import { useEffect, useRef, useState } from "react";
import { loadPythonWorker } from "ismor-lib/utils/Python";
import { monthKeys, MenuItem } from "./Utils"
import StaffList from "./StaffList";
import ByStaff from "./ByStaff";
import ByYear from "./ByYear";
import AddDetail from "./AddDetail";
import EditStaffForm from "./EditStaffForm";
import Extra from "./Extra";
import Analysis from "./Analysis";


export default function Staff(props: any) {
  const [mode, setMode] = useState("By Year");
  const [subMode, setSubMode] = useState<any>(null);
  const [selectedStaff, setSelectedStaff] = useState<any>(null);
  const [staffDetail, setStaffDetail] = useState<any>(null);
  const [pythonLoaded, setPythonLoaded] = useState(false);
  const [pythonError, setPythonError] = useState<any>(null);

  const python = useRef<any>(null);

  // Use Effect ---------------------------------------------------------------------------------
  useEffect(() => { initPython() }, []);

  useEffect(() => {
    if (props.adminLoggedin)
      props.onEvent({message: "GetStaffData", params: {}});
  }, [props.adminLoggedin]);

  useEffect(() => {
    if (pythonError === null) return;
    console.log(pythonError);
    setPythonError(null);
  }, [pythonError]); 

  useEffect(() => {
    if (selectedStaff) {
      let detail = Object.values(props.monthDetail).map((item: any) => item?.[selectedStaff]).filter((item: any) => item)
      const detailDisplay = detail.reduce((acc: any, cur: any) => 
        {
          const year = cur.ym.toString().substring(0, 4);
          const month = cur.ym.toString().substring(4);
          if (!(year in acc["year"])) acc["year"][year] = {};
          if (!(year in acc[month])) acc[month][year] = {}
          acc[month][year] = cur.components;
          return acc;
        }, 
        Object.fromEntries([["year", {}]].concat(
          monthKeys.map((monthKey: string) => [monthKey, {}])))
      );
      setStaffDetail(detailDisplay);
    }
  }, [selectedStaff]);

  const initPython = async () => {
    python.current = await loadPythonWorker("Staff", setPythonLoaded, setPythonError, false, `
    import pandas
    True
    `);
  };

  // Render -------------------------------------------------------------------------------------
  return(
    <div>
      <div style={{display: "flex", borderBottom: "solid #eeeeee 1px"}}>
        <MenuItem name="Staff List" mode={mode} setMode={setMode} />
        {/* <MenuItem name="Add Staff" mode={mode} setMode={setMode} /> */}
        <MenuItem name="By Year" mode={mode} setMode={setMode} />
        {/* <MenuItem name="Add Detail" mode={mode} setMode={setMode} /> */}
        {props.staffplus &&
        <>
          <MenuItem name="Analysis" mode={mode} setMode={setMode} />
          {/* <MenuItem name="Extra" mode={mode} setMode={setMode} /> */}
        </>
        }
      </div>
      <div style={{padding: "5px", height: "91vh", overflow: "auto"}}>
        {mode === "Staff List" && !subMode ?
        <StaffList
          onEvent={props.onEvent}
          setSubMode={setSubMode}
          setSelectedStaff={setSelectedStaff}
          staffProfile={props.staffProfile}
        />
        : mode === "Staff List" && subMode === "By Staff" && staffDetail ?
        <ByStaff
          onEvent={props.onEvent}
          setSubMode={setSubMode}
          selectedStaffProfile={props.staffProfile?.[selectedStaff]}
          staffDetail={staffDetail}
        />
        : mode === "Add Staff"?
        <EditStaffForm
          onEvent={props.onEvent}
        />
        : mode === "Add Detail" ?
        // to change ----------------------------
        <AddDetail
          onEvent={props.onEvent}
          staffDict={props.staffDict}
        />
        : mode === "By Year" ?
        // to change ----------------------------
        <ByYear
          onEvent={props.onEvent}
          staffplus={props.staffplus}
          staffDict={props.staffDict}
          staffProfile={props.staffProfile}
          monthDetail={props.monthDetail}
          dividendDict={props.dividendDict}
          letterDict={props.letterDict}
          triggerStaff={props.triggerStaff}
        />
        
        : mode === "Extra"?
        <Extra
          onEvent={props.onEvent}
        />
        : mode === "Analysis" ?
        (props.staffplus && pythonLoaded?
        <Analysis
          onEvent={props.onEvent}
          python={python}
          pythonLoaded={pythonLoaded}
          pythonError={pythonError}
          staffProfile={props.staffProfile}
        />
        :
        <></>)
        :
        <></>
        }      
      </div>
    </div>
  )
}
