import React, { CSSProperties } from "react";

const RedmineStatus = (props: any) => {
    // console.log(props.byStatusData);
    return(
        Object.keys(props.byStatusData).length === 0 ? <div>Loading data...</div> :
        <div 
            style={{ 
                height: "90vh", 
                overflowY: "scroll",
                display: "grid",
                gridTemplateColumns: "3fr 1fr 1fr 1fr"
            }}>
            <div></div>
            <div style={{ textAlign: "right", paddingRight: "5px" }}>New</div>
            <div style={{ textAlign: "right", paddingRight: "5px"  }}>Prog</div>
            <div style={{ textAlign: "right", paddingRight: "5px"  }}>Res</div>
            {/* <div style={{ textAlign: "right", paddingRight: "5px"  }}>Fixed</div> */}
            {Object.values(props.byStatusData).flatMap((group: any, group_index: number) => (
                group.flatMap((project: any, project_index: number) => {
                    const [name, identifier] = project["name_identifier"].split("|");
                    const dataList = project["project_status"];
                    
                    let New = dataList.filter((item: any) => (item.status_name === "New"))
                    New = New.length > 0 ? New[0].count : 0;
                    
                    let InProgress = dataList.filter((item: any) => (item.status_name === "In Progress"));
                    InProgress = InProgress.length > 0 ? InProgress[0].count : 0;
                    
                    let Resolved = dataList.filter((item: any) => (item.status_name === "Resolved"));
                    Resolved = Resolved.length > 0 ? Resolved[0].count : 0;
                    
                    let Fixed = dataList.filter((item: any) => (item.status_name === "Fixed"));
                    Fixed = Fixed.length > 0 ? Fixed[0].count : 0;

                    const row_index = group_index * 10000 + project_index * 10;
                    const RowStyle: CSSProperties = { 
                        textAlign: "right", 
                        paddingRight: "5px",
                        borderTop: project_index === 0 ? "solid #cccccc 1px" : "none"
                    }

                    return([
                        <div
                            key={row_index}
                            style={RowStyle}
                            onClick={(e: any) => {
                                globalThis.open(`https://redmine.thevcgroup.com/projects/${identifier}/issues`)
                            }}>
                            {name}
                        </div>,
                        <div 
                            key={row_index + 1}
                            style={RowStyle}>
                            {New}
                        </div>,
                        <div 
                            key={row_index + 2}
                            style={RowStyle}>
                            {InProgress}
                        </div>,
                        <div 
                            key={row_index + 3}
                            style={RowStyle}>
                            {Resolved}
                        </div>,
                        // <div 
                        //     key={row_index + 4}
                        //     style={RowStyle}>
                        //     {Fixed}
                        // </div>
                    ]
                    )
                })
            ))}
        </div>
    )
}

export default RedmineStatus