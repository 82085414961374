import React, { useState } from 'react'
import Component, { screenPropsDefault } from './Component'

const TestComponent = (props: any) => {
    const [buttonTitle, setButtonTitle] = useState("hello there")

    return(
    <Component 
        {...screenPropsDefault}
        buttonTitle={buttonTitle}
        setButtonTitle={setButtonTitle}
    />
    )

}

export default TestComponent