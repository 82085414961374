export const issue_count_sql = `
  with temp as (
    from priority_list
    left join issues
      on list_contains(priority_list.releases, issues.release_id)
      or list_contains(priority_list.issues, issues.id)
    left join deliverable
      on priority_list.deliverableId = deliverable.deliverableId
    select 
      deliverable.deadline_current,
      cast(count(distinct issues.id) as integer) issue_count
    group by all
  )
  from temp
  where deadline_current is distinct from null
  order by deadline_current desc
  ;`;

export const issue_problem = `
  with temp as (
    from issues
    select 
      cast(id as integer) id,
      * exclude(id)
  ), 
  problem_list_case as (
    from problem_list
    left join xdcase
      on problem_list.caseId = xdcase.id
    order by 
      xdcase.caseIndex asc
  ),
  temp1 as (
    from temp
    left join problem_list_case
      on list_contains(problem_list_case.issues, temp.id)
    select
      temp.*,
      (case 
        when problem_list_case.caseIndex is distinct from null 
          then concat(cast(problem_list_case.caseIndex as integer) + 1, '. ', problem_list_case.name)
        else
          '[ไม่มี case]'
        end
      ) case_name
  ), 
  temp2 as (
    from temp1
    select
      temp1.* exclude(case_name),
      first(case_name) case_name
    group by all
  )
  from temp2
  ;`

export const priority_resolved_sql1 = `
  with issues_with_last_resolve as (
    from issues
    left join performance_dev
      on issues.id = performance_dev.issueId
    select
      issues.*,
      performance_dev.username last_resolver,
  ), 
  temp as (
    from priority_list
    left join issues_with_last_resolve
      on list_contains(priority_list.releases, issues_with_last_resolve.release_id)
      or list_contains(priority_list.issues, issues_with_last_resolve.id)
    left join deliverable
      on priority_list.deliverableId = deliverable.deliverableId
    select 
      priority_list.name "name",
      deliverable.dealName,
      deliverable.clientName,
      deliverable.name deliverableName,
      deliverable.deadline_current deadline_current,  
      issues_with_last_resolve.id issue_id,
      issues_with_last_resolve.assign issue_assign,
      issues_with_last_resolve.last_resolver last_resolver
    group by all
  ), 
  temp0 as (
    from temp
    select
      * exclude (issue_id, issue_assign, last_resolver),
      list(issue_id) issue_id_list,
      list(issue_assign) issue_assign_list,
      list(last_resolver) last_resolver_list
    group by all
  ),
  temp0_1 as (
    from temp0
    select
      * exclude (issue_assign_list, last_resolver_list),
      list_filter(issue_assign_list, x -> x is distinct from null) issue_assign_list,
      list_filter(last_resolver_list, x -> x is distinct from null) last_resolver_list
  ),
  temp1 as (
    from temp0_1
    left join devlist
      on list_contains(temp0_1.issue_assign_list, devlist.username)
      or list_contains(temp0_1.last_resolver_list, devlist.username)
    select
      temp0_1.name,
      temp0_1.deadline_current,
      devlist.username,
      list_filter(temp0_1.issue_assign_list, x -> x == devlist.username) issue_assign_list,
      list_filter(temp0_1.last_resolver_list, x -> x == devlist.username) last_resolver_list,
    where deadline_current is distinct from null
    order by deadline_current
  ), 
  temp2_1 as (
    from temp1
    select
      name,
      deadline_current,
      username,
      format('{} / {}', len(last_resolver_list), len(issue_assign_list)) count_info,
  ),
  temp3 as (
    pivot temp2_1
    on username,
    using first(count_info)
    group by
      name,
      deadline_current
  )
  from temp3
  select *
;`;

// -------------------------------------------------------------------------------------------------------
// Deprecated -> use priority_resolved_sql1 instead
export const priority_resolved_sql = `
  with temp as (
    from priority_list
    left join issues
      on list_contains(priority_list.releases, issues.release_id)
      or list_contains(priority_list.issues, issues.id)
    left join deliverable
      on priority_list.deliverableId = deliverable.deliverableId
    select 
      priority_list.name "name",
      deliverable.dealName,
      deliverable.clientName,
      deliverable.name deliverableName,
      deliverable.deadline_current deadline_current,  
      list(distinct issues.id) issues
    group by all
  ), temp1 as (
    from temp
    left join performance_dev
      on list_contains(temp.issues, performance_dev.issueId)
    select
      temp.name,
      temp.dealName,
      temp.clientName,
      temp.deliverableName,
      temp.deadline_current,
      temp.issues,
      performance_dev.username,
      performance_dev.issueId,
      performance_dev.last_resolved[:10] last_resolved,
    where deadline_current is distinct from null
    order by deadline_current
  ), temp2 as (
    from temp1
    select 
      name,
      deadline_current,
      username,
      list(last_resolved) last_resolved_list
    group by all
    order by 
      deadline_current,
      name,
      username
  ), temp3 as (
    pivot temp2
    on username,
    using first(last_resolved_list)
    group by
      name,
      deadline_current
  )
  from temp3
  select *
  ;`;

// Not used --> merged to priority_resolved_sql1
export const priority_assigned_sql1 = `
  with temp as (
    from priority_list
    left join issues
      on list_contains(priority_list.releases, issues.release_id)
      or list_contains(priority_list.issues, issues.id)
    left join deliverable
      on priority_list.deliverableId = deliverable.deliverableId
    left join devlist on issues.assign = devlist.username
    select 
      priority_list.id priority_id,
      priority_list.name "name",
      deliverable.deadline_current deadline_current,  
      issues.id issue_id,
      devlist.username
  ), temp1 as (
    from temp
    select 
      temp.priority_id,
      temp.name,
      temp.deadline_current,
      temp.username,
      list(distinct issue_id) issue_list
    group by all
  ), temp2 as (
    pivot temp1
    on username,
    using first(issue_list)
    group by
      name,
      deadline_current
  )
  from temp2
  ;`;

export const priority_count_sql = `
  with temp as (
    from priority_list 
    left join deliverable
      on priority_list.deliverableId = deliverable.deliverableId
    select 
      deliverable.deadline_current,
      cast(count(priority_list.name) as integer) priority_count
    group by all
    order by deliverable.deadline_current
  )
  from temp
  where temp.deadline_current is distinct from null
  order by temp.deadline_current desc
  ;`
