import React from "react";
import moment from "moment";
import { Bar } from 'react-chartjs-2';

const RedminePerformance = (props: any) => {
    const start = moment().subtract(30, 'days');
    const performanceData = props.performanceData.filter((item: any) =>
        item.resolved_latest >= start.format('YYYY-MM-DD')
    )

    const dateDict = Array.from(
            { length: 30 }, 
            (v: any, i: number) => start.add(1, 'days').format('YYYY-MM-DD')
        )
        .reduce((acc: any, cur: string) => {
            acc[cur] = 0
            return acc
        }, {});

    let userList: string[] = (Array.from(
        performanceData
        .map((item: any) => item.user_resolved_latest)
        .reduce((acc: Map<string, boolean>, cur: string) => {
            if (!acc.get(cur)) {
                acc.set(cur, true)
            }
            return acc
        }, new Map())
        .keys()
    ) as string[]).sort();

    const allPlotData = [performanceData].concat(
        userList.map((user: string) => 
            performanceData.filter((item: any) => 
                item.user_resolved_latest === user)
        )
    )

    // console.log(allPlotData)

    const plotData = allPlotData.map((rawData: any[]) => rawData
        .filter((item: any) => item.resolved_latest > 
                               moment().subtract(30, 'days')
                                       .format('YYYY-MM-DD'))
        .map((item: any) => (
            [
                item.resolve_count <= 1 ? item.resolved_latest.split(" ")[0] : false, 
                item.resolve_count > 1 ? item.resolved_latest.split(" ")[0] : false,    
            ]))
        .reduce((acc: any, cur: any) => (
            [
                acc[0].concat(cur[0]),
                acc[1].concat(cur[1])
            ]), [[], []])
        .map((dateList: any) => (
            dateList.filter((item: any) => item)
                    .reduce((acc:any, cur: any) => {
                        if (!Object.keys(acc).includes(cur)) {
                            acc[cur] = 0
                        }
                        acc[cur] += 1
                        return acc
                    }, {...dateDict})
        ))
    );
    // console.log(plotData);
                               
    return(
        performanceData.length === 0?
        <div>Please wait while loading data...</div>:

        <div 
            style={{ 
                height: "90vh", 
                overflowY: "scroll",
                padding: "5px"
            }}>
            {plotData.map((data: any[], data_index: number) => (
            <div key={data_index}>
                <div>{data_index === 0 ? "Total" : userList[data_index - 1]}</div>
                <Bar 
                    height={props.mobileScreen ? 120 : 60}
                    data={{
                        labels: Object.keys(dateDict).map((s: string) => s.substring(5)),
                        datasets: [
                            {
                                label: "1st Resolve",
                                data: Object.values(data[0]),
                                backgroundColor: "green"
                            },
                            {
                                label: "Re-resolve",
                                data: Object.values(data[1]),
                                backgroundColor: "orange"
                            }
                        ]
                    }}
                />
            </div>
            ))} 
        </div>
    )
}

export default RedminePerformance