import { useEffect, useState } from "react";

export default function GameResult(props: any) {
  const [gameStatData, setGameStatData] = useState<any>(null);
  const [gameDict, setGameDict] = useState<any>(null);
  
  useEffect(() => {
    return () => {
      props.setProp("ihGameList", []);
    }
  }, []);

  useEffect(() => {
    if (props.xdId) {
      props.onEvent({message: "GetIHGameList", params: {}});
      props.onEvent({message: "GetGameStat", params: { xdId: props.xdId, all: true}});
      props.onEvent({message: "GetClientUser", params: {}});
    }
  }, [props.xdId]);

  useEffect(() => {
    if (props.ihGameStatList?.length > 0 && props.ihGameList?.length > 0) {
      processStatData();
    }
  }, [props.ihGameStatList, props.ihGameList]);

  const processStatData = async () => {
    let gameDict_ = (props.ihGameList || [])
      .reduce((acc: any, cur: any) => {
        acc[cur.id] = cur;
        return acc;
      }, {});
    setGameDict(gameDict_);
    let data = (await Promise.all(
      (props.ihGameStatList || []).map(async (url: string) => {
        let res = await (await fetch(await props.toWebDownloadUrl(url))).json();
        return res
      })
    )).reduce((acc: any, cur: any) => {
      if (!(cur.gameId in acc)) {
        acc[cur.gameId] = {name: gameDict_?.[cur.gameId]?.name, byUser: {}, latest: null};
      }
      let user = cur.user;
      if (!acc[cur.gameId]?.latest || cur.datetime > acc[cur.gameId]?.latest) {
        acc[cur.gameId].latest = cur.datetime;
      }
      if (!(user in acc[cur.gameId].byUser)) {
        acc[cur.gameId].byUser[user] = cur;
      } else if (acc[cur.gameId].byUser[user].datetime < cur.datetime) {
        acc[cur.gameId].byUser[user] = cur;
      }
      return acc;
    }, {});
    setGameStatData(data);
  }

  return(
    <div style={{padding: "10px", height: "90vh", width: "100%", overflowY: "auto"}}>
      {gameStatData ?
      Object.values(gameStatData)
      .sort((a: any, b: any) => a.latest > b.latest ? -1 : 1)
      .map((item: any, index: number) => {
        let userCount = Object.keys(item.byUser || {}).length;
        return (
          <div key={index} style={{display: "flex", borderBottom: "solid magenta 2px"}}>
            <div style={{flex: 1}}>{item.name}</div>
            <div style={{flex: 5}}>
              {Object.values(item.byUser)
              .sort((a: any, b: any) => a.datetime > b.datetime ? -1 : 1)
              .map((userItem: any, userItemIndex: number) => {
                let logCount = userItem?.logs?.length || 0;
                let lastScore = 0;
                if (logCount > 0) {
                  lastScore = userItem.logs[logCount - 1].score || "-"
                };
                let clientUser = props.clientUserDict?.get(userItem.user);
                let borderBottom = userItemIndex + 1 === userCount ? "none" : "solid grey 1px"
                return(
                  <div 
                    key={userItemIndex} 
                    style={{display: "flex", borderBottom}}
                    >
                    <div style={{flex: 1.5}}>{userItem.user}</div>
                    <div style={{flex: 1}}>{userItem.datetime.replace("T", " ")}</div>
                    <div style={{flex: 0.5, textAlign: "right", paddingRight: "10px"}}>{`${lastScore} / ${userItem.fullScore || "-"}`}</div>
                    <div style={{flex: 1.5}}>{clientUser?.name}</div>
                    <div style={{flex: 1.5}}>{clientUser?.organization}</div>
                    <div style={{flex: 1.5}}>{clientUser?.role}</div>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })
      :
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        Loading Data
      </div>}
    </div>
  )
}
