import { WasmHandler } from 'react-lib/frameworks/WasmController'
import JSZip from 'jszip'
import moment from "moment";
import app from 'firebase/compat/app';
import { STEP_FULL_SCORE } from './GamePlayer2';
import axios, { AxiosResponse } from "axios";

type Handler = WasmHandler<State, Event, Data>

type IHGamePlaySessionLog = {
  datetime: string,
  level: number,
  step: number,
  success: boolean,
}

type IHGamePlaySession = {
  id?: string,
  user: string,
  xdId: string,
  gameId: string,
  publishUrl: string,
  datetime: string,
  fullScore: number,
  lastStep: { level: number, step: number }
  logs: IHGamePlaySessionLog[]
}

// Spec ---------------------------------------------------------------------
export type State = {
  xdId?: string | null,
  ihGameList?: any[],
  selectedIHGame?: any,
  publishedIHGame?: any,
  screenGroupInfo?: any[],
  ihGameMessage?: any,
  ihGamePlaySession?: any,
  ihGameStatList?: any[],
}

export const StateInitial: State = {
  ihGameList: [],
  selectedIHGame: null,
  publishedIHGame: null,
  screenGroupInfo: [],
  ihGameMessage: null,
  ihGamePlaySession: null,
  ihGameStatList: [],
}

export type Event = 
  { message: "GetIHGameList", params: {} }
  | { message: "GetIHGameListMaster", params: {} }
  | { message: "SelectIHGame", params: { id: string } } 
  | { message: "AddIHGame", params: { name: string } } 
  | { message: "AddIHGameStep", 
      params: { 
        toAddLevel: number,
        toAddStep: number,
        toAddStepData: any,
      } } 
  | { message: "EditIHGameStep", 
      params: { 
        selectedLevel: number,
        selectedStep: number,
        editedStepData: any,
      } } 
  | { message: "DeleteIHGameStep", 
      params: { 
        selectedLevel: number,
        selectedStep: number,
      } } 
  | { message: "AddRectTarget", 
      params: { 
        gameId: string,
        selectedLevel: number,
        selectedStep: number,
        x: number,
        y: number,
        width: number,
        height: number, 
      } } 
  | { message: "UploadIHGameAsset", 
      params: { 
        level: number,
        addedScreenName: string
        url: string ,
        assetType: string,
        fileExtension: string, 
      } 
    } 
  | { message: "MoveIHGameScreen", 
      params: { 
        from: { level: number, step: number} ,
        to: { level: number, step: number},
      } 
    } 
  | { message: "PublishIHGame", params: {} } 
  | { message: "SelectPublishedIHGame", params: {id: string} } 
  | { message: "IHGameTestRun", params: {} } 
  | { message: "IHGameAddLevel", params: {} } 
  | { message: "IHGameNewPlaySession", params: {} } 
  | { message: "IHGameLogPlaySession", params: {} } 
  | { message: "GetGameStat", params: { id: string } } 
  | { message: "EditIHGame", params: { name: string } } 

export type Data = {
  unsubscribeIHGame?: any,
}

export const DataInitial = {
  unsubscribeIHGame: () => {},
}

// Get Data for Edit ---------------------------------------------------------------------------------------------------------
export const GetIHGameList: Handler = async (controller, params) => {
  let { xdId } = controller.getState();
  let res;
  if (params?.forClient) {
    res = await controller.db.collection("IHGame")
      .where("users", "array-contains", controller.user.email).get();
    let ihGameList = (res.docs || []).map((doc: any) => ({id: doc.id, ...doc.data()}));
    if (ihGameList.length === 0) return console.log("Empty game list");
    controller.setState({ ihGameList });
    return;
  } 
  
  if (params?.all || !xdId) {
    res = await controller.db.collection("IHGame").get();
  } else {
    controller.setState({ihGameList: [], selectedIHGame: null});
    res = await controller.db.collection("IHGame").where("xdId", "==", xdId).get();
  }
  let ihGameList = (res.docs || []).map((doc: any) => ({id: doc.id, ...doc.data()}));
  if (ihGameList.length === 0) return console.log("Empty game list");
  controller.setState({ ihGameList });
  
  controller.data?.unsubscribeIHGame?.();
  controller.data.unsubscribeIHGame = controller.db.collection("IHGame")
    .where("__name__", "in", ihGameList.map((doc: any) => doc.id))
    .onSnapshot({
      next: (querySnapshot: app.firestore.QuerySnapshot) => {
        let { ihGameList, selectedIHGame } = controller.getState();
        let ihGameDict = Object.fromEntries(
          (ihGameList || []).map((game: any) => [game.id, game]));
        for (const change of querySnapshot.docChanges()) {
          if (change.doc.id in ihGameDict) {
            ihGameDict[change.doc.id] = {id: change.doc.id, ...change.doc.data()};
          }
        }
        if (selectedIHGame && selectedIHGame?.id in ihGameDict) {
          controller.setState({
            ihGameList: Object.values(ihGameDict),
            selectedIHGame: Object.assign({}, ihGameDict[selectedIHGame.id])
          });
        } else {
          controller.setState({ihGameList: Object.values(ihGameDict)});
        }
      },
      error: () => {}
    });
}

export const GetIHGameListMaster: Handler = async (controller, params) => {
  let ihGameList = ((await controller.db.collection("IHGame").get())
    ?.docs || []).map((doc: any) => ({id: doc.id, ...doc.data()}));
  controller.setState({ihGameList});
}

export const SelectIHGame: Handler = async (controller, params) => {
  if (!params.id) return;
  let { ihGameList } = controller.getState();
  let selectedIHGame = ihGameList?.find((game: any) => game.id === params.id);
  if (!selectedIHGame) return console.log("Can't find game by that id")
  controller.setState({selectedIHGame});
}

// Edit ---------------------------------------------------------------------------------------------------------------------
export const AddIHGame: Handler = async (controller, params) => {
  let { xdId, ihGameList } = controller.getState();
  if (!xdId || !params.name) return console.log("No xdId or game name. Can't add ih game");

  let newGame = { xdId, name: params.name, nextVersion: 1, publishUrl: null, levels: []};
  let res = await controller.db.collection("IHGame").add(newGame);
  
  if (!res.id) return console.log("Create failed. No id returned.");
  controller.setState({
    ihGameList: [ ...(ihGameList || []), 
                  {id: res.id, ...newGame}
                ]
  }, () => { SelectIHGame(controller, {id: res.id}); });
}

export const EditIHGame: Handler = async (controller, {gameId, name}) => {
  if (!name || !gameId) return console.log("Insufficient params");
  controller.db.collection("IHGame").doc(gameId).update({name: name});
  let dbweb = (controller as any).dbweb as app.firestore.Firestore;
  if (!dbweb) return console.log("No dbweb. Can't update game in dbweb");
  let docweb = await dbweb.collection("IHGame").doc(gameId).get();
  if (!docweb.exists) {
    return console.log("This game is not published yet. Nothing to update in dbweb");
  } else {
    docweb.ref.update({name: name});
  }
}

export const IHGameAddLevel: Handler = async (controller, params) => {
  let selectedIHGame = Object.assign({}, controller.getState().selectedIHGame);
  selectedIHGame.levels.push({
    name: `level${selectedIHGame.levels.length + 1}`,
    preamble: { url: "gs://ismor-xd/IHGameCommon/images/0.jpeg" },
    sound: {
      failed: { url: "gs://ismor-xd/IHGameCommon/sounds/failed.mp3" },
      shoot: { url: "gs://ismor-xd/IHGameCommon/sounds/0.mp3" },
    },
    steps: []
  });
  controller.db.collection("IHGame").doc(selectedIHGame.id)
    .update({levels: cleanLevelData(selectedIHGame.levels)});
  controller.setState({selectedIHGame});
}

export const AddIHGameStep: Handler = async (controller, params) => {
  let { selectedIHGame } = controller.getState();
  let {toAddLevel, toAddStep, toAddStepData } = params;
  if (!selectedIHGame || !selectedIHGame?.id || toAddLevel === null || toAddStep === null || !toAddStepData)
    console.log("Insufficient params");
  let { id, levels } = selectedIHGame;
  let newStep: any = {};
  if (toAddStepData?.stepId)
    newStep.stepId = toAddStepData.stepId;
  if (toAddStepData?.nodeId)
    newStep.nodeId = toAddStepData.nodeId;
  if (toAddStepData?.guide)
    newStep.guide = toAddStepData.guide;
  if (toAddStepData?.mission)
    newStep.mission = toAddStepData.mission;

  if (toAddStepData?.newDownloadUrl) {
    // Upload new image
    const data = await fetch(toAddStepData.newDownloadUrl);
    const arrayBuffer = await data.arrayBuffer();
    const assetDoc = await controller.db.collection("IHGameAsset")
      .add({gameId: id, type: "screen", url: null});
    const url = `gs://ismor-xd/IHGame/${selectedIHGame.xdId}/${id}` 
                + `/screen/${assetDoc.id}.${toAddStepData.newDownloadType}`;
    await controller.storage.refFromURL(url).put(arrayBuffer);
    assetDoc.update({url});
    newStep.screen = { url };
  }

  if (toAddStepData?.newLessonDownloadUrl) {
    // Upload new image
    const data = await fetch(toAddStepData.newLessonDownloadUrl);
    const arrayBuffer = await data.arrayBuffer();
    const assetDoc = await controller.db.collection("IHGameAsset")
      .add({gameId: id, type: "screen", url: null});
    const url = `gs://ismor-xd/IHGame/${selectedIHGame.xdId}/${id}` 
                + `/screen/${assetDoc.id}.${toAddStepData.newLessonDownloadType}`;
    await controller.storage.refFromURL(url).put(arrayBuffer);
    assetDoc.update({url});
    newStep.lesson = { url };
  }

  if (toAddStep >= levels[toAddLevel].steps.length) {
    levels[toAddLevel].steps.push(newStep);
  } else {
    levels[toAddLevel].steps.splice(toAddStep, 0, newStep);
  }
  await controller.db.collection("IHGame").doc(id).update({levels});
  params?.finishCallBack();
}

export const MoveIHGameScreen: Handler = async (controller, params) => {
  let { from, to } = params;
  let { selectedIHGame } = controller.getState();
  if (!from || !Number.isInteger(from?.level) || !Number.isInteger(from?.step) 
      || !to || !Number.isInteger(to?.level) || !Number.isInteger(to?.step) 
      || !selectedIHGame
  ) return console.log(
      `Not enough input. from: ${from.toString()}, to: ${to.toString()},` 
      + ` selectedIHGame: ${selectedIHGame.toString()}`);

  let { levels } = selectedIHGame;
  if (!levels) return console.log("No levels.");

  if (!levels?.[from.level] || !levels[from.level]?.steps?.[from.step] 
      || !levels?.[to.level] || (to.step !== 0 && !levels[to.level]?.steps?.[to.step])
  ) return console.log("either from or to not in levels data");

  if (from.level !== to.level) {
    let fromItem = levels[from.level].steps.splice(from.step, 1);
    if (fromItem?.length !== 1) return console.log("Encounter problem removing from item");
    levels[to.level].steps.splice(to.step, 0, fromItem[0]);
    controller.db.collection("IHGame").doc(selectedIHGame.id).update({levels});
  } else if (from.step !== to.step) {
    let newLevelSteps = [];
    for (var i = 0; i < levels[from.level].steps.length; i++) {
      if (i === from.step) {
        continue;
      } else if (i !== to.step) {
        newLevelSteps.push(levels[from.level].steps[i]);
      } else if (to.step < from.step) {
        newLevelSteps.push(levels[from.level].steps[from.step]);
        newLevelSteps.push(levels[from.level].steps[to.step]);
      } else if (to.step > from.step) {
        newLevelSteps.push(levels[from.level].steps[to.step]);
        newLevelSteps.push(levels[from.level].steps[from.step]);
      }
    }
    levels[from.level].steps = newLevelSteps;    
    controller.db.collection("IHGame").doc(selectedIHGame.id).update({levels});
  }
}

export const EditIHGameStep: Handler = async (controller, params) => {
  let { selectedIHGame } = controller.getState();
  let {selectedLevel, selectedStep, editedStepData } = params;
  if (!selectedIHGame || !selectedIHGame?.id || selectedLevel === null || selectedStep === null || !editedStepData)
    console.log("Insufficient params");
  let { id, levels } = selectedIHGame;
  if (editedStepData?.stepId)
    levels[selectedLevel].steps[selectedStep].stepId = editedStepData?.stepId;
  if (editedStepData?.nodeId)
    levels[selectedLevel].steps[selectedStep].nodeId = editedStepData?.nodeId;
  if (editedStepData?.guide)
    levels[selectedLevel].steps[selectedStep].guide = editedStepData?.guide;
  if (editedStepData?.mission)
    levels[selectedLevel].steps[selectedStep].mission = editedStepData?.mission;

  if (editedStepData?.newDownloadUrl) {
    let originalUrl = levels[selectedLevel].steps[selectedStep].screen.url;

    // Upload new image
    const data = await fetch(editedStepData.newDownloadUrl);
    const arrayBuffer = await data.arrayBuffer();
    const assetDoc = await controller.db.collection("IHGameAsset")
      .add({gameId: selectedIHGame.id, type: "screen", url: null});
    const url = `gs://ismor-xd/IHGame/${selectedIHGame.xdId}/${selectedIHGame.id}` 
                + `/screen/${assetDoc.id}.${editedStepData.newDownloadType}`;
    await controller.storage.refFromURL(url).put(arrayBuffer);
    assetDoc.update({url});
     // Update level data
     levels[selectedLevel].steps[selectedStep].screen.url = url;

    // Mark new asset 'removed'
    let parts = originalUrl?.split("/");
    let originalAssetDocId = parts[parts.length - 1].split(".")[0];
    controller.db.collection("IHGameAsset").doc(originalAssetDocId)
      .update({removed: true});
  }
  if (editedStepData?.newLessonDownloadUrl || editedStepData?.newLessonDownloadUrl === null) {
    let originalUrl = levels[selectedLevel].steps[selectedStep]?.lesson?.url;

    if (editedStepData?.newLessonDownloadUrl !== null) {
      // Upload new image
      const data = await fetch(editedStepData.newLessonDownloadUrl);
      const arrayBuffer = await data.arrayBuffer();
      const assetDoc = await controller.db.collection("IHGameAsset")
        .add({gameId: selectedIHGame.id, type: "screen", url: null});
      const url = `gs://ismor-xd/IHGame/${selectedIHGame.xdId}/${selectedIHGame.id}` 
                  + `/screen/${assetDoc.id}.${editedStepData.newLessonDownloadType}`;
      await controller.storage.refFromURL(url).put(arrayBuffer);
      assetDoc.update({url});
      // Update level data
      levels[selectedLevel].steps[selectedStep].lesson = {url};
    } else {
      levels[selectedLevel].steps[selectedStep].lesson = null;
    }

    // Mark new asset 'removed'
    if (originalUrl) {
      let parts = originalUrl?.split("/");
      let originalAssetDocId = parts[parts.length - 1].split(".")[0];
      controller.db.collection("IHGameAsset").doc(originalAssetDocId)
        .update({removed: true});
    }
  }
  controller.db.collection("IHGame").doc(id).update({levels});
}

export const DeleteIHGameStep: Handler = (controller, {selectedLevel, selectedStep}) => {
  let { selectedIHGame } = controller.getState();
  if (selectedLevel === null || selectedStep === null || !selectedIHGame || !selectedIHGame?.id)
    return console.log("Insufficient params");
  let url = selectedIHGame.levels?.[selectedLevel]?.steps?.[selectedStep]?.screen?.url;
  if (!url) return console.log("No url");
  let assetId = url?.split("/")?.[7]?.split(".")[0];
  if (!assetId) return console.log("No asset id");
  controller.db.collection("IHGameAsset").doc(assetId).update({removed: true});
  let newLevels = JSON.parse(JSON.stringify(selectedIHGame.levels))
  newLevels[selectedLevel].steps = newLevels[selectedLevel].steps.filter(
    (step: any, index: number) => index !== selectedStep);
  controller.db.collection("IHGame")
    .doc(selectedIHGame.id)
    .update({levels: newLevels});
}

export const AddRectTarget: Handler = (controller, params) => {
  let state = controller.getState();
  let {selectedLevel, selectedStep, x, y, width, height} = params;
  if (!Number.isInteger(selectedLevel) || !Number.isInteger(selectedStep)
      || Number.isNaN(Number(x)) || Number.isNaN(Number(y))
      || Number.isNaN(Number(width)) || Number.isNaN(Number(height))
  ) {
    return;
  }
  let selectedIHGame = Object.assign({}, state.selectedIHGame);
  selectedIHGame = {
    ...selectedIHGame,
    levels: (selectedIHGame?.levels || []).map((level: any, levelIndex: number) => (
      levelIndex !== selectedLevel ? level
        : {
          ...level,
          steps: level.steps.map((step: any, stepIndex: number) => (
            stepIndex !== selectedStep ? step
            : {
              ...step,
              target: [x, y, width, height]
            }
          ))
        }
    ))
  }
  controller.db.collection("IHGame").doc(selectedIHGame.id)
    .update({levels: cleanLevelData(selectedIHGame.levels)});
  controller.setState({selectedIHGame});
}

// To deprecate
export const UploadIHGameAsset: Handler = async (controller, params) => {
  let selectedIHGame = Object.assign({}, controller.getState().selectedIHGame);
  if (!selectedIHGame?.id || !Number.isInteger(params.level) || !params.url 
    || !params.assetType || !params.fileExtension || !params.addedScreenName
  ) return console.log(`incomplete params: ${JSON.stringify(params)}`);
  const data = await fetch(params.url);
  const arrayBuffer = await data.arrayBuffer();
  const assetDoc = await controller.db.collection("IHGameAsset")
    .add({gameId: selectedIHGame.id, type: params.assetType, url: null});
  const url = `gs://ismor-xd/IHGame/${selectedIHGame.xdId}/${selectedIHGame.id}` 
              + `/${params.assetType}/${assetDoc.id}.${params.fileExtension}`;
  await controller.storage.refFromURL(url).put(arrayBuffer);
  assetDoc.update({url});
  selectedIHGame.levels[params.level].steps.push({
    guide: params.addedScreenName,
    screen: { url: url },
    target: [0, 0, 1, 1],
  });
  controller.db.collection("IHGame").doc(selectedIHGame.id).update({
    levels: selectedIHGame.levels
  });
  // controller.setState({selectedIHGame});
}

// Publish & Game Play--------------------------------------------------------------------------------------------------------
export const IHGameTestRun: Handler = async (controller, {setGamePlayStatus}) => {
  let selectedIHGame = controller.getState().selectedIHGame;
  if (!selectedIHGame) return console.log("No selectedIHGame");
  let publishedIHGame = JSON.parse(JSON.stringify(selectedIHGame));
  setGamePlayStatus({
    status: "started",
    messages: [ "Please wait while preparing game" ]
  });
  publishedIHGame = await PackAsset(
    controller, {publishedIHGame, setGamePlayStatus});
  publishedIHGame = PrepareAsset(
    controller, {publishedIHGame, setGamePlayStatus});
  controller.setState({publishedIHGame});
  setGamePlayStatus(null);
}

export const PublishIHGame: Handler = async (controller, {setGamePlayStatus}) => {
  let storageweb = (controller as any).storageweb as app.storage.Storage;
  if (!storageweb) return console.log("No storageweb. Can't publish");

  let dbweb = (controller as any).dbweb as app.firestore.Firestore;
  if (!dbweb) return console.log("No dbweb. Can't publish");
  
  let publishedIHGame = Object.assign({}, controller.getState().selectedIHGame);
  if (!publishedIHGame) return console.log("No publishedIHGame. Can't publish.");
  
  setGamePlayStatus({
    status: "started",
    messages: [ "Please wait while preparing game" ]
  });
  publishedIHGame = await PackAsset(
    controller, {publishedIHGame, setGamePlayStatus});
  let publishVersion = publishedIHGame.nextVersion;
  let publishUrl = `gs://ismor-xd-publish/IHGamePublished/${publishedIHGame.xdId}/` 
                  + `${publishedIHGame.id}/${publishVersion}/game.json.zip`;
  
  // Set publishUrl & nextVersion to this one for file upload
  publishedIHGame.publishUrl = publishUrl;
  publishedIHGame.nextVersion = publishVersion + 1;
  
  // Zip
  const zip = JSZip();
  zip.file("game.json", new Blob([JSON.stringify(publishedIHGame, null, 1)], {type: "application/json"}));
  let content = await zip.generateAsync({type: "blob", compression: "DEFLATE"});
  
  storageweb.refFromURL(publishUrl).put(content)
    .on(app.storage.TaskEvent.STATE_CHANGED, {
      next: (snapshot: any) => {
        setGamePlayStatus({
          status: "uploading",
          messages: [ `Uploading ${(snapshot.bytesTransferred / snapshot.totalBytes * 100).toFixed(2)}%` ]
        });
      },
      error: (error: any) => {
        console.log(error);
      },
      complete: async () => {
        controller.db.collection("IHGame").doc(publishedIHGame.id).update({
          nextVersion: publishVersion + 1,
          publishUrl: publishUrl
        });
        
        let docweb = await dbweb.collection("IHGame").doc(publishedIHGame.id).get();
        if (!docweb.exists) {
          docweb.ref.set({
            name: publishedIHGame.name,
            xdId: publishedIHGame.xdId,
            publishUrl: publishUrl,
            users: []
          })
        } else {
          docweb.ref.update({publishUrl});
        }
        
        publishedIHGame = {
          ...publishedIHGame,
          nextVersion: publishVersion + 1,
          publishUrl: publishUrl
        }
        controller.setState({selectedIHGame: publishedIHGame});
        setGamePlayStatus(null);
      }
    });  
}

export const SelectPublishedIHGame: Handler 
  = async (controller, {id, setGamePlayStatus, setMode}) => 
{
  let storageweb = (controller as any).storageweb as app.storage.Storage;
  if (!storageweb) return console.log("No storageweb. Can't get the game");

  if (!id) return;
  let publishedIHGame = (await controller.db.collection("IHGame").doc(id).get())?.data();
  if (!publishedIHGame) return;
  
  if (!publishedIHGame?.publishUrl) {
    setGamePlayStatus({status: "error", messages: [ "Game not published yet" ]});  
    setTimeout(() => { setGamePlayStatus(null) }, 2000);
    return console.log("Game not published yet")
  };
  
  setGamePlayStatus({status: "started", messages: [ "Please wait while preparing game" ]});  
  
  let url = await storageweb.refFromURL(publishedIHGame.publishUrl)
    .getDownloadURL();

  axios.get(url, {
    responseType: "blob",
    onDownloadProgress: (event: any) => {
      setGamePlayStatus({
        status: "progress", 
        messages: [ `Downloading ${(event?.loaded / event?.total * 100).toFixed(2)}%` ]
      });  
    }
  }).then(async (res: AxiosResponse) => {
    // let content = await (await fetch(url)).blob();
    let content = res.data;
    const zip = JSZip();
    await zip.loadAsync(content);
    let data = await zip.file("game.json")?.async("string");
    if (!data) return;
    try {
      let publishedIHGame = JSON.parse(data);
      if (!publishedIHGame) return;
      publishedIHGame = PrepareAsset(
        controller, {publishedIHGame, setGamePlayStatus});
      controller.setState({publishedIHGame});
      setGamePlayStatus(null);
      setMode("Play");
    } catch (e: any) {
      setGamePlayStatus({status: "error", messages: [ "Error convert game data", e.toString() ]});  
      setTimeout(() => { setGamePlayStatus(null) }, 2000);
      console.log(`Error convert game data ${e.toString()}`);
    }
  }).catch((err: any) => {
    setGamePlayStatus({status: "error", messages: [ "Error fetching game data", err.toString() ]});  
    setTimeout(() => { setGamePlayStatus(null) }, 2000);
    console.log(`Error fetching game data ${err.toString()}`);
  });
}

const PackAsset:Handler = 
  async (controller, {publishedIHGame, setGamePlayStatus}) => {
  let gamePlayStatus: {status: string, messages: string[]} = { status: "download", messages: [] };
  // let levelCount = publishedIHGame.levels.length;
  let requestParams = publishedIHGame.levels.flatMap((level: any, levelIndex: number) => ([
    {data: level.sound.failed, type: "failed", levelIndex},
    {data: level.sound.shoot, type: "shoot", levelIndex},
    {data: level.preamble, type: "preamble", levelIndex},
    ...level.steps.map((step: any, stepIndex: number) => 
      ({data: step.screen, type: "step", stepIndex, levelIndex})),
    ...level.steps.map((step: any, stepIndex: number) => 
      ({data: step?.lesson, type: "lesson", stepIndex, levelIndex})
      ).filter((stepData: any) => stepData.data)
  ]));
  let totalCount = requestParams.length;
  let doneCount = 0;
  let dataUrls = await Promise.all(requestParams.map(async (param: any) => {
    let output = {...param, dataUrl: await GetDataUrl(controller, param.data)};
    doneCount += 1;
    gamePlayStatus.messages[0] = `Preparing data: ${doneCount} of ${totalCount}`;
    setGamePlayStatus({...gamePlayStatus});
    return output
  }));
  for (const requestData of dataUrls) {
    if (requestData.type === "failed") {
      publishedIHGame.levels[requestData.levelIndex].sound.failed.dataUrl 
        = requestData.dataUrl;
    } else if (requestData.type === "shoot") {
      publishedIHGame.levels[requestData.levelIndex].sound.shoot.dataUrl 
        = requestData.dataUrl;
    } else if (requestData.type === "preamble") {
      publishedIHGame.levels[requestData.levelIndex].preamble.dataUrl 
        = requestData.dataUrl;
    } else if (requestData.type === "step") {
      publishedIHGame.levels[requestData.levelIndex].steps[requestData.stepIndex].screen.dataUrl 
        = requestData.dataUrl;
    } else if (requestData.type === "lesson") {
      publishedIHGame.levels[requestData.levelIndex].steps[requestData.stepIndex].lesson.dataUrl 
        = requestData.dataUrl;
    }
  }
  return publishedIHGame
}

const GetDataUrl:Handler = async (controller, item: any) => {
  item = await GetStorageInfo(controller, item);
  let arrayBuffer = await (await fetch(item.downloadUrl)).arrayBuffer();
  var base64 = btoa(
    new Uint8Array(arrayBuffer)
      .reduce((data, byte) => data + String.fromCharCode(byte), '')
  );
  return `data:image/${item.fileType};base64,${base64}`
}

const GetStorageInfo:Handler = async (controller, item: any) => {
  let downloadUrl = await controller.storage.refFromURL(item.url).getDownloadURL();
  let parts = downloadUrl.split(".")
  let fileType = parts[parts.length - 1].split("?")[0];
  return { ...item, downloadUrl, fileType }
}

const PrepareAsset: Handler = 
  (controller, {publishedIHGame, setGamePlayStatus}) => {
  let gamePlayStatus: {status: string, messages: string[]} = { status: "download", messages: [] };
  let levelCount = publishedIHGame.levels.length;
  for (var levelIndex=0; levelIndex< publishedIHGame.levels.length; levelIndex++) {
    gamePlayStatus.messages.push(`Encoding level: ${levelIndex + 1} / ${levelCount}`);
    setGamePlayStatus({...gamePlayStatus});
    publishedIHGame.levels[levelIndex].sound.failed.objectUrl = 
      dataURItoObjectURL(publishedIHGame.levels[levelIndex].sound.failed.dataUrl);
    publishedIHGame.levels[levelIndex].sound.shoot.objectUrl =
      dataURItoObjectURL(publishedIHGame.levels[levelIndex].sound.shoot.dataUrl);
    publishedIHGame.levels[levelIndex].preamble.objectUrl = 
      dataURItoObjectURL(publishedIHGame.levels[levelIndex].preamble.dataUrl);
    for (var stepIndex=0; stepIndex < publishedIHGame.levels[levelIndex].steps.length; stepIndex++) {
      publishedIHGame.levels[levelIndex].steps[stepIndex].screen.objectUrl = 
        dataURItoObjectURL(publishedIHGame.levels[levelIndex].steps[stepIndex].screen.dataUrl);
      if (publishedIHGame.levels[levelIndex].steps[stepIndex]?.lesson?.dataUrl) {
        publishedIHGame.levels[levelIndex].steps[stepIndex].lesson.objectUrl = 
          dataURItoObjectURL(publishedIHGame.levels[levelIndex].steps[stepIndex].lesson.dataUrl);
      }
    }
  }
  return publishedIHGame;
}

const dataURItoObjectURL = (dataURI: string) => {
  var arr = dataURI.split(',');
  var mime = arr[0].match(/:(.*?);/)?.[1];
  var bstr = atob(arr[1])
  var n = bstr.length
  var u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  let blob = new Blob([u8arr], { type: mime });
  return URL.createObjectURL(blob);
}

const cleanLevelData = (levels: any[]) => {
  let levels_ = levels.map((level: any) => ({
    ...level,
    preamble: { url: level.preamble.url },
    sound: {
      failed: { url: level.sound.failed.url },
      shoot: { url: level.sound.shoot.url }
    },
    steps: level.steps.map((step: any) => ({
      ...step,
      screen: { url: step.screen.url }
    }))
  }));
  return levels_
}

// Play session -------------------------------------------------------------------------------------------------------------
export const IHGameNewPlaySession: Handler = async (controller, params) => {
  let dbweb = (controller as any).dbweb as app.firestore.Firestore;
  if (!dbweb) return console.log("No dbweb. Can't create IHGamePlaySession");

  const publishedIHGame = controller.getState().publishedIHGame;
  const dests = publishedIHGame.levels
    .flatMap((level: any, levelIndex: number) => {
      return level.steps.map((step: any, stepIndex: number) => (
              { level: levelIndex, step: stepIndex }))
    });
  let lastStep = dests[dests.length - 1];
  let playSession: IHGamePlaySession = {
    user: controller.user.email,
    xdId: publishedIHGame.xdId,
    gameId: publishedIHGame.id,
    publishUrl: publishedIHGame.publishUrl,
    datetime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    fullScore: STEP_FULL_SCORE * publishedIHGame.levels
            .map((level: any) => level.steps.length)
            .reduce((acc: number, cur: number) => acc + cur, 0),
    lastStep,
    logs: []
  }
  const res = await dbweb.collection("IHGamePlaySession").add(playSession);
  playSession.id = res.id;
  controller.setState({ihGamePlaySession: playSession});
}

export const IHGameLogPlaySession: Handler = async (controller, params) => {
  let storageweb = (controller as any).storageweb as app.storage.Storage;
  if (!storageweb) return console.log("No storageweb. Can't publish");
  
  if (!params.playSession) return console.log("No playSession.");
  
  let playSession: IHGamePlaySession = Object.assign({}, params.playSession);
  let url = `gs://ismor-xd-publish/IHGamePlaySession/${playSession.xdId}/` 
            + `${playSession.gameId}/${playSession.datetime.substring(0, 7)}/` 
            + `${playSession.id}.json`;
  storageweb.refFromURL(url).putString(JSON.stringify(playSession, null, 1));
  controller.setState({ihGamePlaySession: playSession});
}

export const GetGameStat: Handler = async (controller, {xdId, gameId, all}) => {
  if (!xdId || (!gameId && !all)) return console.log("Insufficient params");
  let storageweb = (controller as any).storageweb as app.storage.Storage;
  if (!storageweb) return console.log("No storageweb. Can't get stat"); 
  if (all) {
    let url = `gs://ismor-xd-publish/IHGamePlaySession/${xdId}`;
    let ihGameStatList = 
      (await Promise.all(
      (await Promise.all(
        (await storageweb.refFromURL(url).listAll())
        .prefixes
        .flatMap(async (prefix: any) => (await prefix.listAll()).prefixes) 
      ))
      .flatMap((prefixes: any) => prefixes)
      .map(async (prefix: any) => (await prefix.listAll()).items)
      ))
      .flatMap((items: any) => 
        items.map((item: any) => `gs://${item.bucket}/${item.fullPath}`));
    controller.setState({ihGameStatList});
  } else {
    let url = `gs://ismor-xd-publish/IHGamePlaySession/${xdId}/${gameId}`;
    let ihGameStatList = (await Promise.all((await storageweb.refFromURL(url).listAll())
      .prefixes.flatMap(async (prefix: any) => 
        (await storageweb.refFromURL(`gs://${prefix.bucket}/${prefix.fullPath}`).listAll())
          .items.flatMap((item: any) => `gs://${item.bucket}/${item.fullPath}`)))
      ).flatMap((urlList: any) => urlList);
    controller.setState({ihGameStatList});
  }
}
