import { useState } from "react";
import { Button, Icon, Input } from "semantic-ui-react";

export default function ClientList(props: any) {
  const [mode, setMode] = useState("List");
  const [selectedClient, setSelectedClient] = useState<any>(null);

  const setForm = (id: string | null, parentId?: string, ) => {
    setMode(id ? "Edit" : "Add");
    if (id) {
      setSelectedClient({
        id: id,
        ...props.clientDict[id]
      });
    } else {
      setSelectedClient({
        name: "", 
        parentId: parentId || null
      });
    }
  }

  return(
    <div>
      {mode === "List" ?
      <>
        <div
          onClick={(e: any) => setForm(null)}
          style={{cursor: "pointer"}}>
          <Icon name="plus" size="small" />
          <span>Add New Client</span>
        </div>
        {Object.values(props.clientDict)
        .sort((a: any, b: any) => a.seq < b.seq ? -1 : 1)
        .map((client: any, index: number) => (
          <div 
            key={index} 
            style={{
              cursor: "pointer", borderBottom: "solid #cccccc 1px", 
              backgroundColor:  client.onGoingCount > 0 ? "#FFE7F1" : "white",
              display: "grid", gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr 1fr 1fr 2fr",

            }}>
            <div 
              style={{
                backgroundColor: "white",
                paddingLeft: `${client.level * 12}px`,
              }}>
              <div style={{backgroundColor:  client.onGoingCount > 0 ? "#FFE7F1" : "white"}}>
                <span
                  onClick={(e: any) => setForm(client.id)}>
                  {client.name}
                </span>
                <Icon name="plus" size="small" 
                  onClick={(e: any) => setForm(null, client.id)}
                />
              </div>
            </div>
            <div 
              style={{ display: "flex", justifyContent: "flex-end" }}>
              <div
                style={{
                  padding: "0 5px 0 5px"
                }}>
                {`${client.onGoingCount} / ${client.salesCount} deals`}
              </div>
            </div>
            <div style={{textAlign: "right", paddingRight: "10px"}}>
              {client.onGoingValue?.toLocaleString('en-US', {maximumFractionDigits: 0})}
            </div>
            <div style={{textAlign: "right", paddingRight: "10px"}}>
              {client.salesValue?.toLocaleString('en-US', {maximumFractionDigits: 0})}
            </div>
            <div style={{textAlign: "right"}}>
              {`${client.onGoingCountConsol} / ${client.salesCountConsol} consol deals`}
            </div>
            <div style={{textAlign: "right", paddingRight: "10px"}}>
              {client.onGoingValueConsol?.toLocaleString('en-US', {maximumFractionDigits: 0})}
            </div>
            <div style={{textAlign: "right", paddingRight: "10px"}}>
              {client.salesValueConsol?.toLocaleString('en-US', {maximumFractionDigits: 0})}
            </div>
            <div></div>
          </div>
        ))}
      </>
      : ["Add", "Edit"].includes(mode) ?
      <div>
        <span>Name</span>
        <Input 
          size="mini" 
          value={selectedClient.name} 
          onChange={(e: any) => {
            setSelectedClient({
              ...selectedClient,
              name: e.target.value
            });
          }}
        />
        <Button 
          size="mini"
          disabled={!selectedClient?.name}
          onClick={(e: any) => {
            if (selectedClient?.id) {
              props.onEvent({
                message: "EditExistingClient", 
                params: { 
                  client: { 
                    id: selectedClient.id,
                    name: selectedClient.name, 
                    parentId: selectedClient.parentId || null 
                  }
                }
              });
            } else {
              props.onEvent({
                message: "AddNewClient", 
                params: { 
                  client: { 
                    name: selectedClient.name, 
                    parentId: selectedClient.parentId || null 
                  }
                }
              });
            }
            setSelectedClient(null);
            setMode("List")
          }}>
          Save
          </Button>
      </div>
      :
      <></>
      }
    </div>  
  )
}
