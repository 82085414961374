export default function FileExplorer(props: any) {
  return (
    <div style={{padding: "15px", maxHeight: "80vh", overflow: "auto"}}>
      {(props.videoPathInfo?.items || [])
      .map((item: any, index: number) => (
      <div 
        key={index}
        style={{padding: "5px"}}
        onClick={async (e: any) => {
          props.selectFileItem(item);
        }}>
        <div style={{
            color: item.isFile ? 
              ((props.addedType === "video" && item.path.includes(".mp4")) ? "green" 
                : (props.addedType === "image" && item.path.substring().includes(".png")) ? "green" 
                : (["audio", "marginFiller"].includes(props.addedType) && item.path.substring().includes(".wav")) ? "green" 
                : "red"
              )
              : "black",
            fontWeight: item.isFile ? "normal" : "bold",
          }}> 
          {item.display}
        </div>
      </div>))}
    </div>
  )
}