export const to = (promise: Promise<any>) => {
    return promise
      .then(data => ([data, undefined]))
      .catch(error => Promise.resolve([undefined, error]));
}

export const toUser = (email: any) => {
  let user = email.split("@")[0];
  if (user === "natthatanika") {
    user = "ploynita";
  }
  return user;
}
