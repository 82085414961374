import React, { useState } from 'react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { Bar } from 'react-chartjs-2';
import { Icon, Input } from 'semantic-ui-react';
import RedmineStatus from './RedmineStatus';
import ClassifyProject from './ClassifyProject';
import ClassifyAssigned from './ClassifyAssigned';
import RedminePerformance from './RedminePerformance';
import * as RedmineI from './RedmineInterface'
import Tab from '../../../react-lib/frameworks/TabMinimal';
import NotiTest from './NotiTest';
import NotiDetails from './NotiDetails';
import PriorityList from './PriorityList';
import AssignedPriority from './AssignedPriority';
import PerformanceCommon from "./PerformanceCommon";
// import PerformanceForUser from "./PerformanceForUser";
import ActiveIssues from './ActiveIssues';
import ProjectMonthly from './ProjectMonthly';
import TestReview from 'ismor-lib/apps/XD2/TestReview';


// Implementation -----------------------------------------------------------
export default class extends React.Component<
  {  
    setProp: any,
    onEvent: (e: RedmineI.Event) => any,
    functions: any,
    mobileScreen?: boolean,
    notifications?: any,
    hasperms?: any,
    Perf?: any,
    python?: any,
    pythonLoaded?: boolean,
    pythonError?: any,
    bokehLoaded?: boolean,
    supervise?: string[],
    duck?: any,
    duckCon?: any,
    duckLoaded?: boolean,
  } 
  & RedmineI.State,
  {
    showMenu: boolean,
  }>
{   
    
  constructor(props: any) {
    super(props)
    this.state = {
      showMenu: false
    }
  }

  componentDidMount = () => {
    this.props.onEvent({ message: "RemineDidMount", params: {} });
    if (this.props.hasperms("priority")) {
      this.props.setProp("redmineSub", "PriorityList");
    }
  }

  toggleMenu = (callback?: any) => {
    this.setState({
      showMenu: !this.state.showMenu
    }, callback)
  }

  render() {
    return (
    <div 
      style={{ 
        height: "95vh", 
        display: "flex",
        flexDirection: this.props.mobileScreen ? "column" : "row"
        // display: "grid", 
        // gridTemplateColumns: "150px 1fr"
      }}>
      <div 
        style={{
          // gridColumn: 1, 
          width: this.props.mobileScreen ? "100%" : "150px", 
          height: this.props.mobileScreen ? "auto" : "100%", 
          borderRight: "solid #dddddd 0.5px"
        }}>
        {this.props.hasperms("priority") && 
        <SubMenu 
          sub={this.props.redmineSub}
          subMenu="PriorityList"
          name="Priority List"
          setProp={this.props.setProp}
          onEvent={this.props.onEvent}
          mobileScreen={this.props.mobileScreen}
          showMenu={this.state.showMenu}
          toggleMenu={this.toggleMenu}
          refresh={true}
          onRefresh={(e: any) => {
            this.props.onEvent({message: "GetPriorityDict", params: {}});
          }}
        />}
        <SubMenu 
          sub={this.props.redmineSub}
          subMenu="ActiveIssues"
          name="Active Issues"
          setProp={this.props.setProp}
          onEvent={this.props.onEvent}
          mobileScreen={this.props.mobileScreen}
          showMenu={this.state.showMenu}
          toggleMenu={this.toggleMenu}
          refresh={true}
          onRefresh={(e: any) => {
            this.props.onEvent({
              message: "GetActiveIssues",
              params: {}
            });
          }}
        />
        <SubMenu
          sub={this.props.redmineSub}
          subMenu="AssignedPriority"
          name="Assigned Priority"
          setProp={this.props.setProp}
          onEvent={this.props.onEvent}
          mobileScreen={this.props.mobileScreen}
          showMenu={this.state.showMenu}
          toggleMenu={this.toggleMenu}
          refresh={true}
          onRefresh={(e: any) => {
            this.props.onEvent({message: "GetPriorityDict", params: {}});
          }}
        />
        <SubMenu 
          sub={this.props.redmineSub}
          subMenu="byAssigned"
          name="Assigned Classify"
          setProp={this.props.setProp}
          onEvent={this.props.onEvent}
          mobileScreen={this.props.mobileScreen}
          showMenu={this.state.showMenu}
          toggleMenu={this.toggleMenu}
          refresh={true}
          onRefresh={(e: any) => {
            this.props.setProp("byClassifyData", []);
            this.props.onEvent({
              message: "GetRedmineIssueSummary",
              params: {}
            });
          }}
        /> 
        <SubMenu 
          sub={this.props.redmineSub}
          subMenu="byProject"
          name="Project Classify"
          setProp={this.props.setProp}
          onEvent={this.props.onEvent}
          mobileScreen={this.props.mobileScreen}
          showMenu={this.state.showMenu}
          toggleMenu={this.toggleMenu}
          refresh={true}
          onRefresh={(e: any) => {
            this.props.setProp("byClassifyData", []);
            this.props.onEvent({
              message: "GetRedmineIssueSummary",
              params: {}
            });
          }}
        />
        <SubMenu 
          sub={this.props.redmineSub}
          subMenu="ProjectMonthly"
          name="Project Monthly"
          setProp={this.props.setProp}
          onEvent={this.props.onEvent}
          mobileScreen={this.props.mobileScreen}
          showMenu={this.state.showMenu}
          toggleMenu={this.toggleMenu}
        />
        <SubMenu 
          sub={this.props.redmineSub}
          subMenu="PerformanceSelf"
          name="Performance Self"
          setProp={this.props.setProp}
          onEvent={this.props.onEvent}
          mobileScreen={this.props.mobileScreen}
          showMenu={this.state.showMenu}
          toggleMenu={this.toggleMenu}
        />
        {this.props.hasperms("perfreview") &&
        <SubMenu 
          sub={this.props.redmineSub}
          subMenu="PerformanceReview"
          name="Performance Review"
          setProp={this.props.setProp}
          onEvent={this.props.onEvent}
          mobileScreen={this.props.mobileScreen}
          showMenu={this.state.showMenu}
          toggleMenu={this.toggleMenu}
        />}
        <SubMenu 
          sub={this.props.redmineSub}
          subMenu="TestReview"
          name="Test Review"
          setProp={this.props.setProp}
          onEvent={this.props.onEvent}
          mobileScreen={this.props.mobileScreen}
          showMenu={this.state.showMenu}
          toggleMenu={this.toggleMenu}
        />
        <SubMenu 
          sub={this.props.redmineSub}
          subMenu="charts"
          name="Throughput All"
          setProp={this.props.setProp}
          onEvent={this.props.onEvent}
          mobileScreen={this.props.mobileScreen}
          showMenu={this.state.showMenu}
          toggleMenu={this.toggleMenu}
          refresh={true}
          onRefresh={(e: any) => {
          this.props.setProp("chartData", []);
          this.props.onEvent({
            message: "GetThroughput",
            params: {}
          })
        }}
        />
        <SubMenu 
          sub={this.props.redmineSub}
          subMenu="performance"
          name="Throughput Each"
          setProp={this.props.setProp}
          onEvent={this.props.onEvent}
          mobileScreen={this.props.mobileScreen}
          showMenu={this.state.showMenu}
          toggleMenu={this.toggleMenu}
          refresh={true}
          onRefresh={(e: any) => {
            this.props.setProp("performanceData", []);
            this.props.onEvent({
              message: "GetRedminePerformance",
              params: {}
            })
          }}
        />
        
        {this.props.hasperms("notitest") && // ----------------------- Noti Test -------------------------
        <>
        <SubMenu 
          sub={this.props.redmineSub}
          subMenu="NotiDetails"
          name="Noti Details"
          setProp={this.props.setProp}
          onEvent={this.props.onEvent}
          mobileScreen={this.props.mobileScreen}
          showMenu={this.state.showMenu}
          toggleMenu={this.toggleMenu}
        />
        <SubMenu 
          sub={this.props.redmineSub}
          subMenu="NotiTest"
          name="Noti Test"
          setProp={this.props.setProp}
          onEvent={this.props.onEvent}
          mobileScreen={this.props.mobileScreen}
          showMenu={this.state.showMenu}
          toggleMenu={this.toggleMenu}
        />
        </>}
        
        
        
        {false && // ----------------------------------------Hidden Menu---------------------------------
        <>
        <SubMenu 
          sub={this.props.redmineSub}
          subMenu="Calendar"
          name="Activity Calendar"
          setProp={this.props.setProp}
          onEvent={this.props.onEvent}
          mobileScreen={this.props.mobileScreen}
          showMenu={this.state.showMenu}
          toggleMenu={this.toggleMenu}
        />
        <SubMenu 
          sub={this.props.redmineSub}
          subMenu="byRelease"
          name="Release List"
          setProp={this.props.setProp}
          onEvent={this.props.onEvent}
          mobileScreen={this.props.mobileScreen}
          showMenu={this.state.showMenu}
          toggleMenu={this.toggleMenu}
          refresh={true}
          onRefresh={(e: any) => {
            this.props.setProp("byReleaseData", []);
            this.props.onEvent({
              message: "GetTaskList",
              params: {}
            })
          }}
        />
        <SubMenu 
          sub={this.props.redmineSub}
          subMenu="byResolved"
          name="Resolved List"
          setProp={this.props.setProp}
          onEvent={this.props.onEvent}
          mobileScreen={this.props.mobileScreen}
          showMenu={this.state.showMenu}
          toggleMenu={this.toggleMenu}
          refresh={true}
          onRefresh={(e: any) => {
            this.props.setProp("byDateDataResolved", []);
            this.props.onEvent({
              message: "GetTaskList",
              params: {}
            })
          }}
        />
        <SubMenu 
          sub={this.props.redmineSub}
          subMenu="byCreate"
          name="Create List"
          setProp={this.props.setProp}
          onEvent={this.props.onEvent}
          mobileScreen={this.props.mobileScreen}
          showMenu={this.state.showMenu}
          toggleMenu={this.toggleMenu}
          refresh={true}
          onRefresh={(e: any) => {
            this.props.setProp("byDateDataCreate", []);
            this.props.onEvent({
              message: "GetTaskList",
              params: {}
            })
          }}
        />
        <SubMenu 
          sub={this.props.redmineSub}
          subMenu="byUpdate"
          name="Update List"
          setProp={this.props.setProp}
          onEvent={this.props.onEvent}
          mobileScreen={this.props.mobileScreen}
          showMenu={this.state.showMenu}
          toggleMenu={this.toggleMenu}
          refresh={true}
          onRefresh={(e: any) => {
            this.props.setProp("byDateDataUpdate", []);
            this.props.onEvent({
              message: "GetTaskList",
              params: {}
            })
          }}
        />
        {/* <SubMenu 
            sub={this.props.redmineSub}
            subMenu="byStatus"
            name="By Status"
            setProp={this.props.setProp}
            onEvent={this.props.onEvent}
            mobileScreen={this.props.mobileScreen}
            showMenu={this.state.showMenu}
            toggleMenu={this.toggleMenu}
        /> */}
        {/* <SubMenu 
            sub={this.props.redmineSub}
            subMenu="byProject"
            name="By Project"
            setProp={this.props.setProp}
            onEvent={this.props.onEvent}
            mobileScreen={this.props.mobileScreen}
            showMenu={this.state.showMenu}
            toggleMenu={this.toggleMenu}
        /> */}
        </>} 
        
      </div>

      {/* ---------------------------- Main Panel ----------------------------------------*/}
      <div 
        style={{
            // gridColumn: 2
            flex: 1
        }}>
        {!this.props.redmineLoaded ?
        <div>Please wait while data is being loaded .... </div>

        : this.props.redmineSub === "byStatus" ?
        <RedmineStatus 
          byStatusData={this.props.byStatusData}
        />

        : this.props.redmineSub === "byProject" ?
        <ClassifyProject
          onEvent={this.props.onEvent}
          byClassifyData={this.props.byClassifyData}
          mobileScreen={this.props.mobileScreen}
        />

        : this.props.redmineSub === "byAssigned" ?
        <ClassifyAssigned
          onEvent={this.props.onEvent}
          byClassifyData={this.props.byClassifyData}
          mobileScreen={this.props.mobileScreen}
          redmineUser={this.props.redmineUser}
        />

        : this.props.redmineSub === "performance" ?
        <RedminePerformance 
          performanceData={this.props.performanceData}
          mobileScreen={this.props.mobileScreen}
        />

        : this.props.redmineSub === "Calendar" ?
        this.props.Perf

        : this.props.redmineSub === "byRelease" ?
        <DataTable
          mobileScreen={this.props.mobileScreen}
          columnDef={this.props.mobileScreen ? 
                      byReleaseColumnDef.filter((column, column_index) => (
                          [0, 2, 3].includes(column_index)
                      )) : 
                      byReleaseColumnDef}
          data={this.props.byReleaseData || []}
          onEvent={this.props.onEvent}
        />

        : this.props.redmineSub === "byUpdate" ?
        <DataTable
          breakdownUpdate={true}
          mobileScreen={this.props.mobileScreen}
          columnDef={this.props.mobileScreen ? 
                      byDateColumnDef.filter((column, column_index) => (
                          [0, 2, 3].includes(column_index)
                      )) : 
                      byDateColumnDef}
          data={this.props.byDateDataUpdate || []}
          onEvent={this.props.onEvent}
        />

        : this.props.redmineSub === "byResolved" ?
        <DataTable
          breakdownUpdate={true}
          breakdownResolve={true}
          mobileScreen={this.props.mobileScreen}
          columnDef={this.props.mobileScreen ? 
                      byDateColumnDef.filter((column, column_index) => (
                          [0, 2, 3].includes(column_index)
                      )) : 
                      byDateColumnDef}
          data={this.props.byDateDataResolved || []}
          onEvent={this.props.onEvent}
        />

        : this.props.redmineSub === "byCreate" ?
        <DataTable
          breakdownUpdate={true}
          mobileScreen={this.props.mobileScreen}
          columnDef={this.props.mobileScreen ? 
                      byDateColumnDef.filter((column, column_index) => (
                          [0, 2, 3].includes(column_index)
                      )) : 
                      byDateColumnDef}
          data={this.props.byDateDataCreate || []}
          onEvent={this.props.onEvent}
        />

        : this.props.redmineSub === "byProject" ?
        <DataTable
          breakdownUpdate={true}
          mobileScreen={this.props.mobileScreen}
          columnDef={this.props.mobileScreen ? 
                      byProjectColumnDef.filter((column, column_index) => (
                          [0, 1, 3].includes(column_index)
                      )) : 
                      byProjectColumnDef}
          data={this.props.byProjectData || []}
          onEvent={this.props.onEvent}
        />
        
        : this.props.redmineSub === "ProjectMonthly" ?
        <ProjectMonthly
          onEvent={this.props.onEvent}
          setProp={this.props.setProp}
          redmineProjectList={this.props.redmineProjectList}
          projectIssues={this.props.projectIssues}
        />

        : this.props.redmineSub === "charts" ?
        <Charts
          data={this.props.chartData || []}
        />

        : this.props.redmineSub === "NotiDetails" ?
        <NotiDetails
          onEvent={this.props.onEvent}
          notifications={this.props.notifications}
          redmineUser={this.props.redmineUser}
        />

        : this.props.redmineSub === "NotiTest" ?
        <NotiTest
          onEvent={this.props.onEvent}
          notifications={this.props.notifications}
        />

        : this.props.redmineSub === "AssignedPriority" ?
        <AssignedPriority
          onEvent={this.props.onEvent}
          priorityDict={this.props.priorityDict}
          issueLatestUpdates={this.props.issueLatestUpdates}
          redmineUser={this.props.redmineUser}
          performanceForUser={this.props.performanceForUser}
        />

        : this.props.redmineSub === "PriorityList" ?
        <PriorityList
          onEvent={this.props.onEvent}
          priorityDict={this.props.priorityDict}
          issueLatestUpdates={this.props.issueLatestUpdates}
          dealOngoing={this.props.dealOngoing}
          python={this.props.python}
          pythonLoaded={this.props.pythonLoaded}
          pythonError={this.props.pythonError}
          bokehLoaded={this.props.bokehLoaded}
          duck={this.props.duck}
          duckCon={this.props.duckCon}
          duckLoaded={this.props.duckLoaded}
          performanceDevRaw={this.props.performanceDevRaw}
          devList={this.props.devList}
        />

        : this.props.redmineSub === "ActiveIssues" ?
        <ActiveIssues
          onEvent={this.props.onEvent}
          activeIssues={this.props.activeIssues}
        />

        : this.props.redmineSub === "PerformanceReview" ?
        <PerformanceCommon
          onEvent={this.props.onEvent}
          all={true}
          performance={this.props.performanceAll}
          performanceRaw={this.props.performanceAllRaw}
          issueLatestUpdates={this.props.issueLatestUpdates}
          supervise={this.props.supervise}
          python={this.props.python}
          pythonLoaded={this.props.pythonLoaded}
          pythonError={this.props.pythonError}
          bokehLoaded={this.props.bokehLoaded}
          duck={this.props.duck}
          duckCon={this.props.duckCon}
          duckLoaded={this.props.duckLoaded}
        />

        : this.props.redmineSub === "PerformanceSelf" ?
        <PerformanceCommon
          onEvent={this.props.onEvent}
          all={false}
          performance={this.props.performanceForUser}
          performanceRaw={this.props.performanceForUserRaw}
          issueLatestUpdates={this.props.issueLatestUpdates}
          python={this.props.python}
          pythonLoaded={this.props.pythonLoaded}
          pythonError={this.props.pythonError}
          bokehLoaded={this.props.bokehLoaded}
          duck={this.props.duck}
          duckCon={this.props.duckCon}
          duckLoaded={this.props.duckLoaded}
        />

        : this.props.redmineSub === "TestReview" ?
        <TestReview
          onEvent={this.props.onEvent}
          duckLoaded={this.props.duckLoaded}
          duckCon={this.props.duckCon}
          xdmaster={this.props.xdmaster}
          xdCases={this.props.xdCases}
        />

        :
        <></>
        }
      </div>
    </div>
    )
  }
}

// Subcomponents ------------------------------------------------------------
const Charts = (props: 
    { data: ChartRow[]
    }) => (
    props.data.length === 0 ? <div>Loading data...</div> :
    <div>
      {/* {props.data.map(item => (
          <div>{item.date} {item.created_on_count} {item.updated_on_count}</div>
      ))} */}
      <Bar 
        data={{
          labels: props.data.map(item => item.date),
          datasets: [
            {
              label: "Created",
              data: props.data.map(item => item.created_on_count),
              backgroundColor: "#fe7f9c"
            },
            // {
            //     label: "Updated",
            //     data: props.data.map(item => item.updated_on_count),
            //     backgroundColor: "#cfeefa"
            // },
            {
              label: "Resolved",
              data: props.data.map(item => item.resolved_on_count),
              backgroundColor: "#00aa00"
            }
          ]
        }}
      />
    </div>
)

const DataTable = (props:
  {
    mobileScreen?: boolean,
    columnDef: Column[],
    breakdownUpdate?: boolean,
    breakdownResolve?: boolean,
    data: any[],
    onEvent: (e: RedmineI.Event) => void
  }) => {

  const [filterString, setFilterString] = useState("");

  let dataDict = new Map<string, any>();
  
  dataDict.set("All", props.data);

  if (props.breakdownUpdate || props.breakdownResolve) {
    props.data.forEach((item: any) => {
      if (props.breakdownUpdate) {
        let updated = item.updated_on.split(" ")[0].substring(0, 4).toString();
        if (!dataDict.get(updated)) {
          dataDict.set(updated, []);
        }
        dataDict.get(updated).push(item)
      }
      if (props.breakdownResolve) {
        let assigned = item.assigned_user;
        if (assigned === "") {
          assigned = "Not assigned"
        }
        if (!dataDict.get(assigned)) {
          dataDict.set(assigned, []);
        }
        dataDict.get(assigned).push(item)
      }
    })
  }

  let dataList = Array.from(dataDict.entries());
  dataList.sort((a: any, b: any) => {
    let result = 0;
    if (a[0].substring(0, 2) === "20" && b[0].substring(0, 2) !== "20") {
      result = -1;
    } else if (b[0].substring(0, 2) === "20" && a[0].substring(0, 2) !== "20") {
      result = 1;
    } else if (a[0].substring(0, 2) === "20" && b[0].substring(0, 2) === "20") {
      result = a[0] < b[0] ? 1 : -1
    } else {
      result = a[0] < b[0] ? -1 : 1
    }
    return result
  });
      
  return(
  props.data.length === 0 ? <div>Loading data...</div> :
  <Tab
    tabWidth={150}
    mobileScreen={props.mobileScreen}
    // selectedTabIndex={1}
    data={dataList.map((item: any) => { 
      return(
        {
          key: item[0],
          name: item[0],
          render: () => {
            const [projectFilter, setProjectFilter] = useState<string>("");
            const projectFilters = 
              item[1]
              .map((row: any) => row?.project_for_filter)
              .filter((row: any) => row)
              .reduce((acc: any, cur: any) => {
                if (!acc.includes(cur))
                  acc.push(cur);
                return acc;
              }, [])
              .sort((a: any, b: any) => a < b ? -1 : 1);
            return(
              <div style={{ height: props.mobileScreen ? "92vh" : "90vh" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ padding: "5px" }}>Total {item[1].length}</div>
                <input
                  style={{ width: "30vw" }}
                  value={filterString}
                  onChange={(e: any) => setFilterString(e.target.value) }
                />
                {projectFilters.length > 0 &&
                <select
                  value={projectFilter}
                  onChange={(e: any) => setProjectFilter(e.target.value)}>
                  {[""].concat(projectFilters).map(
                    (filterItem: any, filterIndex: number) => (
                      <option key={filterIndex} value={filterItem}>{filterItem || "Reset"}</option>
                    ))}
                </select>}
              </div>
              <div 
                style={{ 
                  width: "100%", 
                  display: "grid", 
                  borderBottom: "solid #dddddd 1px",
                  gridTemplateColumns: getGridColumns(props.columnDef)
                }}>
                {props.columnDef.map((column, index) => (
                <div key={index} style={{gridColumn: index + 1}}>
                  <b>{column.title}</b>
                </div>
                ))}
              </div>

              <div 
                style={{
                  width: "100%", 
                  height: "90%", 
                  overflowY: "scroll",
                  overflowX: "hidden"
                }}>
                {item[1]
                .filter((row: any) => projectFilter === "" ? true : row.project_for_filter === projectFilter)
                .filter((row: any) => filterString === "" ? true : 
                        row.subject.toLowerCase().includes(filterString))
                .map((row: ByProjectRow | ByDateRow | ByReleaseRow, row_index: number) => (
                <div 
                  key={row_index} 
                  onClick={(e) => {
                    props.onEvent({
                      message: "RedmineDataRow", 
                      params: {
                          data: row as any,
                      }})
                  }}
                  style={{
                    marginBottom: "5px", 
                    width: "100%", 
                    display: "grid",
                    gridTemplateColumns: getGridColumns(props.columnDef),     
                    borderBottom: "solid #dddddd 1px", 
                    cursor: "pointer",
                    backgroundColor: row.type === "ByReleaseRow" ? row.backgroundColor : "white"
                  }}>
                  {props.columnDef.map((column, column_index) => (
                  <div 
                    key={column_index} 
                    style={{gridColumn: (column_index + 1)}}>
                    {column.key === "issue_id" ?
                    <a  href={`https://redmine.thevcgroup.com/issues/${row.issue_id}`}
                        target="_blank">{row[column.key]}</a>
                    :row[column.key as keyof Issue]
                    }
                  </div>
                  ))}
                </div>
                ))}
              </div>
              </div>
            )
          }
        })
    })}
  />
  )
}

const getGridColumns = (columnDef: Column[]) => {
  return columnDef.map(
    column => {
      const sum: number = columnDef
                      .map(item=>parseFloat(item.flex))
                      .reduce((a: number, b: number) => a + b, 0)
      return (parseFloat(column.flex)/sum*100.0).toString() + "% "
    }
  ).join("")
}

export const getProjectKeys = (
  projectList: Project[], 
  projectKeys: ProjectKey[], 
  parent: Project | null,
  level: number) => {
  var listToProcess: Project[] = []
  var output = projectKeys
  if (parent === null) {
    listToProcess = projectList
                        .filter(item => item.parent === null)
                        .sort((a, b) => a.name < b.name ? -1 : 1)
  } else {
    listToProcess = projectList
                        .filter(item => item.parent === parent.name)
                        .sort((a, b) => a.name < b.name ? -1 : 1)
  }
  for (const item of listToProcess) {
    output.push({
      name: item.name,
      level: level + 1
    })
    output = getProjectKeys(projectList, output, item, level + 1)
  }
  return output
}

export const countDisplay = (item: Project | Release) => {
  return `  (${item.count - item.resolved}/${item.count})`
}

const SubMenu = (props: any) => (
  (!props.mobileScreen || props.showMenu || props.sub === props.subMenu) ?
  <div 
    style={{ 
      borderBottom: "solid #dddddd 0.5px", 
      backgroundColor: props.sub === props.subMenu ? "#81C32E" : "#eeeeee",
      padding: "5px", 
      cursor: "pointer",
      display: "flex", alignItems: "center",
      justifyContent: "space-between"
    }}
    onClick={(e) => {
      props.setProp("redmineSub", props.subMenu);
      props.toggleMenu(() => {
          // props.onEvent({
          //     message: "RedmineSub",
          //     params: {
          //         name: props.subMenu
          //     }
          // })
      });
    }}>
    {props.name}
    <div>
      {props.refresh &&
      <Icon name="redo" size="small"
          onClick={(e: MouseEvent) => {
            if (!props.showMenu) {
              e.stopPropagation();
            }
            props.onRefresh()
          }}
      />
      }
      {props.mobileScreen && !props.showMenu &&
      <Icon name="angle down"
        onClick={(e: any) => {
          props.toggleMenu();
        }}
      />
      }
    </div>
  </div>:

  <></>
)

// Constant -----------------------------------------------------------------
export const NO_RELEASE = "No Release"

export interface Column {
  title: string,
  key: string,
  flex: string
}

export const byReleaseColumnDef: Column[] = [
  { title: "Release Name", key: "release_name", flex: "3" },
  { title: "Release Target", key: "release_end_date", flex: "1" },
  { title: "ID", key: "issue_id", flex: "0.75" },
  { title: "Subject", key: "subject", flex: "3" },
  { title: "Tracker", key: "tracker", flex: "1" },
  { title: "Status", key: "status", flex: "1" },
  { title: "Assigned To", key: "assigned_user", flex: "1" },
  { title: "Author", key: "author_user", flex: "1" },
  // { title: "Project Name", key: "project_name", flex: "2" },
  { title: "Created", key: "created_on", flex: "1" },
  { title: "Updated", key: "updated_on", flex: "1" },
]

export const byDateColumnDef: Column[] = [
  { title: "Created", key: "created_on", flex: "2" },
  { title: "Updated", key: "updated_on", flex: "2" },
  { title: "ID", key: "issue_id", flex: "0.75" },
  { title: "Subject", key: "subject", flex: "3" },
  { title: "Tracker", key: "tracker", flex: "1" },
  { title: "Status", key: "status", flex: "1" },
  { title: "Author", key: "author_user", flex: "1" },
  { title: "Assigned To", key: "assigned_user", flex: "1" },
  { title: "Project Name", key: "project_name", flex: "2" },
  { title: "Release Name", key: "release_name", flex: "3" },
]

export const byProjectColumnDef: Column[] = [
  { title: "Project Name", key: "project_name", flex: "2" },
  { title: "Release Name", key: "release_name", flex: "3" },
  { title: "ID", key: "issue_id", flex: "0.5" },
  { title: "Subject", key: "subject", flex: "3" },
  { title: "Tracker", key: "tracker", flex: "1" },
  { title: "Status", key: "status", flex: "1" },
  { title: "Author", key: "author_user", flex: "1" },
  { title: "Assigned To", key: "assigned_user", flex: "1" },
  { title: "Created", key: "created_on", flex: "1" },
  { title: "Updated", key: "updated_on", flex: "1" },
]

// Interface ----------------------------------------------------------------
export interface ProjectDict {
  [name: string]: Project
}

export interface ProjectKey {
  name: string,
  level: number,
}

export interface ReleaseDict {
  [name: string]: Release
}

export interface Project {
  name: string,
  parent: string,
  open: boolean,
  count: number,
  resolved: number,
  releases: ReleaseDict
}

export interface Release {
  name: string,
  start_date: string,
  end_date: string,
  open: boolean,
  count: number,
  resolved: number,
  issues: Issue[]
}

export interface Issue {
  project_name: string,
  project_parent_name: string,
  release_name: string,
  release_start_date: string,
  release_end_date: string,
  release_status: string,
  issue_id: string,
  subject: string,
  tracker: string,
  status: string,
  created_on: string,
  updated_on: string,
  author_user: string,
  assigned_user: string,
}

export const initialIssue: Issue = {
  project_name: "",
  project_parent_name: "",
  release_name: "",
  release_start_date: "",
  release_status: "",
  release_end_date: "",
  issue_id: "",
  subject: "",
  tracker: "",
  status: "",
  created_on: "",
  updated_on: "",
  author_user: "",
  assigned_user: "",
}

export interface Throughput {
  id: number,
  created_on: string,
  updated_on: string,
  resolved_on: string | null
}

export interface ChartRow {
  date: string,
  created_on_count: number,
  updated_on_count: number,
  resolved_on_count: number,
}

export interface ByReleaseRow extends Issue {
  type: "ByReleaseRow",
  backgroundColor: string,
  level: string,
  release_for_open: string
}

export interface ByDateRow extends Issue {
  type: "ByDateRow"
}

export interface ByProjectRow extends Issue {
  type: "ByProjectRow",
  level: string,
  project_for_open: string,
  release_for_open: string
}

export const initialByReleaseRow: ByReleaseRow = {
  type: "ByReleaseRow",
  ...initialIssue,
  backgroundColor: "white",
  level: "",
  release_for_open: ""
 }

export const initialByProjectRow: ByProjectRow = {
  type: "ByProjectRow",
  ...initialIssue,
  level: "",
  project_for_open: "",
  release_for_open: ""
}