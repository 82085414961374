import { useEffect, useRef, useState } from "react";

export default function BokehChartList(props: any) {
  const chartRef = useRef<HTMLDivElement>(null);
  const [chartDataList, setChartDataList] = useState<any[]>([]);

  // Python function to calculate chart data from raw data
  const calcChartData = async () => {
    const result =
      await props.python.run(
        "bk", {rawData: props.rawData}, addPrefixAndSuffix(props.script));
    setChartDataList(result);
  }

  const addPrefixAndSuffix = (script: string) => {
    const prefix = `
    import pandas as pd
    import micropip
    await micropip.install('hvplot')
    await micropip.install('holoviews')
    import hvplot.pandas
    import holoviews as hv
    from bokeh.plotting import figure
    from bokeh.embed import json_item
    from js import pyParams
    import json
    rawData = pyParams.rawData.to_py()`

    const suffix = `
    result = json.dumps([json_item(q) for q in p])
    result
    `
    return removeSpaceAtStart(prefix) 
            + removeSpaceAtStart(script) 
            + removeSpaceAtStart(suffix);
  }

  const removeSpaceAtStart = (script: string) => {
    let script_lines = script.split("\n");
    let firstline = script_lines[1];
    const spacesAtStart = firstline.length - firstline.trimLeft().length;
    let script_ = script_lines
                  .map((line: string) => line.substring(spacesAtStart))
                  .join("\n");
    return script_
  }

  // First step - calculate chart data upong python ready
  useEffect(() => {
    if (props.pythonLoaded && props.bokehLoaded && Object.keys(props.rawData).length > 0)
      calcChartData();
    if (props.pythonError) console.log(props.pythonError);
  }, [props.pythonLoaded, props.bokehLoaded, props.rawData, props.pythonError, props.menu]);

  // Second step - execute BokehJS to plot chart
  useEffect(() => {
    if (!chartRef.current || chartDataList.length === 0) return
    if (chartRef.current) chartRef.current.innerHTML = "";
    for (const index in chartDataList) {
      let chartData = chartDataList[index];
      let child = document.createElement("div");
      let chartId = `myplot${index}`;
      child.setAttribute("id", chartId);
      (window as any).Bokeh.embed.embed_item(chartData, chartId);
      chartRef.current.appendChild(child);
    }
    
  }, [chartRef, chartDataList]);

  return(
    <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
      {props.pythonLoaded && props.bokehLoaded && chartDataList.length > 0 ?  
      <div id="myplot" ref={chartRef}></div>
      
      : 
      props.pythonLoaded && props.bokehLoaded ?  
      <div>Calculatig chart data. Please wait.</div>
      
      : 
      <div>Loading Analytics Engine. Please wait.</div>}
    </div>
  )
}
