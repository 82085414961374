import React, { useState, useEffect } from "react"
import Tab from "react-lib/frameworks/Tab"
import { Loader, Dimmer, Segment } from "semantic-ui-react"
import EmbeddedDataStudio from "react-lib/frameworks/EmbeddedDataStudio"

export default function Stat(props: any) {

  useEffect(() => {
    props.onEvent({message: "GetStatList", params: {}});
  }, []);

  return(
    <div 
      style={{ 
        height: "94vh", 
        borderBottom: "solid #aaaaaa 1px"
      }}>
      <Tab
        tabWidth="200px"
        mobileScreen={props.mobileScreen}
        data={props.statList.map((item: any, index: number) => (
          {
            name: item.name,
            key: item.name,
            render: () => 
              <EmbeddedDataStudio 
                mobileScreen={props.mobileScreen}
                charts={[item.reportId]}
              />
          }))}
      />
    </div>)
}

function StatOld(props: any) {

  return(
    <div 
      style={{ 
        height: "94vh", 
        borderBottom: "solid #aaaaaa 1px"
      }}>
      <Tab
        mobileScreen={props.mobileScreen}
        data={[
          {
            name: "mor.company",
            key: "mor.commpany",
            render: () => 
              <EmbeddedDataStudio 
                mobileScreen={props.mobileScreen}
                charts={[
                  "36f7ab05-81f5-4ab4-8064-d902d1d1798a/page/JJKhB",
                  "36f7ab05-81f5-4ab4-8064-d902d1d1798a/page/yJ1HC",
                  "36f7ab05-81f5-4ab4-8064-d902d1d1798a/page/J4qKC",
                  "36f7ab05-81f5-4ab4-8064-d902d1d1798a/page/lf0HC",
                  "36f7ab05-81f5-4ab4-8064-d902d1d1798a/page/IrmLC",
                  "36f7ab05-81f5-4ab4-8064-d902d1d1798a/page/P2yKC",
                  "36f7ab05-81f5-4ab4-8064-d902d1d1798a/page/Z1HIC",
                  "36f7ab05-81f5-4ab4-8064-d902d1d1798a/page/fh0IC",
                  
                ]}
              />
          },
          {
            name: "My B+",
            key: "My B+",
            render: () => 
              <EmbeddedDataStudio 
                mobileScreen={props.mobileScreen}
                charts={[
                  "1F0u4TvT8L20zK0Si589UzBh5Zu4w_3zr/page/6Zc1" 
                ]}
              />
          },
          {
            name: "IH web analytics",
            key: "IH web analytics",
            render: () => 
              <EmbeddedDataStudio
                mobileScreen={props.mobileScreen}
                charts={[
                  "ee9e3505-03c1-4677-b2ad-2bad87af97f2/page/2NvJC",
                  "ee9e3505-03c1-4677-b2ad-2bad87af97f2/page/phIHC",
                  "ee9e3505-03c1-4677-b2ad-2bad87af97f2/page/B44GC",
                  "ee9e3505-03c1-4677-b2ad-2bad87af97f2/page/L94GC",
                ]}
              />
          },
          {
            name: "IH transaction stat",
            key: "IH transaction stat",
            render: () => 
              <EmbeddedDataStudio 
                mobileScreen={props.mobileScreen}
                charts={[
                  "ca1bafb6-40ef-4d36-8b0d-86a26ab28a61/page/RpzJC",
                  "8ac323dd-d3a1-4e71-9e79-9791748b9d7b/page/hvzJC"
                ]} 
              />
          },
          {
            name: "IH division users",
            key: "IH division users",
            render: () => 
              <UserByDivision 
                mobileScreen={props.mobileScreen}
                ihUserProfile={props.ihUserProfile}
                onEvent={props.onEvent}
              />
          },
          
          {
            name: "Penta",
            key: "Penta",
            render: () => 
              <EmbeddedDataStudio 
                mobileScreen={props.mobileScreen}
                charts={[
                  "1WbMPUX9MrMmp3E52xXMfqr5mq8Vqitii/page/Sb4HC",
                  "1WbMPUX9MrMmp3E52xXMfqr5mq8Vqitii/page/fd3HC",
                  "1WbMPUX9MrMmp3E52xXMfqr5mq8Vqitii/page/Bi3HC",
                  "1WbMPUX9MrMmp3E52xXMfqr5mq8Vqitii/page/HL8HC",
                ]}
              />
          },
        ]}
      />
    </div>
  )
}

// export default IH

const UserByDivision = (props: any) => {

  useEffect(() => {
    props.onEvent({ message: "GetIHUserProfile", params: {} })
  }, [])

  return(
    props.ihUserProfile.size > 0 ? 
    <div style={{ height: "100%", overflowY: "scroll" }}>
      <Tab
        mobileScreen={props.mobileScreen}
        data={Array.from(props.ihUserProfile.entries())
          .sort((a: any, b: any) => (a[0] < b[0] ? -1: 1))
          .map((item: any, index: number) => (
          {
            name: item[0],
            key: item[0],
            render: () => (
              <div>
                {Array.from(item[1].entries())
                  .sort((a: any, b: any) => (a[0] < b[0] ? -1: 1))
                  .map((user: any, user_index: number) => (
                    <div 
                      key={user_index} 
                      style={{ 
                        display: "flex", 
                        borderBottom: "solid #cccccc 1px" }}
                      >
                      <div style={{ flex: 1 }}>{user[0]}</div>
                      <div style={{ flex: 1 }}>
                        {user[1][0].last_login}
                      </div>
                      <div style={{ flex: 1 }}>
                        {user[1].map((x: any) => x.triage_level)
                                .sort()
                                .join(", ")}
                      </div>
                    </div>
                  ))
                }
              </div>
            )
          }
        ))}
      />            
    </div>:
    <Loading />  
  )
}

const Loading = (props: any) => (
  <Segment style={{ height: "94vh"}}>
    <Dimmer inverted active>
      <Loader inverted>Loading</Loader>
    </Dimmer>
  </Segment>
)