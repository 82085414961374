import {
    Button, Segment, Icon, Dropdown, Input, Form, FormField, FormGroup, 
    Checkbox, Radio, TextArea, Label, Pagination, Divider,
    Accordion, AccordionAccordion, AccordionTitle, AccordionContent,
    Menu, MenuItem
} from 'semantic-ui-react';
import { Calendar } from "react-big-calendar";
import { 
  DateTextBox, 
  TimeTextBox,
  TimeFreeTextBox24
} from 'react-lib/apps/common';
import {
    Table
 } from 'react-lib/frameworks/Table';

export type Ux = { 
    name: string,
    label?: string,
    from: string | null,
    component: any, 
    void: boolean,
    defaultProps?: any,
    propDef: { [name: string]: UxProp } 
}
export type UxProp = { choices?: any[] }

const UxDef: { [name: string]: Ux } = {

    br: {
        name: 'br',
        from: null,
        component: 'br',
        void: true,
        propDef: {
            id: {},
            className: {},
        }
    },

    button: {
        name: 'button',
        from: null,
        component: 'button',
        void: false,
        propDef: {
            id: {},
            className: {},
            children: {},
            onClick: {},
            disabled: {},
            style: {},
            icon: {},
            name: {},
        }   
    },

    div: {
        name: 'div',
        from: null,
        component: 'div',
        void: false,
        defaultProps: {
            children: { 
                type: "value", 
                value: 'div' 
            }
        },
        propDef: {
            id: {},
            className: {},
            children: {},
            style: {},
        }
    },

    divgrid: {
        name: 'div',
        label: 'divgrid',
        from: null,
        component: 'div',
        void: false,
        defaultProps: {
            style: { 
              type: "code", 
              value: '{ width: "100%", height: "100%", display: "grid", position: "relative", ' + 
                     'gridTemplateRows: "repeat(50, 1fr)", gridTemplateColumns: "repeat(50, 1fr)" }'
            }
        },
        propDef: {
            id: {},
            className: {},
            children: {},
            style: {},
        }
    },

    divrow: {
        name: 'div',
        from: null,
        component: 'div',
        void: false,
        defaultProps: {
            style: { 
                type: "code", 
                value: '{ display: "flex" }' 
            },
            children: { 
              type: "value", 
              value: 'div' 
            }
        },
        propDef: {
            id: {},
            className: {},
            children: {},
            style: {},
        }
    },

    divcolumn: {
        name: 'div',
        from: null,
        component: 'div',
        void: false,
        defaultProps: {
            style: { 
                type: "code", 
                value: '{ display: "flex", flexDirection: "column" }' 
            },
            children: { 
              type: "value", 
              value: 'div' 
            }
        },
        propDef: {
            id: {},
            className: {},
            children: {},
            style: {},
        }
    },

    form: {
        name: 'form',
        from: null,
        component: 'form',
        void: false,
        propDef: {
            id: {},
            className: {},
            style: {},
            onSubmit: {},
            name: {},
            target: {},
            action: {},
            method: {},
            accept: {},
            enctype: {},
            autocomplete: {},
            autocapitalize: {},
            novalidate: {},
        }
    },

    hr: {
        name: 'hr',
        from: null,
        component: 'hr',
        void: true,
        propDef: {
            id: {},
            className: {},
        }
    },

    label: {
        name: 'label',
        from: null,
        component: 'label',
        void: false,
        defaultProps: {
          children: { 
              type: "value", 
              value: 'label' 
          }
        },
        propDef: {
            id: {},
            className: {},
            children: {},
            style: {}
        }
    },

    img: {
        name: 'img',
        from: null,
        component: 'img',
        void: true,
        propDef: {
            id: {},
            className: {},
            src: {},
            style: {},
            name: {},
        }
    },

    input: {
        name: 'input',
        from: null,
        component: 'input',
        void: false,
        propDef: {
            id: {},
            className: {},
            value: {},
            style: {},
            name: {},
            onChange: {},
            onKeyUp: {},
            onBlur: {},
        },
    },

    select: {
        name: 'select',
        from: null,
        component: 'select',
        void: true,
        propDef: {
            id: {},
            className: {},
            src: {},
            style: {},
            name: {},
        }
    },

    textarea: {
        name: 'textarea',
        from: null,
        component: 'textarea',
        void: true,
        propDef: {
            id: {},
            className: {},
            value: {},
            onChange: {},
            style: {},
            rows: {},
            name: {},
        }
    },

    Accordion: {
        name: 'Accordion',
        from: 'semantic-ui-react',
        component: Accordion,
        void: false,
        propDef: {
            id: {},
            as: {},
            className: {},
            fluid: {},
            styled: {},
            style: {},
            children: {}
        }
    },

    AccordionAccordion: {
        name: 'AccordionAccordion',
        from: 'semantic-ui-react',
        component: AccordionAccordion,
        void: false,
        propDef: {
            id: {},
            activeIndex: {},
            as: {},
            className: {},
            defaultActiveIndex: {},
            exclusive: {},
            onTitleClick: {},
            panels: {},
            style: {},
            children: {},
        }
    },

    AccordionTitle: {
        name: 'AccordionTitle',
        from: 'semantic-ui-react',
        component: AccordionTitle,
        void: false,
        propDef: {
            id: {},
            active: {},
            index: {},
            content: {},
            onClick: {},
            style: {},
            children: {},
            className: {},
            as: {},
        }
    },

    AccordionContent: {
        name: 'AccordionContent',
        from: 'semantic-ui-react',
        component: AccordionContent,
        void: false,
        propDef: {
            id: {},
            active: {},
            content: {},
            style: {},
            children: {},
            className: {},
            as: {}
        }
    },

    Button: {
        name: 'Button',
        from: 'semantic-ui-react',
        component: Button,
        void: false,
        propDef: {
            id: {},
            className: {},
            children: {},
            color: { choices: [
                'red', 'blue', 'green', 'yellow', '', 
                'orange', 'olive', 'teal', 'violet', 'purple', 'pink', 
                'brown', 'grey', 'black', 'facebook', 'google', 'plus', 
                'instagram', 'linkedin', 'twitter', 'vk', 'youtube',
            ] },
            size: { choices: ['tiny', 'mini', 'small', 'massive', '' ]},
            circular: {},
            onClick: {},
            disabled: {},
            style: {},
            type: {},
            basic: {},
            floated: {},
            fluid: {},
            labelPosition: {},
            icon: {},
            name: {},
            loading: {},
        }   
    },

    Calendar: {
        name: 'Calendar',
        from: 'react-big-calendar',
        component: Calendar,
        void: true,
        propDef: {
            id: {},
            localizer: {},
            style: {},
            formats: {},
            views: {},
            min: {},
            max: {},
            startAccessor: {},
            endAccessor: {},
            events: {},
            eventPropGetter: {},
            tooltipAccessor: {},
            onSelectEvent: {},
            onNavigate: {},
            onRangeChange: {}
        }   
    },

    Checkbox: {
        name: "Checkbox",
        from: 'semantic-ui-react',
        component: Checkbox,
        void: false,
        propDef: {
            id: {},
            className: {},
            label: {},
            toggle: {},
            checked: {},
            style: {},
            onChange: {},
            onClick: {},
            readOnly: {},
            disabled: {},
            defaultChecked: {},
            slider: {},
            name: {},
        }
    },

    DateTextBox: {
        name: "DateTextBox",
        from: 'react-lib/apps/common',
        component: DateTextBox,
        void: false,
        propDef: {
            id: {},
            error: {},
            disabled: {},
            placeholder: {},
            minDate: {},
            maxDate: {},
            onChange: {},
            inputClassNames: {},
            overlayClassNames: {},
            overlayWrapperClassNames: {},
            style: {},
            tabIndex: {},
            value: {},
            keepFocus: {},
        }
    },

    Divider: {
        name: 'Divider',
        from: 'semantic-ui-react',
        component: Divider,
        void: false,
        propDef: {
            id: {},
            children: {},
            fitted: {},
            horizontal: {}
        }
    },

    Dropdown: {
        name: 'Dropdown',
        from: 'semantic-ui-react',
        component: Dropdown,
        void: false,
        defaultProps: {
          selection: { 
              type: "code", 
              value: 'true' 
          }
        },
        propDef: {
            id: {},
            className: {},
            options: { choices: [] },
            value: {},
            placeholder: {},
            onChange: {},
            fluid: {},
            compact: {},
            selection: { choices: [true, false] },
            style: {},
            disabled: {},
            error: {},
            renderLabel: {},
            clearable: {},
            search: {},
            openOnFocus: {},
            minCharacters: {},
            noResultsMessage: {},
            icon: {},
            name: {},
            open: {},
            searchQuery: {},
            onClose: {},
            defaultValue: {},
            multiple: {},
            allowAdditions: {},
            onAddItem: {},
            onSearchChange: {},
            loading: {}
        }
    },

    Form: {
        name: 'Form',
        from: 'semantic-ui-react',
        component: Form,
        void: false,
        propDef: {
            id: {},
            className: {},
        }
    },

    FormField: {
        name: 'FormField',
        from: 'semantic-ui-react',
        component: FormField,
        void: false,
        propDef: {
            id: {},
            className: {},
            children: {},
            inline: {},
            width: {},
            style: {},
        }
    },

    FormGroup: {
        name: 'FormGroup',
        from: 'semantic-ui-react',
        component: FormGroup,
        void: false,
        propDef: {
            id: {},
            className: {},
            inline: {},
            widths: {},
            style: {}
        }
    },

    Icon: {
        name: 'Icon',
        from: 'semantic-ui-react',
        component: Icon,
        void: false,
        propDef: {
            id: {},
            className: {},
            name: {},
            onClick: {},
            inverted: {},
            link: {},
        }
    },

    Input: {
        name: 'Input',
        from: 'semantic-ui-react',
        component: Input,
        void: false,
        propDef: {
            id: {},
            className: {},
            value: {},
            onChange: {},
            fluid: {},
            loading: {},
            open: {},
            searchQuery: {},
            onSearchChange: {},
            onFocus: {},
            style: {},
            type: {},
            action: {},
            placeholder: {},
            disabled: {},
            min: {},
            readOnly: {},
            readonly: {},
            size: { choices: ['mini', 'small', 'large']},
            label: {},
            onKeyDown: {},
            icon: {},
            iconPosition: {},
            autoFocus: {},
            ref: {},
            name: {},
        }
    },

    Label: {
        name: 'Label',
        from: 'semantic-ui-react',
        component: Label,
        void: false,
        propDef: {
            id: {},
            className: {},
            children: {},
            name: {},
            color: {},
            style: {},
            size: {},
            tag: {},
            ribbon: {},
            pointing: {},
            onClick: {},
            icon: {},
            detail: {},
            corner: {},
            circular: {},
            basic: {}
        }
    },

    Menu: {
        name: 'Menu',
        from: 'semantic-ui-react',
        component: Menu,
        void: false,
        propDef: {
            id: {},
            borderless: {},
            activeIndex: {},
            as: {}
        }
    },

    MenuItem: {
        name: 'MenuItem',
        from: 'semantic-ui-react',
        component: MenuItem,
        void: false,
        propDef: {
            id: {},
            name: {},
            active: {},
            onClick: {}
        }
    },

    Pagination: {
      name: 'Pagination',
      from: 'semantic-ui-react',
      component: Pagination,
      void: true,
      propDef: {
        id: {},
        activePage: {},
        boundaryRange: {},
        defaultActivePage: {},
        ellipsisItem: {},
        firstItem: {},
        lastItem: {},
        siblingRange: {},
        totalPages: {},
        onPageChange: {},
        secondary: {},
        pointing: {},
        disabled: {},
      }
    },

    Radio: {
        name: "Radio",
        from: 'semantic-ui-react',
        component: Radio,
        void: false,
        propDef: {
            id: {},
            className: {},
            label: {},
            toggle: {},
            checked: {},
            disabled: {},
            style: {},
            onChange: {},
            value: {},
            name: {},
        }
    },

    Segment: {
        name: 'Segment',
        from: 'semantic-ui-react',
        component: Segment,
        void: false,
        propDef: {
            id: {},
            className: {},
        }
    },

    Table: {
        name: 'Table',
        from: 'react-lib/frameworks/Table',
        component: Table,
        void: false,
        propDef: {
            id: {},
            type: {},
            className: {},
            sortable: {},
            headers: {},
            keys: {},
            widths: {},
            columns: {},
            data: {},
            defaultPageSize: {},
            showPagination: {},
            pageSize: {},
            style: {},
            getTrProps: {},
            getTdProps: {}, 
            getTheadThProps: {},
            getTableProps: {},
            getTbodyProps: {},
            minRows: {},
            resizable: {},
            NoDataComponent: {},
            expanded: {},
            expanderDefaults: {},
            onExpandedChange: {},
            subRowsKey: {},
            name: {},
        }
    },

    TextArea: {
        name: 'TextArea',
        from: 'semantic-ui-react',
        component: TextArea,
        void: true,
        propDef: {
            id: {},
            className: {},
            readOnly: {},
            value: {},
            onChange: {},
            style: {},
            rows: {},
            onInput: {},
            onKeyDown: {},
            name: {},
            onBlur: {},
            disabled: {},
        }
    },

    TimeFreeTextBox24: {
      name: 'TimeFreeTextBox24',
      from: 'react-lib/apps/common',
      component: TimeFreeTextBox24,
      void: false,
      propDef: {
          id: {},
          disabled: {},
          onChange: {},
          value: {},
          className: {},
          maxHour: {},
          minHour: {},
          disabledClick: {},
          onClick: {},
          autoFocus: {},
          disableIconClick: {},
          checkTimeOverflow: {},
          use12Hours: {},
          iconClockStyle: {},
      }
    },

    TimeTextBox: {
      name: 'TimeTextBox',
      from: 'react-lib/apps/common',
      component: TimeTextBox,
      void: false,
      propDef: {
          id: {},
          style: {},
          onTimeChange: {},
          defaultTime: {},
          interval: {},
          disabled: {},
          minHour: {},
          maxHour: {},
          useDefaultTime: {},
          availableOptions: {},
          reservedText: {},
          clearable: {},
          search: {},
          value: {},
          className: {},
      }
  },

}

export default UxDef
