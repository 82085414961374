import { useEffect, useState } from "react";

export default function SalesView(props: any) {
  useEffect(() => {
    // Update selectedDeal according to clientDeal (parent)
    if (props.selectedDeal?.id) {
      props.setSelectedDeal(Object.assign({}, props.clientDeal[props.selectedDeal.id]));
    }
  }, [props.clientDeal]);
  
  const openDetail = (deal: any) => {
    props.setSelectedDeal(props.selectedDeal?.id === deal?.id ? null : deal);
  }

  return(
    <div style={{display: "flex", height: "100%"}}>
      <div 
        style={{ flex: 1 }}>
        {Object.values(props.clientDeal)
          .filter((deal: any) => deal.status === "SALES")
          .sort((a: any, b: any) => a.value < b.value ? 1 : -1)
          .map((deal: any, index: number) => (
          <div 
            key={index}
            style={{
              display: "grid", gridTemplateColumns: "4fr 2fr 1fr 1fr", 
              cursor: "pointer", borderBottom: "solid #eeeeee 1px"
            }}
            onClick={(e: any) => openDetail(deal)}>
            <div>{deal.clientName}</div>
            <div>{deal.dealName}</div>
            <div
              style={{textAlign: "right", paddingRight: "10px"}}>
              {deal.value?.toLocaleString('en-US', {maximumFractionDigits: 0})}
            </div>
            <div>
              <button
                style={{backgroundColor: "lightskyblue", border: "none"}}
                onClick={(e: any) => {
                  e.stopPropagation();
                  props.onEvent({
                    message: "UpdateClientDeal",
                    params: {id: deal.id, data: {status: "ONGOING"}}
                  })
                }}>
                {">> Ongoing"}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
