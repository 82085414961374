export function analyzeLessonData(lessonData: any) {
  return groupVideo(lessonData.items, 0, []);
}

function groupVideo(items: any[], index: number, output: number[][]) {
  let group = [];
  while (index < items.length) {
    if (items?.[index]?.next?.length === 1) {
      group.push(index);
    } else if (items?.[index]?.next?.length === 0) {
      group.push(index);
      output.push(group);
      break;
    } else if (items?.[index]?.next?.length > 1) {
      group.push(index);
      output.push(group);
      for (var childIndex of items?.[index]?.next) {
        output = groupVideo(items, childIndex, output)
      }
      break;
    }
    index += 1;
  }
  return output
}