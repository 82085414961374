import { useEffect, useState } from "react";
import { Icon, Input } from "semantic-ui-react";

export default function Keep(props: any) {
  const [newNoteItem, setNewNoteItem] = useState("");
  const [selectedItem, setSelectedItem] = useState<any|null>(null);

  useEffect(() => {
    props.onEvent({message: "GetKeepList", params: {}});
  }, []);

  useEffect(() => {
    if (selectedItem !== null) 
      setSelectedItem(props.keepList?.[selectedItem.documentId]);
    else {
      if (Object.keys(props.keepList).length > 0)
        setSelectedItem(Object.values(props.keepList)[0]);
    }
  }, [props.keepList]);
  
  return(
    <div style={{display: "grid", gridTemplateColumns: "400px 1fr", height: "100%"}}>
      <div style={{gridColumn: 1, borderRight: "solid #dddddd 1px"}}>
        {Object.values(props.keepList).map((keepItem: any, keepItemIndex: number) => (
          <div 
            key={keepItemIndex}
            style={{
              backgroundColor: selectedItem?.documentId === keepItem.documentId ? "#dddddd" : "white", 
              padding: "5px"
            }}
            onClick={(e: any) => {
              if (selectedItem?.doccumentId === keepItem.documentId)
                setSelectedItem(null);
              else
                setSelectedItem(keepItem);
            }}>
            {keepItem.title}
          </div>
        ))}
      </div>
      <div style={{gridColumn: 2, padding: "15px"}}>
        {selectedItem?.format === "sound" &&
        <>
          <audio 
            style={{width: "100%"}}
            controls src={selectedItem?.downloadUrl}
          />
          <Input 
            fluid
            placeholder="ใส่หัวข้อสนทนา ณ เวลาปัจจุบันในไฟล์เสียง"
            value={newNoteItem} 
            onChange={(e: any) => {
              setNewNoteItem(e.target.value);
            }}
            onKeyUp={(e: any) => {
              if (e.key !== "Enter" || newNoteItem === "") return;
              props.onEvent({message: "UpdateKeepItem", params: {
                selectedItem,
                data: {
                  notes: (selectedItem?.detail?.notes || []).concat([
                    {
                      start: 0,
                      stop: 100,
                      text: newNoteItem
                    }
                  ])
                }
              }});
              setNewNoteItem("");
            }}
          />
          <div style={{height: "80vh", overflow: "scroll"}}>
            {(selectedItem?.detail?.notes || []).map((noteItem: any, noteItemIndex: number) => (
              <div 
                key={noteItemIndex}
                style={{
                  borderBottom: "solid #dddddd 1px",
                  display: "grid", gridTemplateColumns: "40px 1fr 100px", padding: "5px"
                }}>
                <div 
                  style={{
                    display: "flex", justifyContent: "center", alignItems: "center", 
                    border: "solid #dddddd 1px", borderRadius: "40px", height: "40px",
                    textAlign: "center"
                  }}>
                  <Icon name="play" fitted/>
                </div>
                <div style={{paddingLeft: "10px"}}>
                  {noteItem?.text}
                </div>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                  <Icon name="step backward" size="small" />
                  {`Start: ${noteItem?.start}`}
                  <Icon name="step forward" size="small"/>
                </div>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                  <Icon name="step backward" size="small"/>
                  {`Stop: ${noteItem?.stop}`}
                  <Icon name="step forward" size="small"/>
                </div>
                </div>
              </div>
            ))}
          </div>
        </>}
      </div>
    </div>
  )
}
