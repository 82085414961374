import axios from 'axios'
import {
    UploadState,
    UploadEvent
} from './Upload'
import XLSX from 'xlsx'
import window874 from 'windows-874'
// import { qmlweb_parse } from './qml2ast';

import { WasmHandler } from '../../../react-lib/frameworks/WasmController'


type Handler = WasmHandler<UploadState, UploadEvent, {}>

export const GetTaskList: Handler = (controller, params) => {
    axios.get('/redmine/taskList.json').then(res => {
        console.log(res)
        controller.setState({ taskList: res.data })
    }).catch(err => {
        console.log(err)
    })
}

export const GetResolvedIssues: Handler = (controller, params) => {
    axios.get('/redmine/resolved/').then(res => {
        console.log(res)
        controller.setState({ resolvedIssues: res.data })
    }).catch(err => {
        console.log(err)
    })
}

export const ProcessExcel: Handler = async (controller, params) => {
    const arrayBuffer = await readFileAsync(params.file) as ArrayBuffer
    const { row, columns } = processFile(arrayBuffer)
    controller.setState({ row: row, columns: columns })
}

export const ProcessJSON: Handler = async (controller, params) => {
    let s = await readFileAsync(params.file, true) as string
    // If csv, pass to csvToObj first
    // let data = csvToObj(s)
    // If json, just parse
    let data = JSON.parse(s)
    console.log(data)
}

export const ProcessQML: Handler = async (controller, params) => {
    let s = await readFileAsync(params.file, true) as string;
    // console.log(s);
    // console.log(qmlweb_parse(s, 1));
}

// Utils ----------------------------------------------------------------------

const csvToObj = (s: string) => {
    // s = window874.decode(s)
    let data = s.split("\n")
                .map(s => s.trim())
                .filter(row => row !== '')
                .map((item: string, index: number) => (splitCSV(item)))
    if (data.length > 0) {
        let keys = data[0]?.map((item) => (
            item.replace('(', '_')
                .replace(')', '_')
                .replace('.', '_')
        )) || []
        data = data.slice(1)
        
        let obj = data.map((row: any) => {
            let output: any = {}
            for (var i=0; i < (keys?.length || 0) ; i++) {
                output[keys[i]] = row[i]
            }
            return output
        })
        return obj
    } else {
        return null
    }
}

const readFileAsync = (file: File, text: boolean = false) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      if (text) {
        reader.readAsText(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
    })
}

const processFile: (arrayBuffer: ArrayBuffer) => ({ row: any, columns: any }) 
= (arrayBuffer) => {
    const xl = XLSX.read(arrayBuffer, {type: 'array'})
    const csv = XLSX.utils.sheet_to_csv(xl.Sheets[xl.SheetNames[0]])
    const lines = csv.split("\n")
    const data = lines
        .map((item, index) => (splitCSV(item)))
    const columns = data[0]?.map((item: any, index: number) => (   
        { 
            headerName: item.toString(), 
            field: item.toString()
        }))
    var row: any[] = []
    
    if (columns) {
        row = data.slice(1).map((item: any) => {
            var obj: any = {}
            for (var i=0; i < columns.length; i++) {
                if (item && item.length > i) {
                    obj[columns[i].field] = item[i]
                }
            }
            return obj
        })
    }
    return {row: row, columns: columns ? columns: []}
}

const splitCSV = (s: string) => {
    var matches = s.match(/(\s*"[^"]+"\s*|\s*[^,]+|,)(?=,|$)/g);
    if (matches) {
        for (var n = 0; n < matches.length; ++n) {
            matches[n] = matches[n].trim();
            if (matches[n][0] === '"') {
                matches[n] = matches[n].replace('"', '')
            }
            if (matches[n] == ',') matches[n] = '';
        }
        if (s[0] == ',') matches.unshift("");
    }
    return matches;
}

