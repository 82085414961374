import { useState } from "react";

export default function(props: any) {
  let [selectedData, setSelectedData] = useState<string[]>([]);
  let [selectedRowIndex, setSelectedRowIndex] = useState<number|null>(null);
  let [selectedColIndex, setSelectedColIndex] = useState<number|null>(null);

  if (props.data && props.data?.all)  {
    let rows = Object.keys(props.data);
    let cols = Object.keys(props.data?.all || {});
    let colcount = cols.length;
  
    return (
      <div>
        <div 
          style={{
            display: "grid", position: "relative",
            gridTemplateColumns: "12%" + `${88 / colcount}%`.repeat(colcount)
          }}>
          {["", ...cols].map((col: string, colIndex: number) => (
            <div 
              key={colIndex} 
              style={{height: "100px", border: "solid grey 0.5px"}}>
              <div style={{transform: "rotate(90deg)", width: "1rem"}}>
                {col}
              </div>
            </div>
          ))}
          {rows.map((row: string, rowIndex: number) => (
            [row].concat(
              cols.map((col: string) => 
                col === "count" ? props.data[row][col] 
                  : props.data[row][col].length > 0 ? props.data[row][col].length
                  : "")
            ).map((item: string, itemIndex: number) => (
              <div 
                key={`${rowIndex}_${itemIndex}`}
                style={{
                  overflow: itemIndex === 0 ? "clip" : "visible",
                  textAlign: itemIndex === 0 ? "left" : "center", 
                  border: "solid grey 0.5px", cursor: "pointer",
                  backgroundColor: selectedRowIndex === rowIndex 
                                    && selectedColIndex === itemIndex - 1 ? "lightblue" : "white"
                }}
                onClick={(e: any) => {
                  if (itemIndex > 0) {
                    setSelectedRowIndex(rowIndex);
                    setSelectedColIndex(itemIndex - 1);
                    setSelectedData(props.data[row][cols[itemIndex - 1]])
                  }
                }}>
                <div>
                  {item}
                </div>
                {selectedRowIndex === rowIndex 
                && selectedColIndex === itemIndex - 1 
                && selectedData.length > 0 &&
                <div 
                  style={{
                    position: "absolute", top: 40, left: 40, textAlign: "left",
                    backgroundColor: "lightgreen", zIndex: 1000, border: "solid black 1px", 
                    padding: "5px", overflowY: "auto", maxHeight: "400px"
                  }}>
                  <b>{`[${row} : ${cols[selectedColIndex]}]`}</b>
                  {selectedData.map((name: string, nameIndex: number) => (
                    <div key={nameIndex}>
                      {name}
                    </div>
                  ))}
                </div>}
              </div>
            ))
          ))}
        </div>
      </div>)
  } else {
    return <></>
  }
}