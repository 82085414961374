import React from "react"
import { Button, Input, Dropdown, Checkbox, Radio } from 'semantic-ui-react'
import { Remarkable } from "remarkable";
import Parser from "html-react-parser";
import "semantic-ui-css/semantic.min.css"

const md = new Remarkable();

const parse = mdtext => Parser(md.render(mdtext));

const toJsx = (setFormIndex) => (item, index) => {
    return (
        <div 
            key={index} 
            style={{    gridRow: item.row, 
                        gridColumn: item.column
                    }}
            onDoubleClick={() => {
                setFormIndex(index)
                const str = item.className
                                .split("-")
                                .map(word=>word.charAt(0).toUpperCase() + word.slice(1))
                                .join("")
                navigator.clipboard.writeText(str)
                console.log(str)
            }}
        >
        {   item.name === "Button" ?
            <Button 
                {...getProps(item)}
                style={getStyles(item)}
            >
                {item.val}
            </Button> :

            item.name === "hr" ?
            <hr
                {...getProps(item)}
                style={getStyles(item)}
            /> :

            item.name === "Dropdown" ?
            <Dropdown 
                fluid placeholder="selection" 
                selection
                options={[
                    {key: 'choice 1', value: 'choice 1', text: 'choice 1'},
                    {key: 'choice 2', value: 'choice 2', text: 'choice 2'},
                    {key: 'choice 3', value: 'choice 3', text: 'choice 3'},
                ]}
                {...getProps(item)}
                style={getStyles(item)}
            /> :

            item.name === "Checkbox" ?
            <Checkbox 
                {...getProps(item)}
                style={getStyles(item)}
            >
                {item.val}
            </Checkbox> :

            item.name === "Radio" ?
            <Radio 
                {...getProps(item)}
                style={getStyles(item)}
            >
                {item.val}
            </Radio> :

            item.name === "Input" ?
            <Input 
                {...getProps(item)}
                style={getStyles(item)}
            /> :

            item.name === "div" ?
            <div 
                style={getStyles(item)}
            >
                {parse(item.val.toString())}
            </div>:

            index > 1 && 'val' in item && 
            'className' in item && item.className !== 'container' ?
            <div 
                style={getStyles(item)}
            >
                {item.val.toString()}
            </div>:

            <></>
        }
        </div>
    )
}



const getProps = (item) => {
    if (item.props) {
        try {
            const props = 
            Object.assign({}, ...item.props.split(" ").map((p) => (
                p.split("=")
            )).map((d) => (
                { [d[0]]: eval(d[1]) }
            )))
            return props
        } catch {
            return {}
        }
    } else {
        return {}
    }
}

const getStyles = (item) => {
    if (item.styles) {
        try {
            const styles = eval(`({${item.styles}})`)
            return styles
        } catch {
            return {}
        }
    } else {
        return {}
    }
}

export default toJsx

const s = `
||container|grid-template-rows|0.9836065573770492% 8.852459016393443% 6.557377049180328% 10.163934426229508% 1.639344262295082% 0.9836065573770492% 0.32786885245901637% 0.32786885245901637% 0.32786885245901637% 0.32786885245901637% 0.6557377049180327% 0.32786885245901637% 0.32786885245901637% 0.32786885245901637% 0.6557377049180327% 0.32786885245901637% 0.32786885245901637% 0.32786885245901637% 0.32786885245901637% 0.32786885245901637% 0.6557377049180327% 0.6557377049180327% 0.32786885245901637% 5.573770491803279% 0.32786885245901637% 1.3114754098360655% 0.32786885245901637% 2.622950819672131% 0.9836065573770492% 0.32786885245901637% 1.3114754098360655% 0.6557377049180327% 3.9344262295081966% 5.901639344262295% 4.262295081967213% 4.262295081967213% 0.32786885245901637% 2.2950819672131146% 0.32786885245901637% 2.9508196721311477% 2.2950819672131146% 0.32786885245901637% 6.229508196721311% 2.2950819672131146% 3.6065573770491803% 1.639344262295082% 8.19672131147541% 1.639344262295082%
||container|grid-template-columns|0.14947683109118087% 1.6442451420029895% 0.14947683109118087% 0.07473841554559044% 1.71898355754858% 0.7473841554559043% 0.8221225710014948% 1.195814648729447% 0.14947683109118087% 0.37369207772795215% 0.2242152466367713% 0.672645739910314% 1.195814648729447% 0.7473841554559043% 2.0179372197309418% 1.4947683109118086% 2.1674140508221225% 2.914798206278027% 0.8968609865470852% 1.345291479820628% 2.092675635276532% 0.672645739910314% 4.484304932735426% 5.4559043348281016% 0.9715994020926756% 0.37369207772795215% 2.765321375186846% 1.7937219730941705% 0.4484304932735426% 4.484304932735426% 0.14947683109118087% 0.5979073243647235% 0.07473841554559044% 0.7473841554559043% 4.559043348281016% 6.128550074738415% 0.7473841554559043% 0.4484304932735426% 9.715994020926756% 3.1390134529147984% 1.195814648729447% 0.37369207772795215% 0.7473841554559043% 1.1210762331838564% 4.708520179372197% 0.672645739910314% 1.195814648729447% 1.1210762331838564% 6.4275037369207775% 1.2705530642750373% 0.523168908819133% 1.345291479820628% 0.37369207772795215% 0.7473841554559043% 1.1210762331838564% 1.7937219730941705% 1.195814648729447% 0.5979073243647235% 0.672645739910314% 1.4947683109118086% 0.4484304932735426% 0.29895366218236175%
  
2/48|2/62|item-1|div|||height: "100%", width: "100%"
  
3 / 5|3 / 30|item-2|div|#### Technician1 Approval||height: "100%", width: "100%"
9/16|4/8|item-3|div|##### ประเภท||height: "100%", width: "100%"
8/17|9/18|item-4|Input|||height: "100%", width: "100%"
11/18|19/21|item-6|div|##### แพทย์||height: "100%", width: "100%"
11/23|22/27|item-7|Input|item-7||height: "100%", width: "100%"
8/18|29/31|item-11|div|##### วันที่รับปรึกษา||height: "100%", width: "100%"
10/23|33/39|item-12|Input|||height: "100%", width: "100%"
  
6/13|40/41|item-16|div|##### Request||height: "100%", width: "100%"
5/22|43/60|item-17|Input|item-17||height: "100%", width: "100%"
25/34|48/51|item-23|Button|เพิ่ม Rqeuest|color="green" size="mini"|width: "100%"
25/34|52/60|item-25|Button|แก้ไข Request|color="yellow" size="mini"|width: "100%"
  
25/29|5/11|item-10|div|#### Diagnosis||height: "100%", width: "100%"
34/34|5/61|item-15|div|||height: "100%", width: "100%"
35/36|5/9|item-20|div|#### Principal||height: "100%", width: "100%"
37/42|6/16|item-21|div|##### Medical Term||width: "100%"
37/42|17/28|item-22|Input|item-22||height: "100%", width: "100%"
37/42|30/35|item-30|div|##### ICD10 Term||height: "100%", width: "100%"
37/42|35/42|item-29|Input|item-29||height: "100%", width: "100%"
37/42|45/48|item-32|div|##### ICD10 Code||height: "100%", width: "100%"
37/42|48/55|item-31|Input|item-31||height: "100%", width: "100%"
38/42|56/57|item-33|Button|x|color="blue" size="mini"|width: "100%"
  
44/47|5/20|item-27|Button|+ Secondary|color="blue" size="mini"|width: "100%"
  `