import { ExchangeDiagramData, DiagramGroup } from "./TypeDef";

let xdToDiagramProgess = 0;

export const getStepSection = (xddata: ExchangeDiagramData) => {
  let stepSection_ = Object.fromEntries(
  xddata.sections
    .flatMap((section: {name: string, steps: string[], inactive?: boolean}, 
              sectionSeq: number) => (
      section.steps.map((stepId: string, stepSeq: number) => ([
        stepId,
        {
          stepId,
          sectionName: section.name,
          sectionSeq,
          stepSeq,
          sectionLabel: `${sectionSeq + 1}.${stepSeq + 1}`,
          inactive: section?.inactive || false
        }
      ]))
    ))
  );
  return stepSection_
}

export const xdToDiagram: (xddata: ExchangeDiagramData, postMessage: any) => DiagramGroup[] = 
  (xddata, postMessage) => 
{
  xdToDiagramProgess = 0;
  let stepSection_ = getStepSection(xddata);
  let stepIdList = Object.keys(xddata.steps)
    .filter((stepId: string) => 
      (stepId in stepSection_) && !stepSection_?.[stepId]?.inactive);
  console.time();
  let groupList = getGroupList(xddata, stepIdList, stepSection_, postMessage);
  console.timeEnd();
  return groupList
}

const getGroupList: (xddata: ExchangeDiagramData, stepIdList: string[], stepSection: any, postMessage: any) => DiagramGroup[] = 
  (xddata: ExchangeDiagramData, stepIdList, stepSection, postMessage) => 
{
  xdToDiagramProgess += 1;
  postMessage({type: "progress", data: `${xdToDiagramProgess}`})
  if (stepIdList.length === 0) return [];
  let edgeList = xddata.edges;
  let groupList: string[][] = [];
  for (const stepId of stepIdList) {
    let relatedStep = getRelatedStep(stepIdList, edgeList);

    // joinIndices includes a list of index in groupList that related steps are in
    let joinIndices: number[] = [];
    for (const relatedItem of relatedStep[stepId]) {
      let index = groupList.findIndex((stepList: string[]) => stepList.includes(relatedItem.id));
      if (index != -1 && !joinIndices.includes(index))
        joinIndices.push(index);
    }
    joinIndices = joinIndices.sort((a: number, b: number) => a < b ? -1 : 1);

    // We then join all groups in joinIndices together to form a connected graph
    if (joinIndices.length === 0) {
      groupList.push([stepId]);
    } else {
      let newGroupList: string[][] = [];
      for (var index = 0; index < groupList.length; index++) {
        if (!joinIndices.includes(index))
          newGroupList.push(groupList[index]);
        else if (index === joinIndices[0]) {
          newGroupList.push(groupList[index].concat([stepId]));
        } else {
          newGroupList[joinIndices[0]] = 
            newGroupList[joinIndices[0]].concat(groupList[index]);
        }
      }
      groupList = newGroupList;
    }
  }
  // Then sort by the number of steps within group
  groupList = groupList.sort((a: string[], b: string[]) => a.length > b.length ? -1 : 1);
  let diagramGroupList: DiagramGroup[] = groupList.map((group: string[]) => {
    if (group.length === 0) {
      return {
        roots: [],
        groupList: [],
        cycle: [],
      }
    }
    let subgroups = getSubgroup(xddata, group);
    if (subgroups[0].length === 0 && subgroups[1].length > 0) {
      return {
        roots: [subgroups[1][0]],
        groupList: subgroups[1].length === 1 ? 
          [] : getGroupList(xddata, subgroups[1].slice(1), stepSection, postMessage),
        cycle: subgroups[1].slice(1)
                .filter((relatedId: string) => 
                  (xddata.steps?.[relatedId]?.dependOn || [])
                  .concat((xddata.steps?.[relatedId]?.dependOnOptional || []))
                  .includes(subgroups[1][0]))
                .map((relatedId: string) => stepSection?.[relatedId]?.sectionLabel || "[No data]"),
      }
    } else {
      return {
        roots: subgroups[0],
        groupList: subgroups[1].length === 0 ? 
          [] : getGroupList(xddata, subgroups[1], stepSection, postMessage),
        cycle: [],
      }
    }
  })
  return diagramGroupList
}

const getSubgroup: (xddata: ExchangeDiagramData, stepIdList: string[]) => [string[], string[]] 
  = (xddata: ExchangeDiagramData, stepIdList: string[]) => 
{
  let stepSection_ = getStepSection(xddata);
  let edgeList = xddata.edges;
  let relatedStep = getRelatedStep(stepIdList, edgeList);
  let rootList = stepIdList
    .sort((a: any, b: any) => 
      Number(stepSection_[a]?.sectionLabel) < Number(stepSection_[b]?.sectionLabel) ? -1 : 1)
    .filter((stepId: string) => {
      let fromStepList = relatedStep[stepId]
        .filter(
          (relatedItem: {id: string, direction: string}) => relatedItem.direction == "to"
        )
        // .map((relatedItem: {id: string, direction: string}) => ({
        //   ...relatedItem,
        //   stepLabel: `${stepSection_[relatedItem.id]?.sectionLabel} ${xddata.steps[relatedItem.id].description}`
        // }))
        ;
      return fromStepList.length === 0
    });
  return [
    rootList, 
    stepIdList
      .filter((stepId: string) => !rootList.includes(stepId))
      .sort((a: any, b: any) => 
        Number(stepSection_[a]?.sectionLabel) < Number(stepSection_[b]?.sectionLabel) ? -1 : 1)
  ];
}

// relatedStep give a list of steps related to a given stepId (key)
const getRelatedStep = (stepIdList: string[], edgeList: any[]) => {
  return Object.fromEntries(stepIdList.map((stepId: string) => ([
    stepId, 
    edgeList
      .filter((edge: any) => 
        (edge.to === stepId && stepIdList.includes(edge.from))
        || 
        (edge.from === stepId && stepIdList.includes(edge.to))
        )
      .map((edge: any) => 
        edge.to === stepId 
        ? { id: edge.from, direction: "to", original: edge }
        : { id: edge.to, direction: "from", original: edge })
  ])));
}

// console.log(groupList);
// let subgroups = getSubgroup(xddata, groupList[0]);
// for (const stepId of subgroups[0]) {
//   // console.log(stepId);
//   console.log(`${stepSection_[stepId]?.sectionLabel} ${xddata.steps[stepId].description}`)
// }
// console.log(subgroups[1])
// let groupList1 = getGroupList(xddata, subgroups[1]);
// console.log(groupList1);
// let subgroups1 = getSubgroup(xddata, groupList1[0]);
// for (const stepId of subgroups1[0]) {
//   // console.log(stepId);
//   console.log(`${stepSection_[stepId]?.sectionLabel} ${xddata.steps[stepId].description}`)
// }