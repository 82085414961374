import React, { useEffect, useState } from "react";
import { Button } from "semantic-ui-react";
import moment from "moment";

export default function SalesLog(props: any) {
  const [salesLogs, setSalesLogs] = useState<any[]>([]);
  const [selectedLogIndex, setSelectedLogIndex] = useState<null|number>(null);

  useEffect(() => {
    setSalesLogs((props.selectedDeal?.salesLogs || [])
      .sort((a: any, b: any) => a.date < b.date ? -1 : 1)
      .map((logItem: any, logIndex: number) => 
        ({...logItem, seq: logIndex})));
  }, [props.selectedDeal]);

  const addLog = () => {    
    // setSelectedLog(null);
    let newSalesLogs: any[] = Object.assign([], salesLogs);
    newSalesLogs.push({
      seq: salesLogs.length,
      date: (moment().format("YYYYMMDD")), 
      details: "[DETAILS]", 
    });
    setSalesLogs(newSalesLogs);
    setSelectedLogIndex(salesLogs.length);
  };

  const setSalesLogAtIndex = (index: number, data: any) => {
    if (index >= salesLogs.length) return;
    let newSalesLogs: any[] = Object.assign([], salesLogs);
    newSalesLogs[index] = data;
    setSalesLogs(newSalesLogs);
  };

  const saveSalesLogs = () => {
    props.onEvent({
      message: "UpdateSalesLog", 
      params: {
        dealId: props.selectedDeal.id,
        salesLogs: salesLogs.map((log: any) => {
          const {seq, ...data} = log;
          return data;
        })
      }});
  };

  console.log(props.selectedDeal);

  return(
    <>
      {salesLogs
      .sort((a: any, b: any) => a.seq < b.seq ? -1 : 1)
      .map((log: any, logIndex: number) =>
        (logIndex === selectedLogIndex) ?
        <SalesLogForm 
          key={logIndex}
          onEvent={props.onEvent}
          selectedDeal={props.selectedDeal}
          selectedLog={salesLogs[selectedLogIndex]}
          // setSelectedLog={setSelectedLog}
          selectedLogIndex={selectedLogIndex}
          setSalesLogAtIndex={setSalesLogAtIndex}
          setSelectedLogIndex={setSelectedLogIndex}
          saveSalesLogs={saveSalesLogs}
        />
        :
        <div
          key={logIndex}
          style={{cursor: "pointer", display: "flex"}}
          onClick={(e: any) => {
            if (selectedLogIndex === null) {
              // setSelectedLog(log);
              setSelectedLogIndex(logIndex);
            }
          }}>
          <div style={{flex: 1}}>{log.date}</div>
          <div style={{flex: 5}}>{log.details}</div>
        </div>)
      }
      {(selectedLogIndex === null) ?
      <Button size="mini" color="orange"
        onClick={(e: any) => addLog()}>
        Add Log
      </Button>
      :
      <></>}
    </>
  )
}

function SalesLogForm(props: any) {
  return (
    <div>
      <div style={{display: "flex"}}>
        <div style={{flex: 1}}>
          <input 
            style={{width: "100%"}}
            value={props.selectedLog.date} 
            onChange={(e: any) => {
              props.setSalesLogAtIndex(
                props.selectedLogIndex, 
                {
                  ...props.selectedLog,
                  date: e.target.value
                });
            }} 
          />
        </div>
        <div style={{flex: 5}}>
          <input 
            style={{width: "100%"}}
            value={props.selectedLog.details || ""} 
            onChange={(e: any) => {
              props.setSalesLogAtIndex(
                props.selectedLogIndex,
                {
                  ...props.selectedLog,
                  details: e.target.value
                });
            }} 
          />
        </div>
      </div>
      <Button size="mini" color="green"
        onClick={(e: any) => {
          props.saveSalesLogs();
          props.setSelectedLogIndex(null);
        }}>
        Done
      </Button>
    </div>
  )
}
