import React, { useEffect, useRef, useState, createRef } from "react";
import { Button, Modal } from "semantic-ui-react";
import ReactMarkdown from "react-markdown";
import { Icon, Input } from "semantic-ui-react";

type WebItem = 
  { type: "md", md: string }
  | {type: "html", html: string}
  | {type: "imagebylink", src: string, alt: string, title: string}
  | {
      type: "imagestorage", src: string, alt: string, title: string, 
      pendingUpload?: boolean,  // For passing params. To be removed in handler
      fileData?: any,           // For passing params. To be removed i handler
    }

type WebPage = {
  id: string,
  title: string,
  path: string,
  site: string,
  date: string,
  items: WebItem[],
  hide?: boolean,
}

const Web = (props: any) => {
  const [webData, setWebData] = useState<WebPage[]>([]);
  const [webPage, setWebPage] = useState<WebPage | null>(null);
  const [selectedPageId, setSelectedPageId] = useState<string | null>(null);
  const [editedPageField, setEditedPageField] = useState<string | null>(null);
  const [editedPageValue, setEditedPageValue] = useState<string | null>(null);
  const [showIconIndex, setShowIconIndex] = useState<null | number>(null);
  const [mode, setMode] = useState<string>("Preview");
  const [edited, setEdited] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [scrollIndex, setScrollIndex] = useState<number | null>(null);
  const [selectedSite, setSelectedSite] = useState("mor.company");
  const [openAddModal, setOpenAddModal] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  
  useEffect(() => {
    if (props.webLoggedin) 
      props.onEvent({message: "LoadWebData", params: {}});
  }, [props.webLoggedin]);

  useEffect(() => {
    if (scrollIndex !== null) {
      itemRefs[scrollIndex]?.current?.scrollIntoView(true);
      setScrollIndex(null);
    }
  }, [scrollIndex])

  useEffect(() => {
    let items = props.webData?.map((item: any) => { 
      if (selectedPageId !== null && item.id === selectedPageId) {
        setWebPage(Object.assign({}, item));
      }
      return JSON.parse(JSON.stringify(item))
    }) || [];
    items = items.sort(
      (a: any, b: any) => 
        a.edited === b.edited ?
          (a.path < b.path ? -1 : 1)
        : (typeof a.edited === "undefined" || a.edited === null) ? 1
        : (typeof b.edited === "undefined" || b.edited === null) ? -1
        : (a.edited > b.edited ? -1 : 1)
    ) 
    setWebData(items);
  }, [props.webData, selectedPageId]);

  const addItem = (index: number, content: WebItem) => {
    if (webPage) {
      let newWebPage = Object.assign({}, webPage);
      newWebPage.items.splice(index + 1, 0, content)
      setWebPage(newWebPage);
      setMode("Edit");
      setShowIconIndex(index + 1);
      setScrollIndex(index + 1);
      setEdited(true);
    }
  }

  const handleItem = (index: number, action: string) => 
    () => {
      if (!webPage) return
      if (action === "delete") {
        setWebPage({
          ...webPage, 
          items: webPage.items.filter(
                  (item: any, docIndex: number) => 
                    docIndex !== index)
        });
        setShowIconIndex(null);
        setMode("Preview");
        setEdited(true);
      } else if (action === "up") {
        if (index === 0 || webPage.items.length === 1) return
        setWebPage({
          ...webPage,
          items: webPage.items.map(
                  (item: any, docIndex: number) => 
                    docIndex === index - 1 ? webPage.items[index]
                      : docIndex === index ? webPage.items[index - 1]
                      : item)
        });
        setShowIconIndex(index - 1)
        setEdited(true);
      } else if (action === "down") {
        if (index === webPage.items.length - 1 || webPage.items.length === 1) return
        setWebPage({
          ...webPage,
          items: webPage.items.map(
                  (item: any, docIndex: number) => 
                    docIndex === index ? webPage.items[index + 1]
                    : docIndex === index + 1 ? webPage.items[index]
                    : item
        )});
        setShowIconIndex(index + 1);
        setEdited(true);
      } else if (action === "md") {
        addItem(index, {type: "md", md: ""})
      } else if (action === "html") {
        addItem(index, {type: "html", html: ""})
      } else if (action === "imagebylink") {
        addItem(index, {type: "imagebylink", src: "", alt: "", title: ""})
      } else if (action === "imagestorage") {
        addItem(index, 
          {type: "imagestorage", src: "", alt: "", title: "", pendingUpload: true,});
      }
    }

  const setPageItem = (index: number, item: WebItem) => {
    setEdited(true);
    let newWebPage = Object.assign({}, webPage);
    newWebPage.items[index] = item;
    setWebPage(newWebPage);
  }

  const saveEditedPageField = () => {
    if (editedPageField) {
      props.onEvent({message: "SaveWebData", params: {
        webPage: {...webPage, [editedPageField]: editedPageValue}
      }});
    }
  }

  // For lookup
  let webDataDict: {[id: string]: WebPage} = {};
  if (webData) {
    webDataDict = Object.fromEntries(
      webData.map((page: WebPage) => ([page.id, page]))
    );
  }
  let itemRefs: any[] = [];
  if (webPage) {
    for (const item of webPage.items) {
      itemRefs.push(createRef());
    }
  }

  return(<div style={{display: "flex", height: "100%"}}>
    {/* Left Panel */}
    <div style={{width: "400px", overflowY: "scroll"}}>
      <div style={{display: "flex", borderBottom: "solid black 1px", alignItems: "center"}}>
        <input 
          style={{width: "100%"}} 
          placeholder="พิมพ์ข้อความค้นหา" 
          value={searchText}
          disabled={edited}
          onChange={(e: any) => {
            if (!edited) {
              setSelectedPageId(null);
              setSearchText(e.target.value);
            }
          }}
        />
        <select
          value={selectedSite}
          onChange={(e:any) => {
            setSelectedSite(e.target.value);
            setWebPage(null);
            setSelectedPageId(null);
          }}>
          <option value="mor.company">mor.company</option>
          <option value="jayjootar.com">jayjootar.com</option>
        </select>
        <Icon 
          name="add" color="green" inverted bordered size="small"
          onClick={(e: any) => setOpenAddModal(true)}
        />
        {/* <Icon 
          
          name="download" color="blue" inverted bordered size="small"
          onClick={(e: any) => {
            let path = webData
                        .filter((item: WebPage) => 
                          item.site === selectedSite && item?.path && !item?.hide)
                        .map((item: WebPage) => `https://${selectedSite}${item?.path}`)
                        .sort((a: string, b: string) => a < b ? -1 : 1);
            const link = document.createElement('a');
            link.download = `${selectedSite}.txt`;
            link.href = URL.createObjectURL(new Blob(
              [path.join("\n")], 
              {type: "text"})
            );
            link.click();
            link.remove();
          }}
        /> */}
        <Icon 
          name={showDeleted ? "eye slash" : "eye"} color="blue" 
          inverted bordered size="small" 
          onClick={(e: any) => setShowDeleted(!showDeleted)}
        />
      </div>
      {webData
      .filter((item: WebPage) => item.site === selectedSite)
      .filter((item: WebPage) => showDeleted ? true : !item?.hide)
      .filter((item: WebPage) => (
        (searchText === "") ? true : item.title?.toLowerCase()?.includes(searchText)
      ))
      .map((item: WebPage, dataIndex: number) => (
        <div 
          key={dataIndex} 
          style={{
            cursor: "pointer", 
            paddingLeft: "5px", 
            borderBottom: "solid #cccccc 1px",
            backgroundColor: selectedPageId === item.id && edited ? "pink" 
                              : selectedPageId === item.id && !edited ? "cyan" 
                              : edited ? "#eeeeee" : "white",
            textDecoration: item?.hide ? "line-through" : "none"
          }}
          onClick={(e: any) => {
            if (!edited) {
              setWebPage(JSON.parse(JSON.stringify(item)));
              setSelectedPageId(item.id);
              setShowIconIndex(null);
              setMode("Preview")
            }
          }}>
          <div>
            {(item.title || `${item.path}` || "[หน้าแรก]")}
          </div>
          <div style={{marginLeft: "10px"}}>{item.path}</div>
        </div>
      ))}
    </div>
    <div style={{flex: 1, height: "100%", overflowY: "hidden"}}>
      {/* Title Bar */}
      <div style={{height: "9.2vh", borderBottom: "solid black 1px", display: "flex", justifyContent: "space-between"}}>
        <div style={{flex: 1}}>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <PageSite 
              site={webPage?.site}
              swapSite={() => {
                let newSite = webPage?.site === "jayjootar.com" ? "mor.company" : "jayjootar.com"
                props.onEvent({message: "SaveWebData", params: {
                  webPage: {
                    ...webPage, 
                    site: newSite
                  }
                }});
                setSelectedSite(newSite);
              }}
            />
            <div style={{marginRight: "10px"}}>{webPage?.id ? `ID: ${webPage?.id}` : ""}</div>
          </div>
          <PageFieldEditor
            field="title"
            editedPageField={editedPageField}
            setEditedPageField={setEditedPageField}
            displayValue={
              !webPage ? "[เลือกหน้าที่ต้องการแก้ไขด้านซ้าย]" 
              : webPage?.title || webPage?.path || "[หน้าแรก]"
            }
            initPageValue={() => setEditedPageValue(webPage?.title || "")}
            editedPageValue={editedPageValue}
            setEditedPageValue={setEditedPageValue}
            save={saveEditedPageField}
          />
          <PageFieldEditor
            field="path"
            editedPageField={editedPageField}
            setEditedPageField={setEditedPageField}
            displayValue={webPage?.path}
            initPageValue={() => setEditedPageValue(webPage?.path || "")}
            editedPageValue={editedPageValue}
            setEditedPageValue={setEditedPageValue}
            save={saveEditedPageField}
          />
          <PageFieldEditor
            field="date"
            editedPageField={editedPageField}
            setEditedPageField={setEditedPageField}
            displayValue={webPage?.date}
            initPageValue={() => setEditedPageValue(webPage?.date || "")}
            editedPageValue={editedPageValue}
            setEditedPageValue={setEditedPageValue}
            save={saveEditedPageField}
          />
        </div>
        <div>
          <Button size="mini" color="red" disabled={!edited}
            onClick={(e: any) => {
              setEdited(false);
              if (selectedPageId !== null) {
                setWebPage(JSON.parse(JSON.stringify(webDataDict[selectedPageId])));
              }
              setMode("Preview");
            }}>
            Cancel
          </Button>
          <Button size="mini" color="green" disabled={!edited}
            onClick={(e: any) => {
              props.onEvent({message: "SaveWebData", params: {webPage}});
              setEdited(false);
              setMode("Preview");
            }}>
            Save
          </Button>
        </div>
      </div>

      {/* Content */}
      {webPage && 
      <div 
        style={{
          height: "86vh", 
          overflowY:"auto",
          display: "grid",
          gridTemplateColumns: "1fr 768px 1fr"
        }}>
        <div></div>
        <div>
        {(webPage.items || []).map((item: any, index: number) => (
          <div 
            key={index}
            ref={itemRefs[index]}
            style={{
              position: "relative", 
              backgroundColor: (mode === "Preview" && showIconIndex === index) ? "#eeffff" : "white"
            }}
            onClick={(e: any) => {
              setMode("Edit");
            }}
            onMouseOver={(e: any) => {
              if (mode === "Preview") {
                setShowIconIndex(index); 
              }
            }}
            onMouseLeave={(e: any) => {
              if (mode === "Preview") {
                setShowIconIndex(null);
              }
            }}>

            {
            // Markdown
            (item.type === "md" && showIconIndex === index && mode === "Edit") ? 
            <textarea               
              style={{width: "100%", height: "200px"}}
              value={item.md}
              onChange={(e: any) => {
                setPageItem(showIconIndex, {type: "md", md: e.target.value});
              }}
            />
            : (item.type === "md" && item.md === "") ?
            <div style={{margin: 0, height: "50px", backgroundColor: "pink"}}>
              [Empty MD]
            </div>
            : (item.type === "md") ?
            <ReactMarkdown>{item.md}</ReactMarkdown>

            // HTML
            :(item.type === "html" && showIconIndex === index && mode === "Edit") ?
            <textarea               
              style={{width: "100%", height: "200px"}}
              value={item.html}
              onChange={(e: any) => {
                setPageItem(showIconIndex, {type: "html", html: e.target.value});
              }}
            />
            : (item.type === "html" && item.html === "") ?
            <div style={{margin: 0, height: "50px", backgroundColor: "pink"}}>
              [Empty HTML]
            </div>
            : (item.type === "html") ?
            <div dangerouslySetInnerHTML={{__html: item.html}}/>

            // Image by link
            :(item.type === "imagebylink" && showIconIndex === index && mode === "Edit") ?
            <ImageByLinkEditor
              src={item.src}
              alt={item.alt}
              title={item.title}
              onCancel={() => {
                setMode("Preview")
              }}
              onSave={(src: string, alt: string, title: string) => {
                setMode("Preview");
                setPageItem(showIconIndex, 
                  {type: "imagebylink", src: src, alt: alt, title: title});
              }} 
            />            
            : (item.type === "imagebylink" && item.src === "") ?
            <div style={{margin: 0, height: "50px", backgroundColor: "pink"}}>
              [Blank Image]
            </div>
            : (item.type === "imagebylink") ?
            <img style={{width: "100%"}} src={item.src} alt={item.alt} title={item.title}/>

            // Image Storage
            : (item.type === "imagestorage" && showIconIndex === index && mode === "Edit") ?
            <ImageStorageEditor
              src={item.src}
              alt={item.alt}
              title={item.title}
              onCancel={() => {
                setMode("Preview")
              }}
              onSave={(src: string, alt: string, title: string, fileData: any) => {
                setMode("Preview");
                setPageItem(showIconIndex, 
                  {
                    type: "imagestorage", src: src, alt: alt, title: title, 
                    pendingUpload: true,
                    fileData,
                  });
              }} 
            />       
            : (item.type === "imagestorage") ?
            <img style={{width: "100%"}} src={item.src} alt={item.alt} title={item.title}/>
            
            // Other
            : <></>}

            {/* Icon Bar ----------------------------------------------------------------------------------------- */}
            {(showIconIndex == index && mode === "Edit") ?
            <div 
              style={{
                display: "flex", position: "absolute", right: 10, top: 0
              }} 
              onClick={(e: any) => e.stopPropagation()}>
              
              {/* Type Name */}
              {item.type === "md" ?
              <div>[MD]</div>
              :item.type === "html" ?
              <div>[HTML]</div>
              :item.type === "imagebylink" ?
              <div>[IMAGEBYLINK]</div>
              :item.type === "imagestorage" ?
              <div>[IMAGESTORAGE]</div>
              :<></>}

              {/* Manipulation */}
              {webPage.items.length > 1 &&
              <Icon name="delete" color="red"
                onClick={handleItem(index, "delete")}
              />}
              {webPage.items.length > 1 &&
              <Icon name="angle up" color="orange"
                onClick={handleItem(index, "up")}
              />}
              {webPage.items.length > 1 &&
              <Icon name="angle down" color="orange"
                onClick={handleItem(index, "down")}
              />}

              {/* Adding new content after */}
              <Icon name="file text" color="blue"
                onClick={handleItem(index, "md")}
              />
              <Icon name="html5" color="blue"
                onClick={handleItem(index, "html")}
              />
              <Icon name="picture" color="blue"
                onClick={handleItem(index, "imagebylink")}
              />
              <Icon name="picture" color="orange"
                onClick={handleItem(index, "imagestorage")}
              />

              {/* Done editing */}
              {["md", "html", "imagestorage"].includes(item.type) &&
              <Icon name="checkmark" color="green"
                onClick={(e: any) => setMode("Preview")}
              />}
            </div>
            : <></>}
          </div>
        ))}
        </div>
        <div></div>
      </div>}
    </div>
    <Modal
      size="mini"
      open={openAddModal}>
      <AddPageModal 
        onEvent={props.onEvent}
        selectedSite={selectedSite}
        setOpenAddModal={setOpenAddModal}
        setSelectedPageId={setSelectedPageId}
      />
    </Modal>
  </div>)
}

const PageSite = (props: any) => {
  return(
    <div
      style={{display: "flex", alignItems: "center"}}>
      <div style={{padding: "0 5px 0 5px"}}>{props.site}</div>
      <Icon 
        name="refresh" size="small" inverted bordered color="blue" 
        onClick={props.swapSite}
      />
    </div>
  )
}

const PageFieldEditor = (props: any) => {
  return (
    <div 
      style={{paddingLeft: "5px"}}
      onClick={(e: any) => {
        if (props.editedPageField === null) {
          props.initPageValue();
          props.setEditedPageField(props.field);
        }
      }}
      >
      {props.editedPageField === props.field ? 
      <div style={{display: "flex"}}>
        <input 
          style={{width: "400px"}}
          value={props.editedPageValue} 
          onChange={(e: any) => {
            props.setEditedPageValue(e.target.value);
          }}
        />
        <button 
          onClick={(e: any) => {
            e.stopPropagation();
            props.setEditedPageField(null);
          }}>
          Cancel
        </button>
        <button
          onClick={(e: any) => {
            e.stopPropagation();
            props.save();
            props.setEditedPageField(null);
          }}>
          Save
        </button>
      </div>
      
      :<div>{props.displayValue}</div> 
      }
    </div>
  )
}

const AddPageModal = (props: any) => {
  const [title, setTitle] = useState("");
  const [path, setPath] = useState("");
  const [date, setDate] = useState("");
  return(
    <div 
      style={{display: "grid", gridTemplateColumns: "20% 80%", padding: "5px"}}>
      <div>Site</div>
      <div>{props.selectedSite}</div>
      <div>Title</div>
      <Input size="mini" value={title} onChange={(e: any) => setTitle(e.target.value)} />
      <div>Path</div>
      <Input size="mini" value={path} onChange={(e: any) => setPath(e.target.value)} />
      <div>Date</div>
      <Input size="mini" value={date} onChange={(e: any) => setDate(e.target.value)} />
      <div style={{textAlign: "right", gridColumn: "1 / 2", display: "flex", marginTop:"10px"}}>
        <Button
          size="small" color="green"
          onClick={(e: any) => {
            props.onEvent({message: "AddWebPage", params: {
              title, path, date, 
              site: props.selectedSite,
              setSelectedPageId: props.setSelectedPageId
            }});
            props.setOpenAddModal(false);
          }}>
          Save
        </Button>
        <Button
          size="small" color="red"
          onClick={(e: any) => props.setOpenAddModal(false)}>
          Cancel
        </Button>
      </div>
    </div>
  )
}

const ImageByLinkEditor = (props: any) => {
  const [src, setSrc] = useState("");
  const [alt, setAlt] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => setSrc(props.src), [props.src]);
  useEffect(() => setAlt(props.alt), [props.alt]);
  useEffect(() => setTitle(props.title), [props.title]);

  return(
    <div style={{backgroundColor: "#eeffff"}}>
      <div>{"\u00a0"}</div>
      <div style={{padding: "10px"}}>
        <div>src</div>
        <input 
          style={{width: "100%"}}
          value={src}
          onChange={(e: any) => {
            setSrc(e.target.value);
          }}
        />
      </div>
      <div style={{padding: "10px"}}>
        <div>alt</div>
        <input 
          style={{width: "100%"}}
          value={alt}
          onChange={(e: any) => {
            setAlt(e.target.value);
          }}
        />
      </div>
      <div style={{padding: "10px"}}>
        <div>title</div>
        <input 
          style={{width: "100%"}}
          value={title}
          onChange={(e: any) => {
            setTitle(e.target.value);
          }}
        />
      </div>
      <div style={{padding: "10px"}}>
        <button 
          onClick={(e: any) => {
            e.stopPropagation();
            props.onCancel();
          }}>
          Cancel
        </button>
        <button 
          onClick={(e: any) => {
            e.stopPropagation();
            props.onSave(src, alt, title);
          }}>
          Confirm
        </button>
      </div>
    </div>
  )
}

const ImageStorageEditor = (props: any) => {
  const [src, setSrc] = useState("");
  const [alt, setAlt] = useState("");
  const [title, setTitle] = useState("");
  const [showPrompt, setShowPrompt] = useState(false);
  const imageData = useRef<ArrayBuffer | null>(null);
  const fileData = useRef<any>(null);


  useEffect(() => setSrc(props.src), [props.src]);
  useEffect(() => setAlt(props.alt), [props.alt]);
  useEffect(() => setTitle(props.title), [props.title]);

  return(
    <div style={{backgroundColor: "#eeffff"}}>
      <div>{"\u00a0"}</div>
      <div style={{padding: "10px"}}>
        {src !== "" &&
        <img style={{width: "100%"}} src={src} />}
        <input 
          type="file"
          onClick={(e: any) => e.target.value = null}
          onChange={async (e: any) => {
            if (e.target?.files?.[0]) {
              let file = e.target.files[0];
              setShowPrompt(true);
              try {
                let arrayBuffer: ArrayBuffer = await new Promise((resolve: any) => {
                  let reader = new FileReader();
                  reader.onload = () => resolve(reader.result);
                  reader.readAsArrayBuffer(file);
                });
                imageData.current = arrayBuffer;
                fileData.current = file;
                let picUrl = URL.createObjectURL(new Blob([arrayBuffer]));
                setSrc(picUrl);
              } catch (e: any) { console.log(e) }
            }
          }}
        />
      </div>
      <div style={{padding: "10px"}}>
        <div>alt</div>
        <input 
          style={{width: "100%"}}
          value={alt}
          onChange={(e: any) => {
            setAlt(e.target.value);
          }}
        />
      </div>
      <div style={{padding: "10px"}}>
        <div>title</div>
        <input 
          style={{width: "100%"}}
          value={title}
          onChange={(e: any) => {
            setTitle(e.target.value);
          }}
        />
      </div>
      {showPrompt &&
      <div style={{padding: "10px"}}>
        <button 
          onClick={(e: any) => {
            e.stopPropagation();
            props.onCancel();
          }}>
          Cancel
        </button>
        <button 
          onClick={(e: any) => {
            e.stopPropagation();
            props.onSave(src, alt, title, fileData.current);
          }}>
          Confirm
        </button>
      </div>}
    </div>
  )
}

export default Web;