import { WasmHandler } from 'react-lib/frameworks/WasmController';
import app from "firebase/compat/app";
// import XLSX from "xlsx";

export type State = {
  clientDict?: any,
  clientDeal?: any,
  pdfExtract?: any,
  webviewMessages?: string[],
  keepList?: any,
  clientUserDict?: Map<string, any>,
  clientContactDict?: Map<string, any>,
}

export const StateInitial = {
  clientDict: {},
  clientDeal: {},
  pdfExtract: {},
  webviewMessages: [],
  keepList: {},
  clientUserList: [],
  clientContactList: [],
}

export type Event =
  // AC 
  { message: "GetClientData", params: {} }
  | { message: "AddNewClient", 
      params: { client: { name: string, parentId: string | null } } }
  | { message: "EditExistingClient", 
      params: { client: { id: string, name: string, parentId: string | null } } }
  | { message: "CreateClientDeal", 
      params: { clientId: string, clientName: string } }
  | { message: "UpdateClientDeal", params: { id: string, data: any } }
  | { message: "UpdateSalesLog", 
      params: { dealId: string, salesLogId: string, data: any } }
  | { message: "UploadDealDocument", 
      params: { data: ArrayBuffer } }
  | { message: "AddDealDeliverable", params: any }      
  | { message: "UpdateDealDeliverable", params: any }      
  | { message: "GetKeepList", params: { clientId: string } }
  | { message: "UpdateKeepItem", 
      params: { clientId: string, keepId: string, data: any } }

  // CP
  | { message: "GetClientUser", params: {} }
  | { message: "SetClientContact", params: { email: string, clientList: string[]} }
  | { message: "SetClientContactPermission", params: any }

export type Data = {
}

export const DataInitial = {
}
  
type Handler = WasmHandler<State, Event>

type ClientDeal = { 
  [id: string]: { 
                  id: string, 
                  clientId: string, 
                  clientName: string, 
                  salesLogs: any[], 
                  deliverables: any,
                  documents: any,
                } 
};
type ClientDict = { 
  [id: string] :  { 
                    name?: string, 
                    dealCount: number, 
                    onGoingCount: number, 
                    salesCount: number, 
                    dealCountConsol: number, 
                    onGoingCountConsol: number, 
                    salesCountConsol: number, 
                    totalValue: number, 
                    onGoingValue: number, 
                    salesValue: number,
                    totalValueConsol: number, 
                    onGoingValueConsol: number, 
                    salesValueConsol: number
                  } 
};

// Account (AC) --------------------------------------------------------------------------

export const GetClientData: Handler = async (controller, params) => {
  // Deal info
  const result = await controller.db.collection("ClientDeal").get();
  const clientDeal: ClientDeal = Object.fromEntries(result.docs.map(
    (doc: app.firestore.DocumentSnapshot, dealIndex: number) => (
      [ doc.id, { id: doc.id, ...(doc.data() as any) }])));

  // Client info
  const clientDict: ClientDict = Object.fromEntries(
    (await controller.db.collection("ClientProfile").get())
      .docs.map((doc: app.firestore.DocumentSnapshot) => { 
        const deals = Object.values(clientDeal)
          .filter((deal: any) => deal.clientId === doc.id);
        const totalValue = deals
          .map((deal: any) => deal.value || 0)
          .reduce((acc: number, cur: number) => acc + cur, 0);
        const onGoing = deals.filter((deal: any) => deal.status !== "SALES");
        const onGoingValue = onGoing
          .map((deal: any) => deal.value || 0)
          .reduce((acc: number, cur: number) => acc + cur, 0);
        return [
          doc.id, 
          { id: doc.id, 
            dealCount: deals.length, 
            onGoingCount: onGoing.length, 
            salesCount: deals.length - onGoing.length,
            dealCountConsol: deals.length, 
            onGoingCountConsol: onGoing.length, 
            salesCountConsol: deals.length - onGoing.length,
            totalValue: totalValue, 
            onGoingValue: onGoingValue,
            salesValue: totalValue - onGoingValue,
            totalValueConsol: totalValue, 
            onGoingValueConsol: onGoingValue,
            salesValueConsol: totalValue - onGoingValue,
            ...doc.data()}
        ];
      }));

  // Collect Client Children info
  addLevelInfo(clientDict, [], 0, 1);

  // Set state
  controller.setState({clientDict: clientDict, clientDeal: clientDeal});
}

function addLevelInfo(
  clientDict: any, parentIdList: string[], 
  level: number, seq: number
) {
  const entries = Object.entries(clientDict)
                .filter((entry: any) => 
                  parentIdList.length === 0 ? (!entry[1].parentId) 
                  : entry[1].parentId === parentIdList[0]
                ).sort((entry1: [string, any], entry2: [string, any]) => 
                  entry1[1].name < entry2[1].name ? -1 : 1);
  for (const entry of entries) {
    const name = (entry as [string, any])[1].name;
    
    clientDict[entry[0]].level = level;
    clientDict[entry[0]].seq = seq;
    clientDict[entry[0]].display = "\u00a0".repeat(level * 5) + name;
    
    for (const parentId of parentIdList) {
      clientDict[parentId].dealCountConsol += clientDict[entry[0]].dealCount;
      clientDict[parentId].onGoingCountConsol += clientDict[entry[0]].onGoingCount;
      clientDict[parentId].salesCountConsol += clientDict[entry[0]].salesCount;
      clientDict[parentId].totalValueConsol += clientDict[entry[0]].totalValue;
      clientDict[parentId].onGoingValueConsol += clientDict[entry[0]].onGoingValue;
      clientDict[parentId].salesValueConsol += clientDict[entry[0]].salesValue;
    }
    seq += 1
    seq = addLevelInfo(clientDict, [entry[0], ...parentIdList], level + 1, seq);
  }
  return seq;
}

export const AddNewClient: Handler = async (controller, params) => {
  const newClient = await controller.db.collection("ClientProfile").add(params.client);  
  let {clientDict, ...rest} = controller.getState();
  let {name, parentId, ...data} = params.client;
  let id = newClient.id;
  if (!id || !name) return;
  let newClientDict = Object.assign({}, clientDict || {});
  newClientDict[id] = {
    id: id,
    name: name, 
    parentId: parentId || null,
    level: 0, 
    seq: Object.keys(clientDict).length + 1,
    dealCount: 0, 
    onGoingCount: 0, 
    salesCount: 0,
    dealCountConsol: 0, 
    onGoingCountConsol: 0, 
    salesCountConsol: 0,
    totalValue: 0, 
    onGoingValue: 0,
    salesValue: 0,
    totalValueConsol: 0, 
    onGoingValueConsol: 0,
    salesValueConsol: 0,
  }
  addLevelInfo(newClientDict, [], 0, 1);
  controller.setState({clientDict: newClientDict});
}

export const EditExistingClient: Handler = async (controller, params) => {
  let {clientDict, clientDeal, ...rest} = controller.getState();
  let {id, ...data} = params.client;
  if (!id) return console.log("No id.");
  controller.db.collection("ClientProfile").doc(id).update(data);
  if (data.name) {
    let name = data.name;
    let newClientDict = Object.assign({}, clientDict || {});
    let newClientDeal = Object.assign({}, clientDeal || {});
    newClientDict[id] = {...newClientDict[id], name, ...data}
    for (const dealId of Object.keys(newClientDeal)) {
      if (newClientDeal[dealId].clientId === id) {
        controller.db.collection("ClientDeal").doc(dealId).update({clientName: name});
        newClientDeal[dealId].clientName = name
      }
    }
    controller.setState({clientDict: newClientDict, clientDeal: newClientDeal});
  } else {
    let newClientDict = Object.assign({}, clientDict || {});
    newClientDict[id] = {...newClientDict[id], ...data};
    controller.setState({clientDict: newClientDict});
  }
}

export const CreateClientDeal: Handler = async (controller, params) => {
  const doc = await controller.db.collection("ClientDeal").add({
    ...params, 
    deliverables: {},
    value: 0,
  });
  SyncClientDataFromDeal(controller, {dealId: doc.id, data: params});
}

export const UpdateClientDeal: Handler = async (controller, params) => {
  let {id, data} = params;
  if (!id || !data) return;
  const deal = (await controller.db.collection("ClientDeal").doc(id).get()).data();
  if (!deal) return;
  await controller.db.collection("ClientDeal").doc(id).update(data);
  UpdateDealOngoing(controller, {dealId: id});
  SyncClientDataFromDeal(controller, {dealId: id, data: data});
}

const SyncClientDataFromDeal: Handler = async (controller, {dealId, data}) => {
  let {clientDict, clientDeal, ...rest} = controller.getState();
  let newClientDict = Object.assign({}, clientDict || {});
  let newClientDeal = Object.assign({}, clientDeal || {});
  newClientDeal[dealId] = {
    id: dealId,
    ...(newClientDeal?.[dealId] || {}), 
    ...data
  };
  for (const clientId of Object.keys(newClientDict)) {
    const deals = Object.values(newClientDeal)
      .filter((deal: any) => deal.clientId === clientId);
    const totalValue = deals
      .map((deal: any) => deal.value || 0)
      .reduce((acc: number, cur: number) => acc + cur, 0);
    const onGoing = deals.filter((deal: any) => deal.status !== "SALES");
    const onGoingValue = onGoing
      .map((deal: any) => deal.value || 0)
      .reduce((acc: number, cur: number) => acc + cur, 0);
    newClientDict[clientId] = { 
      ...newClientDict[clientId],
      id: clientId, 
      dealCount: deals.length, 
      onGoingCount: onGoing.length, 
      salesCount: deals.length - onGoing.length,
      dealCountConsol: deals.length, 
      onGoingCountConsol: onGoing.length, 
      salesCountConsol: deals.length - onGoing.length,
      totalValue: totalValue, 
      onGoingValue: onGoingValue,
      salesValue: totalValue - onGoingValue,
      totalValueConsol: totalValue, 
      onGoingValueConsol: onGoingValue,
      salesValueConsol: totalValue - onGoingValue,
    };
  }
  controller.setState({clientDict: newClientDict, clientDeal: newClientDeal});
}

const UpdateDealOngoing: Handler = async (controller, {dealId}) => {
  if (!dealId) return;
  let dealData = (await controller.db.collection("ClientDeal").doc(dealId).get())?.data() || {};
  const updateData = {
    dealId: dealId,
    status: dealData?.status,
    clientId: dealData?.clientId || null,
    clientName: dealData?.clientName || null,
    dealName: dealData?.dealName,
    deliverableDict: Object.fromEntries((Object.values(dealData?.deliverables) || []).map(
      (deliverable: any) => ([
          deliverable.deliverableId,
          {
            name: deliverable.name,
            status: deliverable.status,
            deadline_current: deliverable?.deadline_current || null
          }
        ])))
  };
  controller.db.collection("cache").doc("dealOngoing")
    .update({[dealId]: updateData});
}

export const UpdateSalesLog: Handler = async (controller, params) => {
  const {dealId, salesLogs} = params;
  if (!dealId || !salesLogs) return;
  controller.db.collection("ClientDeal")
    .doc(params.dealId)
    .update({salesLogs: salesLogs});
  const clientDeal = Object.assign({}, controller.getState().clientDeal);
  clientDeal[params.dealId].salesLogs = params.salesLogs;
  controller.setState({clientDeal: clientDeal});
}

export const UploadDealDocument: Handler = async (controller, params) => {
  const {selectedDeal, docName, data} = params;
  if (!selectedDeal || !docName || !data) return;
  const dealId = selectedDeal.id;
  const clientId = selectedDeal.clientId;
  const prefix = `gs://ismor-client/ClientDeal/${dealId}/`;
  const newDoc = await controller.db.collection("ClientDocument")
    .add({type: "DealDocument", dealId, clientId, prefix, docName});
  const url = `${prefix}${newDoc.id}/${docName}`;
  const result = await controller.storage.refFromURL(url).put(new Blob([data]))
    .catch((error: any) => console.log(error));
  if (result) {
    let documents: any = selectedDeal?.documents || {};
    let docData = {name: docName, url: url};
    documents[newDoc.id] = docData;
    await controller.db.collection("ClientDeal")
      .doc(selectedDeal.id)
      .update({documents});
    let clientDeal: ClientDeal = Object.assign({}, controller.getState().clientDeal);
    if (!clientDeal[selectedDeal.id]?.documents)
      clientDeal[selectedDeal.id].documents = {};
    clientDeal[selectedDeal.id].documents[newDoc.id] = docData;
    controller.setState({clientDeal});
  } else {
    await controller.db.collection("ClientDeal").doc(newDoc.id).delete();
  }
}

export const AddDealDeliverable: Handler = async (controller, {data, selectedDeal}) => {
  if (!data || !selectedDeal ) return;
  const newDeliverable = await controller.db.collection("ClientDealDeliverable").add(data);
  let deal = Object.assign({}, selectedDeal);
  deal.deliverables[newDeliverable.id] = {deliverableId: newDeliverable.id, ...data};
  await controller.db.collection("ClientDeal").doc(deal.id).update({deliverables: deal.deliverables});
  let {id, ...dealData} = deal;
  UpdateDealOngoing(controller, {dealId: id})
  let clientDeal: ClientDeal = Object.assign({}, controller.getState().clientDeal);
  clientDeal[id].deliverables[newDeliverable.id] = {deliverableId: newDeliverable.id, ...data};
  controller.setState({clientDeal});
}

export const UpdateDealDeliverable: Handler = async (controller, {deliverableId, data, selectedDeal}) => {
  if (!deliverableId || !data || !selectedDeal || !(selectedDeal?.deliverables?.[deliverableId])) return;
  controller.db.collection("ClientDealDeliverable").doc(deliverableId).update(data);
  let deal = Object.assign({}, selectedDeal);
  deal.deliverables[deliverableId] = {deliverableId, ...data};
  await controller.db.collection("ClientDeal").doc(deal.id).update({deliverables: deal.deliverables});
  let {id, ...dealData} = deal;
  UpdateDealOngoing(controller, {dealId: id});
  let clientDeal: ClientDeal = Object.assign({}, controller.getState().clientDeal);
  clientDeal[id].deliverables[deliverableId] = {deliverableId, ...data};
  controller.setState({clientDeal});
}

export const GetKeepList: Handler = async (controller, params) => {  
  let clientId = "ppMimpGtqRglSZnscHkJ";
  const data = (await controller.db.collection("ClientProfile").doc(clientId).get()).data() || {};
  controller.setState({
    keepList: Object.fromEntries(
      await Promise.all(Object.entries(data.Keep).map(
        async (entry: [string, any]) => ([
          entry[0],
          {
            documentId: entry[0],
            ...entry[1],
            downloadUrl: await controller.storage.refFromURL(entry[1].url).getDownloadURL(),
            detail: (await controller.db.collection("ClientDocument").doc(entry[0]).get()).data() || {}
          }
        ]))))
  });
}

export const UpdateKeepItem: Handler = async (controller, {selectedItem, data}) => {
  if (!selectedItem || !data) return;
  const {documentId, ...otherlData} = selectedItem;
  await controller.db.collection("ClientDocument").doc(documentId).update(data);
  let newKeepList = Object.assign({}, controller.getState().keepList);
  newKeepList[documentId].detail = {...(newKeepList?.[documentId]?.detail || {}), ...data}
  controller.setState({keepList: newKeepList});
}

// Client Portal (CP) --------------------------------------------------------------------------
export const GetClientUser: Handler = async (controller, params) => {
  let dbweb = (controller as any).dbweb as app.firestore.Firestore;
  if (!dbweb) return console.log("No dbweb. Can't get ClientUser");

  let res = await dbweb.collection("ClientUser").get();
  let clientUserDict = new Map(res.docs.map((doc: any) => ([doc.id, doc.data()])));

  let res1 = await controller.db.collection("ClientContact").get();
  let clientContactDict = new Map(res1.docs.map((doc: any) => ([doc.id, doc.data()])));

  controller.setState({clientUserDict, clientContactDict});
}

export const SetClientContact: Handler = async (controller, params) => {
  let { email, clientList } = params;
  if (!email) return console.log("No email (user id).");
  controller.db.collection("ClientContact").doc(email).set({clientList});
  let {clientContactDict} = controller.getState();
  if (!clientContactDict) return console.log("No clientUserDict");
  let newClientContactDict = new Map(clientContactDict.entries());
  newClientContactDict.set(email, {...clientContactDict.get(email), clientList });
  controller.setState({clientContactDict: newClientContactDict});
}

export const SetClientContactPermission: Handler = 
  async (controller, {email, data, setMessage, xdDict, ihGameList}) => 
{
  if (!email) return console.log("No email (user id).");
  
  let dbweb = (controller as any).dbweb as app.firestore.Firestore;
  if (!dbweb) return console.log("No dbewb. Can't set user permission");
  
  let {clientContactDict} = controller.getState();
  if (!clientContactDict) return console.log("No clientUserDict");

  let message = ["Updating permissions"];
  setMessage(message);

  if (data.xdList && xdDict) {
    let oldXdList = clientContactDict.get(email)?.xdList || [];
    let newXdList = data.xdList || [];
    let xdToRemovePermission = oldXdList.filter((xdId: string) => !newXdList.includes(xdId));
    let xdToAddPermission = newXdList.filter((xdId: string) => !oldXdList.includes(xdId));
    for (const xdId of xdToRemovePermission) {
      let doc = await dbweb.collection("xd2").doc(xdId).get();
      let xdData = doc.data();
      if (doc.exists && xdData) {
        let newUserList = (xdData.users || []).filter((user: string) => user !== email)
        await doc.ref.update({users: newUserList});
      } else {
        message.push(`Can't remove ${email} from XD ${xdDict?.[xdId]}. XD not yet exists`);
        setMessage(message);
      }
    }
    for (const xdId of xdToAddPermission) {
      let doc = await dbweb.collection("xd2").doc(xdId).get();
      let xdData = doc.data();
      if (doc.exists && xdData) {
        let newUserList = Array.from(new Set((xdData.users || []).concat([email])));
        await doc.ref.update({users: newUserList});
      } else {
        message.push(`Can't add ${email} to XD ${xdDict?.[xdId]}. XD not yet exists`);
        setMessage(message);
        data.xdList = data.xdList.filter((item: any) => item !== xdId);
      }
    }
  }

  if (data.gameList && ihGameList) {
    let oldGameList = clientContactDict.get(email)?.gameList || [];
    let newGameList = data.gameList || [];
    let gameToRemovePermission = oldGameList.filter((gameId: string) => !newGameList.includes(gameId));
    let gameToAddPermission = newGameList.filter((gameId: string) => !oldGameList.includes(gameId));
    for (const gameId of gameToRemovePermission) {
      let doc = await dbweb.collection("IHGame").doc(gameId).get();
      let gameData = doc.data();
      if (doc.exists && gameData) {
        let newUserList = (gameData.users || []).filter((user: string) => user !== email)
        await doc.ref.update({users: newUserList});
      } else {
        message.push(
          `Can't remove ${email} from Game ` 
          + `${ihGameList.find((item: any) => item.id === gameId)?.name}. Game not yet exists`);
        setMessage(message);
      }
    }
    for (const gameId of gameToAddPermission) {
      let doc = await dbweb.collection("IHGame").doc(gameId).get();
      let gameData = doc.data();
      if (doc.exists && gameData) {
        let newUserList = Array.from(new Set((gameData.users || []).concat([email])));
        await doc.ref.update({users: newUserList});
      } else {
        message.push(
          `Can't add ${email} to Game ` 
          + `${ihGameList.find((item: any) => item.id === gameId)?.name}. Game not yet exists`);
        setMessage(message);
        data.gameList = data.gameList.filter((item: any) => item !== gameId);
      }
    }
  }

  await controller.db.collection("ClientContact").doc(email).update(data);
  
  let newClientContactDict = new Map(clientContactDict.entries());
  newClientContactDict.set(email, {...clientContactDict.get(email), ...data });
  controller.setState({clientContactDict: newClientContactDict});

  if (message.length > 1) {
    setTimeout(() => {
      setMessage(null);
    }, 2000);
  } else {
    setMessage(null);
  }
}