import React from "react"

export const EmbeddedDataStudio = (props: any) => (
  <div
    style={{height: "94vh", width: "100%"}}> 
    {props.charts.map((chartId: string, index: number) => (
      <div key={index} style={{ height: props.mobileScreen ? "30vh": "100%" }}>
        <iframe 
          width="100%" 
          height="100%" 
          src={`https://datastudio.google.com/embed/reporting/${chartId}`}
          frameBorder="0" 
          style={{ border:0 }} 
          allowFullScreen 
        />  
      </div>
    ))}
  </div>
)

export const EmbeddedDataStudioOld = (props: any) => (
  <div
    style={{
      display: "grid",
      gridTemplateColumns: props.mobileScreen ? "1fr" : "1fr 1fr",
      height: "94vh",
      overflowY: "scroll"
    }}> 
    {props.charts.map((chartId: string, index: number) => (
      <div key={index} style={{ height: props.mobileScreen ? "30vh": "47vh" }}>
        <iframe 
          width="100%" 
          height="100%" 
          src={`https://datastudio.google.com/embed/reporting/${chartId}`}
          frameBorder="0" 
          style={{ border:0 }} 
          allowFullScreen 
        />  
      </div>
    ))}
  </div>
)

export default EmbeddedDataStudio