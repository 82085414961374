import { useEffect, useState } from "react";
import Tab from "react-lib/frameworks/Tab";
import { RedmineProject } from "./ClassifyProject";

const RedmineAssigned = (props: any) => {
  const [byClassifyData, setByClassifyData] = useState<any[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [showFixed, setShowFixed] = useState(false);

  useEffect(() => {
    // props.onEvent({message: "GetRedmineIssueSummary", params: {}});
  }, []);
  
  useEffect(() => {
    let byClassifyData_: any[] = (Array.from(
      props.byClassifyData
      .flatMap((project: any) => (project.issues))
      .filter((issue: any) => 
        issue.status_id !== 3 
        // && (!issue.assigned_to || issue.assigned_to.includes("@thevcgroup.com"))
      )
      .reduce((acc: Map<string, any>, issue: any) => {
        const assigned_to = issue.assigned_to || "Not assigned"
        if (!acc.get(assigned_to)) {
          acc.set(assigned_to, []);
        }
        acc.get(assigned_to).push(issue);
        return acc
      }, new Map<string, any>())
      .entries()
    ) as [string, any[]][])
    .sort((a: [string, any[]], b: [string, any[]]) =>
      a[0] < b[0] ? -1: 1
    )
    .map((user: [string, any[]]) => (
      {
        parent_name: user[0],
        not_resolved: user[1].map((issue: any) => (
            {
              ...issue,
              meta:`[${issue.parent_name}` 
                  + `->${issue.assigned_to || "not assigned"}]` 
            }
          ))
          .sort((a:any, b: any) => a.created_on_elapsed < b.created_on_elapsed ? 1 : -1)
      }
    ))
    setByClassifyData(byClassifyData_);
    setSelectedIndex(byClassifyData_.findIndex((item: any) => item.parent_name === props.redmineUser));
  }, [props.byClassifyData]);

  return(
      props.byClassifyData.length === 0 ? <div>Loading data...</div> :
      <div 
          style={{ height: "95vh" }}>
          {selectedIndex !== null &&
          <RedmineProject 
            showFixed={showFixed}
            setShowFixed={setShowFixed}
            selector={
              <select
                style={{marginRight: "5px"}}
                value={selectedIndex}
                onChange={(e: any) => setSelectedIndex(e.target.value)}>
                {byClassifyData.map((item: any, index: number) => 
                  <option key={index} value={index}>
                    {`${byClassifyData[index].parent_name} (${byClassifyData[index].not_resolved.filter((issue: any) => showFixed ? true : issue.status_id !== 9).length})`}
                  </option>
                )}
              </select>
            }
            project={byClassifyData[selectedIndex]} 
            mobileScreen={props.mobileScreen} 
          />}
      </div>
  )
}

export default RedmineAssigned
