import { useEffect, useRef, useState } from "react";
import FileExplorer from "./FileExplorer";
import { Button, Input } from "semantic-ui-react";

export default function AddScreenFromVideo(props: any) {
  const [loaded, setLoaded] = useState(true);
  const [video, setVideo] = useState<any>(null);
  const [imageUrl, setImageUrl] = useState("")
  const [selectedGroupIndex, setSelectedGroupIndex] = useState<null|number>(null);
  const [selectedScreenIndexInGroup, setSelectedScreenIndexInGroup] = useState<null|number>(null);
  
  useEffect(() => {
    if (!props.webviewReady) return;
    (window as any).pywebview.api.onEvent({
      message: "StartVideoServer", params: {}});
  }, []);

  useEffect(() => {
    if (props.videoServerReady) 
      updatePathInfo("");
  }, [props.videoServerReady]);

  useEffect(() => {
    if (props.screenGroupInfo?.length > 0) {
      props.setProp("ihGameMessage", null);
    }
    setSelectedGroupIndex(0);
  }, [props.screenGroupInfo]);

  const updatePathInfo = (path: string) => {
    (window as any).pywebview.api.onEvent({
      message: "GetVideoPathInfo", params: {videoPath: path}});
  }

  const selectFileItem = (item: any) => {
    if (!item.isFile) {
      updatePathInfo(item.path);
    } else {
      (window as any).pywebview.api.onEvent({
        message: "GetScreenFromVideo", 
        params: {
          path: item.path,
          regenerate: false
        }});
    }
  }

  return(
    <div style={{height: "90vh", padding: "10px"}}>
      {!props.webviewReady ?
      <div
        style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        ต้องใช้โปรแกรม ismorplus สำหรับฟีเจอร์นี้
      </div>
      :
      props.ihGameMessage ?
      <div 
        style={{
          height: "100%", width: "100%", 
          display: "flex", justifyContent: "center", alignItems: "center"
        }}>
        {props.ihGameMessage}
      </div>
      :
      props.screenGroupInfo?.length > 0 ?
      <div>
        <div
          style={{width: "100%", borderBottom: "solid #cccccc 1px"}}
          onClick={(e: any) => props.setProp("screenGroupInfo", [])}>
          &lt;&lt;&nbsp;Choose Another Video
        </div>
        <div style={{display: "flex"}}>
          <div style={{width: "200px", height: "85vh", overflowY: "scroll"}}>
          {(props.screenGroupInfo || []).map((screenList: string[], groupIndex: number) => (
            <div 
              key={groupIndex} 
              style={{
                padding: "10px", borderBottom: "solid #cccccc 1px",
                backgroundColor: groupIndex === selectedGroupIndex ? "#cccccc" : "white"
              }}
              onClick={(e: any) => {
                setSelectedGroupIndex(groupIndex);
                setSelectedScreenIndexInGroup(null);
              }}>
              <img style={{width: "100%"}} src={`${props.videoUrlPrefix}${screenList[0]}`} />
              {`${screenList.length} image(s)`}
            </div>
          ))}
          </div>
          <div style={{ height: "85vh", overflowY: "scroll", flex: 1}}>
            
            {Number.isInteger(selectedGroupIndex) && selectedGroupIndex !== null && (
              Number.isInteger(selectedScreenIndexInGroup) && selectedScreenIndexInGroup !== null ?
                <div>
                  <div style={{display: "flex"}}>
                    <div
                      onClick={(e: any) => setSelectedScreenIndexInGroup(null)}>
                      &lt;&lt;&nbsp;Back to list
                    </div>
                    <div style={{flex: 1}}/>
                    {selectedScreenIndexInGroup > 0 &&
                    <div 
                      style={{marginRight: "10px"}}
                      onClick={(e: any) => {
                        if (selectedScreenIndexInGroup > 0) {
                          setSelectedScreenIndexInGroup(selectedScreenIndexInGroup - 1);
                        }
                      }}>
                      &lt;Previous
                    </div>}
                    {selectedScreenIndexInGroup < props.screenGroupInfo[selectedGroupIndex].length -1 &&
                    <div
                      onClick={(e: any) => {
                        if (selectedScreenIndexInGroup < props.screenGroupInfo[selectedGroupIndex].length -1) {
                          setSelectedScreenIndexInGroup(selectedScreenIndexInGroup + 1);
                        }
                      }}>
                      Next&gt;
                    </div>}
                    <div style={{flex: 1}}/>
                    <div>
                      {`${selectedScreenIndexInGroup + 1} of ${props.screenGroupInfo[selectedGroupIndex].length}`}
                    </div>
                  </div>
                  <div style={{display: "flex"}}>
                    <Input 
                      size="mini" style={{width: "100%"}} placeholder="ใส่ชื่อ step / screen" 
                      onChange={(e: any) => {
                        props.setAddedScreenName(e.target.value);
                      }}
                    />
                    <div style={{flex: 1}}/>
                    <Button size="mini" color="green"
                      onClick={async (e: any) => {
                        let imagePath = `${props.screenGroupInfo[selectedGroupIndex][selectedScreenIndexInGroup]}`
                        let originalUrl = `${props.videoUrlPrefix}` + `${imagePath}`;
                        let url = await new Promise((resolve) => {
                          var img = new Image();
                          img.crossOrigin = "anonymous";
                          img.onload = async () => {
                            const canvas = document.createElement("canvas");
                            canvas.width = img.naturalWidth;
                            canvas.height = img.naturalHeight;
                            const ctx = canvas.getContext("2d");
                            ctx?.drawImage(img, 0, 0);
                            const dataUrl = canvas.toDataURL()
                            resolve(dataUrl);
                          };
                          img.src = originalUrl;
                        });
                        let fileParts = imagePath.split(".");
                        let fileExtension = fileParts[fileParts.length - 1];
                        props.onEvent({message: "UploadIHGameAsset", params: {
                          level: props.levelToAddScreen, 
                          addedScreenName: props.addedScreenName,
                          assetType: "screen",
                          url, 
                          fileExtension
                        }});
                      }}>
                      Upload
                    </Button>
                  </div>
                  <img 
                    style={{width: "100%"}}
                    src={`${props.videoUrlPrefix}${props.screenGroupInfo[selectedGroupIndex][selectedScreenIndexInGroup]}`} 
                  />
                </div>
                :
                <div style={{display: "flex", flexWrap: "wrap"}}>
                  {props.screenGroupInfo[selectedGroupIndex]
                  .map((screenPath: string, screenIndex: number) => (
                    <img 
                      key={screenIndex} style={{width: "25%"}} 
                      src={`${props.videoUrlPrefix}${screenPath}`} 
                      onClick={(e: any) => setSelectedScreenIndexInGroup(screenIndex)}
                    />
                  ))}
                </div>
            )}
          </div>
        </div>
      </div>
      :
      <div>
        <FileExplorer
          videoPathInfo={props.videoPathInfo}
          selectFileItem={selectFileItem}
          addedType={"video"}
        />
      </div>}
    </div>
  )
}
