import React, { useEffect, useState } from "react";
import { Button } from "semantic-ui-react";
import toml from "toml";

const Encounter = (props: any) => {
  const [tomlinput, setTomlinput] = useState("");

  useEffect(() => {
    console.log("Encounter");
  }, [])

  return(
    <div 
      // style={{ height: "95vh" }}
      >
      <Button
        onClick={(e: any) => {
          // const tomlval = toml.parse(tomlinput);
          const tomlval = JSON.parse(tomlinput);
          console.log(tomlval);
        }}
      >
        Parse toml
      </Button>
      <textarea 
        style={{ width: "100%", height: "100%" }}
        value={tomlinput}
        onChange={(e: any) => {
          setTomlinput(e.target.value);
        }}
      />
    </div>
  )
}

export default Encounter
