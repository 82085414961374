import { useEffect, useState } from "react";
import { Button, Icon } from "semantic-ui-react";

export default function NotiIcon(props: any) {
  const [noti, setNoti] = useState<any>({});
  const [show, setShow] = useState(false);
  const [unreadCount, setUnreadCount] = useState<number | null>(null);
  
  useEffect(() => {
    if (Object.keys(props.notifications).length > 0) {
      const notiData = Object.fromEntries(
        Object.entries(props.notifications)
        .filter((entry: [string, any], index: number) => (entry[0] === props.redmineUser))
        .map((entry: [string, any], index: number) => (
        [ 
          entry[0], // User Email
          {
            summary: {
              unreadCount: Object.values(entry[1]).filter((issue: any) => !issue.read).length
            },
            data: Object.fromEntries(Object.entries(entry[1]).filter((issue: any) => !issue.read))
          }
        ]
      )))
      setNoti(notiData);
      setUnreadCount(notiData?.[props.redmineUser]?.summary?.unreadCount)
    }
  }, [props.notifications]);

  return(
    <div 
      style={{position: "relative"}}
      onClick={(e: any) => {
        setShow(!show);
      }}>
      <div 
        style={{
          backgroundColor: "pink", color: "white", padding: "3px 5px 3px 9px", 
          margin: "0 5px 0 5px", cursor: "pointer", borderRadius: "3px", 
        }}>
        <Icon 
          name="bell" 
         
        />
      </div>

      {unreadCount !== null && (unreadCount > 0) &&
      <div 
        style={{
          position: "absolute", top: -5, right: -1, 
          width: "20px", height: "20px", borderRadius: "10px", padding: "1px", fontSize: ".8rem",
          fontWeight: "bold", color: "white", backgroundColor: "red", textAlign: "center"
        }}>
        {unreadCount}
      </div>}

      {show &&
      <div 
        style={{
          position: "absolute", top: 30, right: 0, backgroundColor: "white", zIndex: 1000,
          padding: "5px", height: "60vh", width: "400px", overflow: "auto", border: "solid #cccccc 1px"
        }}>
        {Object.entries(noti).map((entry: [string, any], entryIndex: number) => (
          <div key={entryIndex}>
            <div style={{display: "flex", alignItems: "center", borderBottom: "solid #dddddd 1px"}}>
              <span style={{marginRight: "10px"}}>{entry[0]}</span>
              <div style={{flex: 1}}></div>
              <Button size="mini" color="brown"
                onClick={(e: any) => {
                  props.onEvent({message: "ClearNotifications", 
                    params: {notiId: entry[0], notiData: props.notifications[entry[0]]}})
                }}>
                Clear All
              </Button>
            </div>
            {Object.values(entry[1]?.data || {})
            .filter((issue: any) => !issue.read)
            .map((issue: any, issueIndex: number) => 
              (<div key={issueIndex}>
                <div style={{fontWeight: "bold"}}>{issue.subject || "[No Subject]"}</div>
                <div style={{paddingLeft: "20px"}}>
                  {Object.values(issue.details)
                  .filter((detail: any) => !detail.read)
                  .sort((a: any, b: any) => a.created_on < b.created_on ? -1 : 1)
                  .map((detail:any, detailIndex: number) => (
                    <div 
                      key={detailIndex} 
                      style={{
                        display: "flex", justifyContent: "space-between", width: "100%",
                        borderBottom: "solid #cccccc 1px"
                      }}>
                      <div style={{flex: 2}}>{detail.created_on}</div>
                      <div style={{flex: 2}}>{detail.username}</div>
                      <div style={{flex: 2}}>{detail.detail_id}</div>
                      <div style={{flex: 2}}>{detail.issue_id}</div>
                      <div style={{flex: 1}}>{detail.type}</div>
                      <div style={{flex: 1}}>{detail.prop_key}</div>
                      <div style={{flex: 1}}>{detail.value || "N/A"}</div>
                    </div>
                  ))}
                </div>
              </div>))
            }
          </div>
        ))}
      </div>
      }
    </div>
  )
}
