import { useEffect, useState } from 'react';
import PlayView from './PlayView';
import FlowView from './FlowView';
import { analyzeLessonData } from './Algorithm';

export default function LN(props:any) {
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("Play");
  const [videoGroup, setVideoGroup] = useState<number[][]>([]);
  const [urlList, setUrlList] = useState<any[]>([]);

  useEffect(() => {
    props.onEvent({message: "LNGetList", params: {}});
  }, []);

  useEffect(() => {
    if (props.preferences?.lessonId) {
      props.setProp("lessonId", props.preferences?.lessonId);
    }
  }, [props.preferences]);

  useEffect(() => {
    if (props.lessonId && !props.lessonData) {
      setLoading(true);
      props.onEvent({
        message: "LNGetLessonData", params: { id: props.lessonId, setLoading }});
    }
  }, [props.lessonId]);

  useEffect(() => {
    if (props.lessonData) {
      let genUrlList = async () => {
        let urlList_ = await Promise.all(
          (props.lessonData?.items || []).map(
            (item: any, itemIndex: number) => (
            props.toDownloadUrl(
              `gs://ismor-lesson/${props.lessonId}/thumb/${itemIndex}`)
            )
          )
        );
        setUrlList(urlList_);
      }
      genUrlList();
      
      const result = analyzeLessonData(props.lessonData);
      setVideoGroup(result);
    }
  }, [props.lessonData]);

  return(
    <div style={{height: "95vh"}}>
      <div style={{display: "flex", borderBottom: "solid grey 1px", justifyContent: "space-between"}}>
        {props.lessonList &&
        <select
          value={props.lessonId}
          onChange={(e: any) => {
            setLoading(true);
            props.onEvent({
              message: "LNGetLessonData", params: { id: e.target.value, setLoading }});
          }}>
          {([{name: "[Select Lesson]"}])
          .concat(props.lessonList || [])
          .map((lesson: any, index: number) => (
            <option key={index} value={lesson?.id}>
              {lesson?.name}
            </option>
          ))}
        </select>}
        <div style={{display: "flex"}}>
          <MenuItem name="Play" mode={mode} setMode={setMode} />
          <MenuItem name="Flow" mode={mode} setMode={setMode} />
        </div>        
      </div>
      {loading ? 
      <div>Loading</div>
      
      : !props?.lessonId ?
      <div>Please select Lesson</div>

      : !props?.lessonData ? 
      <div>No data</div>
      
      : mode === "Play" ?
      <PlayView
        onEvent={props.onEvent}
        lessonId={props.lessonId}
        lessonData={props.lessonData}
        toDownloadUrl={props.toDownloadUrl}
        videoGroup={videoGroup}
        urlList={urlList}
      />
      
      : mode === "Flow" ?
      <FlowView 
        onEvent={props.onEvent}
        lessonId={props.lessonId}
        lessonData={props.lessonData}
        toDownloadUrl={props.toDownloadUrl}
        videoGroup={videoGroup}
        urlList={urlList}
      />
      
      : <></>}
    </div>
  )
}

function MenuItem(props: any) {
  return(
    <div
      style={{
        padding: "0 5px 0 5px", cursor: "pointer",
        backgroundColor: props.name === props.mode ? "lightblue" : "white"
      }}
      onClick={(e: any) => props.setMode(props.name)}>
      {props.name}
    </div>
  )
}
