import React, { useState, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, Dropdown, Form } from "semantic-ui-react";
import { CardLayout, RightLabel } from "../common";
import ReactTable from "react-table-6";

const CardPatientSearchCU = ({
  hnPreData,
  getPatientList,
  getNationality,
  hideCallBack,
  onSelectPatient,
  nationalitiesList,
  patientList,
  onOpenModPatientOldName,
  numberOfPage,
  currentPage,
  onPaginationChange,
  patientListLoading,
  isAPILoading,
  hideColumnsByAccessor,
  inputChangeAutoSearch,
  systemHn8Digit,
  formatHn8Digit,
}: any) => {
  const [hn, setHn] = useState("");
  const [fullName, setFullName] = useState("");
  const [citizenId, setCitizenId] = useState("");
  const [nationality, setNationality] = useState<any>(null);
  const [startAge, setStartAge] = useState("");
  const [endAge, setEndAge] = useState("");
  const [birthInfo, setBirthInfo] = useState("dmy");
  const [birthDate, setBirthDate] = useState("");
  const [noEncounter, setNoEncounter] = useState(false);
  const [mergeFile, setMergeFile] = useState(false);
  const [isEmergency, setIsEmergency] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [patientResult, setPatientResult] = useState([]);

  const [clickTimeout, setClickTimeout] = useState<any>(null); // to distinguish between single click and double click
  const [patientSelected, setPatientSelected] = useState<any>(null);

  const styles = {
    mainTable: { height: 420, backgroundColor: "#FFFFFF", overflowX: "auto" },
  };

  const currentChange = useRef<number>(0);
  const isMounted = useRef(false);

  const handleOnClear = () => {
    setHn("");
    setFullName("");
    setCitizenId("");
    setNationality(null);
    setPatientResult([]);
  };

  const handleOnSearch = async (hnparam = "") => {
    // hnparam อาจเป็นชื่อเข้ามาด้วย 

    let hnData = hnparam || hn 

    if (systemHn8Digit) {
      if (/^\d+$/.test(hnData?.trim())) {
        hnData = formatHn8Digit(hnData);
      }
    }

    if (typeof hnData !== "string") {
      return;
    }

    let [birthDateFormat, showADBirthDate] = birthDateFormatAD(birthDate);

    let hnSearch = hnData;
    let fullNameSearch = fullName;
    
    if (!/\d/.test(hnData) && hnparam) {
      fullNameSearch = hnparam
      hnSearch = hn
    }

    await getPatientList({
      hn: hnSearch,
      fullName: fullNameSearch,
      citizenId: citizenId,
      nationality: nationality,
      startAge: startAge,
      endAge: endAge,
      noEncounter: noEncounter,
      mergeFile: mergeFile,
      includeCancel: true,
      isEmergency: isEmergency,
      birthdate: birthDateFormat,
      showADBirthDate: showADBirthDate,
      patientCurrentPage: 1,
    });

    setIsLoading(false);
  };

  const birthDateFormatAD = (birthRecord = "") => {
    let isADYear = true
    if (!birthRecord) {
      return [birthRecord, false];
    }
    let birthSubString = birthRecord.split("/");
    if ((birthInfo === "dmy" && birthSubString.length < 3) || (birthInfo === "my" && birthSubString.length < 2)) {
      return [birthRecord, false];
    }

    let year = birthInfo === "dmy" ? birthSubString[2] : birthInfo === "my" ? birthSubString[1] : birthSubString[0];
    let yearAD = +year;
    if (+year > 2400) {
      yearAD = yearAD - 543;
      isADYear = false
    }

    if (birthInfo === "dmy") {
      return [`${yearAD}-${birthSubString[1]}-${birthSubString[0]}`, isADYear];
    } else if (birthInfo === "my") {
      return [`${yearAD}-${birthSubString[0]}`, isADYear];
    } else {
      return [yearAD, isADYear];
    }
  };

  useEffect(() => {
    getNationality();
  }, []);

  useEffect(() => {
    setPatientResult(patientList);
  }, [patientList]);

  useEffect(() => {
    isMounted.current = true;

    if (/\d/.test(hnPreData)) {
      setHn(hnPreData);
    } else {
      setFullName(hnPreData)
    }

    if (hnPreData) {
      handleOnSearch(hnPreData);
    }
  }, [hnPreData]);

  useEffect(() => {
    if (isMounted.current && inputChangeAutoSearch && fullName.length >= 3) {
      currentChange.current += 1;
      handleAutoSearch();
    }
  }, [fullName]);

  useEffect(() => {
    const inputJumpToPage = document.querySelector(".-pageJump input") as HTMLElement;
    const handleChaneJumpToPage = (e: any) => {
      handlePageChange(e.target.valueAsNumber - 1);
    };
    if (inputJumpToPage) {
      inputJumpToPage.addEventListener("change", handleChaneJumpToPage);
    }
    return () => {
      if (inputJumpToPage) {
        inputJumpToPage.removeEventListener("change", handleChaneJumpToPage);
      }
    };
  }, []);

  const handleAutoSearch = async (hn = "") => {
    const promise = (number: any) => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(number);
        }, 500);
      });
    };
    promise(currentChange.current).then((res) => {
      if (currentChange.current === res) {
        handleOnSearch();
      }
    });
  };

  const handleOnChangeHN = (e: any) => {
    setHn(e.target.value);
  };

  const handleOnChangeFullName = (e: any, data: any) => {
    if (patientListLoading) {
      return;
    }
    setFullName(data.value);
  };

  const handleOnChangeCitizenID = (e: any) => {
    setCitizenId(e.target.value);
  };

  const handleOnChangeNation = (e: any, { value }: any) => {
    setNationality(value);
  };

  const handleOnChangeBirthType = (e: any, { value }: any) => {
    setBirthInfo(value);
  };

  const handleOnChangeBirthDate = (e: any) => {
    setBirthDate(e.target.value);
  };

  const handleChangeStartAge = (e: any) => {
    setStartAge(e.target.value);
  };

  const handleChangeEndAge = (e: any) => {
    setEndAge(e.target.value);
  };

  const handleCheckNoEncounter = (e: any, data: any) => {
    setNoEncounter(data.checked);
  };

  const handleCheckMergeFile = (e: any, data: any) => {
    setMergeFile(data.checked);
  };

  const handleCheckIsEmergency = (e: any, data: any) => {
    setIsEmergency(data.checked);
  };

  const handleOnSelectPatient = () => {
    if (patientSelected) {
      onSelectPatient(patientSelected.original.id, patientSelected.original.hn, patientSelected.original.full_name);
      // hideCallBack();
    }
  };

  const handlePageChange = async (page: any) => {
    setIsLoading(true);
    await onPaginationChange({
      hn: hn,
      fullName: fullName,
      citizenId: citizenId,
      nationality: nationality,
      patientCurrentPage: page + 1,
    });
    console.log("handlePageChange False");
    setIsLoading(false);
  };

  const handleOnKeyPressHN = async (ev: any) => {
    let hn = ev.target.value.toUpperCase();
    if (ev.key === "Enter") {
      if (inputChangeAutoSearch) {
        handleOnSearch(hn);
      }
    }
  };

  const allGridColumns = useMemo(
    () => [
      {
        Header: "HN",
        minWidth: 100,
        Cell: (data: any) => {
          return <div style={{ color: !!data.original.canceled ? "red" : "black" }}>{data.original.hn}</div>;
        },
      },
      {
        Header: "ID / Passport",
        minWidth: 140,
        Cell: (data: any) => {
          return <div style={{ color: !!data.original.canceled ? "red" : "black" }}>{data.original.citizen_passport}</div>;
        },
      },
      {
        Header: "ชื่อ นามสกุล (ไทย)",
        minWidth: 170,
        Cell: (data: any) => {
          return <div style={{ color: !!data.original.canceled ? "red" : "black" }}>{data.original.full_name_th}</div>;
        },
      },
      {
        Header: "ชื่อ นามสกุล (อังกฤษ)",
        minWidth: 170,
        Cell: (data: any) => {
          return <div style={{ color: !!data.original.canceled ? "red" : "black" }}>{data.original.full_name_en}</div>;
        },
      },
      {
        Header: "วันเดือนปีเกิด",
        minWidth: 100,
        Cell: (data: any) => {
          return <div style={{ color: !!data.original.canceled ? "red" : "black" }}>{data.original.birthdate}</div>;
        },
      },
      {
        Header: "ชื่อมารดา",
        minWidth: 170,
        Cell: (data: any) => {
          return <div style={{ color: !!data.original.canceled ? "red" : "black" }}>{data.original.mother}</div>;
        },
      },
      {
        Header: "สถานะ",
        minWidth: 150,
        Cell: (data: any) => {
          return <div style={{ color: !!data.original.canceled ? "red" : "black" }}>{data.original.canceled_reason}</div>;
        },
      },
      {
        Header: "ชื่อเดิม",
        style: { whiteSpace: "unset", textAlign: "center" },
        minWidth: 80,
        Cell: (data: any) => {
          if (data.original.old_name) {
            return <Button color="blue" icon="search" onClick={() => onOpenModPatientOldName(data.original.id, data.original.hn, data.original.full_name)} />;
          }
          return "";
        },
      },
    ],
    []
  );

  const gridColumns = useMemo(() => {
    const columns = [...allGridColumns];
    return columns.filter((col: any) => !(hideColumnsByAccessor || [])?.includes(col.accessor));
  }, []);

  // console.log(gridColumns);

  return (
    <CardLayout titleText="ค้นหาผู้ป่วย" onClose={hideCallBack} toggleable={false} loading={patientListLoading}>
      <Form>
        <Form.Group inline>
          <Form.Field width={2}>
            <RightLabel>HN</RightLabel>
          </Form.Field>
          <Form.Input width={4} onChange={handleOnChangeHN} value={hn} onKeyPress={handleOnKeyPressHN} />
          <Form.Field width={4}>
            <RightLabel>ชื่อ นามสกุล (ห้ามใส่คำนำหน้า)</RightLabel>
          </Form.Field>
          <Form.Input width={6} onChange={handleOnChangeFullName} value={fullName} />
        </Form.Group>

        <Form.Group inline>
          <Form.Field width={2}>
            <RightLabel>ID/Passport</RightLabel>
          </Form.Field>
          <Form.Input width={2} onChange={handleOnChangeCitizenID} value={citizenId} />
          <Form.Field width={1}>
            <RightLabel>สัญชาติ</RightLabel>
          </Form.Field>
          <Form.Field width={2}>
            <Dropdown
              className="fluidDropdown"
              search
              selection
              clearable
              placeholder="ทุกสัญชาติ"
              options={nationalitiesList}
              value={nationality}
              onChange={handleOnChangeNation}
            />
          </Form.Field>
          <Form.Field width={2}>
            <RightLabel>ระบุช่วงอายุ</RightLabel>
          </Form.Field>
          <Form.Field width={3} style={{ paddingRight: "0px" }}>
            <Form style={{ width: "100%" }}>
              <Form.Group inline>
                <Form.Input width={7} onChange={handleChangeStartAge} value={startAge} />
                <Form.Field width={2}>
                  <b>-</b>
                </Form.Field>
                <Form.Input width={7} onChange={handleChangeEndAge} value={endAge} />
              </Form.Group>
            </Form>
          </Form.Field>
          <Form.Field width={2}>
            <RightLabel>
              <Dropdown
                // className="fluidDropdown"
                // search
                // selection
                // clearable
                // placeholder="ทุกสัญชาติ"
                options={[
                  { key: "1b", text: "วันเดือนปีเกิด", value: "dmy" },
                  { key: "2b", text: "เดือนปีเกิด", value: "my" },
                  { key: "3b", text: "ปีเกิด", value: "y" },
                ]}
                value={birthInfo}
                onChange={handleOnChangeBirthType}
              />
            </RightLabel>
          </Form.Field>
          <Form.Input
            placeholder={birthInfo === "dmy" ? "DD/MM/YYYY" : birthInfo === "my" ? "MM/YYYY" : "YYYY"}
            width={2}
            onChange={handleOnChangeBirthDate}
            value={birthDate}
          />
        </Form.Group>

        <Form.Group inline>
          <Form.Field width={2}></Form.Field>
          <Form.Field width={3}>
            <Checkbox label="เฉพาะผู้ป่วยใหม่" checked={noEncounter} onChange={handleCheckNoEncounter} />
          </Form.Field>
          <Form.Field width={3}>
            <Checkbox label="ผู้ป่วยที่มีการโอนแฟ้ม" checked={mergeFile} onChange={handleCheckMergeFile} />
          </Form.Field>
          <Form.Field width={3}>
            <Checkbox label="ผู้ป่วยฉุกเฉิน" checked={isEmergency} onChange={handleCheckIsEmergency} />
          </Form.Field>
          <Form.Field width={1}></Form.Field>
          <Form.Button width={2} fluid color="blue" onClick={() => handleOnSearch()}>
            ค้นหา
          </Form.Button>
          <Form.Button width={2} fluid color="teal" onClick={handleOnClear}>
            Clear
          </Form.Button>
        </Form.Group>
      </Form>

      <ReactTable
        style={styles.mainTable}
        manual
        data={patientResult}
        pageSize={patientList ? (patientList.length < 10 ? 10 : patientList.length) : 10}
        showPageSizeOptions={false}
        page={currentPage - 1}
        pages={numberOfPage}
        onPageChange={handlePageChange}
        loading={isLoading || isAPILoading}
        getNoDataProps={() => {
          return { style: { display: "none" } };
        }}
        getTrProps={(state: any, rowInfo: any) => {
          if (rowInfo && rowInfo.row) {
            let background = rowInfo.index === (patientSelected ? patientSelected.index : null) ? "#CCE2FF" : "white";
            return {
              onClick: (e: any) => {
                setPatientSelected(rowInfo);
                if (clickTimeout !== null) {
                  if ((patientSelected ? patientSelected.index : null) === rowInfo.index) {
                    clearTimeout(clickTimeout);
                    setClickTimeout(null);
                    onSelectPatient(rowInfo.original.id, rowInfo.original.hn, rowInfo.original.full_name);
                    // hideCallBack();
                  }
                } else {
                  setClickTimeout(
                    setTimeout(() => {
                      clearTimeout(clickTimeout);
                      setClickTimeout(null);
                    }, 500)
                  );
                }
              },
              style: {
                background: background,
              },
            };
          } else {
            return {};
          }
        }}
        columns={gridColumns}
      ></ReactTable>
      <br />
      <Button color="blue" onClick={handleOnSelectPatient}>
        เลือก
      </Button>
    </CardLayout>
  );
};

CardPatientSearchCU.defaultProps = {
  hnPreData: "",
  patientList: [],
  nationalitiesList: [],
  getPatientList: () => {},
  getNationality: () => {},
  hideCallBack: () => {},
  onSelectPatient: () => {},
  onOpenModPatientOldName: () => {},
  onPaginationChange: () => {},
  currentPage: 1,
  hideColumnsByAccessor: [],
  inputChangeAutoSearch: false,
  systemHn8Digit: false,
  formatHn8Digit: () => {},
};

CardPatientSearchCU.propTypes = {
  hnPreData: PropTypes.string,
  patientList: PropTypes.array,
  nationalitiesList: PropTypes.array,
  getPatientList: PropTypes.func,
  getNationality: PropTypes.func,
  hideCallBack: PropTypes.func,
  onSelectPatient: PropTypes.func,
  onOpenModPatientOldName: PropTypes.func,
  onPaginationChange: PropTypes.func,
  currentPage: PropTypes.number,
  hideColumnsByAccessor: PropTypes.array,
  inputChangeAutoSearch: PropTypes.bool,
  systemHn8Digit: PropTypes.bool,
  formatHn8Digit: PropTypes.func,
};

export default React.memo(CardPatientSearchCU);
