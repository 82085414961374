import { useEffect, useRef, useState } from "react";
import { Button, Icon, Input, Modal } from "semantic-ui-react";

export default function PlayView(props: any) {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [uploadModal, setUploadModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [newUpload, setNewUpload] = useState(false);
  const [newVideoName, setNewVideoName] = useState("");
  const [pctComplete, setPctComplete] = useState("");
  const [originalUrl, setOriginalUrl] = useState<string | null>(null);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [videoExt, setVideoExt] = useState<string | null>(null);
  const [thumbUrl, setThumbUrl] = useState<string | null>(null);
  const fileRef = useRef<HTMLInputElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (uploadModal) return;
    try {
      (async () => {
        try {
          let url = await  props.toDownloadUrl(
            `gs://ismor-lesson/${props.lessonId}/${selectedIndex}`);
          setOriginalUrl(url);
        } catch (e: any) { console.log(e) }
      })();
    } catch (e: any) { console.log(e) };
  }, [selectedIndex, uploadModal]);

  useEffect(() => {
    setSelectedItem(props.lessonData?.items?.[selectedIndex]);
  }, [selectedIndex]);

  const processVideoFile = async (file: File) => {
    const arrayBuffer = await new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
    if (arrayBuffer) {
      let url = URL.createObjectURL(new Blob([arrayBuffer as ArrayBuffer]));
      setVideoUrl(url);
      let parts = file.name.split(".");
      setVideoExt(parts[parts.length - 1]);
    }
  }

  const cancelUpload = () => {
    setUploadModal(false);
    setUploading(false);
    setNewUpload(false);
    setVideoUrl(null);
    setVideoExt(null);
  }

  return(
    <div style={{display: "flex", height: "92vh"}}>
      <div 
        style={{ 
          width: "15vw", height: "100%", display: "flex", flexDirection: "column", 
          alignItems: "center", borderRight: "solid #cccccc 1px", 
        }}>
        {props.videoGroup.map((group: number[], groupIndex: number) => (
          <div style={{borderBottom: "solid grey 1px"}} key={groupIndex}>
            {group.map((videoId: number, videoIndex: number) => { 
              let items = props.lessonData?.items || [];
              let label = `${items?.[videoId]?.name}`;
              let next = items?.[videoId]?.next || [];
              if (next.length > 0) {
                label += " -> "
                for (var i = 0; i < next.length; i++) {
                  label += items?.[next[i]]?.name;
                  if (i < next.length - 1) {
                    label += ", "
                  }
                }
              }
              return(
                <div 
                  key={videoIndex} 
                  style={{
                    width: "200px", padding: "2px",
                    border: videoId === selectedIndex ? "solid red 1px" :"none"
                  }}
                  onClick={(e: any) => {
                    setSelectedIndex(videoId);
                  }}>
                  <img   
                    style={{width: "100%", height: "auto"}}
                    src={props.urlList?.[videoId]} 
                  />
                  <div>{label}</div>
                </div>
              )
            })}
          </div>
        ))}
      </div>
      <div style={{ width: "85vw"}}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>{selectedItem?.name}</div>
          <div>
          <Icon 
            name="upload" color="blue"
            onClick={(e: any) => {
              setNewUpload(false);
              fileRef?.current?.click();
            }}
          />
          <Icon 
            name="add" color="blue"
            onClick={(e: any) => {
              setNewUpload(true);
              fileRef?.current?.click();
            }}
          />
          </div>
        </div>
        <input 
          ref={fileRef}
          style={{display: "none"}}
          type="file"
          onClick={(e: any) => e.target.value = null}
          onChange={async (e: any) => {
            if (e.target?.files?.[0]) {
              setUploadModal(true);
              setPctComplete("");
              processVideoFile(e.target.files[0]);
            }
          }}
        />
        {originalUrl &&
        <video 
          controls={true} 
          style={{width: "100%", maxHeight: "100%"}} 
          src={originalUrl} 
        />}
        <Modal 
          open={uploadModal}
          closeOnDimmerClick={true}
          closeIcon={true}
          onClose={(e: any) => cancelUpload()}
          >
          {!uploading ?
          <>
            {newUpload &&
            <Input 
              value={newVideoName}
              onChange={(e: any) => {
                setNewVideoName(e.target.value);
              }}
            />}            
            <Button
              size="mini"
              color="green"
              onClick={(e: any) => {
                setUploading(true);
                props.onEvent({message: "LNUploadVideo", 
                  params: { 
                    id: props.lessonId, 
                    index: selectedIndex, newUpload, newVideoName,
                    videoUrl, videoExt, thumbUrl,
                    setUploadModal, setUploading, setPctComplete
                  }}
                );
              }}>
              Upload
            </Button>
            <Button size="mini" color="red" onClick={(e: any) => cancelUpload()}>
              Cancel
            </Button>
            {videoUrl &&
            <div>
              <video 
                ref={(el: HTMLVideoElement) => {
                  if (el !== null) { 
                    el.addEventListener('loadedmetadata', () => {
                      if (canvasRef.current) {                        
                        el.currentTime = 0;
                        canvasRef.current.width = el.clientWidth;
                        canvasRef.current.height = el.clientHeight; 
                      }
                    });
                    el.addEventListener('seeked', () => {
                      if (canvasRef.current) {
                        const ctx = canvasRef.current.getContext("2d");
                        ctx?.drawImage(el, 0, 0, el.clientWidth, el.clientHeight);
                        let url = canvasRef.current.toDataURL('image/png');
                        setThumbUrl(url);
                      }
                    });
                  }
                }}
                controls={true}
                style={{width: "100%", maxHeight: "100%"}}
                src={videoUrl} 
              />
              <canvas ref={canvasRef} style={{display: "none"}}></canvas>
            </div>}
          </>
          :
          <div 
            style={{
              minHeight: "20vh", minWidth: "20vw", display: "flex", 
              justifyContent: "center", alignItems: "center"
            }}>
            {pctComplete}
          </div>}
        </Modal>
      </div>
    </div>
  )
}
