import React, { useEffect, useState } from 'react';
import { Dropdown, Form, Radio, Button, Checkbox, Icon } from 'semantic-ui-react';
import { CreateUIv2Event } from './CreateUIv2';
import { ScreenData } from './CreateUIv2Jsx';
import UxDef, { Ux, UxProp } from './UxDef';
import { DivGridEditor } from './DivGrid';

export type ElementEditorState = {
    screenData?: ScreenData
    editedElementId?: number | null,
}

export type ElementEditorEvent =
        {   message: "SetEditedElementName", params: { name: string } }
    |   {   message: "ChangeElementProp", 
            params: 
                {
                    id: number,
                    key: string,
                    type: string,
                    value: string,
                    valueEN?: string | null,
                }
        }

const ElementEditor = (props: 
        ElementEditorState 
    &   { 
            onEvent: (e: CreateUIv2Event) => void,
            setProp: (keys: string, value: any) => void,
            openEditor: boolean,
            openGridCanvas: boolean,
            mode: string,
            divGridCells: any[],
        }
    ) => {

    const editedElement = props.screenData?.elements?.filter(
        (elem: any) => elem.id === props.editedElementId
    )?.[0]
    const editedElementProps = editedElement?.props 
    const keys = Object.keys(editedElementProps || [])
    const [propKey, setPropKey] = useState("")
    const [propType, setPropType] = useState("value")
    const [propValue, setPropValue] = useState("")
    const [propValueEN, setPropValueEN] = useState<string|null>(null)

    useEffect(() => {
        setPropKey("")
        setPropValue("")
    }, [props.editedElementId])

    const handlePropChange = (name: string, key: string, propType: string, 
                              value: string, valueEN: string | null) => {
        if (name && name !== "" && key && key !== "") {
            console.log(UxDef[name].propDef[key].choices)
            console.log(value)
            if (propType === "code" || !UxDef[name].propDef[key].choices) {
                // If code no no choices, execute right away
                props.onEvent({
                    message: "ChangeElementProp",
                    params: {
                        id: props.editedElementId!,
                        key: key,
                        type: propType,
                        value: value,
                        valueEN: valueEN,
                    }
                })
            } else if (UxDef[name].propDef[key]?.choices?.includes(value)) {
                console.log("match", key, value)
                props.onEvent({
                    message: "ChangeElementProp",
                    params: {
                        id: props.editedElementId!,
                        key: key,
                        type: propType,
                        value: value,
                        valueEN: valueEN,
                    }
                })
            } else {
                console.log("not match", key, value)
            }
            setPropKey(key)
            setPropType(propType)
            setPropValue(value)
            setPropValueEN(valueEN)
        }
    }
    
    return(
        props.openEditor ?
        <div style={{ position: "fixed", right: "10px", top: "70px", padding: "5px",
                        border: "solid blue 1px", width: "400px", maxHeight: "80vh",
                        overflowY: "scroll",
                        zIndex: 10002, backgroundColor: "white"}}>
            <Dropdown
                selection
                fluid
                disabled={true}
                options={Object.keys(UxDef).map(name => (
                    { name: name, text: name, value: name }
                ))}
                value={ editedElement?.label || editedElement?.name || "" }
                onChange={(e, v) => {
                    props.onEvent({
                        message: "SetEditedElementName",
                        params: { name: v.value?.toString() || '' }
                    })
                }}
            />
            {editedElement && !editedElement?.label && editedElement?.name === "div" &&
            <div style={{padding: "5px", display: "flex"}}>
                <div style={{ marginRight: "5px" }}>From image</div>
                <input 
                    type="file" 
                    onClick={(e: any) => { e.target.value = null }}
                    onChange={(event) => {
                        props.onEvent({ 
                            message: "ImportPicture", 
                            params: { 
                                file: event?.target?.files?.[0]!,
                                editedElementId: props.editedElementId || null
                            }
                        })
                    }}
                />
            </div>
            }
            {editedElement?.label && editedElement?.label === "divgrid" ?
            <DivGridEditor 
                setProp={props.setProp} 
                openGridCanvas={props.openGridCanvas} 
                mode={props.mode}
                editedElement={editedElement}
                onEvent={props.onEvent}
                divGridCells={props.divGridCells}
            />
            :
            <Form>
                {editedElement && Object.keys(UxDef[editedElement.name].propDef)
                    .map((key: string, index: number) => (
                <div 
                    key={index} 
                    style={{ 
                        backgroundColor: key === propKey ? '#cccccc' : 'white',
                        padding: "5px",
                    }}>
                    <div style={{ display: "flex" }}>
                        <b style={{ padding: 0, marginBottom: 0 }}>{key}</b>
                        <Radio 
                            label="value" 
                            style={{ marginLeft: "10px" }} 
                            checked={key === propKey ? propType === 'value' 
                                        : editedElementProps?.[key]?.type === 'value'}
                            onClick={(e) => { 
                                setPropKey(key)
                                handlePropChange(
                                    editedElement?.name || "", 
                                    key, 
                                    'value',
                                    editedElementProps?.[key]?.value || "",
                                    editedElementProps?.[key]?.valueEN || null)
                                // setPropValue(editedElementProps?.[key]?.value)
                                // setPropType('value')
                            }}
                        />
                        <Radio 
                            label="code" 
                            style={{ marginLeft: "10px" }} 
                            checked={key === propKey ? propType === 'code' 
                                        : editedElementProps?.[key]?.type === 'code'}
                            onClick={(e) => { 
                                setPropKey(key)
                                handlePropChange(
                                    editedElement?.name || "", 
                                    key, 
                                    'code',
                                    editedElementProps?.[key]?.value || "",
                                    editedElementProps?.[key]?.valueEN || null)
                                // setPropValue(editedElementProps?.[key]?.value)
                                // setPropType('code')
                            }}
                        />
                        {!(typeof editedElementProps?.[key]?.valueEN != "undefined" 
                           && editedElementProps?.[key]?.valueEN !== null) &&
                        <Checkbox
                            style={{ marginLeft: "10px" }}
                            label="EN"
                            onClick={(e: any) => {
                                handlePropChange(
                                    editedElement?.name || "", 
                                    key, 
                                    propType,
                                    editedElementProps?.[key]?.value || "",
                                    "")
                            }}
                        />}
                    </div>
                    <input 
                        style={{ padding: 0 }} 
                        readOnly={key !== propKey}
                        value={key === propKey ? propValue: editedElementProps?.[key]?.value || ""}
                        onClick={(e) => { 
                            console.log(key)
                            setPropKey(key)
                            setPropType(editedElementProps?.[key]?.type || "value")
                            setPropValue(editedElementProps?.[key]?.value || "")
                            setPropValueEN(editedElementProps?.[key]?.valueEN || null)
                        }}
                        onChange={(e: any) => { 
                            handlePropChange(
                                editedElement?.name || "", 
                                key, 
                                propType,
                                e.target.value,
                                editedElementProps?.[key]?.valueEN || null) 
                        }}
                    />
                    {typeof editedElementProps?.[key]?.valueEN != "undefined" 
                     && editedElementProps?.[key]?.valueEN !== null && propType !== "code" &&
                    <div style={{display: "flex"}}>
                        <input 
                            placeholder='ภาษาอังกฤษ'
                            style={{ padding: 0 }} 
                            readOnly={key !== propKey}
                            value={key === propKey ? propValueEN || "": editedElementProps?.[key]?.valueEN || ""}
                            onClick={(e) => { 
                                console.log(key)
                                setPropKey(key)
                                setPropType(editedElementProps?.[key]?.type || "value")
                                setPropValue(editedElementProps?.[key]?.value || "")
                                setPropValueEN(editedElementProps?.[key]?.valueEN || null)
                            }}
                            onChange={(e: any) => { 
                                handlePropChange(
                                    editedElement?.name || "", 
                                    key, 
                                    propType,
                                    editedElementProps?.[key]?.value || "",
                                    e.target.value) 
                            }}
                        />
                        <Icon
                            name="delete"
                            onClick={(e: any) => {
                                handlePropChange(
                                    editedElement?.name || "", 
                                    key, 
                                    propType,
                                    editedElementProps?.[key]?.value || "",
                                    null)
                            }}
                        />
                    </div>}
                </div>
                ))}
            </Form>}   
            
        </div>:

        <></>
    )
}

export default ElementEditor
