import { AsyncDuckDBConnection } from "@duckdb/duckdb-wasm";

export const query = async (sql: string, con: AsyncDuckDBConnection | null) => {
  if (!con) return [];
  let res = await con.query(sql);
  let output = (res?.toArray() || []).map((item: any) => item.toJSON());
  return output
}

export const create_data_table = (result: any[], columns: string[]) => {
  let data: any = Object.fromEntries(
    columns.map((column: string) => ([
      column,
      result.map((item: any) => item[column])
    ]))
  );
  let Bokeh = (window as any).Bokeh;
  let source = new Bokeh.ColumnDataSource({data});
  let TableColumn = Bokeh.Tables.TableColumn
  let table = new Bokeh.Tables.DataTable({
    source: source, 
    columns: columns.map((column: string) => (
      new TableColumn({field: column, title: column})
    )),
    width: 900
  });
  return table;
}
