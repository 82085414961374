import UxDef, { Ux } from './UxDef'
import { Element } from './CreateUIv2Jsx'

export type Tx = { 
  name: string,
  tree: any, 
}

type NodeTree = {
  type: Ux,
  props: any,
  children: NodeTree[]
}

export const collectNodeTree = (node: Element, elements: Element[]) => {
  const nodeTree: NodeTree = {
      type: UxDef[node.name],
      props: node.props,
      children: []
  }
  const children = elements.filter((item: any) => item.parent === node.id)
    .sort((a: any, b: any) => (a.seq < b.seq ? -1: 1));
  for (const child of children) {
      const childTree = collectNodeTree(child, elements);
      nodeTree.children.push(childTree);
  }
  console.log(JSON.stringify(nodeTree));
  return nodeTree
}

export const m = (type: Ux, props: any, children: any) => ({
  type: type,
  props: props,
  children: children
})

export let TxDef: { [name: string]: Tx } = {
  labelOverInput: {
    name: 'labelOverInput',
    tree: m(UxDef.div, 
            { 
              style: { 
                type: "code", 
                value: '{ display: "flex", flexDirection: "column", padding: "5px" }'
              } 
            },
            [
              m(UxDef.label, { children: { type: "value", value: "label" } }, []),
              m(UxDef.Input, {}, [])
            ])
  },
  labelWithInput: {
    name: 'labelWithInput',
    tree: m(UxDef.div, 
            { 
              style: { 
                type: "code", 
                value: '{ display: "flex", padding: "5px" }'
              } 
            },
            [
              m(UxDef.label, { children: { type: "value", value: "label" } }, []),
              m(UxDef.Input, {}, [])
            ])
  },
  labelInputLabel: {
    name: 'labelInputLabel',
    tree: m(UxDef.div, 
            { 
              style: { 
                type: "code", 
                value: '{ display: "flex", padding: "5px" }'
              } 
            },
            [
              m(UxDef.label, { children: { type: "value", value: "label" } }, []),
              m(UxDef.Input, {}, []),
              m(UxDef.label, { children: { type: "value", value: "label" } }, []),
            ])
  },
  labelOverDropdown: {
    name: 'labelOverDropdown',
    tree: m(UxDef.div, 
            { 
              style: { 
                type: "code", 
                value: '{ display: "flex", flexDirection: "column", padding: "5px" }'
              } 
            },
            [
              m(UxDef.label, { children: { type: "value", value: "label" } }, []),
              m(UxDef.Dropdown, {}, [])
            ])
  },
  labelWithDropdown: {
    name: 'labelWithDropdown',
    tree: m(UxDef.div, 
            { 
              style: { 
                type: "code", 
                value: '{ display: "flex", alignItems: "center", padding: "5px" }'
              } 
            },
            [
              m(UxDef.label, { children: { type: "value", value: "label" } }, []),
              m(UxDef.Dropdown, { selection: { type: "code", value: "true" }}, [])
            ])
  },
  dropdownWithDate: {
    name: 'dropdownWithDate',
    tree: m(UxDef.div, 
            { 
              style: { 
                type: "code", 
                value: '{ display: "flex", alignItems: "center", padding: "5px" }'
              } 
            },
            [
              m(UxDef.label, { children: { type: "value", value: "label" } }, []),
              m(UxDef.Dropdown, { selection: { type: "code", value: "true" }}, []),
              m(UxDef.label, { children: { type: "value", value: "label" } }, []),
              m(UxDef.Input, {}, [])
            ])
  },
  checkboxWithLabel: {
    name: 'checkboxWithLabel',
    tree: m(UxDef.div, 
            { 
              style: { 
                type: "code", 
                value: '{ display: "flex", padding: "5px" }'
              } 
            },
            [
              m(UxDef.Checkbox, { style: { type: "code", value: '{ marginRight: "5px" }' }}, []),
              m(UxDef.label, { children: { type: "value", value: "label" } }, [])
            ])
  },
  checkboxWithLabelAndDropdown: {
    name: 'checkboxWithLabelAndDropdown',
    tree: m(UxDef.div, 
            { 
              style: { 
                type: "code", 
                value: '{ display: "flex", alignItems: "center", padding: "5px" }'
              } 
            },
            [
              m(UxDef.Checkbox, { style: { type: "code", value: '{ marginRight: "5px" }' }}, []),
              m(UxDef.label, { children: { type: "value", value: "label" } }, []),
              m(UxDef.Dropdown, { selection: { type: "code", value: "true" } }, [])
            ])
  },
  testDateResult: {
    name: 'testDateResult',
    tree: m(UxDef.div, 
            { 
              style: { 
                type: "code", 
                value: '{ display: "flex", padding: "5px" }'
              } 
            },
            [
              m(UxDef.label, { children: { type: "value", value: "label" } }, []),
              m(UxDef.Dropdown, { selection: { type: "code", value: "true" }}, []),
              m(UxDef.label, { children: { type: "value", value: "label" } }, []),
              m(UxDef.Input, {}, []),
              m(UxDef.label, { children: { type: "value", value: "label" } }, []),
              m(UxDef.Dropdown, { selection: { type: "code", value: "true" }}, []),
            ])
  },
  serviceRecord: {
    name: 'serviceRecord',
    tree: m(UxDef.div, 
            { 
              style: { 
                type: "code", 
                value: '{ display: "flex", padding: "5px", alignItems: "center" }'
              } 
            },
            [
              m(UxDef.Checkbox, { style: { type: "code", value: '{ marginRight: "5px" }' }}, []),
              m(UxDef.label, { children: { type: "value", value: "label" } }, []),
              m(UxDef.label, { children: { type: "value", value: "label" } }, []),
              m(UxDef.Dropdown, { selection: { type: "code", value: "true" }}, []),
              m(UxDef.Input, {}, []),
              m(UxDef.label, { children: { type: "value", value: "label" } }, []),
              m(UxDef.Input, {}, []),
              m(UxDef.label, { children: { type: "value", value: "label" } }, []),
              m(UxDef.Input, {}, []),
            ])
  },
  halfRow: {
    name: 'halfRow',
    tree: m(UxDef.div, 
            { 
              style: { 
                type: "code", 
                value: '{ display: "flex", padding: "5px" }'
              } 
            },
            [
              m(UxDef.div, { children: { type: "value", value: "left" }, style: { type: "code", value: "{ flex: 1 }" } }, []),
              m(UxDef.div, { children: { type: "value", value: "right" }, style: { type: "code", value: "{ flex: 1 }" } }, []),
            ])
  }

}

export default TxDef
