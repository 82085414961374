const cv = (window as any).cv

interface ColorCount {
    color: string,
    count: number
}

interface LineSegment {
    xstart: number,
    xstop: number
    y: number
}

interface BlockSegment {
    xstart: number,
    xstop: number,
    ystart: number,
    ystop: number
}

const COLOR_THRESHOLD = 13

export const processDentalChart = (img: HTMLImageElement, canvas: HTMLCanvasElement) => {
    const orig = cv.imread(img)
    let canny = new cv.Mat()
    cv.Canny(orig, canny, 100, 200)
    let output = new cv.Mat()
    cv.cvtColor(canny, output, cv.COLOR_GRAY2RGBA, 0)
    cv.imshow(canvas, output)
    return output
}

export const processImageGcs = (img: HTMLImageElement, canvas: HTMLCanvasElement) => {
    const orig = cv.imread(img)
    var gray = new cv.Mat()
    cv.cvtColor(orig, gray, cv.COLOR_RGBA2GRAY, 0)
    cv.imshow(canvas, orig)
    const lineSegments: LineSegment[] = getLineSegments(gray)
    const blockSegments: BlockSegment[] = getBlockSegments(lineSegments)
    for (const block of blockSegments) {
        // console.log(block)
        cv.rectangle(orig, 
                     new cv.Point(block.xstart, block.ystart),
                     new cv.Point(block.xstop, block.ystop),
                     new cv.Scalar(255, 0, 0, 255), 1)
    }
    // cv.line(mat, new cv.Point(100, 100), new cv.Point(600, 200), new cv.Scalar(255, 0, 0, 255), 10, cv.LINE_8, 1)
    // for (const segment of lineSegments) {
    //     cv.line(mat, new cv.Point(segment.xstart, segment.y), new cv.Point(segment.xstop, segment.y), 
    //             new cv.Scalar(0), 1, cv.LINE_4, 0)
    //     // console.log(segment)
    // }
    cv.imshow(canvas, orig)
}

export const processImage = (img: HTMLImageElement, canvas: HTMLCanvasElement) => {
    const mat = cv.imread(img)
    cv.imshow(canvas, mat)
    console.log(mat.size().height, mat.size().width)
    console.log(mat.depth(), mat.channels(), mat.type())
    const ptr = mat.ucharPtr(1400, 243)
    console.log(ptr)
    for (var i=0; i<mat.size().height; i++) {
        const p = mat.ucharPtr(600, i)
        console.log(p)
    }
}

const getBlockSegments: (lineSegments: LineSegment[]) => BlockSegment[]
= (lineSegments) => {
    var out: BlockSegment[] = []
    const data = lineSegments.sort((a, b) => {
        return (a.y < b.y) ? -1 :
            a.y > b.y ? 1:
                a.xstart < b.xstart ? -1:
                a.xstart > b.xstart ? 1:
                    a.xstop < b.xstop ? -1:
                    a.xstop > b.xstop ? 1: -1
        })
    var y = 0
    for (const line of data) {
        const touchedBlocks = out.filter(block => {
            return (line.y === block.ystop || line.y === block.ystop+1) 
                && !(line.xstop < block.xstart || line.xstart > block.xstop)
        })
        out = out.filter(block => !touchedBlocks.includes(block))
        if (touchedBlocks.length === 0) {
            out.push({
                xstart: line.xstart,
                xstop: line.xstop,
                ystart: line.y,
                ystop: line.y
            })
        } else {
            const xstart = Math.min(...touchedBlocks.map(block => block.xstart), line.xstart)
            const xstop = Math.max(...touchedBlocks.map(block => block.xstop), line.xstop)
            const ystart = Math.min(...touchedBlocks.map(block => block.ystart))
            const ystop = Math.max(...touchedBlocks.map(block => block.ystop), line.y)
            out.push({
                xstart: xstart,
                xstop: xstop,
                ystart: ystart,
                ystop: ystop
            })
        }
    }
    return out
}

const getLineSegments: (mat: any) => LineSegment[] = (mat) => {
    const rows = mat.size().height
    const cols = mat.size().width
    console.log(mat.size())
    // console.log(mat.ucharPtr(900, 400))
    const base = getBaseColor(mat, 0, cols - 1, 0, rows - 1)
    var lineSegments: LineSegment[] = []
    for (var y=0; y < rows; y++) {
        lineSegments = lineSegments.concat(scanLine(mat, y, base, 0, cols-1))
    }
    return lineSegments
}

const scanLine: (mat: any, y: number, base: number, xmin: number, xmax: number) => LineSegment[]
= (mat, y, base, xmin, xmax) => {
    var output: LineSegment[] = []
    var active = false
    var xstart = xmin
    for (var x=xmin; x<=xmax; x++) {
        if (!active && !matchColor(mat.ucharPtr(y, x)[0], base)) {
            active = true
            xstart = x
        } else if (active && matchColor(mat.ucharPtr(y, x)[0], base)) {
            active = false
            output.push({ y: y, xstart: xstart, xstop: x - 1})
        }
    }
    if (active) {
        output.push({y: y, xstart: xstart, xstop: xmax})
    }
    return output
}

const getBaseColor : (mat: any, x1: number, x2: number, y1: number, y2: number) => number
= (mat, x1, x2, y1, y2) => (
    parseInt(getBorderColors(mat, x2, x2, y1, y2)[0].color)
)

const getBorderColors: (mat: any, x1: number, x2: number, y1: number, y2: number) => ColorCount[]
= (mat, x1, x2, y1, y2) => {
    const data = [
        Array.from({length: x2 - x1 + 1}, (_, i) => i + x1).map(x => mat.ucharPtr(y1, x)[0]),
        Array.from({length: x2 - x1 + 1}, (_, i) => i + x1).map(x => mat.ucharPtr(y2, x)[0]),
        Array.from({length: y2 - y1 + 1}, (_, i) => i + y1).map(y => mat.ucharPtr(y, x1)[0]),
        Array.from({length: y2 - y1 + 1}, (_, i) => i + y1).map(y => mat.ucharPtr(y, x2)[0]),
    ].reduce((acc, cur) => acc.concat(cur), [])
    var temp: { [color: string]: number } = {}
    data.forEach(c => {
        if (!temp[c.toString()])
            temp[c.toString()] = 0
        temp[c.toString()] += 1
    })
    return Object.keys(temp).map((color, index) => ({ color: color, count: temp[color]} as ColorCount))
            .sort((a, b) => a.count < b.count ? 1 : -1)
}

const matchColor = (color: number, base: number) => ( Math.abs(color - base) < COLOR_THRESHOLD)


