import React from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';


export type UploadState = {
    taskList?: any[]
    row?: any[],
    columns?: any[]
    data?: any[],
    resolvedIssues?: any[]
}

export const UploadStateInitial = {
    taskList: [],
    row: [],
    columns: [],
    data: [],
    resolvedIssues: []
}

export type UploadEvent = 
        { message: "GetTaskList", params: {} }
    |   { message: "GetResolvedIssues", params: {} }
    |   { message: "ProcessExcel", params: { file: File } }
    |   { message: "ProcessJSON", params: { file: File } }
    |   { message: "ProcessQML", params: { file: File } }


export default class Upload extends React.Component<
    {
        onEvent: any
    } 
    &  UploadState,
    {
        sub: string,
    }>
{
    
    constructor(props: any) {
        super(props)
        this.state = {
            sub: "qml",
        }
    }

    componentDidMount = async () => {
        if (this.state.sub === "taskList") {
            this.props.onEvent({ message: 'GetTaskList', params: {} })
        } else if (this.state.sub === 'resolved') {
            this.props.onEvent({ message: 'GetResolvedIssues', params: {} })
        }
    }
    
    handleEvent = async ({message, params}: {message: string, params: any}) => {
        console.log(message, params)
        if (message === "clickButton" && params.name === "chooseExcel") {
            this.props.onEvent({ message: "ProcessExcel", params: { file: params.value.files[0] }})
        } else if (message === "clickButton" && params.name === "chooseJSON") {
            this.props.onEvent({ message: "ProcessJSON", params: { file: params.value.files[0] }})
        }
    }

    render() {
        if (this.state.sub === 'taskList') {
            let project_sum: any[] = []
            if (this.props.taskList && this.props.taskList?.length > 0) {
                const sumobj = this.props.taskList
                    .map(item => item.project_name)
                    .reduce((acc, cur) => {
                        if (!Object.keys(acc).includes(cur)) {
                            acc[cur] = 0
                        }
                        acc[cur] += 1
                        return acc
                    }, {})
                project_sum = Object.entries(sumobj)
            }
            return(
                project_sum.map((item: any[], index: number) => (
                <div key={index} style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>{item[0]}</div>
                    <div style={{ flex: 1 }}>{item[1]}</div>
                </div>
                ))
            )
        } else if (this.state.sub === 'qml') {
            return(
                <div style={{height: '100%', width: '100%', padding: '15px'}}>
                    <input 
                        type="file" 
                        style={{ width: "100%"}}
                        onClick={(e: any) => { e.target.value = null }}
                        onChange={(event) => {
                            this.props.onEvent({ 
                                message: "ProcessQML", 
                                params: { file: event.target?.files?.[0] }
                            });
                        }}
                    />
                </div>
            )
        } else if (this.state.sub === 'AgGrid') {
            return(
                <div style={{height: '100%', width: '100%', margin: '15px'}}>
                    <input type="file" onChange={(event) => {
                        this.handleEvent({
                            message: 'clickButton',
                            params: {
                                name: 'chooseExcel',
                                value: event.target
                            }
                        })
                    }}/>
                    {this.props.row && this.props.row.length > 0 && 
                    <div className="ag-theme-balham" style={{ height: '600px', width: '1200px' }}>
                        <AgGridReact
                            columnDefs={this.props.columns}
                            rowData={this.props.row}
                        />
                    </div>}
                </div>
            )
        } else if (this.state.sub === 'JSON') {
            return(
                <div style={{height: '100%', width: '100%', margin: '15px'}}>
                    <input type="file" 
                        onClick={(e: any) => { e.target.value = null }}
                        onChange={(event) => {
                            this.handleEvent({
                                message: 'clickButton',
                                params: {
                                    name: 'chooseJSON',
                                    value: event.target
                                }
                            })
                        }}
                    />
                    {this.props.row && this.props.row.length > 0 && 
                    this.props.row.map((item => <div>{item}</div>))
                    }
                </div>
            )
        } else if (this.state.sub === 'resolved') {
            return(
            <div>
            {this.props.resolvedIssues?.map((item, index) => (
                <div key={index}>{item.subject}</div>
            ))}
            </div>)
        } else {
            return(<div></div>)
        }
    }
}
