import moment from "moment";
import { convertToDoc } from "ismor-lib/apps/XD2/SpecRender";
import { Document, Packer } from "docx";
import JSZip from "jszip";
import { saveAs } from "file-saver";

export function MenuItem(props: any) {
  return (
    <div
      style={{
        padding: "5px 10px 5px 10px", cursor: "pointer",
        backgroundColor: props.name === props.mode ? "#eeeeee" : "white",
      }}
      onClick={(e: any) => {
        props.setMode(props.name);
      }}>
      {props.name}
    </div>
  )
}

export const genLetter =  async (letterDict: any, yearSum: any[], ym: string, categoryList: string[]) => {
  let data = yearSum
    .filter((staffItem: any) => staffItem.id !== "Sum")
    .map((staffItem: any) => ({
      id: staffItem.id, 
      name: staffItem.name,
      ...(Object.fromEntries(
        categoryList.concat(["stock"]).map((category: string) => (
          [category, staffItem.detailDict[ym]?.components[category]]
        ))))
    }))
    .filter((item: any) => 
      categoryList.reduce((acc: any, cur: any) => 
        (acc || (item[cur] && item[cur] > 0)), false)
    )
  const zip = JSZip();
  for (const item of data) {
    let docTemplate = {
      type: "Document",
      sections: [{ properties: {}, children: [
        { type: "Paragraph", children: [{ 
          type: "TextRun", 
          text: `${(item as any).name} ครับ`, size: 30 }]},
        { type: "Paragraph", children: [{ 
          type: "TextRun", 
          text: letterDict[ym]?.pre || "", break: 1, size: 30 }]},
        ...categoryList.map((category: string) => ({
          type: "Paragraph", children: (category !== "dividend" ? [] : [{
          type: "TextRun", 
          text: `dividend ต่อหน่วยปันผล: ` + 
                `${((item as any).dividend / (item as any).stock)?.toLocaleString('en-US', {minimumFractionDigits: 2})}` + 
                ` บาท`,
                break: 0, size: 30}]).concat([{
          type: "TextRun", 
          text: `${category === "stock" ? "หน่วยปันผล" : category}: ` + 
                `${(item as any)[category]?.toLocaleString('en-US', {maximumFractionDigits: 0})}` + 
                ` ${category === "stock" ? "หน่วย": "บาท"}`, 
                break: 1, size: 30 }])
        })),
        { type: "Paragraph", children: [{ 
          type: "TextRun", 
          text: (letterDict[ym]?.post || ""), break: 1, size: 30 }]},
        { type: "Paragraph", children: [{ 
          type: "TextRun", 
          text: "พี่เจ", break: 1, size: 30 }]},
      ]}]
    };
    let doc = convertToDoc(docTemplate, {}) as Document;  
    const blob = await Packer.toBlob(doc);
    zip.file(`${(item as any).name}.docx`, blob);
  }
  zip.generateAsync({type: "blob"}).then(function(content: any) {
    saveAs(content, "letter.zip");
  });
}

// Constant & helpers -------------------------------------------------------------------------------
export const monthKeys = Array.from(
  {length: 12}, 
  (v, i) => (i+1).toString().padStart(2, '0'));

export const thisMonth = (moment().format("YYYYMM"));

export const nextYm = (ym: string) => {
  let [y, m] = [parseInt(ym.substring(0, 4)), parseInt(ym.substring(4))];
  m += 1;
  if (m > 12) {
    y += 1;
    m = 1;
  }
  return `${y}${m.toString().padStart(2, "0")}`;
}  

export const previousYm = (ym: string) => {
  let [y, m] = [parseInt(ym.substring(0, 4)), parseInt(ym.substring(4))];
  m -= 1;
  if (m < 1) {
    y -= 1;
    m = 12;
  }
  return `${y}${m.toString().padStart(2, "0")}`;
}  

export const monthListFrom = (ym: string) => {
  let monthList = [];
  let cur = ym;
  while (cur <= thisMonth) {
    monthList.push(cur);
    cur = nextYm(cur);
  }
  return monthList;
}

export const monthListFromTo = (ym: string, ymto: string) => {
  let monthList = [];
  let cur = ym;
  while (cur <= ymto) {
    monthList.push(cur);
    cur = nextYm(cur);
  }
  return monthList;
}

export const monthListOfYear = (year: string) => {
  return monthListFromTo(`${year}01`, `${year}12`);
}

export const monthListThisYear = () => {
  return monthListOfYear(`${thisMonth.substring(0, 4)}`);
}
