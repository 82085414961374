import React, { useState, useEffect } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { CreateUIv2Event } from './CreateUIv2';
import { ScreenData } from './CreateUIv2Jsx';

export type PropEditorState = {
    screenData?: ScreenData
}

export type PropEditorEvent =
        { message: "ChangeDefaultProp", params: any }

const PropEditor = (props: 
        PropEditorState 
    &   { 
            onEvent: (e: CreateUIv2Event) => void,
            setProp: (keys: string, value: any) => void,
        }
    ) => {

    const [propData, setPropData] = useState<any[][]>([["new", "new"]]);
    const [newKey, setNewKey] = useState<string>("");
    const [newValue, setNewValue] = useState<string>("");

    const resetPropData = () => {
        setPropData(
            Object.entries(props.screenData?.screenPropsDefault || {})
                  .map((entry: any) => (
                      [entry[0], 
                       (typeof entry[1] === "object") ? 
                       renderObject(entry[1]) : JSON.stringify(entry[1])
                      ]
                  ))
        );
        setNewKey("");
        setNewValue("");
    }

    const renderObject = (obj: any) => {
        if (typeof obj !== "object")
            return JSON.stringify(obj);
        let s: string = Object.keys(obj).map((key: string) => (
            `${key}: ${renderObject(obj[key])}`
        )).join(",")
        return `{${s}}`
    }
    
    const updatePropData = () => {
        try {
            let updatedData: any[] = [];
            let testValue: any;
            for (const entry of propData) {
                console.log(entry[1]);
                testValue = eval(`(${entry[1]})`);
                console.log(testValue);
                updatedData.push([entry[0], testValue]);
            }
            if (newKey !== "") {
                try {
                    console.log(newValue);
                    testValue = eval(`(${newValue})`);
                    updatedData.push([newKey, testValue]);
                } catch (e) {
                    console.log("Error", (e as Error).toString());        
                }
            }
            const testPropData = Object.fromEntries(updatedData);
            console.log(testPropData);
            props.setProp("screenData.screenPropsDefault", testPropData);
        } catch (e) {
            console.log("Error", (e as Error).toString());
        }
    }

    const removeKey = (key: string) => {
        if (key) {
            let p_: any[] = Object.assign([], propData);
            p_ = p_.filter((item: any) => item?.[0] !== key);
            setPropData(p_);
        }
    }

    useEffect(resetPropData, [props.screenData?.screenPropsDefault]);
    console.log(propData)
    
    return(
        <div style={{ position: "fixed", right: "50px", top: "70px", padding: "5px",
                        border: "solid blue 1px", width: "600px", maxHeight: "80vh",
                        overflowY: "scroll", zIndex: 10002, backgroundColor: "#cccccc"}}>
            <div style={{ width: "100%", textAlign: "center", marginBottom: "10px",
                          fontSize: "1.2rem", fontWeight: "bold"}}>
                Prop Editor
            </div>
            {propData.map((propItem: any[], index: number) => { 
                return(
                <div key={index} style={{ display: "flex", alignItems: "flex-start" }}>
                    <div style={{ flex: 1, textAlign: "right", marginRight: "5px" }}>
                        <input
                            style={{ textAlign: "right" }} 
                            value={propItem?.[0]?.toString() ?? ""} 
                            disabled={true} 
                        />
                    </div>
                    <div style={{ flex: 2 }}>
                        <textarea
                            style={{ width: "100%" }}
                            value={propItem?.[1]?.toString() ?? ""}
                            onChange={(e: any) => {
                                try {
                                    console.log(e.target.value);
                                    let p_: any[] = Object.assign([], propData);
                                    p_[index][1] = e.target.value;
                                    setPropData(p_);
                                } catch (e) {
                                    console.log("Error: ", (e as Error).toString());
                                }
                            }}
                        />
                    </div>
                    <Icon name="delete" color="red" 
                        onClick={(e: any) => { removeKey(propItem?.[0]); }}
                    />
                </div>
            )}).concat([
                <div key={propData.length} style={{ display: "flex", alignItems: "flex-start" }}>
                    <div style={{ flex: 1, textAlign: "right", marginRight: "5px" }}>
                        <input 
                            placeholder="name"
                            value={newKey}
                            style={{ textAlign: "right" }} 
                            onChange={(e: any) => {
                                if (!propData.map(
                                        (item: any) => item?.[0]
                                    ).includes(e.target.value)) {
                                    setNewKey(e.target.value);
                                }
                            }}
                        />
                    </div>
                    <div style={{ flex: 2 }}>
                        <textarea
                            style={{ width: "100%" }}
                            placeholder="value" 
                            value={newValue}
                            onChange={(e: any) => {
                                setNewValue(e.target.value);
                            }}
                        />
                    </div>
                    <Icon name="delete" color="red" disabled={true} />
                </div>
            ])}
            <div style={{ display: "flex", width: "100%", 
                          paddingTop: "10px", marginTop: "10px", 
                          borderTop: "solid gray 1px" }}>
                <Button color="green" fluid
                    onClick={updatePropData}>
                    Update
                </Button>
                <Button color="red" fluid
                    onClick={resetPropData}>
                    Reset
                </Button>
            </div>
        </div>
    )
}

export default PropEditor
