import { useEffect, useRef, useState } from "react";
import { loadPythonMainThread } from "ismor-lib/utils/Python";

export default function MatplotlibChart(props: any) {
  const [pythonMainThreadLoaded, setPythonMainThreadLoaded] = useState(false);
  const [pythonMainThreadError, setPythonMainThreadError] = useState<any>(null);
  const [img64, setImg64] = useState<string | null>(null);

  const chartRef = useRef<any>(null);
  const pythonMainThread = useRef<any>(null);

  useEffect(() => {
    (async () => {
      pythonMainThread.current = 
        await loadPythonMainThread(setPythonMainThreadLoaded, setPythonMainThreadError, `
        import pandas
        import micropip
        await micropip.install("matplotlib")
        True
        `);
      calcChartData();
    })();
  }, [props.pythonLoaded, props.bokehLoaded, props.pythonError, props.menu]);

  
  const calcChartData = async () => {
    const result =
      await pythonMainThread.current.run({}, `
        import matplotlib
        import matplotlib.pyplot as plt
        from io import BytesIO
        import base64
        import json
        
        plt.plot(
          [3, 4, 5], 
          [100, 200, 300] 
        )
        plt.title('Trend')
        plt.xlabel('Year')
        plt.ylabel('measures')
        buffer = BytesIO()
        plt.savefig(buffer)
        buffer.seek(0)
        img = base64.b64encode(buffer.read()).decode("utf-8")
        json.dumps({"img": img})
        `);
    setImg64(result.img)
  }

  return(
    <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
      {pythonMainThreadLoaded ?  
      <div id="myplot" ref={chartRef}>
        {img64 ? 
        <img src={`data:image/jpeg;base64, ${img64}`} />
        :
        <div>Calculating data. Please wait.</div>}
      </div>
      : 
      <div>Loading Analytics Engine. Please wait.</div>}
    </div>
  )
}
