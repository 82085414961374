import { WasmHandler } from 'react-lib/frameworks/WasmController';
import app from 'firebase/compat/app';

export type State = {
  medList?: any,
  medItem?: any,
}

export const StateInitial = {
  medList: null,
  medItem: null,
}

export type Event =
  { message: "MEDGetList", params: {} }
  | { message: "MEDGetItem", params: { medId : string } }
  | { message: "MEDUpdateItem", params: { medId : string, updateData: any } }

export type Handler = WasmHandler<State, Event>

export type Data = {
}

export const DataInitial: Data = {  
}

export const MEDGetList: Handler = async (controller, params) => {
  let dbweb = (controller as any).dbweb as app.firestore.Firestore;
  let medCache = (await dbweb.collection("cache").doc("medicine").get()).data() || {};
  controller.setState({medList: Object.values(medCache)});
  // MedGroupSetup(controller, { medCache, dbweb });
}

const MedGroupSetup: Handler = async (controller, params) => {
  let {medCache, dbweb} = params;
  console.log(medCache);
}

export const MEDGetItem: Handler = async (controller, params) => {
  if (!params.medId) return console.log("No medId");
  let dbweb = (controller as any).dbweb as app.firestore.Firestore;
  let result = (await dbweb.collection("medicine").doc(params.medId).get()).data() || null;
  controller.setState({medItem: result});
}

export const MEDUpdateItem: Handler = async (controller, params) => {
  if (!params.medId || !params.updateData) return console.log("Insufficient params");
  let dbweb = (controller as any).dbweb as app.firestore.Firestore;
  let { medList, medItem } = controller.getState();
  dbweb.collection("medicine").doc(params.medId).update(params.updateData)
  .then(() => {
    controller.setState({ medItem: { ...medItem, ...params.updateData } });
  });
  let updateCache = {
    id: params.medId,
    name_en: medItem.name_en,
    name_th: medItem.name_th,
    กลุ่มยา: medItem.กลุ่มยา,
    groupId: medItem.groupId,
    groupName: medItem.groupName,
    ...Object.fromEntries(Object.entries(params.updateData)
      .filter((entry: [string, any]) => 
        ["name_en", "name_th", "กลุ่มยา", "groupId", "groupName"].includes(entry[0])))
  };
  dbweb.collection("cache").doc("medicine").update({[params.medId]: updateCache});
  controller.setState({
    medList : medList.filter((item: any) => item.id !== params.medId)
              .concat([updateCache])
  });
}
