import { useEffect, useState } from "react";
import { RedmineStatus } from "ismor-lib/utils/Constant";
import { Button } from "semantic-ui-react";
import XLSX from 'xlsx';

export default function ProjectMonthly(props: any) {
  const [selectedProjectId, setSelectedProjectId] = useState<number | null>(null);
  const [projectDict, setProjectDict] = useState<any>({});
  const [ymList, setYmList] = useState<any[]>([]);
  const [selectedYm, setSelectedYm] = useState<string | null>(null);

  useEffect(() => {
    props.onEvent({message: "GetRedmineProjects", params: {}});
  }, []);

  useEffect(() => {
    if (props.redmineProjectList.length > 0)
      setProjectDict(Object.fromEntries(
        props.redmineProjectList.map((project: any) => [project.id, project])));
  }, [props.redmineProjectList]);

  useEffect(() => {
    if (projectDict?.[239]) 
      setSelectedProjectId(239);
  }, [projectDict]);

  useEffect(() => {
    if (selectedProjectId === null || !projectDict?.[selectedProjectId]) return;
    props.setProp("projectIssues", []);
    setYmList([]);
    getYmList(projectDict[selectedProjectId]);
  }, [selectedProjectId]);

  useEffect(() => {
    refreshData();
  }, [selectedYm]);

  const getYmList = (project: any) => {
    if (!project) return;
    let ymList_ = [];
    let current = project.created_on_tz_first.substring(0, 7)
      .split("-").map((item: string) => Number(item));
    let end = project.created_on_tz_last.substring(0, 7)
      .split("-").map((item: string) => Number(item));
    ymList_.push({year: current[0], month: current[1]});
    while (!(current[0] === end[0] && current[1] === end[1])) {
      current[1] += 1;
      if (current[1] > 12)
        current = [current[0] + 1, 1];
      ymList_ = [{year: current[0], month: current[1]}, ...ymList_];
    }
    setYmList(ymList_);
    if (selectedYm === toYmStr(ymList_?.[0])) {
      refreshData();
    } else {
      setSelectedYm(toYmStr(ymList_?.[0]));
    }
  }

  const refreshData = () => {
    props.setProp("projectIssues", []);
    if (!selectedYm) return;
    const [yearStr, monthStr] = selectedYm.split("-")
    const [year, month] = [Number(yearStr), Number(monthStr)];
    props.onEvent({message: "GetIssuesByProject", 
      params: {project_id: selectedProjectId, year: year, month: month}});
  }

  const toYmStr = (ym: any) => `${ym.year}-${ym.month.toString().padStart(2, "0")}`

  const prepTextField = (s: string, excel: boolean) => 
    excel ? s?.replaceAll(/!(.+)(jpg|png)!/g, "").trim()
    : s?.replaceAll(/!(.+)(jpg|png)!/g, "")?.split("\n")
      .map((line: string, lineIndex: number) => <div key={lineIndex}>{line}</div>);

  const templateColumns = "5% 20% 5% 5% 5% 28% 7% 25%";

  return(
    <div>
      {props.redmineProjectList &&
      <div style={{display: "flex", borderBottom: "solid grey 1px"}}>      
        <div style={{margin: "0 5px 0 5px"}}>Project</div>
        <select
          value={selectedProjectId || 0}
          onChange={(e: any) => setSelectedProjectId(e.target.value)}>
          {props.redmineProjectList
            .filter((project: any) => project.status === 1)
            .sort((a: any, b: any) => a.project_name < b.project_name ? -1 : 1)
            .map((project: any, projectIndex: number) => (
            <option 
              key={projectIndex}
              value={project.id}>
              {`${project.project_name} [id ${project.id}]`}
            </option>
          ))}
        </select>
        <div style={{margin: "0 5px 0 5px"}}>Year Month</div>
        <select
          value={selectedYm || ""}
          onChange={(e: any) => setSelectedYm(e.target.value)}>
          {ymList
            .map((ym: any) => toYmStr(ym))
            .map((ymStr: any, ymStrIndex: number) => {return(
            <option 
              key={ymStrIndex}
              value={ymStr}>
              {ymStr}
            </option>
          )})}
        </select>
        <div style={{flex: 1}}></div>
        <Button 
          size="mini" color="green"
          onClick={(e: any) => {
            if (!selectedProjectId) return;
            let workbook = XLSX.utils.book_new();
            workbook.SheetNames.push("Data");
            let sheet_data = [
              ["Issue ID", "Subject", "Author", "Author date", "Status", "Description", "Resolve/Feedback Date", "Notes"]
            ].concat(
              props.projectIssues.map((issue: any) => (
                [
                  issue.id,
                  issue.subject,
                  issue.author,
                  issue.created_on_tz.split("T").join(" "),
                  RedmineStatus[issue.status_id],
                  prepTextField(issue.description, true)
                ].concat(
                  (() => {
                    if (issue.journal && issue.journal.length > 0) {
                      const last_journal = issue.journal[issue.journal.length - 1];
                      return [
                        last_journal.journal_created_on?.split("T")?.join(" "),
                        prepTextField(last_journal.journal_notes, true)
                      ]
                    } else {
                      return ["", ""]
                    }
                  })()
                )
              )
            ));
            let sheet = XLSX.utils.aoa_to_sheet(sheet_data);
            if (!sheet["!cols"]) sheet["!cols"] = [];
            if (!sheet["!cols"][4]) sheet["!cols"][1] = {wch: 70};
            if (!sheet["!cols"][4]) sheet["!cols"][5] = {wch: 70};
            if (!sheet["!cols"][4]) sheet["!cols"][7] = {wch: 70};
            workbook.Sheets["Data"] = sheet;
            XLSX.writeFile(workbook, 
              `${projectDict?.[selectedProjectId]?.project_name} ${selectedYm}.xlsx`, 
              { bookType: "xlsx", type: "buffer" });
          }}>
          Download
        </Button>
      </div>
      }
      <div 
        style={{
          display: "grid", overflowY: "scroll",
          borderBottom: "solid grey 1px",
          gridTemplateColumns: templateColumns
        }}>
        <div>Issue ID</div>
        <div style={{wordWrap: "break-word", borderLeft: "solid grey 1px"}}>Subject</div>
        <div style={{wordWrap: "break-word", borderLeft: "solid grey 1px"}}>Author</div>
        <div style={{wordWrap: "break-word", borderLeft: "solid grey 1px"}}>Author Date</div>
        <div style={{wordWrap: "break-word", borderLeft: "solid grey 1px"}}>Status</div>
        <div style={{wordWrap: "break-word", borderLeft: "solid grey 1px"}}>Description</div>
        <div style={{wordWrap: "break-word", borderLeft: "solid grey 1px"}}>Resolve/Feedback Date</div>
        <div style={{wordWrap: "break-word", borderLeft: "solid grey 1px"}}>Notes</div>
      </div>
      <div style={{height: "87vh", overflowY: "scroll"}}>
        {props.projectIssues.map((issue: any, issueIndex: number) => (
          <div 
            key={issueIndex} 
            style={{
              display: "grid", 
              borderBottom: "solid grey 1px",
              gridTemplateColumns: templateColumns
            }}>
            <div style={{wordWrap: "break-word"}}>{issue.id}</div>
            <div style={{wordWrap: "break-word", borderLeft: "solid grey 1px"}}>{issue.subject}</div>
            <div style={{wordWrap: "break-word", borderLeft: "solid grey 1px"}}>{issue.author}</div>
            <div style={{wordWrap: "break-word", borderLeft: "solid grey 1px"}}>
              {issue.created_on_tz.split("T").join(" ")}
            </div>
            <div style={{wordWrap: "break-word", borderLeft: "solid grey 1px"}}>{RedmineStatus[issue.status_id]}</div>
            <div style={{wordWrap: "break-word", borderLeft: "solid grey 1px"}}>
              {prepTextField(issue.description, false)}
            </div>
            {(() => {
              if (issue.journal && issue.journal.length > 0) {
                const last_journal = issue.journal[issue.journal.length - 1];
                return (
                  <>
                    <div style={{wordWrap: "break-word", borderLeft: "solid grey 1px"}}>
                      {last_journal.journal_created_on?.split("T")?.join(" ")}
                    </div>
                    <div style={{wordWrap: "break-word", borderLeft: "solid grey 1px"}}>
                      {prepTextField(last_journal.journal_notes, false)}
                    </div>
                  </>
                )
              } else {
                return(<><div></div><div></div></>)
              }
            })()}
          </div>
        ))}
      </div>
    </div>
  )

}
