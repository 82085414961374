import { WasmHandler } from 'react-lib/frameworks/WasmController';
import app from 'firebase/compat/app';

export type State = {
  hzList?: any[]
}

export const StateInitial = {
  hzList: []
}

export type Event =
  { message: "HZGetList", params: {} }
  | { message: "HZGetItem", params: { id : string } }
  

export type Handler = WasmHandler<State, Event>

export type Data = {
}

export const DataInitial: Data = {  
}

export const HZGetList: Handler = async (controller, params) => {
  let storageweb = (controller as any).storageweb as app.storage.Storage;
  let url = await storageweb.refFromURL("gs://everymorian.appspot.com/hanzi.json").getDownloadURL()
  let data = await (await fetch(url)).json();
  controller.setState({hzList: data});
}

export const HZGetItem: Handler = async (controller, params) => {
}
