import { WasmHandler } from '../../../react-lib/frameworks/WasmController'

import axios from "axios";

type Handler = WasmHandler<State, Event, Data>

// Spec ---------------------------------------------------------------------
export type State = {
    hn?: string,
    scoring?: {
        count: number,
        tables: string[],
        note: string,
    }
    // For contest
    hnList?: string[],
    scoringList?: {
        count: number,
        tables: string[],
        note: string,
    }[]
}

export const StateInitial = {
    hn: "",
    scoring: {
        note: "Not yet updated",
        count: 0,
        tables: [],
    },
    hnList: [],
    scoringList: [
        {
            note: "Not yet updated",
            count: 0,
            tables: [],
        },
        {
            note: "Not yet updated",
            count: 0,
            tables: [],
        },
        {
            note: "Not yet updated",
            count: 0,
            tables: [],
        },
        {
            note: "Not yet updated",
            count: 0,
            tables: [],
        }
    ],
}

export type Event = 
    { message: "GetScoring", params: {} }
    | { message: "GetScoringContest", params: { index: number } }
    | { message: "SaveContest", params: { index: number } }

export type Data = {
    FASTAPI?: string,
}

export const DataInitial = {
    FASTAPI: "http://localhost:8000",
}

export const GetScoring: Handler = async (controller, params) => {
    const hn = controller.getState().hn;
    if (hn && hn !== "") {
        try {
            controller
                .db
                .collection("preferences")
                .doc(controller.user.email)
                .update({
                    hn: hn
                });
            const result = await axios.get(
                `${controller.data.FASTAPI}/api/get-encounter/`, 
                {
                    params: { hn: hn },
                    headers: { 
                        "Authorization": `Token ${await controller.user.getIdToken()}`
                    }
                }
            )
            console.log(result.data);
            controller.setState({
                scoring: result.data
            })
        } catch (e) {
            console.log(e);
            controller.setState({
                scoring: {
                    note: (e as Error).toString(),
                    count: 0,
                    tables: []
                }
            })
        }
    }
}

export const GetScoringContest: Handler = async (controller, params) => {
    const hnList = controller.getState().hnList || [];
    let scoringList = [...controller.getState().scoringList || []];
    const resultList = await Promise.all(hnList.map(async (hn: string, index: number) => {
      if (hn && hn !== "") {
        return axios.get(
          `${controller.data.FASTAPI}/api/get-encounter/`, 
          {
              params: { hn: hn },
              headers: { 
                  "Authorization": `Token ${await controller.user.getIdToken()}`
              }
          }).then((result: any) => {
            scoringList[index] = result.data
          })
          .catch((e: any) => {
              console.log(e);
              scoringList[index] = {
                note: (e as Error).toString(),
                count: 0,
                tables: []
              };
          });
      } else {
        return Promise.resolve()
      }
    }))
    controller.setState({
      scoringList: scoringList
    });
}

export const SaveContest: Handler = async (controller, params) => {
    controller
      .db
      .collection("preferences")
      .doc(controller.user.email)
      .update({
          hnList: controller.getState().hnList || []
      });
}
