import React, { useState } from "react";
import { Grid, Header, Button, Input } from "semantic-ui-react";

const FirebaseLoginForm = (props: any) => {
  const [token, setToken] = useState<string>("");
  return(
  <Grid textAlign='center' style={{ height: "100vh" }} verticalAlign='middle'>
    <Grid.Column style={{ maxWidth: 450 }}>
      <Header as='h2' color='teal' textAlign='center'>
        Logging-in to your IsMor account
      </Header>  
      <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <div id='firebase-login' />
        {props.webviewReady ?
        <div style={{width: "220px", marginTop: "15px"}}>
          <Input value={token} onChange={(e: any) => setToken(e.target.value)} fluid/>
          <Button
            style={{width: "100%"}}
            size="mini" color="green"
            onClick={async (e: any) => {
              if (!(token && token !== "")) return;
              
              props.controller.functions.httpsCallable("createCustomTokenFromIdToken")(token)
              .then((result: any) => {
                props.controller.auth.signInWithCustomToken(result.data)
                .catch((err: any) => { console.log(err); });
              })
              .catch((err: any) => { console.log(err); });
            }}>
            Login with Token
          </Button>
        </div>
        :
        <>
          <Button
            style={{width: "220px"}}
            color="blue"
            onClick={async (e: any) => {
              const provider = new props.controller.app.auth.GoogleAuthProvider();
              const user = await props.controller.auth.signInWithPopup(provider);
              props.controller.setupExtraApps(user);
            }}>
            Login with Pop-up
          </Button>
          <Button
            style={{width: "220px", marginTop: "15px"}}
            color="orange"
            onClick={async (e: any) => {
              const provider = new props.controller.app.auth.GoogleAuthProvider();
              const user = await props.controller.auth.signInWithRedirect(provider);
            }}>
            Login Redirect
          </Button>
        </>}
      </div>
    </Grid.Column>
  </Grid>)
};

export default FirebaseLoginForm;
