export default function GameSummary(props: any) {
  
  return(
    <div style={{padding: "10px"}}>
      {(props.ihGameList || []).map((game: any, gameIndex: number) => {
        return(
          <div 
            key={gameIndex}
            style={{display: "flex", borderBottom: "solid grey 1px"}}
            onClick={() =>  {
              props.setMode("Edit");
              props.setSelectedGameId(game.id);
            }}>
            <div style={{flex: 2}}>{game.name}</div>
            <div style={{flex: 1, textAlign: "right"}}>{`${game.levels?.length} levels`}</div>
            <div style={{flex: 1, textAlign: "right"}}>
              {`${(game.levels || [])
              .map((level: any) => level.steps?.length || 0)
              .reduce((acc: number, cur: number) => acc + cur, 0)} steps`}
            </div>
            <div style={{flex: 3}}></div>
          </div>
        )
      })}
    </div>
  )
}