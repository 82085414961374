import { useEffect, useRef, useState } from 'react';

export default function HZ(props:any) {

  useEffect(() => {
    props.onEvent({message: "HZGetList", params: {}});
  }, []);

  return(
    <div style={{height: "95vh", width: '100%'}}>
      {props.hzList.map((item: any, index: number) => (
        <div key={index} style={{display: "flex", width: "100%", borderBottom: "solid #cccccc 1px"}}>
          <div style={{flex: 1}}>{item.character}</div>
          <div style={{flex: 1}}>{item.pinyin}</div>
          <div style={{flex: 1}}>{item.meaning}</div>
        </div>
      ))}
    </div>
  )
}
