import { useState } from "react";
import SalesLog from "./SalesLog";
import DealDocument from "./DealDocument";
import { Button, Icon } from "semantic-ui-react";

export default function DealDetail(props: any) {
  const [mode, setMode] = useState("Edit");
  const [selectedDeliverableId, setSelectedDeliverableId] = useState<string | null>(null);
  const [editedDeliverable, setEditedDeliverable] = useState<any>(null);
  const [editedDeal, setEditedDeal] = useState<any>(null);
  
  const setDeliverable = (key: string, value: any) => {
    if (!selectedDeliverableId) return
    setEditedDeliverable({
      ...editedDeliverable,
      [key]: value
    });
  }

  return(
    <div style={{flex: 3}}>
      <div style={{display: "flex", borderBottom: "solid grey 1px", marginBottom: "10px"}}>
        <MenuItem mode={mode} setMode={setMode} name="Edit" />
        <MenuItem mode={mode} setMode={setMode} name="Sales Log" />
        <MenuItem mode={mode} setMode={setMode} name="Document" />      
      </div>
      {mode === "Sales Log" ?
      <SalesLog
        onEvent={props.onEvent}
        selectedDeal={props.selectedDeal}
      />
      
      : mode === "Document" ?
      <DealDocument 
        onEvent={props.onEvent}
        setProp={props.setProp}
        getDocumentUrl={props.getDocumentUrl}
        webviewReady={props.webviewReady}
        selectedDeal={props.selectedDeal} 
        webviewMessages={props.webviewMessages}
        pdfExtract={props.pdfExtract}
        pythonWorker={props.python}
        pythonWorkerLoaded={props.pythonLoaded}
        pythonWorkerError={props.pythonError}
        bokehLoaded={props.bokehLoaded}
      />
      
      : mode === "Edit" ?
      <>
      <div>
        {editedDeal ?
        <div style={{display: "grid", gridTemplateColumns: "100px 200px 200px 100px 1fr"}}>
          <div style={{marginRight: "10px"}}>{editedDeal.clientName}</div>
          <div>  
            <input 
              disabled={selectedDeliverableId !== null}
              placeholder="Name"
              value={editedDeal?.dealName}
              onChange={(e: any) => {
                if (selectedDeliverableId !== null) return
                setEditedDeal({...editedDeal, dealName: e.target.value});
              }}
              />
          </div>
          <div>
            <input 
              disabled={selectedDeliverableId !== null}
              placeholder="Value"
              value={Number.isNaN(editedDeal?.value) ? "0" : editedDeal.value}
              onChange={(e: any) => {
                if (selectedDeliverableId !== null) return
                if (!Number.isNaN(Number(e.target.value + "0")))
                  setEditedDeal({...editedDeal, value: e.target.value});
              }}
            />
          </div>
          <div>
            <select 
              disabled={selectedDeliverableId !== null}
              value={editedDeal.status}
              onChange={(e: any) => {
                if (selectedDeliverableId !== null) return
                setEditedDeal({
                  ...editedDeal,
                  status: e.target.value
                })
              }}>
              <option value="SALES">SALES</option>
              <option value="ONGOING">ONGOING</option>
              <option value="FINISHED">FINISHED</option>
            </select>
          </div>
          <div>
            <Button 
              disabled={selectedDeliverableId !== null}
              size="mini" color="green"
              onClick={(e: any) => {
                props.onEvent({
                  message: "UpdateClientDeal",
                  params: {
                    id: editedDeal.id,
                    data: {
                      dealName: editedDeal.dealName,
                      value: Number(editedDeal.value),
                      status: editedDeal.status
                    },
                  }
                });
                setEditedDeal(null);
              }}>
              Save
            </Button>
            <Button
              size="mini" color="red"
              onClick={(e: any) => {
                setEditedDeal(null);
              }}>
              Cancel
            </Button>
          </div>
        </div>
        :
        <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",}}
          onClick={(e: any) => setEditedDeal(Object.assign({}, props.selectedDeal))}>
          <div style={{display: "flex"}}>
            <div style={{marginRight: "10px"}}>{props.selectedDeal.clientName}</div>
            <div>  
              {props.selectedDeal.dealName}
            </div>
          </div>
          <div style={{textAlign: "right", marginRight: "20px"}}>
            {(Number.isNaN(props.selectedDeal?.value) ? "0" : props.selectedDeal.value)
              ?.toLocaleString('en-US', {minimumFractionDigits: 2})}
          </div>
          <div></div>
          <div>
            {props.selectedDeal.status}
          </div>
          <div></div>
        </div>
        }
        <div>
          <div
            style={{cursor: "pointer",borderBottom: "solid #cccccc 1px", marginTop: "10px"}}>

            {/* Add deliverable */}
            <Icon name="plus" size="small"/>
            <span
              onClick={(e: any) => {
                props.onEvent({message: "AddDealDeliverable", 
                  params: {
                    data: {name: "New deliverable", status: "CREATED"}, 
                    selectedDeal: props.selectedDeal}
                });
              }}>
              Add deliverable
            </span>
          </div>

          {/* List deliverables */}
          {(Object.values(props.selectedDeal?.deliverables || {}) || [])
            .sort((a: any, b: any) => (a?.deadline_current || "") < (b?.deadline_current || "") ? -1 : 1)
            .map((item: any, itemIndex: number) => (
            (selectedDeliverableId !== null && selectedDeliverableId === item?.deliverableId) ?
            <div 
              key={itemIndex} 
              style={{
                display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                alignItems: "center", borderBottom: "solid #cccccc 1px"
              }}>
              <div>
                <input 
                  placeholder="ชื่อเรียก"
                  style={{width: "100%"}} 
                  value={editedDeliverable?.name || ""}
                  onChange={(e: any) => {
                    setDeliverable("name", e.target.value);
                  }}
                />
              </div>
              <div>
                <input 
                  placeholder="มูลค่า"
                  style={{width: "100%"}}  
                  value={Number.isNaN(editedDeliverable?.value) ? "0" : editedDeliverable.value}
                  onChange={(e: any) => {
                    if (!Number.isNaN(Number(e.target.value + "0")))
                      setDeliverable("value", e.target.value)
                  }}
                />
              </div>
              <div>
                <input 
                  placeholder="deadline ปัจจุบัน"
                  style={{width: "100%"}}  
                  value={editedDeliverable?.deadline_current || ""} 
                  onChange={(e: any) => {
                    setDeliverable("deadline_current", e.target.value);
                  }}
                />
              </div>
              <div>
                <select
                  value={editedDeliverable?.status || null}
                  onChange={(e: any) => {
                    setDeliverable("status", e.target.value);
                  }}>
                  <option value="CREATED">CREATED</option>
                  <option value="ONGOING">ONGOING</option>
                  <option value="FINISHED">FINISHED</option>
                </select>
              </div>
              <div>
                <Button
                  size="mini" color="green"
                  onClick={(e: any) => {
                    let {deliverableId, ...data} = editedDeliverable;
                    props.onEvent({message: "UpdateDealDeliverable", 
                      params: {deliverableId, data, selectedDeal: props.selectedDeal}});
                    setSelectedDeliverableId(null);
                    setEditedDeliverable(null);
                  }}>
                  Save
                </Button>
                <Button
                  size="mini" color="red"
                  onClick={(e: any) => {
                    setSelectedDeliverableId(null);
                    setEditedDeliverable(null);
                  }}>
                  Cancel
                </Button>
              </div>
            </div>
            :
            <div 
              key={itemIndex} 
              style={{
                display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                borderBottom: "solid #cccccc 1px"
              }}
              onClick={(e: any) => {
                if (selectedDeliverableId === null) {
                  setSelectedDeliverableId(item?.deliverableId);
                  setEditedDeliverable(Object.assign({}, item));
                }
              }}>
              <div>{item.name}</div>
              <div style={{textAlign: "right", marginRight: "20px"}}>
                {item.value?.toLocaleString('en-US', {minimumFractionDigits: 2})}
              </div>
              <div>{item.deadline_current}</div>
              <div>{item.status}</div>
              <div></div>
            </div>
          ))}

          {/* Sum deliverables */}
          <div 
            style={{
              display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
              alignItems: "center", marginTop: "5px", fontWeight: "bold"
            }}>
            <div>SUM TOTAL</div>
            <div style={{textAlign: "right", marginRight: "20px"}}>
              {(Object.values(props.selectedDeal?.deliverables || {}) || [])
                .reduce((acc: number, cur: any) => (acc + (Number(cur.value) || 0)), 0)
                ?.toLocaleString('en-US', {minimumFractionDigits: 2})}
              </div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        
      </div>
      </>
      
      :
      <></>
      }
      </div>
  )
}

function MenuItem(props: any) {
  return(
    <div 
      style={{
        backgroundColor: props.name === props.mode ? "#dddddd" : "white", 
        padding: "5px", cursor: "pointer"
      }}
      onClick={(e: any) => props.setMode(props.name)}>
      {props.name}
    </div>
  )
}
