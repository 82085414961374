import { WasmHandler } from 'react-lib/frameworks/WasmController'

export type State = {
  videoServerReady?: boolean,
  videoPathInfo?: any,
  videoData?: any,
  videoUrlPrefix?: string | null,
  videoBeingGenerated?: boolean,
  videoMessage?: string,
}

export const StateInitial = {
  videoServerReady: false,
  videoPathInfo: null,
  videoData: null,
  videoUrlPrefix: null,
  videoBeingGenerated: false,
  videoMessage: "",
}

export type Event = 
  { message: "GetVideo", params: {docId: string} }
  | { message: "SaveVideo", params: {docId: string, videoData: any} }

export type Data = {
}

export const DataInitial = {
}

type Handler = WasmHandler<State, Event, Data>

export const GetVideo: Handler = async (controller, params) => {
  if (!params.docId) return;
  const result = await controller.db.collection("video").doc(params.docId).get();
  let videoData = result.data();
  (window as any).pywebview.api.onEvent({
    message: "FillVideoData", 
    params: { videoData: videoData }
  });
}

export const SaveVideo: Handler = async (controller, params) => {
  if (!params.docId || !params.videoData) return;
  let items = (params.videoData?.items || []).map((item: any) => {
    // remove thumbnail
    let {thumbnail, ...cleanedData} = item;
    return cleanedData;
  });
  // console.log(items);
  controller.db.collection("video").doc(params.docId).set({items});
}