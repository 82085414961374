import React, { useState, useEffect } from 'react'
import FormDef, { type } from "./FormDef";
import { Checkbox, Icon } from 'semantic-ui-react';

type RenderEditorProps = {
  onEvent: any,
  setProp: any,
  first: boolean,
  last: boolean,
  obj: any,
  parentKey: string,
  dataKey: string,
  root?: boolean,
  openDataKey?: string | null,
  setOpenDataKey?: any,
}

const RenderEditor = (props: RenderEditorProps) => {

  const [value, setValue] = useState("");
  const [adding, setAdding] = useState(false);
  const [removing, setRemoving] = useState(false);


  useEffect(() => {
    if (type(props.obj) !== "object") {
      setValue(props.obj);
    }
  }, [props.obj])
  
  const t = type(props.obj);

  return(
  <div 
    style={{ 
      borderBottom: props.root ? "solid #cccccc 0.5px" : "none",
    }}>
    {t === "undefined" ?
    <div></div>:
    
    t === "array" ? 
    <div>
      <div 
        style={{
          display: "flex", justifyContent: "space-between", 
          backgroundColor: "#dddddd", cursor: "pointer" 
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <div>{`Array length = ${props.obj.length}`}</div>
        <AddButton
          onEvent={props.onEvent}
          adding={adding}
          setAdding={setAdding}
          dataKey={props.dataKey}
          parentKey={props.parentKey}
          t={t}
        />
      </div>
      {!(props.obj.__meta?.hide || false)
      && props.obj.map((item: any, index: number) => 
      type(item) === "array" ? // Children are also arrays
      <div
        key={index}
        style={{display: "grid", gridTemplateColumns: "20px 1fr"}}>
        <div>{index}</div>
        <RenderEditor
          onEvent={props.onEvent}
          setProp={props.setProp}
          openDataKey={props.openDataKey}
          setOpenDataKey={props.setOpenDataKey}
          first={false}
          last={false}
          key={index} 
          obj={item} 
          parentKey="array"
          dataKey={`${props.dataKey}.${index}`}
        />
      </div>
      :
      <RenderEditor
        key={index}
        openDataKey={props.openDataKey}
        setOpenDataKey={props.setOpenDataKey}
        first={index === 0}
        last={index === props.obj.length - 1}
        onEvent={props.onEvent}
        setProp={props.setProp}
        obj={item} 
        parentKey="array"
        dataKey={`${props.dataKey}.${index}`}
      />)}
    </div>
    :
    t !== "object" ? 
    <input
        style={{width: "100%", border: "none"}}
        value={value}
        onChange={(e: any) => setValue(e.target.value)}
        onBlur={(e: any) => {
          console.log(`formDef${props.dataKey}`);
          if (props.parentKey === "bold") {
            console.log("bold");
            props.setProp(`formDef${props.dataKey}`, eval(value));
          } else {
            props.setProp(`formDef${props.dataKey}`, value);
          }
          props.onEvent({message: "RefreshPDF", params: {}});
        }}
    />
    :
    <div>
      <div 
        style={{ 
          display: "flex", justifyContent: "space-between",
          backgroundColor: "lightblue", cursor: "pointer", borderBottom: "solid grey 1px"
        }}
        onClick={(e) => {
          e.stopPropagation();
          let newObj = Object.assign({}, props.obj);
          newObj.__meta.hide = !(newObj.__meta?.hide || false);
          props.setProp(`formDef${props.dataKey}`, newObj);
        }}>
        <ItemControlBar
          dataKey={props.dataKey}
          openDataKey={props.openDataKey}
          setOpenDataKey={props.setOpenDataKey}
          obj={props.obj}
          setProp={props.setProp}
          onEvent={props.onEvent}
        />
        <div style={{flex: 1}}></div>
        {/* <div>{`parentKey = '${props.parentKey}'; dataKey = '${props.dataKey}'`}</div> */}
        {props.parentKey === "array" &&
        <div style={{display: "flex", minWidth: "100px", justifyContent: "flex-end"}}>
          {!props.first &&
          <UpButton
            onEvent={props.onEvent}
            dataKey={props.dataKey}
            parentKey={props.parentKey}
            t={t}
          />}
          {/* {!props.last && */}
          <DownButton 
            last={props.last}
            onEvent={props.onEvent}
            dataKey={props.dataKey}
            parentKey={props.parentKey}
            t={t}
          />
          {/* } */}
          <RemoveButton 
            onEvent={props.onEvent}
            removing={removing}
            setRemoving={setRemoving}
            dataKey={props.dataKey}
            parentKey={props.parentKey}
            t={t}
          />
          <AddButton
            onEvent={props.onEvent}
            adding={adding}
            setAdding={setAdding}
            dataKey={props.dataKey}
            parentKey={props.parentKey}
            t={t}
          />
        </div>}
      </div>
      {!(props.obj.__meta?.hide || false)
      && Object.keys(props.obj || {})
      .filter((key: string) => key !== "__meta" && key !== "images")
      .sort()
      .map((key: any, key_index: number) => 
        <div 
          key={key_index}
          style={{ 
            display: "grid", 
            gridTemplateColumns: "15px 1fr"
          }}>
          <div></div>
          <div style={{ width: "100%", borderLeft: "solid #cccccc 0.5px" }}>
            <div 
              style={{ 
                border: "none", backgroundColor: "lightgreen", 
                display: "flex", alignItems: "center"
              }}>
              <div>{`${key}`}</div>
              <Checkbox 
                style={{marginLeft: "25px"}}
                label="props"
                onClick={(e: any) => {
                  e.preventDefault();
                  const currentFlag = props.obj.__meta.props.includes(key);
                  let newProps = props.obj.__meta.props;
                  const propsFlagKey = `formDef${props.dataKey}.__meta.props`
                  if (currentFlag) {
                    newProps = newProps.filter((name: any) => name !== key);
                  } else {
                    newProps.push(key);
                  }
                  props.setProp(propsFlagKey, newProps);
                }}
                checked={props.obj.__meta.props.includes(key)}
              />
              {!["object", "array"].includes(type(props.obj[key])) &&
              <div style={{ marginLeft: "20px"}}>
                {`(${type(props.obj[key])})`}
              </div>}
            </div>
            <RenderEditor
              onEvent={props.onEvent}
              setProp={props.setProp}
              openDataKey={props.openDataKey}
              setOpenDataKey={props.setOpenDataKey}
              first={false}
              last={false}
              obj={props.obj[key]} 
              parentKey={key}
              dataKey={`${props.dataKey}.${key}`}
            />
          </div>
          
        </div>)}
    </div>}
  </div>
  )
}

const ItemControlBar = (props: any) => {
  // const [openPanel, setOpenPanel] = useState(false);
  return(
    <div style={{display: "flex"}}>
      <div>{`${props.obj?.__meta?.type} ` 
            + `${props.obj?.__meta?.type === "TEXT" ? "(" + props.obj.text.trim().substring(0, 20) + "...)" : "" }`
            + `${props.obj?.__meta?.type === "COLUMNS" ? "(" +
                ((props.obj?.columns || {})?.__items || [])
                .map((item: any) => item?.text?.trim()?.substring(0, 10) + ".." || null)
                .filter((item: any) => item).join(",") + ")" : ""}` 
      }</div>
      <div style={{position: "relative"}}>
        <Icon
          name="paint brush"
          onClick={(e: any) => {
            e.stopPropagation();
            if (props.openDataKey === props.dataKey) 
              props.setOpenDataKey(null);
            else 
              props.setOpenDataKey(props.dataKey);
            // setOpenPanel(!openPanel)
          }}
        />
        {(props.openDataKey === props.dataKey) &&
        <div 
          style={{
            width: "170px", zIndex: 1000, minHeight: "70px",
            position: "absolute", top: 20, left: 0, fontSize: "0.9rem",
            backgroundColor: "lightblue", border: "solid grey 1px", flexWrap: "wrap",
            display: "flex", padding: "2px", overflowY: "auto"
          }}
          onClick={(e: any) => {
            e.stopPropagation();
          }}>
          {Formatter
          .filter((item: any) => (item.types.includes(props.obj?.__meta?.type))).length === 0 ?
          <div>No formatter for this component</div>
          :
          Formatter
          .filter((item: any) => (item.types.includes(props.obj?.__meta?.type)))
          .map((item: any, index: number) => (
            <div
              key={index}
              style={{
                padding: "2px", margin: "1px", backgroundColor: "white",
                border: "solid #dddddd 1px", flexWrap: "wrap"
              }}
              onClick={(e: any) => {
                console.log(item);
                let newObj = Object.assign({}, props.obj);
                newObj[item.key] = item.value;
                if (item.name === "Blank") {
                  newObj.__meta.props = 
                    newObj.__meta.props.filter((p: string) => p !== "text");
                }
                props.setProp(`formDef${props.dataKey}`, newObj);
                props.onEvent({message: "RefreshPDF", params: {}});
                // setOpenPanel(false);
              }}>
              {item.name}
            </div>
          ))
          }
        </div>}
      </div>
    </div>
  )
}

const Formatter = [
  { name: "Center", types: ["TEXT"], key: "alignment", value: "center" },
  { name: "Left", types: ["TEXT"], key: "alignment", value: "left" },
  { name: "Right", types: ["TEXT"], key: "alignment", value: "right" },
  { name: "Blank", types: ["TEXT"], key: "text", value: "" },
  { name: "Bold", types: ["TEXT"], key: "bold", value: "true" },
  { name: "f11", types: ["TEXT"], key: "fontSize", value: 11 },
  { name: "f15", types: ["TEXT"], key: "fontSize", value: 15 },
  { name: "f20", types: ["TEXT"], key: "fontSize", value: 20 },
  { name: "f30",  types: ["TEXT"], key: "fontSize", value: 30 },
  { name: "w20", types: ["TEXT"], key: "width", value: 20 },
  { name: "w50", types: ["TEXT"], key: "width", value: 50 },
  { name: "w150", types: ["TEXT"], key: "width", value: 150 },
  { name: "w200", types: ["TEXT"], key: "width", value: 200 },
  { name: "w250", types: ["TEXT"], key: "width", value: 250 },
]

const UpButton = (props: any) => (
  <div style={{position: "relative"}}>
    <button
      style={{
        backgroundColor: "yellow",
        border: "none"
      }}
      onClick={(e: any) => {
        e.stopPropagation();
        props.onEvent({ message: "GenPDF", params: {
          action: "UpRow",
          dataKey: props.dataKey,
        }});
      }}>
      &#8593;
    </button>
  </div>
)

const DownButton = (props: any) => (
  <div style={{position: "relative"}}>
    <button
      style={{
        backgroundColor: props.last ? "cyan": "yellow",
        color: props.last ? "cyan" : "black",
        border: "none"
      }}
      onClick={(e: any) => {
        if (props.last) { 
          e.stopPropagation(); 
          return
        }
        e.stopPropagation();
        props.onEvent({ message: "GenPDF", params: {
          action: "DownRow",
          dataKey: props.dataKey,
        }});
      }}>
      &#8595;
    </button>
  </div>
)

const RemoveButton = (props: any) => (
  <div style={{position: "relative"}}>
    <button
      style={{
        backgroundColor: "pink",
        border: "none"
      }}
      onClick={(e: any) => {
        e.stopPropagation();
        props.setRemoving(!props.removing);
      }}>
      x
    </button>
    {props.removing &&
    <div 
      style={{
        position: "absolute", right: 0, top: 20, zIndex: 1000,
        width: "200px", border: "solid grey 1px",
        backgroundColor: "pink"
      }}
      onClick={(e: any) => {
        e.stopPropagation();
        props.onEvent({ message: "GenPDF", params: {
          action: "RemoveRow",
          dataKey: props.dataKey,
        }});
        props.setRemoving(false);
      }}>
      Confirm removing
    </div>}
  </div>
)

const AddButton = (props: any) => (
  <div style={{position: "relative"}}>
    <button
      style={{
        backgroundColor: (props.parentKey === "array" && props.t === "array"
                          && (props.obj.__meta?.hide || false)
                         ) || (props.parentKey === "body") ? "yellow" : "lightblue",
        border: "none"
      }}
      onClick={(e: any) => {
        if ((props.parentKey === "array" && props.t === "array"
            && (props.obj.__meta?.hide || false))
            || (props.parentKey === "body")
        ) {
          e.stopPropagation();
          props.onEvent({ message: "GenPDF", params: {
            action: "AddArray",
            dataKey: props.dataKey + (props.parentKey === "body" ?  ".-1" : ""),
          }});
        } else {
          e.stopPropagation();
          props.setAdding(!props.adding);
        }  
      }}>
      +
    </button>
    {props.adding &&
    <div 
      style={{
        position: "absolute", right: 0, top: 20, zIndex: 1000,
        width: "200px", height: "200px", border: "solid black 1px",
        backgroundColor: "white"
      }}>
      {Object.entries(FormDef).map((item: any, formDefIndex: number) => (
      <div
        key={formDefIndex}
        onClick={(e: any) => {
          e.stopPropagation();
          props.onEvent({ message: "GenPDF", params: {
            action: "AddRow",
            dataKey: props.dataKey + (props.t === "array" ?  ".-1" : ""),
            formName: item[0],
            formProps: item[1]
          }});
          props.setAdding(false);
        }}>
        {item[0]}
      </div>))}
    </div>}
  </div>
)

export default RenderEditor


// const subtype = t === "object" ? 
//     (
//       Object.keys(props.obj).includes("text") ? "Text"
//       :
//       Object.keys(props.obj).includes("columns") ? "Columns"
//       :
//       props.parentKey === "images" ? "Images"
//       :
//       Object.keys(props.obj).includes("image") ? "Image"
//       :
//       Object.keys(props.obj).includes("table") ? "Table"
//       :
//       Object.keys(props.obj).includes("body") ? "Table Body"
//       :
//       Object.keys(props.obj).includes("font") ? "Font"
//       :
//       Object.keys(props.obj).includes("content") ? "Root"
//       :
//       Object.keys(props.obj).includes("type") ? "META"
//       :
//       Object.keys(props.obj).includes("__items") ? "Array Items"
//       :
//       "unknown type"
//     )
//     : 
//     ""