import React, { useEffect, useRef, useState } from 'react';
import { generateChart } from './SQL';
import ExploreDb from './ExploreDb';
import VegaEmbed from 'ismor-lib/utils/VegaEmbed';
import BokehDocChart from './BokehDocChart';
import IHTableAnalysis from './IHTableAnalysis';

let dataMenu = [
  {name: "IH data structure", url: "fk_ih.json", format: "json"},
  {name: "IH table analysis", url: "fk_ih_analysis.json", format: "json"},
  {name: "CNMI data structure", url: "fk.json", format: "json"},
  {name: "Table by rank", url: "fk.json", format: "json"},
  {name: "Table by module", url: "fk.json", format: "json"},
]

export default function DP(props:any) {
  const [selectedData, setSelectedData] = 
    useState<{name: string, url: string, format: string} | null>(dataMenu[0]);
  const [chartData, setChartData] = useState<any>(null);

  const loading = useRef(false);

  useEffect(() => {
    if (selectedData && dataMenu.map((item: any) => item.name).includes(selectedData.name) 
        && !loading.current) {
      setChartData(null);
      loading.current = true;
      props.onEvent({message: "DPGetData", params: {
        name: selectedData.name,
        url: selectedData.url,
        format: selectedData.format,
        loading: loading,
      }});
    } else if (selectedData?.name === "Master") {
      props.onEvent({message: "DPGetData", params: {
        name: selectedData.name,
        url: selectedData.url,
        format: selectedData.format,
        loading: loading,
      }});
    }
  }, [selectedData]);

  useEffect(() => {
    loading.current = false;
  }, [props.dpData]);

  useEffect(() => {
    if (props.bokehLoaded && props.duckLoaded
        && props.dpData && props.duckCon && selectedData
    ) {
      processChart()
    }
  }, [props.bokehLoaded, props.duckLoaded, props.dpData, props.dpDataName]);

  const processChart = async () => {
    try {
      await generateChart(props.duckCon, selectedData, setChartData);
    } catch (e: any) { console.log(e); }
  }

  return(
    <div style={{display: "flex", height: "95vh"}}>
      <div  
        style={{width: "150px", borderRight: "solid black 1px"}}>
        {dataMenu.map((item: any, index: number) =>
          <div 
            style={{
              padding: "0 5px 0 5px", cursor: "pointer",
              backgroundColor: selectedData?.name === item.name ? "#cccccc" : "white"
            }}
            onClick={(e: any) => {
              setSelectedData(item);
            }} 
            key={index}>
            {item.name}
          </div>
        )}
      </div>
      <div style={{flex: 1, height: "95vh", overflowY: "scroll"}}>
        {selectedData?.name === "IH data structure" ?
        <ExploreDb data={chartData} />

        : selectedData?.name === "IH table analysis" ?
        <IHTableAnalysis data={props.dpData} />
        
        : selectedData?.name === "CNMI data structure" ?
        <ExploreDb data={chartData} />

        : selectedData?.name === "Table by rank" ?
        <VegaEmbed data={chartData} />

        : selectedData?.name === "Table by module" ?
        <BokehDocChart chartData={chartData} />
        
        :
        <></>}
      </div>
    </div>
  )
}


// let arrowTable = tableFromArrays({ 
//   name: ["foo", "bar", "foo", "bar"],
//   amt: [1, 2, 3, 11],
// });    

// const p2 = Bokeh.Charts.pie(pie_data, {
//     inner_radius: 0.2,
//     start_angle: Math.PI / 2
// });
// const p3 = Bokeh.Charts.pie(pie_data, {
//     inner_radius: 0.2,
//     start_angle: Math.PI / 6,
//     end_angle: 5 * Math.PI / 6
// });
// const p4 = Bokeh.Charts.pie(pie_data, {
//     inner_radius: 0.2,
//     palette: "Oranges9",
//     slice_labels: "percentages"
// });
