import { WasmHandler } from 'react-lib/frameworks/WasmController'
import moment from "moment";
import app from 'firebase/compat/app';
import sizeOf from "buffer-image-size";

export type State = {
    webData?: any[]
}

export const StateInitial = {
    webData: [],
}

export type Event =
  { message: "LoadWebData", params: any }
  | { message: "SaveWebData", params: any }
  | { message: "AddWebPage", 
      params: { title: string, path: string, date: string, site: string } }

export type Handler = WasmHandler<State, Event>

export const LoadWebData: Handler = async (controller, params) => {
  let dbweb = (controller as any).dbweb as app.firestore.Firestore;
  let storageweb = (controller as any).storageweb as app.storage.Storage;
  const result = await dbweb.collection("webpage").get();
  const webData = result.docs
    .map((doc: any) => ({id: doc.id, ...doc.data()}));
  controller.setState({webData: webData});
  // MigrateImage(controller, {webData, dbweb, storageweb})
}

export const AddWebPage: Handler = async (controller, params) => {
  let { title, path, date, site } = params;
  if (!title || !path || !site) return console.log("Not sufficient params");
  let dbweb = (controller as any).dbweb as app.firestore.Firestore;
  const edited = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  let data = { 
    title, path, date: date || edited, edited, site, 
    items: [{ type: "md", md: title }]};
  const result = await dbweb.collection("webpage").add(data);
  console.log(result);
  let webData = controller.getState().webData;
  controller.setState(
    {
      webData: [
        ...(webData || []), 
        {
          id: result.id, 
          ...data
        }
      ]
    }, 
    () => params.setSelectedPageId(result.id)
  );
}

const MigrateImage: Handler = async (controller, params) => {
  let { webData, dbweb, storageweb } = params;
  let imageList = webData
                  .flatMap((page: any) => 
                    (page?.items || []).map((item: any) => ({...item, id: page.id})))
                  .filter((item: any) => item.type === "imagestorage");
  let item = imageList[3];
  console.log(item);

  const res = await fetch(item.src);
  const data = await res.arrayBuffer();

  // Reduce image size
  const originalImage = await new Promise(resolve => {
    const image = new Image();
    image.onload = async (event: any) => resolve(image)
    image.src = URL.createObjectURL(new Blob([data]));
  });

  let originalSize = sizeOf(Buffer.from(data));
  let targetWidth=300;
  const compact = document.createElement("canvas");
  compact.width = targetWidth;
  compact.height = originalSize.height / originalSize.width * targetWidth;
  compact.getContext("2d")?.drawImage(originalImage as CanvasImageSource, 0, 0, compact.width, compact.height);
  const blob = await new Promise(resolve => compact.toBlob(resolve));
  const arrayBuffer = await new Promise(resolve => {
    let reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsArrayBuffer(blob as Blob);
  });
  compact.remove();
  console.log(arrayBuffer);

  // // Save to cloud
  let parts = item.storageUrl.split(item.id);
  let fileName = parts[parts.length - 1];
  const thumbUrl = `gs://morweb/${item.id}/thumb${fileName}`
  console.log(thumbUrl);
  await storageweb.refFromURL(thumbUrl).put(arrayBuffer);
}

export const SaveWebData: Handler = async (controller, params) => {
  let dbweb = (controller as any).dbweb as app.firestore.Firestore;
  let storageweb = (controller as any).storageweb as app.storage.Storage;
  const {id, ...saveData} = params.webPage;
  if (id) {
    const edited = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    let newData = {...saveData, edited};
    let newDataItems: any[] = [];
    for (var i=0; i < newData.items.length; i++) {
      let item = newData.items[i];
      if (item.type === "imagestorage" && item.pendingUpload) {
        // Skip if file not uploaded
        if (!item.src.includes("blob"))
          continue;
        let {pendingUpload, fileData, ...itemData} = item;
        let imageToUpload: ArrayBuffer = await getImageArrayBufferForUpload(item.src);
        let storageUrl = `gs://morweb/${id}/${fileData.name}`;
        await storageweb.refFromURL(storageUrl).put(imageToUpload);
        itemData.storageUrl = storageUrl;
        itemData.src = await storageweb.refFromURL(storageUrl).getDownloadURL();
        newDataItems.push(itemData);
      } else {
        newDataItems.push(item);
      }
    }
    newData.items = newDataItems;
    await dbweb.collection("webpage").doc(id).set(newData);
    const state = controller.getState()
    if (state.webData) {
      const newWebData = state.webData.map((item: any) => (
        item.id === id ? {id: id, ...newData} : item
      ));
      controller.setState({webData: newWebData});
    }
  }
}

export const getImageArrayBufferForUpload: (src: string) => Promise<ArrayBuffer> 
= async (src: string) => {
  return await new Promise((resolve) => {
    var img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = async () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(img, 0, 0);
      const dataUrl = canvas.toDataURL()
      const result = await fetch(dataUrl);
      const data = await result.arrayBuffer();
      resolve(data);
    };
    img.src = src;
  });
}

// const MigrateImage: Handler = async (controller, params) => {
//   let { webData, dbweb, storageweb } = params;
//   console.log(webData.length);
//   for (var i=0; i < webData.length; i++) {
//     console.log(`processing ${webData[i].id}`)
//     let imageStorageCount = 0;
//     for (var itemIndex=0; itemIndex < webData[i].items.length; itemIndex++) {
//       let item = webData[i].items[itemIndex];
//       if (item.type === "imagebylink") {
//         if (item.src.substring(0, 11) === "/wp-content") {
//           imageStorageCount += 1;
//           try {
//             let imageToMove: ArrayBuffer = await getImageArrayBufferForUpload(item.src);
//             let storageUrl = `gs://morweb/${webData[i].id}${item.src}`;
//             await storageweb.refFromURL(storageUrl).put(imageToMove);
//             webData[i].items[itemIndex].storageUrl = storageUrl;
//             webData[i].items[itemIndex].type = "imagestorage";
//             // return;
//           } catch (e: any) { console.log(e); }
//         } else {
//           console.log(`skip: ${item.src}`)
//         }
//       } else if (item.type === "imagestorage") {
//         imageStorageCount += 1;
//         webData[i].items[itemIndex].src = await storageweb.refFromURL(item.storageUrl).getDownloadURL() ;
//       }
//     }
//     if (imageStorageCount > 0) {
//       dbweb.collection("webpage").doc(webData[i].id).update({
//         items: webData[i].items
//       })
//     }
//   }
//   console.log("Finished");
// }
