export const addReadOnlyProp = (
  screenData: any, 
  setProp: (key: string, value: any) => void
) => {
  console.log(screenData);
  for (var item of screenData.elements) { 
    if (item.name === "Dropdown") {
      if (item.props?.disabled) {
        console.log("Dropdown disabled exists");
      } else {
        item.props.disabled = { type: 'code', value: 'props.staffReadOnly'}
      } 
    } else if (item.name === "Radio") {
      if (item.props?.disabled) {
        console.log("Radio disabled exists");
      } else {
        item.props.disabled = { type: 'code', value: 'props.staffReadOnly'}
      } 
    } else if (item.name === "Input") {
      if (item.props?.readOnly) {
        console.log("Input readOnly exists");
      } else {
        item.props.readOnly = { type: 'code', value: 'props.staffReadOnly'}
      }
    } else if (item.name === "Checkbox") {
      if (item.props?.readOnly) {
        console.log("Checkbox readOnly exists")
      } else {
        item.props.readOnly = { type: 'code', value: 'props.staffReadOnly'}
      } 
    }
  }
  setProp("screenData", screenData);
}

// First transform function to correct grammatical errors
export const doMagic = (context: any) => {
  for (const item of context.props.screenData?.elements || []) {
    if (item.name === "Checkbox" && item?.props?.checked?.value) {
      console.log(item.name);
      console.log(item);
      if (!item.props.checked.value.includes("|| false")) {
        item.props.checked.value = item.props.checked.value + " || false"
      }
      item.props.onChange = {
        type: "code",
        value: `(e: any) => { props.setProp("${item.props.checked.value
                                                    .replace(" || false", "")
                                                    .replace("props?.", "")
                                                    .replace(/\?/g, "")}"` +
                `, !(${item.props.checked.value})); } `
      }
    } else if (item.name === "Radio" && item?.props?.value?.value) {
      console.log(item.name);
      console.log(item);
      console.log(item?.props?.value?.value);
    } else if (item.name === "Input" && item?.props?.value?.value) {
      console.log(item.name);
      console.log(item);
      console.log(item?.props?.value?.value);
      if (!item.props.value.value.includes('|| ""')) {
        item.props.value.value = item.props.value.value + ' || ""'
      }
      item.props.onChange = {
        type: "code",
        value: `(e: any, v: any) => { props.setProp("${item.props.value.value
                                                    .replace(' || ""', "")
                                                    .replace("props?.", "")
                                                    .replace(/\?/g, "")}"` +
                `, v.value); } `
      }
    } else if (item.name === "Dropdown" && item?.props?.value?.value) {
      console.log(item.name);
      console.log(item);
      console.log(item?.props?.value?.value);
      item.props.options = {
        type: "code",
        value: '[{ key: "1", value: "1", text: "ตัวเลือก 1"}]'
      }
      if (!item.props.value.value.includes('|| ""')) {
        item.props.value.value = item.props.value.value + ' || ""'
      }
      item.props.onChange = {
        type: "code",
        value: `(e: any, v: any) => { props.setProp("${item.props.value.value
                                                    .replace(' || ""', "")
                                                    .replace("props?.", "")
                                                    .replace(/\?/g, "")}"` +
                `, v.value); } `
      }
    }
  }
}