import { Button } from "semantic-ui-react";
import { genLetter } from "./Utils";

export default function LetterForm(props: any) {
  return(
    <div style={{padding: "10px"}}>
      <div
        style={{cursor: "pointer"}}
        onClick={(e: any) => {
          props.setSelectedYm(null);
          props.setOpenLetter(false);
        }}>
        &lt;&lt;&nbsp;Back
      </div>
      <div>{props.selectedYm}</div>
      <div>
        <textarea
          style={{width: "650px"}} rows={10}
          value={props.letter?.pre || ""}
          onChange={(e: any) => {
            props.setLetter({...props.letter, pre: e.target.value});
          }}
        />
      </div>
      <div>
        <textarea
          style={{width: "650px"}} rows={10}
          value={props.letter?.post || ""}
          onChange={(e: any) => {
            props.setLetter({...props.letter, post: e.target.value});
          }}
        />
      </div>
      <Button size="mini" color="blue"
        onClick={(e: any) => {
          props.onEvent({message: "SaveStaffLetter",
            params: {ym: props.selectedYm, letter: props.letter}});
        }}>
        Save
      </Button>
      <Button size="mini" color="green"
        onClick={(e: any) => {
          genLetter(props.letterDict, props.yearSum, props.selectedYm, ["stock","dividend"]);
        }}>
        Download
      </Button>
    </div>
  )
}
  