import React from 'react'
import { Icon } from 'semantic-ui-react'
import { CreateUIv2Event } from './CreateUIv2'
import { Element } from './CreateUIv2Jsx'
import UxDef, { Ux } from './UxDef'
import { Tx, collectNodeTree } from './TxDef'

export const renderTree = (screenData: any, onEvent: any, openEditor: any, 
        uxToAdd: Ux | null, txToAdd: Tx | null, action: string, 
        editedElementId: number | null, setAction: any, tempTx: any) => {
    if (screenData.elements && Object.keys(screenData.elements).length > 0) {
        try {
            return renderElement(screenData.elements, 0, [], '', true, onEvent, 
                openEditor, uxToAdd, txToAdd, action, editedElementId, setAction, 
                tempTx)
        } catch {
            return React.createElement('div')
        }
    }
}

const renderElement = (
        elements: Element[], 
        id: number, output: any[], 
        indent: string, 
        lastChild: boolean, 
        onEvent: (e: CreateUIv2Event) => void, 
        openEditor: any, 
        uxToAdd: Ux | null, 
        txToAdd: Tx | null,
        action: string,
        editedElementId: number | null, 
        setAction: any,
        tempTx: any) => {
    const createComponent = (node: Element) => {
        const tx = {
            name: "New Component",
            tree: collectNodeTree(node, elements)
        };
        tempTx(tx)
    }
    try {
        const node = elements.filter((elem: any) => elem.id ===id)[0]
        output.push(renderElementItself(node, indent, lastChild, onEvent, 
            openEditor, action, uxToAdd, txToAdd, editedElementId, 
            setAction, createComponent))
        const children = elements.filter((elem: any) => elem.parent === id)
            .sort((a, b) => (a.seq - b.seq))
        const newIndent = indent + (lastChild ? ' ' : '|') + guider
        for (var i=0; i < children.length; i++) {
            if (i < children.length - 1) {
                output = renderElement(elements, children[i].id, output, 
                    newIndent, false, onEvent, openEditor, uxToAdd, txToAdd, action,
                    editedElementId, setAction, tempTx)
            } else {
                output = renderElement(elements, children[i].id, output, 
                    newIndent, true, onEvent, openEditor, uxToAdd, txToAdd, action,
                    editedElementId, setAction, tempTx)
            }
        }
        return output
    } catch {
        return output
    }
}

const renderElementItself = (node: Element, indent: string, lastChild: boolean, 
    onEvent: (e: CreateUIv2Event) => void, openEditor: any,
    action: string, uxToAdd: Ux | null, txToAdd: Tx | null, editedElementId: number | null, 
    setAction: any, createComponent: any) => (
    <div 
        key={node.id} 
        style={{ 
            cursor: "pointer", 
            display: "flex", 
            alignItems: "center",
            border: (typeof editedElementId === 'number' && node.id === editedElementId) ? 
                    "solid red 1px" : "none"
        }}>
        <div
            onClick={
                action === "Delete" ?
                (e: any) => {
                    onEvent({ 
                        message: "RemoveElementAndChildren",
                        params: { id: node.id }
                    }) 
                }:
                
                action === "Add" && uxToAdd ?
                (e: any) => {
                    onEvent({ 
                        message: "AddChildElement",
                        params: { 
                            parent: node.id,
                            ux: uxToAdd
                        }
                    })
                }:

                action === "AddTx" && txToAdd ?
                (e: any) => {
                    onEvent({ 
                        message: "AddTxElement",
                        params: { 
                            parent: node.id,
                            tx: txToAdd
                        }
                    })
                }:
    
                action === "Move" ?
                (e: any) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onEvent({
                        message: "ChangeParent",
                        params: {
                            parent: node.id,
                        }
                    })
                    // Reset action
                    setAction("")
                }:
    
                (e: any) => {
                    onEvent({
                        message: "SetEditedElementId",
                        params: {
                            id: node.id
                        }
                    })
                    openEditor()
                }
            }
            onDragOver={(e) => {
                e.preventDefault()
            }}
            onDrop={(e) => {
                e.preventDefault()
                console.log("drop")
                const ux = e.dataTransfer.getData("text")
                if (ux) {
                    onEvent({
                        message: "AddChildElement",
                        params: { 
                            parent: node.id,
                            ux: UxDef[ux]
                        }
                    })
                }
            }}
        >
            {indent + (lastChild ? "'" : '|') + connector + 
             node.name + 
             ` [id:${node.id}] (${node.props?.children?.value || ""})` +
             "\u00a0"}
        </div>
        
        <NodeIcon name='chevron up' onClick={(e: any) => { 
            onEvent({ 
                message: "SwapChildren",
                params: { 
                    up: true,
                    id: node.id,
                    parent: node.parent
                }
            }) 
        }}/>
        <NodeIcon name='chevron down' onClick={(e: any) => { 
            onEvent({ 
                message: "SwapChildren",
                params: { 
                    up: false,
                    id: node.id,
                    parent: node.parent
                }
            }) 
        }}/>
        <NodeIcon name='bookmark' onClick={(e: any) => { 
            createComponent(node)
        }}/>
    </div>
)

const NodeIcon = (props: any) => (
    <Icon 
        name={props.name} 
        size='small' 
        onClick={props.onClick}
        style={{ marginLeft: "5px"}}
    />
)

const guider = '\u00a0'.repeat(10)
const connector = '-'.repeat(5)

{/* <NodeIcon name='plus' onClick={(e: any) => { 
    onEvent({ 
        message: "AddChildElement",
        params: { 
            parent: node.id,
            ux: UxDef['Segment']
        }
    }) 
}}/>
<NodeIcon name='trash' onClick={(e: any) => { 
    onEvent({ 
        message: "RemoveElementAndChildren",
        params: { id: node.id }
    }) 
}}/> */}