import { useEffect, useState } from "react";
import { query } from "ismor-lib/utils/DuckLib";
import VegaEmbed from "ismor-lib/utils/VegaEmbed";
import { toUser } from "ismor-lib/utils/Utils";
import { ConsoleLogger } from "@duckdb/duckdb-wasm";

export default function TestReview(props: any) {
  const [view, setView] = useState("Chart Summary");
  const [testDataReady, setTestDataReady] = useState<string|null>(null);
  const [rawData, setRawData] = useState<any>(null);
  const [displayData, setDisplayData] = useState<any>(null);
  const [staffData, setStaffData] = useState<any>(null);
  const [staffList, setStaffList] = useState<string[]>([]);
  const [xdDict, setXdDict] = useState<any>({});
  const [dataFilter, setDataFilter] = useState<any>(null);
  
  useEffect(() => {
    props.onEvent({message: "GetXDMaster", params: {}});
    props.onEvent({message: "GetXdTestSummary", params: {
      all: true, setDataReady: setTestDataReady}});
  }, []);

  useEffect(() => {
    if (testDataReady && props.duckCon 
        && Object.keys(props.xdmaster || {}).length > 0 
        && props.xdCases?.length > 0
    ) {
      try {
        prepareData(props.duckCon)
      } catch (e: any) { console.log(e) };
    }
  }, [testDataReady, props.duckCon, props.xdmaster, props.xdCases]);

  const prepareData = async (duckCon: any) => {
    let xdDict_ = Object.fromEntries(
      (props.xdmaster?.allXd || []).map((row: any) => [row.id, row.name]))
    setXdDict(xdDict_);
    
    setRawData(
      (await query(`
      from xdtestresult
      select
        datetime[:10] date,
        *
      ;`, 
      duckCon))
      .map((row: any) => ({
        ...row,
        xdName: xdDict_?.[row.xdId]
      })));
    
    let result = await query(`
      from xdtestresult 
      select 
        datetime[:10] date,
        xdId,
        caseName,
        count(distinct issueId) total
      group by date, caseName, xdId
      order by date desc, caseName
      ;`, 
      duckCon);    
    setDisplayData(result.map((row: any) => ({
      ...row, 
      xdName: xdDict_?.[row.xdId],
      total: parseInt(row.total)
    })));

    let staffList_ = (await query(`from xdtestresult select tester group by all`, duckCon))
      .map((item: any) => toUser(item.tester))
      .sort((a: string, b: string) => a < b ? -1 : 1);
    setStaffList(staffList_);

    let xdCaseDict = Object.fromEntries((props.xdCases || []).map((item: any) => [item.id, item]));

    let staffData_ = (await query(`
      with temp as (
        from xdtestresult 
        select 
          xdId,
          caseId,
          tester,
          datetime
        order by datetime
      )
      pivot temp
      on tester
      using last(datetime)
      group by xdId, caseId
      ;`, 
      duckCon))
      .filter((row: any) => row.caseId in xdCaseDict)
      .map((row: any) => (
        Object.fromEntries(
          Object.entries(row)
          .filter((entry: [string, any]) => entry[0] !== "xdId")
          .map((entry: [string, any]) => ([
              entry[0] === "caseId" ? "caseName" : toUser(entry[0]),
              entry[0] === "caseId" ? `[${xdDict_?.[row.xdId]}] ${xdCaseDict?.[entry[1]]?.name}` : entry[1]
          ]))
        )
        
      ))
      .sort((a: any, b: any) => a.caseName < b.caseName ? -1 : 1);
    setStaffData(staffData_);
  }

  return(
    <div style={{height: "95vh"}}>
      <div style={{height: "2.5vh", borderBottom: "solid grey 1px", display: "flex", alignItems: "flex-end"}}>
        <MenuItem view={view} setView={setView} name="Chart Summary"/>
        <MenuItem view={view} setView={setView} name="Latest Staff / Case"/>
        <MenuItem view={view} setView={setView} name="Table Summary"/>
      </div>
      {displayData &&  
      <div style={{height: "92.5vh", overflowY: "auto"}}>
        {view === "Chart Summary" ?
        <div style={{display: "flex"}}>
          <VegaEmbed
            data={{
              data: {values: displayData},
              title: "Test Summary by Daily Unique Issue Id Count",
              width: 600,
              height: 500,
              mark: "bar",
              selection: {pts: { type: "point"}},
              encoding: {
                x: { field: "date", type: "ordinal" },
                y: { field: "total", type: "quantitative"},
                color: { 
                  field: "xdName", 
                  type: "nominal",
                },
              }
            }}
            eventListener={{
              type: "click",
              handler: (event: any, item: any) => {
                if (dataFilter?.date === item.datum?.date && dataFilter?.xdName === item.datum?.xdName) {
                  setDataFilter(null);
                } else {
                  setDataFilter({
                    date: item.datum?.date,
                    xdName: item.datum?.xdName,
                  });
                }
              }
            }}
          />
          <div 
            style={{
              flex: 1, display: "flex",
              justifyContent: "center", alignItems: "center",
            }}>
            {dataFilter ?
            <div style={{height: "92.5vh", overflowY: "scroll", width: "100%"}}>
              {(rawData || [])
              .filter((row: any) => row.date === dataFilter.date && row.xdName === dataFilter.xdName)
              .sort((a: any, b: any) => a.datetime < b.datetime ? -1 : 1)
              .map((row: any, rowIndex: number) => {
                let tester = toUser(row.tester.split("@")[0])
                return (
                <div key={rowIndex} style={{display: "flex", width: "100%", borderBottom: "solid #cccccc 1px"}}>
                  <div style={{flex: 2}}>{row.datetime.substring(0, 16)}</div>
                  <div style={{flex: 1}}>{xdDict?.[row.xdId]}</div>
                  <div style={{flex: 2}}>{row.caseName}</div>
                  <div style={{flex: 2}}>{tester}</div>
                  <div style={{flex: 1}}>{row.issueId}</div>
                  <div style={{flex: 1}}>{row.status}</div>
                </div>)
              })}
            </div>
            :
            <div>Click bar chart to see detailed data</div>
            }
          </div>
        </div>
        
        : view === "Latest Staff / Case" ?
        <>
        <div style={{display: "grid", gridTemplateColumns: `150px repeat(${staffList.length}, 1fr)`, overflowY: "scroll"}}>
          {[""].concat(staffList).map((item: string, index: number) => 
            <div 
              key={`0_${index}`} 
              style={{border: "solid grey 1px", textAlign: "center"}}>
              {item}
            </div>)}
        </div>
        <div 
          style={{
            display: "grid", 
            gridTemplateColumns: `150px repeat(${staffList.length}, 1fr)`,
            height: "90vh", overflowY: "scroll"
          }}>
          {staffData.flatMap((row: any, rowIndex: number) => (
            [row.caseName].concat(staffList.map((staff: string) => row[staff]))
            .map((content: string, contentIndex: number) => 
              <div 
                key={`${rowIndex + 1}_${contentIndex}`} 
                style={{
                  border: "solid grey 1px", fontSize: "0.8rem",
                  textAlign: contentIndex === 0 ? "left" : "center"
                }}>
                {contentIndex === 0 ? content : content?.replace("T", " ")}
              </div>
            )
          ))}
        </div>
        </>
        : view === "Table Summary" ?
        (displayData || []).map((row: any, rowIndex: number) => (
        <div key={rowIndex} style={{display: "flex", width: "900px", borderBottom: "solid #cccccc 1px"}}>
          <div style={{flex: 1}}>{row.date}</div>
          <div style={{flex: 1}}>{xdDict?.[row.xdId]}</div>
          <div style={{flex: 1}}>{row.caseName}</div>
          <div style={{flex: 1, textAlign: "right"}}>{parseInt(row.total)}</div>
        </div>
        ))
        
        :
        <></>}
      </div>}     
    </div>
  )
}

function MenuItem(props: any) {
  return(
    <div
      style={{
        backgroundColor: props.view === props.name ? "lightgreen" : "white",
        cursor: "pointer", padding: "0 5px 0 5px"
      }}
      onClick={() => {
        props.setView(props.name);
      }}
      >
      {props.name}
    </div>
  )
}