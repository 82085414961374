import { Step, ExchangeDiagramData, Layout, View, StepView, EdgeView } from "./TypeDef";
import { sectionMargin, sectionTitle } from "./XdDetail";

export const xdToView: (xddata: ExchangeDiagramData, layout: Layout, foldedSections: number[]) => View 
= (xddata, layout, foldedSections) => {

  const gap = 10;
  const span = layout.span;

  const sectionLengths = (xddata?.sections || []).map(
    (section: any) => section?.steps?.length || 0);

  const sectionStartY = sectionLengths
    .map((len: number, sectionIndex: number) => 
      foldedSections.includes(sectionIndex) 
      ? sectionTitle 
      : sectionLengths[sectionIndex] * span + sectionMargin
    )
    .reduce((acc: any, cur: any, sectionIndex: number) => {    
      acc.push(acc[acc.length - 1] + cur);
      return acc
    }, [0]);

  const steps = (xddata?.sections || []).flatMap(
    (section: any, sectionIndex: number) => (
      section.steps.map((id: string, stepIndex: number) => (
        {
          ...xddata.steps[id],
          sectionIndex: sectionIndex,
          sectionName: section.name,
          stepIndex: stepIndex,
        }
      ))  
    )
  );

  const parentStep = steps.flatMap((item: Step, index: number) => (
    (item?.dependOn || []).map((dependOnItem: any) => ([dependOnItem, item.id]))
  ))
  .reduce((acc: any, cur: any) => {
    if (!(cur[0] in acc))
      acc[cur[0]] = []
    acc[cur[0]].push(cur[1])
    return acc;
  }, {});

  let stepDict = Object.fromEntries(steps.map((item: Step, index: number) => (
    [ item.id, 
      {
        id: item.id,
        x: 20,
        y: foldedSections.includes(item.sectionIndex!)  
            ? sectionStartY[item.sectionIndex!]
            : sectionStartY[item.sectionIndex!]
              + sectionMargin
              + item.stepIndex! * span
              + gap/2 - 3   
            ,
        hide: foldedSections.includes(item.sectionIndex!),
        width: (layout.width - 40),
        height: span - gap,
        name: item.description,
        step: item,
        sectionIndex: item.sectionIndex,
        sectionName: item.sectionName,
        stepIndex: item.stepIndex,
        seq: `${item.sectionIndex! + 1}.${item.stepIndex! + 1}`,
        dependOn: item.dependOn,
        nextSteps: item.dependOn,
        isRoot: !(item.id! in parentStep),
        parents: (item.id! in parentStep) ? parentStep[item.id!] : []
      }
    ]
  )));
  for (const id in stepDict) {
    for (const nextId of stepDict[id].nextSteps) {
      if (!(nextId in stepDict)) {
        console.log("edge problem from -> to", id, "->", nextId)
      }
    }
    stepDict[id].nextSteps = stepDict[id].nextSteps.length === 0 
      ? "Final" 
      : stepDict[id].nextSteps.map((nextId: string) => 
          (`${stepDict[nextId]?.sectionIndex + 1}.${stepDict[nextId]?.stepIndex + 1}`)
        ).join(", ")
  }

  let stepViews = Object.values(stepDict) as StepView[];

  const gapViews = stepViews
    .filter((item: StepView, index: number) => (!item.hide))
    .flatMap((item: StepView, index: number) => (
      [
        {
          x: 20,
          y: sectionStartY[item.sectionIndex] + sectionTitle + item.stepIndex! * span,
          width: layout.width - 40,
          height: (gap / 2) + 2,
          sectionIndex: item.sectionIndex,      
          stepIndex: item.stepIndex,
        }
      ].concat(item.stepIndex! === sectionLengths[item.sectionIndex!] - 1 ?
      [
        {
          x: 20,
          y: sectionStartY[item.sectionIndex] + sectionTitle + (item.stepIndex! + 1) * span,
          width: layout.width - 40,
          height: (gap / 2) + 2,
          sectionIndex: item.sectionIndex,      
          stepIndex: item.stepIndex + 1,
        }
      ]
      : 
      [])
    ))
    .concat(
      sectionLengths
        .map((len: number, sectionIndex: number) => ({len: len, sectionIndex: sectionIndex}))
        .filter((item: any) => item.len === 0)
        .map((item: any) => ({
          x: 20,
          y: sectionStartY[item.sectionIndex] + sectionTitle,
          width: layout.width - 40,
          height: (gap / 2) + 2,
          sectionIndex: item.sectionIndex,
          stepIndex: 1
        }))
    );

  const sectionViews = (xddata?.sections || []).map(
    (section: any, sectionIndex: number) => (
      {
        x: 0,
        y: sectionStartY[sectionIndex],
        width: layout.width - 0,
        height: foldedSections.includes(sectionIndex) ? sectionTitle 
                : sectionLengths[sectionIndex] * span + sectionMargin,
        name: section.name,
        inactive: section?.inactive || false,
        visibleSteps: stepViews.filter((item: any) => 
                        item.sectionIndex === sectionIndex && !item.hide).length
      }
    ));
  
  const edges = stepViews.flatMap((view: StepView) => Array.from(new Set(view.step.dependOn.concat(view.step?.dependOnOptional || []))));
  const edgeCount = edges.length;
  const space = (layout.width - 40) / (edgeCount + 1)
  let edgeViews: EdgeView[];
  const useEdgeFromXdData = true;
  
  if (!useEdgeFromXdData) {
    edgeViews = stepViews.flatMap((view: StepView) => (
      view.step.dependOn.map((id: string) => (
        {
          start:{ x: stepDict[id].x, y: stepDict[id].y, id: id },
          end:  { x: stepDict[id].x, y: view.y, id: view.step.id },
          optional: view.step?.dependOnOptional && view.step.dependOnOptional.includes(id),
          hide: view.hide && stepDict[id].hide,
        }  as EdgeView))
      ))
  } else {
    edgeViews = (xddata?.edges || [])
    .map((item: {from: string, to: string, optional: boolean}) => (
      {
        start:  { x: stepDict[item.to]?.x, y: stepDict[item.to]?.y, id: item.to },
        end:{ x: stepDict[item.from].x, y: stepDict[item.from].y, id: item.from },
        optional: item?.optional || false,
        hide: stepDict[item.from].hide && stepDict[item.to].hide,
      }  as EdgeView)
    )
  }

  edgeViews = edgeViews.map((edge: EdgeView, index: number) => (
    {
      start: { 
        x: edge.start.x + (index + 1) * space, 
        y: edge.end.y > edge.start.y ? edge.start.y + span - gap : edge.start.y + 1, 
        id: edge.start.id
      },
      end: {
        x: edge.end.x + (index + 1) * space, 
        y: edge.end.y > edge.start.y ? edge.end.y + 1 : edge.end.y + span - gap,
        id: edge.end.id
      },
      optional: edge.optional,
      hide: edge.hide,
    }
  ));

  stepViews = stepViews.map((step: StepView) => {
    const edges = edgeViews.filter((edge: EdgeView) => 
                    edge.start.id === step.id || edge.end.id === step.id
                  ).map((edge: EdgeView) => edge.start.x);
    let minX, maxX: number;
    if (edges.length === 0) {
      minX = 0;
      maxX = 0;
    } else {
      minX = Math.min(...edges);
      maxX = Math.max(...edges);
      if (edges.length === 1) {
        if (maxX > layout.width / 2) {
          minX = maxX - 50
        }
      }
    }
    return {
      ...step,
      minX: Math.max(20, minX - 5),
      slimWidth: maxX - minX + 10 < 50 ? 
                  50 
                  : Math.min(maxX - minX + 10, layout.width - 20 - minX + 5)
    }
  })
  
  return {
    stepViews,
    edgeViews,
    sectionViews,
    stepDict,
    gapViews,
    space
  }
}

export const getFromSteps = (stepId: string, stepDict: {[id: string]: StepView}) => {
  return Object.values(stepDict).filter(
    (item: any) => item.dependOn.includes(stepId)
  );
}

export const getStepLabelDict = (xddata: ExchangeDiagramData) => {
  let labelDict = Object.fromEntries(
    (xddata.sections || [])
    .flatMap((section: any, sectionIndex: number) => (
      (section.steps || [])
      .map((stepId: string, stepIndex: number) => (
        [
          stepId,
          `${sectionIndex + 1}.${stepIndex + 1}`,
        ]
      ))
    )));
  return labelDict;
}

// Deprecated -- use steps from collection xd instead ---------------------------------------------------
export const sortStepsForDisplay: 
  (steps: {[id: string]: Step}, id: string | null, output: Step[]) => Step[] 
= (steps, id, output) => {
  if (Object.values(steps).length === 0)
    return []
  const outputId = output.map((item: Step) => item?.id)
                         .filter((itemId: any) => itemId);
  let todo: Step[] = [];
  for (const step of Object.values(steps)) {
    if (step?.id) {
      if (outputId.includes(step.id))
        continue
      if (
        (id === null && step?.dependOn?.length === 0) 
        || (id !== null && step?.dependOn.includes(id))
      ) {
        output.push(step);
        todo.push(step);
      }
    }
  }
  for (const step of todo) {
    if (step?.id) {
      output = sortStepsForDisplay(steps, step.id || null, output);
    }
  }
  if (id === null) {
    let unProcessedId = getFirstUnprocessedId(steps, output);
    while (unProcessedId) {
      output.push(steps[unProcessedId]);
      output = sortStepsForDisplay(steps, unProcessedId, output);
      unProcessedId = getFirstUnprocessedId(steps, output);
    }
  }
  return output;
}

const getFirstUnprocessedId = (steps: {[id: string]: Step}, output: Step[]) => {
  const outputIdList = output.map((step: any) => step?.id)
                             .filter((stepId: any) => stepId);
  const unprocessedIdList = Object.keys(steps)
                                  .filter((id: string) => !outputIdList.includes(id));
  return unprocessedIdList.length > 0 ? unprocessedIdList[0] : null
}
