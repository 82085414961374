import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { query, create_data_table } from "ismor-lib/utils/DuckLib";
import { priority_count_sql, issue_count_sql, priority_resolved_sql1 } from "./SQL";

export default function PriorityStat(props: any) {
  const [dataReady, setDataReady] = useState(false);
  const [selectedChart, setSelectedChart] = useState("Resolved");
  const [priorityPerfList, setPriorityPerfList] = useState<any[]>([]);
  const chartRef = useRef<HTMLDivElement>(null);

  const priority_count = async () => {
    let result = await query(priority_count_sql, props.duckCon);    
    
    let Bokeh = (window as any).Bokeh;
    let bar_data = [["deadline", "priority_count"]]
      .concat(result.map((row: any) => Object.values(row)));
    const plt = Bokeh.Plotting;
    const p1 = Bokeh.Charts.bar(bar_data, {
      orientation: "horizontal", 
      palette: ["green"]
    });
    const doc = new Bokeh.Document();
    doc.add_root(plt.gridplot(
                    [[p1]],
                    {width: 900, height: 550}));
    Bokeh.embed.add_document_standalone(doc, chartRef.current);
  }

  const issue_count = async () => {
    let result = await query(issue_count_sql, props.duckCon);    
    
    let Bokeh = (window as any).Bokeh;
    let bar_data = [["deadline", "issue_count"]]
      .concat(result.map((row: any) => Object.values(row)));
    const plt = Bokeh.Plotting;
    const p1 = Bokeh.Charts.bar(bar_data, {
      orientation: "horizontal", 
      palette: ["green"]
    });
    const doc = new Bokeh.Document();
    doc.add_root(plt.gridplot(
                    [[p1]],
                    {width: 900, height: 550}));
    Bokeh.embed.add_document_standalone(doc, chartRef.current);
  }

  const data_table = async () => {
    let result = await query(priority_resolved_sql1, props.duckCon);
    let table = create_data_table(result, [
      "name", 
      "deadline_current",
    ]);
    let Bokeh = (window as any).Bokeh;
    const doc = new Bokeh.Document();
    doc.add_root(table);
    Bokeh.embed.add_document_standalone(doc, chartRef.current);
  }

  const resolved = async () => {
    let result = await query(priority_resolved_sql1, props.duckCon);
    let priorityPerfList_ = result.map((item: any) => {
      let {name, deadline_current, ...perf} = item;
      return { name, deadline_current, perf };
    });
    setPriorityPerfList(priorityPerfList_);
  }

  let chartList = [
    {name: "Resolved", func: resolved },
    {name: "Priority Count", func: priority_count },
    {name: "Issue Count", func: issue_count },
    {name: "Test", func: data_table },
  ];

  useEffect(() => {
    if (props.bokehLoaded && props.duckLoaded
        && props.priorityDict && props.issueLatestUpdates
        && props.dealOngoing
        && Object.keys(props.priorityDict).length > 0
        && Object.keys(props.issueLatestUpdates).length > 0
        && Object.keys(props.dealOngoing).length > 0
        && props.performanceDevRaw?.length > 0
    ) setDataReady(true);
  }, [props.bokehLoaded, props.duckLoaded, 
      props.priorityDict, props.issueLatestUpdates,
      props.dealOngoing, props.performanceDevRaw]);

  useEffect(() => {
    if (!selectedChart || !dataReady) return;
    chartList.find((chart: any) => chart.name === selectedChart)?.func();
  }, [selectedChart, dataReady]);

  return(
    <div style={{width: "100%", height: "100%"}}>
      {dataReady ?
      <div>
        <div style={{display: "flex", borderTop: "solid grey 1px", borderBottom: "solid grey 1px"}}>
          {chartList.map((chart: any, index: number) => (
          <div 
            key={index} 
            style={{
              paddingLeft: "5px", paddingRight: "5px", cursor: "pointer",
              borderRight: "solid grey 1px",
              backgroundColor: chart.name === selectedChart ? "lightgreen": "white"
            }}
            onClick={(e: any) => {
              if (!chartRef.current) return
              chartRef.current.innerHTML = "";
              setSelectedChart(chart.name);
            }}>
            {`${chart.name}`}
          </div>
          ))}
        </div>  
        <div style={{flex: 1, height: "85vh", overflowY: "auto"}}>
          <div ref={chartRef}></div>
          {selectedChart === "Resolved" &&
          <PerfTable 
            priorityPerfList={priorityPerfList} 
            devList={props.devList} 
          />}
        </div>
      </div>

      :

      <div 
        style={{
          width: "100%", height: "100%", display: "flex", 
          justifyContent: "center", alignItems: "center"
        }}>
        Preparing Data
      </div>}
    </div>
  )
}

const PerfTable = (props: any) => {
  const [ym, setYm] = useState(moment());
      
  return (
    <div>
      <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
        <span 
          onClick={(e: any) => {
            setYm(ym.clone().subtract(1, 'M'));
          }}
          style={{cursor: "pointer"}}>
          &lt;&lt;
        </span>
        <span style={{marginLeft: "10px", marginRight: "10px"}}>
          {ym.format('YYYYMM')}
        </span>
        <span 
          onClick={(e: any) => {
            setYm(ym.clone().add(1, 'M'))
          }}
          style={{cursor: "pointer"}}>
          &gt;&gt;
        </span>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `200px repeat(${props.devList.length}, 1fr)`
        }}>
        {[<div key="0_0" style={{border: "solid #cccccc 1px"}}></div>].concat(
          props.devList.map((dev: any, devIndex: number) => 
            <div 
              key={`0_${devIndex+1}`}
              style={{
                border: "solid #cccccc 1px",
                display: "flex",
                justifyContent: "center", alignItems: "center"
              }}>
              {dev.split("@")[0]}
            </div>)
        )}
        {props.priorityPerfList
          .filter((priority: any) => priority.deadline_current.substring(0, 6) === ym.format('YYYYMM'))
          .flatMap((priority: any, priorityIndex: number) => 
          [<div 
            key={`${priorityIndex+1}_0`}
            style={{border: "solid #cccccc 1px"}}>
            {`${priority.name} (${priority.deadline_current})`}
          </div>]
          .concat(
            props.devList.map((dev: string, devIndex: number) => (
              <div 
                key={`${priorityIndex+1}_${devIndex+1}`}
                style={{
                  border: "solid #cccccc 1px",
                  display: "flex",
                  justifyContent: "center", alignItems: "center"
                }}>
                {priority.perf[dev]}
              </div>
            ))
          ))
        }
      </div>
    </div>
  )
}
