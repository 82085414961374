import { useRef, useEffect } from "react";
import embed from "vega-embed";

export default function VegaEmbed(props: any) {
  const chartRef = useRef<any>(null);

  useEffect(() => {
    if (props.data) handleEmbed(chartRef.current, props.data);
  }, [props.data]);

  const handleEmbed = async (el: any, data: any) => {
    let res = await embed(chartRef.current, props.data);
    if (props.eventListener?.type && props.eventListener?.handler) {
      res.view.addEventListener(props.eventListener.type, props.eventListener.handler);
    }
  }
  
  return (
    <div>
      <div 
        id="myplot" ref={chartRef}
        style={{
          // height: "400px"
        }}>
      </div>
    </div>
  )
}
