import { WasmHandler } from 'react-lib/frameworks/WasmController'
import NestedMap from 'react-lib/frameworks/NestedMap'
import axios from "axios";
import app from "firebase/compat/app"


export type State = {
  ihUserProfile?: Map<string, any>,
  statList?: any[]
}

export const StateInitial = {
  ihUserProfile: new Map(),
  statList: []
}

export type Event =
  { message: "GetIHUserProfile", params: {} }
  | { message: "GetStatList", params: {} }

type Handler = WasmHandler<State, Event>

export const GetStatList: Handler = async (controller, params) => {
  const result = await controller.db.collection("stat").get()
  const data = result.docs
    .map((doc: app.firestore.DocumentSnapshot) => 
      ({docId: doc.id, ...doc.data()}))
    .filter((doc: any) => !doc.hide)
  controller.setState({statList: data});
}

export const GetIHUserProfile: Handler = async (controller, params) => {
  axios.get(
    `${controller.data.FASTAPI}/api/penta/ih_userprofile/`,
    {headers: { "Authorization": `Token ${await controller.user.getIdToken()}`}}
  )
  // controller.functions.httpsCallable("getIHUserProfile1")("")
  .then((result: any) => {
    console.log(result)
    let x = new NestedMap(result.data, [
      (row: any) => row.name ? `${row.name} (${row.division_id})` : "No division",
      (row: any) => `${row.username} (${row.id})`,
    ]);
    console.log(x.toMap());
    controller.setState({
      ihUserProfile: x.toMap()
    })
  }).catch((error: any) => {
    console.log(error)
  })

  // Test fastapi on cloud run
  // controller.user.getIdToken(true).then((idToken: any) => {
  //   console.log(idToken);
  //   axios.post("https://fastapi-jran5tfbba-de.a.run.app/firebase", { token: idToken })
  //   .then((res: any) => {
  //     console.log(res);
  //   })
  //   .catch((err: any) => {
  //     console.log(err);
  //   })
  // }).catch((err: any) => {
  //     alert(`Error ${err}`)
  // })
  
  // controller.functions.httpsCallable("getIHUserProfile1")().then((result: any) => {
  //   console.log("GCF")
  //   console.log(result)
  // }).catch((error: any) => {
  //   console.log(error)
  // })

}
