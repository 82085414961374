import React, { useEffect } from "react";
import Tab from "react-lib/frameworks/Tab";
import { Button } from "semantic-ui-react";

const Performance = (props: any) => {
  return(
    <Tab 
      data={[
        {
          key: "Latency past 10 mins",
          name: "Latency past 10 mins",
          render: () => (
            <ApiAnalysis
              onEvent={props.onEvent}
              setProp={props.setProp}
              apiAnalysisData={props.apiAnalysisData}
            />
          )
        }
      ]}
    />  
  )
}

const ApiAnalysis = (props: any) => {

  useEffect(() => {
    props.onEvent({"message": "GetApiAnalysisData", params: {}})
  }, [])
  
  console.log(props.apiAnalysisData);
  let now = new Date()
  let total_sum = props.apiAnalysisData.map((item: any) => item.sum)
                       .reduce((acc: number, cur: number) => acc + cur, 0)
  let total_count = props.apiAnalysisData.map((item: any) => item.count)
                       .reduce((acc: number, cur: number) => acc + cur, 0)
  let cum_sum = 0.0;
  let apiAnalysisData = props.apiAnalysisData.map((item: any) => {
    cum_sum += item.sum;
    return({ ...item, cum_sum: cum_sum})
  })

  return(
  <div 
    style={{ 
      height: "94vh", 
      overflowY: "scroll", 
    }}>
    
    {props.apiAnalysisData.length === 0 ? "Waiting to load data...":
    
    <div style={{ display: "flex", alignItems: "flex-start" }}>
      <div>
        {apiAnalysisData.map((item: any, item_index: number) => (
        <div 
          key={item_index}
          style={{ 
            display: "grid", 
            gridTemplateColumns: "500px 1fr 1fr 1fr 1fr 1fr", 
            borderBottom: "solid #dddddd 1px"
          }}>
          {item_index === 0 &&
            <div>API</div>}
          {item_index === 0 &&
            <div style={{marginLeft: "20px"}}>sum</div>}
          {item_index === 0 &&
            <div style={{marginLeft: "20px"}}>% total</div>}
          {item_index === 0 &&
            <div style={{marginLeft: "20px"}}>% total cum</div>}
          {item_index === 0 &&
            <div style={{marginLeft: "20px"}}>count</div>}
          {item_index === 0 &&
            <div style={{marginLeft: "20px"}}>avg</div>}
          {/* {item_index === 0 &&
            <div style={{marginLeft: "20px"}}>std</div>}
          {item_index === 0 &&
            <div style={{marginLeft: "20px"}}>max</div>} */}
          {item_index === 0 &&
            <div style={{marginLeft: "20px"}}></div>}
          {item_index === 0 &&
            <div style={{marginLeft: "20px"}}>secs/1 sec</div>}
          {item_index === 0 &&
            <div style={{marginLeft: "20px"}}></div>}
          {item_index === 0 &&
            <div style={{marginLeft: "20px"}}></div>}
          {item_index === 0 &&
            <div style={{marginLeft: "20px"}}>/1 sec</div>}
          {item_index === 0 &&
            <div style={{marginLeft: "20px"}}>secs/call</div>}
          {/* {item_index === 0 &&
            <div style={{marginLeft: "20px"}}>secs/call</div>}
          {item_index === 0 &&
            <div style={{marginLeft: "20px"}}>secs/call</div>} */}
          <div>{item.key}</div>
          <div style={{ marginLeft: "20px" }}>
            {(Math.round(item.sum * 100) / 100).toLocaleString()}
          </div>
          <div style={{ marginLeft: "20px" }}>
            {(Math.round(item.sum / total_sum * 100 * 100) / 100).toLocaleString()}
          </div>
          <div style={{ marginLeft: "20px" }}>
            {(Math.round(item.cum_sum / total_sum * 100 * 100) / 100).toLocaleString()}
          </div>
          <div style={{ marginLeft: "20px" }}>
            {(Math.round(item.count * 100) / 100).toLocaleString()}
          </div>
          <div style={{ marginLeft: "20px" }}>
            {(Math.round(item.avg * 100) / 100).toLocaleString()}
          </div>
          {/* <div style={{ marginLeft: "20px" }}>
            {(Math.round(item.std * 100) / 100).toLocaleString()}
          </div>
          <div style={{ marginLeft: "20px" }}>
            {(Math.round(item.max * 100) / 100).toLocaleString()}
          </div> */}
        </div>
        ))}
      </div>
      <div style={{ marginLeft: "50px" }}>
        <div>{`${now.toLocaleDateString()} ${now.toLocaleTimeString()}`}</div>
        <div>{`Total Sum: ${(Math.round(total_sum * 100) / 100).toLocaleString()}`}</div>
        <div>{`Total Count: ${(Math.round(total_count * 100) / 100).toLocaleString()}`}</div>
        <Button
          color="blue"
          onClick={(e: any) => {
            props.setProp("apiAnalysisData", []);
            props.onEvent({"message": "GetApiAnalysisData", params: {}});
          }}>
          Refresh
        </Button>
      </div>
    </div>
    }
  </div>)
}

export default Performance
