import { createRef, useEffect, useRef, useState } from "react";
import { Icon } from "semantic-ui-react";

export default function LevelPanel(props: any) {
  const [trigger, setTrigger] = useState(false);
  const [from, setFrom] = useState<{level: number, step: number}|null>(null);
  const [to, setTo] = useState<{level: number, step: number}|null>(null);
  
  const initialLoad = useRef(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<any>({});
  const imageUrl = useRef<any>({});

  useEffect(() => {
    initialLoad.current = false;
    imageRef.current = initImageDict(() => createRef<HTMLImageElement>());
    updateImage();
  }, [props.selectedIHGame, props.selectedGameId]);

  useEffect(() => {
    updateImage();
    if (props.selectedLevel !== null && props.selectedStep != null 
        && initialLoad.current && props.mode === "Edit"
    ) {
      imageRef.current?.[props.selectedLevel]?.[props.selectedStep]?.current
        ?.scrollIntoView({block: "center", inline: "nearest", behavior: "smooth"});
    }
  }, [props.mode]);

  const initImageDict = (initializer: () => any) => {
    return Object.fromEntries(
      (props.selectedIHGame?.levels || [])
      .map((level: any, levelIndex: number) => ([
        levelIndex,
        Object.fromEntries((level?.steps || [])
        .map((step: any, stepIndex: number) => (
          [stepIndex, initializer()]
        )))
      ]))
    );
  }

  const isVisible = (image: HTMLImageElement, container: HTMLDivElement) => {
    let { top, bottom } = container.getBoundingClientRect();
    let imageRect = image.getBoundingClientRect();
    let threshold = 200;
    return (
      (imageRect.top > top && imageRect.top < bottom)
      || (imageRect.bottom > top && imageRect.bottom < bottom)
      || (Math.abs(imageRect.top - bottom) < threshold)
      || (Math.abs(imageRect.bottom - top) < threshold)
    );
  }

  const updateImage = () => {
    let urlList = []
    for (var levelIndex = 0; levelIndex < props.selectedIHGame?.levels.length; levelIndex ++) {
      for (var stepIndex = 0; stepIndex < props.selectedIHGame?.levels?.[levelIndex]?.steps?.length; stepIndex ++) {
        let el: HTMLImageElement = imageRef.current?.[levelIndex]?.[stepIndex]?.current;
        let originalUrl = props.selectedIHGame?.levels?.[levelIndex]?.steps?.[stepIndex]?.screen?.url;
        if (el
            && isVisible(el, containerRef.current!) 
            && !(Object.keys(imageUrl.current).includes(originalUrl))
        ) {
          urlList.push(originalUrl);
        }
      }
    }
    if (urlList.length > 0) {
      getDownloadUrlList(urlList);
    } else {
      setTrigger(!trigger);
    }
  }

  const getDownloadUrlList = async (urlList: string[]) => {
    let downloadUrlList = await Promise.all(
      urlList
      .map(async (originalUrl: string) => {
        // console.log(`process image: ${originalUrl}`);
        imageUrl.current[originalUrl] = "/blankscreen.png";
        return await props.toDownloadUrl(originalUrl);
      })        
    );
    for (var i = 0; i < urlList.length; i++) {
      imageUrl.current[urlList[i]] = downloadUrlList[i];
    }
    setTrigger(!trigger);
  }
  
  return(
    <div 
      ref={containerRef}
      style={{
        borderRight: "solid #dddddd 1px", height: "100%",
        overflowY: "auto", padding: "5px",
      }}
      onScroll={() => updateImage()}>
      {["Edit", "AddScreen"].includes(props.mode) ?
      (props.selectedIHGame?.levels || [])
      .map((level: any, levelIndex: number) => {
        return (
          <div key={levelIndex}>
            <div
              style={{
                backgroundColor: props.selectedLevel === levelIndex && props.selectedStep === null ? "lightgreen" : "white", 
                padding: "3px", display: "flex", justifyContent: "space-between", alignItems: "center",
                fontWeight: "bold", fontSize: "1.4rem",
                borderBottom: to?.level === levelIndex && to.step === 0 ? "solid red 3px" : "solid grey 1px"
              }}
              onClick={(e: any) => props.selectLevelAndStep(levelIndex, null)}
              draggable
              onDragEnter={() => {
                if (!from) return console.log("'from' is not set");
                setTo({level: levelIndex, step: 0});
              }}
              onDragEnd={() => {
                if (from && to && (from.level !== to.level || from.step !== to.step)) {
                  props.onEvent({message: "MoveIHGameScreen", params: {from, to}});
                  props.selectLevelAndStep(to.level, to.step);
                }
                setFrom(null);
                setTo(null);
              }}
              >
              <div>
                {`Level ${levelIndex + 1}`}
              </div>
              <Icon
                name="play" size="small" color="green"
                disabled={level?.steps?.length <= 0}
                onClick={(e: any) => {
                  e.stopPropagation();
                  props.setStartLevel(levelIndex);
                  props.setMode("Play");
              }}/>  
            </div>
            <div>
              {((props.mode === "AddScreen" && props.toAddLevel === levelIndex && props.toAddStep === 0) ?
                [<img src="/blankscreen.png" style={{width: "100%"}}/>] : []
              ).concat(
                (level?.steps || [])
                .map((step: any, stepIndex: number) => { 
                  return (
                    <div key={stepIndex}>
                      <div
                        style={{
                          backgroundColor: levelIndex === props.selectedLevel && stepIndex === props.selectedStep ? "lightgreen": "white",
                          cursor: "pointer",
                          borderTop: from && to 
                                    && to.level === levelIndex && to.step === stepIndex && to.step !== 0
                                    && (levelIndex != from.level || (levelIndex === from.level && stepIndex < from.step)) 
                                    ? "solid red 3px" : "none",
                          borderBottom: from && to 
                                    && to.level === levelIndex && to.step === stepIndex 
                                    &&  (levelIndex === from.level && stepIndex > from.step)
                                    ? "solid red 3px" : "none",
                        }}
                        onClick={(e: any) => props.selectLevelAndStep(levelIndex, stepIndex)}
                        >
                        <div>{`${step?.guide?.substring(0, 30) || ""}${step?.guide?.length > 30 ? "..." : ""}`}</div>
                        <img 
                          onLoad={() => {
                            if (!initialLoad.current) {                        
                              initialLoad.current = true;
                              updateImage();
                            }
                          }}
                          ref={imageRef.current?.[levelIndex]?.[stepIndex]}
                          style={{width: "100%"}} 
                          // src={step?.screen?.url} 
                          src={imageUrl.current?.[step?.screen?.url] 
                              || "/blankscreen.png"} 
                          draggable={true}
                          onDragStart={(e: any) => {
                            setFrom({level: levelIndex, step:stepIndex})
                          }}
                          onDragEnter={() => {
                            if (!from) return console.log("'from' is not set");
                            setTo({level: levelIndex, step: stepIndex});
                          }}
                          onDragLeave={() => {
                          }}
                          onDragEnd={() => {
                            if (from && to && (from.level !== to.level || from.step !== to.step)) {
                              props.onEvent({message: "MoveIHGameScreen", params: {from, to}});
                              props.selectLevelAndStep(to.level, to.step);
                            }
                            setFrom(null);
                            setTo(null);
                          }}
                        />
                      </div>
                      {(props.mode === "AddScreen" && props.toAddLevel === levelIndex && props.toAddStep === stepIndex + 1) &&
                      <img src="/blankscreen.png" style={{width: "100%"}} />}
                    </div>
                  )
                })
              ).concat([
                // <div 
                //   key={level?.steps?.length}
                //   style={{cursor: "pointer", display: "flex"}}>
                //   {props.webviewReady && false && 
                //   // Close this button for now
                //   <div
                //     onClick={(e: any) => props.openAddFromVideoDialog(levelIndex)}>
                //     + video
                //   </div>}
                // </div>
              ])}
            </div>
          </div>
        )
      }).concat([
        <div
          key={(props.selectedIHGame?.levels || []).length}
          style={{cursor: "pointer"}}
          onClick={(e: any) => {
            props.onEvent({message: "IHGameAddLevel", params: {}});
          }}>
          + Level
        </div>
      ])
      
      : props.mode === "Grid" ?
      (props.selectedIHGame?.levels || [])
      .map((level: any, levelIndex: number) => (
        <div key={levelIndex}>
          <div style={{backgroundColor: "pink"}}>{`Level ${levelIndex + 1}`}</div>
          <div style={{display: "flex", flexWrap: "wrap"}}>
            {(level?.steps || []).map((step: any, stepIndex: number) => (
              <div 
                key={stepIndex}
                onClick={(e: any) => {
                  props.setMode("Edit");
                  props.selectLevelAndStep(levelIndex, stepIndex)
                }}>
                <div>{`${step?.guide?.substring(0, 30) || ""}${step?.guide?.length > 30 ? "..." : ""}`}</div>
                <img 
                  onLoad={() => {
                    if (!initialLoad.current 
                      && levelIndex === props.selectedIHGame?.levels?.length - 1
                      && stepIndex === level?.steps?.length - 1
                    ) {
                      initialLoad.current = true;
                      updateImage();
                    }
                  }}
                  ref={imageRef.current?.[levelIndex]?.[stepIndex]}
                  style={{width: "200px"}} 
                  src={imageUrl.current?.[step?.screen?.url] 
                      || "/blankscreen.png"}  
                />
              </div>
            ))}
          </div>
        </div>
      ))
      
      :
      <></>}
    </div>
  )
}
