import { useEffect, useState } from "react";
import { RedmineStatus } from "ismor-lib/utils/Constant";
import { Checkbox } from "semantic-ui-react";

const RedmineClassify = (props: any) => {
  const [byClassifyData, setByClassifyData] = useState<any[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [showFixed, setShowFixed] = useState(false);

  useEffect(() => {
    // props.onEvent({message: "GetRedmineIssueSummary", params: {}});
  }, []);

  useEffect(() => {
    let byClassifyData_: any[] = props.byClassifyData.map((project: any) => ({
      ...project,
      not_resolved: project.issues
                           .filter((issue: any) => issue.status_id !== 3)
                           .map((issue: any) => (
                            {
                              ...issue,
                              meta:`[${project.parent_name}` 
                                    + `->${issue.assigned_to || "not assigned"}]` 
                            }
                           ))
    }));
    byClassifyData_.unshift({
        parent_name: "All",
        parent_id: 0,
        not_resolved: byClassifyData_.flatMap((project: any) => 
            project.not_resolved.map((issue: any) => {
                let issue_ = Object.assign({}, issue);
                // issue_.subject = `[${project.parent_name}] ${issue_.subject}`
                return issue_
            })
        )
        .sort((a:any, b: any) => a.created_on_elapsed < b.created_on_elapsed ? 1 : -1)
    });
    setByClassifyData(byClassifyData_);
    setSelectedIndex(0);
  }, [props.byClassifyData]);
  
  return(
      props.byClassifyData.length === 0 ? <div>Loading data...</div> :
      <div 
          style={{ height: "90vh" }}>
          {selectedIndex !== null &&
          <RedmineProject 
            showFixed={showFixed}
            setShowFixed={setShowFixed}
            selector={
              <select
                style={{marginRight: "5px"}}
                value={selectedIndex}
                onChange={(e: any) => setSelectedIndex(e.target.value)}>
                {byClassifyData.map((item: any, index: number) => 
                  <option key={index} value={index}>
                    {`${byClassifyData[index].parent_name} (${byClassifyData[index].not_resolved.filter((issue: any) => showFixed ? true : issue.status_id !== 9).length})`}
                  </option>
                )}
              </select>
            }
            project={byClassifyData[selectedIndex]} 
            mobileScreen={props.mobileScreen} 
          />}
      </div>
  )
}

const Priority: { [key: number]: any } = {
    0: { name: "All", deadline: "n/a" },
    1: { name: "Low", deadline: 30 },
    2: { name: "Normal", deadline: 7 },
    3: { name: "High", deadline: 2 },
    4: { name: "Urgent", deadline: 1 },
    5: { name: "Immediate", deadline: 1 },
}

export const RedmineProject = (props: any) => {
    const [selectedPriorityIndex, setSelectedPriorityIndex] = useState<any>(null);
    const [issueByPriority, setIssueByPriority] = useState<any[]>([]);

    useEffect(() => {
        let issueByPriority_ = classify(props.project?.not_resolved || [], (issue) => issue.priority_id);
        let allPriority = { 
        name: 0, 
        issues: [] as any[], 
        issues_late: [] as any[], 
        issues_ongoing: [] as any[] 
        }
        issueByPriority_ = issueByPriority_.sort((a: any, b: any) => (a.name < b.name ? 1 : -1))
        .map((priority: any, priority_index: number) => {
            let issueByDeadline = classify(
            priority.issues, 
            (issue) => issue.created_on_elapsed >= Priority[priority.name].deadline ? 
                        "late" : "ongoing"
            );
            let late = issueByDeadline.filter((item: any) => item.name === "late");
            late = late.length > 0 ? late[0].issues : [];
            let ongoing = issueByDeadline.filter((item: any) => item.name === "ongoing");
            ongoing = ongoing.length > 0 ? ongoing[0].issues : [];
            priority.issues_late = late;
            priority.issues_ongoing = ongoing;
            allPriority.issues = allPriority.issues.concat(priority.issues);
            allPriority.issues_late = allPriority.issues_late.concat(late);
            allPriority.issues_ongoing = allPriority.issues_ongoing.concat(ongoing);
            return priority
        });
        issueByPriority_.unshift(allPriority);
        setIssueByPriority(issueByPriority_)
        setSelectedPriorityIndex(0);
    }, [props.project]);
    
    return(
        <div>
          <div style={{height: "2vh"}}>
            {props.selector}
            <select
                onChange={(e: any) => setSelectedPriorityIndex(e.target.value)}>
                {issueByPriority.map((priority: any, priorityIndex: number) => 
                <option 
                    key={priorityIndex} 
                    value={priorityIndex}>
                    {`${Priority[priority.name].name} (${priority.issues.filter((issue: any) => props.showFixed ? true : issue.status_id !== 9).length})`}
                </option>)}
            </select>
            <Checkbox 
                style={{marginLeft: "10px"}}
                label="แสดงรายการ Fixed"
                checked={props.showFixed}
                onClick={(e: any) => props.setShowFixed(!props.showFixed)}
            />
          </div>
          
          {selectedPriorityIndex !== null &&
          <RedminePriority 
              priority={issueByPriority[selectedPriorityIndex]} 
              mobileScreen={props.mobileScreen}
              showFixed={props.showFixed}
          />}
        </div>
    )
}

const RedminePriority = (props: any) => {
    return(
        <div 
            style={{ 
                height: props.mobileScreen ? "77vh" : "93vh", 
                display: "grid", 
                gridTemplateRows: "20px 1fr 20px 1fr" 
            }}>
            <div style={{ backgroundColor: "#81C32E", width: "100%" }}>
                {`Late >= ${Priority[props.priority.name].deadline} days (${props.priority.issues_late.filter((issue: any) => props.showFixed ? true : issue.status_id !== 9).length})`}
            </div>
            <div 
                style={{ 
                    overflowY: "scroll", 
                    height: "100%", 
                    borderBottom: "solid #cccccc 1px" 
                }}>
                {props.priority.issues_late
                .filter((issue: any) => props.showFixed ? true : issue.status_id !== 9)
                .map((issue: any, issue_index: number) => (
                    <div 
                        style={{
                            borderBottom: "solid #cccccc 1px",
                            padding: "5px",
                            cursor: "pointer",
                            backgroundColor: issue.status_id === 2 ? "#fee6c8" : "white"
                        }}
                        key={issue_index}
                        onClick={(e: any) => { 
                            window.open(
                                `https://redmine.thevcgroup.com/issues/${issue.issue_id}`,
                                "_blank"
                            );
                        }}>
                        <div style={{ fontWeight: "bold", color: "green" }}>
                          {`${issue.issue_id}: ${issue.meta} (${issue.created_on_elapsed} days) ${RedmineStatus?.[issue.status_id]}`}
                        </div>
                        <div>
                          {`${issue.subject}`}
                        </div>
                    </div>
                ))}
            </div>
            <div style={{ backgroundColor: "#81C32E", width: "100%" }}>
                {`Ongoing (${props.priority.issues_ongoing.length})`}
            </div>
            <div 
                style={{ 
                    overflowY: "scroll", 
                    height: "100%", 
                    borderBottom: "solid #cccccc 1px"
                }}>
                {props.priority.issues_ongoing
                .filter((issue: any) => props.showFixed ? true : issue.status_id !== 9)
                .map((issue: any, issue_index: number) => (
                    <div 
                        style={{
                            borderBottom: "solid #cccccc 1px",
                            padding: "5px",
                            cursor: "pointer",
                            backgroundColor: issue.status_id === 2 ? "#fee6c8" : "white"
                        }}
                        key={issue_index}
                        onClick={(e: any) => { 
                            window.open(
                                `https://redmine.thevcgroup.com/issues/${issue.issue_id}`,
                                "_blank"
                            );
                        }}>
                        <div style={{ fontWeight: "bold", color: "green" }}>
                          {`${issue.issue_id}: ${issue.meta} (${issue.created_on_elapsed} days) ${RedmineStatus?.[issue.status_id]}`}
                        </div>
                        <div>
                          {`${issue.subject}`}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

const classify = (issues: any[], classifier: (item: any) => string) => {
    let result = new Map();
    for (const issue of issues) {
        const classified = classifier(issue);
        if (!result.get(classified)) {
            result.set(classified, []);
        }
        result.get(classified).push(issue)
    }
    let output = Array.from(result, ([name, value]) => ({ name: name, issues: value }));
    return output
}

export default RedmineClassify