import React from 'react'
import TxDef from './TxDef'

const TxSelector = (props: any) => (
    <div 
        style={{ 
            position: "absolute", 
            top: 30,
            right: 500,
            zIndex: 10002,
            backgroundColor: "white",
            width: "300px", 
            height: "300px",
            border: "solid black 1px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignContent: "flex-start" 
        }}>
            {Object.keys(TxDef).map((tx, index) => (
            <div 
                key={index}
                draggable={true}
                onDragStart={(e) => { 
                    console.log("ondragstart")
                    e.dataTransfer.setData("text", tx) 
                }}
                style={{ 
                    margin: "2px", 
                    border:"solid #cccccc 1px",
                    lineHeight: "30px",
                    height: "30px",
                    padding: "2px",
                    cursor: "pointer"
                }}
                onClick={(e: any) => {
                    props.setTxToAdd(TxDef[tx])
                }}>
                {tx}
            </div>
            ))}
    </div>
)

export default TxSelector
