import { createElement, useEffect, useState } from "react";
import { Button } from "semantic-ui-react";

export default function Story(props: any) {
  const [items, setItems] = useState<any[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number|null>(null);
  const [openMenu, setOpenMenu] = useState<string|null>(null);
  const [StoryItemDef, setStoryItemDef] = useState<any>({});
  const [unsaved, setUnsaved] = useState(false);
  const [mode, setMode] = useState("Edit");
  const [selectedMaster, setSelectedMaster] = useState<string|null>(null);
  const [masterList, setMasterList] = useState<any[]>([]);
  const [imageSearchKeyword, setImageSearchKeyword] = useState("");
  const [searching, setSearching] = useState(true);

  useEffect(() => {
    props.onEvent({message: "GetStoryMaster", params: {}});
  }, [])

  useEffect(() => {
    if (props.selectedStory && props.selectedStory?.items)
      setItems(props.selectedStory.items);
    if (props.storyMaster)
      setStoryItemDef(props.storyMaster);
    let nameList = Object.entries(props.storyMaster)
      .filter((entry: [string, any]) => !entry[1]?.custom)
      .map((entry: [string, any]) => entry[0]);
    if (nameList.length > 0) setSelectedMaster(nameList[0]);
  }, [props.selectedStory, props.storyMaster]);

  useEffect(() => {
    if (selectedMaster && (selectedMaster in StoryItemDef)) {
      const def = StoryItemDef[selectedMaster].propDef || {}
      const keys = Object.keys(def);
      const _masterList = getMasterList(
        def,
        [],
        keys[0],
        keys.slice(1)
      );
      setMasterList(_masterList);
    }
  }, [selectedMaster, StoryItemDef]);

  useEffect(() => {
    setSearching(false);
  }, [props.imageSearchList]);

  const getMasterList: (propDef: any, accList: any[], curKey: string, remainingKeys: string[]) => any[] 
    = (propDef, accList, curKey, remainingKeys) => {
    const curList = propDef[curKey].choices.map((choice: string) => ({[curKey]: choice}));
    if (accList.length === 0)
      return getMasterList(propDef, curList, remainingKeys[0], remainingKeys.slice(1));
    const newAccList = curList.flatMap((curItem: any) =>
      accList.map((accItem: any) => ({...accItem, ...curItem}))
    );
    if (remainingKeys.length === 0)
      return newAccList
    return getMasterList(propDef, newAccList, remainingKeys[0], remainingKeys.slice(1));
  }
  
  const menuDict = Object.entries(StoryItemDef).reduce((acc: any, entry: any) => {
    if (!(entry[1].group in acc)) acc[entry[1].group] = { sub: [] };
    acc[entry[1].group].sub.push({name: entry[0]});
    return acc
  }, {});
  
  const menuData = Object.entries(menuDict)
    .map((entry: [string, any]) => ({ name: entry[0], sub: entry[1].sub }));

  const setItemAtIndex = (index: number) => 
    (value: any) => {
      const newItems = items.map((item: any, runIndex: number) => (
        runIndex === index ? value : item
      ));
      setItems(newItems);
      setUnsaved(true);
    }

  const addItem = (item: any) => {
    const startPosition = {x: 10, y: 10};
    if (!item?.name || !(item.name in StoryItemDef)) return;

    const def = StoryItemDef[item.name];
    if (!def.propDef) {
      setItems([...items, {...item, ...startPosition}])
      return
    };
    
    const newItem = {
      name: item.name,
      ...Object.fromEntries(
        Object.entries(def.propDef).map((entry: any) => ([entry[0], entry[1].default]))
      ),
      ...startPosition
    }
    setItems([...items, newItem]);
    setUnsaved(true);
  }

  const saveData = async () => {
    props.onEvent({message: "UpdateStory", params: {data: {items}}});
    setUnsaved(false);
  }

  return(
    <div 
      style={{ 
        height: "100%", borderBottom: "solid #aaaaaa 1px",
        display: "flex", flexDirection: "column", width: "100%"
      }}>
      <div style={{display: "flex", borderBottom: "solid #dddddd 1px"}}>
        <MenuItem mode={mode} setMode={setMode} name="Edit" />
        <MenuItem mode={mode} setMode={setMode} name="Search" />
        <MenuItem mode={mode} setMode={setMode} name="Extract" />
        <MenuItem mode={mode} setMode={setMode} name="Library" />
      </div>
      {mode === "Edit" ?
      <>
        <div 
          style={{
            display: "flex", cursor: "pointer", height: "30px", alignItems: "center",
            backgroundColor: selectedIndex === null ? "white" : "cyan"
          }}>
          {selectedIndex === null ?
          <>
            {menuData.map((menu: any, index: number) => (
              <StoryGroupMenuItem key={index} openMenu={openMenu} setOpenMenu={setOpenMenu} 
                menu={menu} addItem={addItem}/>
            ))}
            <div style={{flex: 1}}></div>
            {unsaved && <div style={{color: "red", marginRight: "10px"}}>There are unsaved changes.</div>}
            <Button 
              size="mini" color="green"
              onClick={(e: any) => saveData()}>
              Save
            </Button>
          </>
          :
          <>
            <PropMenu 
              index={selectedIndex}
              item={items[selectedIndex]}
              propDef={StoryItemDef[items[selectedIndex].name]?.propDef}
              setItem={setItemAtIndex(selectedIndex)}
            />
            <div style={{flex: 1}}></div>
            {unsaved && <div style={{color: "red", marginRight: "10px"}}>There are unsaved changes.</div>}
            <Button size="mini" color="blue"
              onClick={(e: any) => {
                saveData();
                setSelectedIndex(null);
              }}>
              Done & Save
            </Button>
          </>}
        </div>
        <svg 
          width="100%" height="100%"
          style={{border: "solid blue 1px"}}
          viewBox={`0 0 1200 400`}
          onClick={(event: any) => {
            const svg = event.currentTarget;
            const point = svg.createSVGPoint();
    
            // Get the location of the click relative to the SVG element
            point.x = event.clientX;
            point.y = event.clientY;
            const svgPoint = point.matrixTransform(svg.getScreenCTM().inverse());
    
            console.log(`Clicked at: (${svgPoint.x}, ${svgPoint.y})`);
          }}
          >
          {items.map((item: any, index: number) => { 
            const def = StoryItemDef[item.name]
            return(
            <g key={index} onClick={(e: any) => setSelectedIndex(index)}>
              <StoryDisplayItem 
                key={index} index={index} item={item} def={def} 
                setItemAtIndex={setItemAtIndex}
              />
            </g>)
          })}
        </svg>
      </>
      
      : mode === "Library" ?
      <>
        <div 
          style={{
            display: "flex", cursor: "pointer", height: "30px", alignItems: "center",
            backgroundColor: selectedIndex === null ? "white" : "cyan"
          }}>
          {Object.entries(StoryItemDef)
          .filter((entry: [string, any]) => !entry[1].custom)
          .map((entry: [string, any], index: number) => (
            <div 
              key={index}
              style={{backgroundColor: selectedMaster === entry[0] ? "#eeeeee": "white"}}>
              {entry[0]}
            </div>
          ))}
        </div>
        <div>
          {selectedMaster && (selectedMaster in StoryItemDef) &&
          masterList.map((item: any, index: number) => { 
            let candidates: any[] = []
            if (selectedMaster && selectedMaster in StoryItemDef) {
              candidates = StoryItemDef[selectedMaster].items.filter((candidate: any) => 
                Object.keys(item).reduce((acc: boolean, key: string) => {
                  return acc && item[key] === candidate[key]
                }, true)
              )
            }
            return(
              <div key={index} 
                style={{
                  display: "grid", gridTemplateColumns: "50% 50%", 
                  border: "solid black 1px", padding: "10px"
                }}>
                <div style={{display: "grid", gridTemplateColumns: "50% 50%"}}>
                {Object.entries(item).map((entry: [string, any], entryIndex: number) => (
                  <div key={entryIndex}>
                    {`${entry[0]}: ${entry[1]}`}
                  </div>)
                )}
                </div>
                {candidates.length > 0 ?
                <img style={{maxWidth: "50px", maxHeight: "50px"}} src={candidates[0].value} />
                : 
                <div>(Not found)</div>}
              </div>
            )
          })}
        </div>
      </>

      : mode === "Search" ?
      <div style={{padding: "15px"}}>
        <div style={{display: "flex", marginBottom: "10px"}}>
          <input 
            style={{flex: 1}}
            value={imageSearchKeyword} 
            onChange={(e: any) => setImageSearchKeyword(e.target.value)}
            onKeyUp={((e: any) => {
              if (e.key === "Enter") {
                props.onEvent({message: "SearchImage", 
                  params: {keyword: imageSearchKeyword}});
                setSearching(true);
              }
            })}
          />
        </div>
        <div style={{height: "100%", overflowY: "auto"}}>
          {searching ?
          <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
            Please wait while searching...
          </div>
          :
          props.imageSearchList.map((url: string, urlIndex: number) => (
            <img key={urlIndex} src={url} />
          ))}
        </div>
      </div>

      : mode === "Extract" ?
      <ExtractView
      />

      :
      <></>
      }
    </div>
  )
}

function MenuItem(props: any) {
  return(
    <div 
      style={{
        backgroundColor: props.name === props.mode ? "#dddddd" : "white", 
        padding: "5px", cursor: "pointer"
      }}
      onClick={(e: any) => props.setMode(props.name)}>
      {props.name}
    </div>
  )
}

function ExtractView(props: any) {
  const [svgSrc, setSvgSrc] = useState("");
  const [svgItems, setSvgItems] = useState<any[]>([]);
  const [inactiveList, setInactiveList] = useState<number[]>([]);

  useEffect(() => {
    let parser = new DOMParser();
    const htmlDoc = parser.parseFromString(svgSrc, "text/html");
    let svg = htmlDoc.getElementsByTagName("svg")?.[0];
    if (svg) {
      let items = [];
      for (const child of svg.children) {
        items.push({
          tagName: child.tagName,
          props: Object.fromEntries(
                  Array.from(child.attributes).map((attr: any) => [attr.name, attr.value]))
        })
      }
      setSvgItems(items);
      setInactiveList([]);
    }
  }, [svgSrc]);
  console.log(inactiveList);

  return(
    <div style={{display: "flex"}}>
      <div style={{flex: 1}}>
        <textarea 
          style={{width: "100%"}}
          rows={50}
          value={svgSrc}
          onChange={(e: any) => {
            setSvgSrc(e.target.value);
          }}
        />
      </div>
      <div style={{flex: 2}}>
        <svg 
          width="100%"
          style={{border: "solid blue 1px"}}
          viewBox={`0 0 2200 2200`}
          >
          {svgItems
            .map((item: any, index: number) => (
              createElement(
                item.tagName, 
                {
                  key: index,
                  ...item.props, 
                  stroke: "black",
                  onClick: (e: any) => {
                    console.log(item.props);
                    if (!inactiveList.includes(index)) {
                      setInactiveList([...inactiveList, index].sort());
                    }
                  }
                })
            ))
            .filter((item: any, index: number) => !inactiveList.includes(index))
          }
        </svg>
      </div>
    </div>
  )
}

function PropMenu(props: any) {
  const changeProp = (key: string, value: any) => {
    const newItem = Object.fromEntries(
      Object.entries(props.item).map((entry: [string, any], propIndex: number) => (
        entry[0] === key ? [entry[0], value] : [entry[0], entry[1]]
      ))
    );
    props.setItem(newItem);
  }

  return (
    props.propDef ?
    <div style={{display: "flex"}}>
      <div style={{fontWeight: "bold"}}>{props.item.name}</div>
      {Object.entries(props.item)
      .filter((entry: [string, any]) => entry[0] !== "name")
      .map((entry: [string, any], index: number) => (
        <div key={index} style={{marginLeft: "5px", display: "flex"}}>
          <div style={{marginRight: "5px"}}>{entry[0]}</div>
          {props.propDef[entry[0]]?.type === "choice" ?
          <select 
            value={entry[1]}
            onChange={(e: any) => changeProp(entry[0], e.target.value)}>
            {(props.propDef[entry[0]]?.choices || []).map((choice: any, choiceIndex: number) => (
              <option key={choiceIndex} value={choice}>{choice}</option>
            ))}
          </select>
          :
          props.propDef[entry[0]]?.type === "number" ?
          <input value={entry[1]} onChange={(e: any) => changeProp(entry[0], parseFloat(e.target.value))}/>
          :
          <div>{entry[1]}</div>
          }
        </div>
      ))}
    </div>
    :
    <></>
  )
}

function StoryGroupMenuItem(props: any) {
  return(
  <div style={{position: "relative"}}>
    <div 
      style={{padding: "3px", border: "solid gray 1px", height: "28px"}}
      onClick={(e: any) => props.setOpenMenu(props.openMenu === props.menu.name ? null : props.menu.name)}>
      {props.menu.name}
    </div>
    {props.openMenu === props.menu.name && 
    <div style={{position: "absolute", top: 27, backgroundColor: "white", width: "200px", border: "solid gray 1px", height: "200px"}}>
      {props.menu.sub.map((sub: any, subIndex: number) => (
        <div 
          key={subIndex}
          onClick={(e: any) => {
            props.addItem({...sub, x: 20, y: 20});
            props.setOpenMenu(false);
          }}>
          {sub.name}
        </div>
      ))}
    </div>}
  </div>)
}

function DraggableGroup(props: any) {
  const [origItem, setOrigItem] = useState<any>(null);
  const [offsetX, setOffsetX] = useState<number | null>(null);  
  const [offsetY, setOffsetY] = useState<number | null>(null);  

  useEffect(() => {
    setOrigItem(props.item);
  }, [props.item])

  return(
    <g
      onPointerDown={(e: any) => {
        setOffsetX(e.clientX - e.currentTarget.getBoundingClientRect().left);
        setOffsetY(e.clientY - e.currentTarget.getBoundingClientRect().top);
      }}
      onPointerMove={(e: any) => {
        if (offsetX !== null && offsetY !== null) {          
          const diffX = e.clientX - e.currentTarget.getBoundingClientRect().left - offsetX;
          const diffY = e.clientY - e.currentTarget.getBoundingClientRect().top - offsetY;
          if (Math.abs(diffX) > 1 || Math.abs(diffY) > 1) {
            props.setItemAtIndex(props.index)({
              ...props.item,
              x: Math.round(origItem.x + diffX) || 0,
              y: Math.round(origItem.y + diffY) || 0, 
            });
          }
        }
      }}
      onPointerUp={(e: any) => {
        setOffsetX(null);
        setOffsetY(null);
      }}>
      {props.children}
    </g>
  )
}

function StoryDisplayItem(props: any) {
  return(
    props.item.shape === "square" ?
    <DraggableGroup
      index={props.index}
      item={props.item}
      setItemAtIndex={props.setItemAtIndex}>
      <rect x={props.item.x} y={props.item.y} 
        width={props.item.size} height={props.item.size} fill="none" stroke="none"
      />
      <foreignObject
        x={props.item.x} y={props.item.y} width={props.item.size || 100} height={props.item.size || 100}>
        <div 
          style={{
            paddingTop: "10px", paddingLeft: "10px",
            width: "100%", height: "100%", backgroundColor: props.item.color, color: "white",
            fontWeight: "bold", cursor: "pointer", fontSize: "0.8em", display: "flex", 
            justifyContent: "space-between"
          }}>
          {props.item.name}
        </div>
      </foreignObject>
    </DraggableGroup>
    
    : props.item.shape === "circle" ?
    <DraggableGroup
    index={props.index}
    item={props.item}
    setItemAtIndex={props.setItemAtIndex}>
      <circle 
        cx={props.item.x + props.item.size / 2} 
        cy={props.item.y + props.item.size / 2 } 
        r={props.item.size / 2}
        fill={props.item.color}
      />
      <foreignObject
        x={props.item.x} y={props.item.y} width={props.item.size || 100} height={props.item.size || 100}>
        <div 
          style={{
            paddingTop: "10px", paddingLeft: "10px",
            width: "100%", height: "100%", color: "white",
            fontWeight: "bold", cursor: "pointer", fontSize: "0.8em", display: "flex", 
            justifyContent: "space-between"
          }}>
          {props.item.name}
        </div>
      </foreignObject>
    </DraggableGroup>
    
    : !props.def?.custom ?
    <DraggableGroup
      index={props.index}
      item={props.item}
      setItemAtIndex={props.setItemAtIndex}>
      <StoryDisplayItemSelect
        index={props.index}
        item={props.item}
        def={props.def}
      />
    </DraggableGroup>
    
    :
    <DraggableGroup
      index={props.index}
      item={props.item}
      setItemAtIndex={props.setItemAtIndex}>
      <foreignObject
        x={props.item.x} y={props.item.y} width={80} height={80}>
        <div 
          style={{
            paddingTop: "10px", textAlign: "center",
            width: "100%", height: "100%", backgroundColor: "red", color: "white",
            fontWeight: "bold", cursor: "pointer", fontSize: "0.8em",
            justifyContent: "space-between"
          }}>
          <div>{props.item.name}</div>
          <div>(Unknown)</div>
        </div>
      </foreignObject>
    </DraggableGroup>
  )
}

function StoryDisplayItemSelect(props: any) {  
  const displayItems = (props.def?.items || []).filter((defItem: any) => 
    Object.keys(props.def.propDef).reduce((acc: boolean, key: string) => {
      return acc && props.item[key] === defItem[key]
    }, true));
  
  return (
  <g>
    <foreignObject
      x={props.item.x} y={props.item.y} width={80} height={80}>
      {displayItems.length > 0 ? 
      <img draggable={false} style={{maxWidth: "100%", maxHeight: "100%"}} src={displayItems[0].value} /> 
      : 
      <></>}
    </foreignObject>
  </g>)
}
