import { useEffect, useRef, useState } from "react";
import XDCaseDetail from "./XDCaseDetail";
import { XdCase } from "./TypeDef";
import { issue_priority } from "./SQL"
import { query } from "ismor-lib/utils/DuckLib";
import { Button, Icon, Input, Modal } from "semantic-ui-react";

export default function XDCase(props: any) {
  const [dataReady, setDataReady] = useState(false);
  const [issueLatestUpdates, setIssueLatestUpdates] = useState<any>(null);
  const [addingCase, setAddingCase] = useState(false);
  const [editingCase, setEditingCase] = useState(false);
  const [newCaseName, setNewCaseName] = useState("");
  const [xdId, setXdId] = useState(null);
  const [selectedXdCaseId, setSelectedXdCaseId] = useState<string|null>(null);

  const loadingXdCaseList = useRef<boolean>(false);
  const loadingPriority = useRef(false);

  useEffect(() => {
    if (Object.keys(props.priorityDict).length === 0 && !loadingPriority.current) {
      loadingPriority.current = true;
      props.onEvent({message: "GetPriorityDict", params: {}});
    }
  }, []);

  useEffect(() => {
    if (loadingPriority.current) 
      loadingPriority.current = false;
  }, [props.priorityDict]);
  
  useEffect(() => {
    if ((!loadingXdCaseList.current && props.xdId && props.xdId === xdId) 
        || props.xdId !== xdId
    ) {
      setXdId(props.xdId);
      props.onEvent({message: "GetXdCaseList", params:{}});
      loadingXdCaseList.current = true;
    }
  }, [props.xdId]);

  useEffect(() => {
    loadingXdCaseList.current = false;
    if (props.xdCases.length > 0 ) {
      if (!selectedXdCaseId)
        setSelectedXdCaseId(props.xdCases[0].id);
    } else {
      setSelectedXdCaseId(null);
    }
  }, [props.xdCases]);

  useEffect(() => {
    if (props.duckLoaded
        && props.priorityDict && props.issueLatestUpdates && props.dealOngoing
        && Object.keys(props.priorityDict).length > 0
        && Object.keys(props.issueLatestUpdates).length > 0
        && Object.keys(props.dealOngoing).length > 0
    ) setDataReady(true);
  }, [props.duckLoaded, props.priorityDict, 
      props.issueLatestUpdates, props.dealOngoing]);

  useEffect(() => {
    if (!dataReady) return;
    (async () => {
      try {
        let result = await query(issue_priority, props.duckCon);
        setIssueLatestUpdates(
          Object.fromEntries(result.map((item: any) => [item.id, item])));
        setDataReady(false);
      } catch (e: any) {
        console.log(e);
        setIssueLatestUpdates(props.issueLatestUpdates);
        setDataReady(false);
      }
      
    })();
  }, [dataReady]);

  return(
    <div style={{display: "flex", height: "100%"}}>
      <div style={{width: "200px", borderRight:"solid grey 1px", height: "100%"}}>
        {props.xdCases
          .sort((a: any, b: any) => a?.caseIndex < b?.caseIndex ? -1 : 1)
          .map((xdCase: XdCase, index: number) => (
          <div 
            key={index}
            style={{
              padding: "2px", display: "flex",
              borderBottom: "solid #cccccc 1px",
              backgroundColor: selectedXdCaseId === xdCase?.id ? "#dddddd" : "white"
            }}
            onClick={() => {
              if (xdCase.id)
                setSelectedXdCaseId(xdCase.id)
            }}>
            <div>{xdCase?.name}</div>
            <div style={{flex: 1}}></div>
            <Icon 
              name="edit" 
              onClick={() => {
                if (xdCase.id) {
                  setEditingCase(true);
                  setSelectedXdCaseId(xdCase.id);
                  setNewCaseName(xdCase.name)
                }
              }}
            />
          </div>
        ))}
        <div 
          style={{padding: "2px", cursor: "pointer"}}
          onClick={() => {
            setAddingCase(true);
          }}>
          + Add Case
        </div>
      </div>
      <div style={{flex: 1}}>
        <XDCaseDetail 
          onEvent={props.onEvent}
          user={props.user}
          view={props.view}
          xdId={props.xdId}
          docInfo={props.docInfo}
          xddata={props.xddata}
          selectedXdCase={props.xdCases.find((xdCase: any) => xdCase.id === selectedXdCaseId)}
          priorityDict={props.priorityDict}
          dealOngoing={props.dealOngoing}
          issueLatestUpdates={issueLatestUpdates}
          xdProblemDict={props.xdProblemDict}
          issueTestResult={props.issueTestResult}
        />
      </div>
      <Modal
        size="mini"
        open={addingCase}
        onClose={() => {
          setAddingCase(false);
          setNewCaseName("");
        }}
        closeOnDimmerClick={true}
        closeIcon={true}>
        <div style={{padding: "10px"}}>
          <Input
            fluid
            placeholder="ชื่อ case"
            value={newCaseName}
            onChange={(e: any) => {
              setNewCaseName(e.target.value);
            }}
          />
          <Button
            color="red"
            onClick={() => {
              setAddingCase(false);
              setNewCaseName("");
            }}>
            Cancel
          </Button>
          <Button
            color="green"
            onClick={() => {
              setAddingCase(false);
              props.onEvent({message: "AddXdCase", params: {name: newCaseName}});
              setNewCaseName("")
            }}>
            Save
          </Button>
        </div>
      </Modal>
      <Modal
        size="mini"
        open={editingCase}
        closeIcon={true}
        closeOnDimmerClick={true}
        onClose={() => {
          setEditingCase(false);
          setNewCaseName("");
        }}>
        <div 
          style={{padding: "10px"}}>
          <Input
            fluid
            value={newCaseName}
            onChange={(e: any) => setNewCaseName(e.target.value)}
          />
          <Button
            color="red"
            onClick={() => {
              setEditingCase(false);
              setNewCaseName("");
            }}>
            Cancel
          </Button>
          <Button
            color="green"
            onClick={() => {
              setEditingCase(false);
              props.onEvent({message: "EditXdCase", params: {caseId: selectedXdCaseId, name: newCaseName}});
              setNewCaseName("");
            }}>
            Save
          </Button>
        </div>
      </Modal>
    </div>
  )
}