import { useEffect, useRef, useState } from 'react';
import { Button, Menu } from "semantic-ui-react";
import GoogleMap from 'google-map-react';

export default function HL(props:any) {
  const [mode, setMode] = useState<"List"|"Map">("List");

  useEffect(() => {
    props.onEvent({message: "HLGetList", params: {}});
  }, []);

  return(
    <div style={{height: "95vh", width: '100%', padding: "5px"}}>
      {mode === "Map" ?
        <GoogleMap
          bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY || "" }}
          center={{lat: 13.723034866404351, lng: 100.5235556563171 }}
          zoom={18}
          >
          {[
            { lat: 13.723034866404351, lng: 100.5235556563171, mapname: "Home" }
          ].map((item: any, index: number) => (
            <Marker
              key={index}
              lat={item.lat}
              lng={item.lng}
              text={item.mapname}
            />
          ))}
        </GoogleMap>
      : mode === "List" ?
        <div>
          {props.hospitalList.map((hospital: any, index: number) => (
            <div key={index}>
              {`${index + 1}. ${hospital.original_name}`}
            </div>
          ))}
        </div>
        :
        <></>
      } 
    </div>
  )
}

const Marker = ({lat, lng, text }: { lat: number, lng: number, text: string }) => {
  const width = text.length * 10 + 5;
  const height = 20;
  return <div 
    style={{
      backgroundColor: "white", border: "solid red 2px", fontSize: "1rem", fontWeight: "bold",
      position: "absolute", left: -width / 2, top: -height / 2, 
      width: width, height: height, textAlign: "center"
    }}>
    {text}
  </div>;
}
