import { WasmHandler } from 'react-lib/frameworks/WasmController'
import axios from "axios";
import { userInfo } from 'os';

type Handler = WasmHandler<State, Event>
const now = new Date();

// Spec ---------------------------------------------------------------------
export type State = 
  {
    selectedUser?: string,
    selectedYear?: number,
    selectedMonth?: number,
    perfData?: any[]
  }

export const StateInitial = 
  {
    selectedUser: "",
    selectedYear: now.getFullYear(),
    selectedMonth: now.getMonth() + 1,
    perfData: []
  }

export type Event = 
  { message: "GetPerfData", params: {} }


export const GetPerfData: Handler = async (controller, params) => {
  controller.setState({ perfData: [] });
  let state = controller.getState();
  let selectedUser = params.selectedUser ? params.selectedUser :
  state.selectedUser === "" ? controller.user.email: state.selectedUser;
  let selectedYear = params.selectedYear ? params.selectedYear : state.selectedYear;
  let selectedMonth = params.selectedMonth ? params.selectedMonth : state.selectedMonth;
  axios.get(
    `${controller.data.FASTAPI}/api/redmine/perf_map/`, 
    {
      params: {
        "login":  selectedUser,
        "year": selectedYear,
        "month": selectedMonth
      },
      headers: { 
        "Authorization": `Token ${await controller.user.getIdToken()}`
      }
    }
  )
  .then((result: any) => {
    const perfData = result.data
    .map((item: any, index: number) => {
      const ymd = item.date.split("-").map((d: string) => parseInt(d));
      return(
        {
          id: index,
          date: item.date,
          records_summary: item.records_summary,
          start: new Date(ymd[0], ymd[1] - 1, ymd[2], 0, 0, 0, 0),
          end: new Date(ymd[0], ymd[1] - 1, ymd[2], 23, 59, 0, 0),
        }
      )
    })
    controller.setState({ 
      perfData: perfData, 
      selectedUser: selectedUser,
      selectedYear: selectedYear,
      selectedMonth: selectedMonth
    });
  }).catch((error: any) => {
      console.log(error);
  });
}

