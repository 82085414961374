import React, { MouseEvent, useRef, useEffect, useState, RefObject, DragEvent, DragEventHandler } from "react";
import { Input, Button } from "semantic-ui-react";
import { getStyleDetails } from "./DivGridInterface";
import { m } from "./TxDef";

export const DivGridEditor = (props: any) => {    
    const [style, rows, cols] = getStyleDetails(props.editedElement);
    
    return(
    <div>
        {props.mode === "Play" ?
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ margin: "0 5px 0 5px" }}>Row</div>
                <Input 
                    size="mini" value={rows} 
                    onChange={(e: any) => {
                        if (isNaN(e.target.value)) return
                        props.onEvent({
                            message: "ChangeGridDimension",
                            params: { 
                                rows: parseInt(e.target.value), 
                                cols: cols,
                                editedElement: props.editedElement,
                            }
                        })
                    }} 
                />
                <div style={{ margin: "0 5px 0 5px" }}>Column</div>
                <Input 
                    size="mini" value={cols} 
                    onChange={(e: any) => {
                        if (isNaN(e.target.value)) return
                        props.onEvent({
                            message: "ChangeGridDimension",
                            params: { 
                                rows: rows,
                                cols: parseInt(e.target.value), 
                                editedElement: props.editedElement,
                            }
                        })
                    }}
                />
            </div>
            <Button 
                color="brown" size="mini"
                onClick={(e: any) => 
                    props.setProp("openGridCanvas", !props.openGridCanvas)}
                >
                Open Grid Panel
            </Button>
            <h4>Existing Elements</h4>
            {props.divGridCells
            .filter((cell: any) => cell.id)
            .map((cell: any, index: number) => (
            <div key={index} style={{display : "flex", alignItems: "center"}}>
                <div style={{ flex: 1 }}>Row</div>
                <div style={{ flex: 1 }}>{`${cell.startRow}/${cell.endRow}`}</div>
                <div style={{ flex: 1 }}>Col</div>
                <div style={{ flex: 1 }}>{`${cell.startColumn}/${cell.endColumn}`}</div>
                <Button 
                    size="mini" 
                    color="red"
                    disabled={!!cell.id}
                    onClick={(e: any) => {
                        props.onEvent({
                            message: "RemoveGridCell",
                            params: { index: index }
                        })
                    }}>
                    Delete
                </Button>
            </div>))}
            <h4>New Elements</h4>
            {props.divGridCells
            .map((cell: any, index: number) => ({...cell, originalIndex: index}))
            .filter((cell: any) => !cell.id)
            .map((cell: any, index: number) => (
            <div key={index} style={{display : "flex", alignItems: "center"}}>
                <div style={{ flex: 1 }}>Row</div>
                <div style={{ flex: 1 }}>{`${cell.startRow}/${cell.endRow}`}</div>
                <div style={{ flex: 1 }}>Col</div>
                <div style={{ flex: 1 }}>{`${cell.startColumn}/${cell.endColumn}`}</div>
                <Button 
                    size="mini" 
                    color="red"
                    onClick={(e: any) => {
                        props.onEvent({
                            message: "RemoveGridCell",
                            params: { index: cell.originalIndex }
                        })
                    }}>
                    Delete
                </Button>
            </div>))}
            <div style={{ display: "flex" }}>
                <Button 
                    style={{ flex: 1 }}
                    color="red" size="mini"
                    onClick={(e: any) => 
                        props.onEvent({ message: "ClearGridCell", params: {}})
                    }>
                    Clear New Elements
                </Button>
                <Button 
                    style={{ flex: 1 }}
                    color="green" size="mini"
                    onClick={(e: any) => 
                        props.onEvent({ message: "ConfirmGridCell", params: {}})
                    }>
                    Add New Elements
                </Button>
            </div>
        </div> : 
        <div>Change to Play mode to edit grid details</div>}
    </div>)
}

export const DivGrid = (props: any) => {
    const [mode, setMode] = useState("view");
    const [startRow, setStartRow] = useState<any|null>(null);
    const [startColumn, setStartColumn] = useState<any|null>(null);
    const [endRow, setEndRow] = useState<any|null>(null);
    const [endColumn, setEndColumn] = useState<any|null>(null);
    const [finished, setFinished] = useState(false);
    const editedElement = props.elements?.filter(
        (elem: any) => elem.id === props.editedElementId
    )?.[0]
    const [style, rows, cols] = getStyleDetails(editedElement);
    
    const gridRef = useRef<HTMLDivElement>(null);

    const handleDrop = (e: DragEvent) => {
        const [left, right, top, bottom, startX, startY] = e.dataTransfer.getData("startPosition")
                                  .split(":")
                                  .map((item: any) => parseInt(item));
        props.onEvent({
            message: "MoveDivGridCell",
            params: {
                left: left,
                right: right,
                top: top,
                bottom: bottom,
                rows: rows,
                cols: cols,
                startX: startX,
                startY: startY,
                endX: e.pageX,
                endY: e.pageY
            }
        })
    }

    useEffect(() => {
        if (!finished) return
        const cell = { 
            startRow: startRow, 
            startColumn: startColumn, 
            endRow: endRow + 1, 
            endColumn: endColumn + 1, 
        }
        console.log(cell);
        props.onEvent({
            message: "AddGridCell",
            params: cell
        });
        setFinished(false);
        setStartRow(null);
        setStartColumn(null);
        setEndRow(null);
        setEndColumn(null);
    }, [finished]);

    return(
    <div 
        ref={gridRef}
        style={{ 
            ...props.style,
            width: "100%", height: "100%", 
            position: "absolute",
            backgroundColor: "#cccccc", 
            opacity: 0.5,
        }}>
        {Array
         .from({ length: rows * cols }, (v, i) => i)
         .map((index: any) => {
            const gridRow = Math.floor(index / cols) + 1;
            const gridColumn = index % cols + 1
            return(
                <div 
                    key={index}
                    style={{ 
                        gridRow: gridRow,
                        gridColumn: gridColumn,
                        width: "100%", height: "100%",
                        backgroundColor: 
                            gridRow >= startRow &&
                            gridColumn >= startColumn &&
                            gridRow <= endRow &&
                            gridColumn <= endColumn ? 
                                "red": "white",
                        border: "solid rgba(200, 200, 200, 0.25) 1px"
                    }}
                    onDragOver={(e: any) => { e.preventDefault(); }}
                    onDrop={handleDrop}
                    onClick={(e: any) => {
                        if (mode === "view") {
                            setStartRow(gridRow);
                            setStartColumn(gridColumn);
                            setEndRow(gridRow);
                            setEndColumn(gridColumn);
                            setMode("add");
                        } else {
                            setEndRow(gridRow);
                            setEndColumn(gridColumn);
                            setMode("view");
                            setFinished(true);
                        }
                    }}
                    onMouseOver={(e: any) => {
                        if (mode !== "add") return
                        setEndRow(gridRow);
                        setEndColumn(gridColumn);
                    }}
                    // onMouseDown={(e: any) => {
                    //     setMode("add");
                    //     setStartRow(gridRow);
                    //     setStartColumn(gridColumn);
                    //     setEndRow(gridRow);
                    //     setEndColumn(gridColumn);
                    // }}
                    // onMouseUp={(e: any) => {
                    //     if (mode !== "add") return
                    //     setMode("view");
                    //     setEndRow(gridRow);
                    //     setEndColumn(gridColumn);
                    //     setFinished(true);
                    // }}
                    >
                </div>)
        })}
        {props.divGridCells.map((cell: any, index: number) => (
            <div 
                key={index}
                style={{ 
                    width: "100%", height: "100%",
                    gridRow: `${cell.startRow}/${cell.endRow}`,
                    gridColumn: `${cell.startColumn}/${cell.endColumn}`,
                    backgroundColor: index === props.draggedCellIndex ? "red" : cell.id ? "cyan" : "green",
                }}
                onClick={(e: MouseEvent) => {
                    // console.log(e.pageX);
                    // console.log(e.pageY);
                    // console.log(gridRef?.current?.getBoundingClientRect());
                    // // props.setProp("draggedCellIndex", index);
                }}
                draggable={true}
                onDragStart={(e: DragEvent) => {
                    const b = gridRef.current?.getBoundingClientRect();
                    e.dataTransfer.setData("startPosition", 
                        `${b?.left}:${b?.right}:${b?.top}:${b?.bottom}:${e.pageX}:${e.pageY}`);
                    props.setProp("draggedCellIndex", index);
                }}
                onDragOver={(e: any) => { e.preventDefault(); }}
                onDrop={handleDrop}
                // onDragEnd={(e: any) => {
                //     props.setProp("draggedCellIndex", null);
                // }}
                
                // onDrop={(e: any) => {
                //     console.log(e.pageX);
                //     console.log(e.pageY);
                // }}
                >
            </div>)
        )}

    </div>)
}
