import { useEffect, useRef, useState } from "react";
import { Button, Icon, Input, Label, Modal } from "semantic-ui-react";
import AddScreenFromVideo from "./AddScreenFromVideo";
import LevelPanel from "./LevelPanel";
import XDCaseAddStepPanel from "../XD2/XDCaseAddStepPanel";
import { getStepLabelDict } from "../XD2/Algorithm";
import ReactMarkdown from "react-markdown";

export default function GameEditor(props: any) {
  const [selectedIHGame, setSelectedIHGame] = useState<any>(null);
  const [selectedLevel, setSelectedLevel] = useState<number | null>(null);
  const [selectedStep, setSelectedStep] = useState<number | null>(null);
  const [toAddLevel, setToAddLevel] = useState<number | null>(null);
  const [toAddStep, setToAddStep] = useState<number | null>(null);
  const [editView, setEditView] = useState("Screen");
  const [editedImage, setEditedImage] = useState("Screen");
  const [selectedScreenUrl, setSelectedScreenUrl] = useState<string | null>(null);
  const [targetData, setTargetData] = useState<any>(null);
  const [levelToAddScreen, setLevelToAddScreen] = useState<number | null>(null);
  const [addedScreenName, setAddedScreenName] = useState("");
  const [imageSize, setImageSize] = useState({width: 0, height: 0});
  const [naturalSize, setNaturalSize] = useState({width: 0, height: 0});
  const [svgMode, setSvgMode] = useState("Standby");
  const [startPosition, setStartPosition] = useState<any>(null);
  const [endPosition, setEndPosition] = useState<any>(null);
  const [imageUrl, setImageUrl] = useState<string|null>(null);
  const [imageType, setImageType] = useState<string|null>(null);
  const [openAddFromImageDialog, setOpenAddFromImageDialog] = useState(false);
  const [openAddFromVideoDialog, setOpenAddFromVideoDialog] = useState(false);
  const [editedStepData, setEditedStepData] = useState<any>(null);
  const [toAddStepData, setToAddStepData] = useState<any>(null);
  const [edited, setEdited] = useState(false);
  const [openDeleteStepDialog, setOpenDeleteStepDialog] = useState(false);
  const [stepLabelDict, setStepLabelDict] = useState<any>({});
  
  const imageRef  = useRef<HTMLImageElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);
  // const fileRef = useRef<HTMLInputElement>(null);
  const workareaRef = useRef<HTMLDivElement>(null);
  
  // New Screen   
  const [selectingXdStep, setSelectingXdStep] = useState(false);
  const [newScreenImageView, setNewScreenImageView] = useState("From XD");
  const [openAddNewScreenImageDialog, setOpenAddNewScreenImageDialog] = useState(false);
  const [newScreenImageUrl, setNewScreenImageUrl] = useState<string|null>(null);
  const [newScreenImageType, setNewScreenImageType] = useState<string|null>(null);
  const newScreenImageWorkareaRef = useRef<HTMLDivElement>(null);
  const [docInfo, setDocInfo] = useState<any[]>([]);
  const fileRef1 = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setStepLabelDict(getStepLabelDict(props.xddata));
  }, [props.xddata]);

  useEffect(() => {
    setDocInfo(props.docInfo);
  }, [props.docInfo]);

  useEffect(() => {
    if (props.selectedIHGame) {
      if (selectedIHGame?.id !== props.selectedIHGame.id) {
        setTargetData(null)
        if (props.selectedIHGame?.levels?.length > 0) {
          setSelectedLevel(0);
          if (props.selectedIHGame.levels[0]?.steps?.length > 0) {
            setSelectedStep(0);
          }
        }
      } else {
        updateGameData();
        updateImage();
      }
      setSelectedIHGame(props.selectedIHGame);
    } else if (props.selectedIHGame === null && selectedIHGame) {
      // Clear game
      setTargetData(null);
      setSelectedLevel(null);
      setSelectedStep(null);
      setSelectedIHGame(null);
    }
  }, [props.selectedIHGame]);

  useEffect(() => {
    props.setMode("Edit");
    setEdited(false);
    setToAddStepData(null);
    setDocInfo([]);
    if (selectedLevel !== null && selectedStep !== null) {
      setTargetData(null);
      // setEditedStepData(selectedIHGame?.levels?.[selectedLevel]?.steps?.[selectedStep]);
      refreshEditedStepData();
      updateImage();
    } else {
      setEditedStepData(null);
    }
  }, [selectedLevel, selectedStep]);

  useEffect(() => {
    updateGameData();
  }, [imageSize, naturalSize]);

  useEffect(() => {
    setDocInfo([]);
    if (props.mode === "AddScreen" && selectedLevel !== null) {
      setEdited(false);
      setToAddStepData(null);
      setToAddLevel(selectedLevel);
      setEditView("Details");
      if (selectedStep === null) {
        setToAddStep(0);
      } else {
        setToAddStep(selectedStep + 1);
      }
    } else if (props.mode === "Edit") {
      setEdited(false);
      setToAddStepData(null);
      setEditView("Screen");
      setToAddLevel(null);
      setToAddStep(null);
    }
  }, [props.mode]);

  const updateImage = () => {
    // setSelectedScreenUrl(null);
    if (selectedLevel !== null && selectedStep !== null) {
      (async () => {
        let url = props.selectedIHGame?.levels
          ?.[selectedLevel]
          ?.steps?.[selectedStep]
          ?.screen?.url;
        if (url)
          setSelectedScreenUrl(await props.toDownloadUrl(url));
        else
          setSelectedScreenUrl("/blankscreen.png");
      })();
    }
  }

  const updateGameData = () => {
    if (selectedLevel === null || selectedStep === null) 
      return console.log("level or step null")
    let target = 
      props.selectedIHGame?.levels[selectedLevel]?.steps?.[selectedStep]?.target || null;
    let scaleX = imageSize.width === 0 ? null : (naturalSize.width / imageSize.width);
    let scaleY = imageSize.height === 0 ? null : (naturalSize.height / imageSize.height);
    setTargetData({target, scaleX, scaleY});
    // setEditedStepData(selectedIHGame?.levels?.[selectedLevel]?.steps?.[selectedStep]);
    refreshEditedStepData();
  }

  const refreshEditedStepData = async () => {
    if (selectedLevel !== null && selectedStep !== null) {
      let stepData = selectedIHGame?.levels?.[selectedLevel]?.steps?.[selectedStep];
      let downloadUrl = stepData?.screen?.url ? await props.toDownloadUrl(stepData.screen.url) : "/blankscreen.png";
      let lessonDownloadUrl = stepData?.lesson?.url ? await props.toDownloadUrl(stepData.lesson.url) : "/blankscreen.png";
      setEditedStepData({
        ...(stepData || {}),
        downloadUrl,
        lessonDownloadUrl
      });
    }
  }

  const selectLevelAndStep = (level: number, step: number) => {
    setSelectedLevel(level);
    setSelectedStep(step);
    setEditView("Screen")
  }

  const handleSelectedImageFile = async (file: File) => {
    if (!file.type.includes("image")) return console.log("File not an image");
    setNewScreenImageType(file.type.split("/")?.[1] || "jpg");
    newScreenImageWorkareaRef.current!.innerHTML = "";
    const arrayBuffer = await new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
    if (arrayBuffer) {
      let img = new Image();
      newScreenImageWorkareaRef.current?.appendChild(img);
      img.style.maxWidth = "100%"
      img.style.maxHeight = "100%"
      img.style.border = "solid #dddddd 1px"
      let url = URL.createObjectURL(new Blob([arrayBuffer as ArrayBuffer]));
      img.src = url;
      setNewScreenImageUrl(url);
    }
  }

  const clearImageDialog = () => {
    setOpenAddNewScreenImageDialog(false); 
    setNewScreenImageUrl(null);
    setNewScreenImageType(null);
    setEditedImage("Screen");
  }
  
  return(
    <div style={{height: "100%"}}>
      {(!props.selectedGameId || !props.selectedIHGame) ?
      <div 
        style={{
          width: "100%", height: "100%", display: "flex", fontSize: "2rem",
          justifyContent: "center", alignItems: "center"
        }}>
        {props.ihGameList?.length === 0 ? `No game list` : !props.selectedGameId ? `Please Select Game` : `Loading Game`}        
      </div>
      
      :
      <div 
        style={{
          height: "100%", 
          overflowY: "hidden",
          ...(["Edit", "AddScreen"].includes(props.mode) 
              ? {display: "grid", gridTemplateColumns: "250px 1fr"}
              : {})
        }}>
        <LevelPanel
          onEvent={props.onEvent}
          mode={props.mode}
          setMode={props.setMode}
          setStartLevel={props.setStartLevel}
          webviewReady={props.webviewReady}
          selectedGameId={props.selectedGameId}
          selectedIHGame={props.selectedIHGame}
          selectedLevel={selectedLevel}
          selectedStep={selectedStep}
          toAddLevel={toAddLevel}
          toAddStep={toAddStep}
          selectLevelAndStep={selectLevelAndStep}
          toDownloadUrl={props.toDownloadUrl}
          openAddFromVideoDialog={(level: number) => {
            setLevelToAddScreen(level);
            setAddedScreenName("");
            setOpenAddFromVideoDialog(true);
          }}
        />
        {/* Main edit panel */}
        <div>
          <>
            {(props.mode === "AddScreen" || (props.mode === "Edit" && selectedLevel !== null && selectedStep !== null)) &&
            <div style={{width: "100%", display: "flex", borderBottom: "solid grey 1px", alignItems: "center"}}>
              <ViewMenu 
                name="Details" disabled={props.mode !== "Edit"}
                view={editView} setView={setEditView} 
              />
              {props.mode === "Edit" &&
              <ViewMenu 
                name="Screen" disabled={props.mode !== "Edit"}
                view={editView} setView={setEditView} 
              />}
              {props.mode === "Edit" && editedStepData?.lessonDownloadUrl && editedStepData.lessonDownloadUrl !== "/blankscreen.png" &&
              <ViewMenu 
                name="Lesson" disabled={props.mode !== "Edit"}
                view={editView} setView={setEditView} 
              />}
              <div style={{flex: 1}}/>
              {edited && <div style={{color: "red"}}>{`มีการแก้ไขที่ยังไม่ได้ save`}</div>}
            </div>}
            
            {props.mode === "Edit" && selectedLevel !== null && selectedStep !== null && editView === "Screen" ?
            <div style={{position: "relative", height: "100%"}}>
              {selectedScreenUrl ?
              <>
                <img 
                  ref={imageRef}
                  onLoad={() => {
                    setImageSize({
                      width: imageRef.current?.offsetWidth || 0, 
                      height: imageRef.current?.offsetHeight || 0
                    });
                    setNaturalSize({
                      width: imageRef.current?.naturalWidth || 0, 
                      height: imageRef.current?.naturalHeight || 0
                    });
                  }}
                  style={{maxWidth: "100%", maxHeight: "100%"}}
                  src={selectedScreenUrl || ""} 
                />
                {targetData &&
                <svg 
                  ref={svgRef}  
                  width="100%"
                  viewBox={`0 0 ${imageSize.width} ${imageSize.height}`}
                  onPointerDown={(e: any) => {
                    setSvgMode("CreateRect");
                    let rect = svgRef.current?.getBoundingClientRect() || null;
                    if (rect) {
                      setStartPosition({
                        x: e.clientX - rect.left,
                        y: e.clientY - rect.top
                      });
                      setEndPosition({
                        x: e.clientX - rect.left,
                        y: e.clientY - rect.top
                      })
                    }
                  }}
                  onPointerMove={(e: any) => {
                    if (svgMode === "CreateRect") {
                      let rect = svgRef.current?.getBoundingClientRect() || null;
                      if (rect) {
                        setEndPosition({
                          x: e.clientX - rect.left,
                          y: e.clientY - rect.top
                        });
                      }
                    }
                  }}
                  onPointerUp={(e: any) => {
                    setSvgMode("Standby");
                    if (!targetData.scaleX || !targetData.scaleY) return;
                    props.onEvent({message: "AddRectTarget", params: {
                      gameId: selectedIHGame?.id,
                      selectedLevel,
                      selectedStep,
                      x: startPosition.x * targetData.scaleX,
                      y: startPosition.y * targetData.scaleY,
                      width: (endPosition.x - startPosition.x) * targetData.scaleX,
                      height: (endPosition.y - startPosition.y) * targetData.scaleY,
                    }})
                  }}
                  style={{
                    position: "absolute", top: 0, left: 0,
                    width: imageSize.width, 
                    height: imageSize.height, 
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                  }}
                  onClick={(e: any) => {
                  }}>
                  {svgMode === "CreateRect" ?
                  <rect
                    x={Math.min(startPosition.x, endPosition.x)}
                    y={Math.min(startPosition.y, endPosition.y)}
                    width={Math.abs(endPosition.x - startPosition.x)}
                    height={Math.abs(endPosition.y - startPosition.y)}
                    strokeWidth={2}
                    fill="none"
                    stroke="red"
                  />:
                  targetData.target && targetData.scaleX && targetData.scaleY ?
                  <rect
                    x={targetData.target[0] / targetData.scaleX}
                    y={targetData.target[1] / targetData.scaleY}
                    width={targetData.target[2] / targetData.scaleX}
                    height={targetData.target[3] / targetData.scaleY}
                    strokeWidth={2}
                    fill="none"
                    stroke="red"
                  />
                  :
                  <></>}
                </svg>}
                
              </>
              :
              <div style={{height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                Waiting for Screen Image
              </div>}
            </div>
            
            : props.mode === "Edit" && selectedLevel !== null && selectedStep !== null && editView === "Lesson" ?
            <div style={{height: "100%"}}>
              <img 
                style={{maxWidth: "100%", maxHeight: "100%"}}
                src={editedStepData?.newLessonDownloadUrl ? editedStepData.newLessonDownloadUrl
                    : editedStepData.newLessonDownloadUrl === null ? "/blankscreen.png"
                    : editedStepData?.lessonDownloadUrl ? editedStepData.lessonDownloadUrl
                    : "/blankscreen.png"}
              />
            </div>

            : (props.mode === "AddScreen" 
              || (props.mode === "Edit" && selectedLevel !== null && selectedStep !== null && editView === "Details")) ?
            <div style={{padding: "10px"}}>
              <div style={{marginBottom: "5px", display: "grid", gridTemplateColumns: "150px 1fr"}}>
                <div>Step</div>
                <div style={{position: "relative"}}>
                  {!selectingXdStep ?
                  <div onClick={() => setSelectingXdStep(true)}>
                  {(() => {
                    if (props.mode === "Edit") {
                      let stepId = editedStepData?.stepId;
                      if (stepId && props.xddata.steps?.[stepId]) 
                        return props.xddata.steps?.[stepId]?.description || "Not description";
                      else
                        return "Not selected";
                    } else {
                      if (props.mode === "AddScreen") {
                        let stepId = toAddStepData?.stepId;
                        if (stepId && props.xddata.steps?.[stepId]) 
                          return `${stepLabelDict?.[stepId] || "??"} ${props.xddata.steps?.[stepId]?.description || "Not description"}`;
                        else
                          return "Not selected";
                      }
                    }
                  })()}
                  </div>
                  :
                  <div 
                    style={{
                      position: "absolute", top:0, left: 0, zIndex: 100, border: "solid grey 1px",
                      height: "70vh", width: "300px", backgroundColor: "white", padding: "5px"
                    }}>
                    <XDCaseAddStepPanel 
                      xddata={props.xddata}
                      showAllSeqOptions={true}
                      setShowAllSeqOptions={() => { console.log("showAllSeqOptions")}}
                      nextSeqList={[]}
                      selectStep={(step: any) => {
                        setEdited(true);
                        if (props.mode === "Edit") {
                          setEditedStepData({
                            ...(editedStepData || {}),
                            stepId: step.id,
                            nodeId: null,
                          });
                          setSelectingXdStep(false);
                        } else if (props.mode === "AddScreen") {
                          setToAddStepData({
                            ...(toAddStepData || {}),
                            stepId: step.id,
                            nodeId: null,
                          });
                          setSelectingXdStep(false);
                        }
                      }}
                    />
                  </div>}
                </div>
              </div>
              <div style={{marginBottom: "5px", display: "grid", gridTemplateColumns: "150px 1fr"}}>
                <div>Role</div>
                {(props.mode === "Edit" && !editedStepData?.stepId) || (props.mode === "AddScreen" && !toAddStepData?.stepId) ?
                <div>Not seleted</div>
                :
                <select
                  value={(props.mode === "Edit" ? editedStepData?.nodeId || "NOTSELECTED"
                    : props.mode === "AddScreen" ? toAddStepData?.nodeId || "NOTSELECTED" 
                    : "NOTSELECTED")}
                  onChange={(e: any) => {
                    setEdited(true);
                    if (props.mode === "Edit") {
                      setEditedStepData({
                        ...(editedStepData || {}),
                        nodeId: e.target.value
                      });
                    } else if (props.mode === "AddScreen") {
                      setToAddStepData({
                        ...(toAddStepData || {}),
                        nodeId: e.target.value
                      });
                    }
                    
                  }}
                >
                  {(((props.mode === "Edit" && !editedStepData?.nodeId) 
                      || (props.mode === "AddScreen" && !toAddStepData?.nodeId)) 
                    ? [<option key={-1} value={"NOTSELECTED"}>Not selected</option>] : [])
                  .concat((() => {
                    let step = props.xddata.steps?.[props.mode === "Edit" ? editedStepData?.stepId : toAddStepData?.stepId];
                    let nodeList = Object.keys(step.nodeDetail || {});
                    return nodeList;
                  })().map((nodeId: string, nodeIndex: number) => (
                    <option
                      key={nodeIndex}
                      value={nodeId}>
                      {props.xddata.nodeMaster?.[nodeId]?.name || "No role name"}
                    </option>
                  )))}
                </select>}
              </div>
              <div style={{marginBottom: "5px", display: "grid", gridTemplateColumns: "150px 1fr"}}>
                <div>Name</div>
                <Input
                  size="small"
                  value={props.mode === "Edit" ? (editedStepData?.guide || "") : (toAddStepData?.guide || "")}
                  onChange={(e: any) => {
                    setEdited(true);
                    if (props.mode === "Edit") {
                      setEditedStepData({
                        ...editedStepData,
                        guide: e.target.value
                      });
                    } else {
                      setToAddStepData({
                        ...(toAddStepData || {}),
                        guide: e.target.value
                      });
                    }
                    
                  }}
                />
              </div>
              <div style={{marginBottom: "5px", display: "grid", gridTemplateColumns: "150px 1fr 1fr"}}>
                <div>Mission</div>
                <textarea 
                  style={{width: "100%", minHeight: "200px"}}
                  value={props.mode === "Edit" ? (editedStepData?.mission || "") : (toAddStepData?.mission || "")}
                  onChange={(e: any) => {
                    setEdited(true);
                    if (props.mode === "Edit") {
                      setEditedStepData({
                        ...editedStepData,
                        mission: e.target.value
                      });
                    } else {
                      setToAddStepData({
                        ...(toAddStepData || {}),
                        mission: e.target.value
                      });
                    }
                  }}
                />
                <div style={{textAlign: "center"}}>
                  <ReactMarkdown>{editedStepData?.mission}</ReactMarkdown>
                </div>
              </div>
              <div style={{marginBottom: "5px", display: "grid", gridTemplateColumns: "150px 1fr"}}>
                <div>Screen</div>
                <div 
                  onClick={() => {
                    setEditedImage("Screen");
                    setOpenAddNewScreenImageDialog(true);
                    if ((props.mode === "Edit" && editedStepData?.stepId && editedStepData?.nodeId) 
                      || (props.mode === "AddScreen" && toAddStepData?.stepId && toAddStepData?.nodeId)
                    ) {
                      setNewScreenImageView("From XD");
                      props.onEvent({
                        message: "GetNodeDetailDoc", 
                        params: {
                          stepId: props.mode === "Edit" ? editedStepData.stepId : toAddStepData.stepId,
                          loc: {
                            id: props.mode === "Edit" ? editedStepData.nodeId : toAddStepData.nodeId,
                          }
                        }}
                      );
                    } else {
                      setNewScreenImageView("Copy & Paste");
                    }
                  }}>
                  <img 
                    src={props.mode === "Edit" ? 
                      (editedStepData?.newDownloadUrl ? editedStepData.newDownloadUrl 
                        : editedStepData?.downloadUrl ? editedStepData.downloadUrl 
                        : '/blankscreen.png') 
                      : props.mode === "AddScreen" ?
                      (toAddStepData?.newDownloadUrl ? toAddStepData.newDownloadUrl
                        : toAddStepData?.downloadUrl  ? toAddStepData.downloadUrl
                        : '/blankscreen.png')
                      : "/blankscreen.png"}
                    style={{width: "200px"}} 
                  />
                </div>
              </div>
              <div 
                style={{
                  marginBottom: "5px", display: "grid", gridTemplateColumns: "150px 1fr",
                }}>
                <div>Lesson</div>
                <div 
                  style={{display: "flex", alignItems: "flex-start"}}
                  onClick={() => {
                    setEditedImage("Lesson");
                    setOpenAddNewScreenImageDialog(true);
                    if ((props.mode === "Edit" && editedStepData?.stepId && editedStepData?.nodeId) 
                      || (props.mode === "AddScreen" && toAddStepData?.stepId && toAddStepData?.nodeId)
                    ) {
                      setNewScreenImageView("From XD");
                      props.onEvent({
                        message: "GetNodeDetailDoc", 
                        params: {
                          stepId: props.mode === "Edit" ? editedStepData.stepId : toAddStepData.stepId,
                          loc: {
                            id: props.mode === "Edit" ? editedStepData.nodeId : toAddStepData.nodeId,
                          }
                        }}
                      );
                    } else {
                      setNewScreenImageView("Copy & Paste");
                    }
                  }}>
                  <img 
                    src={props.mode === "Edit" ? 
                      (editedStepData?.newLessonDownloadUrl ? editedStepData.newLessonDownloadUrl 
                        : editedStepData?.newLessonDownloadUrl === null ? "/blankscreen.png"
                        : editedStepData?.lessonDownloadUrl ? editedStepData.lessonDownloadUrl 
                        : '/blankscreen.png') 
                      : props.mode === "AddScreen" ?
                      (toAddStepData?.newLessonDownloadUrl ? toAddStepData.newLessonDownloadUrl
                        : toAddStepData?.newLessonDownloadUrl === null ? "/blankscreen.png"
                        : toAddStepData?.lessonDownloadUrl  ? toAddStepData.lessonDownloadUrl
                        : '/blankscreen.png')
                      : "/blankscreen.png"}
                    style={{width: "200px"}} 
                  />
                  {((props.mode === "Edit" && 
                      ((editedStepData?.lessonDownloadUrl !== "/blankscreen.png" && editedStepData.newLessonDownloadUrl !== null)
                        || (editedStepData?.newLessonDownloadUrl))
                    ) 
                    || 
                    (props.mode === "AddScreen" && 
                      ((toAddStepData?.lessonDownloadUrl !== "/blankscreen.png" && toAddStepData?.newLessonDownloadUrl !== null)
                        || (toAddStepData?.newLessonDownloadUrl))
                    )) && 
                  <Icon 
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setEdited(true);
                      if (props.mode === "Edit") {
                        setEditedStepData({
                          ...editedStepData,
                          newLessonDownloadUrl: null
                        });
                      } else if (props.mode === "AddScreen") {
                        setToAddStepData({
                          ...toAddStepData,
                          newLessonDownloadUrl: null
                        });
                      }
                    }}
                    name="delete" color="red" label="remove" 
                  />}
                </div>
              </div>
              <Button 
                size="mini"
                color="green"
                disabled={
                  (props.mode === "Edit" && !edited) 
                  || (props.mode === "Edit"  && edited
                      && (!editedStepData?.guide || !editedStepData?.mission || !editedStepData?.stepId 
                          || !editedStepData?.nodeId))
                  || (props.mode === "AddScreen" 
                      && (!toAddStepData?.guide || !toAddStepData?.mission || !toAddStepData?.stepId 
                          || !toAddStepData?.nodeId || !toAddStepData?.newDownloadUrl))}
                onClick={() => {
                  if (props.mode === "Edit" && selectedLevel !== null && selectedStep !== null) {
                    props.onEvent({message: "EditIHGameStep", params: {
                      selectedLevel, selectedStep, editedStepData
                    }});
                    setEdited(false);
                    // setEditedStepData(selectedIHGame?.levels?.[selectedLevel]?.steps?.[selectedStep]);
                    refreshEditedStepData();
                  } else if (props.mode === "AddScreen" && toAddLevel !== null && toAddStep !== null) {
                    props.onEvent({message: "AddIHGameStep", params: {
                      toAddLevel, toAddStep, toAddStepData,
                      finishCallBack: () => {
                        props.setMode("Edit")
                        setSelectedLevel(toAddLevel);
                        setSelectedStep(toAddStep);
                        setToAddLevel(null);
                        setToAddStep(null);
                        setToAddStepData(null);
                      }
                    }});
                    setEdited(false);
                    
                  }
                }}>
                Save
              </Button>
              <Button 
                size="mini"
                color="orange"
                disabled={props.mode === "Edit" && !edited}
                onClick={() => {
                  if (props.mode === "Edit" && selectedLevel !== null && selectedStep !== null) {
                    setEdited(false);
                    // setEditedStepData(selectedIHGame?.levels?.[selectedLevel]?.steps?.[selectedStep]);
                    refreshEditedStepData();
                  } else if (props.mode === "AddScreen") {
                    props.setMode("Edit")
                  }
                }}>
                Cancel
              </Button>
              {props.mode === "Edit" &&
              <Button 
                size="mini"
                disabled={props.mode !== "Edit"}
                color="red"
                onClick={() => {
                  if (props.mode === "Edit" && selectedLevel !== null && selectedStep !== null) {
                    setOpenDeleteStepDialog(true);
                  }
                }}>
                Delete
              </Button>}
            </div>

            :
            <></>}
          </>
        </div>
      </div>}
      {/* New image dialog ----------------------------------------------------------------------- */}
      <Modal
        size="fullscreen"
        style={{height: "90vh"}}
        open={openAddNewScreenImageDialog}
        closeOnDimmerClick={false}
        onClose={() => {
          clearImageDialog();
        }}>
        <div>
          <input 
            ref={fileRef1}
            style={{display: "none"}}
            type="file"
            onClick={(e: any) => {
              e.target.value = null;
              setNewScreenImageUrl(null);
              setNewScreenImageType(null);
            }}
            onBlur={(e: any) => console.log("blur")}
            onChange={async (e: any) => {
              if (e.target?.files?.[0]) {
                handleSelectedImageFile(e.target.files[0]);
              }
            }}
          />
          <div 
            style={{
              width: "100%", display: "flex", alignItems: "flex-end",
              borderBottom: "solid grey 1px"
            }}
            onPasteCapture={async (e: any) => {
              if (e.clipboardData?.files?.[0]) {
                handleSelectedImageFile(e.clipboardData.files[0]);
              }
            }}>
            {((props.mode === "Edit" && editedStepData?.stepId && editedStepData?.nodeId) 
              || (props.mode === "AddScreen" && toAddStepData?.stepId && toAddStepData?.nodeId)) &&
            <ViewMenu name="From XD" view={newScreenImageView} setView={setNewScreenImageView} />}
            
            <ViewMenu name="Copy & Paste" view={newScreenImageView} setView={setNewScreenImageView} />
            
            <ViewMenu 
              name="Upload" view={newScreenImageView} setView={setNewScreenImageView} 
              onClick={() => {
                fileRef1.current?.click();
              }}
            />          
            
            <div style={{flex: 1}}/>
            <Button 
              size="mini" color="orange" 
              onClick={() => {
                clearImageDialog();
              }}>
              Cancel
            </Button>
            <Button 
              size="mini" color="green"
              onClick={() => {
                if (props.mode === "Edit" && newScreenImageUrl) {
                  setEdited(true);
                  if (editedImage === "Lesson") {
                    setEditedStepData({
                      ...(editedStepData || {}),
                      newLessonDownloadUrl: newScreenImageUrl,
                      newLessonDownloadType: newScreenImageType,
                    });
                  } else {
                    setEditedStepData({
                      ...(editedStepData || {}),
                      newDownloadUrl: newScreenImageUrl,
                      newDownloadType: newScreenImageType,
                    });
                  }
                  clearImageDialog();
                } else if (props.mode === "AddScreen" && newScreenImageUrl) {
                  setEdited(true);
                  if (editedImage === "Lesson") {
                    setToAddStepData({
                      ...(toAddStepData || {}),
                      newLessonDownloadUrl: newScreenImageUrl,
                      newLessonDownloadType: newScreenImageType,
                    });
                  } else {
                    setToAddStepData({
                      ...(toAddStepData || {}),
                      newDownloadUrl: newScreenImageUrl,
                      newDownloadType: newScreenImageType,
                    });
                  }
                  clearImageDialog();
                }
              }}>
              Confirm
            </Button>
          </div>
        </div>
        {newScreenImageView === "From XD" &&
        (newScreenImageUrl ?
          <div
            style={{cursor: "pointer", paddingBottom: "5px"}}
            onClick={() => {
              newScreenImageWorkareaRef.current!.innerHTML = "";
              setNewScreenImageUrl(null)
            }}>
            {`<<Back`}
          </div>
          :
          <div style={{display: "flex", flexWrap: "wrap", overflowY: "auto"}}>
            {(docInfo || [])
            .filter((item: any) => item.type === "image")  
            .map((item: any, itemIndex: number) => (
              <img 
                key={itemIndex}
                style={{width: "300px"}} src={item.detail} 
                onClick={() => {
                  let parts = item.detail?.split("%2F");
                  let fileName = parts?.[parts?.length - 1]?.split("?")[0];
                  let fileType = fileName?.split(".")?.[1];
                  setNewScreenImageType(fileType || "jpg");
                  let img = new Image();
                  newScreenImageWorkareaRef.current!.innerHTML = "";
                  newScreenImageWorkareaRef.current?.appendChild(img);
                  img.style.maxWidth = "100%"
                  img.style.maxHeight = "100%"
                  img.style.border = "solid #dddddd 1px"
                  img.src = item.detail
                  setNewScreenImageUrl(item.detail);
                }}
              />
            ))}
          </div>
          )}
        <div ref={newScreenImageWorkareaRef} />
      </Modal>

      {/* Delete dialog ----------------------------------------------------------------------- */}
      <Modal 
        size="mini"
        open={openDeleteStepDialog}
        closeOnDimmerClick={true}
        onClose={(e: any) => setOpenDeleteStepDialog(false)}>
        <div 
          style={{
            width: "100%", height: "200px", padding: "20px",
            display: "flex", flexDirection: "column",
            justifyContent: "space-around", alignItems: "center"
          }}>
          <div style={{fontWeight: "bold", color: "red", fontSize: "1.2rem"}}>
            Confirm Delete?
          </div>
          <div>
            <Button size="mini" color="green"
              onClick={() => setOpenDeleteStepDialog(false)}>
              Cancel
            </Button>
            <Button size="mini" color="red"
              onClick={() => {
                props.onEvent({message: "DeleteIHGameStep", params: {
                  selectedLevel, selectedStep
                }});
                setOpenDeleteStepDialog(false);
                setEditView("Screen");
                setEditedStepData(null);
              }}>
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
      {/* Add from video dialog ----------------------------------------------------------------------- */}
      <Modal 
        size="fullscreen"
        open={openAddFromVideoDialog}
        closeOnDimmerClick={true}
        onClose={(e: any) => setOpenAddFromVideoDialog(false)}>
        <AddScreenFromVideo 
          onEvent={props.onEvent}
          setProp={props.setProp}
          webviewReady={props.webviewReady}
          videoServerReady={props.videoServerReady}
          videoUrlPrefix={props.videoUrlPrefix}
          videoPathInfo={props.videoPathInfo}
          screenGroupInfo={props.screenGroupInfo}
          ihGameMessage={props.ihGameMessage}
          addedScreenName={addedScreenName}
          setAddedScreenName={setAddedScreenName}
          levelToAddScreen={levelToAddScreen}
          imageUrl={imageUrl}
          imageType={imageType}
        />
      </Modal>
    </div>
  )
}

function ViewMenu(props: any) {
  return(
    <div
      style={{
        cursor: "pointer",
        padding: "5px", borderRight: "solid grey 1px", borderTop: "solid grey 1px",
        backgroundColor: props.name === props.view ? "lightblue" : "white"
      }}
      onClick={() => {
        if (props.disabled) return;
        props.setView(props.name);
        if (props.onClick) props.onClick?.();
      }}>
      {props.name}
    </div>
  )
}

 {/* Old image dialog ----------------------------------------------------------------------- */}
//  <input 
//  ref={fileRef}
//  style={{display: "none"}}
//  type="file"
//  onClick={(e: any) => {
//    e.target.value = null;
//    setImageUrl(null);
//    setImageType(null);
//  }}
//  onBlur={(e: any) => console.log("blur")}
//  onChange={async (e: any) => {
//    if (e.target?.files?.[0]) {
//      if (!(e.target.files[0].type.includes("image"))) return
//      const imageType_ = e.target.files[0].type.split("/")[1];
//      workareaRef.current!.innerHTML = "";
//      const arrayBuffer = await new Promise((resolve, reject) => {
//        let reader = new FileReader();
//        reader.onload = () => {
//          resolve(reader.result);
//        };
//        reader.onerror = reject;
//        reader.readAsArrayBuffer(e.target.files[0]);
//      });
//      if (arrayBuffer) {
//        let img = new Image();
//        workareaRef.current?.appendChild(img);
//        img.style.maxWidth = "100%"
//        img.style.maxHeight = "100%"
//        img.style.border = "solid #dddddd 1px"
//        let url = URL.createObjectURL(new Blob([arrayBuffer as ArrayBuffer]));
//        img.src = url;
//        setImageUrl(url);
//        setImageType(imageType_);
//        setOpenAddFromImageDialog(true);
//      }
//    }
//  }}
// />
// <Modal
//  open={openAddFromImageDialog}
//  closeOnDimmerClick={true}
//  onClose={() => {
//    setOpenAddFromImageDialog(false); 
//    setImageUrl(null);
//    setImageType(null);
//  }}>
//  {imageUrl && 
//  <div style={{display: "flex"}}>
//    <Input 
//      size="mini" style={{width: "100%"}} placeholder="ใส่ชื่อ step / screen" 
//      onChange={(e: any) => {
//        setAddedScreenName(e.target.value);
//      }}
//    />
//    <div style={{flex: 1}}/>
//    <Button size="mini" color="green"
//      disabled={addedScreenName===""}
//      onClick={(e: any) => {
//        props.onEvent({message: "UploadIHGameAsset", params: {
//          level: levelToAddScreen, 
//          addedScreenName: addedScreenName,
//          url: imageUrl, 
//          assetType: "screen",
//          fileExtension: imageType
//        }});
//        setOpenAddFromImageDialog(false);
//      }}>
//      Upload
//    </Button>
//  </div>}
//  <div ref={workareaRef} />
// </Modal>
