import { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Icon, Input, Modal } from 'semantic-ui-react';
import { DownloadDataAsJsonFile } from 'ismor-lib/utils/File';

let schema = {name: "Master", url: "ih.json", format: "json"}
let PANELWIDTH="350px"

export default function Master(props: any) {
  const [showTree, setShowTree] = useState(true);
  const [tableList, setTableList] = useState<string[]>([]);
  const [tableListDisplay, setTableListDisplay] = useState<string[]>([]);
  const [treeDisplay, setTreeDisplay] = useState<any[]>([]);
  const [selectedTable, setSelectedTable] = useState<string | null>(null)
  const [filterTerm, setFilterTerm] = useState<string|null>(null);
  const [selectedIHMaster, setSelectedMaster] = useState<any>(null);
  const [mode, setMode] = useState("Description");
  const [editedData, setEditedData] = useState<any>(null);
  const [masterExist, setMasterExist] = useState(true);
  const [tableDescription, setTableDescription] = useState<string|null>(null);
  const [edited, setEdited] = useState(false);
  const [showIgnoreList, setShowIgnoreList] = useState(false);
  const [moduleList, setModuleList] = useState<any[]>([]);
  const [relatedTable, setRelatedTable] = useState<[string, any][]>([]);
  const [descStat, setDescStat] = useState("");

  const loadingSchema = useRef(false);
  const loadingMaster = useRef(false);
  const selectedTableRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    props.onEvent({message: "DPGetData", params: {
      name: schema.name,
      url: schema.url,
      format: schema.format,
      loading: loadingSchema,
    }});
  }, []);

  useEffect(() => {
    if (!loadingMaster.current && props.xdId) {
      props.onEvent({message: "MasterGetData", params: {
        xdId: props.xdId,
        setMasterExist: setMasterExist
      }});
    }
  }, [props.xdId]);

  useEffect(() => {
    if (props.schema && props.ihSchema) {
      setTreeDisplay(props.ihSchema?.treeDisplay);
      let tableList_: string[] = Array.from(
        new Set((props.schema?.columns || []).map((item: any) => item.table)));
      let activeTableList_: string[] = tableList_.filter(
        (table: string) => !(props.ihSchema?.ignoreList || []).includes(table))
      let moduleList_: string[] = Array.from(new Set(
        activeTableList_
        .map((table: string) => table.split("_")[0]))
      ).sort((a: string, b: string) => a < b ? -1 : 1);
      let moduleListExtra_ = moduleList_.map((module: string) => (
        {
          name: module,
          tableCount: activeTableList_.filter((table: string) => table.substring(0, module.length) == module).length
        }
      ));
      setTableList(tableList_);
      setTableListDisplay(tableList_
        .filter((table: string) => filterTerm ? table.includes(filterTerm) : true)
        .filter((table: string) => showIgnoreList ? true : !props.ihSchema?.ignoreList?.includes(table))
        .sort((a: string, b: string) => a < b ? -1 : 1)
      );
      setModuleList(moduleListExtra_);
      setDescStat(`Description: ${Object.keys(props.ihSchema?.description || {}).length} / ${activeTableList_.length}`);
    }
    selectedTableRef.current?.scrollIntoView({block: "center", inline: "nearest", behavior: "smooth"});
  }, [props.schema, props.ihSchema, filterTerm, showIgnoreList]);
  
  useEffect(() => {
    if (props.ihMaster) {
      setSelectedMaster(props.ihMaster);
    }
  }, [props.ihMaster]);

  useEffect(() => {
    refreshTableInfo();
  }, [props.ihSchema]);

  useEffect(() => {
    if (selectedTable) {
      refreshTableInfo();
    }
  }, [selectedTable]);

  useEffect(() => {
    selectedTableRef.current?.scrollIntoView({block: "center", inline: "nearest", behavior: "smooth"});
  }, [tableListDisplay, selectedTable]);

  const refreshTableInfo = () => {
    if (selectedTable) {
      setTableDescription(props.ihSchema?.description?.[selectedTable] || "");
      let relatedTable_ = Object.entries(
          (props.schema?.fk_list || [])
          .filter((fk: any) => selectedTable && fk.referenced_table === selectedTable)
          .reduce(
            (acc: any, cur: any) => {
              if (!(cur.table in acc)) {
                acc[cur.table] = []
              }
              acc[cur.table].push(cur.column);
              return acc;
            }, {}
          )
        )
        .filter((entry: [string, any]) => !((props.ihSchema?.ignoreList || []).includes(entry[0])))
        .sort((a: any, b: any) => a[0] < b[0] ? -1 : 1);
      setRelatedTable(relatedTable_);
    }
  }

  return (
    <div style={{height: "92vh", overflowY: "hidden", width: "100%"}}>
      {!props.xdId ?
      <div 
        style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        No XD selected
      </div>
      
      :props.schema?.columns ?
      <>
        <div style={{borderBottom: "solid grey 1px", display: "flex", height: "2.5vh", alignItems: "center"}}>
          {/* {selectedTable && !tableListDisplay.includes(selectedTable) && */}
          <div style={{backgroundColor: "pink", width: PANELWIDTH}}>
            {selectedTable}
          </div>
          {/* } */}
          <Icon
            name="list" size="small" fitted
            color="blue" bordered inverted={showTree}
            onClick={() => {
              setShowTree(!showTree);
              setShowIgnoreList(false);
              setFilterTerm("");
            }}
          />
          <input
            placeholder='ค้นหาขื่อตาราง'
            value={filterTerm || ""}
            onChange={(e: any) => {
              if (!showTree)
                setFilterTerm(e.target.value);
            }}
          />
          <select
            value=""
            onChange={(e: any) => {
              if (!showTree)
                setFilterTerm(`${e.target.value}_`);
            }}>
            {[{name: "Select Module", tableCount: 0}].concat(moduleList).map((module: any, moduleIndex: number) => 
            <option 
              key={moduleIndex}
              value={module.name}>
              {`${module.name} ${module.name === "Select Module" ? "" : `(${module.tableCount})`}`}
            </option>)}
          </select>
          {selectedIHMaster && selectedTable &&
          <div 
            style={{cursor: "pointer", margin: "0 10px 0 10px"}}
            onClick={() => {
              props.onEvent({message: "MasterAddTable", params: {
                masterId: props.xdId, table: selectedTable}});
            }}>
            {`+ Table`}
          </div>}
          <Checkbox
            style={{margin: "0 5px 0 5px"}}
            label="แสดง table ที่ไม่ต้องสนใจด้วย"
            checked={showIgnoreList}
            onClick={() => {
              if (!showTree)
                setShowIgnoreList(!showIgnoreList)
            }}
          />
          <div style={{margin: "0 5px 0 5px"}}>{descStat}</div>
          {selectedIHMaster &&
          <Icon
            name="download"
            color="green" inverted
            onClick={() => {
              DownloadDataAsJsonFile(selectedIHMaster, `${selectedIHMaster.name}`)
            }}
          />}
          <div style={{flex: 1}}></div>
          {selectedTable &&
          <>
          <MenuItem name="Description" mode={mode} setMode={setMode} />
          {/* <MenuItem name="Schema" mode={mode} setMode={setMode} />
          <MenuItem name="Related Table" mode={mode} setMode={setMode} remark={`(${relatedTable.length})`} /> */}
          </>}

          {selectedTable && selectedIHMaster && selectedTable in (selectedIHMaster?.data || {}) &&
          <MenuItem name="Data" mode={mode} setMode={setMode} />}
        </div>
        
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "89vh", width: "100%"}}>
          {!masterExist ?
          <div style={{textAlign: "center"}}>
            <div>Master not exists</div>
            <Button
              size="mini"
              color="blue"
              onClick={(e: any) => {
                props.onEvent({message: "MasterCreate", params: {xdId: props.xdId, setMasterExist}});
              }}>
              Create Master
            </Button>
          </div>
          
          : selectedIHMaster ?
          <>
            <div style={{width: PANELWIDTH, height: "100%"}}>
              <div style={{ height: "89vh", overflowY: "scroll", overflowX: "scroll"}}>
                {showTree ?
                <Tree
                  onEvent={props.onEvent}
                  ihSchema={props.ihSchema}
                  treeDisplay={treeDisplay}
                  listDisplay={tableListDisplay}
                  ignoreList={props.schema?.ignoreList || []}
                  selectedTable={selectedTable}
                  setSelectedTable={setSelectedTable}
                  selectedIHMaster={selectedIHMaster}
                />
                
                : tableListDisplay
                .map((table: string, tableIndex: number) => {
                  let hasData = table in selectedIHMaster.data;
                  return (
                    <div
                      key={tableIndex}
                      ref={table === selectedTable ? selectedTableRef : null}
                      onClick={(e: any) => {
                        if (selectedIHMaster && table in (selectedIHMaster?.data || {})) {
                          if (mode !== "Data") {
                            // setMode("Description");
                          }
                          setEditedData(Object.fromEntries(
                            (props.schema.columns || [])
                            .filter((column: any) => table ? column.table === table : false)
                            .map((column: any) => [
                              column.column_name,
                              column.column_type === "INTEGER" ? 1 
                              : column.column_type === "BOOLEAN" ? 1
                              : ""
                            ]))
                          );
                        } else {
                          // setMode("Description");
                        }
                        setSelectedTable(table);
                      }}
                      style={{
                        cursor: "pointer", textWrap: "nowrap",
                        color: hasData ? "blue" : "black",
                        backgroundColor: table === selectedTable ? "pink" : "white",
                        textDecoration: props.ihSchema?.ignoreList?.includes(table) ? "line-through" : "none"
                      }}>
                      {`${tableIndex + 1}. ${table}`}
                      
                      {props.ihSchema?.description && table in props.ihSchema?.description && 
                      <Icon style={{marginLeft: "5px"}} size="small" color="blue" name="file"/>}

                      {selectedIHMaster && table in (selectedIHMaster?.data || {}) &&
                      <Icon style={{marginLeft: "2px"}} size="small" color="pink" name="database"/>}
                    </div>)
                  })}
              </div>
            </div>
            <div 
              style={{flex: 1, height: "100%"}}>
              {!selectedTable ?
              <></>

              : mode === "Description" ?
              <div 
                style={{
                  display: "grid", height: "89vh",
                  gridTemplateColumns: "50% 50%", 
                  gridTemplateRows: "50% 50%"
                }}>
                <div style={{padding: "5px", borderBottom: "solid grey 1px"}}>
                  <textarea
                    style={{width: "100%", height: "80%"}}
                    value={tableDescription || ""}
                    onChange={(e: any) => {
                      setTableDescription(e.target.value);
                      setEdited(true)
                    }}
                  />
                  <Checkbox 
                    style={{marginRight: "10px"}}
                    label="ไม่ต้องสนใจ table นี้"
                    checked={props.ihSchema?.ignoreList?.includes(selectedTable)}
                    onClick={() => {
                      props.onEvent({message: "MasterSchemaUpdate", params: {
                        key: `ignoreList`,
                        value: props.ihSchema?.ignoreList?.includes(selectedTable) 
                                ? (props.ihSchema?.ignoreList || []).filter((item: string) => item !== selectedTable)
                                : (props.ihSchema?.ignoreList || []).concat([selectedTable])
                      }});
                    }}
                  />
                  <div style={{display: "flex", alignItems: "center"}}>
                    <Button
                      size="mini" color="orange"
                      disabled={!edited}
                      onClick={() => {
                        refreshTableInfo();
                        setEdited(false);
                      }}
                      >
                      Cancel
                    </Button>
                    <Button
                      size="mini" color="green"
                      disabled={!edited}
                      onClick={() => {
                        if (!selectedTable) return console.log("No selectedTable.");
                        props.onEvent({message: "MasterSchemaUpdate", params: {
                          key: `description.${selectedTable}`,
                          value: tableDescription
                        }});
                        setEdited(false);
                      }}
                      >
                      Save
                    </Button>
                    {edited && <div style={{color: "red"}}>You have unsaved edit.</div>}
                  </div>
                </div>
                <div style={{height: "100%", overflowY: "scroll", padding: "5px", borderBottom: "solid grey 1px"}}>
                  <b>Fields</b>
                  {(props.schema.columns || [])
                  .filter((column: any) => selectedTable ? column.table === selectedTable : false)
                  // .sort((a: any, b: any) => a?.column_name < b?.column_name ? -1 : 1)
                  .map((column: any, columnIndex: number) => { 
                    let relatedTableForColumn = props.schema
                      .fk_list.find((item: any) => 
                        item.table === column.table 
                        && item.column === column.column_name);
                    return (
                      <div 
                        key={columnIndex} 
                        style={{
                          display: "grid", borderBottom: "solid grey 1px",
                          gridTemplateColumns: "40% 20% 40%"
                        }}
                        >
                        <div>
                          {column.column_name}
                        </div>
                        <div>
                          {column.column_type}
                        </div>
                        {/* <div>
                          {column.null}
                        </div> */}
                        <div
                          onClick={() => {
                            if (relatedTableForColumn) {
                              setSelectedTable(relatedTableForColumn?.referenced_table);
                            }
                          }}>
                          {relatedTableForColumn ? `${relatedTableForColumn?.referenced_table} ` 
                            + `(via ${relatedTableForColumn?.referenced_column})` : ""}
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div style={{padding: "5px", overflowY: "scroll"}}>
                  {(() => {
                    let referToTableList = Object.entries(props.schema.fk_list
                      .filter((item: any) => item.table === selectedTable)
                      .reduce((acc: any, cur: any) => {
                        if (cur?.referenced_table && !(cur.referenced_table in acc)) {
                          acc[cur.referenced_table] = []
                        }
                        acc[cur.referenced_table].push(cur.column)
                        return acc
                      }, {}));
                    return(
                      <>
                        <div style={{fontWeight: "bold"}}>
                          {`Refer to (${referToTableList.length})`}
                        </div>
                        {referToTableList.map((entry: [string, any], entryIndex: number) => (
                          <li 
                            key={entryIndex}
                            onClick={() => setSelectedTable(entry[0])}>
                            {`${entry[0]} (via ${entry[1].join(", ")})`}
                          </li>
                        ))}
                      </>
                    )
                  })()}
                </div>
                <div style={{padding: "5px", overflowY: "scroll"}}>
                  <div style={{fontWeight: "bold"}}>
                    {`Referred From (${relatedTable.length})`}
                  </div>
                  {relatedTable
                  .map((entry: [string, any], entryIndex: number) => (
                    <li
                      key={entryIndex}
                      style={{cursor: "pointer"}}
                      onClick={() => {
                        setSelectedTable(entry[0]);
                      }}>
                      {`${entry[0]} (via ${entry[1].join(", ")})`}
                    </li>
                  ))}
                </div>
              </div>
              
              : mode === "Data" ?
              <div>
                <div style={{display: "flex", alignItems: "center", borderBottom: "solid grey 1px"}}>
                  <div>
                    {`Record count: ${selectedTable && (selectedTable in selectedIHMaster?.data) 
                    ? selectedIHMaster?.data?.[selectedTable]?.length : "-"}`}
                  </div>
                  <div style={{flex: 1}} />
                  <Button
                    size="mini" color="green"
                    onClick={() => {
                      if (selectedIHMaster && selectedTable) {
                        props.onEvent({message: "MasterUpdateTable", params: {
                          masterId: props.xdId,
                          table: selectedTable,
                          data: [
                            ...(selectedIHMaster?.[selectedTable] || []),
                            editedData
                          ]
                        }});
                      }
                    }}>
                    Save
                  </Button>

                </div>
                <div>
                  {(props.schema.columns || [])
                  .filter((column: any) => selectedTable ? column.table === selectedTable : false)
                  .map((column: any, columnIndex: number) => { 
                    let relatedTable = props.schema
                      .fk_list.find((item: any) => 
                        item.table === column.table 
                        && item.column === column.column_name);
                    return (
                      <div 
                        key={columnIndex} 
                        style={{
                          display: "grid", borderBottom: "solid grey 1px",
                          gridTemplateColumns: "28% 16% 28% 28%"
                        }}
                        >
                        <div>
                          {column.column_name}
                        </div>
                        <div>
                          {column.column_type}
                        </div>
                        <input 
                          onChange={(e: any) => {
                            setEditedData({
                              ...editedData,
                              [column.column_name]: e.target.value
                            })
                          }}
                          value={editedData?.[column.column_name] || ""}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
              
              :
              <></>}
            </div>
          </>

          :
          <div>Loading data...</div>}
        </div>
      </>
      :
      <></>}
    </div>
  )
}

function MenuItem(props: any) {
  return (
    <div
      onClick={() => {
        props.setMode(props.name)
      }}
      style={{
        cursor: "pointer", padding: "0 5px 0 5px",
        backgroundColor: props.name === props.mode ? "lightblue" : "white"
      }}>
      {`${props.name}${props.remark ? " " + props.remark : ""}`}
    </div>
  )
}

function Tree(props: any) {
  const [ungroupedList, setUngroupedList] = useState<string[]>([]);
  const [toMoveTable, setToMoveTable] = useState<string|null>(null);
  const [fromLoc, setFromLoc] = useState<{groupIndex: number, itemIndex: number}|null>(null);
  const [toLoc, setToLoc] = useState<{groupIndex: number, itemIndex: number}|null>(null);
  const [groupToEdit, setGroupToEdit] = useState<any>(null);
  const [moveWithContextMenu, setMoveWithContextMenu] = useState(false);
  const [shownGroupList, setShownGroupList] = useState<number[]>([]);

  useEffect(() => {
    let treeDisplayItems = (props.treeDisplay || []).flatMap((group: any) => group?.items || []);
    let ungroupedList_ = (props.listDisplay || [])
      .filter((table: string) => !props.ignoreList.includes(table) && !treeDisplayItems.includes(table));
    setUngroupedList(ungroupedList_);
  }, [props.listDisplay, props.treeDisplay, props.ignoreList]);  

  const handleMove = () => {
    if (toMoveTable && toLoc && !(fromLoc && (
        (toLoc.groupIndex === fromLoc.groupIndex && toLoc.itemIndex === fromLoc.itemIndex)
        || 
        (toLoc.groupIndex === fromLoc.groupIndex && toLoc.itemIndex === fromLoc.itemIndex + 1)
        ))
    ) {
      props.onEvent({message: "MasterMoveTableInTree", 
        params: {toMoveTable, toLoc, fromLoc}
      });
    }
    setToMoveTable(null);
    setFromLoc(null);
    setToLoc(null);
  }

  return(
    <div>
      {props.treeDisplay.map((group: any, groupIndex: number) => 
      <div 
        key={groupIndex} 
        style={{ width: "100%" }}
        >
        <div
          style={{
            width: "100%", display: "flex", alignItems: "center",
            borderBottom: toLoc && toLoc.groupIndex === groupIndex 
                          && toLoc.itemIndex === 0 
                          ? "solid red 1px" : "none",
            backgroundColor: "lightblue",
          }}
          onDragEnter={() => {
            setToLoc({groupIndex, itemIndex: 0});
          }}
          >
          <Icon 
            name={shownGroupList.includes(groupIndex) ? "angle down" : "angle right"}
            size="small" color="blue" 
            style={{marginRight: "5px"}} 
            onClick={(e: any) => {
              e.stopPropagation();
              if (shownGroupList.includes(groupIndex)) {
                setShownGroupList(shownGroupList.filter((index: number) => index !== groupIndex));
              } else {
                setShownGroupList([...shownGroupList, groupIndex])
              }
            }}
          />
          <div
            style={{
              cursor: "pointer",
              fontWeight: group.items.includes(props.selectedTable) ? "bold" : "normal"
            }}
            onClick={() => {
              setGroupToEdit({
                index: groupIndex,
                ...group
              });
            }}>
            {`${group?.name} (${group.items.length})`}
          </div>
          <div style={{flex: 1}} />
          {group.items.length === 0 &&
          <Icon 
            name="delete" size="small" color="red"
            onClick={(e: any) => {
              e.stopPropagation();
              setShownGroupList([]);
              props.onEvent({message: "MasterTreeGroup", params: {groupIndex, action: "delete"}});
            }}
          />}
          {groupIndex > 0 &&
          <Icon 
            name="angle double up" size="small" color="orange"
            onClick={(e: any) => {
              e.stopPropagation();
              setShownGroupList([]);
              props.onEvent({message: "MasterTreeGroup", params: {groupIndex, action: "swapup"}});
            }}
          />}
          {groupIndex + 1 < props.treeDisplay?.length &&
          <Icon 
            name="angle double down" size="small" color="orange"
            onClick={(e: any) => {
              e.stopPropagation();
              setShownGroupList([]);
              props.onEvent({message: "MasterTreeGroup", params: {groupIndex, action: "swapdown"}});
            }}
          />}
          <Icon 
            name="add" size="small" color="green"
            onClick={(e: any) => {
              e.stopPropagation();
              setShownGroupList([]);
              props.onEvent({message: "MasterTreeGroup", params: {groupIndex, action: "add"}});
            }}
          />
        </div>
        {shownGroupList.includes(groupIndex) &&
        (group?.items || []).map((item: string, itemIndex: number) => (
          <div 
            key={itemIndex} 
            style={{
              marginLeft: "10px",
              borderBottom: toLoc && toLoc.groupIndex === groupIndex 
                            && toLoc.itemIndex === itemIndex + 1
                            ? "solid red 1px" : "none",
              backgroundColor: props.selectedTable === item ? "pink" : "white"
            }}
            draggable
            onDragStart={() => {
              setToMoveTable(item);
              setFromLoc({groupIndex, itemIndex});
            }}
            onDragEnd={() => {
              handleMove();
            }}
            onDragEnter={() => {
              setToLoc({groupIndex, itemIndex: itemIndex + 1});
            }}
            onClick={() => {
              props.setSelectedTable(item);
            }}
            onContextMenu={(e: any) => {
              e.preventDefault();
              setMoveWithContextMenu(true);
              setToMoveTable(item);
              setFromLoc({groupIndex, itemIndex});
            }}
            >
            {item}
            
            {props.ihSchema?.description && item in props.ihSchema?.description && 
            <Icon style={{marginLeft: "2px"}} size="small" color="blue" name="file"/>}
            
            {props.selectedIHMaster && item in (props.selectedIHMaster?.data || {}) &&
            <Icon style={{marginLeft: "2px"}} size="small" color="pink" name="database"/>}
          </div>
        ))}
      </div>)}
      {ungroupedList.length > 0 &&
      <div
        style={{backgroundColor: "lightgrey"}}>
        {`Ungrouped (${ungroupedList.length})`}
      </div>}
      {ungroupedList.map((table: string, tableIndex: number) => (
        <div 
          key={tableIndex} 
          style={{
            marginLeft: "10px", position: "relative",
            backgroundColor: props.selectedTable === table ? "lightgreen" : "white"
          }}
          draggable
          onDragStart={() => {
            setToMoveTable(table);
            setFromLoc(null);
          }}
          onDragEnd={() => {
            handleMove();
          }}
          onClick={() => {
            props.setSelectedTable(table);
          }}
          onContextMenu={(e: any) => {
            e.preventDefault();
            setMoveWithContextMenu(true);
            setToMoveTable(table);
            setFromLoc(null);
          }}
          >
          {table}
          {props.ihSchema?.description && table in props.ihSchema?.description && 
          <Icon style={{marginLeft: "5px"}} size="small" color="blue" name="file"/>}
        </div>
      ))}
      <Modal
        open={groupToEdit !== null}
        size="mini"
        style={{padding: "20px"}}
        closeIcon={true}
        closeOnDimmerClick
        onClose={() => setGroupToEdit(null)}>
        <Input
          value={groupToEdit?.name || ""}
          onChange={(e: any) => {
            setGroupToEdit({
              ...groupToEdit,
              name: e.target.value
            })
          }}
        />
        <Button size="mini" color="orange"
          onClick={() => setGroupToEdit(null)}>
          Cancel
        </Button>
        <Button size="mini" color="green"
          onClick={() => {
            props.onEvent({message: "MasterTreeGroup", params: { 
              groupIndex: groupToEdit.index, action: "rename", name: groupToEdit.name || "No name"}});
            setGroupToEdit(null);
          }}>
          Save
        </Button>
      </Modal>
      <Modal
        open={moveWithContextMenu}
        size="mini"
        style={{padding: "20px"}}
        closeIcon={true}
        closeOnDimmerClick
        onClose={() => {
          setMoveWithContextMenu(false);
          setToMoveTable(null);
          setToLoc(null);
          setFromLoc(null);
        }}>
        {props.treeDisplay.map((group: any, groupIndex: number) => (
          <div 
            key={groupIndex}
            style={{cursor: "pointer", padding: "5px"}}
            onClick={(e: any) => {
              props.onEvent({message: "MasterMoveTableInTree", params: {
                toMoveTable, fromLoc,
                toLoc: { groupIndex, itemIndex: group.items.length }
              }});
              setMoveWithContextMenu(false);
              setToMoveTable(null);
              setToLoc(null);
              setFromLoc(null);
            }}>
            {group.name}
          </div>
        ))}
      </Modal>
    </div>
  )
}


// : mode === "Schema" ?
// <div style={{height: "89vh", overflowY: "scroll"}}>
//   {(props.schema.columns || [])
//   .filter((column: any) => selectedTable ? column.table === selectedTable : false)
//   // .sort((a: any, b: any) => a?.column_name < b?.column_name ? -1 : 1)
//   .map((column: any, columnIndex: number) => { 
//     let relatedTableForColumn = props.schema
//       .fk_list.find((item: any) => 
//         item.table === column.table 
//         && item.column === column.column_name);
//     return (
//       <div 
//         key={columnIndex} 
//         style={{
//           display: "grid", borderBottom: "solid grey 1px",
//           gridTemplateColumns: "28% 16% 28% 28%"
//         }}
//         >
//         <div>
//           {column.column_name}
//         </div>
//         <div>
//           {column.column_type}
//         </div>
//         {/* <div>
//           {column.null}
//         </div> */}
//         <div
//           onClick={() => {
//             if (relatedTableForColumn) {
//               setSelectedTable(relatedTableForColumn?.referenced_table);
//             }
//           }}>
//           {relatedTableForColumn ? `${relatedTableForColumn?.referenced_table} ` 
//             + `(via ${relatedTableForColumn?.referenced_column})` : ""}
//         </div>
//         <div>
//         </div>
//       </div>
//     )
//   })}
// </div>

// : mode === "Related Table" ?
// <div style={{height: "89vh", display: "flex"}}>
//   <div style={{flex: 1}}>
//     <div style={{fontWeight: "bold"}}>
//       Refer to
//     </div>
//     {Object.entries(props.schema.fk_list
//       .filter((item: any) => item.table === selectedTable)
//       .reduce((acc: any, cur: any) => {
//         if (cur?.referenced_table && !(cur.referenced_table in acc)) {
//           acc[cur.referenced_table] = []
//         }
//         acc[cur.referenced_table].push(cur.column)
//         return acc
//       }, {})
//     ).map((entry: [string, any], entryIndex: number) => (
//       <div key={entryIndex}>
//         {`${entry[0]} (via ${entry[1].join(", ")})`}
//       </div>
//     ))}
//   </div>
//   <div style={{flex: 1, height: "89vh", overflowY: "scroll"}}>
//     <div style={{fontWeight: "bold"}}>
//       Referred From
//     </div>
//     {relatedTable
//     .map((entry: [string, any], entryIndex: number) => (
//       <div 
//         key={entryIndex}
//         style={{cursor: "pointer"}}
//         onClick={() => {
//           setSelectedTable(entry[0]);
//         }}>
//         {`${entry[0]} (via ${entry[1].join(", ")})`}
//       </div>
//     ))}
//   </div>
// </div>