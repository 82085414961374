import { useEffect, useRef, useState } from "react";
import { query } from 'ismor-lib/utils/DuckLib';
import VegaEmbed from "ismor-lib/utils/VegaEmbed";
import { Checkbox } from "semantic-ui-react";
import { toUser } from "ismor-lib/utils/Utils";

export default function XDTestSummary(props: any) {
  const [mode, setMode] = useState("Role");
  const [xdId, setXdId] = useState<string|null>(null);
  
  const [xdDataReady, setXdDataReady] = useState<string|null>(null);
  const [displayData, setDisplayData] = useState<any[]>([]);
  const [issueData, setIssueData] = useState<any[]>([]);
  const [nodeData, setNodeData] = useState<any[]>([]);
  const [stepData, setStepData] = useState<any[]>([]);
  const [showZero, setShowZero] = useState(false);
  const isLoading = useRef(false);

  useEffect(() => {
    if (isLoading.current && props.xdId === xdId) return;
    setDisplayData([]);
    setXdDataReady(null);
    isLoading.current = true;
    setXdId(xdId);
  }, [props.xdId]);

  useEffect(() => {
    if (props.xddata.name !== "")
      setXdDataReady("ok")
  }, [props.xddata]);

  useEffect(() => {
    if (props.testDataReady === "ok" && xdDataReady === "ok" && props.duckCon) {
      isLoading.current = false;
      (async () => {
        try {
          // List ---------------------------------------------------------------------
          let result = await query(`from xdtestresult;`, props.duckCon);
          setDisplayData(result);

          let issueResult = await query(`
            with temp as (
              from xdtestresult 
              where datetime is distinct from null
              order by issueId, datetime
            )
            from temp
            select 
              last(datetime) datetime,
              issueId,
              last(tester) tester,
              last(status) status,
              last(step) step,
              last(node) node,
            group by all
            order by datetime desc
          `, props.duckCon);
          setIssueData(issueResult);

          // Role ---------------------------------------------------------------------
          let result1 = await query(`
            with temp as (
              from xdtestresult
              order by issueId, datetime
            ),
            temp1 as (
              from temp
              select 
                issueId, 
                node,
                last(status) latest_status,
                last(datetime) latest_datetime
              group by all
            )
            from temp1
            select 
              node,
              latest_status,
              count(issueId) item_count
            group by all
            order by node, latest_status
            ;`, 
            props.duckCon);
          let nodeDataDict1 = result1.reduce((acc: any, cur: any) => {
            if (!Object.keys(acc).includes(cur.node))
              acc[cur.node] = { passed: 0, failed: 0 }
            acc[cur.node][cur.latest_status] = Number(cur.item_count);
            return acc;
          }, {});
          let nodeData1 = (props.xddata?.nodes || [])
          .flatMap((nodeId: string, nodeIndex: number) => ([
            {
              nodeId: nodeId,
              role: `${props.xddata?.nodeMaster?.[nodeId]?.name}` 
                    || `Node ${nodeId}`,
              latest_status: "passed",
              item_count: nodeDataDict1[nodeId]?.passed || 0
            },
            {
              nodeId: nodeId,
              role: `${props.xddata?.nodeMaster?.[nodeId]?.name}` 
                    || `Node ${nodeId}`,
              latest_status: "failed",
              item_count: nodeDataDict1[nodeId]?.failed || 0
            },

          ]))
          .filter((item: any) => showZero ? true : item?.item_count !== 0);
          setNodeData(nodeData1);

          // Step ---------------------------------------------------------------------
          let result2 = await query(`
            with temp as (
              from xdtestresult
              order by issueId, datetime
            ),
            temp1 as (
              from temp
              select 
                issueId, 
                step,
                last(status) latest_status,
                last(datetime) latest_datetime
              group by all
            )
            from temp1
            select 
              step,
              latest_status,
              count(issueId) item_count
            group by all
            order by step, latest_status
            ;`, 
            props.duckCon);

          let stepDataDict1 = result2.reduce((acc: any, cur: any) => {
            if (!Object.keys(acc).includes(cur.step))
              acc[cur.step] = { passed: 0, failed: 0 }
            acc[cur.step][cur.latest_status] = Number(cur.item_count);
            return acc;
          }, {});

          let stepData1 = (props.xddata?.sections || [])
            .filter((section: any) => !section?.inactive)
            .flatMap((section: any, sectionIndex: number) => (
              (section?.steps).flatMap((stepId: string, stepIndex: number) => ([
                {
                  seq: Number(`${sectionIndex + 1}.${stepIndex + 1}`),
                  step: `${sectionIndex + 1}.${stepIndex + 1}. ${props.xddata?.steps?.[stepId]?.description || stepId}`,
                  latest_status: "passed",
                  item_count: stepDataDict1[stepId]?.passed || 0
                },
                {
                  seq: Number(`${sectionIndex + 1}.${stepIndex + 1}`),
                  step: `${sectionIndex + 1}.${stepIndex + 1}. ${props.xddata?.steps?.[stepId]?.description || stepId}`,
                  latest_status: "failed",
                  item_count: stepDataDict1[stepId]?.failed || 0
                },
              ]))
            ))
            .filter((item: any) => showZero ? true : item?.item_count !== 0)
          setStepData(stepData1);
        } catch (e: any) { console.log(e); }      
      })();
    }
  }, [props.testDataReady, showZero, xdDataReady]);

  return(
    <div style={{height: "100%"}}>
      {!props.testDataReady ?
      <div
        style={{
          width: "100%", height: "100%", display: "flex", 
          justifyContent: "center", alignItems: "center"
        }}>
        Loading data
      </div>
      :
      <div style={{height: "100%"}}>
        {displayData.length === 0 || props.testDataReady === "error" ?
          <div
            style={{
              width: "100%", height: "100%", display: "flex", 
              justifyContent: "center", alignItems: "center"
            }}>
            No data to display
          </div>
        : <div>
            <div style={{display: "flex", borderBottom: "solid #cccccc 1px", width: "100%"}}>
              {["Role", "Step", "Latest", "List"].map((menu: string, menuIndex: number) => (
                <div 
                  key={menuIndex}
                  style={{
                    cursor: "pointer",
                    paddingLeft: "10px", paddingRight: "10px",
                    backgroundColor: menu === mode ? "lightgreen" : "white"
                  }}
                  onClick={() => setMode(menu)}>
                  {menu}
                </div>
              ))}
              <div style={{flex: 1}}></div>
              <Checkbox
                checked={showZero}
                onClick={() => setShowZero(!showZero)}
                label="แสดงสิ่งที่ยังไม่ถูกทดสอบด้วย"
              />
            </div>
            <div style={{height: "90vh", overflowY: "scroll"}}>
              {mode === "List" ?
                displayData
                .sort((a: any, b: any) => a.datetime < b.datetime ? 1 : -1)
                .map((item: any, index: number) => {
                  let tester = toUser(item.tester);
                  return(
                  <div
                    style={{display: "flex", borderBottom: "solid #cccccc 1px"}}
                    key={index}> 
                    <div style={{flex: 2}}>{item.datetime}</div>
                    <div style={{flex: 1}}>{item.issueId}</div>
                    <div style={{flex: 1}}>{tester}</div>
                    <div style={{flex: 1}}>{item.status}</div>
                    {/* <div style={{flex: 1}}>{item.closedAt}</div> */}
                    {/* <div style={{flex: 1}}>{item.testId}</div> */}
                    <div style={{flex: 3}}>{props.xddata?.steps?.[item.step]?.description}</div>
                    <div style={{flex: 2}}>{props.xddata?.nodeMaster?.[item.node]?.name}</div>
                  </div>)
                })
              :  mode === "Latest" ?
                issueData
                .sort((a: any, b: any) => a.datetime < b.datetime ? 1 : -1)
                .map((item: any, index: number) => {
                  let tester = toUser(item.tester);
                  return (
                  <div
                    style={{display: "flex", borderBottom: "solid #cccccc 1px"}}
                    key={index}> 
                    <div style={{flex: 2}}>{item.datetime}</div>
                    <div style={{flex: 1}}>{item.issueId}</div>
                    <div style={{flex: 1}}>{tester}</div>
                    <div style={{flex: 1}}>{item.status}</div>
                    {/* <div style={{flex: 1}}>{item.closedAt}</div> */}
                    {/* <div style={{flex: 1}}>{item.testId}</div> */}
                    <div style={{flex: 3}}>{props.xddata?.steps?.[item.step]?.description}</div>
                    <div style={{flex: 2}}>{props.xddata?.nodeMaster?.[item.node]?.name}</div>
                  </div>)
                })
              : mode === "Role" ?
              <>
                {nodeData?.length > 0 ? 
                <VegaEmbed
                  data={{
                    title: "By Role",
                    data: {values: nodeData},
                    transform: [{
                      calculate: 'if(datum.latest_status === "passed", 0, if(datum.latest_status === "failed", 1, 2))',
                      as: "siteOrder"
                    }],
                    width: 900,
                    mark: "bar",
                    encoding: {
                      y: { field: "role", type: "ordinal", sort: "nodeId" },
                      x: { field: "item_count", type: "quantitative" },
                      color: { 
                        field: "latest_status", 
                        type: "ordinal",
                        scale: {
                          domain: ["failed", "passed"],
                          range: ["#ff0000", "#00ff00"]
                        }
                      },
                      order: { field: "siteOrder"}
                    }
                  }}
                />
                :
                <div
                  style={{
                    width: "100%", height: "100%", display: "flex", 
                    justifyContent: "center", alignItems: "center"
                  }}>
                  No Role Data
                </div>}
              </>
              : mode === "Step" ?
              <>
                {stepData?.length > 0 ? 
                <VegaEmbed
                  data={{
                    title: "By Step",
                    data: {values: stepData},
                    transform: [{
                      calculate: 'if(datum.latest_status === "passed", 0, if(datum.latest_status === "failed", 1, 2))',
                      as: "siteOrder"
                    }],
                    width: 800,
                    // height: 600,
                    mark: "bar",
                    encoding: {
                      y: { field: "step", type: "ordinal", sort: "seq" },
                      x: { field: "item_count", type: "quantitative"},
                      color: {
                        field: "latest_status",
                        type: "ordinal",
                        scale: {
                          domain: ["failed", "passed"],
                          range: ["#ff0000", "#00ff00"]
                        }
                      },
                      order: { field: "siteOrder"}
                    }
                  }}
                />
                :
                <div
                  style={{
                    width: "100%", height: "100%", display: "flex", 
                    justifyContent: "center", alignItems: "center"
                  }}>
                  No Step Data
                </div>}
              </>
              :
              <></>
              }
            </div>
          </div>
        }
      </div>}
    </div>
  )
}