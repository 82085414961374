import React, { useEffect, useRef, useState } from "react";
import { Input, Icon, Modal, Button } from "semantic-ui-react";
import { MenuItem } from "../UIGEN/CreateUIv2";
import RenderEditor from "./RenderEditor";
import GetProp from "react-lib/frameworks/GetProp";
import RenderSourceCode from "./RenderSourceCode";

const PDF = (props: any) => {
  const [mode, setMode] = useState("Editor")
  const [openBrowser, setOpenBrowser] = useState(false);
  const [openFileDialog, setOpenFileDialog] = useState(false);
  const [openImages, setOpenImages] = useState(false);
  const [fileMode, setFileMode] = useState("New");

  useEffect(() => {
    props.onEvent({message: "GenPDF", params: {action: "Init"}})
  }, [])

  const convertUploadedText = (text: string) => {
    let mode: "normal" | "prop" = "normal";
    let currentItem = "";
    let output: any[] = [];
    let lines = text.split("\n");
    for (const lineIndex in lines) {
      output[lineIndex] = [];
      for (const char of lines[lineIndex]) {
        if (mode === "normal" && char === "[") {
          output[lineIndex].push(
            {mode: "normal", item: currentItem, width: currentItem.length}
          );
          currentItem = "";
          mode = "prop";
        } else if (mode === "prop" && char === "]") {
          output[lineIndex].push(
            {mode: "prop", item: currentItem.replaceAll(" ", ""), width: currentItem.length}
          );
          currentItem = "";
          mode = "normal";
        } else {
          currentItem += char
        }
      }
      if (currentItem !== "") {
        if (mode === "normal") {
          output[lineIndex].push(
            {mode: "normal", item: currentItem, width: currentItem.length}
          );
        } else {
          output[lineIndex].push(
            {mode: "prop", item: currentItem.replaceAll(" ", ""), width: currentItem.length}
          );
        }
      }
      currentItem = "";
      mode = "normal";
    }
    props.onEvent({message: "PDFSetContent", params: {content: output}});
  }

  let formRefDict = (props.formRefList || []).reduce((acc: any, cur: any) => {
    if (!Object.keys(acc).includes(cur.project))
      acc[cur.project] = []
    acc[cur.project].push(cur);
    return acc
  }, {});

  for (const key in formRefDict) {
    formRefDict[key] = formRefDict[key].sort((a: any, b: any) => a.name < b.name ? -1 : 1)
  }

  return(
    <div>
      <div style={{ 
        width: "100%", 
        height: "91vh", 
        overflowY: "hidden",
        display: "grid",
        gridTemplateColumns: "50% 50%"}}>
        <div>
          <TopBar 
            onEvent={props.onEvent}
            openBrowser={openBrowser}
            setOpenBrowser={setOpenBrowser}
            mode={mode}
            setMode={setMode}
            fileMode={fileMode}
            setFileMode={setFileMode}
            formRefDict={formRefDict}
            selectedFormRef={props.selectedFormRef}
            setOpenImages={setOpenImages}
            setOpenFileDialog={setOpenFileDialog}
            convertUploadedText={convertUploadedText}
          />

          {mode === "Editor" ?
          <FormEditor
            onEvent={props.onEvent}
            setProp={props.setProp}
            formDef={props.formDef}
          />:

          mode === "Source Code" ?
          <textarea 
            style={{ overflowY: "scroll", width: "100%", height: "100%" }}
            readOnly
            value={RenderSourceCode(props.formDef)} 
          />:

          // mode === "Editor"?
          // <Editor
          //   onEvent={props.onEvent}
          //   formDef={props.formDef}
          //   setFormDef={props.setFormDef}
          // />:

          <></>
          }
        </div>

        <div style={{ width: "100%", height: "100%" }}>
          <iframe style={{ width: "100%", height: "100%" }} src={props.pdfSrc || ""} />
        </div>
        
      </div>
      <Modal  
        open={openImages}
        size='small'
        closeOnDimmerClick={true}
        onClose={() => setOpenImages(false)}>
        <ImagePanel 
          setProp={props.SetProp}
          onEvent={props.onEvent}
          images={props.selectedFormRef?.images || {}}
        />
      </Modal>
      <Modal  
        open={openFileDialog}
        size='tiny'
        closeOnDimmerClick={true}
        onClose={() => setOpenFileDialog(false)}>
        <FileDialog
          setProp={props.SetProp}
          onEvent={props.onEvent}
          fileMode={fileMode}
          fileId={props.selectedFormRef?.id}
          name={props.selectedFormRef?.name}
          project={props.selectedFormRef?.project}
          setOpenFileDialog={setOpenFileDialog}
          images={props.selectedFormRef?.images || {}}
        />
      </Modal>
    </div>
    
  )
}

const FileDialog = (props: any) => {
  const [name, setName] = useState(props.fileMode === "New" ? "" : props.name);
  const [project, setProject] = useState(props.fileMode === "New" ? "" : props.project);

  return(
    <div
      style={{minHeight: "400px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
      <div>{props.fileMode === "New" ? "สร้างฟอร์มใหม่" : "แก้ไขชื่อและ/หรือโปรจกต์"}</div>
      <div style={{marginBottom: "20px", marginTop: "20px"}}>
        <Input placeholder="ชื่อฟอร์ม" style={{width: "400px"}}
          value={name}
          onChange={(e: any) => setName(e.target.value)}
        />
      </div>
      <div style={{marginBottom: "20px"}}>
        <Input placeholder="โปรเจกต์" fluid style={{width: "400px"}}
          value={project}
          onChange={(e: any) => setProject(e.target.value)}
        />
      </div>
      <div style={{marginBottom: "20px"}}>
        <Button size="mini" color="green"
          onClick={(e: any) => {
            props.setOpenFileDialog(false);
            if (props.fileMode === "New") {
              props.onEvent({message: "PDFAddNewFile", params: {name, project}});
            } else {
              props.onEvent({message: "EditPDFNameAndProject", params: {id: props.fileId, name, project}});
            }
          }}>
          Save
        </Button>
        <Button size="mini" color="red"
          onClick={(e: any) => props.setOpenFileDialog(false)}>
          Cancel
        </Button>
      </div>
    </div>)
}

const ImagePanel = (props: any) => {
  const [name, setName] = useState("");
  const [fileName, setFileName] = useState<string|null>(null);
  const [adding, setAdding] = useState(false);
  const [imgBlob, setImgBlob] = useState<Blob | null>(null);
  const imgRef = useRef<HTMLImageElement>(null);
 
  return(
    <div
      style={{minHeight: "400px"}}>
      {adding ?
      <div 
        style={{
          display: "flex", flexDirection: "column", 
          alignItems: "center", justifyContent: "space-around", height:"400px"
        }}>
        <Input 
          size="mini" placeholder="ชื่อภาพ (ไม่เว้นวรรค)" 
          value={name}
          onChange={(e: any) => {
            e.preventDefault();
            let newValue = e.target.value.replaceAll(" ", "");
            console.log(newValue);
            setName(newValue);
          }}
        />
        <input 
          type="file"
          onClick={(e: any) => e.target.value = null}
          onChange={async (e: any) => {
            let file: File = e?.target?.files[0];
            if (!file || !imgRef?.current) return;
            setFileName(file.name);
            const arrayBuffer: ArrayBuffer = 
              await (new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.onload = () => resolve(reader.result as ArrayBuffer);
                reader.onerror = reject;
                reader.readAsArrayBuffer(file);
              }));
            let blob = new Blob([arrayBuffer]);
            setImgBlob(blob);
            const picUrl = URL.createObjectURL(blob);
            imgRef.current.src = picUrl
          }}          
        />
        <img ref={imgRef} />
        <div>
        <Button 
          size="mini" 
          color="blue" 
          disabled={name === "" || !imgBlob} 
          onClick={(e: any) => {
            props.onEvent({message: "PDFAddImage", params: {name, fileName, imgBlob}});
            setName("");
            setImgBlob(null);
            setAdding(false);
          }}>
          Done
        </Button>
        <Button 
          size="mini" 
          color="red" 
          onClick={(e: any) => {
            setName("");
            setImgBlob(null);
            setAdding(false);
          }}>
          Cancel
        </Button>
        </div>
      </div>
      :
      <div
        style={{minHeight: "400px", display: "grid", gridTemplateColumns: "20% 80%", padding: "5px"}}>
        <div style={{borderRight: "solid #dddddd 1px"}}>
          {Object.entries(props.images).map((entry: [string, any], entryIndex: number) => (
            <div 
              key={entryIndex}
              style={{cursor: "pointer", borderBottom: "solid #cccccc 1px", padding: "2px"}}>
              {entry[0]}
            </div>
          ))}
          <div 
            onClick={(e: any) => setAdding(true)}
            style={{cursor: "pointer", borderBottom: "solid #cccccc 1px", padding: "2px"}}>
            <span>Add new image</span>
            <Icon name="plus"/>
          </div>
        </div>
        <div>Display</div>
      </div>
      }
  </div>)

}

const TopBar = (props: any) => {
  const [copying, setCopying] = useState(false);
  const [saving, setSaving] = useState(false);
  const [pasteText, setPasteText] = useState("");
  const [showPastePanel, setShowPastePanel] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  return(
    <div style={{ display: "flex", alignItems: "center", backgroundColor: "#fee6c8", position: "relative" }} >
      <Modal
        closeOnDimmerClick={true}
        open={showPastePanel}
        onClose={(e: any) => setShowPastePanel(false)}>
        <div>
          <div>
            <textarea
              style={{width: "100%", height: "400px"}}
              value={pasteText}
              onChange={(e: any) => {
                setPasteText(e.target.value)
              }}
            />
          </div>
          <Button size="mini" color="red"
            onClick={(e: any) => {
              setShowPastePanel(false);
              setPasteText("");
            }}>
            cancel
          </Button>
          <Button size="mini" color="green"
            onClick={(e: any) => {
              setShowPastePanel(false);
              props.convertUploadedText(pasteText);
              setPasteText("");
            }}>
            Save
          </Button>
        </div>
      </Modal>
      <MenuItem
        onClick={(e: any) => props.setOpenBrowser(!props.openBrowser)}
        children={<Icon name="folder"/>}
        hilight={props.openBrowser}
      />
      <MenuItem
        onClick={(e: any) => { 
          props.onEvent({ message: "SavePDF", params: {} })
          setSaving(true);
          setTimeout(() => {
            setSaving(false)
          }, 200);
        }}
        children={<Icon name="save"/>}
        hilight={saving}
      />
      <MenuItem
        onClick={(e: any) => {
          props.setFileMode("New");
          props.setOpenFileDialog(true);
        }}
        children={<Icon name="add"/>}
      />
      <MenuItem 
        onClick={(e: any) => {
          setShowPastePanel(true);
        }}
        children={<Icon name="paste"/>}
      />
      <MenuItem 
        onClick={(e: any) => {
          fileInputRef?.current?.click();
        }}
        children={<Icon name="cloud upload"/>}
      />
      <input 
        type="file" hidden ref={fileInputRef} 
        onClick={(e: any) => e.target.value = null}
        onChange={async (e: any) => {
          if (e.target?.files?.[0]) {
            let file: File = e?.target?.files[0];
            const text: string = 
              await (new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.onload = () => resolve(reader.result as string);
                reader.onerror = reject;
                reader.readAsText(file);
              }));
            props.convertUploadedText(text);
          }
        }}
      />
      <MenuItem
        onClick={(e: any) => {
          props.onEvent({message: "PDFMakeDownload", params: {}})
          setCopying(true);
          setTimeout(() => {
              setCopying(false)
          }, 200);
        }}
        hilight={copying}
        children={<Icon name="cloud download"/>}
      />
      <MenuItem
        onClick={(e: any) => {
          props.setOpenImages(true);
        }}
        children={<Icon name="images"/>}
      />
      <MenuItem 
        onClick={(e: any) => { props.onEvent({ message: "RefreshPDF", params: {} })}}
        children={<Icon name="refresh"/>}
      />
      <div style={{ flex: 1 }}></div>
      {props.selectedFormRef && 
      <div 
        style={{display: "flex", alignItems: "center"}}
        onClick={(e: any) =>{
          props.setFileMode("Edit");
          props.setOpenFileDialog(true);
        }}>
        <b>Name: </b>
        <div style={{padding: "0 5px 0 5px"}}>{props.selectedFormRef?.name || ""}</div>
        <b>Project: </b>
        <div style={{padding: "0 5px 0 5px"}}>{props.selectedFormRef?.project || ""}</div>
        {/* <div style={{padding: "0 5px 0 5px", color: "grey"}}>
          {`[${props.selectedFormRef?.id}]`}
        </div> */}
      </div>}
      <div style={{ flex: 1 }}></div>
      <MenuItem
        onClick={(e: any) => { props.setMode("Editor"); } }
        hilight={props.mode=="Editor"}
        children={<Icon name="sitemap"/>}
      />
      <MenuItem
        onClick={(e: any) => { props.setMode("Source Code"); } }
        hilight={props.mode=="Source Code"}
        children={<Icon name="code"/>}
      />
      
      {/* <MenuItem
        onClick={(e: any) => { props.setMode("Editor"); } }
        hilight={props.mode=="Editor"}
        children={<div>Editor</div>}
      /> */}
      <div 
        style={{
          position: "absolute", width: "300px", zIndex: 100,
          minHeight: "200px", maxHeight: "400px",
          top: 30, left: 3, backgroundColor: "white", 
          border: "solid black 1px", overflowY: "auto",
          visibility: props.openBrowser ? "visible" : "hidden"
        }}>
        {Object.keys(props.formRefDict).map((project: string, project_index: number) => (
        <div key={project_index} style={{cursor: "pointer"}}>
          {project}
          {props.formRefDict[project].map((formItem: any, form_index: number) => (
          <div 
            key={form_index} style={{ marginLeft: "10px", cursor: "pointer"}}
            onClick={(e: any) => {
              props.onEvent({message: "GenPDF", params: {
                action: "SelectForm", 
                formRef: formItem
              }});
              props.setOpenBrowser(false);
            }}>
            {formItem.name}
          </div>))}
        </div>))}
      </div>
    </div>
  )
}

const FormEditor = (props: any) => {
  const [openDataKey, setOpenDataKey] = useState<string | null>(null);

  return(
    <div style={{ border: "solid #cccccc 1px", overflow: "scroll", height: "94vh", paddingBottom: "100px" }}>
      <RenderEditor
        onEvent={props.onEvent}
        setProp={props.setProp}
        openDataKey={openDataKey}
        setOpenDataKey={setOpenDataKey}
        first={false}
        last={false}
        obj={props.formDef}
        parentKey="root"
        dataKey=""
        root={true}
      />
    </div>
  )
}

const Editor = (props: any) => {
  const [selected, setSelected] = useState("")

  const getProp = GetProp({ state: props.formDef }, "")

  const display = (accessor: string) => { 
    console.log(accessor)
    console.log(getProp(accessor))
  }

  const handleClick = (accessor: string) => {
    display(accessor)
    setSelected(accessor)
  }

  const output = toRepresentation(
    props.formDef?.content || [], 
    [], 
    "content", 
    0, 
    display,
    handleClick,
    selected,
    setSelected)
  // const output: any[] = []
  // console.log(output)

  const detail = getProp(selected)

  return(
    <div style={{ display: "grid", gridTemplateColumns: "50% 50%", border: "solid #cccccc 1px"}}>
      <div>{output.map((node: any, index: number) => 
        <div key={index}>{node}</div>)}
      </div>
      <div
        style={{ border: "solid #cccccc 1px"}}>
        <div>
          Details
          <button
            onClick={(e: any) => { props.onEvent({ message: "RefreshPDF", params: {} })}}>
            Refresh
          </button>
          <button
            onClick={(e: any) => { props.onEvent({ message: "SavePDF", params: {} })}}>
            Save
          </button>
        </div>
        <div style={{ width: "100%" }}>
          {detail && Object.keys(detail).length > 0 && Object.keys(detail).map(key => (
            <div style={{ width: "100%", display: "flex" }}>
              <div style={{ flex: 1 }}>{key}</div>
              <input 
                style={{ flex: 1 }} 
                value={detail[key]}
                onChange={(e) => { props.setFormDef(`${selected}.${key}`, e.target.value) }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const toRepresentation = (nodeList: any[], output: any[], key: string, level: number, 
  display: any, handleClick: any, selected: string, setSelected: any) => {
  for (const [index, node] of Array.from(nodeList.entries())) {
    // console.log(node)
    if (Object.keys(node).includes("columns")) {
      const accessor = `${key}.${index}.columns`
      output.push(
        <PDFNode
          title={'\u00a0'.repeat(level * 4) + `${accessor} ` + Object.keys(node).join(", ")}
          handleClick={handleClick}
          accessor={accessor}
          selected={selected}
          setSelected={setSelected}
        />
      )
      output = toRepresentation(
        node.columns, 
        output, 
        accessor, 
        level + 1,
        display,
        handleClick,
        selected,
        setSelected
      )
    } else if (Object.keys(node).includes("table")) {
      const accessor = `${key}.${index}.table`
      output.push(
        <PDFNode
          title={'\u00a0'.repeat(level * 4) + accessor}
          handleClick={handleClick}
          accessor={accessor}
          selected={selected}
          setSelected={setSelected}
        />)
      for (const row in node.table.body) {
        output.push(
          <PDFNode
            title={'\u00a0'.repeat((level + 1) * 4) + `row ${row}`}
            handleClick={handleClick}
            accessor={accessor}
            selected={selected}
            setSelected={setSelected}
          />)
        for (const col in node.table.body[row]) {
          output.push('\u00a0'.repeat((level + 2) * 4) + `col ${col}`)
          output = toRepresentation(
            [node.table.body[row][col]], 
            output, 
            `${accessor}.body.${row}.${col}`,
            level + 3,
            display,
            handleClick,
            selected,
            setSelected
          )
        }
      }
    } else if (nodeList.length > 1) {
      const accessor = `${key}.${index}`
      output.push(
        <PDFNode
          title={'\u00a0'.repeat(level * 4) + `${accessor}: ` + Object.keys(node).join(", ")}
          handleClick={handleClick}
          accessor={accessor}
          selected={selected}
          setSelected={setSelected}
        />)
    } else {
      const accessor = `${key}`
      output.push(
        <PDFNode
          title={'\u00a0'.repeat(level * 4) + `${accessor}: ` + Object.keys(node).join(", ")}
          handleClick={handleClick}
          accessor={accessor}
          selected={selected}
          setSelected={setSelected}
        />)
    }
  }
  return output
}

const PDFNode = (props: any) => {
  return(
    <div
      style={{ 
        cursor: "pointer",
        backgroundColor: props.selected === props.accessor ? "#eeeeee" : "white" 
      }}
      onClick={(e) => { props.handleClick(props.accessor)}}>
      {props.title}
    </div>
  )
}

export default PDF
