import React, { useState, useEffect } from 'react'
import { Button, Input, Dropdown, Segment } from 'semantic-ui-react'
import toJsx from "./toJsx.js"

interface UiData {
    index: number | null,
    row: number | null,
    column: number | null,
    className: string,
    name: string,
    val: string,
    props: string,
    styles: string
}

export type CreateUIState = {
    fs?: any,
}

export const CreateUIStateInitial = {
    fs: {}
}

export type CreateUIEvent =
        { message: "CreateUILoad", params: { email: string } }
    |   { message: "CreateUISetData", params: 
            {   email: string, doc: {   data: string,
                                        height: number,
                                        width: number   }}}


const CreateUI = (props: any) => {

    const initialUiData: UiData = {
        index: null,
        row: null,
        column: null,
        className: '',
        name: '',
        val: '',
        props: '',
        styles: '',
    }

    const [firstLoad, setFirstLoad] = useState<boolean>(true)
    const [uidraft, setUidraft] = useState<string>("")
    const [uidef, setUidef] = useState<Array<UiData>>([])
    const [rows, setRows] = useState<string>("")
    const [columns, setColumns] = useState<string>("")
    const [height, setHeight] = useState<number>(100)
    const [width, setWidth] = useState<number>(100)
    const [trigger, setTrigger] = useState<boolean>(true)
    const [formMode, setFormMode] = useState<boolean>(true)
    const [formData, setFormData] = useState<UiData>(initialUiData)
    
    useEffect(() => {
        console.log(uidraft)
        if (props.user && props.db) {
            if (firstLoad || uidraft.length === 0) {
                props.onEvent({ message: "CreateUILoad", 
                                params: { email: props.user.email } })
            }
        }
    })

    useEffect(() => {
        if (props.fs) {
            if (props.fs.height) {
                setHeight(props.fs.height)
            }
            if (props.fs.width) {
                setWidth(props.fs.width)
            }
            if (props.fs.data) {
                setUidraft(props.fs.data)
                setFirstLoad(false)
            }
        }
    }, [props.fs])

    useEffect(() => {
        if (trigger && uidraft !== "") {
            setUi()
            setTrigger(false)
        }
    }, [uidraft])

    const setUi = async () => {
        setTemplate(uidraft)
        setUidef(mapUidef(uidraft))
        if (props.user && props.db) {
            props.onEvent({
                message: "CreateUISetData",
                params: {
                    email: props.user.email,
                    doc: {
                        data: uidraft,
                        height: height,
                        width: width,
                    }}
            })
        }
      }

    const setTemplate = (s: string) => {
        if (s != "") {
            const lines = s.split('\n')
            const r = lines.filter((line: string) => 
                            (line.includes('grid-template-rows'))
                        )[0].split("|")[4]
            const c = lines.filter((line: string) => 
                                (line.includes('grid-template-columns'))
                            )[0].split("|")[4]
            setRows(r)
            setColumns(c)
        }
    }

    const mapUidef = (s: string) => (
        s.split('\n').map((line: string, index: number) => (
            Object.assign({index: index}, ...line.split("|").map((param, index_p) => {
                return mapParam(param, index_p)
            }))
        ))
    )

    const mapParam = (param: string, index: number) => {
        switch (index) {
        case 0: 
            return { row: param }
        case 1:
            return { column: param }
        case 2:
            return { className: param }
        case 3:
            return { name: param }
        case 4:
            return { val: param }
        case 5:
             return { props: param.trim() }
        case 6:
            return { styles: param.trim() }
        default:
            return {}
        }
    }

    const adjustSize = ({axis, increase}: {axis: string, increase: boolean}) => {
        if (increase && axis === "height") {
            if (height <= 95) {
                setHeight(height + 5)
            }
        } else if (increase && axis === "width") {
            if (width <= 95) {
                setWidth(width + 5)
            }
        } else if (!increase && axis === "height") {
            if (height >= 5) {
                setHeight(height - 5)
            }
        } else if (!increase && axis === "width") {
            if (width >= 5) {
                setWidth(width - 5)
            }
        }
    }

    const getFormIndexChoices = (uidef: Array<UiData>) => {
        const choices = uidef
                .map((item, index) => (
                    {   key: index, 
                        value: index, 
                        text: `${item.name} (${item.val})`,
                        name: item.name
                    })
                )
                .filter((item) => 
                    item.name &&
                    item.name !== 'grid-template-rows' &&
                    item.name !== 'grid-template-columns')
        return choices
    }

    const setFormIndex = async (index: number) => {
        let data = mapUidef(uidraft)[index]
        // console.log(data)
        for (var key of ["row", "column", "className", "name", "val", "props", "styles"]) {
            if (!data[key]) data[key] = ""
        }
        setFormData(data)
    }

    const syncForm = (formData: UiData) => {
        let uiForm = uidraft.split("\n")
        const formStr = 
            `${formData.row || ''}|` +
            `${formData.column || ''}|` +
            `${formData.className || ''}|` +
            `${formData.name || ''}|` +
            `${formData.val || ''}|` +
            `${formData.props || ''}|` +
            `${formData.styles || ''}`
        if (formData.index) {
            uiForm[formData.index] = formStr
        }
        setUidraft(uiForm.join("\n"))
        setFormData(formData)
    }

    const removeForm = (formData: UiData) => {
        if (formData.index) {
            setFormData(initialUiData)
            let uiForm = uidraft.split("\n")
            uiForm[formData.index] = ""
            setTrigger(true)
            setUidraft(uiForm.join("\n"))
        }
    }

    return (
        <div 
            style={{ 
                display: "grid", 
                gridTemplateRows: "100%", 
                gridTemplateColumns: "75% 25%",
                width: "100%",
                height: "100%"
            }}
        >
            <div 
                style={{ 
                    gridColumn: 1,
                    display: "grid", 
                    gridTemplateRows: rows, 
                    gridTemplateColumns: columns,
                    width: `${width}%`,
                    height: `${height}%`,
                    border: "solid blue 0.5px"
                }}
            >
                { uidef.map(toJsx(setFormIndex)) }
            </div>
            <div style={{ gridColumn: 2, width: "100%", height: "100%"}}>
                <div style={{display: "flex"}}>
                    
                    <Button 
                        fluid color="yellow"
                        onClick = {(e) => {
                            setUi()
                        }}
                    >
                        Re
                    </Button>

                    <Button 
                        fluid color="green"
                        onClick = {(e) => {
                            setUi()
                            setFormMode(!formMode)
                        }}
                    >
                        Switch
                    </Button>
                    
                    <div style={{flex: 1}}>
                        <div>Height:{`${height}%`}</div>
                        <div style={{display: "flex"}}>
                            <Button 
                                onClick={() => { adjustSize({axis: "height", increase: false})}}
                                color="red" size="mini"
                            >-</Button>
                            <Button 
                                onClick={() => { adjustSize({axis: "height", increase: true})}}
                                color="blue" size="mini"
                            >+</Button>
                        </div>
                    </div>
                    
                    <div style={{ flex: 1}}>
                        <div>Width:{`${width}%`}</div>
                        <div style={{display: "flex"}}>
                            <Button 
                                onClick={() => { adjustSize({axis: "width", increase: false})}}
                                color="red" size="mini"
                            >-</Button>
                            
                            <Button 
                                onClick={() => { adjustSize({axis: "width", increase: true})}}
                                color="blue" size="mini"
                            >+</Button>
                        </div>
                    </div>

                </div>
                {   formMode ? 
                    
                    <UiForm 
                        choices={getFormIndexChoices(uidef)} 
                        setFormIndex={setFormIndex}
                        formData={formData}
                        syncForm={syncForm}
                        removeForm={removeForm}
                    /> : 
                    
                    <textarea 
                        style={{height: "100%", width: "100%", 
                                backgroundColor: "aliceblue"}} 
                        value={uidraft}
                        onChange={(e) => {
                            setUidraft(e.target.value)
                        }}
                    />
                }
                
            </div>
        </div>
    );
}

export default CreateUI

const UiForm = (props: any) => {
    const changeData = ({dataname, value}: {dataname: string, value: string}) => {
        let newData = props.formData
        newData[dataname] = value
        props.syncForm(newData)
    }

    return (
        <div>
            <Dropdown 
                selection fluid 
                options={props.choices}
                value={props.formData.index}
                onChange={(e, {value}) => {
                    props.setFormIndex(value)
                }} 
            />
            <Segment>
                <Button fluid
                    onClick = {(e) => {
                        props.removeForm(props.formData)
                    }}
                >
                    Delete
                </Button>
                <div><h4>Rows</h4></div>
                <Input fluid value={props.formData.row || ""}
                    onChange={(e) => changeData({
                        dataname: "row", 
                        value: e.target.value
                    })} />

                <div><h4>Cols</h4></div>
                <Input fluid value={props.formData.column || ""} 
                    onChange={(e) => changeData({
                        dataname: "column", 
                        value: e.target.value
                    })}/>

                <div><h4>Class</h4></div>
                <Input fluid value={props.formData.className || ""} 
                    onChange={(e) => changeData({
                        dataname: "className", 
                        value: e.target.value
                    })}/>

                <div><h4>Name</h4></div>
                <Input fluid value={props.formData.name} 
                    onChange={(e) => changeData({
                        dataname: "name", 
                        value: e.target.value
                    })}/>

                <div><h4>Text</h4></div>
                <Input fluid value={props.formData.val} 
                    onChange={(e) => changeData({
                        dataname: "val", 
                        value: e.target.value
                    })}/>

                <div><h4>Props</h4></div>
                <textarea rows={10} style={{width: '100%'}} 
                    value={props.formData.props} 
                    onChange={(e) => changeData({
                        dataname: "props",
                        value: e.target.value
                    })}/>

                <div><h4>Styles</h4></div>
                <textarea rows={10} style={{width: '100%'}} 
                    value={props.formData.styles} 
                    onChange={(e) => changeData({
                        dataname: "styles", 
                        value: e.target.value
                    })}/>
            </Segment>
        </div>
    )
}

