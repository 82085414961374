import React, { useState } from "react";
import ReactTable from "react-table-6";

const NodeStat = (props: any) => {

  let nodeInfo = Object.values(props.xddata.steps)
    .flatMap((step: any) => (
      Object.entries(step.nodeDetail)
        .map((entry: any) => [entry[0], entry[1].type, step.id])
    ))
    .reduce((acc: any, item: any) => {
      if (!(item[0] in acc))
        acc[item[0]] = { editor: 0, reviewer: 0 };
      acc[item[0]][item[1]] += 1;
      return acc;
    }, {});

  for (const nodeId in nodeInfo) {
    nodeInfo[nodeId] = {
      ...nodeInfo[nodeId],
      ...props.xddata.nodeMaster[nodeId]
    } 
  }

  nodeInfo = Object.entries(nodeInfo)
    .map((entry: [string, any]) => ({
      id: entry[0],
      ...entry[1]
    }))
    .sort((a: any, b: any) => 
      a.editor > b.editor ? -1 : 1);
  
  return (
    <div>
      <div>{props.xddata.name}</div>
      <ReactTable
        columns={[
          {Header: "id", accessor: "id"},
          {Header: "name", accessor: "name"},
          {Header: "system", accessor: "system"},
          {Header: "editor", accessor: "editor"},
          {Header: "reviewer", accessor: "reviewer"},
        ]}
        data={nodeInfo}
        getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
          return {
            style: { textAlign: "center" }
          }
        }}
      />
    </div>
  )

}

export default NodeStat;