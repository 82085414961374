import React, { createRef } from 'react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { processImageGcs, processImage, processDentalChart } from './processImage'

const cv = (window as any).cv

export type Pic2UIState = {
    uiPic?: any,
}

export const Pic2UIStateInitial = {
    uiPic: null,
}

export type Pic2UIEvent =
        { message: "LoadPic2UI", params: { ref: React.RefObject<HTMLImageElement> } }
    |   { message: "ProcessPic2UI", params: { ref: React.RefObject<HTMLImageElement>, 
                                              file: File } }

type Point = { x: number, y: number }

export default class Pic extends React.Component<
    {
        onEvent: (e: Pic2UIEvent) => any
    },
    {
        picUrl: string,
        sub: string,
        edges: { x1: number, y1: number, x2: number, y2: number }[]
    }>
{

    myRef = createRef<HTMLImageElement>()
    myRef1 = createRef<HTMLImageElement>()
    myCanvas = createRef<HTMLCanvasElement>()
    dentalChartImg = createRef<HTMLImageElement>()
    dentalChartCanvas = createRef<HTMLCanvasElement>()
    dentalChartMat: any | null
    point1: Point | null = null
    point2: Point | null = null
    data: Point[] = []
    
    constructor(props: any) {
        super(props)
        this.state = {
            picUrl: "",
            sub: "DentalChart",
            edges: []
        }
    }

    // Hook up call back
    componentDidMount = () => {
        if (this.myRef?.current)
            this.myRef.current.onload = () => {
                processImage(this.myRef.current!, this.myCanvas.current!)
            }

        if (this.myRef1?.current)
            this.myRef1.current.onload = () => {
                processImageGcs(this.myRef1.current!, this.myCanvas.current!)
            }

        if (this.dentalChartImg?.current) {
            this.dentalChartImg.current.onload = () => {
                this.dentalChartMat = processDentalChart(this.dentalChartImg.current!, this.dentalChartCanvas.current!)
            }
        }

        // Kick-off gcs flow
        this.props.onEvent({ message: "LoadPic2UI", params: { ref: this.myRef1 } })
    }    

    render() {
        console.log(this.state.edges.length)
        return(
        <div style={{height: '100%', width: '100%', margin: '15px', 
                     display: "grid", gridTemplateColumns: "100px 1fr"}}>
            <div style={{gridColumn: 1, display: "flex", flexDirection: "column", 
                         borderRight: "solid #aaaaaa 1px", marginRight: "5px"}}>
                <TabItem name="Upload" sub={this.state.sub} setSub={(sub: string) => { this.setState({ sub: sub })}}/>
                <TabItem name="Result" sub={this.state.sub} setSub={(sub: string) => { this.setState({ sub: sub })}}/>
                <TabItem name="DentalChart" sub={this.state.sub} setSub={(sub: string) => { this.setState({ sub: sub })}}/>
            </div>
            <div style={{gridColumn: 2}}>
                <TabContent name="Upload" sub={this.state.sub}>
                    <div>
                        <input type="file" onChange={(event) => {
                            this.props.onEvent({ 
                                message: "ProcessPic2UI", 
                                params: { 
                                    ref: this.myRef, 
                                    file: event?.target?.files?.[0]!
                                }})
                        }}/>
                    </div>
                    <img ref={this.myRef1}/>
                    <img ref={this.myRef}/>
                </TabContent>
                <TabContent name="Result" sub={this.state.sub}>
                    <canvas width="100" height="100" ref={this.myCanvas} />
                </TabContent>
                <TabContent name="DentalChart" sub={this.state.sub}>
                    <div>
                        <img 
                            ref={this.dentalChartImg} src="/data/dentalchart3.jpg"
                        />
                        <canvas width="100" height="100" ref={this.dentalChartCanvas} 
                            onClick={(e) => { 
                                e.persist()
                                const point = getXY(this.dentalChartCanvas!.current!, e)
                                // drawLine(this.dentalChartCanvas!.current!, this.dentalChartMat, point.x, point.y)
                                if (!this.point1) {
                                    console.log("point1", point)
                                    this.point1 = point
                                } else if (!this.point2) {
                                    let data = []
                                    console.log("point2", point)
                                    this.point2 = point
                                    // cv.rectangle(this.dentalChartMat, 
                                    //     new cv.Point(this.point1.x, this.point1.y),
                                    //     new cv.Point(this.point2.x, this.point2.y),
                                    //     new cv.Scalar(255, 0, 0, 255), 1)
                                    let src = this.dentalChartMat
                                    for (let x = Math.min(this.point1.x, this.point2.x); 
                                        x <= Math.max(this.point1.x, this.point2.x); x++) {
                                        for (let y = Math.min(this.point1.y, this.point2.y); 
                                            y <= Math.max(this.point1.y, this.point2.y); y++) {
                                            const index = y * src.cols * src.channels() + x * src.channels()
                                            if (src.data[index] === 0 &&
                                                src.data[index + 1] === 0 &&
                                                src.data[index + 2] === 0) {}
                                            else {
                                                src.data[index] = 255
                                                src.data[index + 1] = 0
                                                src.data[index + 2] = 0
                                                data.push({ x: x, y: y })
                                            }   
                                        }
                                    }
                                    const edges = processData(data)
                                    this.setState({ edges: edges })
                                    cv.imshow(this.dentalChartCanvas!.current!, this.dentalChartMat)
                                } else {
                                    this.point1 = point
                                    this.point2 = null
                                }
                            }}
                        />
                    </div>
                </TabContent>
                <svg width={1000} height={1000}>
                    <path 
                        d={this.state.edges.map((edge: any, edge_index: number) => (
                            `M${edge.x1},${edge.y1} L${edge.x2},${edge.y2}`
                        )).join("")}
                        stroke="black"
                        fill="white"
                    />
                </svg>
            </div>
        </div>)
    }
}

const processData = (data: Point[]) => {
    let edges: { x1: number, y1: number, x2: number, y2: number }[] = []
    for (const point1 of data) {
        const candidates = data.map((point2) => (
            {
                point: point2,
                sq: point2.x === point1.x && point2.y === point1.y ?
                    10 : ((point1.x - point2.x)**2 + (point1.y - point2.y)**2)
            }
            
        ))
        .filter((item: any) => item.sq <= 8)
        .map(item => item.point)
        for (const point2 of candidates) {
            if (!edges.includes({ x1: point1.x, y1: point1.y, x2: point2.x, y2: point2.y }) &&
                !edges.includes({ x1: point2.x, y1: point2.y, x2: point1.x, y2: point1.y })) {
                edges.push({ x1: point1.x, y1: point1.y, x2: point2.x, y2: point2.y })
            }
        }
    }
    return edges
}

const getXY = (canvas: HTMLCanvasElement, e: { clientX: number, clientY: number }) => {
    const rect = canvas.getBoundingClientRect()
    let x = e.clientX - (rect?.left || 0)
    let y = e.clientY - (rect?.top || 0)
    return { x: x, y: y }
}

const drawLine = (canvas: HTMLCanvasElement, src: any, x: number, y: number) => {
    if (src.isContinuous()) {   
        let data = src.data
        let hit = false
        let x1 = x
        while (!hit) {
            data[y * src.cols * src.channels() + x1 * src.channels()] = 255
            x1 += 1
            const pixel = src.ucharPtr(y, x1)
            if (pixel[0] === 255 || x1 >= src.cols) {
                hit = true
            }
        }
        hit = false
        x1 = x
        while (!hit) {
            data[y * src.cols * src.channels() + x1 * src.channels()] = 255
            x1 -= 1
            const pixel = src.ucharPtr(y, x1)
            if (pixel[0] === 255 || x1 <= 0) {
                hit = true
            }
        }
        cv.imshow(canvas, src)
    }
}

const TabItem = (props: any) => (
    <div 
        style={{ backgroundColor: props.sub === props.name ? "#eeeeee" : "white", cursor: "pointer" }}
        onClick={(e) => { props.setSub(props.name) }}
    >{props.name}</div>
)

const TabContent = (props: any) => (
    <div style={{ display: props.sub === props.name ? "block" : "none"}}>
        {props.children}
    </div>
)