const FormDef = {
  text: {
    __meta: { type: "TEXT", props: [] as any[], hide: true },
    text: "",
    decoration: "",
    decorationStyle: "",
    decorationColor: "",
    width: "auto",
    color: "",
    bold: false,
    fontSize: 15,
    pageBreak: "",
    alignment: "left",
    preserveLeadingSpaces: true,
    margin: {
      __meta: { type: "MARGIN", props: [] as any[], hide: true },
      __items: [0, 0, 0, 0]
    }
  },
  columns: {
    __meta: { type: "COLUMNS", props: [] as any[], hide: true },
    columns: {
      __meta: { type: "COLUMN_ITEMS", props: [], hide: true },
      __items: [] as any[]
    },
    // margin: {
    //   __meta: { type: "MARGIN", props: [] as any[], hide: true },
    //   __items: [0, 0, 0, 0]
    // }
  },
  stack: {
    __meta: { type: "STACK", props: [] as any[], hide: true },
    stack: {
      __meta: { type: "STACK_ITEMS", props: [], hide: true },
      __items: [] as any[],
      fontSize: 15,
    },
    // margin: {
    //   __meta: { type: "MARGIN", props: [] as any[], hide: true },
    //   __items: [0, 0, 0, 0]
    // }
  },
  image: {
    __meta: { type: "IMAGE", props: [] as any[], hide: true },
    image: "",
    // margin: {
    //   __meta: { type: "MARGIN", props: [] as any[], hide: true },
    //   __items: [0, 0, 0, 0]
    // }
  },
  // table: {
  //   __meta: { type: "TABLE", props: [] },
  //   table: {
  //     __meta: { type: "TABLE_BODY", props: [] },
  //     dontBreakRows: true,
  //     headerRows: 0,
  //     body: {
  //       __meta: { type: "TABLE_ROWS", props: [] },
  //       __items: [
  //         {
  //           __meta: { type: "TABLE_COLUMNS", props: [] },
  //           __items: [
  //             {
  //               __meta: { type: "TEXT", props: [] },
  //               text: "item1",
  //               color: "",
  //               bold: false,
  //               fontSize: 15,
  //               pageBreak: "",
  //               alignment: "left",
  //             },
  //             {
  //               __meta: { type: "TEXT", props: [] },
  //               text: "item2",
  //               color: "",
  //               bold: false,
  //               fontSize: 15,
  //               pageBreak: "",
  //               alignment: "left",
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   }
  // },
}

export const NewPDFDefTemplate = {
  __meta: { type: "ROOT", props: [], },
  content: {
    __meta: { type: "ROOT_CONTENT", props: [] },
    __items: []
  },
  defaultStyle: { 
    __meta: { type: "STYLE", props: [] },
    font: "THSarabunNew",
  },
  pageOrientation: "portrait",
  pageSize: "A4",
}

export default FormDef

export const type = (obj: any) => {
  const t = typeof obj
  if (t !== "object") {
    return t
  } else if (obj === null) {
    return "null"
  } else {
    if (typeof obj.length !== "undefined") {
      return "array"
    } else {
      return t
    }
  }
}