import { AsyncDuckDBConnection } from "@duckdb/duckdb-wasm";
import { query } from "ismor-lib/utils/DuckLib";

export const generateChart = async (
  con: AsyncDuckDBConnection | null, 
  selectedData: any,
  setChartData: (data: any) => void
) => {
  if (!selectedData) return;
  if (!con) return;

  if (["IH data structure", "CNMI data structure"].includes(selectedData.name)) {
    let res = await query(`from fk_relation;`, con);
    setChartData(res);
  } else if (selectedData.name === "Table by rank") {
    // tables col_fk_name src_table src_column
    let result = await query(`
    from fk_relation 
    select 
      rank, 
      cast(count(*) as integer) table_count
    group by all
    ;`, con);
    
    setChartData({
      title: "Table by rank",
      data: {values: result},
      width: 900,
      height: 600,
      mark: "bar",
      encoding: {
        y: { field: "rank", type: "ordinal" },
        x: { field: "table_count", type: "quantitative"}
      }
    });
  } else if (selectedData.name === "Table by module") {
    // tables col_fk_name src_table src_column
    let result = await query(`
    with table_module as (
      from fk_relation 
      select distinct name, string_split(name, '_')[1] module
      order by name
    )
    from table_module
    select module, cast(count(name) as integer) table_count
    group by all
    order by table_count ${selectedData.name === "Table by module" ? "asc" : "desc"}
    ;`, con);

    // Do plotting
    let Bokeh = (window as any).Bokeh;

    let bar_data = [["module", "table_count"]]
    .concat(result.map((row: any) => Object.values(row)))
    const plt = Bokeh.Plotting;
    const pie_data = {
      labels: ['Work', 'Eat', 'Commute', 'Sport', 'Watch TV', 'Sleep'],
      values: [8, 2, 2, 4, 0, 8],
    };

    // Do plotting
    const p1 = Bokeh.Charts.bar(bar_data, {
    orientation: "horizontal", 
    palette: selectedData.name === "Table by module" ? ["orange"] : ["green"]
    });

    const doc = new Bokeh.Document();
    doc.add_root(plt.gridplot(
                  [[p1]],
                  {width: 900, height: 550}));
    setChartData(doc);
  }
}
