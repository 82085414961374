import { useEffect, useRef, useState } from "react";

export default function Analysis(props: any) {
  const [staffList, setStaffList] = useState<any[]>([]);

  useEffect(() => {
    if (props.pythonLoaded && props.staffProfile) {  
      staffSummary(props.staffProfile);
    }
  }, [props.pythonLoaded, props.staffProfile])

  // Functions ---------------------------------------------------------------------------------
  const staffSummary = async (staffProfile: any) => {
    const result = await props.python.current.run("staffSummary", { 
    staffData: JSON.stringify(Object.values(staffProfile))
    }, `
    import pandas as pd
    import json


    from js import pyParams
    staffData = pyParams.staffData
    staffData = json.loads(staffData)
    
    df = pd.DataFrame(staffData)
    
    json.dumps(df.email.to_list())
    `
    ).catch((err: any) => console.log(err));
    // console.log(result);
    setStaffList(result);
  }
 
  return(
    <div>
      <div>Analysis</div>
      {staffList?.map((id: string, index: number) => (
        <div key={index}>{id}</div>
      ))}
    </div>
  )
}

