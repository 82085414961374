import React, { useState } from "react";
import ReactTable from "react-table-6";

const display = (node: any) => {
  return `${node.name} (${node.system})`;
}

const stepDisplay = (step: any, from: string, to: string, xddata: any) => {
  return `${display(xddata.nodeMaster[from])} -> ${display(xddata.nodeMaster[to])}` + 
        `: ${step.description}`
}

export const getNodeSummary: 
  (xddata: any, xdmaster: any, steps: any[]) 
    => [{[id:string]: any}, string[]] 
= (xddata: any, xdmaster: any, steps: any[]) => {
  // console.log(xddata);
  // console.log(xdmaster);

  let matrix: {[id: string]: {[id1:string]: {to: any[], from: any[]}}} = {};
  let nodeList: string[] = [];

  for (const nodeId of xddata.nodes) {
    if (!(nodeList.includes(xddata.nodeMaster[nodeId].name))) {
      nodeList.push(xddata.nodeMaster[nodeId].name)
    }
  }
  // console.log(nodeList)

  for (const step of steps) {
    // console.log(step);
    let fromName: string | null = null;
    let fromId: string | null = null;
    for (const [nodeId, detail] of Object.entries((step as any)?.nodeDetail || {})) {
      if ((detail as any)?.type === "editor") {
        fromName = xddata.nodeMaster[nodeId].name;
        fromId = nodeId;
        // if (fromName && !nodeList.includes(fromName))
        //   nodeList.push(fromName);
        break;
      }
    }
    if (fromId && fromName) {
      for (const [toId, detail] of Object.entries((step as any)?.nodeDetail || {})) {
        const toName = xddata.nodeMaster[toId].name;
        if (!(fromName in matrix))
          matrix[fromName] = {};
        if (!(toName in matrix[fromName]))
          matrix[fromName][toName] = {to: [], from: []}
        matrix[fromName][toName].from.push(
            stepDisplay(step, fromId, toId, xddata)
        );
        if (!(toName in matrix))
          matrix[toName] = {};
        if (!(fromName in matrix[toName]))
          matrix[toName][fromName] = {to: [], from: []}
        
        matrix[toName][fromName].to.push(
          stepDisplay(step, fromId, toId, xddata)
        );
      }
    }
  }
  // console.log(matrix);
  return [matrix, nodeList];
}

const NodeMatrix = (props: any) => {
  const steps = props.view.stepViews.map((item: any) => item.step);
  const [matrix, nodeList] = getNodeSummary(props.xddata, props.xdmaster, steps);
  
  return (
    <div 
      style={{ 
        width: "100%", height: "100%", display: "grid", 
        gridTemplateColumns: `repeat(${nodeList.length + 1}, minmax(0, 1fr))`,
        gridTemplateRows: `repeat(${nodeList.length + 1}, minmax(0, 1fr))`,
      }}
      children={
        [<div key={`0_0`} style={{border: "solid black 1px"}}></div>]
        .concat(
          nodeList.map((nodeName: string, index: number) => 
            <div 
              key={`0_${index+1}`}
              style={{
                border: "solid black 1px",
                display: "flex", justifyContent: "center", alignItems: "flex-start",
              }}>{nodeName}</div>)
        )
        .concat(
          nodeList.flatMap((fromName: string, index: number) => 
            Object.keys(matrix).includes(fromName) ? 
            [<div 
              key={`${index+1}_${0}`}
              style={{
                border: "solid black 1px", 
                display: "flex", 
                justifyContent: "flex-start", 
                alignItems: "center"
              }}>{fromName}</div>]
            .concat(
              nodeList.map((toName: string, toIndex: number) => 
                (Object.keys(matrix[fromName]).includes(toName) 
                && matrix[fromName][toName].from.length > 0 
                  ? <div 
                      key={`${index+1}_${toIndex+1}`}
                      style={{
                        border: "solid black 1px", cursor: "pointer",
                        display: "flex", justifyContent: "center", alignItems: "center",
                        backgroundColor: fromName === toName ? "#cccccc" : "white"
                      }}>
                      {matrix[fromName][toName].from.length}
                    </div> 
                  : <div 
                      key={`${index+1}_${toIndex+1}`}
                      style={{
                        border: "solid black 1px",
                        backgroundColor: fromName === toName ? "#cccccc" : "white"
                      }}></div>)
              )
            )

            : 
            
            [<div 
              key={`${index+1}_${0}`} 
              style={{border: "solid black 1px"}}>{fromName}</div>]
            .concat(
              nodeList.map((toName: string, toIndex: number) => 
                <div 
                  key={`${index+1}_${toIndex+1}`}
                  style={{
                    border: "solid black 1px", 
                    backgroundColor: fromName === toName ? "#cccccc" : "white"
                  }}></div>)
            )
          )
        )
      }
    />
  )

}

export default NodeMatrix;