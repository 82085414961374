import { useEffect, useState } from "react";
import { Button, Checkbox, Icon } from "semantic-ui-react";
import ClientSelector from "./ClientSelector";

export default function DealList(props: any) {
  const [mode, setMode] = useState("List");
  const [dealList, setDealList] = useState<any[]>([]);
  const [showFinished, setShowFinished] = useState(false);
  const [clientId, setClientId] = useState<string | null>(null);
  const [newDeal, setNewDeal] = useState<any>({});

  useEffect(() => {
    let dealList_ = Object.values(props.clientDeal)
      .map((item: any) => {
        let remainingValue = (Object.values(item?.deliverables || {}) || [])
          .filter((d: any) => d.status !== "FINISHED")
          .reduce((acc: number, cur: any) => {
            return acc + (Number(cur.value) || 0)
          }, 0);
        return {...item, remainingValue};
      })
      .sort((a: any, b: any) => 
        a.status < b.status ? -1 
        : a.status > b.status ? 1
        : a.status === b.status && a.status === "ONGOING" ?
        (a.remainingValue > b.remainingValue ? -1 : 1)
        : a.status === b.status && a.status === "SALES" ?
        (a.value > b.value ? -1 : 1)
        : 0);
    setDealList(dealList_);
  }, [props.clientDeal]);

  return(
    <div>
      {mode === "List"?
      <>
        <div
          style={{cursor: "pointer", display: "flex", alignItems: "center"}}
          >
          <div 
            onClick={(e: any) => {
              setMode("Add");
            }}>
            <Icon name="add" size="small" />
            <span>Add New Deal</span>
          </div>
          <Checkbox 
            style={{marginLeft: "15px"}} 
            label="Show Finished" 
            checked={showFinished}
            onClick={() => setShowFinished(!showFinished)}
          />
        </div>
        {dealList
        .filter((deal: any) => showFinished ? true : deal.status !== "FINISHED")
        .map((deal: any, dealIndex: number) => (
          <div 
            key={dealIndex} 
            style={{
              display: "flex", 
              color: deal.status === "FINISHED" ? "#aaaaaa" : "black",
              borderBottom: "solid #cccccc 1px", 
              cursor: "pointer"}}
            onClick={(e: any) => {
              props.setSelectedDeal(deal);
            }}>
            <div style={{flex: 1}}>{deal.clientName}</div>
            <div style={{flex: 1}}>{deal.dealName}</div>
            <div style={{flex: 1}}>{deal.status}</div>
            <div style={{flex: 0.5, textAlign: "right", paddingRight: "10px"}}>
              {deal.remainingValue?.toLocaleString('en-US', {maximumFractionDigits: 0})}
            </div>
            <div style={{flex: 0.5, textAlign: "right", paddingRight: "10px"}}>
              {deal.value?.toLocaleString('en-US', {maximumFractionDigits: 0})}
            </div>
            <div style={{flex: 1}}>{`${(Object.values(deal?.deliverables || {})|| []).length} deliverables`}</div>
          </div>
        ))}
      </>
      : mode === "Add" ?
      <div>
        <div 
          style={{cursor: "pointer"}}
          onClick={(e: any) => setMode("List")}>
          Back to List
        </div>
        <ClientSelector
          clientDict={props.clientDict}
          clientId={clientId}
          setClientId={setClientId}
        />
        <div>
          <input 
            placeholder="ชื่อ Deal (เช่น HIS)" 
            style={{minWidth: "200px"}}
            value={newDeal?.dealName || ""}
            onChange={(e: any) => setNewDeal({dealName: e.target.value})}
          />
        </div>
        <Button 
          size="mini" color="green"
          disabled={clientId === null}
          onClick={(e: any) => {
            if (!clientId) return;
            props.onEvent({
              message: "CreateClientDeal",
              params: {
                clientId: clientId,
                clientName: props.clientDict[clientId].name,
                dealName: newDeal.dealName,
                status: "SALES",
              }
            });
            setMode("List");
          }}>
          Add
        </Button>
        <Button 
          size="mini" color="red"
          onClick={(e: any) => {
            setNewDeal({});
            setClientId(null);
            setMode("List");
          }}>
          Cancel
        </Button>
      </div>
      :
      <></>
      }
    </div>
  )
}
