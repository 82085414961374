import React, { useState, useEffect } from "react";
import { Input, Button } from "semantic-ui-react";

const Score = (props: any) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(false);
    }, [props.scoring])

    return(
    <div style={{ display: "flex", width: "100%", marginTop: "10px" }}>
        <div style={{ flex: 1 }}></div>
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex" }}>
                <Input
                    placeholder="กรอก HN"
                    style={{ flex: 1 }}
                    value={props.hn}
                    onChange={(e:any, v: any) => {
                        props.setProp("hn", e.target.value);
                    }}
                />
                <Button 
                    color={loading ? "red": "green"}
                    onClick={(e: any) => {
                        setLoading(true);
                        props.onEvent({ message: "GetScoring", params: {}});
                    }}>
                    {loading ? "Loading...": "Sync"}
                </Button>
            </div>
            <div>{props.scoring.note}</div>
            
            {props.scoring?.count > 0 &&
            <div>{`Scoring: ${props.scoring.count}`}</div>
            }
            
            <div>
            {props.scoring.tables.map((table: string, index: number) => (
                <div key={index}>{table}</div>
            ))}
            </div>
        </div>
        <div style={{ flex: 1 }}></div>
    </div>
    )
}

export default Score