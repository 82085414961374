import { useEffect, useState } from 'react';
import GameEditor from './GameEditor';
import GamePlayer2 from './GamePlayer2';
import { Button, Icon, Input, Modal } from 'semantic-ui-react';
import GameSummary from './GameSummary';
import GameStat from './GameStat';

export default function IHGame(props: any) {
  const [selectedGameId, setSelectedGameId] = useState<string|null>(null);
  const [mode, setMode] = useState("Edit");
  const [showAddModal, setShowAddModal] = useState(false);
  const [newGameName, setNewGameName] = useState("");
  const [xdId, setXdId] = useState<string|null>(null);
  const [gamePlayStatus, setGamePlayStatus] = useState<any>(null);
  const [startLevel, setStartLevel] = useState<number|null>(null);
  const [selectedGame, setSelectedGame] = useState<any>(null);
  const [edited, setEdited] = useState(false);
  
  useEffect(() => {
    return () => {
      props.setProp("ihGameList", []);
    }
  }, [])

  useEffect(() => {
    if (props.xdId) {
      if (props.xdId !== xdId) {
        setSelectedGameId(null);
      }
      props.onEvent({message: "GetIHGameList", params: {}});  
      setXdId(props.xdId);
    }
  }, [props.xdId]);

  useEffect(() => {
    if (!selectedGameId) return;
    props.onEvent({message: "SelectIHGame", params: {id: selectedGameId}});
  }, [selectedGameId]);

  useEffect(() => {
    setSelectedGame(JSON.parse(JSON.stringify(props.selectedIHGame)));
    setEdited(false);
  }, [props.selectedIHGame]);

  useEffect(() => {
    if (mode === "Play") {
      props.onEvent({message: "IHGameTestRun", params: {setGamePlayStatus}});
    }
  }, [mode]);

  return (
    <div style={{width: "100%", height: "88vh"}}>
      <div 
        style={{
          // height: "3vh", 
          display: "flex", alignItems: "center",
          justifyContent: "flex-start", borderBottom: "solid #cccccc 1px"
        }}>
        <select
          value={props.selectedIHGame?.id}
          onChange={(e: any) => {
            if (e.target.value !== "NOTSELECTED") {
              setSelectedGameId(e.target.value);
            }
          }}>
          {[
            { id: "NOTSELECTED", name: "Please select game"}
          ]
          .concat(props.ihGameList.sort((a: any, b: any) => a?.name < b?.name ? -1 :1))
          .map((game: any, gameIndex: number) => 
          <option value={game.id} key={gameIndex}>
            {game.name}
          </option>)}
        </select>

        <Icon 
          name="add"
          // disabled
          onClick={() => { setShowAddModal(true) }}
        />
        
        {["Edit", "AddScreen"].includes(mode) && props.selectedIHGame ?
        <div style={{marginLeft: "10px", display: "flex"}}>
          <div 
            style={{
              backgroundColor: "lightgreen", cursor: "pointer", 
              padding: "0 5px 0 5px", borderRadius: "3px"
            }}
            onClick={(e: any) => {
              props.onEvent({message: "PublishIHGame", params: {setGamePlayStatus}});
            }}>
            {`Publish v.${props.selectedIHGame?.nextVersion}`}
          </div>
          <div 
            style={{marginLeft: "10px", cursor: "pointer"}}
            onClick={() => {
              if (mode !== "AddScreen")
                setMode("AddScreen");
              else
                setMode("Edit")

            }}>
            {`${mode === "AddScreen" ? "<< Back to edit" : "+ Add Screen"}`}
          </div>
        </div>
        :
        <>
        </>}
        
        <div style={{flex: 1}} />
        
        {selectedGameId &&
        <>
        <Icon
          name="play" color="green"
          onClick={() => setMode("Play")}
        />
        <MenuItem name="Edit" mode={mode} setMode={setMode} />
        <MenuItem name="Grid" mode={mode} setMode={setMode} />
        <MenuItem name="Stat" mode={mode} setMode={setMode} />
        <MenuItem name="General" mode={mode} setMode={setMode} />
        <MenuItem name="All Games" mode={mode} setMode={setMode} />
        </>}
      </div>

      {selectedGameId === null || mode === "All Games" ?
      <GameSummary
        ihGameList={props.ihGameList}
        setSelectedGameId={setSelectedGameId}
        setMode={setMode}
      />
      
      : ["Edit", "Grid", "AddScreen"].includes(mode) ?
      <div
        style={{ 
          height: "100%"
        }}>
        <GameEditor
          onEvent={props.onEvent}
          setProp={props.setProp}
          xdId={props.xdId}
          xddata={props.xddata}
          mode={mode}
          setMode={setMode}
          docInfo={props.docInfo}
          setStartLevel={setStartLevel}
          
          ihGameList={props.ihGameList}
          selectedGameId={selectedGameId}
          selectedIHGame={props.selectedIHGame}
          screenGroupInfo={props.screenGroupInfo}
          ihGameMessage={props.ihGameMessage}

          toDownloadUrl={props.toDownloadUrl}
          webviewReady={props.webviewReady}
          videoServerReady={props.videoServerReady}
          videoUrlPrefix={props.videoUrlPrefix}
          videoPathInfo={props.videoPathInfo}
        />
      </div>
    
      : mode === "Play" ?
      <GamePlayer2 
        onEvent={props.onEvent}
        forTest={true}
        selectedIHGame={props.publishedIHGame}
        ihGamePlaySession={props.ihGamePlaySession}
        startLevel={startLevel}
        setStartLevel={setStartLevel}
      />

      : mode === "Stat" ?
      <GameStat 
        onEvent={props.onEvent}
        xdId={props.xdId}
        gameId={selectedGameId}
        ihGameStatList={props.ihGameStatList}
        toWebDownloadUrl={props.toWebDownloadUrl}
      />

      : mode === "General" ?
      <div>
        <Input 
          fluid
          label="Name" 
          value={selectedGame?.name || ""}  
          onChange={(e: any) => {
            setEdited(true);
            setSelectedGame({
              selectedGame,
              name: e.target.value
            })
          }}
        />
        <br />
        <Button
          size="mini" color="green"
          disabled={!edited}
          onClick={(e: any) => {
            if (selectedGame?.name) {
              props.onEvent({message: "EditIHGame", params: {
                gameId: selectedGameId, name: selectedGame?.name}});
            }
            setEdited(false);
          }}>
          Save
        </Button>
      </div>
      
      :
      <></>}

      <Modal
        size="tiny"
        closeIcon={true}
        open={showAddModal}
        style={{padding: "20px"}}
        onClose={() => {
          setNewGameName("");
          setShowAddModal(false);
        }}>
        <Input 
          fluid
          placeholder="ชื่อเกม"
          value={newGameName}
          onChange={(e: any) => setNewGameName(e.target.value)}
        />
        <div style={{display: "flex", justifyContent: "center", marginTop: "20px"}}>
          <Button
            color="red"
            onClick={() => {
              setNewGameName("");
              setShowAddModal(false);
            }}>
            Cancel
          </Button>
          <Button
            color="green"
            onClick={(() => {
              props.onEvent({message: "AddIHGame", params: {name: newGameName}});
              setNewGameName("");
              setShowAddModal(false);
            })}>
            Save
          </Button>
        </div>
      </Modal>

      <Modal
        size="small"
        open={gamePlayStatus !== null}>
        <div 
          style={{
            minHeight: "300px", overflowY: "auto", textAlign: "center", padding: "20px", 
            display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"
          }}>
          {(gamePlayStatus?.messages || []).map((message: string, index: number) => (
            <div key={index}>{message}</div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

function MenuItem(props: any) {
  return(
    <div
      style={{
        padding: "0 5px 0 5px", cursor: "pointer",
        backgroundColor: props.mode === props.name ? "#dddddd" : "white"
      }}
      onClick={(e: any) => {
        if (props.mode !== props.name) {
          props.setMode(props.name);
        }
      }}>
      {props.name}
    </div>

  )
}