import {
    Issue,
    Throughput,
    ReleaseDict,
    ProjectDict,
    NO_RELEASE,
    ByReleaseRow,
    initialByReleaseRow,
    ByDateRow,
    ByProjectRow,
    getProjectKeys,
    initialByProjectRow,
    countDisplay,
    ChartRow
} from './Redmine'

import { WasmHandler } from '../../../react-lib/frameworks/WasmController'

import axios from "axios";
import { tableFromJSON } from 'apache-arrow';

type Handler = WasmHandler<State, Event, Data>

// Spec ---------------------------------------------------------------------
export type State = {
    redmineSub?: string,
    redmineLoaded?: boolean,
    redmineUser?: string | null,
    chartData?: ChartRow[],
    byReleaseData?: any[],
    byDateData?: any[],
    byDateDataResolved?: any[],
    byDateDataCreate?: any[],
    byDateDataUpdate?: any[],
    byProjectData?: any[],
    byStatusData?: { [key: string]: any[] },
    byClassifyData?: any[],
    performanceData?: any[],
    priorityDict?: any,
    assignedIssues?: any[],
    dealOngoing?: any,
    issueLatestUpdates?: any,
    performanceAll?: any,
    performanceForUser?: any,
    performanceAllRaw?: any,
    performanceForUserRaw?: any,
    devList?: string[],
    performanceDevRaw?: any,
    activeIssues?: any[],
    redmineProjectList?: any[],
    projectIssues?: any[],
    xdmaster?: any,
    xdCases?: any[],
}

export const StateInitial: State = {
    redmineSub: "AssignedPriority",
    redmineLoaded: true,
    redmineUser: null,
    chartData: [],
    byReleaseData: [],
    byDateData: [],
    byDateDataResolved: [],
    byDateDataCreate: [],
    byDateDataUpdate: [],
    byProjectData: [],
    byStatusData: {},
    byClassifyData: [],
    performanceData: [],
    priorityDict: {},
    assignedIssues: [],
    dealOngoing: {},
    issueLatestUpdates: {},
    performanceAll: {},
    performanceForUser: {},
    performanceAllRaw: {},
    performanceForUserRaw: [],
    devList: [],
    performanceDevRaw: {},
    activeIssues: [],
    redmineProjectList: [],
    projectIssues: [],
}

export type Event = 
    { message: "RemineDidMount", params: {} }
    | { message: "RedmineSub", params: { name: string } } 
    | { message: "renderByProject", params: {} } 
    | { message: "renderByRelease", params: {} } 
    | { message: "RedmineDataRow", params: { data: any[] } } 
    | { message: "GetRedmineIssueSummary", params: {} } 
    | { message: "GetRedminePerformance", params: {} } 
    | { message: "GetTaskList", params: {} } 
    | { message: "GetThroughput", params: {} } 
    | { message: "GetPriorityDict", params: {} } 
    | { message: "GetAssignedIssues", params: {} } 
    | { message: "AddNewPriority", params: any } 
    | { message: "UpdatePriority", params: {priorityId: string, data: any} } 
    | { message: "AddPriorityItem", params: {type: string, item: any} }
    | { message: "DeletePriorityItem", params: {type: string, item: any} }
    | { message: "GetPerformanceDev", params: {} }
    | { message: "GetPerformanceAll", params: {} }
    | { message: "GetPerformanceForUser", params: {} }
    | { message: "GetActiveIssues", params: {} }
    | { message: "GetIssueLatestUpdates", params: {} }
    | { message: "GetRedmineProjects", params: {} }
    | { message: "GetIssuesByProject", params: {projectId: number} }

export type Data = {
    unsubscribeNoti?: any,
    FASTAPI?: string,
    taskListData?: Issue[]
    throughputData?: Throughput[]
    taskList?: ProjectDict
    releaseList?: ReleaseDict,
    duckCon?: any,
}

export const DataInitial = {
    unsubscribeNoti: () => {},
    FASTAPI: "http://localhost:8000",
    taskListData: [],
    throughputData: [],
    taskList: {},
    releaseList: {},
}

export const RedmineDidMount: Handler = async (controller, params) => {
    GetRedmineIssueSummary(controller, params);
    GetRedminePerformance(controller, params);
    // GetIssueStatus(controller, params);
    // GetTaskList(controller, params);
    GetThroughput(controller, params);
}

export const GetRedmineProjects: Handler = async (controller, params) => {
    axios.get(
        `${controller.data.FASTAPI}/api/redmine/project_list/`, 
        {headers: { "Authorization": `Token ${await controller.user.getIdToken()}`}}
      )
      .then((result: any) => {
        controller.setState({redmineProjectList: result.data});
      }).catch((error: any) => {
        console.log(error);
      });
}

export const GetIssuesByProject: Handler = async (controller, {project_id, year,month}) => {
    axios.get(
        `${controller.data.FASTAPI}/api/redmine/issues_by_project/`, 
        {   params: {project_id, year, month},
            headers: { "Authorization": `Token ${await controller.user.getIdToken()}`}}
      )
      .then((result: any) => {
        controller.setState({projectIssues: result.data});
      }).catch((error: any) => {
        console.log(error);
      });
}

export const GetActiveIssues: Handler = async (controller, params) => {
    axios.get(
        `${controller.data.FASTAPI}/api/redmine/issue_in_progress/`, 
        {headers: { "Authorization": `Token ${await controller.user.getIdToken()}`}}
      )
      .then((result: any) => {
        controller.setState({activeIssues: result.data});
      }).catch((error: any) => {
        console.log(error);
      });
}

const testFastapi = async (url:string, controller: any) => {
    axios.get(url, {
      headers: { "Authorization": `Token ${await controller.user.getIdToken()}` }
    }).then((res: any) => {
      console.log(url);
      console.log(res);
    });
}

export const GetIssueStatus: Handler = async (controller, params) => {
    // console.time("issue status")
    axios.get(
      `${controller.data.FASTAPI}/api/redmine/active_issue_groupby_status/`, 
      {headers: { "Authorization": `Token ${await controller.user.getIdToken()}`}}
    )
    // controller.functions.httpsCallable("getRedmineActiveIssueGroupbyStatus")("")
    .then((result: any) => {
        // console.timeEnd("issue status")
        // console.log(result);
        controller.setState({ 
            redmineLoaded: true,
            byStatusData: result.data
        });
    }).catch((error: any) => {
        console.log(error);
    })
}

export const GetTaskList: Handler = async (controller, params) => {
    // console.time("task list");
    axios.get(
      `${controller.data.FASTAPI}/api/redmine/task_list/`, 
      {headers: { "Authorization": `Token ${await controller.user.getIdToken()}`}}
    )
    // controller.functions.httpsCallable("getTaskList")("")
    .then((result: any) => {
        // console.timeEnd("task list");
        // console.log(result);
        controller.setData({ taskListData: result.data });
        processReleaseList(controller);
        renderByRelease(controller);
        processTaskList(controller);
        // renderByProject(controller);
        renderByUpdate(controller);
        renderByResolved(controller);
        renderByCreate(controller);
        // RedmineSub(controller, params);
    }).catch((error: any) => {
        console.log(error);
    })
}

export const GetThroughput: Handler = async (controller, params) => {
    // console.time("throughput");
    axios.get(
      `${controller.data.FASTAPI}/api/redmine/throughput/`, 
      {headers: { "Authorization": `Token ${await controller.user.getIdToken()}`}}
    )
    // controller.functions.httpsCallable("getThroughput")("")
    .then((result: any) => {
        // console.timeEnd("throughput");
        // console.log(result);
        controller.setData({ throughputData: result.data });
        renderCharts(controller)
        // RedmineSub(controller, params);
    }).catch((error: any) => {
        console.log(error);
    })
}

export const GetRedmineIssueSummary: Handler = async (controller, params) => {
    // console.time("issue summary")
    axios.get(
      `${controller.data.FASTAPI}/api/redmine/issue_summary/`, 
      {headers: { "Authorization": `Token ${await controller.user.getIdToken()}`}}
    )
    // controller.functions.httpsCallable("getRedmineIssueSummary")("")
    .then((result: any) => {
        // console.timeEnd("issue summary")
        const data = result.data.sort((a: any, b:any) => (
            a.issues.length > b.issues.length ? -1 : 1
        ));
        controller.setState({ byClassifyData: data })
    }).catch((error: any) => {
        console.log(error);
    })
}

export const GetRedminePerformance: Handler = async (controller, params) => {
    // console.time("performance")
    axios.get(
      `${controller.data.FASTAPI}/api/redmine/performance/`, 
      {headers: { "Authorization": `Token ${await controller.user.getIdToken()}`}}
    )
    // controller.functions.httpsCallable("getRedminePerformance")("")
    .then((result: any) => {
        // console.timeEnd("performance")
        // console.log(result);
        controller.setState({ 
            performanceData: result.data
        });
    }).catch((error: any) => {
        console.log(error);
    })
}

export const RedmineSub: Handler = (controller, params) => {
    alert("RedmineSub");
    // console.log(params)
    // const redmineSub = controller.getState().redmineSub;
    // if (redmineSub === "byRelease") {
    //     controller.setState({redmineLoaded: false}, () => {
    //         processReleaseList(controller)
    //         renderByRelease(controller)
    //     })
    // } else if (redmineSub === "byUpdate") {
    //     renderByUpdate(controller)
    // } else if (redmineSub === "byResolved") {
    //     renderByResolved(controller)
    // } else if (redmineSub === "byCreate") {
    //     renderByCreate(controller)
    // } else if (redmineSub === "byProject") {
    //     processTaskList(controller)
    //     renderByProject(controller)
    // } else if (redmineSub === "charts") {
    //     renderCharts(controller)
    // } else if (redmineSub === "byStatus") {
    //     controller.setState({ redmineSub: "byStatus" });
    // }
}

export const renderByProject: Handler = (controller, params) => {
    const data: ByProjectRow[] = []
    const taskList = controller.data.taskList || {}
    const projectKeys = getProjectKeys(Object.values(taskList), [], null, 0)
    // console.log(projectKeys)
    for (const projectKey of projectKeys) {
        data.push({
            ...initialByProjectRow,
            project_name: Array(projectKey.level - 1).fill('- - ').join('') 
                          + projectKey.name 
                          + countDisplay(taskList[projectKey.name]),
            level: "project",
            project_for_open: projectKey.name,
        })
        if (taskList[projectKey.name].open) {
            for (const release_name of Object.keys(taskList[projectKey.name].releases).sort(
                (a, b) => {
                    const itemA = taskList[projectKey.name].releases[a]
                    const itemB = taskList[projectKey.name].releases[b]
                    return (itemB.count - itemB.resolved) - (itemA.count - itemA.resolved)
                }
            )) {        
                data.push({
                    ...initialByProjectRow,
                    release_name: release_name 
                                  + countDisplay(taskList[projectKey.name].releases[release_name]),
                    level: "release",
                    project_for_open: projectKey.name,
                    release_for_open: release_name
                })
                if (taskList[projectKey.name].releases[release_name].open) {
                    for (const item of taskList[projectKey.name].releases[release_name].issues
                        .filter(item => item.status !== "Resolved")
                        .sort((a, b) => a.created_on < b.created_on ? -1 : 1)
                    ) {
                        data.push({
                            ...initialByProjectRow,
                            issue_id: item.issue_id,
                            subject: item.subject,
                            tracker: item.tracker,
                            status: item.status,
                            created_on: item.created_on,
                            updated_on: item.updated_on,
                            author_user: item.author_user.split("@")[0],
                            assigned_user: item.assigned_user ? item.assigned_user.split("@")[0] : "",
                            level: "issue",
                            project_for_open: projectKey.name,
                            release_for_open: release_name
                        })
                    }
                }
            }
        }
    }
    controller.setState({/*redmineSub: "byProject",*/ byProjectData: data, redmineLoaded: true})
}

export const renderByRelease: Handler = (controller, params) => {
    const data: ByReleaseRow[] = []
    const releaseList = controller.data.releaseList || {}
    // console.log("wasm in redmine")
    // console.log(controller.wasm.byReleaseData(controller.data.releaseList))
    // console.log(releaseList);
    for (const release_name of Object.keys(releaseList).sort(
            (a, b) => releaseList[a].end_date < releaseList[b].end_date ? -1 : 1)
        ) {
        const backgroundColor = (new Date()).toISOString().split("T")[0] >= releaseList[release_name].end_date ? "#ffe2e6": "#ffffbf" 
        data.push({
            ...initialByReleaseRow,
            release_name: `[${releaseList[release_name].issues[0]?.project_name}] ${release_name}`,
            release_start_date: releaseList[release_name].start_date,
            release_end_date: releaseList[release_name].end_date,
            backgroundColor: backgroundColor,
            level: "release",
            release_for_open: release_name,
            project_for_filter: releaseList[release_name].issues[0]?.project_name,
        } as ByReleaseRow)
        if (releaseList[release_name].open) {
            for (const issue of releaseList[release_name].issues) {
                data.push({
                    ...initialByReleaseRow,  
                    project_name: issue.project_name,
                    project_parent_name: issue.project_parent_name,
                    issue_id: issue.issue_id,
                    subject: issue.subject,
                    tracker: issue.tracker,
                    status: issue.status,
                    created_on: issue.created_on,
                    updated_on: issue.updated_on,
                    author_user: issue.author_user.split("@")[0],
                    assigned_user: issue.assigned_user ? issue.assigned_user.split("@")[0] : "",
                    project_for_filter: issue.project_name,
                } as ByReleaseRow)
            }
        }
    }
    controller.setState({/*redmineSub: "byRelease",*/ byReleaseData: data, redmineLoaded: true})
}

export const RedmineDataRow: Handler = (controller, params) => {
    if (params.data.type === "ByProjectRow") {
        const data: ByProjectRow = params.data
        if (data.level == "project") {
            // Project row
            var taskList = controller.data.taskList!
            taskList[data.project_for_open].open = 
                !taskList[data.project_for_open].open
            controller.setData({ taskList: taskList })
            renderByProject(controller)
        } else if (data.level == "release") {
            // Release row
            var taskList = controller.data.taskList!
            taskList[data.project_for_open].releases[data.release_for_open].open = 
                !taskList[data.project_for_open].releases[data.release_for_open].open
            controller.setData({ taskList: taskList })
            renderByProject(controller)
        } // Otherwise do nothing
    } else if (params.data.type === "ByReleaseRow") {
        const data: ByReleaseRow = params.data
        if (data.level === "release") {
            var releaseList = controller.data.releaseList!
            releaseList[data.release_for_open].open = 
                !releaseList[data.release_for_open].open 
            controller.setData({ releaseList: releaseList })
            renderByRelease(controller)
        }
    }
}

export const GetPriorityDict: Handler = async (controller, {newPriority}) => {
    const result = await controller.db.collection("priority")
        .where("active", "==", true).get();
    const resultDict = Object.fromEntries(result.docs.map((doc: any) => 
        [doc.id, {id: doc.id, ...doc.data()}]
    ));    
    const issuesToUpdate = Object.values(resultDict)
        .flatMap((priority: any) => (priority.issues || []));
    const releasesToUpdate = Object.values(resultDict)
        .flatMap((priority: any) => (priority.releases || []));
    GetIssueLatestUpdates(controller, {issues: issuesToUpdate, releases: releasesToUpdate});
    const dealOngoing = (await controller.db.collection("cache").doc("dealOngoing").get()).data();
    if (Object.keys(resultDict).length === 0) return;
    await UpdatePriorityDict(controller, {priorityDict: resultDict});
    await UpdateDealOngoing(controller, {dealOngoing});
}

const UpdatePriorityDict: Handler = async (controller, {priorityDict}) => {
    let { duckCon } = controller.data;
    // Prepare priority_list table
    const priorityItems: any[] = Object.values(priorityDict)
    .map((item: any) => ({
        ...item,
        issues: (item?.issues || []).join(","),
        releases: (item?.releases || []).join(","),
    }));
    try { 
        await duckCon.query(`drop table if exists priority;`)
    } catch {}
    try {
        await duckCon.insertArrowTable(tableFromJSON(priorityItems), 
            {name: "priority", schema: "main", create: true});
    } catch (e: any) { console.log(e); };
    try { await duckCon.query(`
        create or replace table priority_list as
        from priority
        select
        * replace (
            list_filter(issues.split(','), x -> x !='') as issues, 
            list_filter(releases.split(','), x-> x !='') as releases
        );`)
    } catch (e: any) { console.log(e) };
    controller.setState({priorityDict: Object.assign({}, priorityDict)});
}

const UpdateDealOngoing: Handler = async (controller, {dealOngoing}) => {
    let { duckCon } = controller.data;
    let deliverableItems = Object.entries(dealOngoing)
        .flatMap((dealEntry: [string, any]) => (
        Object.entries(dealEntry[1].deliverableDict || {})
            .map((deliverableEntry: [string, any]) => ({
            clientId: dealEntry[1].clientId,
            clientName: dealEntry[1].clientName,
            dealId: dealEntry[1].dealId,
            dealName: dealEntry[1].dealName,
            deliverableId: deliverableEntry[0],
            ...deliverableEntry[1]
            }))
        ));
    try { await duckCon.query(`drop table if exists deliverable;`)} catch {}
    try { await duckCon.insertArrowTable(tableFromJSON(deliverableItems), 
        {name: "deliverable", schema: "main", create: true}
    ) } catch(e: any) { console.log(e); }; 
    controller.setState({dealOngoing});
}

export const GetIssueLatestUpdates: Handler = async (controller, params) => {
    let { duckCon } = controller.data;
    const result = await axios.post(
    `${controller.data.FASTAPI}/api/redmine/issue_latest_update/`, 
    {
        issues: (params.issues || []).join(",") /*'62077,62076'*/, 
        releases: (params.releases || []).join(",") /*'525' */
    },
    {
        headers: { "Authorization": `Token ${await controller.user.getIdToken()}`},
    }).catch((err: any) => console.log(err));
    if (result) {
        let newUpdates = Object.assign({}, controller.getState().issueLatestUpdates);
        result.data.forEach((item: any) => {
            newUpdates[item.id] = item;
        });
        const issueItems: any[] = 
            Object.values(newUpdates)
            .map((item: any) => ({
            ...item,
            id: item.id.toString(),
            release_id: item.release_id?.toString(),
            }));
        try { await duckCon.query(`drop table if exists issues;`)} catch {}
        try { 
            await duckCon.insertArrowTable(
                tableFromJSON(issueItems), 
                {name: "issues", schema: "main", create: true}) 
        } catch (e: any){ console.log(e); }; 
        controller.setState({issueLatestUpdates: newUpdates});
    }
}

export const GetAssignedIssues: Handler = async (controller, params) => {
    const result = await axios.get(
    `${controller.data.FASTAPI}/api/redmine/issue_by_assign/`, 
    {
        headers: { "Authorization": `Token ${await controller.user.getIdToken()}`},
        params: {
            assign: controller.getState().redmineUser
        }
    }).catch((err: any) => console.log(err));
    if (result) {
        controller.setState({assignedIssues: result.data});
    }
}

export const AddNewPriority: Handler = async (controller, {newPriority}) => {
    if (!newPriority) return;
    const data = {...newPriority, active: true};
    const result = await controller.db.collection("priority").add(data);
    let newPriorityDict = Object.assign({}, controller.getState().priorityDict);
    newPriorityDict[result.id] = {...newPriorityDict[result.id], ...data};
    await UpdatePriorityDict(controller, {priorityDict: newPriorityDict})
    // controller.setState({priorityDict: newPriorityDict});
}

export const UpdatePriority: Handler = async (controller, {priorityId, data}) => {
    if (!priorityId || !data) return;
    // Update state
    let newPriorityDict = Object.assign({}, controller.getState().priorityDict);
    newPriorityDict[priorityId] = {
        ...newPriorityDict[priorityId], 
        ...data,
    };
    controller.db.collection("priority").doc(priorityId).update(data);
    await UpdatePriorityDict(controller, {priorityDict: newPriorityDict})
    // controller.setState({priorityDict: newPriorityDict});
}

export const AddPriorityItem: Handler = async (controller, {priorityId, type, item}) => {
    if (!priorityId || !type) return;
    const priorityDict = Object.assign({}, controller.getState().priorityDict);
    if (type === "issue") {
        if (!item) return
        GetIssueLatestUpdates(controller, {issues: [item]});
        let issueSet = new Set(priorityDict[priorityId]?.issues || []);
        issueSet.add(item);
        let newIssues = Array.from(issueSet);
        controller.db.collection("priority").doc(priorityId).update({
            issues: newIssues
        });
        priorityDict[priorityId].issues = newIssues;
        await UpdatePriorityDict(controller, {priorityDict});
        // controller.setState({priorityDict});
    } else if (type === "release") {
        if (!item) return
        GetIssueLatestUpdates(controller, {releases: [item]});
        let releaseSet = new Set(priorityDict[priorityId]?.releases || []);
        releaseSet.add(item);
        let newReleases = Array.from(releaseSet);
        controller.db.collection("priority").doc(priorityId).update({
            releases: newReleases
        });
        priorityDict[priorityId].releases = newReleases;
        await UpdatePriorityDict(controller, {priorityDict});
        // controller.setState({priorityDict});
    } else if (type === "refresh") {
        GetIssueLatestUpdates(controller, {
            issues: priorityDict[priorityId]?.issues || [],
            releases: priorityDict[priorityId]?.releases || [],
        });
    }
}

export const DeletePriorityItem: Handler = async (controller, {priorityId, type, item}) => {
    if (!priorityId || !type || !item) return;
    const priorityDict = Object.assign({}, controller.getState().priorityDict);
    if (type === "issue") {
        let newIssues = (priorityDict[priorityId]?.issues || [])
            .filter((issue: string) => issue !== item.toString())
        controller.db.collection("priority").doc(priorityId).update({
            issues: newIssues
        });
        priorityDict[priorityId].issues = newIssues;
        await UpdatePriorityDict(controller, {priorityDict});
        // controller.setState({priorityDict});
    } else if (type === "release") {
        let newReleases = (priorityDict[priorityId]?.releases || [])
            .filter((release: string) => release !== item.toString())
        controller.db.collection("priority").doc(priorityId).update({
            releases: newReleases
        });
        priorityDict[priorityId].releases = newReleases;
        await UpdatePriorityDict(controller, {priorityDict});
        // controller.setState({priorityDict});
    }
}

// Get performance data from storage (firestore not sufficient)
const getPerformanceFromStorage: Handler = async (controller, {user}) => {
    const url = await controller.storage
                        .refFromURL(`gs://ismor-redmine/performances/${user}.json`)
                        .getDownloadURL()
    const res = await axios.get(url, { responseType: "json" });
    return res.data
}

export const GetPerformanceDev: Handler = async (controller, {supervise}) => {
    let { duckCon } = controller.data;
    const devList = ((await controller.db.collection("masterdata").doc("devList").get())
                    .data()?.items || []).sort((a: string, b: string) => a < b ? -1 : 1);
    const result = Object.fromEntries(
        await Promise.all(devList.map(async (email: any) => (
            [email, await getPerformanceFromStorage(controller, {user: email})]
        ))));
    let byDev = Object.entries(result)
        .map(([email, issueDict]: [string, any]) => {
            let issueList = Object.entries(issueDict)
                .map(([issueId, journalDict]: [string, any]) => {
                    let resolveList: any[] = Object.values(journalDict)
                     .filter((journal: any) => 
                        journal.status_id === "3"
                    );
                    if (resolveList.length > 0 
                        && resolveList[resolveList.length - 1].username === email) {
                        return { 
                            issueId, 
                            last_resolved: resolveList[resolveList.length - 1].created_on
                        };
                    } else {
                        return { issueId, last_resolved: null};
                    }
                });
            return { 
                username: email, 
                issues: issueList.filter((issue: any) => issue.last_resolved)
            };
        });
    let performanceDevRaw = byDev.flatMap(
        (row: any) => row.issues.map((issue: any) => ({username: row.username, ...issue})));
    
    try { await duckCon.query(`drop table if exists performance_dev;`)} catch {}
    try {
        await duckCon.insertArrowTable(tableFromJSON(performanceDevRaw), 
            {name: "performance_dev", schema: "main", create: true})
    } catch(e: any) { console.log(e); }; 

    try { await duckCon.query(`drop table if exists devlist;`)} catch {}
    try {
        await duckCon.insertArrowTable(tableFromJSON(devList.map((dev: string) => ({username: dev}))), 
            {name: "devlist", schema: "main", create: true});
    } catch(e: any) { console.log(e); }; 
    
    controller.setState({devList, performanceDevRaw})
}

export const GetPerformanceAll: Handler = async (controller, {supervise}) => {
    let {redmineUser} = controller.getState();
    let { duckCon } = controller.data;
    const perfResult = await controller.db.collection("performances")
                                .where("#supervisor", "array-contains", redmineUser)
                                .get();
    const performanceAllRaw = Object.fromEntries(
        await Promise.all(perfResult.docs.map(async (doc: any) => (
            [doc.id, await getPerformanceFromStorage(controller, {user: doc.id})]
        ))));
    let performanceAll = Object.fromEntries(
        perfResult.docs.map((doc: any) => [
            doc.id, 
            {
                ...doc.data(),
                ...(performanceAllRaw[doc.id])
            }
        ])
    );
    let userIssues = Object.fromEntries(
        Object.entries(performanceAll).map((entry: [string, any]) => (
            [entry[0], preparePerformanceReport(entry[1], entry[0])]
        ))); 

    // Save to duck ---------------------------------------------------------------
    await createDayPerformanceTable(duckCon, "day_performance_all", userIssues);

    // Set state ------------------------------------------------------------------
    controller.setState({
        performanceAll: userIssues, 
        performanceAllRaw: performanceAllRaw
    });
}

export const GetPerformanceForUser: Handler = async (controller, {}) => {
    let { duckCon } = controller.data;
    let {redmineUser, issueLatestUpdates} = controller.getState();
    // redmineUser = "sajja@thevcgroup.com";
    if (!redmineUser || redmineUser === null) return;
    let performanceUserRaw = {
        [redmineUser]: await getPerformanceFromStorage(controller, {user: redmineUser})
    }
    let rawPerformance = {
        ...((await (controller.db.collection("performances").doc(redmineUser).get())).data() || {}),
        ...(performanceUserRaw[redmineUser])
    }
    let perf = preparePerformanceReport(rawPerformance, redmineUser);
    const result = await axios.post(
        `${controller.data.FASTAPI}/api/redmine/issue_latest_update/`, 
        {
            issues: (perf.issues || []).map((issue: any) => issue.issue_id).join(",")
        },
        {
            headers: { "Authorization": `Token ${await controller.user.getIdToken()}`},
        }).catch((err: any) => console.log(err));
    let resultDict;
    if (result) {
        resultDict = Object.fromEntries(result.data.map((row: any) => [row.id, row]));
    } else {
        resultDict = issueLatestUpdates
    }
    for (const index in perf.issues) {
        perf.issues[index].detail = resultDict[perf.issues[index].issue_id];
    }
    // console.log(perf);

    // Save to duck ---------------------------------------------------------------
    await createDayPerformanceTable(duckCon, "day_performance_user", { [redmineUser]: perf });
    
    // Set state ------------------------------------------------------------------
    controller.setState({
        performanceForUser: { [redmineUser]: perf }, 
        performanceForUserRaw: performanceUserRaw
    });  
}

const createDayPerformanceTable = async (duckCon: any, tablename: string, data: any) => {
    let temp = Object.entries(data)
    .flatMap((entry: [string, any]) => (
        entry[1].issues.flatMap((issue: any) => (
        issue.status_log.map((status_log_item: any) => ({
            perf_user: entry[0],
            issue_id: issue.issue_id,
            ...status_log_item,
            detail_id_unique: status_log_item.detail_id === "create_status" ? 
                                `${issue.issue_id}_create_status` : status_log_item.detail_id,
            created_date: status_log_item.created_on.substring(0, 10)
        }))
        ))
    ));
    try { await duckCon.query(`drop table if exists ${tablename};`)} 
    catch (e: any) { console.log(e); }
    try {
        await duckCon.insertArrowTable(tableFromJSON(temp), 
            {name: `${tablename}`, schema: "main", create: true})
    } catch(e: any) { console.log(e); }; 
}

const preparePerformanceReport = (rawPerformance: any, user: string) => {
    let issues = Object.entries(rawPerformance)
        .filter((entry: any) => entry[0] !== "#supervisor")
        .map((entry: any) => ({
            issue_id: entry[0], 
            status_log: Object.entries(entry[1]).map((detailEntry: any) => (
                {
                    detail_id: detailEntry[0],
                    ...detailEntry[1]
                }
                ))
                .sort((a: any, b: any) => a.created_on < b.created_on ? -1 : 1),
            details: entry[1],
            supervisor: rawPerformance["#supervisor"]
        }))
        .map((issue: any) => ({
            ...issue, 
            status_id: issue.status_log[issue.status_log.length -1].status_id,
            resolve_list: issue.status_log
                            .filter((log: any) => log.status_id === "3"),
            closed: issue.status_log[issue.status_log.length - 1].status_id === "5",
            author: issue.details?.create_status?.username,
            created_on: issue.details?.create_status?.created_on
        }))
        .map((issue: any) => ({
            ...issue,
            last_resolved: issue.resolve_list.length > 0 ? 
                                issue.resolve_list[issue.resolve_list.length - 1].created_on : null,
            last_resolving_user: issue.resolve_list.length > 0 ? 
                                issue.resolve_list[issue.resolve_list.length - 1].username : null,
            resolver_last: issue.resolve_list.map((item: any) => item.username).includes(user)
                        && issue.resolve_list[issue.resolve_list.length - 1].username === user,
            resolver_contributor: issue.resolve_list.map((item: any) => item.username).includes(user)
                        && issue.resolve_list[issue.resolve_list.length - 1].username !== user,
        }))
        .map((issue: any) => ({
            ...issue,
            is_author: !issue.resolver_last && (issue.author === user)
        }));  
        return { supervisor: rawPerformance["#supervisor"], issues };
}

// Helper functions ---------------------------------------------------------------------------------------------
const processReleaseList: Handler = (controller, params) => {
    const taskListData = controller.data.taskListData
    const releaseList: ReleaseDict = {}
    // console.log(taskListData?.[0])
    const taskListDataFilter = taskListData?.filter(
        item => item.release_name !== null && 
                item.release_status === "open" &&
                item.release_name !== NO_RELEASE
        ) || []
    
    for (const item of taskListDataFilter) {
        if (!Object.keys(releaseList).includes(item.release_name)) {
            releaseList[item.release_name] = {
                name: item.release_name,
                start_date: item.release_start_date,
                end_date: item.release_end_date,
                open: false,
                count: 0,
                resolved: 0,
                issues: []
            }
        }
        releaseList[item.release_name].issues.push(item)
        releaseList[item.release_name].count += 1
        if (item.status === "Resolved") {
            releaseList[item.release_name].resolved += 1
        }
    }
    controller.setData({ releaseList: releaseList })
}

const processTaskList: Handler = (controller, params) => {
    const taskListData = controller.data.taskListData
    const taskList: ProjectDict = {}     
    for (const item of taskListData || []) {
        if (!Object.keys(taskList).includes(item.project_name)) {
            taskList[item.project_name] = {
                name: item.project_name,
                parent: item.project_parent_name,
                open: false,
                count: 0,
                resolved: 0,
                releases: {} 
            }
        }
        if (item.release_name === null)
            item.release_name = NO_RELEASE
        if (!Object.keys(taskList[item.project_name].releases).includes(item.release_name)) {
            taskList[item.project_name].releases[item.release_name] = {
                name: item.release_name,
                start_date: "",
                end_date: "",
                open: false,
                count: 0,
                resolved: 0,
                issues: []
            }
        }
        taskList[item.project_name].releases[item.release_name].issues.push(item)
        taskList[item.project_name].count += 1
        taskList[item.project_name].releases[item.release_name].count += 1
        if (item.status === "Resolved") {
            taskList[item.project_name].resolved += 1
            taskList[item.project_name].releases[item.release_name].resolved += 1
        }
    }
    controller.setData( { taskList: taskList })
}

const renderByUpdate: Handler = (controller, params) => {
    var data: ByDateRow[] = []
    // console.time("render update");
    data = controller.data.taskListData
            ?.filter(item => item.status !== "Resolved")
            .sort((a, b) => a.updated_on < b.updated_on ? 1: -1)
            .map(item => (
                {   ...item,
                    author_user: item.author_user.split("@")[0],
                    assigned_user: item.assigned_user ? item.assigned_user.split("@")[0] : ""
                } as ByDateRow)) || []
    // console.timeEnd("render update");
    // console.time("set state");
    controller.setState({/*redmineSub: "byUpdate",*/ 
        byDateDataUpdate: data, 
        redmineLoaded: true
    }, () => { /*console.timeEnd("set state")*/})
}

const renderByResolved: Handler = (controller, params) => {
    var data: ByDateRow[] = []
    data = controller.data.taskListData
            ?.filter(item => item.status === "Resolved")
            .sort((a, b) => a.updated_on < b.updated_on ? 1: -1)
            .map(item => (
                {   ...item,
                    author_user: item.author_user.split("@")[0],
                    assigned_user: item.assigned_user ? item.assigned_user.split("@")[0] : ""
                } as ByDateRow)) || []
    controller.setState({/*redmineSub: "byResolved",*/ byDateDataResolved: data, redmineLoaded: true})
}

const renderByCreate: Handler = (controller, params) => {
    var data: ByDateRow[] = []
    data = controller.data.taskListData
            ?.filter((item: any) => item.status !== "Resolved")
            .sort((a: any, b: any) => a.created_on < b.created_on ? 1: -1)
            .map((item: any) => (
                {   ...item,
                    author_user: item.author_user.split("@")[0],
                    assigned_user: item.assigned_user ? item.assigned_user.split("@")[0] : ""
                } as ByDateRow)) || []
    controller.setState({/*redmineSub: "byCreate",*/ byDateDataCreate: data, redmineLoaded: true})
}

const renderCharts: Handler = (controller, params) => {
    var data: { [date: string]: ChartRow } = {}
    // const dayList = this.taskListData
    //                     .filter(item => item.created_on.includes("2020"))
    //                     .map(item => ({
    //                         ...item, 
    //                         created_on: item.created_on.split(" ")[0],
    //                         updated_on: item.updated_on.split(" ")[0]
    //                     }))
    // for (const item of dayList) {
    const throughputData = controller.data.throughputData || []
    // console.log(throughputData)
    for (const item of throughputData) {
        if (!Object.keys(data).includes(item.created_on)) {
            data[item.created_on] = { 
                date: item.created_on, 
                created_on_count: 0,
                updated_on_count: 0,
                resolved_on_count: 0,
            }
        }
        if (!Object.keys(data).includes(item.updated_on)) {
            data[item.updated_on] = { 
                date: item.updated_on, 
                created_on_count: 0,
                updated_on_count: 0,
                resolved_on_count: 0,
            }
        }
        if (item.resolved_on && !Object.keys(data).includes(item.resolved_on)) {
            data[item.resolved_on] = { 
                date: item.resolved_on, 
                created_on_count: 0,
                updated_on_count: 0,
                resolved_on_count: 0,
            }
        }
        data[item.created_on].created_on_count += 1
        data[item.updated_on].updated_on_count += 1
        if (item.resolved_on) {
            data[item.resolved_on].resolved_on_count += 1
        }
    }
    const chartData: ChartRow[] = Object.values(data)
                                    .sort((a, b) => a.date < b.date ? -1: 1)
                                    .slice(Object.keys(data).length - 29)
    // console.log(chartData)
    controller.setState({/*redmineSub: "charts",*/ chartData: chartData, redmineLoaded: true})
}

// export const GetPerformanceAllOld: Handler = async (controller, {}) => {
//     // const result = await controller.db.collection("performances").get();
//     // let performanceAll = Object.fromEntries(
//     //     result.docs.map((doc: any) => [doc.id, {...doc.data()}]),
//     // )
//     // DownloadDataAsJsonFile(performanceAll)
//     let performanceAll = await (await fetch("/data/performances.json")).json();
//     controller.setState({performanceAll});
// }
