import { useEffect, useState } from "react";
import OngoingView from "./OngoingView";
import SalesView from "./SalesView";
import DealList from "./DealList";
import ClientList from "./ClientList";
import { Button, Input, Modal } from "semantic-ui-react";
import DealDetail from "./DealDetail";

export default function Client(props: any) {
  const [mode, setMode] = useState("Ongoing");
  const [selectedDeal, setSelectedDeal] = useState<any>(null);
  const [pin, setPin] = useState("");
  const [newPin, setNewPin] = useState("");
  const [newPinConfirm, setNewPinConfirm] = useState("");
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    props.onEvent({ message: "GetClientData", params: {} });
    return () => {
      setAuthorized(false);
    }
  }, []);

  useEffect(() => {
    if (mode === "ChangePin") {
      setAuthorized(false);
    }
  }, [mode]);

  useEffect(() => {
    if (selectedDeal !== null) {
      setSelectedDeal(props.clientDeal[selectedDeal.id])
    }
  }, [props.clientDeal]);

  return(
    <div style={{height: "95vh"}}>
      {authorized?
      <>
        <div 
          style={{
            display: "flex", height: "3vh", alignItems: "center",
            borderBottom: "solid #cccccc 1px"
          }}>
          <MenuButton name="Ongoing" mode={mode} setMode={setMode}/>
          <MenuButton name="Sales" mode={mode} setMode={setMode}/>
          <MenuButton name="All Deals" mode={mode} setMode={setMode}/>
          <MenuButton name="All Clients" mode={mode} setMode={setMode}/>
          <div style={{flex: 1}}></div>
          <div 
            style={{marginRight: "10px", cursor: "pointer"}}
            onClick={(e: any) => setMode("ChangePin")}>
            [Change Pin]
          </div>
        </div>
        <div style={{height: "92vh", padding: "5px"}}>
          {mode === "Sales" ? //==========================================
          <SalesView
            onEvent={props.onEvent}
            setProp={props.setProp}
            webviewReady={props.webviewReady}
            clientDeal={props.clientDeal}
            webviewMessages={props.webviewMessages}
            pdfExtract={props.pdfExtract}
            python={props.python}
            pythonLoaded={props.pythonLoaded}
            pythonError={props.pythonError}
            bokehLoaded={props.bokehLoaded}
            selectedDeal={selectedDeal}
            setSelectedDeal={setSelectedDeal}
          />
          
          : mode === "Ongoing" ?
          <OngoingView
            clientDeal={props.clientDeal}
            setSelectedDeal={setSelectedDeal}
          />
          
          : mode === "All Deals" ? //==============================================
          <DealList
            onEvent={props.onEvent}
            clientDict={props.clientDict}
            clientDeal={props.clientDeal}
            selectedDeal={selectedDeal}
            setSelectedDeal={setSelectedDeal}
          />

          : mode === "All Clients" ? //============================================
          <ClientList
            onEvent={props.onEvent}
            clientDict={props.clientDict}
          />
          
          : mode === "ChangePin" ?
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height : "100%"}}>
            {authorized ?
            <>
              <div>
                <Input 
                  type="password"
                  placeholder="กรอก PIN ใหม่"
                  onChange={(e: any) => {
                    setNewPin(e.target.value);
                  }}
                />
              </div>
              <div>
                <Input 
                  type="password"
                  placeholder="กรอก PIN ซ้ำ"  
                  onChange={(e: any) => {
                    if (newPin === e.target.value) {
                      setNewPin("");
                      setNewPinConfirm("");
                      props.onEvent({message: "SetPin", params: {pin: newPin}});
                      setMode("Ongoing");
                    } else {
                      setNewPinConfirm(e.target.value);
                    }
                  }}
                />
              </div>
              <Button color="red" onClick={(e: any) => setMode("Ongoing")}>Cancel</Button>
            </>
            :
            <div>
              <PinChecker pin={props.pin} setPin={setPin} setAuthorized={setAuthorized} />
            </div>
            }
          </div>

          :
          <></>}
        </div>
        <Modal
          size="large"
          style={{minHeight: "40vh"}}
          closeOnDimmerClick={true}
          closeIcon={true}
          onClose={() => setSelectedDeal(null)}
          open={selectedDeal !== null}>
          <DealDetail 
            onEvent={props.onEvent}
            getDocumentUrl={props.getDocumentUrl}
            setProp={props.setProp}
            selectedDeal={selectedDeal}
            setSelectedDeal={setSelectedDeal}
            webviewReady={props.webviewReady}
            clientDeal={props.clientDeal}
            webviewMessages={props.webviewMessages}
            pdfExtract={props.pdfExtract}
            python={props.python}
            pythonLoaded={props.pythonLoaded}
            pythonError={props.pythonError}
            bokehLoaded={props.bokehLoaded}
          />
        </Modal>
      </>
      :
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height : "100%"}}>
        <PinChecker pin={props.pin} setPin={setPin} setAuthorized={setAuthorized} />
      </div>
      }
    </div>
  )
}

function MenuButton(props: any) {
  return(
    <div
      style={{
        height: "100%", display: "flex", alignItems: "center",
        padding: "0 10px 0 10px", 
        backgroundColor: props.mode === props.name ? "#eeeeee" : "white", 
        cursor: "pointer"
      }}
      onClick={(e: any) => {
        props.setMode(props.name);
      }}>
      {props.name}
    </div>
  )
}

function PinChecker(props: any) {
  return(
    <Input 
      type="password"
      placeholder="กรอก PIN"
      onChange={(e: any) => {
        if (e.target.value === props.pin) {
          props.setPin("");
          props.setAuthorized(true);
        } else {
          props.setPin(e.target.value)
        }
      }}
    />
  )
}

