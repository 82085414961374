import React, { useState, forwardRef, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Modal, Grid, Segment, Button, Input } from "semantic-ui-react";

// Common
import CardLayout from "react-lib/apps/common/CardLayout";
import ModConfirm from "react-lib/apps/common/ModConfirm";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ComboBox from "react-lib/apps/common/ComboBox";
import ModInfo from "react-lib/apps/common/ModInfo";
import CardEncounterList from "react-lib/apps/common/CardEncounterList";
import PatientIdentification from "react-lib/apps/common/PatientIdentification";
import FrameChrome from "react-lib/apps/common/FrameChrome";
import SearchBox from "react-lib/apps/common/SearchBox";
import PureReactTable from "./PureReactTable";
import getPdfMake from "react-lib/appcon/common/pdfMake";

// Util
import { beToAd } from "react-lib/utils/dateUtils";
import { vcMessenger } from "react-lib/compat/vc-websocket";
import moment from "moment";
import _ from "react-lib/compat/lodashplus";

// Config
import config from "../../../config/config";

// PDF
import { AddHeaderPrintListTH } from "./pdfPrintList/AddHeaderSapiensPrintListTH";
import { AddHeaderPrintListEN } from "./pdfPrintList/AddHeaderSapiensPrintListEN";
import { default as FormLivingWill } from "./pdfPrintList/FormLivingWill";
import { default as FormConsentPersonalHealth } from "./pdfPrintList/FormConsentPersonalHealth";
import { default as FormConsentDrugScreenTH } from "./pdfPrintList/FormConsentDrugScreenTH";
import { default as FormConsentDrugScreenEN } from "./pdfPrintList/FormConsentDrugScreenEN";
import { default as FormConsentNarcoticUseTH } from "./pdfPrintList/FormConsentNarcoticUseTH";
import { default as FormConsentNarcoticUseEN } from "./pdfPrintList/FormConsentNarcoticUseEN";
import { default as FormConsentHIVTestTH } from "./pdfPrintList/FormConsentHIVTestTH";
import { default as FormConsentHIVTestEN } from "./pdfPrintList/FormConsentHIVTestEN";
import { default as FormConsentBloodTransfusionTH } from "./pdfPrintList/FormConsentBloodTransfusionTH";
import { default as FormConsentBloodTransfusionEN } from "./pdfPrintList/FormConsentBloodTransfusionEN";
import { default as FormConsentPreSedationTH } from "./pdfPrintList/FormConsentPreSedationTH";
import { default as FormConsentPreSedationEN } from "./pdfPrintList/FormConsentPreSedationEN";
import { default as FormConsentOperatingTH } from "./pdfPrintList/FormConsentOperatingTH";
import { default as FormConsentTreatmentProcedure } from "./pdfPrintList/FormConsentTreatmentProcedure";

// Const c.
const HOST = `${config.API_HOST}`;
const isEnableHeader = config.ENABLE_HEADER_PRINTLIST;

/* Note About Inject Data in PDF Form 
- FormConsentPreSedationTH : Inject Table Detail Anesthetic ท้ายสุดของ Content
- FormConsentPreSedationEN : Inject Table Detail Anesthetic ท้ายสุดของ Content
- FormConsentTreatmentProcedure : Inject Table Finger Print หลัง Paragraph "ทั้งนี้ข้าพเจ้า เข้าใจ......เป็นหลักฐาน"
*/

const CardPrintList = forwardRef((props: any, ref) => {
  const [taskTracking, setTaskTracking] = useState(null);
  const [openModEncounterList, setOpenModEncounterList] = useState(false);
  const [encounterId, setEncounterId] = useState(null);
  const [encounterList, setEncounterList] = useState([]); //* as default options for SearchBox
  const [documentType, setDocumentType] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null); //* pdf
  const [message, setMessage] = useState<any>({}); //* msg from vcMessenger
  const [patientInfo, setPatientInfo] = useState(props.patientInfo); // for Search Encounter
  const [emrId, setEmrId] = useState(props.patientData?.EMR?.emr_id); // for Search Encounter
  const [encounterNumber, setEncounterNumber] = useState(props.encounterNumber); // for Search Encounter
  const [doctorName, setDoctorName] = useState(props.patientInfo?.doctor_name); // for Search Encounter
  const [isSearchDocumentLoading, setIsSearchDocumentLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  //* show error and success
  const [openModInfo, setOpenModInfo] = useState(false);
  const [modInfoTitle, setModInfoTitle] = useState("");
  const [modInfoTitleColor, setModInfoTitleColor] = useState("");
  const [modInfoText, setModInfoText] = useState("");

  //* Mod Show task progress
  const [openModInfoTask, setOpenModInfoTask] = useState(false);
  const [modInfoTaskText, setModInfoTaskText] = useState("");

  //* Don't know what this is
  const [getPrinted, setGetPrinted] = useState(false);

  //* Cant find use case yet
  const [openModConfirm, setOpenModConfirm] = useState(false);
  const [modConfirmTitle, setModConfirmTitle] = useState("แจ้งเตือน!!!");
  const [reasonChoices, setReasonChoices] = useState([]);
  const [modConfirmError, setModConfirmError] = useState(null);

  const isMounted = useRef(false);
  const sbxEncounterRef = useRef();
  const reasonRef = useRef();

  useEffect(() => {
    isMounted.current = true;
    getDocumentType();
    vcMessenger.onMessage((msg: any) => {
      try {
        setMessage(msg);
      } catch (e) {}
    });
    getReason();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (message.event === "DPI_DOCTOR_ORDER_PRINT") {
      if (message.task_id === taskTracking) {
        handleTaskTrackingStatus({ data: message });
      }
    }
  }, [message]);

  useEffect(() => {
    if (props.encounterId) {
      setEncounterId(
        props.encounterId ? props.encounterId : props.patientInfo?.encounter_id
      );
    }
  }, [props.encounterId]);

  useEffect(() => {
    if (props.encounterNumber) {
      handleSearchEncounter({ searchText: props.encounterNumber });
    }
  }, [props.encounterNumber]);

  useEffect(() => {
    if (taskTracking) {
      var task = setInterval(() => {
        getTaskTracking();
      }, 15000);
    }
    return () => clearInterval(task);
  }, [taskTracking]);

  const clear = () => {
    setFile(null);
    setSelectedRow(null);
    getDocumentType();
    setTaskTracking(null);
    setGetPrinted(false);
    // frmError.clear()
    // default Data
    setEncounterId(null);
    setPatientInfo(props.patientInfo);
    setEmrId(null);
    setEncounterNumber(null);
    setDoctorName(null);
  };

  const getReason = async () => {
    const [data, error] = await props.controller.getClinicalTerm({
      type: "REASON_PATIENT_CARD",
    });
    if (isMounted.current) {
      if (data) {
        setReasonChoices(data);
      }
    }
  };

  const handleTaskTrackingStatus = ({ data }: any = {}) => {
    // console.log("Print List Data: ", data);
    if (data) {
      if (data.status === TASK_TRACKING_STATUS.COMPLETED && !getPrinted) {
        setTaskTracking(null);
        getPrintedDocument();
        setGetPrinted(true);
        setOpenModInfoTask(false);
      } else if (data.status === TASK_TRACKING_STATUS.FAILED) {
        setTaskTracking({ taskId: data.task_id } as any);
        setModInfoTaskText(data.task_id);
        setModInfoTitle("ผิดพลาด");
        setModInfoTitleColor("red");
      } else if (data.status === TASK_TRACKING_STATUS.CANCELED) {
        setTaskTracking(null);
        setModInfoText(data.current_progress_description);
        setModInfoTitle("ยกเลิก");
        setModInfoTitleColor("red");
        setOpenModInfoTask(false);
        setOpenModInfo(true);
      } else if (data.status === TASK_TRACKING_STATUS.IN_PROGRESS) {
        setModInfoTaskText(
          (
            <div style={{ textAlign: "left", padding: 0 }}>
              <div>กรุณารอสักครู่ </div>
              <div>
                Status:{" "}
                {`${data.status} ${
                  data.current_progress ? data.current_progress : data.progress
                }%`}
              </div>
              For IT: task_id = {data.task_id}
            </div>
          ) as any
        );
      } else if (!data.hasOwnProperty("status")) {
        setTaskTracking(null);
        handleGetDocumentResult({ data, preview: true });
      }
    }
  };

  const getPrintedDocument = ({ taskId }: any = {}) => {
    (reasonRef as any).current.clear();
    getTaskTracking({ taskId } as any);
  };

  const getTaskTracking = async ({ action, taskId }: any = {}) => {
    const [data, error] = await props.controller.getTaskTrackingResult({
      taskId: taskId ? taskId : taskTracking,
      action,
    });
    if (isMounted.current) {
      handleTaskTrackingStatus({ data });
    }
  };

  const getDocumentType = async () => {
    setIsLoading(true);
    const [data, error] = await props.controller.getDocumentType({
      // name: "ใบลงทะเบียนผู้ป่วยใหม่ (New Patient Registration Form)",
      printable: true,
      division: "current",
      offset: 0,
      limit: 40,
    });
    if (isMounted.current) {
      if (data) {
        setDocumentType(data.items);
      }
    }
    setIsLoading(false);
  };

  const handleSearchEncounter = async ({ searchText }: any = {}) => {
    const [data, error] = await props.controller.getEncounterSearch({
      // patientId: props.patientId, // #65599 show all encounter
      patientHn: props.patientInfo?.hn,
      search: searchText,
    });
    if (isMounted.current) {
      if (data) {
        setEncounterList(data.items);
      }
    }
    return [data, error];
  };

  // Search Document
  const handleSearchDocumentType = async ({ target }: any) => {
    const searchText = target.value;
    setSearchInput(searchText);
    const minCharacters = 3;

    if (searchText.length === 0) {
      getDocumentType();
      return;
    }
    if (searchText.length >= minCharacters) {
      debounceSearchDocument(searchText);
    }
  };

  // Debounce Search Document
  const debounceSearchDocument = _.debounce(async (inputValue: any) => {
    setIsSearchDocumentLoading(true);
    const [data, error] = await props.controller.getDocumentType({
      name: inputValue,
      printable: true,
      division: "current",
      offset: 0,
      limit: 40,
    });
    if (isMounted.current) {
      if (data) {
        setDocumentType(data.items);
      }
    }
    setIsSearchDocumentLoading(false);
  }, 500);

  const handleSelectOption = async ({ item }: any = {}) => {
    getEncounterSearchDetail({ encounterId: item.id });
  };

  const getEncounterSearchDetail = async ({ encounterId }: any = {}) => {
    const [data, error] = await props.controller.getEncounterSearchDetail({
      encounterId,
    });
    if (isMounted.current) {
      if (data) {
        setEncounterId(data.id);
        setEncounterNumber(data.number);
        setDoctorName(data.doctor_name);
      }

      // get Encounter Detail
      let encounterData = await getEncounter({ encounterId: encounterId });

      // console.log("Print List Encounter Data: ", encounterData);
      // get Patient Detail
      if (encounterData) {
        setEmrId(encounterData.medical_record);

        // get Patient Detail
        let patientDetailData = await getPatientDetailView({
          patientId: encounterData.patient,
        });

        // console.log("Print List PatientDetail Data: ", patientDetailData);
        if (patientDetailData) {
          setPatientInfo(patientDetailData[0]);
        }
      }
    }
  };

  const getEncounter = async ({ encounterId }: any = {}) => {
    const [data, error] = await props.controller.getEncounter({
      encounterId,
    });
    if (data) {
      return data;
    }
  };

  const handleSelectDocType = (originalRow: any) => {
    setSelectedRow(originalRow);
    console.log("Print List Original Row: ", originalRow);
    if (originalRow?.print_api !== "/pdfmake") {
      getPrintAPI({ originalRow, preview: true } as any);
    } else {
      getPrintPDFMake({ originalRow, print: false } as any);
    }
  };

  const handlePrintAPIFail = ({ error, preview, confirm }: any = {}) => {
    if (preview) {
      clear();
    }
    setFile(null);
    if (confirm) {
      if (error) {
        setModConfirmTitle(error.message);
      }
      setOpenModConfirm(true);
    } else {
      setModInfoTitle("ผิดพลาด");
      setModInfoTitleColor("red");
      if (error) {
        if (error.message) {
          setModInfoText(error.message);
        } else {
          setModInfoText(error);
        }
      }
      setOpenModInfo(true);
    }
  };

  const getPrintAPI = async ({ originalRow, preview, confirm }: any = {}) => {
    setFile(null);
    if (!originalRow) {
      return;
    }
    setIsLoading(true);
    const [data, error, network] = await props.controller.getPrintAPI({
      printAPI: HOST + originalRow.print_api,
      patientId: props.patientId ? props.patientId : props.patientInfo?.id, // #65578 and #65580 patientId is null
      orderId: props.operatingOrderId,
      encounterId,
      jasperModule: originalRow.jasper_module,
      jasperPath: originalRow.jasper_path,
      pdf: preview,
      confirm,
      reason: reasonRef.current ? (reasonRef as any).current.getValue() : "",
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        handleGetDocumentResult({ data, preview });
      } else if (error && ![500, 503].includes(network.response.status)) {
        handlePrintAPIFail({ error, preview, confirm });
      }
    }
  };

  const getOperatingOrderDetailByEmr = async ({ emrId }: any = {}) => {
    if (!emrId) {
      return;
    }
    const [data, error] = await props.controller.getOperatingOrderDetailByEmr({
      emrId: emrId,
    });
    // console.log("Print List Operating Order Data: ", data);
    return [data, error];
  };

  const getDoctorDetail = async ({ doctorId }: any = {}) => {
    if (!doctorId) {
      return;
    }
    const [data, error] = await props.controller.getDoctorDetail({
      doctorId: doctorId,
    });
    // console.log("Print List Doctor Detail Data: ", data);
    return [data, error];
  };

  const getPatientDetailView = async ({ patientId }: any = {}) => {
    if (!patientId) {
      return;
    }
    const [data, error] = await props.controller.getPatientDetailView({
      patientId: patientId,
    });
    // console.log("Print List Patient Detail Data: ", data);
    return [data, error];
  };

  const getDiagnosisMedicalRecordDetail = async ({ emrId }: any = {}) => {
    if (!emrId) {
      return;
    }
    const [data, error] =
      await props.controller.getDiagnosisMedicalRecordDetail({
        emrId: emrId,
      });
    // console.log("Print List Diagnosis Detail Data: ", data);
    return [data, error];
  };

  // Split Date
  /* return [getDay, getMonth, getMonthName, getYear, getTime] และ [null,null] */
  const getSliceDate = (date: any) => {
    let getDay,
      getMonth,
      getMonthName,
      getYear,
      getTime = null;

    switch (date.length) {
      case 10:
        getDay = date.slice(0, 2);
        getMonth = date.slice(3, 5);
        getMonthName = moment(getMonth, "MM").locale("th").format("MMMM");
        getYear = date.slice(6, 10);
        // [0] = Day, [1] = Month, [2] = MonthName, [3] = Year
        // return [getDay, getMonth, getMonthName, getYear, getTime];
        break;
      case 16:
        getDay = date.slice(0, 2);
        getMonth = date.slice(3, 5);
        getMonthName = moment(getMonth, "MM").locale("th").format("MMMM");
        getYear = date.slice(6, 10);
        getTime = date.slice(12, 16);
        // [0] = Day, [1] = Month, [2] = MonthName, [3] = Year, [4] = Time
        // return [getDay, getMonth, getMonthName, getYear, getTime];
        break;
      case 17:
        getDay = date.slice(0, 2);
        getMonth = date.slice(3, 5);
        getMonthName = moment(getMonth, "MM").locale("th").format("MMMM");
        getYear = date.slice(6, 10);
        getTime = date.slice(12, 17);
        // [0] = Day, [1] = Month, [2] = MonthName, [3] = Year, [4] = Time
        break;
    }
    return [getDay, getMonth, getMonthName, getYear, getTime];
  };

  // Age Calculate (CurrentDate - BirthDate) // birth and current must be formatted by using formatDateBack() before.
  /* return [yearDuration, monthDuration, dayDuration] or [null, null, null]*/
  const getAge = (birthDate: string, currentDate: string) => {
    const diffDate = moment(currentDate).diff(birthDate);
    var yearDuration = moment.duration(diffDate).years();
    var monthDuration = moment.duration(diffDate).months();
    var dayDuration = moment.duration(diffDate).days();
    // [0] = Year, [1] = Month, [2] = Day
    return [yearDuration, monthDuration, dayDuration];
  };

  // Empty Obj Check
  const emptyObjectCheck = (obj: any) => {
    return (
      obj !== null && typeof obj === "object" && Object.keys(obj).length > 0
    );
  };

  const getPrintPDFMake = async ({ originalRow, print }: any = {}) => {
    // console.log("Print List PrintPDFMake (Func): ", originalRow);
    setFile(null);
    if (!originalRow) {
      return;
    }
    setIsLoading(true);
    let docPdfDef: any = { content: [] };
    let pdfData = null;

    // Print/Create Date (Global)
    const currentDate = moment().format("YYYY-MM-DD, HH:mm"); // 2024-02-29, 14:30
    const christYear = moment(currentDate).format("YYYY");
    const buddhistYear = (parseInt(christYear) + 543).toString();
    const formattedDate = moment(currentDate).format(
      "DD/MM/YYYY".replace("YYYY", buddhistYear)
    );
    const printedDateFormTh = moment(currentDate)
      .locale("th")
      .format("DD/MM/YYYY, HH:mm".replace("YYYY", buddhistYear)); // 29/02/2567, 14:30
    const printedDateFormEn = moment(currentDate).format("DD/MM/YYYY, HH:mm"); // 29/02/2024, 14:30

    // Format Address
    const formattedAddress = {
      haveAddress: emptyObjectCheck(props.patientInfo?.present_address),
      no:
        props.patientInfo?.present_address?.no !== null
          ? props.patientInfo?.present_address?.no
          : " ",
      town:
        props.patientInfo?.present_address?.town !== null
          ? props.patientInfo?.present_address?.town
          : " ",
      name:
        props.patientInfo?.present_address?.name !== null
          ? props.patientInfo?.present_address?.name
          : " ",
      street:
        props.patientInfo?.present_address?.street !== null
          ? props.patientInfo?.present_address?.street
          : " ",
      road:
        props.patientInfo?.present_address?.road !== null
          ? props.patientInfo?.present_address?.road
          : " ",
      city:
        props.patientInfo?.present_address?.city_label !== null
          ? props.patientInfo?.present_address?.city_label
          : " ",
      district:
        props.patientInfo?.present_address?.district_label !== null
          ? props.patientInfo?.present_address?.district_label
          : " ",
      province:
        props.patientInfo?.present_address?.province_label !== null
          ? props.patientInfo?.present_address?.province_label
          : " ",
      zipcode:
        props.patientInfo?.present_address?.zipcode !== null
          ? props.patientInfo?.present_address?.zipcode
          : " ",
    };

    // getSliceDate
    let createdFormDate = getSliceDate(printedDateFormTh);

    // patientInfo Variable
    let patientEmrId = props.patientData?.EMR?.emr_id;
    let patientDoctorName = props.patientInfo?.doctor_name;
    let patientEncounterNumber = props.patientInfo?.encounter_no;

    // RegEx
    let operationRegEx,
      icd9RegEx = null;

    // Patient Age (FormattedDate - BirthDate)
    let patientAge = getAge(
      beToAd(props.patientInfo?.birthdate),
      beToAd(formattedDate)
    );

    // Data Selected
    if (encounterId) {
      props.encounterId = encounterId;
      props.encounterNumber = encounterNumber;
      patientEncounterNumber = encounterNumber;
      patientEmrId = emrId;
    }
    if (patientInfo) {
      props.patientInfo = patientInfo;
      patientDoctorName = doctorName;
    }
    // console.log(
    //   "Print List Props Transform Data: ",
    //   props.patientInfo + " , " + patientEmrId + " , " + props.encounterId
    // );

    // Condition Form
    switch (originalRow?.code) {
      case "living_will":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : patientEncounterNumber
              ? patientEncounterNumber
              : "-",
            haveHeader: isEnableHeader,
            printDay: createdFormDate[0],
            printMonth: createdFormDate[2],
            printYear: createdFormDate[3],
            formAddress: formattedAddress,
            formatPatient: `${props.patientInfo?.first_name} ${props.patientInfo?.last_name}`,
            formatDoctor: patientDoctorName ? patientDoctorName.slice(3) : " ",
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
          },
        };

        docPdfDef = FormLivingWill(pdfData);

        if (isEnableHeader) {
          docPdfDef = AddHeaderPrintListTH(docPdfDef, {
            createdDate: printedDateFormTh,
            formName:
              "หนังสือแสดงเจตนาไม่ประสงค์จะรับบริการสาธารณสุขที่เป็นเพียง\nเพื่อยืดการตายในวาระสุดท้ายของชีวิต หรือเพื่อยุติการทรมานจากการเจ็บป่วย",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_pers_health":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : patientEncounterNumber
              ? patientEncounterNumber
              : "-",
            haveHeader: isEnableHeader,
            printDay: createdFormDate[0],
            printMonth: createdFormDate[2],
            printYear: createdFormDate[3],
            printTime: createdFormDate[4],
            formAddress: formattedAddress,
            formatPatient: `${props.patientInfo?.first_name} ${props.patientInfo?.last_name}`,
            formatDoctor: patientDoctorName ? patientDoctorName.slice(3) : " ",
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
          },
        };

        docPdfDef = FormConsentPersonalHealth(pdfData);

        if (isEnableHeader) {
          docPdfDef = AddHeaderPrintListTH(docPdfDef, {
            createdDate: printedDateFormTh,
            formName:
              "หนังสือแสดงความยินยอมให้เปิดเผยข้อมูลด้านสุขภาพ\nของบุคคลทางอิเล็กทรอนิกส์",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_drug_screen_th":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : patientEncounterNumber
              ? patientEncounterNumber
              : "-",
            haveHeader: isEnableHeader,
            formatPatient: `${props.patientInfo?.first_name} ${props.patientInfo?.last_name}`,
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
          },
        };

        docPdfDef = FormConsentDrugScreenTH(pdfData);

        if (isEnableHeader) {
          docPdfDef = AddHeaderPrintListTH(docPdfDef, {
            createdDate: printedDateFormTh,
            formName: "หนังสือแสดงการยินยอมตรวจหาสารเสพติด",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_drug_screen_en":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : patientEncounterNumber
              ? patientEncounterNumber
              : "-",
            haveHeader: isEnableHeader,
            formatPatient: `${props.patientInfo?.first_name} ${props.patientInfo?.last_name}`,
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
          },
        };

        docPdfDef = FormConsentDrugScreenEN(pdfData);

        if (isEnableHeader) {
          docPdfDef = AddHeaderPrintListEN(docPdfDef, {
            createdDate: printedDateFormEn,
            formName: "Consent Drug Screen Test",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_narcotic_use_th":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : patientEncounterNumber
              ? patientEncounterNumber
              : "-",
            haveHeader: isEnableHeader,
            formatPatient: `${props.patientInfo?.first_name} ${props.patientInfo?.last_name}`,
            formatDoctor: patientDoctorName ? patientDoctorName.slice(3) : " ",
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
          },
        };

        docPdfDef = FormConsentNarcoticUseTH(pdfData);

        if (isEnableHeader) {
          docPdfDef = AddHeaderPrintListTH(docPdfDef, {
            createdDate: printedDateFormTh,
            formName:
              "หนังสือแสดงความยินยอมรับการรักษาด้วยยารักษาอาการปวด\nชนิดเสพติดในอาการปวดเรื้อรังที่ไม่ใช่มะเร็ง",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_narcotic_use_en":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : patientEncounterNumber
              ? patientEncounterNumber
              : "-",
            haveHeader: isEnableHeader,
            formatPatient: `${props.patientInfo?.first_name} ${props.patientInfo?.last_name}`,
            formatDoctor: patientDoctorName ? patientDoctorName.slice(3) : " ",
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
          },
        };

        docPdfDef = FormConsentNarcoticUseEN(pdfData);

        if (isEnableHeader) {
          docPdfDef = AddHeaderPrintListEN(docPdfDef, {
            createdDate: printedDateFormEn,
            formName: "Consent for Narcotic Use In Chronic Non-Cancer Pain",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_hiv_test_th":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : patientEncounterNumber
              ? patientEncounterNumber
              : "-",
            haveHeader: isEnableHeader,
            formatPatient: `${props.patientInfo?.first_name} ${props.patientInfo?.last_name}`,
            formatDoctor: patientDoctorName ? patientDoctorName.slice(3) : " ",
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
          },
        };

        docPdfDef = FormConsentHIVTestTH(pdfData);

        if (isEnableHeader) {
          docPdfDef = AddHeaderPrintListTH(docPdfDef, {
            createdDate: printedDateFormTh,
            formName: "หนังสือแสดงความยินยอมการตรวจวินิจฉัยเพื่อหาเชื้อเอชไอวี",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_hiv_test_en":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : patientEncounterNumber
              ? patientEncounterNumber
              : "-",
            haveHeader: isEnableHeader,
            formatPatient: `${props.patientInfo?.first_name} ${props.patientInfo?.last_name}`,
            formatDoctor: patientDoctorName ? patientDoctorName.slice(3) : " ",
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
          },
        };

        docPdfDef = FormConsentHIVTestEN(pdfData);

        if (isEnableHeader) {
          docPdfDef = AddHeaderPrintListEN(docPdfDef, {
            createdDate: printedDateFormEn,
            formName: "Informed Consent to HIV Blood Test",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_blood_trans_th":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : patientEncounterNumber
              ? patientEncounterNumber
              : "-",
            haveHeader: isEnableHeader,
            formatPatient: `${props.patientInfo?.first_name} ${props.patientInfo?.last_name}`,
            formatDoctor: patientDoctorName ? patientDoctorName.slice(3) : " ",
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
          },
        };

        docPdfDef = FormConsentBloodTransfusionTH(pdfData);

        if (isEnableHeader) {
          docPdfDef = AddHeaderPrintListTH(docPdfDef, {
            createdDate: printedDateFormTh,
            formName:
              "หนังสือแสดงความยินยอมการรับโลหิต และ/หรือ ส่วนประกอบของโลหิต",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_blood_trans_en":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : patientEncounterNumber
              ? patientEncounterNumber
              : "-",
            haveHeader: isEnableHeader,
            formatPatient: `${props.patientInfo?.first_name} ${props.patientInfo?.last_name}`,
            formatDoctor: patientDoctorName ? patientDoctorName.slice(3) : " ",
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
          },
        };

        docPdfDef = FormConsentBloodTransfusionEN(pdfData);

        if (isEnableHeader) {
          docPdfDef = AddHeaderPrintListEN(docPdfDef, {
            createdDate: printedDateFormEn,
            formName: "Consent for Blood/Blood Product Transfusion",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_pre_sedat_th":
        let anesthesiaPreSedationThId,
          anesthesiaPreSedationThData,
          operatingOrderDetailPreSedationTh,
          operatingOrderDataPreSedationTh = null;

        if (patientEmrId) {
          const resultOperatingOrderPreSedationTh =
            await getOperatingOrderDetailByEmr({ emrId: patientEmrId });
          if (resultOperatingOrderPreSedationTh) {
            operatingOrderDataPreSedationTh =
              resultOperatingOrderPreSedationTh[0];
          }
        } else {
          operatingOrderDataPreSedationTh = null;
        }

        if (
          operatingOrderDataPreSedationTh &&
          operatingOrderDataPreSedationTh.anesthesia_teams[0]
        ) {
          anesthesiaPreSedationThId =
            operatingOrderDataPreSedationTh.anesthesia_teams[0]
              ?.anesthesiologist;
        } else {
          anesthesiaPreSedationThId = null;
        }

        // Anes Check
        if (anesthesiaPreSedationThId) {
          // Find Anesthesiologist Name
          const resultAnesthesiaPreSedationThData = await getDoctorDetail({
            doctorId: anesthesiaPreSedationThId,
          });

          if (resultAnesthesiaPreSedationThData) {
            anesthesiaPreSedationThData = resultAnesthesiaPreSedationThData[0];
          }

          operatingOrderDetailPreSedationTh = {
            operation_summary:
              operatingOrderDataPreSedationTh.pre_operation_summary,
            diagnosis_summary:
              operatingOrderDataPreSedationTh.preoperative_diagnosis_summary,
            anes_name: anesthesiaPreSedationThData.full_name,
          };

          operationRegEx =
            operatingOrderDetailPreSedationTh.operation_summary.match(
              /1\. ([^\n]+)/
            );
          icd9RegEx =
            operatingOrderDetailPreSedationTh.operation_summary.match(
              /2\. ([^\n]+)/
            );

          pdfData = {
            items: {
              ...props.patientInfo,
              patientEncounter: props.patientInfo?.encounter_no
                ? props.patientInfo?.encounter_no
                : patientEncounterNumber
                ? patientEncounterNumber
                : "-",
              haveHeader: isEnableHeader,
              operatingData: operatingOrderDetailPreSedationTh,
              formatPatient: `${props.patientInfo?.first_name} ${props.patientInfo?.last_name}`,
              formatDoctor: patientDoctorName
                ? patientDoctorName.slice(3)
                : " ",
              formatOperation: operationRegEx[1],
              formatIcd9: icd9RegEx[1],
              formatIcd10:
                operatingOrderDetailPreSedationTh.diagnosis_summary.slice(3),
              formatAnes: operatingOrderDetailPreSedationTh.anes_name.slice(3),
              ageYear: patientAge[0],
              ageMonth: patientAge[1],
              ageDay: patientAge[2],
            },
          };
        } else {
          operatingOrderDetailPreSedationTh = {
            operation_summary: operatingOrderDataPreSedationTh
              ? operatingOrderDataPreSedationTh.pre_operation_summary
                ? operatingOrderDataPreSedationTh.pre_operation_summary
                : " "
              : " ",
            diagnosis_summary: operatingOrderDataPreSedationTh
              ? operatingOrderDataPreSedationTh.preoperative_diagnosis_summary
                ? operatingOrderDataPreSedationTh.preoperative_diagnosis_summary
                : " "
              : " ",
            anes_name: " ",
          };

          if (operatingOrderDetailPreSedationTh.operation_summary) {
            operationRegEx =
              operatingOrderDetailPreSedationTh.operation_summary.match(
                /1\. ([^\n]+)/
              );
            icd9RegEx =
              operatingOrderDetailPreSedationTh.operation_summary.match(
                /2\. ([^\n]+)/
              );
          } else {
            operationRegEx = " ";
            icd9RegEx = " ";
          }
          // console.log("Print List operatingRegEx: ", operationRegEx);
          // console.log("Print List icd9RegEx: ", icd9RegEx);

          pdfData = {
            items: {
              ...props.patientInfo,
              patientEncounter: props.patientInfo?.encounter_no
                ? props.patientInfo?.encounter_no
                : patientEncounterNumber
                ? patientEncounterNumber
                : "-",
              haveHeader: isEnableHeader,
              operatingData: operatingOrderDetailPreSedationTh,
              formatPatient: `${props.patientInfo?.first_name} ${props.patientInfo?.last_name}`,
              formatDoctor: patientDoctorName
                ? patientDoctorName.slice(3)
                : " ",
              // formatOperation: operationRegEx[1]
              //   ? operationRegEx[1]
              //   : operationRegEx,
              formatOperation: operationRegEx ? operationRegEx : " ",
              // formatIcd9: icd9RegEx[1] ? icd9RegEx[1] : icd9RegEx ? icd9RegEx : " ",
              formatIcd9: icd9RegEx ? icd9RegEx : " ",
              formatIcd10: operatingOrderDetailPreSedationTh.diagnosis_summary
                ? operatingOrderDetailPreSedationTh.diagnosis_summary.slice(3)
                : " ",
              formatAnes: operatingOrderDetailPreSedationTh.anes_name
                ? operatingOrderDetailPreSedationTh.anes_name
                : " ",
              ageYear: patientAge[0],
              ageMonth: patientAge[1],
              ageDay: patientAge[2],
            },
          };
        }

        // console.log("Print List OperatingObj: ", operatingInfo);
        // console.log("Print List PDFData: ", pdfData);

        docPdfDef = FormConsentPreSedationTH(pdfData);

        if (isEnableHeader) {
          docPdfDef = AddHeaderPrintListTH(docPdfDef, {
            createdDate: printedDateFormTh,
            formName:
              "หนังสือยินยอมก่อนการให้ยาระงับปวด\nหรือยาระงับความรู้สึกตัวการทำหัตถการ/การผ่าตัด",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_pre_sedat_en":
        let anesthesiaPreSedationEnId,
          anesthesiaPreSedationEnData,
          operatingOrderDetailPreSedationEn,
          operatingOrderDataPreSedationEn = null;

        if (patientEmrId) {
          const resultOperatingOrderPreSedationEn =
            await getOperatingOrderDetailByEmr({ emrId: patientEmrId });
          if (resultOperatingOrderPreSedationEn) {
            operatingOrderDataPreSedationEn =
              resultOperatingOrderPreSedationEn[0];
            // console.log(
            //   "Print List Operating Order Data (Pre Sedation EN): ",
            //   operatingOrderDataPreSedationEn
            // );
          }
        } else {
          operatingOrderDataPreSedationEn = null;
        }

        if (
          operatingOrderDataPreSedationEn &&
          operatingOrderDataPreSedationEn.anesthesia_teams[0]
        ) {
          anesthesiaPreSedationEnId =
            operatingOrderDataPreSedationEn.anesthesia_teams[0]
              ?.anesthesiologist;
        } else {
          anesthesiaPreSedationEnId = null;
        }

        // Anes Check
        if (anesthesiaPreSedationEnId) {
          // Find Anesthesiologist Name
          const resultAnesthesiaPreSedationEnData = await getDoctorDetail({
            doctorId: anesthesiaPreSedationEnId,
          });

          if (resultAnesthesiaPreSedationEnData) {
            anesthesiaPreSedationEnData = resultAnesthesiaPreSedationEnData[0];
          }

          operatingOrderDetailPreSedationEn = {
            operation_summary:
              operatingOrderDataPreSedationEn.pre_operation_summary,
            diagnosis_summary:
              operatingOrderDataPreSedationEn.preoperative_diagnosis_summary,
            anes_name: anesthesiaPreSedationEnData.full_name,
          };

          operationRegEx =
            operatingOrderDetailPreSedationEn.operation_summary.match(
              /1\. ([^\n]+)/
            );
          icd9RegEx =
            operatingOrderDetailPreSedationEn.operation_summary.match(
              /2\. ([^\n]+)/
            );

          pdfData = {
            items: {
              ...props.patientInfo,
              patientEncounter: props.patientInfo?.encounter_no
                ? props.patientInfo?.encounter_no
                : patientEncounterNumber
                ? patientEncounterNumber
                : "-",
              haveHeader: isEnableHeader,
              operatingData: operatingOrderDetailPreSedationEn,
              formatPatient: `${props.patientInfo?.first_name} ${props.patientInfo?.last_name}`,
              formatDoctor: patientDoctorName
                ? patientDoctorName.slice(3)
                : " ",
              formatOperation: operationRegEx[1],
              formatIcd9: icd9RegEx[1],
              formatIcd10:
                operatingOrderDetailPreSedationEn.diagnosis_summary.slice(3),
              formatAnes: operatingOrderDetailPreSedationEn.anes_name.slice(3),
              ageYear: patientAge[0],
              ageMonth: patientAge[1],
              ageDay: patientAge[2],
            },
          };
        } else {
          operatingOrderDetailPreSedationEn = {
            operation_summary: operatingOrderDataPreSedationEn
              ? operatingOrderDataPreSedationEn.pre_operation_summary
                ? operatingOrderDataPreSedationEn.pre_operation_summary
                : " "
              : " ",
            diagnosis_summary: operatingOrderDataPreSedationEn
              ? operatingOrderDataPreSedationEn.preoperative_diagnosis_summary
                ? operatingOrderDataPreSedationEn.preoperative_diagnosis_summary
                : " "
              : " ",
            anes_name: " ",
          };

          if (operatingOrderDetailPreSedationEn.operation_summary) {
            operationRegEx =
              operatingOrderDetailPreSedationEn.operation_summary.match(
                /1\. ([^\n]+)/
              );
            icd9RegEx =
              operatingOrderDetailPreSedationEn.operation_summary.match(
                /2\. ([^\n]+)/
              );
          } else {
            operationRegEx = " ";
            icd9RegEx = " ";
          }

          pdfData = {
            items: {
              ...props.patientInfo,
              patientEncounter: props.patientInfo?.encounter_no
                ? props.patientInfo?.encounter_no
                : patientEncounterNumber
                ? patientEncounterNumber
                : "-",
              haveHeader: isEnableHeader,
              operatingData: operatingOrderDetailPreSedationEn,
              formatPatient: `${props.patientInfo?.first_name} ${props.patientInfo?.last_name}`,
              formatDoctor: patientDoctorName
                ? patientDoctorName.slice(3)
                : " ",
              // formatOperation: operationRegEx[1]
              //   ? operationRegEx[1]
              //   : operationRegEx ? operationRegEx : " ",
              formatOperation: operationRegEx ? operationRegEx : " ",
              // formatIcd9: icd9RegEx[1] ? icd9RegEx[1] : icd9RegEx ? icd9RegEx : " ",
              formatIcd9: icd9RegEx ? icd9RegEx : " ",
              formatIcd10: operatingOrderDetailPreSedationEn.diagnosis_summary
                ? operatingOrderDetailPreSedationEn.diagnosis_summary.slice(3)
                : " ",
              formatAnes: operatingOrderDetailPreSedationEn.anes_name
                ? operatingOrderDetailPreSedationEn.anes_name
                : " ",
              ageYear: patientAge[0],
              ageMonth: patientAge[1],
              ageDay: patientAge[2],
            },
          };
        }

        // console.log("Print List OperatingObj: ", operatingInfo);
        // console.log("Print List PDFData: ", pdfData);

        docPdfDef = FormConsentPreSedationEN(pdfData);

        if (isEnableHeader) {
          docPdfDef = AddHeaderPrintListEN(docPdfDef, {
            createdDate: printedDateFormEn,
            formName:
              "Pre Procedural Sedation and Pre Anesthesia\nInformed Consent",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_operating_th":
        let consOperatingOrderDetailTh,
          consOperatingOrderDataTh = null;

        if (patientEmrId) {
          const resultConsOperatingOrderDetailTh =
            await getOperatingOrderDetailByEmr({ emrId: patientEmrId });

          if (resultConsOperatingOrderDetailTh) {
            consOperatingOrderDataTh = resultConsOperatingOrderDetailTh[0];
          }

          consOperatingOrderDetailTh = {
            operation_summary: consOperatingOrderDataTh.pre_operation_summary,
            diagnosis_summary:
              consOperatingOrderDataTh.preoperative_diagnosis_summary,
          };
        } else {
          consOperatingOrderDetailTh = {
            operation_summary: null,
            diagnosis_summary: null,
          };
        }

        if (consOperatingOrderDetailTh.operation_summary) {
          operationRegEx =
            consOperatingOrderDetailTh.operation_summary.match(/1\. ([^\n]+)/);
          icd9RegEx =
            consOperatingOrderDetailTh.operation_summary.match(/2\. ([^\n]+)/);
        } else {
          operationRegEx = " ";
          icd9RegEx = " ";
        }

        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : patientEncounterNumber
              ? patientEncounterNumber
              : "-",
            haveHeader: isEnableHeader,
            operatingData: consOperatingOrderDetailTh,
            formatPatient: `${props.patientInfo?.first_name} ${props.patientInfo?.last_name}`,
            formatDoctor: patientDoctorName ? patientDoctorName.slice(3) : " ",
            formatOperation: operationRegEx[1]
              ? operationRegEx[1] === "" || operationRegEx[1] == null
                ? " "
                : operationRegEx[1]
              : operationRegEx,
            formatIcd9: icd9RegEx[1]
              ? icd9RegEx[1].replace(/\(\)/g, "").trim() === "" ||
                icd9RegEx[1].replace(/\(\)/g, "").trim() == null
                ? " "
                : icd9RegEx[1].replace(/\(\)/g, "").trim()
              : icd9RegEx,
            formatIcd10: consOperatingOrderDetailTh.diagnosis_summary
              ? consOperatingOrderDetailTh.diagnosis_summary
                  .slice(3)
                  .replace(/\(\)/g, "")
                  .trim() === "" ||
                consOperatingOrderDetailTh.diagnosis_summary
                  .slice(3)
                  .replace(/\(\)/g, "")
                  .trim() == null
                ? " "
                : consOperatingOrderDetailTh.diagnosis_summary
                    .slice(3)
                    .replace(/\(\)/g, "")
                    .trim()
              : " ",
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
          },
        };
        // console.log("Print List pdfData: ", pdfData);

        docPdfDef = FormConsentOperatingTH(pdfData);

        if (isEnableHeader) {
          docPdfDef = AddHeaderPrintListTH(docPdfDef, {
            createdDate: printedDateFormTh,
            formName: "หนังสือแสดงความยินยอมการผ่าตัดหรือการทำหัตถการพิเศษ",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_treat_proc":
        let diagnosisTreatmentProcedureInfo,
          diagnosisTreatmentProcedureData = null;

        if (patientEmrId) {
          // Call API Diagnosis
          const resultDiagnosisTreatmentProcedure =
            await getDiagnosisMedicalRecordDetail({ emrId: patientEmrId });

          if (resultDiagnosisTreatmentProcedure) {
            diagnosisTreatmentProcedureData =
              resultDiagnosisTreatmentProcedure[0];
          }
        } else {
          diagnosisTreatmentProcedureData = null;
        }

        if (diagnosisTreatmentProcedureData) {
          diagnosisTreatmentProcedureInfo = {
            principal_diagnosis:
              diagnosisTreatmentProcedureData.principal_diagnosis?.[0],
            icd10_code:
              diagnosisTreatmentProcedureData.principal_diagnosis?.[0]
                ?.icd_code,
            icd10_term:
              diagnosisTreatmentProcedureData.principal_diagnosis?.[0]
                ?.icd_term,
            icd10_med_term:
              diagnosisTreatmentProcedureData.principal_diagnosis?.[0]
                ?.icd10_med_term,
          };

          pdfData = {
            items: {
              ...props.patientInfo,
              patientEncounter: props.patientInfo?.encounter_no
                ? props.patientInfo?.encounter_no
                : patientEncounterNumber
                ? patientEncounterNumber
                : "-",
              haveHeader: isEnableHeader,
              diagnosisData: diagnosisTreatmentProcedureInfo,
              formatIcd10Code:
                diagnosisTreatmentProcedureInfo.icd10_code === "" ||
                diagnosisTreatmentProcedureInfo.icd10_code == null
                  ? " "
                  : diagnosisTreatmentProcedureInfo.icd10_code,
              formatIcd10Term:
                diagnosisTreatmentProcedureInfo.icd10_term === "" ||
                diagnosisTreatmentProcedureInfo.icd10_term == null
                  ? " "
                  : diagnosisTreatmentProcedureInfo.icd10_term,
              printDay: createdFormDate[0],
              printMonth: createdFormDate[2],
              printYear: createdFormDate[3],
              printTime: createdFormDate[4],
              formatPatient: `${props.patientInfo?.first_name} ${props.patientInfo?.last_name}`,
              formatDoctor: patientDoctorName
                ? patientDoctorName.slice(3)
                : " ",
              ageYear: patientAge[0],
              ageMonth: patientAge[1],
              ageDay: patientAge[2],
            },
          };
        } else {
          pdfData = {
            items: {
              ...props.patientInfo,
              patientEncounter: props.patientInfo?.encounter_no
                ? props.patientInfo?.encounter_no
                : patientEncounterNumber
                ? patientEncounterNumber
                : "-",
              haveHeader: isEnableHeader,
              diagnosisData: " ",
              formatIcd10Code: " ",
              formatIcd10Term: " ",
              printDay: createdFormDate[0],
              printMonth: createdFormDate[2],
              printYear: createdFormDate[3],
              printTime: createdFormDate[4],
              formatPatient: `${props.patientInfo?.first_name} ${props.patientInfo?.last_name}`,
              formatDoctor: patientDoctorName
                ? patientDoctorName.slice(3)
                : " ",
              ageYear: patientAge[0],
              ageMonth: patientAge[1],
              ageDay: patientAge[2],
            },
          };
        }
        // console.log("Print List pdfData: ", pdfData);

        docPdfDef = FormConsentTreatmentProcedure(pdfData);

        if (isEnableHeader) {
          docPdfDef = AddHeaderPrintListTH(docPdfDef, {
            createdDate: printedDateFormTh,
            formName:
              "หนังสือรับทราบ และยินยอมรับการรักษา ฟื้นฟู หรือทำหัตถการ",
            patientInfo: pdfData.items,
          });
        }
        break;
      default:
        // console.error("Invalid code form!!!");
        clear();
        break;
    }

    console.log("Print List PDF Data: ", pdfData);
    // console.log("Print List Doc Def: ", docPdfDef);

    if (isMounted.current) {
      setIsLoading(false);
      if (docPdfDef && print === false) {
        // console.log("Print List isMounted Preview: ", docPdfDef);
        handleGetPdfMakeResult(docPdfDef);
      } else if (docPdfDef && print === true) {
        // console.log("Print List isMounted Print: ", docPdfDef);
        // Need Print on current Page *****
        (await getPdfMake(true)).createPdf(docPdfDef).print();
        setOpenModInfoTask(false);
        setModInfoText("พิมพ์รายการสำเร็จ");
        setModInfoTitle("สำเร็จ");
        setModInfoTitleColor("teal");
        setOpenModInfo(true);
        clear();
      } else {
        setFile("about:blank" as any);
        console.error("PDF Data doesn't Exist");
      }
    }
  };

  const handleGetPdfMakeResult = async (data: any) => {
    // console.log("Print List GetPDF Result (Func): ", data);
    if (data) {
      setOpenModInfo(false);
      const pdfDocGen = (await getPdfMake(true)).createPdf(data);
      const pdfB64Data = await new Promise<string>((resolve, reject) => {
        pdfDocGen.getBase64((data) => resolve(data));
      });
      // console.log("Print List PDF Gen Base64: ", pdfB64Data);
      // setFile((await getPdfMake()).createPdf(data).open() as any)
      setFile(("data:application/pdf;base64," + pdfB64Data) as any);
    } else {
      console.error("Cannot Open PDF File!!");
    }
  };

  const handleGetDocumentResult = ({ data, preview }: any = {}) => {
    if (preview && data.success && data.pdf_b64data.length > 0) {
      setOpenModInfoTask(false);
      return setFile(
        ("data:application/pdf;base64," + data.pdf_b64data) as any
      );
    } else if (!preview && data.success) {
      setOpenModInfoTask(false);
      setModInfoText("พิมพ์รายการสำเร็จ");
      setModInfoTitle("สำเร็จ");
      setModInfoTitleColor("teal");
      setOpenModInfo(true);
      clear();
    } else if (data.warning) {
      setFile("about:blank" as any);
      alert(data.warning);
    } else if (data.task_id) {
      setModInfoTaskText(
        (
          <div style={{ textAlign: "left", padding: 0 }}>
            <div>กรุณารอสักครู่ </div>For IT: task_id = {data.task_id}
          </div>
        ) as any
      );
      setOpenModInfoTask(true);
      setGetPrinted(false);
      setTaskTracking(data.task_id);
    } else {
      setOpenModInfoTask(false);
      handlePrintAPIFail({ error: data.error, preview });
    }
  };

  const handleCancelTask = () => {
    getTaskTracking({ action: "CANCEL" });
  };

  const handleApproveConfirm = () => {
    setModConfirmError(null);
    if (!(reasonRef as any).current.getValue()) {
      return setModConfirmError("กรุณาระบุเหตุผล" as any);
    }
    setOpenModConfirm(false);
    getPrintAPI({ originalRow: selectedRow, preview: false, confirm: true });
  };

  // Print
  const handlePrintReport = (originalRow: any) => {
    // console.log("Print List Print Button: ", originalRow);
    if (originalRow?.print_api !== "/pdfmake") {
      getPrintAPI({ originalRow: selectedRow, preview: false, confirm: false });
    } else {
      getPrintPDFMake({ originalRow: selectedRow, print: true });
    }
  };

  // Print List Data
  console.log("Print List Props: ", props);
  // console.log("Print List Controller: ", props.controller);

  return (
    <CardLayout
      titleText="พิมพ์เอกสาร"
      closeable={props.closeable}
      toggleable={props.toggleable}
      onClose={props.onClose}
      loading={isLoading}
    >
      <ModConfirm
        openModal={openModConfirm}
        className={"fixNotMiddleModal"}
        onDeny={() => setOpenModConfirm(false)}
        titleName={modConfirmTitle}
        onApprove={handleApproveConfirm}
        content={
          <Form>
            <ErrorMessage error={modConfirmError} />
            <Form.Group>
              <Form.Field width={16}>
                <label>
                  ระบุเหตุผล
                  <label style={{ color: "red" }}>*</label>
                </label>
                <ComboBox ref={reasonRef as any} options={reasonChoices} />
              </Form.Field>
            </Form.Group>
          </Form>
        }
      />
      <ModInfo
        className="fixNotMiddleModal"
        open={openModInfoTask}
        alertText={modInfoTaskText}
        titleName="กำลังเตรียมเอกสาร"
        btnText="ยกเลิก"
        titleColor="teal"
        buttonColor="red"
        textAlign={"left"}
        onApprove={handleCancelTask}
      />
      <ModInfo
        className="fixNotMiddleModal"
        open={openModInfo}
        titleColor={modInfoTitleColor}
        titleName={modInfoTitle}
        alertText={
          typeof modInfoText !== "string" ? (
            <ErrorMessage error={modInfoText} />
          ) : (
            modInfoText
          )
        }
        closeOnDimmerClick={true}
        onClose={() => setOpenModInfo(false)}
        onApprove={() => setOpenModInfo(false)}
      />
      <Modal
        open={openModEncounterList}
        closeOnDimmerClick={true}
        onClose={() => setOpenModEncounterList(false)}
        content={
          <CardEncounterList
            patientId={props.patientId}
            patientHn={props.patientInfo?.hn}
            controller={props.controller}
            onSelect={(selectedRow: any) => {
              (sbxEncounterRef as any).current.setText(selectedRow.number_date);
              (sbxEncounterRef as any).current.setId(selectedRow.id);
              handleSelectOption({ item: selectedRow });
              setOpenModEncounterList(false);
            }}
          />
        }
      />
      <PatientIdentification patientInfo={patientInfo} />
      <Form>
        <Form.Group inline>
          <Form.Field>
            <label>Encounter Number :</label>
          </Form.Field>
          <Form.Field>
            <SearchBox
              ref={sbxEncounterRef}
              testid="sbxEncounter"
              linkIcon
              onIconClick={() => setOpenModEncounterList(true)}
              minCharacters={2}
              textField="number_date"
              onGetSearchOptions={handleSearchEncounter}
              onSelectOption={handleSelectOption}
              onClear={() => setEncounterId(props.encounterId)}
              defaultOptions={encounterList}
              defaultId={
                props.encounterId
                  ? props.encounterId
                  : props.patientInfo?.encounter_id
              }
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <br />
      <Grid>
        <Grid.Column width={4}>
          <Input
            fluid
            icon="search"
            placeholder="ค้นหาชื่อเอกสาร"
            onChange={handleSearchDocumentType}
            loading={isSearchDocumentLoading}
          />
          <br />
          <PureReactTable
            style={styles.docTable}
            showPagination={false}
            testid="grdRequest"
            data={documentType}
            onSelect={handleSelectDocType}
            selectedRow={selectedRow}
            selectedClassName="blueSelectedRow"
            columns={[
              {
                Header: "ชื่อเอกสาร",
                accessor: "name",
              },
            ]}
          />
        </Grid.Column>
        <Grid.Column width={12}>
          <Segment style={{ height: "500px" }}>
            <FrameChrome file={file} visible />
          </Segment>
        </Grid.Column>
      </Grid>
      <br />
      <Form>
        <Form.Group inline className="rightAlign">
          <Form.Field width={3}>
            <Button
              color="blue"
              content="พิมพ์"
              fluid
              // onClick={() => {
              //   getPrintAPI({
              //     originalRow: selectedRow,
              //     preview: false,
              //     confirm: false,
              //   });
              // }}
              onClick={() => {
                handlePrintReport(selectedRow);
              }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </CardLayout>
  );
});

const TASK_TRACKING_STATUS = {
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  CANCELED: "CANCELED",
  IN_PROGRESS: "IN_PROGRESS",
};

const styles = {
  docTable: {
    height: "500px",
  },
};

CardPrintList.propTypes = {
  toggleable: PropTypes.bool,
  closeable: PropTypes.bool,
  patientInfo: PropTypes.object,
  onClose: PropTypes.func,
  patientData: PropTypes.object,
  controller: PropTypes.object,
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  encounterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  encounterNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  operatingOrderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CardPrintList.defaultProps = {
  toggleable: false,
  closeable: true,
  patientInfo: {},
  onClose: () => {},
  patientData: {},
  controller: {},
  patientId: null,
  encounterId: null,
  encounterNumber: null,
  operatingOrderId: null,
};

CardPrintList.displayName = "CardPrintList";
export default React.memo(CardPrintList);
