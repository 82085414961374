import { useEffect, useRef, useState } from "react";
import { Button, Checkbox, Icon, Modal } from "semantic-ui-react";
import XDTestFormAddProblem from "./XDTestFormAddProblem";
import StepEditor from "./StepEditor";
import { RedmineStatus } from "ismor-lib/utils/Constant";
import XDCaseFormAddorEditProblem from "./XDCaseFormAddorEditProblem";

export default function XDTest(props: any) {
  const [mode, setMode] = useState("List");
  const [xdId, setXdId] = useState<string|null>(null);
  const [xdTestList, setXdTestList] = useState<any[]>([]);
  const [selectedTestId, setSelectedTestId] = useState(null);
  const [closeStatus, setCloseStatus] = useState({closing: false, message: ""});
  const [addingTest, setAddingTest] = useState(false);
  const [addingProblem, setAddingProblem] = useState(false);
  const [problemToEdit, setProblemToEdit] = useState<any>(null);
  const [problemMode, setProblemMode] = useState("nothing");
  const [selectedNode, setSelectedNode] = useState<string|null>(null);
  const [selectedStep, setSelectedStep] = useState<string|null>(null);
  const [showNodeDetail, setShowNodeDetail] = useState(false);
  const [loc, setLoc] = useState<any>(null);

  const loadingPriority = useRef(false);
  
  useEffect(() => {
    if (Object.keys(props.priorityDict).length === 0 && !loadingPriority.current) {
      loadingPriority.current = true;
      props.onEvent({message: "GetPriorityDict", params: {}});
    }
  }, []);

  useEffect(() => {
    if (props.xdId && props.xdId !== xdId) {
      setXdTestList([]);
      setXdId(props.xdId);
      props.onEvent({message: "GetXdTestList", params: {done: false}});
    }
  }, [props.xdId]);

  useEffect(() => {
    setXdTestList(props.xdTestList);
  }, [props.xdTestList]);

  let selectedXdTest = props.xdTestList?.find((xdTest: any) => xdTest.id === selectedTestId);
  let selectedXdTestCase = selectedXdTest?.xdCase;
  let currentXdCase = 
    props.xdCases?.find((xdCase: any) => xdCase?.id === selectedXdTestCase?.id);

  return(
    <div style={{height: "100%"}}>
      {mode === "List" ?
      <div style={{height: "100%"}}>
        <div
          onClick={() => setAddingTest(!addingTest)}>
          {`${addingTest ? "<< Back" : "+ Add Test"}`}
        </div>
        <div style={{display: "flex", height: "100%"}}>
          {addingTest && 
          <div style={{width: "200px", borderRight: "solid #cccccc 1px", height: "100%"}}>
            {props.xdCases
              .sort((a: any, b: any) => a?.caseIndex < b?.caseIndex ? -1 : 1)
              .map((xdCase: any, index: number) => (
                <div 
                  key={index}
                  style={{
                    padding: "2px", display: "flex",
                    background: "lightgreen",
                    borderBottom: "solid #cccccc 1px",
                  }}
                  onClick={() => {
                    props.onEvent({message: "AddXdTest", params: { xdCaseId: xdCase.id }});
                  }}>
                  <div>{xdCase.name}</div>
                  <div style={{flex: 1}}></div>
                  <Icon name="arrow right" />
                </div>
            ))}
          </div>}
          <div style={{flex: 1}}>
            {xdTestList
            .filter((xdTest: any) => !xdTest?.done)
            .sort((a: any, b: any) => a.createdAt < b.createdAt ? 1 : -1)
            .map((xdTest: any, index: number) => (
              <div 
                key={index}   
                style={{display: "flex", width: "100%", padding: "2px", borderBottom: "solid #cccccc 1px"}}
                onClick={() => {
                  setMode("Edit");
                  setAddingTest(false);
                  setSelectedTestId(xdTest.id);
                }}>
                <div style={{flex: 1}}>{xdTest?.xdCase?.name}</div>
                <div style={{flex: 1}}>{xdTest?.createdBy}</div>
                <div style={{flex: 1}}>{xdTest?.createdAt}</div>
                <div style={{flex: 1}}>{`${xdTest?.done ? "done" : "ongoing"}`}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      : 
      // Mode Edit
      <div style={{height: "100%"}}>
        <div style={{display: "flex", borderBottom: "solid #cccccc 1px", alignItems: "center"}}>
          <div onClick={() => setMode("List")}>&lt;&lt;&nbsp;Back</div>
          <div style={{flex: 1}}></div>
          <div>{`${selectedXdTestCase?.name} - ${selectedXdTest?.createdBy} - ${selectedXdTest?.createdAt}`}</div>
          <div style={{flex: 1}}></div>
          <Button 
            disabled={!props.duckCon || selectedXdTest?.done}
            size="mini"
            color={`${props.duckCon ? "instagram" : "red"}`}
            onClick={() => {
              props.onEvent({message: "CloseXdTest", params: { testId: selectedTestId, setCloseStatus }});
              setCloseStatus({closing: true, message: ""});
              setMode("List");
              setSelectedTestId(null);
            }}>
            {`${props.pythonLoaded ? "Close Test" : "Loading Lib..."}`}
          </Button>
        </div>
        { 
        // Has test case, not done, length > 0
        selectedXdTestCase && !selectedXdTest?.done && selectedXdTestCase?.sequence?.length > 0  ?
        selectedXdTestCase.sequence.map((testSeq: any, testSeqIndex: number) => { 
          let problemNodeList = 
            Array.from(
              new Set(testSeq.problems.map(
                (problemItem: any) => problemItem.nodeId)));
          let nodeList = 
            Object.keys(props.xddata.nodeMaster)
              .filter((nodeId: string) => problemNodeList.includes(nodeId))
          return (
            <div 
              key={testSeqIndex} 
              style={{padding: "2px", border: "solid grey 1px", borderRadius: "4px", margin: "6px"}}>
              <div style={{fontWeight: "bold"}}>{testSeq.description}</div>
              <div style={{paddingLeft: "20px"}}>
                {nodeList.map((nodeId: string, nodeIndex: number) => (
                  <div 
                    key={nodeIndex} 
                    style={{display: "flex", borderBottom: "solid #cccccc 1px"}}>
                    <div style={{flex: 1}}>
                      <div>{props.xddata.nodeMaster[nodeId].name}</div>
                      <div style={{display: "flex", alignItems: "center"}}>
                        <div style={{marginRight: "2px"}}>
                          {`[${props.xddata.steps?.[testSeq.stepId]?.nodeDetail?.[nodeId]?.detail}]`}
                        </div>
                        <Icon 
                          name="question" 
                          color="blue"
                          size="tiny"
                          bordered fitted
                          inverted
                          onClick={() => {
                            setLoc({
                              id: nodeId,
                              step: props.xddata.steps[testSeq.stepId],
                              node: props.xddata.nodeMaster[nodeId],
                            });
                            setShowNodeDetail(true);
                          }}
                        />
                      </div>
                    </div>
                    <div style={{flex: 5}}>
                      {/* Original problems */}
                      {testSeq.problems
                      .filter((problemItem: any) => (problemItem.nodeId  === nodeId))
                      .map((problemItem: any, problemIndex: number) => {
                        let problemDetail = props.xdProblemDict?.[problemItem.id];
                        return ( 
                          <div 
                            key={problemIndex} 
                            style={{display: "flex", borderBottom: "solid #cccccc 1px"}}>
                            <div style={{flex: 2}}>
                              <div>{problemItem.description}</div>
                              <div>{problemItem?.tester && `(${problemItem?.tester})`}</div>
                            </div>
                            <div style={{flex: 3}}>
                              {problemDetail.issues.map((issueId: number, issueIndex: number) => { 
                                let issueStatus = RedmineStatus?.[props.issueLatestUpdates?.[issueId]?.status_id];
                                return(
                                <div 
                                  key={issueIndex} 
                                  style={{display: "flex", borderBottom: "solid #cccccc 1px"}}>
                                  <div 
                                    style={{ flex: 1 }}>
                                    <a 
                                      href={`https://redmine.thevcgroup.com/issues/${issueId}`} 
                                      target="_blank"  
                                      >
                                      {`${issueId}`}
                                    </a>
                                    <span
                                      style={{
                                        color: issueStatus === "Closed" ? "green" : "black"
                                      }}>
                                      {` (${issueStatus})`}</span>
                                  </div>
                                  <div style={{flex: 1}}>
                                    <Checkbox label="Pass" 
                                      checked={problemItem.issues_passed.includes(issueId)}
                                      onClick={() => {
                                        if (problemItem.issues_passed.includes(issueId)) return;
                                        props.onEvent({message: "UpdateXdTest", params: {
                                          testId: selectedTestId,
                                          seqIndex: testSeqIndex,
                                          problemItemId: problemItem.id,
                                          changeType: "issue_passed",
                                          issueId,
                                        }});
                                      }}
                                      
                                    />
                                  </div>
                                  <div style={{flex: 1}}>
                                    <Checkbox label="Fail" 
                                      checked={problemItem.issues_failed.includes(issueId)}
                                      onClick={() => {
                                        if (problemItem.issues_failed.includes(issueId)) return;
                                        props.onEvent({message: "UpdateXdTest", params: {
                                          testId: selectedTestId,
                                          seqIndex: testSeqIndex,
                                          problemItemId: problemItem.id,
                                          changeType: "issue_failed",
                                          issueId,
                                        }});
                                      }}
                                    />
                                  </div>
                                  <div style={{flex: 1}}>
                                    <Checkbox label="Not tested" 
                                      checked={problemItem.issues_untested.includes(issueId)}
                                      onClick={() => {
                                        if (problemItem.issues_untested.includes(issueId)) return;
                                        props.onEvent({message: "UpdateXdTest", params: {
                                          testId: selectedTestId,
                                          seqIndex: testSeqIndex,
                                          problemItemId: problemItem.id,
                                          changeType: "issue_untested",
                                          issueId,
                                        }});
                                      }}
                                    />
                                  </div>
                                </div>
                                )
                              })}
                            </div>
                          </div>
                        )
                      })}
                      {/* Problems added during test */}
                      {Object.values(props.xdProblemDict)
                      .filter((xdProblem: any) => (
                        xdProblem.testId === selectedTestId 
                        && xdProblem.testSeqIndex === testSeqIndex
                        && xdProblem.nodeId === nodeId
                      ))
                      .map((xdProblem: any, xdProblemIndex) => {
                        let candidateSeq = currentXdCase.sequence
                          .map((seq: any, seqIndex: number) => ({...seq, seqIndex}))
                          .find((seq: any) => (
                            seq.problems.map((seqProblem: any) => seqProblem?.id).includes(xdProblem?.id)
                          ));
                        let selectedSeqIndex = candidateSeq?.seqIndex;
                        if (problemMode === "edit" && problemToEdit?.id === xdProblem?.id 
                            && Number.isInteger(selectedSeqIndex)
                        ) {
                          return (
                            <XDCaseFormAddorEditProblem
                              key={xdProblemIndex} 
                              onEvent={props.onEvent}
                              xdCaseId={xdProblem?.caseId}
                              problemMode={problemMode}
                              setProblemMode={(mode: any) => setProblemMode(mode)}
                              selectedSeqIndex={selectedSeqIndex}
                              selectedNode={selectedNode}
                              setSelectedNode={setSelectedNode}
                              selectedProblemId={xdProblem?.id}
                              setSelectedProblemId={() => {}}
                              problemItem={xdProblem}
                              issueLatestUpdates={props.issueLatestUpdates}
                              xdProblemDict={props.xdProblemDict}
                              issueTestResult={props.issueTestResult}
                              onRequestDelete={() => {}}
                            />) 
                        } else {
                          return (
                            <div 
                              key={xdProblemIndex} 
                              style={{backgroundColor:"lightblue"}}
                              onClick={(e: any) => {
                                setProblemMode("edit");
                                setSelectedNode(nodeId);
                                setProblemToEdit(xdProblem);
                              }}
                              >
                              {xdProblem.description}
                            </div>
                          )
                        }                        
                      })}
                      {addingProblem && selectedNode === nodeId && testSeq.stepId === selectedStep ?
                      <XDTestFormAddProblem
                        onEvent={props.onEvent}
                        selectedTestId={selectedTestId}
                        testSeqIndex={testSeqIndex}
                        setAddingProblem={setAddingProblem}
                        selectedNode={selectedNode}
                        setSelectedNode={setSelectedNode}
                        selectedStep={selectedStep}
                        setSelectedStep={setSelectedStep}
                        currentXdCase={currentXdCase}
                        xddata={props.xddata}
                        xdProblemDict={props.xdProblemDict}
                      />
                      : currentXdCase?.sequence
                        ?.filter((caseSeq: any) => caseSeq.stepId === testSeq.stepId)
                        ?.length > 0 ?
                      <div 
                        style={{width: "100%"}}
                        onClick={() => {
                          setAddingProblem(true);
                          setSelectedNode(nodeId);
                          setSelectedStep(testSeq.stepId);
                        }}>
                        + Add Problem
                      </div>
                      :
                      <></>
                      // <div
                      //   onClick={() => {
                      //     console.log(currentXdCase?.sequence
                      //       ?.filter((caseSeq: any) => caseSeq.stepId === testSeq.stepId));
                      //   }}>
                      //   xxx
                      // </div>
                      }
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )
        })
        :
        <div 
          style={{
            height: "100%", width: "100%", display: "flex", 
            justifyContent: "center", alignItems: "center"
          }}>
          {`${!selectedXdTestCase ? "ไม่พบ Test case นี้" 
              : selectedXdTest?.done ? "Test นี้ถูก Close แล้ว" 
              : "Test นี้ไม่มีข้อมูล sequence"}`}
        </div>}
      </div>}
      {/* End Mode Edit */}
      <Modal
        size="mini"
        open={closeStatus?.closing}
        closeIcon={false}
        closeOnDimmerClick={false}>
        <div style={{padding: "10px"}}>
          <div>Closing test. Please wait</div>
          <div>{closeStatus?.message}</div>
        </div>
      </Modal>
      <Modal
        open={showNodeDetail}
        closeIcon={true}
        closeOnDimmerClick={true}
        onClose={() => {
          setShowNodeDetail(false);
          setLoc(null);
        }}
        >
        {loc ?
        <StepEditor
          xddata={props.xddata}
          docInfo={props.docInfo}
          editMode={"Role"}
          onEvent={props.onEvent}
          view={props.view}
          loc={loc}
          xdedit={false}
          // Not actually used
          setProp={() => {}}
          trigger={false}
          remove={() => {}}
          doneCellDetail={() => {}}
        />:
        "ไม่พบข้อมูลรายละเอียด"
        }
      </Modal>
    </div>
  )
}