import React, { useState } from "react";
import { getNodeSummary } from "./NodeMatrix";

const NodeSummary = (props: any) => {
  const [focusItem, setFocusItem] = useState<any>(null)

  const steps = props.view.stepViews.map((item: any) => item.step);
  const [matrix, nodeList] = getNodeSummary(props.xddata, props.xdmaster, steps);

  return (
    <div 
      style={{ margin: "5px"}}>
      {nodeList.map((node: string, index: number) => 
      <div key={index} style={{display: "grid", gridTemplateColumns: "200px 1fr", borderBottom: "solid black 1px"}}>
        <div key={`${index}_0`} style={{marginBottom: "10px"}}>{`${node}`}</div>
        <div key={`${index}_1}`} style={{display: "flex", flexWrap: "wrap"}}>
        {node in matrix ? 
        Object.keys(matrix[node]).map((node1: string, fromIndex: number) => 
        (matrix[node][node1].from.length > 0 || matrix[node][node1].to.length > 0) && 
        <div 
          key={fromIndex} 
          style={{marginRight: "10px", cursor: "pointer", display: "flex"}}
          >
          <div style={{marginRight: "2px"}}>{`${node1}`}</div>
          <div 
            style={{marginRight: "2px", position: "relative"}}
            onMouseOver={(e: any) => {
              setFocusItem({node, node1, from: true});
            }}
            onMouseLeave={(e: any) => {
              setFocusItem(null);
            }}
            >
            <div>{`(${matrix[node][node1].from.length}, `}</div>
            {focusItem !== null && focusItem.node === node && focusItem.node1 == node1 
            && focusItem.from && matrix[node][node1].from.length > 0 &&
            <div 
              style={{
                position: "absolute", top: 20, left: 0, 
                width: "500px", backgroundColor: "cyan", 
                border: "solid black 1px", zIndex: 1000
              }}>
              {matrix[node][node1].from.map((item: string, detailIndex: number) =>
                <div key={detailIndex} style={{borderBottom: "solid gray 1px"}}>{item}</div>)
              }
            </div>}
          </div>
          
          <div
            style={{position: "relative"}}
            onMouseOver={(e: any) => {
              setFocusItem({node, node1, from: false});
            }}
            onMouseLeave={(e: any) => {
              setFocusItem(null);
            }}
            >
            <div>{`${matrix[node][node1].to.length}`}</div>
            {focusItem !== null && focusItem.node === node && focusItem.node1 == node1 
            && !focusItem.from && matrix[node][node1].to.length > 0 &&
            <div 
              style={{
                position: "absolute", top: 20, left: 0, 
                width: "500px", backgroundColor: "cyan", 
                border: "solid black 1px", zIndex: 1000
              }}>
              {matrix[node][node1].to.map((item: string, detailIndex: number) =>
                <div key={detailIndex} style={{borderBottom: "solid gray 1px"}}>{item}</div>)
              }
            </div>}
          </div>
          <div>{`),`}</div>
        </div>)
        
        // node not in matrix
        : <div></div>}
      </div>
    </div>)}
    </div>
  )

}

export default NodeSummary;