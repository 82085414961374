import {
    CreateUIState,
    CreateUIEvent
} from './CreateUI'

import { WasmHandler } from '../../../react-lib/frameworks/WasmController'

type Handler = WasmHandler<CreateUIState, CreateUIEvent>

export const CreateUILoad: Handler = (controller, params) => {
    controller.db
    .collection("uigen")
    .doc(params.email)
    .get()
    .then((result: any) => {
        controller.setState({ fs: result.data() })
    });
}

export const CreateUISetData: Handler = (controller, params) => {
    controller.db
    .collection("uigen")
    .doc(params.email).set(params.doc)
}
