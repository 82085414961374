import { useState } from "react";
import { Checkbox, Input } from "semantic-ui-react";

export default function XDCaseAddStepPanel(props: any) {
  const [search, setSearch] = useState("");

  return(
    <div 
      style={{height: "100%", padding: "5px", display: "flex", flexDirection: "column"}}>
      <div style={{display: "flex", alignItems: "center"}}>
        <Checkbox
          label="เฉพาะ step ถัดไป"
          checked={!props.showAllSeqOptions}
          onClick={() => {
            props.setShowAllSeqOptions(!props.showAllSeqOptions);
            setSearch("");
          }}
        />
        <Input 
          style={{flex: 1, marginRight: "5px"}}
          size="mini" 
          placeholder="ค้นจากชื่อ step"
          value={search}
          onChange={(e: any) => setSearch(e.target.value)}
        />
      </div>
      <div
        style={{overflowY: "scroll", flex: 1, padding: "5px"}}>
        {(props.xddata.sections || [])
          .filter((section: any) => !section.inactive)
          .flatMap((section: any, sectionIndex: number) => (
            (section.steps || [])
            .map((stepId: any, stepIndex: number) => ({stepId, originalIndex: stepIndex}))
            .filter((stepItem: any) => 
              props.showAllSeqOptions ? true : props.nextSeqList.includes(stepItem.stepId))
            .filter((stepItem: any) => 
              search === "" ? true 
              : props.xddata.steps?.[stepItem.stepId]?.description?.includes(search))
            .map((stepItem: any, stepIndex: number) => {
              let step = props.xddata.steps[stepItem.stepId];
              return(
                <div 
                  key={`${sectionIndex}_${stepItem.originalIndex}`}
                  style={{padding: "2px", borderBottom: "solid #cccccc 1px"}}
                  onClick={() => {
                    props.selectStep(step);
                  }}>
                  {`${sectionIndex + 1}.${stepItem.originalIndex + 1}. ${step.description}`}
                </div>
              )
            }
        )))}
      </div>
    </div>
  )
}
