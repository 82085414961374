import { useEffect, useState } from "react";

export default function Activeissues(props: any) {
  const [selectedStatus, setSelectedStatus] = useState("2");
  const [groupby, setGroupby] = useState("release_name");

  useEffect(() => {
    refreshData()
  }, []);

  const refreshData = () => {
    props.onEvent({message: "GetActiveIssues", params: {}});
  }

  // console.log(props.activeIssues);

  return(
    <div style={{padding: "5px"}}>
      <div style={{display: "flex", borderBottom: "solid #dddddd 1px"}}>
        <div
          style={{backgroundColor: selectedStatus === "2" ? "#dddddd" : "white", cursor: "pointer", padding: "5px", border: "solid #dddddd 1px"}}
          onClick={(e: any) => setSelectedStatus("2")}>
          {`In Progress (${props.activeIssues.filter((issue: any) => issue?.assign && issue?.status_id?.toString() === "2").length})`}
        </div>
        <div
          style={{backgroundColor: selectedStatus === "3" ? "#dddddd" : "white", cursor: "pointer", padding: "5px", border: "solid #dddddd 1px"}}
          onClick={(e: any) => setSelectedStatus("3")}>
          {`Resolved (${props.activeIssues.filter((issue: any) => issue?.assign && issue?.status_id?.toString() === "3").length})`}
        </div>
        <div
          style={{backgroundColor: selectedStatus === "4" ? "#dddddd" : "white", cursor: "pointer", padding: "5px", border: "solid #dddddd 1px"}}
          onClick={(e: any) => setSelectedStatus("4")}>
          {`Feedback (${props.activeIssues.filter((issue: any) => issue?.assign && issue?.status_id?.toString() === "4").length})`}
        </div>
        <div
          style={{backgroundColor: selectedStatus === "9" ? "#dddddd" : "white", cursor: "pointer", padding: "5px", border: "solid #dddddd 1px"}}
          onClick={(e: any) => setSelectedStatus("9")}>
          {`Ready for Test (${props.activeIssues.filter((issue: any) => issue?.assign && issue?.status_id?.toString() === "9").length})`}
        </div>
        <div style={{flex: 1}}></div>
      </div>
      <div 
        style={{
          display: "grid", gridTemplateColumns: "50px 1fr 150px 200px 200px 200px",
          borderBottom: "solid grey 1px", overflowY: "scroll"
        }}>
        <div>Issue ID</div>
        <div>Subject</div>
        <div>Updated</div>
        <div
          style={{backgroundColor: groupby === "release_name" ? "#eeeeee" : "white", cursor: "pointer"}}
          onClick={(e: any) => setGroupby("release_name")}>
          Release</div>
        <div
          style={{backgroundColor: groupby === "assign" ? "#eeeeee" : "white", cursor: "pointer"}}
          onClick={(e: any) => setGroupby("assign")}>
          Assigned To</div>
        <div
          style={{backgroundColor: groupby === "author" ? "#eeeeee" : "white", cursor: "pointer"}}
          onClick={(e: any) => setGroupby("author")}>
          Author</div>
      </div>
      <div style={{height: "87vh", overflowY: "scroll"}}>
        {props.activeIssues
        .filter((issue: any) => issue?.assign && issue?.status_id?.toString() === selectedStatus)
        .sort((a: any, b: any) => 
          (a[groupby] || "") < (b[groupby] || "") ? -1 
          : (a[groupby] || "") > (b[groupby] || "") ? 1
          : (a.updated_on < b.updated_on ? -1: 1)
        )
        .reduce((acc: any[], cur: any) => {
          if (acc.length > 0 && 
            cur[groupby].trim() !== acc[acc.length - 1][groupby].trim()
          ) {
            acc[acc.length - 1].lastForGroup = true;
          }
          acc.push(Object.assign({}, cur));
          return acc;
        }, [])
        .map((issue: any, issueIndex: number) => (
          <div 
            key={issueIndex}
            style={{
              display: "grid", 
              gridTemplateColumns: "50px 1fr 150px 200px 200px 200px",
              borderBottom: issue.lastForGroup ? "solid black 2px" : "solid #dddddd 1px"
            }}>
            <div
              style={{cursor: "pointer"}}
              onClick={(e: any) => 
                window.open(`https://redmine.thevcgroup.com/issues/${issue.id}`, "_blank")
              }>
              <a>{issue.id}</a>
            </div>
            <div>{issue.subject}</div>
            <div>{issue.updated_on}</div>
            <div>{issue.release_name}</div>
            <div>{issue.assign}</div>
            <div>{issue.author}</div>
          </div>
        ))}
      </div>
    </div>
  )
}