import { WasmHandler } from 'react-lib/frameworks/WasmController';
import app from 'firebase/compat/app';

export type State = {
  hospitalList?: any[]
}

export const StateInitial = {
  hospitalList: []
}

export type Event =
  { message: "HLGetList", params: {} }
  | { message: "HLGetItem", params: { id : string } }
  

export type Handler = WasmHandler<State, Event>

export type Data = {
}

export const DataInitial: Data = {  
}

export const HLGetList: Handler = async (controller, params) => {
  let cache = (await controller.db.collection("cache").doc("hospital").get())?.data() || {}
  controller.setState({hospitalList: Object.values(cache)});
}

export const HLGetItem: Handler = async (controller, params) => {

}

// let storageweb = (controller as any).storageweb as app.storage.Storage;
// let url = await storageweb.refFromURL("gs://everymorian.appspot.com/hospitals.json").getDownloadURL()
// let data = await (await fetch(url)).json();
// controller.setState({hospitalList: data});
// let batch = controller.db.batch();
// let cache: any = {}
// for (var i = 0; i < data.length; i++) {
//   let id = `${i}`.padStart(6, '0');
//   let doc = { id: id, ...data[i] };
//   console.log(id, data[i]);
//   cache[id] = doc
//   batch.set(controller.db.collection("hospital").doc(id), data[i])
// }
// console.log(cache);
// await batch.commit();
// controller.db.collection("cache").doc("hospital").set(cache);