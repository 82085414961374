import React, { createRef, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Icon } from "semantic-ui-react";
import moment from "moment";
import ReactMarkdown from "react-markdown";

export const STEP_FULL_SCORE = 3

export default function GamePlayer2(props: any) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [currentLoc, setCurrentLoc] = 
    useState<{level: number, step: number, view: string}>(
      {
        level: props?.startLevel || 0, 
        step: 0, 
        view: "LevelIntro"
      });
  const [openMission, setOpenMission] = useState(false);
  const [totalScore, setTotalScore] = useState(0);
  const [stepScore, setStepScore] = useState(STEP_FULL_SCORE);

  const containerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const ihGamePlaySessionId = useRef<any>(null);
  const shootSound = useRef<HTMLAudioElement>(null);
  const failedSound = useRef<HTMLAudioElement>(null);
  const targetRef = useRef<any>();
  const stepScoreRef = useRef(STEP_FULL_SCORE);

  // Hook ------------------------------------------------------------------------------
  useLayoutEffect(() => {  setWidthHeight(); }, []);

  useEffect(() => {
    return () => {
      props?.setStartLevel?.(0);
    }
  }, []);

  useEffect(() => {
    if (props.ihGamePlaySession && props.ihGamePlaySession.id !== ihGamePlaySessionId.current) {
      ihGamePlaySessionId.current = props.ihGamePlaySession.id;
      startGame();
    }
  }, [props.ihGamePlaySession]);

  useEffect(() => {
    if (playing && currentLoc.view === "LevelIntro") {
      setTimeout(() => {
        setCurrentLoc({
          ...currentLoc,
          view: "StepIntro"
        });
      }, 2000);
    } else if (playing && currentLoc.view === "StepIntro") {
      setTimeout(() => {
        setCurrentLoc({
          ...currentLoc,
          view: "Screen"
        });
      }, 2000);
    } else if (playing && currentLoc.view == "Screen") {
      if (!canvasRef.current || canvasRef.current === null) return console.log("No canvas");
      const img = new Image();
      img.onload = async (ev) => {
        let canvas = canvasRef.current!;
        let ctx = canvasRef.current!.getContext("2d")!;

        // Clear old data
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        
        // Set canvas sizing
        let { naturalWidth, naturalHeight } = img;
        let containerRatio = width / height;
        let naturalRatio = naturalWidth / naturalHeight;
        let offsetX = 0;
        let offsetY = 0;
        if (containerRatio >= naturalRatio) {
          canvas.height = naturalHeight;
          canvas.width = naturalHeight * containerRatio;
          offsetX = (canvas.width - naturalWidth) / 2.0;
        } else if (containerRatio < naturalRatio) {
          canvas.width = naturalWidth;
          canvas.height = naturalWidth / containerRatio;
          offsetY = (canvas.height - naturalHeight) / 2.0;
        }
        
        // Draw image & target
        ctx.drawImage(img as CanvasImageSource, offsetX, offsetY, naturalWidth, naturalHeight);
        if (props.selectedIHGame.levels[currentLoc.level].steps[currentLoc.step]?.target) {
          let [x, y, w, h] = props.selectedIHGame.levels[currentLoc.level].steps[currentLoc.step].target; 
          let target = new Path2D();
          target.rect(x + offsetX, y + offsetY, w, h);
          targetRef.current = {x: x + offsetX, y: y + offsetY, width: w, height: h};
          // ctx.lineWidth = 3;
          // ctx.strokeStyle = "red";
          // ctx.stroke(target);

          // Set up event listener
          canvas.addEventListener('mouseup', function handleClick(e: MouseEvent) {
            let canvas = canvasRef.current!;
            let ctx = canvas?.getContext("2d")!;
            if (ctx?.isPointInPath(
              target, 
              e.clientX * canvas.width / canvas.clientWidth, 
              e.offsetY * canvas.height / canvas.clientHeight)
            ) {
              HandleShoot();
              canvas.removeEventListener('mouseup', handleClick);
            } else {
              HandleFailed();
            }
          });       
        } else {
          targetRef.current = null;
        }
      }
      img.src = props.selectedIHGame.levels[currentLoc.level].steps[currentLoc.step].screen.objectUrl;
    } else if (playing && currentLoc.view === "Solution") {
      DrawTarget();
      // setTimeout(() => { HandleNextStep(); }, 2000);
    } else if (playing && currentLoc.view === "Lesson") {
      if (!canvasRef.current || canvasRef.current === null) return console.log("No canvas");
      const img = new Image();
      img.onload = async (ev) => {
        let canvas = canvasRef.current!;
        let ctx = canvasRef.current!.getContext("2d")!;

        // Clear old data
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        
        // Set canvas sizing
        let { naturalWidth, naturalHeight } = img;
        let containerRatio = width / height;
        let naturalRatio = naturalWidth / naturalHeight;
        let offsetX = 0;
        let offsetY = 0;
        if (containerRatio >= naturalRatio) {
          canvas.height = naturalHeight;
          canvas.width = naturalHeight * containerRatio;
          offsetX = (canvas.width - naturalWidth) / 2.0;
        } else if (containerRatio < naturalRatio) {
          canvas.width = naturalWidth;
          canvas.height = naturalWidth / containerRatio;
          offsetY = (canvas.height - naturalHeight) / 2.0;
        }
        
        // Draw image & target
        ctx.drawImage(img as CanvasImageSource, offsetX, offsetY, naturalWidth, naturalHeight);
      }
      img.src = props.selectedIHGame.levels[currentLoc.level].steps[currentLoc.step].lesson.objectUrl;
      setTimeout(() => {
        HandleNextStep();
      }, 3000);
    }
  }, [playing, currentLoc]);

  // Utilities --------------------------------------------------------------------------
  const setWidthHeight = () => {
    if (containerRef?.current) {
      setWidth(containerRef.current.offsetWidth);
      setHeight(containerRef.current.offsetHeight);
    }
  }

  const addLog = (level: number, step: number, success: boolean, totalScoreNow: number) => {
    if (props.forTest) return;

    let playSession = Object.assign({}, props.ihGamePlaySession);
    playSession.logs.push({
      datetime: moment().format('YYYY-MM-DDTHH:mm:ss'),
      level: level,
      step: step,
      success: success,
      score: totalScoreNow,
      fullScore: fullScore(),
    });
    props.onEvent({message: "IHGameLogPlaySession", params: {playSession}});    
  }

  const fullScore = () => {
    return (STEP_FULL_SCORE * props.selectedIHGame.levels
            .map((level: any) => level.steps.length)
            .reduce((acc: number, cur: number) => acc + cur, 0));
  }

  // Game events -------------------------------------------------------------------------
  const startGame = () => {
    // Return if no width and height
    if (!width || !height) return;

    setPlaying(true);
    setTotalScore(0);
    stepScoreRef.current = STEP_FULL_SCORE;
    setStepScore(stepScoreRef.current);
  };

  const stopGame = () => {
    setPlaying(false);
    setCurrentLoc({level: 0, step: 0, view: "Summary"});
  }

  const DrawTarget = () => {
    if (targetRef.current) {
      let { x, y, width, height } = targetRef.current;
      let target = new Path2D();
      target.rect(x, y, width, height);
      let ctx = canvasRef.current?.getContext("2d");
      if (ctx) {
        ctx.lineWidth = 3;
        ctx.strokeStyle = "red";
        ctx.stroke(target);
      }
    }
  }

  const HandleShoot = () => {
    if (currentLoc.view === "Screen") {
      // console.log("shoot (success)", currentLoc.level, currentLoc.step);
      addLog(currentLoc.level, currentLoc.step, true, totalScore + stepScoreRef.current);
      setTotalScore(totalScore + stepScoreRef.current);      
      shootSound?.current?.play();
      DrawTarget();
      if (props.selectedIHGame.levels[currentLoc.level].steps[currentLoc.step]?.lesson?.objectUrl) {
        setCurrentLoc({
          ...currentLoc,
          view: "Lesson"
        });
      } else {
        setTimeout(() => {
          HandleNextStep();
        }, 1000);
      }
    } else if (currentLoc.view === "Solution") {
      shootSound?.current?.play();
      HandleNextStep();
    }
  }

  const HandleFailed = () => {
    if (currentLoc.view === "Screen") {
      // console.log("failed", currentLoc.level, currentLoc.step);
      addLog(currentLoc.level, currentLoc.step, false, totalScore);
      failedSound?.current?.play();
      if (stepScoreRef.current - 1 > 0) {
        stepScoreRef.current = stepScoreRef.current - 1;
        setStepScore(stepScoreRef.current);
      } else {
        stepScoreRef.current = 0;
        setStepScore(stepScoreRef.current);
        setCurrentLoc({
          ...currentLoc, 
          view: "Solution"
        });
      }
    } else if (currentLoc.view === "Solution") {
      failedSound?.current?.play();
    }
  }

  const HandleNextStep = () => {
    stepScoreRef.current = STEP_FULL_SCORE;
    setStepScore(stepScoreRef.current);
    if (currentLoc.step < props.selectedIHGame.levels[currentLoc.level].steps.length - 1) {
      setCurrentLoc({level: currentLoc.level, step: currentLoc.step + 1, view: "StepIntro"});
    } else if (currentLoc.level < props.selectedIHGame.levels.length - 1) {
      let nextLevel = currentLoc.level + 1;
      while (nextLevel <= props.selectedIHGame.levels.length - 1
             && props.selectedIHGame.levels[nextLevel].steps.length === 0
      ) {
        nextLevel++;
      }
      if (nextLevel >= props.selectedIHGame.levels.length) {
        HandleStop();
      } else {
        setCurrentLoc({level: nextLevel, step: 0, view: "LevelIntro"});
      }
    } else {
      HandleStop();
    }
  }

  const HandleStop = () => {
    stopGame();
  }

  const HandleRestart = () => {
    setCurrentLoc({level: 0, step: 0, view: "LevelIntro"});
  }

  // Render ------------------------------------------------------------------------------
  return (
    <div 
      ref={containerRef}
      style={{
        width: "100%", height: "90vh", position: "relative",
        display: "flex", flexDirection: "column",
        justifyContent: "center", alignItems: "center"
      }}>
      {playing &&
      <div 
        style={{
          position: "absolute", top: -20, fontSize: "1.2rem", 
          color: currentLoc.view === "Solution" ? "red" 
                : !props.selectedIHGame?.levels?.[currentLoc.level]?.steps?.[currentLoc.step]?.target ? "red"
                : "green",
          fontWeight: "bold"
        }}>
        {currentLoc.view === "Solution" ? 
          "กดในกรอบสีแดงเผื่อผ่านไปขั้นต่อไป" 
          : !props.selectedIHGame?.levels?.[currentLoc.level]?.steps?.[currentLoc.step]?.target ? `ไม่มี Target ไม่สามารถไปต่อได้`
          : `Total Score: ${totalScore} <--- +${stepScore}`}
      </div>}
      {playing && ["Screen", "Solution", "Lesson"].includes(currentLoc.view) ?    
      <>
        <div 
          style={{
            position: "absolute", top: 10, right: 10,
            display: "flex", flexDirection: "column", alignItems: "flex-end"
          }}>
          <Icon 
            name="question" color="blue" bordered inverted circular 
            onClick={() => {
              // setCurrentLoc({...currentLoc, view: "StepIntro"});
              setOpenMission(!openMission);
            }}
          />
          {openMission &&
          <div 
            style={{
              width: "300px", backgroundColor: "lightgreen", 
              border: "solid black 1px", padding: "10px", textAlign: "center"
            }}>
            <div>
              <ReactMarkdown>
                {props.selectedIHGame?.levels?.[currentLoc.level]?.steps?.[currentLoc.step]?.mission ||
                props.selectedIHGame?.levels?.[currentLoc.level]?.steps?.[currentLoc.step]?.guide || "????"}
              </ReactMarkdown>
            </div>
          </div>}
        </div>
        <canvas 
          ref={canvasRef}
          style={{width: "100%", height: "100%"}}
        /> 
      </>
      : playing && currentLoc.view === "StepIntro" ?
      <div 
        style={{
          fontSize: "3rem", lineHeight: "3rem",
          width: "100%", height: "100%", display: "flex", padding: "40px",
          justifyContent: "center", alignItems: "center", textAlign: "center", flex: 1,
        }}>
        <div>
          <ReactMarkdown>
            {props.selectedIHGame?.levels?.[currentLoc.level]?.steps?.[currentLoc.step]?.mission ||
            props.selectedIHGame?.levels?.[currentLoc.level]?.steps?.[currentLoc.step]?.guide || "????"}
          </ReactMarkdown>
        </div>
      </div>
      : playing && currentLoc.view === "LevelIntro" ?
      <div style={{fontSize: "5rem", fontWeight: "bold"}}>
        {`Level ${currentLoc.level + 1} / ${props.selectedIHGame.levels?.length}`}
      </div>
      : !playing ?
      <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
        {currentLoc.view === "Summary" &&
        <div 
          style={{
            height: "300px", width: "400px", fontSize: "2rem",
            display: "flex", justifyContent: "center", alignItems: "center",
            backgroundColor: "lightgreen", marginBottom: "20px"
          }}>
          {`Total Score: ${totalScore} / ${fullScore()}`}
        </div>}
        <Button
          disabled={!props.selectedIHGame}
          color="blue"
          onClick={(e: any) => {
            if (props.forTest ) {
              if (currentLoc?.view === "Summary") {
                HandleRestart();
              }
              startGame();
            } else {
              if (currentLoc?.view === "Summary") {
                HandleRestart();
              }
              props.onEvent({message: "IHGameNewPlaySession", params: {}});
            }
          }}>
          {`${!props.selectedIHGame ? "Loading" : currentLoc?.view === "Summary" ? "Restart": "Start"}`}
        </Button>
      </div>
      :
      <></>}
      <audio 
        ref={shootSound} 
        src={props.selectedIHGame?.levels?.[currentLoc.level]?.sound?.shoot?.objectUrl} 
        style={{display: "none"}} 
      />
      <audio 
        ref={failedSound} 
        src={props.selectedIHGame?.levels?.[currentLoc.level]?.sound?.failed?.objectUrl} 
        style={{display: "none"}} 
      />
    </div>
  )
};
