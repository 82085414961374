import { useEffect, useState } from "react";
import { Button } from "semantic-ui-react";

export default function EditStaffForm(props: any) {
  const [username, setUsername] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [start, setStart] = useState<string | null>(null);
  const [role, setRole] = useState<string | null>(null);

  useEffect(() => {
    if (props.editedStaff) {
      setUsername(props.editedStaff?.id);
      setName(props.editedStaff?.name);
      setStart(props.editedStaff?.start);
      setRole(props.editedStaff?.role);
    }
  }, [props.editedStaff]);

  return (
    <div>
      <div 
        style={{
          display: "grid", 
          gridTemplateColumns: "100px 200px 1fr"
        }}>
        <div>username</div>
        <input 
          value={username || ""}
          onChange={(e: any) => setUsername(e.target.value)}
        />
      </div>
      <div 
        style={{
          display: "grid", 
          gridTemplateColumns: "100px 200px 1fr"
        }}>
        <div>name</div>
        <input 
          value={name || ""}
          onChange={(e: any) => setName(e.target.value)}
        />
      </div>
      <div 
        style={{
          display: "grid", 
          gridTemplateColumns: "100px 200px 1fr"
        }}>
        <div>role</div>
        <input 
          value={role || ""}
          onChange={(e: any) => setRole(e.target.value)}
        />
      </div>
      <div 
        style={{
          display: "grid", 
          gridTemplateColumns: "100px 200px 1fr"
        }}>
        <div>start</div>
        <input 
          value={start || ""}
          onChange={(e: any) => setStart(e.target.value)}
        />
      </div>
      <Button size="mini" color="blue"
        onClick={(e: any) => {
          props.onEvent({
            message: "EditStaff",
            params: {
              username, name, start, role, 
              add: !props.editedStaff
            }
          });
          props.onDone();
        }}>
        Done
      </Button>
    </div>)
}