import React from 'react'
import { WasmHandler } from 'react-lib/frameworks/WasmController'
import pdfMake from 'pdfmake/build/pdfmake'
import vfsFonts from 'pdfmake/build/vfs_fonts'
import FormDef, { type, NewPDFDefTemplate } from "./FormDef";
import RenderSourceCode, { convertDataForPDFMake} from './RenderSourceCode';
import JSZip from "jszip";
import { saveAs } from "file-saver";

// Setup pdfmake
const { vfs } = vfsFonts.pdfMake
pdfMake.vfs = vfs
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}

export type State = {
  formRefList?: any[],
  preferences?: any,
  selectedFormRef?: any,
  formDef?: any,
  pdfSrc?: string,
}

export const StateInitial = {
  formRefList: [],
  selectedFormRef: null,
  formDef: {},
  pdfSrc: "",
}

export type Event =
  { message: "GenPDF", params: any } 
  | { message: "RefreshPDF", params: {} } 
  | { message: "SavePDF", params: {} }
  | { message: "EditPDFNameAndProject", params: {name: string, project: string} }
  | { message: "PDFMakeDownload", params: {} }
  | { message: "PDFAddImage", params: {name: string, fileName: string, imgBlob: Blob}}
  | { message: "PDFAddNewFile", params: {name: string, project: string}}
  | { message: "PDFSetContent", params: {content: any[]}}

type Handler = WasmHandler<State, Event>

const deepCopy = (obj: any) => JSON.parse(JSON.stringify(obj))

export const GenPDF: Handler = async (controller, params) => {
  const state = controller.getState();
  if (params?.action === "Init") {
    let pdfCache = (await controller.db.collection("cache").doc("pdf").get()).data();
    let formRefList = pdfCache?.items || []
    controller.setState({ formRefList: formRefList });
    let formInfo;
    if (state.preferences?.form 
        && formRefList.map((item: any) => item.id).includes(state.preferences.form)
    ) {
      formInfo = formRefList.find((item: any) => item.id === state.preferences.form)
    } else {
      formInfo = formRefList[0];
    }
    await UpdateSelectedForm(controller, {formInfo});
  } else if (params?.action === "SelectForm" && params?.formRef) {
    let formInfo = params.formRef;
    controller.db.collection("preferences")
      .doc(controller.user.email)
      .update({form: formInfo.id});
    controller.setState({
      preferences: {...state.preferences, form: formInfo.id}
    })
    await UpdateSelectedForm(controller, {formInfo});
  } else if (params?.action === "AddRow") {
    let [obj, keys, targetKey] = GetFormDefAtDataKey(state.formDef, params?.dataKey || "")
    let data = JSON.parse(JSON.stringify(params.formProps));
    
    obj.splice(parseInt(keys[keys.length-1][1] as string) + 1, 0, data);
    controller.setProp(targetKey, obj);
  } else if (params?.action === "RemoveRow") {
    if (!params?.dataKey) return;
    let [obj, keys, targetKey] = GetFormDefAtDataKey(state.formDef, params.dataKey || "")
    const indexToRemove = parseInt(keys[keys.length-1][1] as string);
    obj = obj.filter((item: any, itemIndex: number) => itemIndex != indexToRemove);
    controller.setProp(targetKey, obj);
  } else if (params?.action === "DownRow") {
    if (!params?.dataKey) return;
    let [obj, keys, targetKey] = GetFormDefAtDataKey(state.formDef, params.dataKey || "")
    const indexToDown = parseInt(keys[keys.length-1][1] as string);
    obj = obj.map((item: any, index: number) => 
      index === indexToDown ? obj[indexToDown + 1]
      : index === indexToDown + 1 ? obj[indexToDown]
      : item
    )
    controller.setProp(targetKey, obj);
  } else if (params?.action === "UpRow") {
    if (!params?.dataKey) return;
    let [obj, keys, targetKey] = GetFormDefAtDataKey(state.formDef, params.dataKey || "")
    const indexToUp = parseInt(keys[keys.length-1][1] as string);
    obj = obj.map((item: any, index: number) => 
      index === indexToUp - 1 ? obj[indexToUp]
      : index === indexToUp ? obj[indexToUp - 1]
      : item
    )
    controller.setProp(targetKey, obj);
  } else if (params?.action === "AddArray") {
    // After moving to firestore with {__items: []} format, this case shouldn't happen any more.
    let [obj, keys, targetKey] = GetFormDefAtDataKey(state.formDef, params?.dataKey || "")
    obj.splice(parseInt(keys[keys.length -1][1] as string) + 1, 0, []);
    controller.setProp(targetKey, obj);
  } else {
    console.log("action ", params?.action, ": not implemented.")
  }
}

export const EditPDFNameAndProject: Handler = async (controller, params) => {
  if (!params.id || !params.name || !params.project) return;
  let state = controller.getState();
  controller.db.collection("ClientForm").doc(params.id)
    .update({
      name: params.name,
      project: params.project
    });
  let formRefList = (await controller.db.collection("cache").doc("pdf").get()).data()?.items || [];
  for (var i=0; i < formRefList.length; i ++) {
    if (formRefList[i].id === params.id) {
      formRefList[i].name = params.name;
      formRefList[i].project = params.project
    }
  }
  controller.db.collection("cache").doc("pdf").set({items: formRefList});
  controller.setState({
    formRefList: formRefList,
    selectedFormRef: {
      ...state.selectedFormRef,
      name: params.name,
      project: params.project,
    }
  });
}

export const PDFAddNewFile: Handler = async (controller, {name, project}) => {
  const state = controller.getState();
  let selectedFormRef: any = {
    name, 
    project,
    images: {},
    pdfDef: Object.assign({}, NewPDFDefTemplate)
  }
  const doc = await controller.db.collection("ClientForm").add(selectedFormRef);
  let formInfo = {id: doc.id, name, project}
  let formRefList = state.formRefList!;
  formRefList = [...formRefList, formInfo];
  controller.setState({ formRefList: formRefList });
  controller.db.collection("cache").doc("pdf").set({items: formRefList});
  controller.db.collection("preferences").doc(controller.user.email).update({form: formInfo.id});
  await UpdateSelectedForm(controller, {formInfo});
}

export const PDFSetContent: Handler = async (controller, {content}) => {
  let state = controller.getState();
  let newContent = content.map((row: any[]) => {
    if (row.length === 0) {
      return null
    } else if (row.length === 1) {
      let newItem = JSON.parse(JSON.stringify(FormDef.text));
      newItem.text = row[0].item;
      newItem.width = "auto";
      if (row[0].mode === "prop") {
        newItem.__meta.props = ["text"]
      }
      return newItem;
    } else {
      let newItem = JSON.parse(JSON.stringify(FormDef.columns));
      newItem.columns.__items = row.map((column: any) => {
        let newColumn =  JSON.parse(JSON.stringify(FormDef.text));
        newColumn.text = column.item;
        console.log(column.item);
        newColumn.width = "auto";
        if (column.mode === "prop") {
          newColumn.__meta.props = ["text"]
          newColumn.alignment = "center"
        }
        return newColumn;
      });
      return newItem; 
    }
  }).filter((item: any) => item);
  console.log(newContent);
  state.formDef.content.__items = newContent;
  controller.setState({formDef: state.formDef});
  RefreshPDF(controller, {});
}

const UpdateSelectedForm: Handler = async (controller, {formInfo}) => {
  let result = 
    (await controller.db.collection("ClientForm").doc(formInfo.id).get());
  let selectedFormRef: any = {id: result.id, ...result.data()};
  if (!selectedFormRef) return;
  // Get Download Urls for Images
  selectedFormRef.imageUrls = await Promise.all(
    Object.entries(selectedFormRef.images)
    .map(async (entry: [string, any]) => (
      {name: entry[0], url: await controller.storage.refFromURL(entry[1]).getDownloadURL()}
    ))
  );
  controller.setState({ selectedFormRef });

  // Prepare pdfDef
  const pdfDef = selectedFormRef.pdfDef || {}
  UpdatePdfDef(controller, {pdfDef});
}

const UpdatePdfDef: Handler = async (controller, {pdfDef}) => {
  const state = controller.getState();
  let selectedFormRef = state.selectedFormRef
  let pdfData: any = convertDataForPDFMake(pdfDef);
  pdfData.images = Object.fromEntries(
    selectedFormRef.imageUrls.map((item: any) => (
      [item.name, item.url]
    )));

  // Generate PDF and setState
  pdfMake.createPdf(pdfData).getDataUrl((pdfSrc) => {
    controller.setState({ 
      selectedFormRef: selectedFormRef,
      formDef: JSON.parse(JSON.stringify(pdfDef)),
      pdfSrc: pdfSrc 
    });
  });
}

const gatherAllKeys = (obj: any, parentKey: string, output: string[]) => {
  for (const key of Object.keys(obj)) {
    output.push(`${parentKey}.${key}`);
    if (type(obj[key]) === "object") {
      output = gatherAllKeys(obj[key], parentKey + "." + key, output);
    } else if (type(obj[key]) === "array") {
      for (const index in obj[key]) {
        output.push(`${parentKey}.${key}.${index}`)
        output = gatherAllKeys(obj[key][index], parentKey + "." + key + "." + index, output)
      }
    }
  }
  return output
}
 
export const PDFAddImage: Handler = async (controller, {name, fileName, imgBlob}) => {
  if (!name || !imgBlob) return;
  
  let state = controller.getState();
  const {id, ...data_} = state.selectedFormRef;
  if (!id) return;

  let ext = fileName.split(".")?.[1]
  let fullName = name + (ext ? ("." + ext) : "");
  let ref = `gs://ismor-pdfgen/images/${id}/${fullName}`;
  controller.storage.refFromURL(ref)
    .put(imgBlob)
    .then((snapshot: any) => {
      controller.db.collection("ClientForm").doc(id).update({[`images.${name}`]: ref})
    }).catch((error: any) => console.log(error));
}

const GetFormDefAtDataKey = (formDef: any, dataKey: string) => {
  let obj = formDef;
  const keys = Array.from(dataKey.split(".").entries());
  let targetKey = "formDef"
  for (const item of keys) {
    if (item[0] > 0 && item[0] < keys.length -1) {
      targetKey += `.${item[1]}`
      obj = obj[item[1]]
    }
  }
  return [obj, keys, targetKey]
}

export const RefreshPDF: Handler = async (controller, params) => {
  let state = controller.getState();
  UpdatePdfDef(controller, {pdfDef: state.formDef});
}

export const SavePDF: Handler = async (controller, params) => {
  const state = controller.getState();
  if (state.selectedFormRef) {
    const {id, ...data_} = state.selectedFormRef;
    // Convert old data
    // let dataToSave = AddMeta(state.formDef);
    let dataToSave = state.formDef;
    controller.db.collection("ClientForm").doc(id).update({pdfDef: dataToSave})
  }
}

export const PDFMakeDownload: Handler = async (controller, params) => {
  const state = controller.getState();
  const formDef = state.formDef;
  const images = state.selectedFormRef.images;
  const src = RenderSourceCode(formDef);
  const tsFileName = `${state.selectedFormRef.project.replaceAll(" ", "")}_` 
                     + `${state.selectedFormRef.name.replaceAll(" ", "")}`
  
  // Version 1 clipboard
  // const selectedFormRef = controller.getState().selectedFormRef;
  // const src = JSON.stringify(AddMeta(selectedFormRef), null, 1);
  // controller.window.navigator.clipboard.writeText(src);
  
  // Version 2 download source code
  // const link = document.createElement('a');
  // link.download = tsFileName;
  // link.href = URL.createObjectURL(new Blob([src], 
  //   {type: "text/typescript"}));
  // link.click();
  // URL.revokeObjectURL(link.href);
  
  // Version 3 download source code and images
  const zip = JSZip();
  zip.file(tsFileName + ".ts", new Blob([src], {type: "text/typescript"}));
  const img = zip.folder("images");
  for (const entry of Object.entries(images)) {
    const ref = entry[1] as string;
    const url = await controller.storage.refFromURL(ref).getDownloadURL()
    const url_ = url.replaceAll("%2F", "/");
    const parts = url_.split("/");
    const imageFileName = parts[parts.length - 1].split("?")[0];
    const imgData = await fetch(url);
    img?.file(imageFileName, imgData.blob());
  }
  zip.generateAsync({type: "blob"})
  .then((content: any) => saveAs(content, tsFileName + ".zip"));
}

// For migrating from storage to firestore -------------------------------------------
const AddMeta = (formDef: any) => {
  let convertedData = convertDataToAddMeta(formDef, "pdfDef", null);
  return convertedData
}

const convertDataToAddMeta = (data: any, selfKey: string, parentKey: string | null) => {
  let convertedData: {[key:string]: any} = {
    __meta: {
      type: selfKey === "pdfDef" ? "ROOT" :
            selfKey === "content" ? "ROOT_CONTENT" :
            selfKey === "images" ? "IMAGE_DEF" : 
            selfKey === "body" ? "TABLE_ROWS" : 
            parentKey === "body" && selfKey === "__items" ? "TABLE_COLUMNS" :
            selfKey === "columns" ? "COLUMN_ITEMS" :
            Object.keys(data)?.includes("text") ? "TEXT" :
            Object.keys(data)?.includes("columns") ? "COLUMNS" :
            Object.keys(data)?.includes("image") ? "IMAGE" :
            Object.keys(data)?.includes("table") ? "TABLE" :
            Object.keys(data)?.includes("body") ? "TABLE_BODY" :
            Object.keys(data)?.includes("font") ? "STYLE" :
            Object.keys(data)?.includes("__items") ? "ITEMS" : null,
      props: []
    },
  };
  for (const key of Object.keys(data)) {
    if (key === "__meta")
      continue
    else if (type(data[key]) === "array") {
      convertedData[key] = data[key].map(
        (item: any) => convertDataToAddMeta(item, key, selfKey))
    } else if (type(data[key]) !== "object") {
      convertedData[key] = data[key]
    } else {
      convertedData[key] = convertDataToAddMeta(data[key], key, selfKey);
    }
  }
  return convertedData;
}

const SaveToDb: Handler = async (controller, {selectedFormRef, data}) => {
  const docId = `${selectedFormRef.project.replaceAll("-", "_")}_`
    + `${selectedFormRef.form.split(".")[0].replaceAll("-", "_")}`;;
  let convertedData = convertDataForDb(data);
  controller.db.collection("ClientForm").doc(docId).set({pdfDef: convertedData});
 }

 const convertDataForDb: (data: any) => any 
 = (data: any) => {
  if (type(data) === "array") {
    return {
      __items: data.map((item: any) => convertDataForDb(item))
    }
  } else if (type(data) !== "object") {
    console.log("not object", data)
    return data;
  } 
  let convertedData: {[key:string]: any} = {};
  for (const key of Object.keys(data)) {
    console.log(key, type(data[key]));
    if (type(data[key]) === "array") {
      console.log(key, "-->array")
      convertedData[key] = {
        __items: data[key].map((item: any) => convertDataForDb(item))
      }
    } else if (type(data[key]) !== "object") {
      convertedData[key] = data[key]
    } else {
      convertedData[key] = convertDataForDb(data[key]);
    }
  }
  return convertedData;
 }

 // Object.fromEntries(params.formProps.map((prop: any) => ([
    //   prop.key, 
    //   prop.type === "metatext" ? { type: "TEXT", props: [] }
    //   : prop.type === "metacolumns" ? { type: "COLUMNS", props: [] }
    //   : prop.type === "metatable" ? { type: "TABLE", props: [] }
    //   : prop.type === "string" ? ""
    //   : prop.type === "boolean" ? false
    //   : prop.type === "array" ? { __items: [] }
    //   : prop.type === "table" ? {
    //     body: {__items: [{__items: []}]}, 
    //     dontBreakRows: true, headerRows: 0}
    //   : ""
    // ])));