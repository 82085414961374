import { NumberSequence } from 'ag-grid-community';
import { parseXML } from 'jquery';
import { getJSDocParameterTags } from 'typescript';
import { WasmHandler } from '../../../react-lib/frameworks/WasmController';
import { ScreenData } from './CreateUIv2Jsx';
import { ChangeElementProp } from "./ElementEditorHandler";
import UxDef from './UxDef';

export type State = 
    {
        screenData?: ScreenData,
        editedElementId?: number | null,
        divGridCells?: any[],
        openGridCanvas?: boolean,
        draggedCellIndex?: number | null,
    }

export const StateInitial = 
    {
        divGridCells: [],
        draggedCellIndex: null,
    }

export type Event =
        { message: "ChangeGridDimension", 
          params: { rows: number, cols: number, editedElement: any } }
    |   { message: "AddGridCell", 
          params: { 
              startRow: number, 
              startColumn: number,
              endRow: number,
              endColumn: number
          } }
    |   { message: "RemoveGridCell", params: { index: number } }
    |   { message: "ClearGridCell", params: {} }
    |   { message: "ConfirmGridCell", params: {} }
    |   { message: "MoveDivGridCell", params: GridMoveParams }

type GridMoveParams = { left: number, right: number, 
                        top: number, bottom: number,
                        rows: number, cols: number,
                        startX: number, startY: number, 
                        endX: number, endY: number }

type GridCell = { id?: number, 
                  startRow: number, endRow: number,
                  startColumn: number, endColumn: number }

type Handler = WasmHandler<State, Event>

export const MoveDivGridCell: Handler = async (controller, params: GridMoveParams) => {
    const state = controller.getState();
    const xmove = params.endX - params.startX;
    const ymove = params.endY - params.startY;
    const gridWidth = params.right - params.left;
    const gridHeight = params.bottom - params.top;
    const cellWidth = gridWidth / params.cols;
    const cellHeight = gridHeight / params.rows;
    const colMove = Math.floor(xmove / cellWidth);
    const rowMove = Math.floor(ymove / cellHeight);
    // console.log(params);
    // console.log(xmove, ymove, cellWidth, cellHeight, colMove, rowMove);
    let divGridCells = state.divGridCells || [];
    divGridCells.forEach((cell: GridCell, index: number) => {
        if (Number.isInteger(state.draggedCellIndex) && state.draggedCellIndex === index) {
            cell.startRow += rowMove;
            cell.endRow += rowMove;
            cell.startColumn += colMove;
            cell.endColumn += colMove;
            if (cell.id) {
                let elements = state.screenData?.elements || [];
                for (let element of elements) {
                    if (element.id === cell.id) {
                        let style = eval(`(${element.props?.style?.value})`);
                        style.gridRow = `${cell.startRow}/${cell.endRow}`;
                        style.gridColumn = `${cell.startColumn}/${cell.endColumn}`;
                        element.props!.style.value = JSON.stringify(style);
                        // element.props!.style.value =  
                        //     `{ gridRow: "${cell.startRow}/${cell.endRow}", ` + 
                        //     `gridColumn: "${cell.startColumn}/${cell.endColumn}", ` + 
                        //     `border: "solid 1px rgb(200, 200, 200, 0.5)" }`
                        controller.setProp("screenData.elements", elements);
                        break;
                    }
                }
            }
            controller.setState({
                divGridCells: divGridCells,
                draggedCellIndex: null,
            });
        }
    });
}

export const GetDivGridCellsFromId: Handler = (controller, params) => {
    const elements = controller.getState().screenData?.elements || []
    for (const elem of elements) {
        if (elem.id === params.id) {
            if (!(elem?.label === "divgrid")) return null
            const children = elements.filter((item: any) => item.parent === params.id);
            console.log(children);
            const divGridCells = children.map((item: any) => {
                const style = Object.fromEntries(
                    item.props.style.value
                    .split(",")
                    .map((s: string) => s.replaceAll("{", "")
                                         .replaceAll("}", "")
                                         .replaceAll('"', '')
                                         .replaceAll("'", "")
                                         .replaceAll(" ", "",))
                    .map((s: string) => s.split(":"))
                    .filter((l: string[]) => 
                           ["gridRow", "gridColumn"].includes(l[0]))
                );
                // Eval caused a problem when there is a reference to props in style
                // const style = eval(`(${item.props.style.value.replace("props.", "props?.")})`);
                if (!style.gridRow || !(style.gridRow.split("/").length === 2))
                    return null
                console.log("rows");
                if (!style.gridColumn || !(style.gridColumn.split("/").length == 2))
                    return null  
                console.log("cols");              
                try {
                    const rows = style.gridRow.split("/").map((rowItem: any) => parseInt(rowItem));
                    const cols = style.gridColumn.split("/").map((colItem: any) => parseInt(colItem));
                    return {
                        id: item.id,
                        startRow: rows[0],
                        endRow: rows[1],
                        startColumn: cols[0],
                        endColumn: cols[1],
                    } 
                } catch (e) {
                    return null
                }
            }).filter((item: any) => item !== null);
            return divGridCells;
        }
    }
    return null
}

export const ClearGridCell: Handler = async (controller, params) => {
    let state = controller.getState();
    let cells = state.divGridCells || [];
    controller.setState({ 
        divGridCells: cells.filter((cell: any) => cell.id)
    });
}

export const ConfirmGridCell: Handler = async (controller, params) => {
    let state = controller.getState();
    let elements = state.screenData?.elements || [];
    let cells = state.divGridCells || [];
    const ux = UxDef.div;
    for (const cell of cells) {
        if (!cell.id) {
            const maxId = Math.max(...elements.map((elem: any) => elem.id))
            if (typeof maxId === 'number') {
                cell.id = maxId + 1;
                elements.push({
                    id: maxId + 1,
                    name: ux.name,
                    from: ux.from,
                    void: ux.void,
                    parent: state.editedElementId!,
                    seq: maxId + 1,
                    props: {
                        style: {
                            type: "code",
                            value: `{ gridRow: "${cell.startRow}/${cell.endRow}", ` + 
                                   `gridColumn: "${cell.startColumn}/${cell.endColumn}", ` + 
                                   `border: "solid 1px rgb(200, 200, 200, 0.5)" }`
                        }                    
                    }
                })
            }
        }
    }
    controller.setProp("screenData.elements", elements, () => {
        controller.setState({
            divGridCells: cells
        })
    });
    // controller.setProp("screenData.elements", elements, () => {
    //     controller.setState({
    //         divGridCells: [],
    //         openGridCanvas: false,
    //         editedElementId: null,
    //     })
    // });
}

export const AddGridCell: Handler = async (controller, params) => {
    let cells = controller.getState().divGridCells;
    cells?.push(params);
    controller.setState({ divGridCells: cells });
}

export const RemoveGridCell: Handler = async (controller, params) => {
    const cells = controller.getState()
                            .divGridCells
                            ?.filter((item: any, index: number) =>
                                index !== params.index
                            ) || []
    controller.setState({
        divGridCells: cells
    });
}

export const ChangeGridDimension: Handler = async (controller, params) => {
    let elements = controller.getState().screenData?.elements || [];
    for (const element of elements) {
        if (element.id === params.editedElement.id) {
            console.log(element);
            const style = eval(`(${element.props?.style?.value})`);
            style.gridTemplateRows = `repeat(${params.rows}, 1fr)`;
            style.gridTemplateColumns = `repeat(${params.cols}, 1fr)`;
            console.log(JSON.stringify(style));
            ChangeElementProp(
                controller as any, 
                {
                    id: params.editedElement.id,
                    key: "style",
                    type: "code",
                    value: JSON.stringify(style)
                });
        }
    }
}

export const getStyleDetails = (editedElement: any) => {
    const style = eval(`(${editedElement.props.style.value})`);
    const rows = parseInt(style.gridTemplateRows.split("repeat(")[1].split(",")[0]);
    const cols = parseInt(style.gridTemplateColumns.split("repeat(")[1].split(",")[0]);
    return [style, rows, cols]
}
