import {
    MyBPlusState,
    MyBPlusEvent
} from './MyBPlus'

import { WasmHandler } from '../../../react-lib/frameworks/WasmController'

type Handler = WasmHandler<MyBPlusState, MyBPlusEvent, {}>

export const MyBPlusDidmount: Handler = (controller, params) => {
    controller.functions.httpsCallable("getMyBPlusStat")("").then((result: any) => {
        console.log(result)
        controller.setState({ myBPlusStats: result.data })
    }).catch((error: any) => {
        console.log(error)
    })
}