import { Element } from './CreateUIv2Jsx'
import {
    ElementEditorState, 
    ElementEditorEvent
} from './ElementEditor'

import { WasmHandler } from '../../../react-lib/frameworks/WasmController'
import UxDef from './UxDef'

type Handler = WasmHandler<ElementEditorState, ElementEditorEvent>

export const SetEditedElementName: Handler = (controller, params) => {
    let editedElementId = controller.getState().editedElementId
    if (typeof editedElementId === 'number') {
        SetElement(controller, {   
                elementId: editedElementId,
                modifier: (elem: Element) => { 
                    const ux = UxDef[params.name];
                    elem.name = params.name;
                    elem.from =  ux.from;
                    elem.void = ux.void;
                }
        })
    }
}

export const ChangeElementProp: Handler = async (controller, params) => {
    console.log(params);
    SetElement(controller, {   
            elementId: params.id, 
            modifier: (elem: Element) => { 
                if (!elem.props) {
                    elem.props = {}
                }
                elem.props[params.key] = {
                    type: params.type,
                    value: params.value,
                    ...((typeof params?.valueEN !== "undefined" && params?.valueEN !== null) ? 
                        {valueEN: params.valueEN} : {})
                }
                console.log(elem.props[params.key]);
            }
    })
    console.log(controller.getState().screenData?.elements)
}

// Helper -------------------------------------------------------------------
const SetElement: Handler = (
    controller, 
    params: {
        elementId: number, 
        modifier: (elem: Element) => Element
    }) => {
    const elements: Element[] = controller.getState().screenData?.elements || []
    elements.forEach((elem: Element) => {
        if (elem.id === params.elementId) {
            elem = params.modifier(elem)
        }
    })
    controller.setProp(`screenData.elements`, elements)
}